const initialState = {
  flashAlert: {
    isDisplayed: false,
    message: '',
    type: 'info',
    hideClose: false,
  },
};

const getters = {
  getFlashAlert(state) {
    return state.flashAlert;
  },
  isFlashAlertDisplayed(state) {
    return state.flashAlert.isDisplayed;
  },
};

const actions = {
  showFlashAlert({
    commit,
    dispatch,
  }, {
    message,
    type = 'info',
    hideClose = false,
  }) {
    dispatch('hideFlashAlert');
    setTimeout(() => commit('showFlashAlert', {
      message,
      type,
      hideClose,
    }), 200);
  },
  hideFlashAlert({
    state,
    commit,
  }) {
    if (state.flashAlert.isDisplayed) {
      commit('hideFlashAlert');
    }
  },
};

const mutations = {
  showFlashAlert(state, {
    message,
    type,
    hideClose,
  }) {
    state.flashAlert.isDisplayed = true;
    state.flashAlert.message = message;
    state.flashAlert.type = type;
    state.flashAlert.hideClose = hideClose;
  },
  hideFlashAlert(state) {
    state.flashAlert.isDisplayed = false;
    state.flashAlert.message = '';
    state.flashAlert.type = 'info';
    state.flashAlert.hideClose = true;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
