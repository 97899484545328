import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';

Vue.use(Router);

// https://github.com/nuxt-community/router-module#accessing-default-router
export function createRouter() {
  return new Router({
    mode: 'history',
    routes,
  });
}

export default { createRouter };
