import axios from 'axios';
import { generateBrowserID } from '~/utils/platform';

export default function ({ app, store, route }) {
  if (process.server) return; // TODO: Enable tracker to be available server-side
  // References:
  // - https://nuxtjs.org/api/context
  // - https://github.com/bukalapak/mothership/blob/ff741b2663eb17a3ec95aaa2ac03efcb753ee6be/app/assets/javascripts/siburung.es6

  const referrerActions = [
    {
      expr: () => app.$trackingReferrer?.name,
      value: () => ({ name: app.$trackingReferrer.name, url: app.$trackingReferrer.url, type: app.$trackingReferrer.type }),
    },
    {
      expr: () => !document.referrer && route.query.from,
      value: () => ({ name: route.query.from, url: '', type: 'param_from' }),
    },
    {
      expr: () => (document.referrer !== '') && (!document.referrer.includes(window.location.origin)),
      value: () => ({ name: 'referred', url: document.referrer, type: 'external_link' }),
    },
    {
      expr: () => (document.referrer !== '') && document.referrer.includes(window.location.origin),
      value: () => ({ name: 'other', url: document.referrer, type: 'internal_link' }),
    },
  ];
  const referrer = referrerActions.find(item => item.expr())?.value() ?? { name: 'organic', url: '', type: 'direct' };

  app.$trackingReferrer = { name: route.name, url: route.path, type: 'internal_link' };

  // Only send the tracking data if the page is accessed for the first time.
  // If the URL is accessed from another page in sentinel, it will be ignored.
  if (['organic', 'referred', 'authorize_page'].includes(referrer.name) && !['authorize_page', 'logout'].includes(route.name)) {
    const user = store.getters.globalUser;
    const url = process.env.SIBURUNG_TRACKING_URL;
    if (url !== '') {
      const formData = {
        action: referrer.name === 'authorize_page' ? 'login' : 'visit',
        client_id: generateBrowserID(),
        identity: app.$credentials.identity,
        landing_page_url: window.location.href,
        referrer_url: referrer.url,
        user_id: user.id ? Number(user.id).toString(36) : undefined,
      };

      if (process.env.NODE_ENV !== 'production') {
        console.warn('Tracker: ', url, formData);
      } else {
        axios.post(url, formData);
      }
    }
  }
}
