import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const DEBT_SNAPSHOTS_URL = `${process.env.API_V4_HOST}/debt-snapshots`;

export default Client.wrap({
  createDebtSnapshot(requestPayload) {
    return this.post(`${DEBT_SNAPSHOTS_URL}/`, 'user', new ApiOptions({ requestPayload }));
  },
});
