import {
  AgentSellingProductEntity,
  AgentSellingProductPayload,
  AgentSellingProductSearchParams,
  AgentSellingProductUpsertParams,
} from '~/types/api/agent-selling-products';
import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const AGENT_SELLING_PRODUCTS_URL = `${process.env.API_V4_HOST}/agent-selling-products`;

export const AgentSellingProducts = Client.wrap({
  updateAgentSellingProduct(requestPayload: AgentSellingProductPayload) {
    const { id } = requestPayload;
    return this.patch<AgentSellingProductEntity>(`${AGENT_SELLING_PRODUCTS_URL}/${id}`, 'user', new ApiOptions({ requestPayload }));
  },
  upsertAgentSellingProduct(requestPayload: AgentSellingProductUpsertParams) {
    return this.post<AgentSellingProductEntity>(`${AGENT_SELLING_PRODUCTS_URL}`, 'user', new ApiOptions({ requestPayload }));
  },
  getAgentSellingProducts(requestPayload: AgentSellingProductSearchParams) {
    return this.get<AgentSellingProductEntity>(AGENT_SELLING_PRODUCTS_URL, 'user', new ApiOptions({ requestPayload }));
  },
  getAgentSellingProduct(id: Number) {
    return this.get<AgentSellingProductEntity>(`${AGENT_SELLING_PRODUCTS_URL}/${id}`, 'user', new ApiOptions({ }));
  }
});
