import AgenliteAPIClient from '~/api/index';

const initialState = {};

const moduleGetters = {};

const actions = {
  async checkResetPasswordAvailability({ dispatch }, { type, param }) {
    dispatch('showAppLoading');
    dispatch('hideFlashAlert');
    let response = null;
    let isRegistered = false;
    try {
      response = await AgenliteAPIClient.fetchUserRegistrationAvailability({ [type]: param });
      const typeText = type === 'email' ? 'alamat e-mail' : 'no. handphone';
      isRegistered = ((response || {}).meta || {}).http_status !== 200;
      if (!isRegistered) {
        dispatch('catchError', {
          error: null,
          message: `User dengan ${typeText} ${param} belum terdaftar.`,
          hideAppLoading: true,
        });
        throw response;
      }
    } catch (error) {
      isRegistered = ((error || {}).meta || {}).http_status === 400;
      if (isRegistered) {
        response = error;
      } else {
        if (((response || {}).meta || {}).http_status !== 200) {
          dispatch('catchError', {
            error,
            message: 'Gagal melakukan reset password.',
            hideAppLoading: true,
          });
        }
        throw error;
      }
    } finally {
      dispatch('hideAppLoading');
    }
    return response;
  },
  async resetUserPasswordByEmail({ dispatch }, email) {
    dispatch('showAppLoading');
    dispatch('hideFlashAlert');
    let response = null;
    try {
      response = await AgenliteAPIClient.resetUserPasswordByEmail(email);
    } finally {
      dispatch('hideAppLoading');
    }
    return response;
  },
  async updateResetUserPassword({ dispatch }, { requestPayload, options = {} }) {
    dispatch('showAppLoading');
    dispatch('hideFlashAlert');
    let response = null;
    try {
      response = await AgenliteAPIClient.updateResetUserPassword(requestPayload, options);
      if (((response || {}).meta || {}).http_status !== 200) throw response;
      dispatch('hideAppLoading');
    } catch (error) {
      dispatch('hideAppLoading');
      if (options.triggerOnly && ((error || {}).meta || {}).http_status === 422) {
        return error;
      }
      if (((error || {}).meta || {}).http_status !== 401) {
        dispatch('catchError', {
          error,
          message: 'Gagal melakukan reset password.',
          hideAppLoading: true,
        });
      }
      throw error;
    }
    return response;
  },
};

const mutations = {};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  actions,
  mutations,
};
