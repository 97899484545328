const initialState = {
  inTransaction: {
    busy: false,
    type: '',
    product: '',
    qty: '',
    success: false,
  },
  topupTransaction: {
    amount: 0,
    paymentMethod: '',
    bank: '',
    paymentId: '',
    expiredAt: null,
    isBusy: false,
  },
};

const moduleGetters = {
  getTransactionState(state) {
    return state.inTransaction;
  },
  getTopupTransaction(state) {
    return state.topupTransaction;
  },
};

const actions = {
  beginTransaction({ dispatch, commit }, { transactionType, product, value, username }) {
    dispatch('showAppLoading');
    commit('beginTransaction', { transactionType, product, value, username });
  },
  updateTransaction({ commit }, { transactionType, product, value }) {
    commit('updateTransaction', { transactionType, product, value });
  },
  finishTransaction({ dispatch, commit }, { status }) {
    commit('finishTransaction', { status });
    dispatch('hideAppLoading');
  },
  setTopupTransaction({ commit }, payload) {
    return new Promise(resolve => {
      commit('setTopupTransaction', payload);
      if (payload.isBusy === true) {
        // TODO: add something here
      }
      resolve('success');
    });
  },
};

const mutations = {
  beginTransaction(state, { transactionType, product, value, username }) {
    // Acquire lock and re-set transaction
    state.inTransaction.busy = true;
    state.inTransaction.type = transactionType;
    state.inTransaction.product = product;
    state.inTransaction.qty = value;
    state.inTransaction.username = username || '';
  },
  updateTransaction(state, { transactionType, product, value }) {
    if (transactionType) state.inTransaction.type = transactionType || state.inTransaction.type;
    if (product) state.inTransaction.product = product || state.inTransaction.product;
    if (value) state.inTransaction.qty = value || state.inTransaction.qty;
  },
  finishTransaction(state, { status }) {
    state.inTransaction.busy = false;
    state.inTransaction.success = status;
    // Do not change the state.type to know last transaction happened.
    // finishTransaction is for release the transaction lock.
  },
  setTopupTransaction(state, topupData) {
    state.topupTransaction = {
      ...state.topupTransaction,
      ...topupData,
    };
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  actions,
  mutations,
};
