import ApiOptions from '~/api/v4/api-options';
import Client from '~/types/api/api-module';

const VOUCHERKU_URL = `${process.env.API_VPNONTRADER_HOST}/v1/vouchers/voucherkus`;
const BULK_VALIDATE_VOUCHER_URL = `${process.env.API_VPNONTRADER_HOST}/v1/vouchers/bulk-validate`;

export default Client.wrap({
  getVoucherku(requestPayload) {
    return this.get(VOUCHERKU_URL, 'product.*.*', { enableAuthHeader: true, ...new ApiOptions({ requestPayload }) });
  },
  bulkValidateVoucher(requestPayload) {
    return this.post(BULK_VALIDATE_VOUCHER_URL, 'product.*.*', {
      enableAuthHeader: true,
      ...new ApiOptions({ requestPayload }),
    });
  },
});
