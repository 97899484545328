/**
 * @module helpers/metrics-helpers
 */

import axios from 'axios';
import { timeDiffSeconds } from '~/server/helpers/date-helper';

export const API_TIMEOUT_DURATION = 30000;

type MetricStatus = 'ok' | 'fail';

export function logStdout(message = '', params = {}) {
  const form = new FormData();
  form.append('message', message);
  form.append('data', JSON.stringify(params));
  axios.post('/api-report', form);
}

export function promLatency(startTime: number, path = '/', status: MetricStatus = 'ok', action = 'sentinel_login') {
  const elapsedTime = timeDiffSeconds(startTime, new Date().getTime());
  const form = new FormData();
  form.append('histogramName', 'service_latency_seconds');
  form.append('time', elapsedTime.toString());
  form.append('action', action);
  form.append('path', path);
  form.append('status', status);
  axios.post('/prom', form);
}

export function promCounter(status: MetricStatus = 'ok', metricLabel = 'sentinel_login') {
  const form = new FormData();
  form.append('name', metricLabel);
  form.append('status', status);
  axios.post('/promc', form);
}

export function promCounterEx(
  metricLabel = 'sentinel_generic',
  metricDataset: { status: MetricStatus } = { status: 'ok' }
) {
  const postData = { ...metricDataset, label: metricLabel };
  axios.post('/promcex', postData);
}

export default {
  API_TIMEOUT_DURATION,
  logStdout,
  promLatency,
  promCounter,
};
