import timelimit from '~/constants/vp/timelimit';
import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const MULTIFINANCE_URL = `${process.env.API_V4_HOST}/multifinance`;

export default Client.wrap({
  // API interface
  Public: {
    async payMultifinance(userId, transaction, method, voucherCode) {
      const invoiceResponse = await this.registerInvoice(transaction.id, transaction.type, method, voucherCode);
      if (invoiceResponse.meta.http_status !== 201) throw invoiceResponse;
      const paymentResponse = await this.makePayment(userId, invoiceResponse.data.payment_id, method);
      if (paymentResponse.meta.http_status !== 200) throw paymentResponse;
      return {
        invoiceResponse,
        paymentResponse,
      };
    },
  },
  Private: {
    fetchMultifinanceBillers() {
      return this.get(`${MULTIFINANCE_URL}/billers`, 'public', new ApiOptions());
    },
    inquireMultifinanceInfo(nomorPelanggan, billerId) {
      const requestPayload = {
        customer_number: nomorPelanggan,
        biller_id: billerId,
      };
      this.abortRequest();
      return this.post(`${MULTIFINANCE_URL}/inquiries`, 'public', new ApiOptions({ requestPayload }));
    },
    getMultifinanceTransaction(remoteId) {
      return this.get(`${MULTIFINANCE_URL}/transactions/${remoteId}`, 'user', new ApiOptions());
    },
    createMultifinanceTransaction(nomorPelanggan, billerId, phoneNumberInput) {
      const requestPayload = {
        customer_number: nomorPelanggan,
        biller_id: billerId,
        mobile_number: phoneNumberInput,
      };
      const requestLimit = { time: timelimit.deposit, additionalKey: nomorPelanggan };
      return this.post(`${MULTIFINANCE_URL}/transactions`, 'user', new ApiOptions({ requestPayload, requestLimit }));
    },
  },
});
