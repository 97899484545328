import API from '~/api';

export default function ({ store, redirect, route }) {
  const splitUrl = route.fullPath.split('?');
  const query = splitUrl && splitUrl.length > 1 ? `?${splitUrl[1]}` : '?from=register';
  if (!API.credentials.login) {
    return;
  }
  if (!store.getters.getCurrentAgent?.id) {
    redirect(`/aktivasi-mitra${query}`);
    return;
  }
  if (store.getters.getCurrentAgent?.status === 'deactivated') {
    redirect(`/deactivated${query}`);
    return;
  }
  redirect('/');
}
