export default function ({ redirect, route }) {
  const splitUrl = route.fullPath.split('?');

  if (splitUrl[0].includes('/mitra-branch/staff/detail/')) {
    let validParams = false;
    splitUrl.forEach(str => {
      if (str === 'type=staff' || str === 'type=request') {
        validParams = true;
      }
    });
  
    if (validParams) return;
    redirect('/mitra-branch');
  }
}
