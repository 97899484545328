import {
  PublicGetCustomerEWalletTransactionResponse,
  PublicCreateCustomerEWalletInquiryResponse,
} from '@bukalapak/openapi-schema/vptrader/transaction/v1/public/transaction-domain';
import {
  PublicGetCustomerTransactionResponse,
  SharedTransactionMetadata,
} from '@bukalapak/openapi-schema/vptrader/transaction/v2/public/transaction-domain';
import VPNonTraderAPIClient from '~/api/vpnontrader';

function getMetadataValue(meta: SharedTransactionMetadata[] | undefined, name: string): string {
  return (
    meta?.find(m => {
      return m.name === name;
    })?.value || ''
  );
}

function convertToV1Response(res: PublicGetCustomerTransactionResponse): PublicGetCustomerEWalletTransactionResponse {
  const meta = res.customer_transaction?.metadata_list;

  return {
    e_wallet_transaction: {
      id: res.customer_transaction?.id,
      order_id: res.customer_transaction?.order_id,
      invoice_group_number: res.customer_transaction?.invoice_group_number,
      state: res.customer_transaction?.state,
      payment_state: res.customer_transaction?.payment_state,
      phone_number: getMetadataValue(meta, 'phone_number'),
      customer_name: getMetadataValue(meta, 'customer_name'),
      serial_number: getMetadataValue(meta, 'serial_number'),
      currency: res.customer_transaction?.currency,
      total_price: res.customer_transaction?.total_price,
      product_name: res.customer_transaction?.product_name,
      paid_at: res.customer_transaction?.paid_at,
      created_at: res.customer_transaction?.created_at,
      updated_at: res.customer_transaction?.updated_at,
      base_price: res.customer_transaction?.base_price,
      product_id: res.customer_transaction?.product_id,
      partner_id: res.customer_transaction?.partner_id,
      discount: res.customer_transaction?.discount,
      segmentation_adjustment: res.customer_transaction?.segmentation_adjustment,
      promo_label_text: getMetadataValue(meta, 'promo_label_text'),
      request_amount: getMetadataValue(meta, 'request_amount'),
      refund_note: getMetadataValue(meta, 'refund_note'),
      vendor: {
        code: res.customer_transaction?.vendor?.code,
        logo_url: res.customer_transaction?.vendor?.logo_url,
        name: res.customer_transaction?.vendor?.name,
      },
    },
  };
}

const initialState = {
  transaction: {
    vendor: {},
  },
  loading: true,
};

const getters = {
  getDigitalVoucherTransaction(state) {
    return state.transaction;
  },
  getDigitalVoucherTransactionLoading(state) {
    return state.loading;
  },
};

const actions = {
  async retrieveDigitalVoucherTransaction({ dispatch, commit }, payload) {
    let response: PublicGetCustomerTransactionResponse = {};

    const { options, ...requestPayload } = payload;

    try {
      if (!options?.ignoreLoading) {
        commit('setDigitalVoucherTransactionLoading', true);
      }

      response = await VPNonTraderAPIClient.getEWalletTransaction(requestPayload.id);

      const trx = convertToV1Response(response);

      const isBasePriceSegmentationAdjusted =
        getMetadataValue(response.customer_transaction?.metadata_list, 'is_base_price_segmentation_adjusted') ===
        'true';
      commit('setDigitalVoucherTransaction', {
        ...trx.e_wallet_transaction,
        is_base_price_segmentation_adjusted: isBasePriceSegmentationAdjusted,
      });

      return trx.e_wallet_transaction;
    } catch (error) {
      const message = 'Gagal mendapatkan data transaksi';
      dispatch('catchError', { error, message });
      throw error;
    } finally {
      commit('setDigitalVoucherTransactionLoading', false);
    }
  },
  async retrieveDigitalVoucherInquiry(_, requestPayload) {
    let response: PublicCreateCustomerEWalletInquiryResponse = {};

    try {
      response = await VPNonTraderAPIClient.createEWalletInquiry(requestPayload);
      return response;
    } catch (error) {
      return error;
    }
  },
};

const mutations = {
  setDigitalVoucherTransaction(state, transaction) {
    state.transaction = {
      ...transaction,
      base_price: parseInt(transaction.base_price, 10),
      total_price: parseInt(transaction.total_price, 10),
    };
  },
  setDigitalVoucherTransactionLoading(state, isLoading) {
    state.loading = isLoading;
  },
};

export default {
  state: () => ({ ...initialState }),
  mutations,
  getters,
  actions,
};
