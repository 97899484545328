import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const ALLOWLIST_URL = `${process.env.API_V4_HOST}/mitra/me/allowlists`;

export default Client.wrap({
  retrieveAllowlistMeFeature(feature) {
    return this.get(`${ALLOWLIST_URL}/${feature}`, 'user', new ApiOptions());
  }
});
