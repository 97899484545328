import credentialStorageInstance from '~/api/credential-storage-instance';
import Client from '~/types/api/api-module';

import ApiOptions from '../../api-options';

const NOTIFICATION_X_API_HOST = `${process.env.API_V4_HOST}/_exclusive/notifications`;

export default Client.wrap({
  putDevice(requestPayload) {
    const requestHeaders = { 'Bukalapak-Identity': credentialStorageInstance.identity };
    return this.put(`${NOTIFICATION_X_API_HOST}/devices`, 'user', new ApiOptions({ requestHeaders, requestPayload }));
  },
  deleteDevice(requestPayload) {
    const requestHeaders = { 'Bukalapak-Identity': credentialStorageInstance.identity };
    return this.delete(
      `${NOTIFICATION_X_API_HOST}/devices`,
      'user',
      new ApiOptions({ requestHeaders, requestPayload })
    );
  },
  loadSettings() {
    const requestPayload = { platform: 'push-notification' };
    return this.get(`${NOTIFICATION_X_API_HOST}/settings`, 'user', new ApiOptions({ requestPayload }));
  },
});
