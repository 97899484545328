import { RouteConfig } from '@nuxt/types/config/router';
import r from '~/utils/render-utils';

const BookkeepingIndex = () => import(/* webpackChunkName: 'bookkeeping' */ '~/pages/Bookkeeping/index.vue');
const Landing = () => import(/* webpackChunkName: 'bookkeeping-landing-container' */ '~/pages/Bookkeeping/Landing.vue');
const Bookkeeping = () => import(/* webpackChunkName: 'bookkeeping-landing' */ '~/components/Bookkeeping/Entries/Landing/index.vue');
const BookkeepingEntry = () => import(/* webpackChunkName: 'bookkeeping-entry' */ '~/pages/Bookkeeping/Entry/index.vue');
const BookkeepingEntryForm = () => import(/* webpackChunkName: 'bookkeeping-entry-form' */ '~/pages/Bookkeeping/EntryForm.vue');
const BookkeepingEntryDetails = () => import(/* webpackChunkName: 'bookkeeping-entry-details' */ '~/pages/Bookkeeping/Entry/Details.vue');
const BookkeepingEntryDebtNote = () => import(/* webpackChunkName: 'bookkeeping-entry-debt-note' */ '~/pages/Bookkeeping/Entry/DebtNote.vue');
const BookkeepingProductItemList = () => import(/* webpackChunkName: 'bookkeeping-product-item-list' */ '~/components/Bookkeeping/ProductItem/List/index.vue');
const BookkeepingProductItemForm = () => import(/* webpackChunkName: 'bookkeeping-product-item-add' */ '~/components/Bookkeeping/ProductItem/Form/index.vue');
const BookkeepingReport = () => import(/* webpackChunkName: 'bookkeeping-report' */ '~/pages/Bookkeeping/Report.vue');

const Buka20DebtNoteEntry = () => import(/* webpackChunkName: 'bookkeeping-report' */ '~/pages/Bookkeeping/Buka20/DebtNoteEntry.vue');

const DebtNotes = () => import(/* webpackChunkName: 'debt-notes-landing' */ '~/components/Bookkeeping/DebtNotes/Landing/index.vue');
const DebtNotesCustomer = () => import(/* webpackChunkName: 'debt-notes-customer' */ '~/pages/Bookkeeping/DebtNotes/Customer/index.vue');
const DebtSnapshot = () => import(/* webpackChunkName: 'debt-snapshot-preview' */ '~/pages/Bookkeeping/DebtNotes/Customer/_id/DebtSnapshot.vue');
const DebtSnapshotSetting = () => import(/* webpackChunkName: 'debt-snapshot-setting' */ '~/pages/Bookkeeping/DebtNotes/Customer/_id/DebtSnapshotSetting.vue');
const DebtNotesCustomerEdit = () => import(/* webpackChunkName: 'debt-notes-customer-edit' */ '~/pages/Bookkeeping/DebtNotes/Customer/Edit.vue');
const DebtNotesMutation = () => import(/* webpackChunkName: 'debt-notes-mutation' */ '~/pages/Bookkeeping/DebtNotes/Mutation.vue');
const DebtNotesDetails = () => import(/* webpackChunkName: 'debt-notes-details' */ '~/pages/Bookkeeping/DebtNotes/Details.vue');

const PaymentLink = () => import(/* webpackChunkName: 'payment-link' */ '~/pages/Bookkeeping/DebtNotes/PaymentLink/index.vue');
const PaymentLinkOnboarding = () => import(/* webpackChunkName: 'payment-link-onboarding' */ '~/pages/Bookkeeping/DebtNotes/PaymentLink/Onboarding.vue');
const PaymentLinkDetail = () => import(/* webpackChunkName: 'payment-link-detail' */ '~/pages/Bookkeeping/DebtNotes/PaymentLink/Detail.vue');

const DueDebtCalendar = () => import(/* webpackChunkName: 'due-debt-calendar' */ '~/pages/Bookkeeping/DebtNotes/DueDebtCalendar.vue');
const DueDateSetMultiple = () => import(/* webpackChunkName: 'due-debt-set-multiple' */ '~/pages/Bookkeeping/DebtNotes/SetDueDate/index.vue');

export const BookKeepingRoutes: RouteConfig[] = [
  {
    path: '/pembukuan/entry/buka-20/debt-note',
    component: r(Buka20DebtNoteEntry),
    name: 'buka20_bookkeeping_entry_debt_note_add',
  },
  {
    path: '/pembukuan',
    alias: '/pembukuan-mitra',
    component: r(BookkeepingIndex),
    children: [
      {
        path: 'report',
        component: r(BookkeepingReport),
        name: 'bookkeeping_report',
        meta: {
          hideAppHeader: true,
        }
      },
      {
        path: 'debt-notes/due-debt-calendar',
        component: r(DueDebtCalendar),
        name: 'due_debt_calendar',
        meta: {
          hideAppHeader: true,
        }
      },
      {
        path: 'debt-notes/set-due-date',
        component: r(DueDateSetMultiple),
        name: 'due_date_set_multiple',
        meta: {
          hideAppHeader: true,
        }
      },
      {
        path: 'debt-notes/customer/:id/payment-link/:paymentRequestId',
        component: r(PaymentLinkDetail),
        name: 'payment_link_detail',
        meta: {
          bookkeeping: true,
          hideAppHeader: true,
        },
      },
      {
        path: 'debt-notes/customer/:id',
        component: r(DebtNotesCustomer),
        name: 'debt_notes_customer',
        meta: {
          bookkeeping: true,
          hideAppHeader: true,
        },
      },
      {
        path: 'debt-notes/customer/:id/debt-snapshots',
        component: r(DebtSnapshot),
        name: 'debt_snapshot_preview',
        meta: {
          bookkeeping: true,
          hideAppHeader: true,
        },
      },
      {
        path: 'debt-notes/customer/:id/debt-snapshots-setting',
        component: r(DebtSnapshotSetting),
        name: 'debt_snapshot_setting',
        meta: {
          bookkeeping: true,
          hideAppHeader: true,
        },
      },
      {
        path: 'debt-notes/customer/:id/payment-link',
        component: r(PaymentLink),
        name: 'payment_link',
        meta: {
          bookkeeping: true,
          hideAppHeader: true,
        },
      },
      {
        path: 'debt-notes/customer/:id/payment-link-onboarding',
        component: r(PaymentLinkOnboarding),
        name: 'payment_link_onboarding',
        meta: {
          bookkeeping: true,
          hideAppHeader: true,
        },
      },
      {
        path: 'debt-notes/customer/:id/edit',
        component: r(DebtNotesCustomerEdit),
        name: 'debt_notes_customer_edit',
        meta: {
          bookkeeping: true,
          hideAppHeader: true,
        },
      },
      {
        path: 'debt-notes/customer/:id/paid',
        component: r(DebtNotesMutation),
        name: 'debt_notes_mutation_paid_add',
        meta: {
          bookkeeping: true,
          hideAppHeader: true,
          mutationType: 'paid',
          action: 'add',
        },
      },
      {
        path: 'debt-notes/customer/:id/paid/:mutationId',
        component: r(DebtNotesMutation),
        name: 'debt_notes_mutation_paid_edit',
        meta: {
          bookkeeping: true,
          hideAppHeader: true,
          mutationType: 'paid',
          action: 'edit',
        },
      },
      {
        path: 'debt-notes/customer/:id/paid/:mutationId/details',
        component: r(DebtNotesDetails),
        name: 'debt_notes_mutation_paid_details',
        meta: {
          bookkeeping: true,
          hideAppHeader: true,
          mutationType: 'paid',
          action: 'detail',
        },
      },
      {
        path: 'debt-notes/customer/:id/debt',
        component: r(DebtNotesMutation),
        name: 'debt_notes_mutation_debt_add',
        meta: {
          bookkeeping: true,
          hideAppHeader: true,
          mutationType: 'debt',
          action: 'add',
        },
      },
      {
        path: 'debt-notes/customer/:id/debt/:mutationId',
        component: r(DebtNotesMutation),
        name: 'debt_notes_mutation_debt_edit',
        meta: {
          bookkeeping: true,
          hideAppHeader: true,
          mutationType: 'debt',
          action: 'edit',
        },
      },
      {
        path: 'debt-notes/customer/:id/debt/:mutationId/details',
        component: r(DebtNotesDetails),
        name: 'debt_notes_mutation_debt_details',
        meta: {
          bookkeeping: true,
          hideAppHeader: true,
          mutationType: 'debt',
          action: 'detail',
        },
      },
      {
        path: '',
        component: r(Landing),
        children: [
          {
            path: '',
            component: r(Bookkeeping),
            name: 'bookkeeping_landing',
            meta: {
              bookkeepingCoachmarkStep: 0,
              bookkeeping: true,
              hideAppHeader: true,
              trackAdjustVisit: 'visitBookkeeping',
            },
            props: true,
          },
          {
            path: 'debt-notes',
            component: r(DebtNotes),
            name: 'debt_notes_landing',
            meta: {
              bookkeepingCoachmarkStep: 0,
              bookkeeping: true,
              hideAppHeader: true,
              trackAdjustVisit: 'visitDebtNotes',
            },
            props: true,
          },
        ]
      },
      {
        path: 'product-item/list',
        component: r(BookkeepingProductItemList),
        name: 'bookkeeping_product_item_list',
        meta: {
          bookkeeping: true,
          hideAppHeader: true,
          parent: {
            name: 'debt_notes_landing',
          },
        },
      },
      {
        path: 'entry/income/product-item/index',
        component: r(BookkeepingProductItemForm),
        name: 'bookkeeping_entry_income_product_item_add',
        meta: {
          bookkeeping: true,
          action: 'add',
          type: 'product-item',
          hideAppHeader: true,
          parent: {
            name: 'debt_notes_landing',
          },
        },
      },
      {
        path: '/product-item/index',
        component: r(BookkeepingProductItemForm),
        name: 'bookkeeping_product_item_add',
        meta: {
          bookkeeping: true,
          action: 'add',
          type: 'product-item',
          hideAppHeader: true,
          parent: {
            name: 'debt_notes_landing',
          },
        },
      },
      {
        path: '/product-item/_id',
        component: r(BookkeepingProductItemForm),
        name: 'bookkeeping_product_item_edit',
        meta: {
          bookkeeping: true,
          action: 'edit',
          type: 'product-item',
          hideAppHeader: true,
          parent: {
            name: 'debt_notes_landing',
          },
        },
      },
      {
        path: 'entry',
        component: r(BookkeepingEntry),
        children: [
          {
            path: 'income/product-item/list',
            component: r(BookkeepingProductItemList),
            name: 'bookkeeping_entry_income_product_item_list',
            meta: {
              bookkeeping: true,
              hideAppHeader: true,
              showNavbar: false,
              parent: {
                name: 'bookkeeping_landing',
              },
            },
          },
          {
            path: 'income/product-item/_id',
            component: r(BookkeepingProductItemForm),
            name: 'bookkeeping_entry_income_product_item_edit',
            meta: {
              bookkeeping: true,
              showNavbar: false,
              action: 'edit',
              type: 'product-item',
              parent: {
                name: 'bookkeeping_landing',
              },
            },
          },
          {
            path: 'income',
            alias: '',
            component: r(BookkeepingEntryForm),
            name: 'bookkeeping_entry_income_add',
            meta: {
              bookkeeping: true,
              hideAppHeader: true,
              entryType: 'income',
              action: 'add',
              parent: {
                name: 'bookkeeping_landing',
              },
            },
          },
          {
            path: 'income/:id',
            component: r(BookkeepingEntryForm),
            name: 'bookkeeping_entry_income_edit',
            meta: {
              bookkeeping: true,
              hideAppHeader: true,
              entryType: 'income',
              action: 'edit',
            },
          },
          {
            path: 'income/:id/details',
            component: r(BookkeepingEntryDetails),
            name: 'bookkeeping_entry_income_details',
            meta: {
              hideAppHeader: true,
              entryType: 'income',
              action: 'detail',
            }
          },
          {
            path: 'expense',
            component: r(BookkeepingEntryForm),
            name: 'bookkeeping_entry_expense_add',
            meta: {
              bookkeeping: true,
              hideAppHeader: true,
              entryType: 'expense',
              action: 'add',
              parent: {
                name: 'bookkeeping_landing',
              },
            },
          },
          {
            path: 'expense/:id',
            component: r(BookkeepingEntryForm),
            name: 'bookkeeping_entry_expense_edit',
            meta: {
              bookkeeping: true,
              hideAppHeader: true,
              entryType: 'expense',
              action: 'edit',
            },
          },
          {
            path: 'expense/:id/details',
            component: r(BookkeepingEntryDetails),
            name: 'bookkeeping_entry_expense_details',
            meta: {
              hideAppHeader: true,
              entryType: 'expense',
              action: 'detail',
            }
          },
          {
            path: 'debt-note',
            component: r(BookkeepingEntryDebtNote),
            name: 'bookkeeping_entry_debt_note_add',
            meta: {
              bookkeeping: true,
              hideAppHeader: true,
              skipStatusCoachmark: true,
              entryType: 'debtnote',
              action: 'add',
              parent: {
                name: 'debt_notes_landing',
              },
            },
          },
          {
            path: 'my-debt-note',
            component: r(BookkeepingEntryDebtNote),
            name: 'bookkeeping_entry_my_debt_note_add',
            meta: {
              bookkeeping: true,
              hideAppHeader: true,
              entryType: 'mydebtnote',
              skipStatusCoachmark: true,
              action: 'add',
              parent: {
                name: 'debt_notes_landing',
              },
            },
          },
        ]
      },
    ],
  },
];

export default BookKeepingRoutes;
