import type {
  PublicGetInvoiceGroupResponse,
  PublicListBillingFeeConfigsResponse,
} from '@bukalapak/openapi-schema/reusable/payment/v1/public/payment-domain';
import { NEO_CONFIG_PAYMENT_CODE_MAPPING } from '~/utils/buka20-constants';
import PaymentDomainAPIClient from '~/api/payment-domain';

type PaymentNeoConfigs = {
  'default-payment-method'?: string;
  'enable-product-service-fee'?: string[];
};

const initialState = {
  invoiceGroup: {},
  feeConfigs: [],
  paymentNeoConfigs: undefined as (PaymentNeoConfigs | undefined),
};

const getters = {
  getInvoiceGroup(state) {
    return state.invoiceGroup;
  },
  getInvoiceFee(state) {
    if (!state.invoiceGroup?.fees?.length) return 0;
    const fee = state.invoiceGroup.fees.find(fee => fee.type === 'INVOICE_FEE_TYPE_SERVICE_FEE');
    return fee ? Number(fee.amount) : 0;
  },
  getActiveFee(state) {
    return payload => {
      if (!payload) return 0;
      const { paymentType, totalAmount } = payload;

      if (!paymentType) return 0;
      const activeConfig = state.feeConfigs.find(config => config.payment_method === paymentType);
      if (!activeConfig) return 0;
      return Number(activeConfig.flat_fee) + Math.ceil(Number(activeConfig.fee_percentage) / 100 * totalAmount);
    };
  },
  getIsPaymentNeoConfigsLoaded(state) {
    return state.paymentNeoConfigs !== undefined;
  },
  getDefaultPaymentMethodType(state) {
    const defaultPaymentCode = state.paymentNeoConfigs?.['default-payment-method'] || 'wallet';
    return NEO_CONFIG_PAYMENT_CODE_MAPPING[defaultPaymentCode];
  },
  getEnableProductServiceFee(state) {
    return state.paymentNeoConfigs?.['enable-product-service-fee'] || [];
  },
};

const actions = {
  async retrieveInvoiceGroup({ dispatch, commit }, invoiceGroupNumber) {
    let response: PublicGetInvoiceGroupResponse = {};

    try {
      response = await PaymentDomainAPIClient.getInvoiceDetails(invoiceGroupNumber);
      commit('setInvoiceGroup', response);
    } catch (error) {
      const message = 'Gagal mendapatkan data invoice';
      dispatch('catchError', { error, message });
      throw error;
    }
  },

  async fetchFeeConfigs({ dispatch, commit, getters }, payload) {
    try {
      if (!getters.getIsPaymentNeoConfigsLoaded) await dispatch('fetchPaymentConfigs');
      if (!getters.getEnableProductServiceFee.includes(payload.productType)) return;

      const response: PublicListBillingFeeConfigsResponse = await PaymentDomainAPIClient.getFeeConfigs(payload);
      commit('setFeeConfigs', response);
    } catch (error) {
      const message = 'Gagal mendapatkan data biaya layanan';
      dispatch('catchError', { error, message });
    }
  },

  async fetchPaymentConfigs({ dispatch, commit, getters }) {
    if (getters.getIsPaymentNeoConfigsLoaded) return;
    const response = await dispatch('fetchNeoConfigs', ['mitra-payment-method-config'], { root: true });
    const config = response?.[0]?.data || {};
    commit('setPaymentNeoConfigs', config);
  },
};

const mutations = {
  setInvoiceGroup(state, invoiceGroup) {
    state.invoiceGroup = invoiceGroup;
  },
  setFeeConfigs(state, feeConfigs: PublicListBillingFeeConfigsResponse) {
    state.feeConfigs = feeConfigs.configs_billing_fee;
  },
  setPaymentNeoConfigs(state, paymentNeoConfigs: PaymentNeoConfigs) {
    state.paymentNeoConfigs = paymentNeoConfigs;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  mutations,
  actions,
};
