import AgenliteAPIClient from '~/api';
import SilkroadAPIClient from '~/api/silkroad';

const initialState = {
  addresses: null,
  province: [],
  city: [],
  area: [],
  district: [],
  postCodes: [],
  vpStatusInfo: null,
};

const moduleGetters = {
  getProvinces(state) {
    return state.province;
  },
  getCities(state) {
    return state.city;
  },
  getAreas(state) {
    return state.area;
  },
  getDistricts(state) {
    return state.district;
  },
  getProvinceListInfo(state) {
    return state.addresses ? Object.keys(state.addresses) : [];
  },
  getCityListInfo(state) {
    return province => (state.addresses && state.addresses[province] ? Object.keys(state.addresses[province]) : []);
  },
  getDistrictListInfo(state) {
    return (province, city) =>
      state.addresses && state.addresses[province] && state.addresses[province][city]
        ? state.addresses[province][city]
        : [];
  },
  getPostCodes(state) {
    return state.postCodes;
  },
  getVpStatusInfo(state) {
    return state.vpStatusInfo;
  },
};

const actions = {
  retrieveProvince({ dispatch, commit }) {
    dispatch('showAppLoading');
    return new Promise((resolve, reject) => {
      SilkroadAPIClient.getProvinces()
        .then(response => {
          commit('setProvinces', response.data);
          dispatch('hideAppLoading');
          resolve();
        })
        .catch(error => {
          dispatch('hideAppLoading');
          reject(error);
        });
    });
  },
  retrieveCity({ dispatch, commit }, options) {
    dispatch('showAppLoading');
    return new Promise((resolve, reject) => {
      SilkroadAPIClient.getCities(options)
        .then(response => {
          commit('setCities', response.data);
          dispatch('hideAppLoading');
          resolve();
        })
        .catch(error => {
          dispatch('hideAppLoading');
          reject(error);
        });
    });
  },
  retrieveCities({ dispatch, commit }, options) {
    dispatch('showAppLoading');
    return new Promise((resolve, reject) => {
      SilkroadAPIClient.getMultipleCities(options)
        .then(response => {
          commit('setCities', response.data);
          dispatch('hideAppLoading');
          resolve();
        })
        .catch(error => {
          dispatch('hideAppLoading');
          reject(error);
        });
    });
  },
  retrieveArea({ dispatch, commit }, options) {
    dispatch('showAppLoading');
    return new Promise((resolve, reject) => {
      SilkroadAPIClient.getAreas(options)
        .then(response => {
          commit('setAreas', response.data);
          dispatch('hideAppLoading');
          resolve();
        })
        .catch(error => {
          dispatch('hideAppLoading');
          reject(error);
        });
    });
  },
  retrieveDistrict({ dispatch, commit }, options) {
    dispatch('showAppLoading');
    return new Promise((resolve, reject) => {
      SilkroadAPIClient.getDistricts(options)
        .then(response => {
          commit('setDistricts', response.data);
          dispatch('hideAppLoading');
          resolve();
        })
        .catch(error => {
          dispatch('hideAppLoading');
          reject(error);
        });
    });
  },
  retrievePostCode({ dispatch, commit }, options) {
    dispatch('showAppLoading');
    return new Promise((resolve, reject) => {
      SilkroadAPIClient.getPostCodes(options)
        .then(response => {
          commit('setPostCodes', response.data);
          dispatch('hideAppLoading');
          resolve();
        })
        .catch(error => {
          dispatch('hideAppLoading');
          reject(error);
        });
    });
  },
  retrieveInfoAddresses({ commit, dispatch }) {
    dispatch('showAppLoading');
    return new Promise((resolve, reject) => {
      AgenliteAPIClient.retrieveInfoAddresses()
        .then(response => {
          dispatch('hideFlashAlert');
          dispatch('hideAppLoading');
          const addresses = response.data;
          commit('setInfoAddress', addresses);
          resolve(addresses);
        })
        .catch(error => {
          const message = 'Gagal mendapatkan data alamat.';
          dispatch('catchError', { error, message, hideAppLoading: true });
          reject(error);
        });
    });
  },
  retrieveVpStatusInfo({ dispatch, commit }, type) {
    commit('setVpStatusInfo', null);
    dispatch('showAppLoading');
    return new Promise(resolve => {
      AgenliteAPIClient.retrieveVpStatusInfo(type)
        .then(response => {
          dispatch('hideAppLoading');
          commit('setVpStatusInfo', response.data);
          resolve(response.data);
        })
        .catch(() => {
          dispatch('hideAppLoading');
        });
    });
  },
  async retrievePaymentLimitAndFee({ dispatch, commit }) {
    try {
      const response = await AgenliteAPIClient.retrievePaymentLimitAndFee();
      commit('setProviderList', response.data);
    } catch (error) {
      this.$router.push('/');
      const message = 'Terjadi kesalahan. Silakan coba lagi nanti.';
      dispatch('catchError', { error, message, hideAppLoading: true });
      throw error;
    }
  },
};

const mutations = {
  setProvinces(state, province) {
    state.province = province;
  },
  setCities(state, city) {
    state.city = city;
  },
  setAreas(state, area) {
    state.area = area;
  },
  setDistricts(state, district) {
    state.district = district;
  },
  setInfoAddress(state, addresses) {
    state.addresses = addresses;
  },
  setPostCodes(state, postCodes) {
    state.postCodes = postCodes;
  },
  setVpStatusInfo(state, vpStatusInfo) {
    state.vpStatusInfo = vpStatusInfo;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  actions,
  mutations,
};
