import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

type TransactionsParams = {
  trx_type?: string;
  list_type?: string;
};

/**
next for each transaction type we can use extends to TransactionItem interface
e.g export interface PhoneCreditTrx extends TransactionItem { ... }
*/
export interface TransactionItem {
  trx_type: string;
  created_at: string;
  detail: object;
}

type TransactionItemResponse = Array<TransactionItem>;

const TRANSACTION_URL = `${process.env.API_V4_HOST}/mitra-payment/transactions`;

export const MitraTrx = Client.wrap({
  getMitraTransactions(params: TransactionsParams) {
    return this.get<TransactionItemResponse>(TRANSACTION_URL, 'user', new ApiOptions({ requestPayload: params }));
  }
});
