import blGlobal from '~/api/v4/blglobal';

const agenliteConfig = {
  displayedInvoiceType: [
    'phone-credit-prepaid',
    'data-plan-prepaid',
    'electricity-prepaid',
    'electricity_postpaid',
    'pdam',
    'bpjs-kesehatan',
    'multifinance',
    'train',
  ],
  csWhatsappNumber: '+6287784682523',
  alertMinimumBalance: 50000,
  loanThreshold: 50000,
  productSearchPerPageLimit: 7,
  confirmModalMinimumAmount: 100000,
  loyaltyReleaseDate: 1538586000000,
  loyaltyVPRate: 0.0001,
  loyaltyGTRate: 0.0001,
  kycReleaseDate: 'Tue May 1 2018 01:00:00 GMT+0700 (WIB)',
  disableBankTransferStartDate: 'Fri Mar 22 2019 01:00:00 GMT+0700 (WIB)',
  topupDanaCustomerLimit: {
    max: 20000000,
    min: 20000,
  },
  imageSize: {
    max: {
      limit: 10000000,
      error: 'Ukuran file foto yang kamu upload terlalu besar. Coba upload lagi foto yang kurang dari 10MB ya.',
    },
  },
};

const sharedConfigData = {
  ...agenliteConfig,
  fetch: async (forceRenew = false) => {
    const response = await blGlobal.fetch(forceRenew);
    return {
      ...agenliteConfig,
      ...response,
    };
  },
};

const SharedConfigInstaller = {
  ...sharedConfigData,
};

export default SharedConfigInstaller;
