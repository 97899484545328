import { money } from '~/server/helpers/number-helper';

export interface VoucherMeta {
  message_key: string;
  message: string;
}

const ANY_EXIST_MESSAGE = 'Maaf, promo tidak berlaku karena total belanja belum mencukupi syarat.';
const IS_NOT_STARTED_MESSAGE = 'Maaf, belum masuk waktu promo. Cek info waktunya di Syarat dan Ketentuan.';
const QUOTA_INSUFFICIENT_MESSAGE = 'Maaf, kuota promo hari ini sudah habis.';
const REWARD_MESSAGE = 'Selamat, kamu dapat cashback %s! Cek di Saldo maks. 2x24 jam setelah terima barang.';

const voucherMessageList = {
  success: 'Selamat, kode promo dapat dipakai!',
  not_found: 'Pastikan kode promonya benar, lalu masukkan ulang.',
  expired: 'Maaf, promo ini telah kedaluwarsa.',
  out_of_quota: QUOTA_INSUFFICIENT_MESSAGE,
  out_of_budget: QUOTA_INSUFFICIENT_MESSAGE,
  confirmed_email_only: 'Silakan konfirmasi email yang kamu daftarkan ke  Bukalapak untuk pakai kode promo ini.',
  confirmed_phone_only: 'Silakan konfirmasi nomor handphone yang kamu daftarkan ke Bukalapak untuk ikutan promo ini.',
  first_purchase_only: 'Maaf, promo hanya berlaku untuk pembelian pertama dari akunmu.',
  ineligible_user: 'Maaf, promo tidak berlaku untuk pembelian dari akunmu.',
  limit_reached_on_device: 'Maaf, kamu tidak bisa ikutan promo dengan perangkat yang sama.',
  limit_reached_on_phone_number: 'Maaf, kamu sudah mencapai batas maksimum keikutsertaan promo ini.',
  have_pending_transaction: 'Silakan tunggu sampai transaksi sebelumnya kedaluwarsa.',
  invalid_product: 'Maaf, promo tidak berlaku untuk pembelian barang ini.',
  invalid_platform: 'Maaf, promo tidak berlaku untuk pembelian dengan jenis aplikasi ini.',
  invalid_product_category: 'Maaf, promo tidak berlaku untuk jenis barang ini.',
  invalid_location: 'Maaf, promo tidak berlaku untuk lokasi tujuan pengirimanmu.',
  invalid_amount: ANY_EXIST_MESSAGE,
  all_exist: ANY_EXIST_MESSAGE,
  min_exist: ANY_EXIST_MESSAGE,
  max_exist: ANY_EXIST_MESSAGE,
  no_mix_payment: 'Maaf, promo ini hanya berlaku untuk satu pilihan metode pembayaran.',
  invalid_payment_method: 'Maaf, promo tidak berlaku untuk metode pembayaran yang kamu pilih.',
  invalid_time: 'Maaf, promo tidak bisa kamu dapatkan saat ini.',
  invalid_item_amount: 'Maaf, promo tidak berlaku karena harga barang belum mencukupi syarat.',
  reward_non_marketplace: REWARD_MESSAGE,
  reward: REWARD_MESSAGE,
  limit_reached_on_phone_number_limit: 'Maaf, kuota promo saat ini sudah habis.',
  usage_limit_reached_by_phone_number: 'Maaf, batas keikutsertaan promo untuk nomor handphone ini sudah habis.',
  invalid_phone_confirm_user: 'Maaf, promo tidak bisa kamu dapatkan karena nomor handphone sudah diverifikasi pada akun lain.',
  invalid_special_voucher_usage: 'Maaf, kamu tidak memenuhi syarat untuk mengikuti promo ini.',
  invalid_virtual_account_type: 'Maaf, promo hanya berlaku untuk pembayaran dengan jenis virtual account yang kamu pilih.',
  invalid_fraud_detector: 'Maaf, kamu sementara tidak bisa mengikuti promo ini. Silakan Hubungi Bukalapak.',
  before_start: IS_NOT_STARTED_MESSAGE,
  after_end: IS_NOT_STARTED_MESSAGE,
};

export default function getVoucherMessage({ message_key: messageKey, message }: VoucherMeta, rewardValue: number = 0): string {
  return voucherMessageList[messageKey]?.replace('%s', money(rewardValue)) ?? message ?? '';
};
