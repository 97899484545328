import {
  PublicCreateTopupCheckoutDraftRequest,
  PublicValidateTopupCheckoutDraftRequest,
  PublicFinalizeTopupCheckoutDraftRequest,
} from '@bukalapak/openapi-schema/reusable/payment/v2/public/payment-domain';
import ApiOptions from '~/api/v4/api-options';
import Client from '~/types/api/api-module';

const TOPUP_URL = `${process.env.API_PAYMENT_HOST}/v2/payment/topups`;
const TOPUP_SCOPE = 'payment.*.*';

export default Client.wrap({
  createTopupCheckoutDraft(requestPayload: PublicCreateTopupCheckoutDraftRequest) {
    return this.post(`${TOPUP_URL}`, TOPUP_SCOPE, {
      enableAuthHeader: true,
      ...new ApiOptions({ requestPayload }),
    });
  },
  validateTopupCheckoutDraft(requestPayload: PublicValidateTopupCheckoutDraftRequest) {
    return this.patch(`${TOPUP_URL}/validate`, TOPUP_SCOPE, {
      enableAuthHeader: true,
      ...new ApiOptions({ requestPayload }),
    });
  },

  finalizeTopupCheckoutDraft(requestPayload: PublicFinalizeTopupCheckoutDraftRequest) {
    return this.post(`${TOPUP_URL}/finalize`, TOPUP_SCOPE, {
      enableAuthHeader: true,
      ...new ApiOptions({ requestPayload }),
    });
  },
});
