import AgenliteAPIClient from '~/api';
import SilkroadAPIClient from '~/api/silkroad';

const initialState = {
  me: {},
  superWarungKYC: {},
  basicKYC: {},
  hasOnboarding: {
    kyc: false,
    landing: false,
  },
  cart: {},
};

const getters = {
  getSuperWarungStatus(state) {
    return state.superWarungKYC.status;
  },
  getBasicKYCStatus(state) {
    return state.basicKYC.state;
  },
  getOnboardingStatus(state) {
    return {
      'kyc': state.hasOnboarding.kyc,
      'landing': state.hasOnboarding.landing
    };
  },
  getBasicPlafond(state) {
    return state.superWarungKYC.plafond_limit;
  },
  getPlafond(state) {
    return state.me.plafond_limit;
  },
  getStatus(state) {
    return state.me.status;
  },
  getUserPIC(state) {
    return {
      'name': state.superWarungKYC.pic_name || '',
      'phone': state.superWarungKYC.pic_phone?.replace(/^62/, '0').match(/.{1,4}/g)?.join('-') || '',
      'link': state.superWarungKYC.wa_link || '',
    };
  },
  getTnC(state) {
    return state.me.term_and_conditions;
  },
  getCartTotal(state) {
    return state.cart?.meta?.total_price;
  },
  mitraCanTransaction(state) {
    return [
      'inbound_approved',
      'sa_visited'
    ].includes(state.me.status);
  },
  mustRequestReview(state) {
    return [
      'already_transacted',
      'sti_rejected',
      'bill_rejected'
    ].includes(state.me.status);
  },
};

const actions = {
  async fetchSuperWarungKYC({ commit, dispatch }) {
    let response = null;
    try {
      response = await SilkroadAPIClient.getSuperWarungKYC();
      commit('setSuperWarungKYC', response);
      commit('setOnboarding', {
        'kyc': response.data.onboarded,
        'landing': response.data.seen_success_kyc
      });
    } catch (error) {
      if (error?.data?.onboarded && error?.meta?.http_status === 404) {
        commit('setOnboarding', {
          'kyc': error.data?.onboarded,
          'landing': error.data?.seen_success_kyc || false
        });
      } else {
        const message = 'Gagal mendapatkan data KYC mitra.';
        dispatch('catchError', { error, message, noFlashAlert: true }, { root: true });  
        throw error;
      }
    }

    return response;
  },
  async fetcMitraKYC({ commit, dispatch }) {
    let response = null;
    try {
      response = await AgenliteAPIClient.getMitraKYC();
      commit('setKYCBasic', response.data);
    } catch (error) {
      const message = 'Gagal mendapatkan data KYC basic.';
      dispatch('catchError', { error, message, noFlashAlert: true }, { root: true });
      throw error;
    }
    return response?.data;
  },
  async fetchMe({ commit, dispatch }) { 
    let response = null;
    try {
      response = await SilkroadAPIClient.getSuperWarungMe();
      commit('setMe', response.data);
    } catch (error) {
      const message = 'Gagal mendapatkan data mitra.';
      dispatch('catchError', { error, message, noFlashAlert: true }, { root: true });
      throw error;
    }

    return response;
  },
  async patchInboundReview({ dispatch }) {
    let response = null;
    try {
      response = await SilkroadAPIClient.patchSuperWarungInboundReview();
      dispatch('fetchMe');
    } catch (error) {
      const message = error?.message || 'Gagal meminta review.';
      dispatch('catchError', { message }, { root: true });
      throw error;
    }

    return response?.message;
  },
  async createUserLabel({ dispatch }, userLabel) {
    try {
      await SilkroadAPIClient.addUserLabels({ label: userLabel });
      dispatch('fetchSuperWarungKYC');
    } catch (error) {
      const message = 'Gagal membuat user label.';
      dispatch('catchError', { error, message, noFlashAlert: true }, { root: true });
      throw error;
    }
  },
  async fetchCart({ commit, dispatch }) {
    let response = null;
    try {
      response = await SilkroadAPIClient.retrieveCart({
        cartType: 'super_warung',
      });
      commit('setCart', response);
    } catch (error) {
      const message = 'Gagal mendapatankan data cart.';
      dispatch('catchError', { error, message, noFlashAlert: true }, { root: true });
      throw error;
    } 

    return response;
  }
};

const mutations = {
  setSuperWarungKYC(state, payload) {
    state.superWarungKYC = payload.data;
    state.superWarungKYC['message'] = payload.message;
  },
  setKYCBasic(state, payload) {
    state.basicKYC = payload;
  },
  setOnboarding(state, payload) {
    state.hasOnboarding = payload;
  },
  setMe(state, payload) {
    state.me = payload;
   },
   setCart(state, payload) {
    state.cart = payload;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
  namespaced: true,
};
