import AgenliteAPIClient from '~/api/index';
import SilkroadAPIClient from '~/api/silkroad';

const initialState = {
  bookkeepingDebtNoteForm: {
    amount: 0,
    notes: '',
    transaction_date: null
  },
  paymentLinkForm: {
    amount: 0,
    notes: '',
    business_name: '',
  },
  saasUser: {},
  selectedBookkeepingOfflineCustomer: {},
  selectedBookkeepingOfflineCustomerMutations: [],
  bookkeepingOfflineCustomers: [],
  emptyAllBookkeepingOfflineCustomers: false,
  bookkeepingUnspecifiedDebtDueDateCustomersMeta: {
    total: 0,
  },
  bookkeepingOfflineCustomersMeta: {
    total: 0,
    offset: 0,
    limit: 25,
    total_debt_balance: 0,
  },
  paymentRequest: {},
  debtSnapshot: {},
  hasSeenMultipleDebtNotesOnboarding: false,
  hasSeenProductItemTooltip: {
    1: false,
    2: false
  },
};

const getters = {
  getSaasUser: state => state.saasUser,
  getBookkeepingOfflineCustomersMeta: state => state.bookkeepingOfflineCustomersMeta,
  getBookkeepingUnspecifiedDebtDueDateCustomersMeta: state => state.bookkeepingUnspecifiedDebtDueDateCustomersMeta,
  getSelectedCustomer: state => state.selectedBookkeepingOfflineCustomer,
  getSelectedCustomerMutation: state => state.bookkeepingDebtNoteForm,
  getSelectedCustomerMutations: state => state.selectedBookkeepingOfflineCustomerMutations,
  getBookkeepingOfflineCustomers: state => state.bookkeepingOfflineCustomers,
  getEmptyAllBookkeepingOfflineCustomers: state => state.emptyAllBookkeepingOfflineCustomers,
  getPaymentLinkForm: state => state.paymentLinkForm,
  getPaymentRequest: state => state.paymentRequest,
  getDebtSnapshot: state => state.debtSnapshot,
  hasSeenMultipleDebtNotesOnboarding: state => state.hasSeenMultipleDebtNotesOnboarding,
  hasSeenProductItemsTooltip: state => step => state.hasSeenProductItemTooltip[step],
};

const actions = {
  async retrievePaymentRequest({ commit }, requestPayload) {
    const { data } = await AgenliteAPIClient.retrievePaymentRequest(requestPayload.id);
    commit('setPaymentRequest', data);
  },
  async getOfflineCustomerDebtMutation({ commit, state }, requestPayload) {
    const userId = state.selectedBookkeepingOfflineCustomer?.id;
    const { data } = await AgenliteAPIClient.getOfflineCustomerDebtMutation(userId, requestPayload.id);
    commit('setBookkeepingDebtNoteForm', data);
  },
  async createDebtSnapshot({ commit }, requestPayload) {
    const { data } = await AgenliteAPIClient.createDebtSnapshot(requestPayload);
    commit('setDebtSnapshot', data);
  },
  async createOfflineCustomerDebtMutation({ commit, state }, requestPayload) {
    const userId = state.selectedBookkeepingOfflineCustomer?.id;
    const { data } = await AgenliteAPIClient.createOfflineCustomerDebtMutation(userId, requestPayload);
    commit('setBookkeepingDebtNoteForm', data);
  },
  async updateOfflineCustomerDebtMutation({ commit, state }, requestPayload) {
    const userId = state.selectedBookkeepingOfflineCustomer?.id;
    const { data } = await AgenliteAPIClient.updateOfflineCustomerDebtMutation(userId, requestPayload.id, requestPayload);
    commit('setBookkeepingDebtNoteForm', data);
  },
  async deleteOfflineCustomerDebtMutation({ commit, state }, requestPayload) {
    const userId = state.selectedBookkeepingOfflineCustomer?.id;
    await AgenliteAPIClient.deleteOfflineCustomerDebtMutation(userId, requestPayload.id);
    commit('setBookkeepingDebtNoteForm', {});
  },
  async checkEmptyOfflineCustomers({ commit }) {
    try {
      const { meta } = await AgenliteAPIClient.getAgentCustomersList({ limit: 1 });
      commit('setEmptyAllBookkeepingOfflineCustomers', meta.total === 0);
    } catch (err) {
      console.error(err);
    }
  },
  async fetchUnspecifiedDebtDueDateCustomers({ commit }) {
    const { meta = {} } = await AgenliteAPIClient.getAgentCustomersList({ relative_debt_due_date: 'unspecified', debt: true, debt_status: 'in_debt' });
    commit('setBookkeepingUnspecifiedDebtDueDateCustomersMeta', meta);
    if (meta.total > 0) commit('bookkeepingOnboarding/hasClosedJatuhTempoAnnouncement', false);
  },
  async fetchOfflineCustomers({ commit }, requestPayload) {
    const { data, meta } = await AgenliteAPIClient.getAgentCustomersList(requestPayload);
    commit('setBookkeepingOfflineCustomers', data);
    commit('setBookkeepingOfflineCustomersMeta', meta);
  },
  async fetchMoreOfflineCustomers({ commit, state }, requestPayload) {
    if (state.bookkeepingOfflineCustomers.length > 0 && state.bookkeepingOfflineCustomers.length < state.bookkeepingOfflineCustomersMeta.total) {
      const { data } = await AgenliteAPIClient.getAgentCustomersList({
        ...requestPayload,
        offset: state.bookkeepingOfflineCustomers.length,
        limit: 20,
      });
      commit('setBookkeepingOfflineCustomers', [
        ...state.bookkeepingOfflineCustomers,
        ...data,
      ]);
    }
  },
  async fetchOfflineCustomer({ commit }, userId) {
    const { data } = await AgenliteAPIClient.getAgentCustomer(userId);

    commit('setSelectedBookkeepingOfflineCustomer', data);
  },
  async fetchOfflineCustomerDebtMutation({ commit }, userId) {
    const requestPayload = { limit: 100, sort: '-transaction_date,-id' };
    const { data } = await AgenliteAPIClient.getOfflineCustomerDebtMutationList(userId, requestPayload);
    commit('setSelectedBookkeepingOfflineCustomerMutations', data);
  },
  async addNewCustomer({ commit, state, getters }, requestPayload) {
    const payload = { ...requestPayload };

    if (!payload.phone) delete payload.phone;
    const { data: customer } = await AgenliteAPIClient.addCustomers(payload);
    if (getters.getEmptyAllBookkeepingOfflineCustomers) commit('setEmptyAllBookkeepingOfflineCustomers', false);
    commit('setSelectedBookkeepingOfflineCustomer', customer);
    commit('setBookkeepingOfflineCustomers', [
      customer,
      ...state.bookkeepingOfflineCustomers,
    ]);
  },
  async prefillDebtNoteFormByInvoiceId({ commit }, invoiceId) {
    const { data: invoice } = await AgenliteAPIClient.getInvoice(invoiceId);

    const invoiceTransaction = invoice?.transactions[0];
    const mapTypes = {
      'data-plan-prepaid': {
        fetchTransaction: AgenliteAPIClient.getDataPlanTransaction,
        id: 'data_plan',
      },
      'electricity-prepaid': {
        fetchTransaction: AgenliteAPIClient.getElectricityPrepaidTransaction,
        id: 'electricity_prepaid',
      },
      'phone-credit-prepaid': {
        fetchTransaction: AgenliteAPIClient.getPhoneCreditTransaction,
        id: 'phone_credit',
      },
    };

    // unsupported transaction type
    if (!mapTypes[invoiceTransaction?.type]) return;

    // Fetch Transaction by its type
    const { data: transaction } = await mapTypes[invoiceTransaction?.type].fetchTransaction(invoiceTransaction.id);

    const product = transaction?.package?.partner_package?.product || transaction?.product;

    // Fetch Agent Selling Products
    const { data: agentSellingProducts } = await AgenliteAPIClient.getAgentSellingProducts({
      product_id: product.id,
      product_type: mapTypes[transaction.type]?.id,
    });
    const agentSellingProduct = agentSellingProducts[0];

    // Fetch Customer Numbers
    const { data: customerNumbers } = await AgenliteAPIClient.getCustomerNumbers({
      number: transaction?.phone_number || transaction?.meter_number,
      type: mapTypes[transaction?.type]?.id,
    });

    const customerNumber = customerNumbers[0];

    /* istanbul ignore else */
    if (customerNumber) {
      // Fetch Customer
      const { data: customer } = await AgenliteAPIClient.getAgentCustomer(customerNumber.offline_customer_id);

      commit('setSelectedBookkeepingOfflineCustomer', customer);
    }

    commit('setBookkeepingDebtNoteForm', {
      amount: agentSellingProduct?.selling_price || agentSellingProduct?.recommended_selling_price || invoice?.amount?.total,
      notes: product?.name,
      transaction_date: transaction?.state_changed_at?.invoiced_at || transaction?.state_changed_at?.processed_at,
    });
  },
  async addNewPaymentLink({ state }) {
    const { haveNoAddress } = state.paymentLinkForm;
    const isRegisteredSaasUser = state.saasUser.id;
    const isBusinessNameChanged = state.saasUser.business_name !== state.paymentLinkForm.business_name;

    if (haveNoAddress) {
      const saasUserPayload = { business_name: state.paymentLinkForm.business_name };
      if (isRegisteredSaasUser && isBusinessNameChanged) {
        await AgenliteAPIClient.updateSaasUser(saasUserPayload);
      } else if (!isRegisteredSaasUser) {
        await AgenliteAPIClient.registerSaasUser(saasUserPayload);
      }
    }

    const payload = {
      ...state.paymentLinkForm,
      offline_customer_id: state.selectedBookkeepingOfflineCustomer.id,
    };

    if (!payload.notes) delete payload.notes;
    return AgenliteAPIClient.createPaymentRequest(payload);
  },
  async prefillPaymentLinkBusinessName({ state, commit, dispatch }) {
    try {
      const { data: { address } } = await SilkroadAPIClient.me();
      commit('setPaymentLinkForm', {
        ...state.paymentLinkForm,
        business_name: address.name,
      });
    } catch (e) {
      if (e.meta.http_status === 403) {
        commit('setPaymentLinkForm', {
          ...state.paymentLinkForm,
          haveNoAddress: true,
        });
        dispatch('fetchBusinessName');
      }
    }
  },

  async fetchSaasUser({ commit }) {
    const { data: user } = await AgenliteAPIClient.fetchSaasUser();
    commit('setSaasUser', user);
  },

  async fetchBusinessName({ commit, state }) {
    const { data: user } = await AgenliteAPIClient.fetchSaasUser();
    commit('setSaasUser', user);
    commit('setPaymentLinkForm', {
      ...state.paymentLinkForm,
      business_name: user.business_name,
    });
  }
};

const mutations = {
  setPaymentRequest(state, paymentRequest) {
    state.paymentRequest = paymentRequest;
  },
  setBookkeepingDebtNoteForm(state, form) {
    state.bookkeepingDebtNoteForm = form;
  },
  setBookkeepingOfflineCustomers(state, customers) {
    state.bookkeepingOfflineCustomers = customers;
  },
  setBookkeepingOfflineCustomersMeta(state, meta) {
    state.bookkeepingOfflineCustomersMeta = meta;
  },
  setBookkeepingUnspecifiedDebtDueDateCustomersMeta(state, meta) {
    state.bookkeepingUnspecifiedDebtDueDateCustomersMeta = meta;
  },
  setSelectedBookkeepingOfflineCustomer(state, customer) {
    state.selectedBookkeepingOfflineCustomer = customer;
  },
  setSelectedBookkeepingOfflineCustomerMutations(state, mutations) {
    state.selectedBookkeepingOfflineCustomerMutations = mutations;
  },
  setHasSeenMultipleDebtNotesOnboarding: (state, val) => {
    state.hasSeenMultipleDebtNotesOnboarding = val;
  },
  setHasSeenProductItemTooltip: (state, { step, value }) => {
    state.hasSeenProductItemTooltip[step] = value;
  },
  setEmptyAllBookkeepingOfflineCustomers(state, val) {
    state.emptyAllBookkeepingOfflineCustomers = val;
  },
  setPaymentLinkForm(state, form) {
    state.paymentLinkForm = form;
  },
  setSaasUser(state, user) {
    state.saasUser = user;
  },
  setDebtSnapshot(state, snapshot) {
    state.debtSnapshot = snapshot;
  },
};

export default {
  state: () => ({ ...initialState }),
  mutations,
  getters,
  actions,
};
