import Client from '~/types/api/api-module';
import ApiOptions from '../../api-options';

const MITRA_SERVICE_URL = `${process.env.API_V4_HOST}/_exclusive/mitra`;

export default Client.wrap({
  getMitraTNC(slug) {
    return this.get(`${MITRA_SERVICE_URL}/terms/${slug}`, 'user', new ApiOptions());
  },
  getMixedDanaActivationType() {
    return this.get(`${MITRA_SERVICE_URL}/me/flags`, 'user', new ApiOptions());
  },
  postMitraTNCAgreement(slug, requestPayload) {
    return this.post(`${MITRA_SERVICE_URL}/terms/${slug}`, 'user', new ApiOptions({ requestPayload }));
  },
});
