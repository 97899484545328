export default {
  trackSuperWarungVisit(basicStatus, superWarungStatus) {
    const payload = {
      evn: 'agenlite_screen_visit',
      journey_id: this.getTrackerJourneyId,
      current_screen: 'Grocery_SwStatus_Screen',
      referrer_screen: this.$route.query?.from || this.routeReferrer?.name || '',
      referrer_url: this.routeReferrer?.url || '',
      status_verifikasi_data: basicStatus,
      status_verifikasi_superwarung: superWarungStatus,
    };
    
    this.$blTracker.actions.trackActions(payload);
  },

  trackSuperWarungClick(clickSource, targetScreen, currentScreen = 'Grocery_SwStatus_Screen') {
    const payload = {
      evn: 'agenlite_wholesale_general_click',
      journey_id: this.getTrackerJourneyId,
      current_screen: currentScreen,
      referrer_screen: this.routeReferrer?.name || '',
      click_source: clickSource,
      target_screen: targetScreen,
    };
  
    this.$blTracker.actions.trackActions(payload);
  },
};
