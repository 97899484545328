import AgenliteAPIClient from '~/api';
import { transactionMapper } from '~/utils/mitra-branch-transaction-list';
import { humanizeTimestamp } from '~/server/helpers/date-helper';

const initialState = {
  mitraBranchProfile: {},
  mitraBranchStaffs: [],
  mitraBranchStaffRequest: [],
  mitraBranchDeal: null
};

const getters = {
  getMitraBranchProfile(state) {
    return state.mitraBranchProfile;
  },
  // by default mitra user has role as owner
  isOwner(state) {
    return state.mitraBranchProfile?.role === 'owner';
  },
  isMitraSubscriptionActive(state) {
    const { subscription } = state.mitraBranchProfile;
    return subscription?.status === 'active';
  },
  getMitraBranchSubscription(state) {
    const { subscription } = state.mitraBranchProfile;
    return subscription;
  },
  mitraBranchStaffs(state) {
    return state.mitraBranchStaffs;
  },
  mitraBranchStaffRequest(state) {
    return state.mitraBranchStaffRequest;
  },
  mitraBranchDeal(state) {
    return state.mitraBranchDeal;
  }
};

const mutations = {
  setMitraBranchProfile(state, profile) {
    state.mitraBranchProfile = profile;
  },
  setMitraBranchStaffs(state, staffs) {
    state.mitraBranchStaffs = staffs;
  },
  setMitraBranchStaffRequest(state, staffs) {
    state.mitraBranchStaffRequest = staffs;
  },
  setMitraBranchDeals(state, mitraBranchDeal) {
    state.mitraBranchDeal = mitraBranchDeal;
  }
};

const actions = {
  async retrieveCurrentMitraBranch({ commit, dispatch }) {
    try {
      const result = await AgenliteAPIClient.retrieveCurrentMitraBranch();
      commit('setMitraBranchProfile', result.data);
      return result.data;
    } catch (error) {
      dispatch('catchError', { error, noFlashAlert: true });
      throw error;
    }
  },
  async retrieveListOfStaffs({ dispatch, commit }) {
    try {
      const result = await AgenliteAPIClient.retrieveListOfStaffs();
      commit('setMitraBranchStaffs', result.data);
      return result.data;
    } catch (error) {
      dispatch('catchError', { error, noFlashAlert: true });
    }
  },
  async retrieveListOfRequests({ dispatch, commit }) {
    try {
      const params = {
        status: 'pending'
      };
      const result = await AgenliteAPIClient.retrieveListOfRequests(params);
      commit('setMitraBranchStaffRequest', result.data);
      return result.data;
    } catch (error) {
      dispatch('catchError', { error, noFlashAlert: true });
    }
  },
  async retrieveStaffByID({ dispatch }, staffID) {
    try {
      const result = await AgenliteAPIClient.retrieveStaffByID(staffID);
      return result.data;
    } catch (error) {
      dispatch('catchError', { error, noFlashAlert: true });
      throw error;
    }
  },
  async retrieveRequestStaffByID({ dispatch }, id) {
    try {
      const result = await AgenliteAPIClient.retrieveRequestStaffByID(id);
      return result.data;
    } catch (error) {
      dispatch('catchError', { error, noFlashAlert: true });
      throw error;
    }
  },
  async deleteStaff({ dispatch }, staffID) {
    try {
      const result = await AgenliteAPIClient.deleteStaff(staffID);
      return result.meta.http_status === 200;
    } catch (error) {
      const message = error.errors.length > 0 ? error.errors[0].message : 'Terjadi kesalahan, silakan coba lagi.';
      dispatch('showFlashAlert', {
        message,
        type: 'warning',
      });
      throw error;
    }
  },
  async updateStaffInvitationStatus({ dispatch }, { id, status }) {
    try {
      const result = await AgenliteAPIClient.updateStaffInvitationStatus({ id, status });
      return result.meta.http_status === 200;
    } catch (error) {
      const message = error.errors.length > 0 ? error.errors[0].message : 'Terjadi kesalahan, silakan coba lagi.';
      dispatch('showFlashAlert', {
        message,
        type: 'warning',
      });
      throw error;
    }
  },
  async validatePhoneNumber({ dispatch }, phoneNumber) {
    try {
      const result = await AgenliteAPIClient.validatePhoneNumber(phoneNumber);
      return result.data;
    } catch (error) {
      const message = error.errors.length > 0 ? error.errors[0].message : 'Terjadi kesalahan, silakan coba lagi.';
      dispatch('showFlashAlert', {
        message,
        type: 'warning',
      });
      throw error;
    }
  },
  async requestAddStaff({ dispatch }, payload) {
    try {
      const result = await AgenliteAPIClient.requestAddStaff(payload);
      return result.meta.http_status === 201;
    } catch (error) {
      const message = error.errors.length > 0 ? error.errors[0].message : 'Terjadi kesalahan, silakan coba lagi.';
      dispatch('showFlashAlert', {
        message,
        type: 'warning',
      });
      throw error;
    }
  },
  async updateStaff({ dispatch }, { staffID, branchName }) {
    try {
      const result = await AgenliteAPIClient.updateStaff(staffID, branchName);
      return result.meta.http_status === 200;
    } catch (error) {
      const message = error.errors.length > 0 ? error.errors[0].message : 'Terjadi kesalahan, silakan coba lagi.';
      dispatch('showFlashAlert', {
        message,
        type: 'warning',
      });
      throw error;
    }
  },
  async requestTransfer({ dispatch, getters }, { requestPayload, options }) {
    let response = null;
    const { staffId, amount } = requestPayload;
    try {
      dispatch('showAppLoading');
      const userId = getters.getUserId;
      response = await AgenliteAPIClient.sendStaffBalance({
        userId,
        staffId,
        amount,
        OTPOptions: options,
      });
      AgenliteAPIClient.setOtpKey(userId, response?.meta?.otp_key ?? '');
    } catch (error) {
      if (error?.meta?.['http_status'] === 401) throw error;
      const userId = getters.getUserId;
      const message = error?.errors[0]?.message || 'Terjadi kesalahan, silakan coba lagi.';
      AgenliteAPIClient.setOtpKey(userId, error?.meta?.otp_key ?? '');
      dispatch('showFlashAlert', {
        message,
        type: 'warning',
      });
      throw error;
    } finally {
      dispatch('hideAppLoading');
    }

    return response;
  },
  async retrieveTransactionDetailStaff({ dispatch }, id) {
    try {
      const result = await AgenliteAPIClient.retrieveTransactionDetailStaff(id);
      return result.data;
    } catch (error) {
      dispatch('catchError', { error, noFlashAlert: true });
      throw error;
    }
  },
  /**
   *
   * The function returning history transaction with grouping based on the date
   * @param {number} staffID
   * @returns array of object
   * [
   *    {
   *      "date": "20 Okt 2024",
   *      "items": [
   *          {
   *            "title": "Pulsa",
   *            "nominal": "-Rp10.500",
   *            "invoiceNumber": "INV-GR-000007TRS",
   *            "time": "10:00 WIB"
   *          }
   *      ]
   *    }
   * ]
   */
  async retrieveListOfStaffTransactions({ dispatch }, staffID) {
    try {
      const result = await AgenliteAPIClient.retrieveListOfStaffTransactions(staffID);
      const grouped = result.data.reduce((acc, item) => {
        // Extract only the date part (dd mmm yyyy) from the created_at field
        const sortDate = new Date(item.created_at);
        const displayDate = humanizeTimestamp(item.created_at, '%dd% %mmm% %yyyy%');

        // Initialize an array for each date if it doesn't exist
        if (!acc[displayDate]) {
          acc[displayDate] = { sortDate, items: [] };
        }

        // Push the item into the appropriate date array
        acc[displayDate].items.push(transactionMapper(item));

        return acc;
      }, {});

      const sortedGroupedArray = Object.entries(grouped)
        .sort(([, a], [, b]) => b.sortDate - a.sortDate) // Sort by date descending
        .map(([displayDate, { items }]) => ({ date: displayDate, items }));

      return sortedGroupedArray;
    } catch (error) {
      dispatch('catchError', { error, noFlashAlert: true });
      return [];
    }
  },
  async retrieveMitraBranchDealsId({ dispatch, commit, getters }) {
    if (getters.mitraBranchDeal) return;

    const result = await dispatch('fetchNeoConfigs', ['subscription-checkout-list']);
    const subsCheckouts = result[0]?.data;
    const mitraBranchDeals = Object.entries(subsCheckouts).find(([_key, value]) => value === 'mitra_branch');
    commit('setMitraBranchDeals', mitraBranchDeals[0]);
  }
};

export default {
  state: () => ({
    ...initialState
  }),
  getters,
  mutations,
  actions
};
