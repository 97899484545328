import r from '~/utils/render-utils';
import PageOAuthLogin from '~/pages/System/OAuthLogin';
import PageAuthorize from '~/pages/System/Authorize';
import { redirect } from '~/plugins/LocationPlugin';

const PageTopupKurirLanding = () => import(/* webpackChunkName: 'topupkurir' */ '~/pages/TopupKurir/Home');
const PageTopupKurirTopupMitra = () => import(/* webpackChunkName: 'topupkurir' */ '~/pages/TopupKurir/TopupMitra');
const PageTopupKurirSetorSaldo = () => import(/* webpackChunkName: 'topupkurir' */ '~/pages/TopupKurir/SetorSaldo');
const PageTopupKurirTransaction = () =>
  import(/* webpackChunkName: 'topupkurir' */ '~/pages/TopupKurir/Transaction/index');
const PageTopupKurirTransactionDetail = () =>
  import(/* webpackChunkName: 'topupkurir' */ '~/pages/TopupKurir/Transaction/detail');
const PageTopupKurirKomisi = () => import(/* webpackChunkName: 'topupkurir' */ '~/pages/TopupKurir/Komisi');

const topupkurir = [
  {
    path: '/kurir/login',
    component: PageOAuthLogin,
    name: 'topupkurir_login',
    beforeEnter(_to, _from, next) {
      redirect('/home');
      next(false);
    },
  },
  {
    path: '/kurir/authorize',
    component: PageAuthorize,
    name: 'topupkurir_authorize',
    beforeEnter(_to, _from, next) {
      redirect('/home');
      next(false);
    },
  },
  {
    path: '/kurir',
    component: r(PageTopupKurirLanding),
    name: 'topupkurir_home',
    beforeEnter(_to, _from, next) {
      redirect('/home');
      next(false);
    },
  },
  {
    path: '/kurir/topup-mitra',
    component: r(PageTopupKurirTopupMitra),
    name: 'topupkurir_topup_mitra',
    beforeEnter(_to, _from, next) {
      redirect('/home');
      next(false);
    },
  },
  {
    path: '/kurir/setor-saldo',
    component: r(PageTopupKurirSetorSaldo),
    name: 'topupkurir_setor_saldo',
    beforeEnter(_to, _from, next) {
      redirect('/home');
      next(false);
    },
  },
  {
    path: '/kurir/transaksi',
    component: r(PageTopupKurirTransaction),
    name: 'topupkurir_transaction',
    beforeEnter(_to, _from, next) {
      redirect('/home');
      next(false);
    },
  },
  {
    path: '/kurir/transaksi/:type/:id',
    component: r(PageTopupKurirTransactionDetail),
    name: 'topupkurir_transaction_detail',
    beforeEnter(_to, _from, next) {
      redirect('/home');
      next(false);
    },
  },
  {
    path: '/kurir/komisi',
    component: r(PageTopupKurirKomisi),
    name: 'topupkurir_komisi',
    beforeEnter(_to, _from, next) {
      redirect('/home');
      next(false);
    },
  },
];

export default topupkurir;
