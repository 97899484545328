import r from '~/utils/render-utils';

const VPTrain = () => import(/* webpackChunkName: 'vp-tix' */ '~/pages/VpTix/Train/Train');
const VPTrainRoute = () => import(/* webpackChunkName: 'vp-tix' */ '~/pages/VpTix/Train/RouteSelect');
const VPTrainScheduleList = () => import(/* webpackChunkName: 'vp-tix' */ '~/pages/VpTix/Train/ScheduleList');
const VPTrainSeatSelection = () => import(/* webpackChunkName: 'vp-tix' */ '~/pages/VpTix/Train/SeatSelection');
const VPTrainPassengerDetails = () => import(/* webpackChunkName: 'vp-tix' */ '~/pages/VpTix/Train/PassengerDetails');
const VPTrainConfirmPage = () => import(/* webpackChunkName: 'vp-tix' */ '~/pages/VpTix/Train/ConfirmPage');
const VPTrainPayment = () => import(/* webpackChunkName: 'vp-tix' */ '~/pages/VpTix/Train/Payment');
const VPTrainSuccessPage = () => import(/* webpackChunkName: 'vp-tix' */ '~/pages/VpTix/Train/TransactionSuccess');

const VPDealsRootPage = () => import(/* webpackChunkName: 'vp-tix-deals' */ '~/pages/VpTix/Deals/DealsRootPage');
const VPDealsHomePage = () => import(/* webpackChunkName: 'vp-tix-deals' */ '~/pages/VpTix/Deals/DealsHomePage');
const VPDealsSearchPage = () => import(/* webpackChunkName: 'vp-tix-deals' */ '~/pages/VpTix/Deals/DealsSearchPage');
const VPDealsDetailPage = () => import(/* webpackChunkName: 'vp-tix-deals' */ '~/pages/VpTix/Deals/DealsDetailPage');
const VPDealsDetailTNCPage = () =>
  import(/* webpackChunkName: 'vp-tix-deals' */ '~/pages/VpTix/Deals/DealsDetailTNCPage');
const VPDealsCouponsMerchantPage = () =>
  import(/* webpackChunkName: 'vp-tix-deals' */ '~/pages/VpTix/Deals/DealsCouponsMerchantPage');
const VPDealsMerchantsPage = () =>
  import(/* webpackChunkName: 'vp-tix-deals' */ '~/pages/VpTix/Deals/DealsMerchantsPage');

const vptix = [
  {
    path: '/tiket-kai/',
    component: r(VPTrain),
    children: [
      { path: '', component: r(VPTrainRoute), name: 'vp_tix_train' },
      { path: 'jadwal', component: r(VPTrainScheduleList), name: 'vp_tix_train_schedule' },
      { path: 'pilih-kursi', component: r(VPTrainSeatSelection), name: 'vp_tix_train_seat_selection' },
      { path: 'data-penumpang', component: r(VPTrainPassengerDetails), name: 'vp_tix_train_passenger_details' },
      { path: 'confirm', component: r(VPTrainConfirmPage), name: 'vp_tix_train_confirm' },
      { path: 'checkout', component: r(VPTrainPayment), name: 'vp_tix_train_checkout' },
      { path: 'checkout/success', component: r(VPTrainSuccessPage), name: 'vp_tix_train_transaction_success' },
    ],
  },
  {
    path: '/kupon/',
    component: r(VPDealsRootPage),
    children: [
      { path: '', component: r(VPDealsHomePage), name: 'vp_tix_kupon' },
      { path: 'search', component: r(VPDealsSearchPage), name: 'vp_tix_kupon_search' },
      { path: 'merchants', component: r(VPDealsMerchantsPage), name: 'vp_tix_kupon_all_merchants' },
      { path: 'merchant/:merchantId', component: r(VPDealsCouponsMerchantPage), name: 'vp_tix_kupon_merchant' },
      { path: ':slug', component: r(VPDealsDetailPage), name: 'vp_tix_kupon_detail' },
      { path: ':slug/checkout', component: r(VPDealsDetailPage), name: 'vp_tix_kupon_detail_checkout' },
      { path: ':slug/syarat-ketentuan', component: r(VPDealsDetailTNCPage), name: 'vp_tix_kupon_tnc' },
      { path: '*', redirect: { name: 'vp_tix_kupon' } },
    ],
  },
];

export default vptix;
