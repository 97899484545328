import Client from '~/types/api/api-module';
import ApiOptions from '../../api-options';

const BASE_URL = `${process.env.API_V4_HOST}/_exclusive/mitra-qr/qris-cash-out/transactions`;

export default Client.wrap({
  getQrisCashOutTransaction(id: number) {
    return this.get(`${BASE_URL}/${id}`, 'user', new ApiOptions());
  },
});
