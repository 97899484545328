import AgenliteAPIClient from '~/api';

const initialState = {
  bankList: [],
  bankAccounts: [],
};

const moduleGetters = {
  getBankList: state => state.bankList,
  getBankAccounts: state => state.bankAccounts,
};

const actions = {
  async fetchBankList({ commit, dispatch }) {
    try {
      const bankList = await AgenliteAPIClient.fetchBankList();
      commit('setBankList', bankList.data);
    } catch (error) {
      const message = 'Terjadi kesalahan ketika memuat daftar bank.';
      dispatch('catchError', { error, message, hideAppLoading: true });
      throw error;
    } finally {
      dispatch('hideAppLoading');
      dispatch('hideFlashAlert');
    }
  },
  async fetchBankAccounts({ commit, dispatch }) {
    try {
      const response = await AgenliteAPIClient.fetchBankAccounts();
      commit('setBankAccounts', response.data || {});
    } catch (error) {
      const message = 'Terjadi kesalahan ketika memuat daftar bank.';
      dispatch('catchError', { error, message, hideAppLoading: true });
      throw error;
    } finally {
      dispatch('hideAppLoading');
      dispatch('hideFlashAlert');
    }
  },

  async fetchSingleBankAccount({ dispatch }, id) {
    try {
      const bankAccount = await AgenliteAPIClient.fetchSingleBankAccount(id);
      return bankAccount;
    } catch (error) {
      const message = 'Terjadi kesalahan ketika memuat daftar bank.';
      dispatch('catchError', { error, message, hideAppLoading: true });
      throw error;
    } finally {
      dispatch('hideAppLoading');
      dispatch('hideFlashAlert');
    }
  },

  addBankAccount({ getters }, whichBank) {
    const { bank, number, name } = whichBank;
    const userId = getters.getCurrentAgent.user_id;
    return AgenliteAPIClient.addBankAccount({ bank, number, name }, userId);
  },
  removeBankAccount(_, entryId) {
    return AgenliteAPIClient.removeBankAccount(entryId);
  },
  requestSendMoney({ dispatch, getters }, data) {
    const userId = getters.getCurrentAgent.user_id;
    dispatch('showAppLoading');
    return new Promise((resolve, reject) => {
      AgenliteAPIClient.requestSendMoney(data, userId)
        .then(response => {
          dispatch('hideFlashAlert');
          dispatch('finishTransaction', { status: true });
          resolve(response);
        })
        .catch(error => {
          if (error.errors !== undefined && error.errors[0].code === 10203) {
            reject(error);
          } else {
            const message =
              'Pengiriman uang gagal. Cek <a href="/transaksi">daftar transaksi</a> sebelum mengulang transaksi.';
            dispatch('catchError', { error, message, finishTransaction: true });
            reject(error);
          }
        });
    });
  },
};

const mutations = {
  setBankList(state, bankList) {
    state.bankList = bankList;
  },
  setBankAccounts(state, bankAccounts) {
    state.bankAccounts = bankAccounts;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  actions,
  mutations,
};
