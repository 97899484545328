import Cookies from 'js-cookie';
import credentialStorageInstance from '~/api/credential-storage-instance';

const RequestBuilder = {
  getOtpHeader(otpKey, options = {}) {
    const otpHeader = {
      ...(otpKey && { 'Bukalapak-OTP-Key': otpKey }),
      ...(options?.otpCode && { 'Bukalapak-OTP': options.otpCode }),
      ...(options?.otpCode && { 'Bukalapak-Phone-Token': options.otpCode }),
      ...(options?.method && { 'Bukalapak-OTP-Method': options.method }),
    };

    if (otpKey || options?.otpCode || options?.method) {
      if (Cookies.get('otp_key_temp')) {
        otpHeader['Bukalapak-OTP-Device-ID'] = Cookies.get('device_id_temp');
        otpHeader['Bukalapak-Identity'] = Cookies.get('device_id_temp');
      } else {
        otpHeader['Bukalapak-OTP-Device-ID'] = credentialStorageInstance.identity;
        otpHeader['Bukalapak-Identity'] = credentialStorageInstance.identity;
      }
    }
    const browserFingerprint = window.localStorage.getItem('browser_fp');
    if (browserFingerprint) {
      otpHeader['Bukalapak-Device-Fingerprint'] = browserFingerprint;
    }
    return otpHeader;
  },
  getBpjsPayload(customerNumber, year, month) {
    return {
      customer_number: customerNumber,
      paid_until: { year, month },
    };
  },
  getTrainTransactionPayload(payload) {
    const trains = payload.trains.map(t => ({
      date: t.date,
      no: t.train.no,
      class: t.class,
      subclass: t.subclass,
      from: t.from,
      to: t.to,
    }));

    const { name, contactEmail, contactPhone } = payload.contact;
    const contact = {
      name,
      email: contactEmail,
      phone: contactPhone,
    };

    const passengers = payload.passengers.map((p, i) => ({
      name: p.name,
      type: p.type,
      id_no: p.idNum,
      phone: `0${811111111111 + i}`, // apparently, it requires phone number.., yes it is.
    }));

    return {
      trains,
      contact,
      passengers,
    };
  },
  getTrainSeatChangePayload(payload) {
    const { trx, passengers } = payload;
    const step = ['departure', 'arrival'];

    const books = trx.bookings.map((b, i) => {
      const wagonNo = [];
      const wagonCode = [];
      const seatName = [];
      passengers.forEach(p => {
        wagonNo.push(p[`${step[i]}Wagon`].no);
        wagonCode.push(p[`${step[i]}Wagon`].code);
        seatName.push(p[`${step[i]}Seat`].name);
      });
      return {
        book_code: b.book_code,
        wagon_no: wagonNo,
        wagon_code: wagonCode,
        seat_name: seatName,
      };
    });

    return {
      books,
    };
  },
  VOCPayloadBuilder(type, paymentMethod, vocOptionsPayload, vocPayload) {
    return {
      transaction_type: type,
      method: paymentMethod,
      voucher_code: vocOptionsPayload.voucherCode,
      transaction_id: vocOptionsPayload.transactionId,
      final_amount: vocOptionsPayload.finalAmount,
      payload: this.VOCVPPayloadBuilder(type, vocPayload),
    };
  },
  VOCVPPayloadBuilder(type, vocPayload) {
    let payload = {};
    switch (type) {
      case 'phone-credit-prepaid':
        payload = this.VOCPhoneCreditPayload(vocPayload);
        break;
      case 'data-plan-prepaid':
        payload = this.VOCDataPlanPayload(vocPayload);
        break;
      case 'electricity-prepaid':
        payload = this.VOCElectricityPrepaidPayload(vocPayload);
        break;
      case 'motorcycle-insurance':
        payload = this.VOCMotorcycleInsurancePayload(vocPayload);
        break;
      case 'coupon-deals':
        payload = this.VOCDealsPayload(vocPayload);
        break;
      default:
        break;
    }
    return payload;
  },
  VOCMotorcycleInsurancePayload(vocPayload) {
    return {
      product_id: vocPayload.productId,
      policy_holder_phone: vocPayload.policyHolderPhone,
    };
  },
  VOCDealsPayload(vocPayload) {
    return {
      deal_id: vocPayload.dealId,
      skus: vocPayload.skus,
      recipient: vocPayload.recipient,
    };
  },
  VOCPhoneCreditPayload(vocPayload) {
    return {
      package_id: vocPayload.id,
      phone_number: vocPayload.phone,
    };
  },
  VOCDataPlanPayload(vocPayload) {
    return {
      package_id: vocPayload.id,
      phone_number: vocPayload.phone,
    };
  },
  VOCElectricityPrepaidPayload(vocPayload) {
    return {
      customer_number: vocPayload.customerNumber,
      product_id: vocPayload.productId,
    };
  },
};

export default RequestBuilder;
