import r from '~/utils/render-utils';

const RegIndex = () => import(/* webpackChunkName: 'registration' */ '~/pages/register/index');
const RegPhone = () => import(/* webpackChunkName: 'registration' */ '~/pages/register/RegPhone');
const RegRegistered = () => import(/* webpackChunkName: 'registration' */ '~/pages/register/RegRegistered');
const RegForm = () => import(/* webpackChunkName: 'registration' */ '~/pages/register/RegForm');

const register = [
  {
    path: '/register',
    component: r(RegIndex),
    children: [
      {
        path: '',
        component: r(RegPhone),
        name: 'register_phone',
      },
      {
        path: 'registered',
        component: r(RegRegistered),
        name: 'register_registered',
      },
      {
        path: 'form',
        component: r(RegForm),
        name: 'register_form',
      },
    ],
  },
];

export default register;
