import {
  PublicGetInvoiceGroupResponse,
  PublicListBillingFeeConfigsResponse,
} from '@bukalapak/openapi-schema/reusable/payment/v1/public/payment-domain';
import {
  PublicCreateTopupCheckoutDraftRequest,
  PublicValidateTopupCheckoutDraftRequest,
  PublicFinalizeTopupCheckoutDraftRequest,
  PublicCreateTopupCheckoutDraftResponse,
  PublicValidateTopupCheckoutDraftResponse,
  PublicFinalizeTopupCheckoutDraftResponse,
  SharedTopupCheckoutDraftValidationPayload,
  SharedExpandablePaymentValidationPayload,
} from '@bukalapak/openapi-schema/reusable/payment/v2/public/payment-domain';
import accessTokenManagerInstance from '~/api/v4/access-token-manager-instance';
import wrapSecureClientInterface from './v4';

import PaymentDomainInvoiceAPI from './v4/modules/payment-domain/invoice-api';
import PaymentDomainBillingAPI from './v4/modules/payment-domain/billing-api';
import PaymentDomainTopupAPI from './v4/modules/payment-domain/topup-api';

const PaymentDomainAPIClient = {
  v4: {
    accessTokenManager: accessTokenManagerInstance,
  } as any,

  init() {
    this.v4.paymentDomainInvoice = wrapSecureClientInterface(
      accessTokenManagerInstance,
      PaymentDomainInvoiceAPI,
      'payment_domain_invoice'
    );
    this.v4.paymentDomainBilling = wrapSecureClientInterface(
      accessTokenManagerInstance,
      PaymentDomainBillingAPI,
      'payment_domain_billing'
    );
    this.v4.paymentDomainTopup = wrapSecureClientInterface(
      accessTokenManagerInstance,
      PaymentDomainTopupAPI,
      'payment_domain_topup'
    );
  },
  getInvoiceDetails(invoiceGroupNumber: string): PublicGetInvoiceGroupResponse {
    return this.v4.paymentDomainInvoice.getInvoiceDetails(invoiceGroupNumber);
  },
  createTopupCheckoutDraft(payload): PublicCreateTopupCheckoutDraftResponse {
    const requestPayload: PublicCreateTopupCheckoutDraftRequest = {
      amount: payload.amount,
      currency: payload.currency,
    };
    return this.v4.paymentDomainTopup.createTopupCheckoutDraft(requestPayload);
  },
  validateTopupCheckoutDraft(payload): PublicValidateTopupCheckoutDraftResponse {
    const expandablePaymentValidationPayload: SharedExpandablePaymentValidationPayload = {
      payment_group_id: payload.payment_group_id,
      payment_method_id: payload.payment_method_id,
    };

    const validationPayload: SharedTopupCheckoutDraftValidationPayload = {
      expandable_payment: expandablePaymentValidationPayload,
    };

    const requestPayload: PublicValidateTopupCheckoutDraftRequest = {
      session_id: payload.session_id,
      validation_payload: validationPayload,
    };
    return this.v4.paymentDomainTopup.validateTopupCheckoutDraft(requestPayload);
  },
  finalizeTopupCheckoutDraft(payload): PublicFinalizeTopupCheckoutDraftResponse {
    const requestPayload: PublicFinalizeTopupCheckoutDraftRequest = {
      session_id: payload.session_id,
    };
    return this.v4.paymentDomainTopup.finalizeTopupCheckoutDraft(requestPayload);
  },
  getFeeConfigs(payload): PublicListBillingFeeConfigsResponse {
    const requestPayload = {
      payment_methods: payload.paymentTypes,
      metadata: JSON.stringify({ product_type: payload.productType }),
    };
    return this.v4.paymentDomainBilling.getFeeConfigs(requestPayload);
  },
};

PaymentDomainAPIClient.init();

export default PaymentDomainAPIClient;
