import ApiOptions from '~/api/v4/api-options';
import Client from '~/types/api/api-module';

const VENDORS_URL = `${process.env.API_VPNONTRADER_HOST}/v1/vendors`;

export default Client.wrap({
  getVendors(requestPayload) {
    return this.get(VENDORS_URL, 'product.*.*', { enableAuthHeader: true, ...new ApiOptions({ requestPayload }) });
  },
});
