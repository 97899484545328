import AgenliteAPIClient from '~/api';

export default {
  trackBukaBantuanLiveChat(data) {
    const userId = AgenliteAPIClient.getCurrentUserIdV4();
    const payload = {
      evn: `live_chat_mitra`,
      ui: userId.toString() || '',
      entry_point: data.entryPoint,
    };
    this.$blTracker.actions.trackActions(payload);
  },
};
