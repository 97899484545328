import { Plugin } from '@nuxt/types';

export interface A2HS {
  event?: Event;
  ready: boolean;
}

export const A2HSPlugin: Plugin = (_, inject) => {
  const a2hsMeta: A2HS = { ready: false };
  window.addEventListener('beforeinstallprompt', event => {
    console.warn('Disabling A2HS prompt.');
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    event.preventDefault();
    // Stash the event so it can be triggered later.
    Object.assign(a2hsMeta, {
      event,
      ready: true,
    });
  });
  inject('a2hs', a2hsMeta);
};

export default A2HSPlugin;
