<template lang="pug">
  transition(name="c-remodal-transition")
    .c-remodal(v-if="visible", :class="{ '`c-remodal--${size}`': size !== 'default' }", :style="{ height: window.height + 'px' }")
      .c-remodal__wrapper(@mousedown.stop="onBackgroundClick", @touchstart.stop="onBackgroundClick")
      .c-remodal__content(ref="modal" :class="{ rounded: isRounded }" :style="{ 'vertical-align': position }")
        button.c-remodal__close(v-if="showCloseBtn", type="button", @click="toggle(false)", v-html="'&times;'" ref="remodal-close")
        //- (@mousedown.stop, @touchstart.stop)
        .c-remodal__panel
          .c-remodal__panel__head(v-if="title")
            .c-remodal__panel__title(ref="remodal-title") {{ title }}
          .c-remodal__panel__body(ref="body-slot")
            slot
          .c-remodal__panel__footer(v-if="hasFooter")
            slot(name="footer")
</template>

<script>
import remodalEventBus from './remodalEventBus';

export default {
  name: 'BLCRemodal',
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'default',
      validator(value) {
        const validValues = ['small', 'default', 'medium', 'large'];
        return validValues.includes(value);
      },
    },
    showCloseBtn: {
      type: Boolean,
      default: true,
    },
    overlayClickToClose: {
      type: Boolean,
      default: true,
    },
    delay: {
      type: Number,
      default: 0,
    },
    isRounded: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'middle',
      validator(value) {
        const validValues = ['top', 'middle', 'bottom'];
        return validValues.includes(value);
      },
    },
  },
  data() {
    return {
      visible: false,
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  computed: {
    hasFooter() {
      return !!this.$slots.footer;
    },
  },
  watch: {
    /**
     * Sets the visibility of overlay and modal.
     * Events 'opened' and 'closed' is called here
     * inside `setTimeout` and `$nextTick`, after the DOM changes.
     * This fixes `$refs.modal` `undefined` bug
     */
    visible(value) {
      if (value) {
        // this.visibility.overlay = true;

        setTimeout(() => {
          // this.visibility.modal = true;
          this.$nextTick(() => {
            this.callAfterEvent(true);
          });
        }, this.delay);
      } else {
        // this.visibility.modal = false;

        setTimeout(() => {
          // this.visibility.overlay = false;
          this.$nextTick(() => {
            this.callAfterEvent(false);
          });
        }, this.delay);
      }
    },
  },
  beforeMount() {
    remodalEventBus.$on('toggle', (name, state) => {
      if (name === this.name) {
        // if undefine, state toggle the modal
        const toggleState = typeof state === 'undefined' ? !this.visible : state;
        this.toggle(toggleState);
      }
    });

    window.addEventListener('resize', this.onWindowResize);

    this.onWindowResize();
  },
  methods: {
    toggle(state) {
      const beforeEventName = this.visible ? 'before-close' : 'before-open';

      let stopEventExecution = false;

      const stop = () => {
        stopEventExecution = true;
      };

      const beforeEvent = this.genEventObject({ stop, state });

      this.$emit(beforeEventName, beforeEvent);

      if (!stopEventExecution) {
        if (state) {
          document.documentElement.style.overflowY = 'hidden';
        } else {
          document.documentElement.style.overflowY = '';
          this.$emit('close');
        }
        this.visible = state;
        // after events are called in `watch.visible`
      }
    },
    genEventObject(params) {
      const eventData = {
        name: this.name,
        timestamp: Date.now(),
        ref: this.$refs.modal,
      };

      return Object.assign(eventData, params || {});
    },
    callAfterEvent(state) {
      const afterEventName = state ? 'opened' : 'closed';

      const afterEvent = this.genEventObject({ state });

      this.$emit(afterEventName, afterEvent);
    },
    onBackgroundClick() {
      if (this.overlayClickToClose) {
        this.toggle(false);
      }
    },
    onWindowResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
// ==========================================================================
// Remodal
// ==========================================================================

$remodal-width--small: 200px;
$remodal-width: 400px;
$remodal-width--medium: 600px;
$remodal-width--large: 800px;
$remodal-index: 99999;
$remodal-spacing: 24px;
$remodal-spacing--small: 20px;
$remodal-close-btn-color: #333;
$remodal-close-btn-font-size: 24px;
$remodal-backdrop-bgcolor: #000;
$remodal-panel-bgcolor: #fff;
$remodal-border-color: #ddd;
$remodal-border-radius: 2px;
$remodal-head-bgcolor: #fafafa;
$remodal-title-font-size: 18px;
$remodal-title-font-size--small: 16px;

.c-remodal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $remodal-index;
  overflow: hidden;
}

.c-remodal__wrapper {
  position: fixed;
  z-index: $remodal-index + 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;

  display: block;
  padding: ($remodal-spacing--small / 2) ($remodal-spacing--small / 2) 0;

  background-color: rgba($remodal-backdrop-bgcolor, 0.4);
  text-align: center;
  -webkit-overflow-scrolling: touch;
  backface-visibility: hidden;

  &:after {
    display: inline-block;
    height: 100%;
    margin-left: -0.05em;
    content: '';
  }

  @media only screen and (min-width: 1024px) {
    padding: $remodal-spacing $remodal-spacing 0;
  }
}

.c-remodal__content {
  position: relative;
  z-index: $remodal-index + 2;
  margin-bottom: $remodal-spacing;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px 10px 0;

  display: inline-block;
  width: 100%;
  max-width: $remodal-width;

  text-align: left;

  &.rounded {
    border-radius: 8px;
    overflow: hidden;
  }
}

.c-remodal__wrapper:after {
  vertical-align: middle;
}

.c-remodal__close {
  position: absolute;
  top: 6px;
  right: 14px;
  z-index: 1;
  background: none;
  outline: 0;
  border: 0;
  cursor: pointer;
  color: $remodal-close-btn-color;
  opacity: 0.4;
  font-size: $remodal-close-btn-font-size;

  @media only screen and (min-width: 1024px) {
    top: 10px;
    right: 18px;
  }
}

.c-remodal__panel {
  background-color: $remodal-panel-bgcolor;

  position: relative;

  width: 100%;

  border-radius: $remodal-border-radius;

  &__head {
    position: relative;
    display: block;
    padding: 16px 36px 16px 20px;

    background-color: $remodal-head-bgcolor;
    border-bottom: 1px solid $remodal-border-color;

    border-radius: $remodal-border-radius $remodal-border-radius 0 0;

    @media only screen and (min-width: 1024px) {
      padding: 18px 36px 18px 24px;
    }
  }

  &__title {
    font-size: $remodal-title-font-size--small;
    font-weight: bold;
    line-height: 1;

    @media only screen and (min-width: 1024px) {
      font-size: $remodal-title-font-size;
    }
  }

  &__body {
    display: block;
    padding: $remodal-spacing--small;

    &:before,
    &:after {
      content: ' ';
      display: table;
    }
    &:after {
      clear: both;
    }

    @media only screen and (min-width: 1024px) {
      padding: $remodal-spacing;
    }
  }

  &__footer {
    position: relative;

    display: block;
    padding: 16px 20px;

    background-color: $remodal-panel-bgcolor;
    border-top: 1px solid $remodal-border-color;
    border-radius: 0 0 $remodal-border-radius $remodal-border-radius;

    @media only screen and (min-width: 1024px) {
      padding: 18px 24px;
    }
  }

  &:after {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    content: ' ';
    background-color: white;
    opacity: 0;
    pointer-events: none;
  }
}

//
// Remodal Size Variant (Desktop only)
// ==========================================================================

@media only screen and (min-width: 1024px) {
  .c-remodal--small .c-remodal__content {
    max-width: $remodal-width--small;
  }

  .c-remodal--medium .c-remodal__content {
    max-width: $remodal-width--medium;
  }

  .c-remodal--large .c-remodal__content {
    max-width: $remodal-width--large;
  }
}

//
// Remodal transition
// ==========================================================================

.c-remodal-transition-enter,
.c-remodal-transition-leave-to {
  opacity: 0;
  .c-remodal__content {
    transform: scale(0.83);
  }

  .c-remodal__panel:after {
    opacity: 1;
  }
}

.c-remodal-transition-leave,
.c-remodal-transition-enter-to {
  opacity: 1;
  .c-remodal__content {
    transform: scale(1);
  }
}

.c-remodal-transition-enter-active,
.c-remodal-transition-leave-active {
  transition: opacity 0.34s cubic-bezier(0.365, 0.585, 0.19, 0.99);
  .c-remodal__panel:after {
    transition: opacity 0.14s ease-out 0.06s;
  }
  .c-remodal__content {
    transition: all 0.2s ease-out;
  }
}

.c-remodal-transition-leave-active {
  .c-remodal__panel:after {
    transition: opacity 0.07s linear;
    box-shadow: 0 0 8px 3px white;
    opacity: 1;
    pointer-events: visible;
  }
}
</style>
