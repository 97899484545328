import { Plugin } from '@nuxt/types';

export const SWRegisterPlugin: Plugin = async (_, inject) => {
  try {
    const registrarList = await navigator.serviceWorker?.getRegistrations();
    inject('sw', registrarList);
    // eslint-disable-next-line
    console.log('Worker installed for ', registrarList.map(sw => sw.scope).join(','));
  } catch (error) {
    console.warn('Service worker is unexpectedly closed.', error);
  }
};

export default SWRegisterPlugin;
