import { ExplanationState } from '~/utils/promise-explanation';

/**
 * @module vuex/promise-explanation
 *
 */

export interface IExplanation<T = any> {
  promise: Promise<T>;
  isFinished: boolean;
  createdAt: Number;
  finishedAt: Number | undefined;
  state: ExplanationState;
}

export class VuexExplanation<T = any> implements IExplanation<T> {
  promise: Promise<T>;
  isFinished: boolean;
  createdAt: Number;
  finishedAt: Number | undefined;
  state: ExplanationState;

  constructor(promise?: Promise<T>) {
    this.promise = promise ?? new Promise<T>(resolve => resolve([] as any));
    this.createdAt = Date.now();
    this.finishedAt = Date.now();
    this.isFinished = true;
    this.state = 'resolved';
  }
}

/**
 * Generate a state/condition of specified promise functionally (pure immutable)
 *
 * @export
 * @param {Promise<any>} promise - A promise to explain
 * @param {Function} mutator - a function to mutate after state changes
 * @returns {Promise<void>}
 */
export async function createExplanation<T>(promise: Promise<T>, mutator: (P: IExplanation<T>) => void): Promise<void> {
  const initPayload: IExplanation = {
    createdAt: Date.now(),
    promise,
    isFinished: false,
    state: 'pending',
    finishedAt: undefined,
  };

  try {
    mutator(initPayload);
    await promise;
    const payload: IExplanation = {
      ...initPayload,
      isFinished: true,
      state: 'resolved',
      finishedAt: Date.now(),
    };
    mutator(payload);
  } catch (err) {
    const payload: IExplanation = {
      ...initPayload,
      isFinished: true,
      state: 'rejected',
      finishedAt: Date.now(),
    };
    mutator(payload);
  }
}
