import { RouteConfig } from '@nuxt/types/config/router';
import r from '~/utils/render-utils';

const PromotionMediaIndex = () => import(/* webpackChunkName: 'promotion-media' */ '~/pages/PromotionMedia/index.vue');
const PromotionMediaLanding = () => import(/* webpackChunkName: 'promotion-media-landing' */ '~/pages/PromotionMedia/Landing/index.vue');
const PromotionMediaVpCatalogLanding = () => import(/* webpackChunkName: 'promotion-media-vp-catalog-landing' */ '~/pages/PromotionMedia/VpCatalog/Landing.vue');
const Catalog = () => import(/* webpackChunkName: 'promotion-media-catalog' */ '~/pages/PromotionMedia/Catalog/index.vue');
const CatalogProduct = () => import(/* webpackChunkName: 'promotion-media-catalog-product' */ '~/pages/PromotionMedia/Catalog/Product.vue');
const Banner = () => import(/* webpackChunkName: 'promotion-media-banner' */ '~/pages/PromotionMedia/Banner/index.vue');
const BusinessCard = () => import(/* webpackChunkName: 'business-card' */ '~/pages/PromotionMedia/BusinessCard/index.vue');
const VpCatalog = () => import(/* webpackChunkName: 'promotion-media-vp-catalog' */ '~/pages/PromotionMedia/VpCatalog/Form.vue');

const PromotionMediaRoutes: RouteConfig[] = [
  {
    path: '/promotion-media',
    component: r(PromotionMediaIndex),
    children: [
      {
        path: '',
        component: r(PromotionMediaLanding),
        name: 'promotion_media_landing',
        meta: {
          hideAppHeader: true,
          trackAdjustVisit: 'visitMediaPromotion',
        },
      },
      {
        path: 'catalog/new',
        alias: 'catalog',
        component: r(Catalog),
        name: 'promotion_media_catalog_new',
        meta: {
          hideAppHeader: true,
          isAddAction: true,
          type: 'normal',
        },
      },
      {
        path: 'catalog/:id',
        component: r(Catalog),
        name: 'promotion_media_catalog_edit',
        meta: {
          hideAppHeader: true,
          isAddAction: false,
          type: 'normal',
        },
      },
      {
        path: 'catalog/product/new',
        alias: 'catalog/product',
        component: r(CatalogProduct),
        name: 'promotion_media_catalog_product_new',
        meta: {
          hideAppHeader: true,
          isAddAction: true,
          type: 'normal',
        },
      },
      {
        path: 'catalog/product/:idx',
        component: r(CatalogProduct),
        name: 'promotion_media_catalog_product_edit',
        meta: {
          hideAppHeader: true,
          isAddAction: false,
          type: 'normal',
        },
      },

      {
        path: 'catalog_without_image/new',
        alias: 'catalog',
        component: r(Catalog),
        name: 'promotion_media_catalog_without_image_new',
        meta: {
          hideAppHeader: true,
          isAddAction: true,
          type: 'without_image',
        },
      },
      {
        path: 'catalog_without_image/:id',
        component: r(Catalog),
        name: 'promotion_media_catalog_without_image_edit',
        meta: {
          hideAppHeader: true,
          isAddAction: false,
          type: 'without_image',
        },
      },
      {
        path: 'catalog_without_image/product/new',
        alias: 'catalog/product',
        component: r(CatalogProduct),
        name: 'promotion_media_catalog_without_image_product_new',
        meta: {
          hideAppHeader: true,
          isAddAction: true,
          type: 'without_image',
        },
      },
      {
        path: 'catalog_without_image/product/:idx',
        component: r(CatalogProduct),
        name: 'promotion_media_catalog_without_image_product_edit',
        meta: {
          hideAppHeader: true,
          isAddAction: false,
          type: 'without_image',
        },
      },
      {
        path: 'banner/new',
        alias: 'banner',
        component: r(Banner),
        name: 'promotion_media_banner_new',
        meta: {
          hideAppHeader: true,
          isAddAction: true,
        },
      },
      {
        path: 'banner/:id',
        component: r(Banner),
        name: 'promotion_media_banner_edit',
        meta: {
          hideAppHeader: true,
          isAddAction: false,
        },
      },
      {
        path: 'business_card/new',
        alias: 'business_card',
        component: r(BusinessCard),
        name: 'promotion_media_business_card_new',
        meta: {
          hideAppHeader: true,
          isAddAction: true,
        },
      },
      {
        path: 'business_card/:id',
        component: r(BusinessCard),
        name: 'promotion_media_business_card_edit',
        meta: {
          hideAppHeader: true,
          isAddAction: false,
        },
      },
      {
        path: 'vp_catalog/landing',
        component: r(PromotionMediaVpCatalogLanding),
        name: 'promotion_media_vp_catalog_landing',
        meta: {
          hideAppHeader: true,
          trackAdjustVisit: 'visitVpCatalogLanding',
        },
      },
      {
        path: 'vp_catalog/:catalog_type/new',
        component: r(VpCatalog),
        name: 'promotion_media_vp_catalog_new',
        meta: {
          hideAppHeader: true,
          isAddAction: true,
          type: 'normal',
        },
      },
      {
        path: 'vp_catalog/:catalog_type/:id',
        component: r(VpCatalog),
        name: 'promotion_media_vp_catalog_edit',
        meta: {
          hideAppHeader: true,
          isAddAction: false,
          type: 'normal',
        },
      },
    ]
  }
];

export default PromotionMediaRoutes;
