import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const RETENTION_URL = `${process.env.API_V4_HOST}/agent-retention`;
const LEADERBOARD_URL = `${RETENTION_URL}/leaderboard`;

export default Client.wrap({
  retrieveLeaderboardRankings() {
    return this.get(`${LEADERBOARD_URL}/rankings`, 'user', new ApiOptions());
  },
  retrieveLeaderboard() {
    return this.get(`${LEADERBOARD_URL}/active-board`, 'user', new ApiOptions());
  },
  retrieveUserDetail() {
    return this.get(`${LEADERBOARD_URL}/users/me`, 'user', new ApiOptions());
  },
});
