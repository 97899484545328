import { Client } from '~/types/api/api-module';
import ApiOptions from '~/api/v4/api-options';

const HELPS_URL = `${process.env.API_V4_HOST}/_exclusive/helps`;

export default Client.wrap({
  /**
   * https://blueprint.bukalapak.io/exclusive/t/helps~bukabantuan~announcement~get~exclusive~helps~bukabantuan-announcements
   */
  fetchBukaBantuanAnnouncements() {
    const requestPayload = {
      limit: 5,
      platform: 'mitra',
    };
    return this.get(`${HELPS_URL}/bukabantuan-announcements`, 'public', new ApiOptions({ requestPayload }));
  },
});
