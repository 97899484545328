import { Plugin } from '@nuxt/types';
import Cookies from 'js-cookie';
import { generateAccessTokenObject } from '~/utils/auth-utils';
import credentialStorageInstance from '~/api/credential-storage-instance';

// TODO: This AuthInit only executed once, and only supports client-side
const AuthInit: Plugin = async ({ app, store, req }) => {
  const cookieList = [
    'TEMP-Token-V4',
    'TEMP-RefreshToken-V4',
    'TEMP-CreatedAt',
    'TEMP-UserId',
    'TEMP-DeviceId',
    'TEMP-RefreshUrl',
    'TEMP-ServiceOrigin',
    'TEMP-ExpiresIn',
    'TEMP-BukuMitraVersion',
  ];
  const authData = cookieList.reduce((acc, cur) => {
    acc[cur] = Cookies.get(cur);
    return acc;
  }, {});

  if (!(authData['TEMP-Token-V4'] && authData['TEMP-UserId'] && authData['TEMP-DeviceId'])) {
    credentialStorageInstance.fetchIdentity();
    await store.dispatch('initSharedConfig', { originalUrl: req?.originalUrl, force: false });
    return;
  }

  // Autologin user with dem credentials
  const jwtToken = authData['TEMP-Token-V4'];
  app.$accessToken.storage.token = {
    ...generateAccessTokenObject(jwtToken),
    refresh_token: authData['TEMP-RefreshToken-V4'],
    userId: Number.parseInt(authData['TEMP-UserId'], 10),
    scope: 'public user store product.*.* transaction.*.* subsidy.*.* payment.*.* insurance.*.*', // force scope as wide as possible for now
    refresh_url: authData['TEMP-RefreshUrl'],
    service_origin: authData['TEMP-ServiceOrigin'],
    bukumitra_version: authData['TEMP-BukuMitraVersion'],
  };

  credentialStorageInstance.enableWebviewLogin(authData['TEMP-DeviceId']); // get identity from apps
  store.commit('setLoggedIn', true);
  store.commit('retrieveCurrentAgent', null); // Prevent cache from loading previous user data

  await store.dispatch('initSharedConfig', { originalUrl: req?.originalUrl, force: false });
  store
    .dispatch('retrieveCurrentAgent', { forceCommit: true })
    .catch(err => {
      console.warn(err);
    })
    .finally(() => {
      // Cleanup cookies so it won't pollute bukalapak cookies
      cookieList.forEach(label => {
        Cookies.remove(label, {
          path: '/',
        });
      });
    });
};

export default AuthInit;
