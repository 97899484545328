import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const PAYMENT_URL = `${process.env.API_HOST}/payments`;

export default Client.wrap({
  makePaymentByChannel(invoiceId, requestHeaders, paymentChannel = 'wallet', paymentMethod = '') {
    const requestPayload = {
      ...(paymentMethod && paymentMethod !== '' && { method: paymentMethod }),
    };
    return this.post(
      `${PAYMENT_URL}/${invoiceId}/${paymentChannel}`,
      'user',
      new ApiOptions({ requestHeaders, requestPayload })
    );
  },
  /** @deprecated */ payWithBukalapakAgent(invoiceId, offlineId, shareAmount, otp, requestHeaders) {
    const requestPayload = {
      payer_id: offlineId,
      share_amount: shareAmount,
      ...(otp?.length > 0 && { payer_otp: otp }),
    };
    return this.post(
      `${PAYMENT_URL}/${invoiceId}/bukalapak-agent`,
      'user',
      new ApiOptions({ requestHeaders, requestPayload })
    );
  },
});
