import ApiOptions from '../api-options';

const GENERAL_TRADE_URL = `${process.env.API_V4_HOST}/general-trade`;
const USER_LABEL_URL = `${GENERAL_TRADE_URL}/user-labels`;

export default {
  createUserLabels(requestPayload) {
    return this.post(`${USER_LABEL_URL}`, 'user', new ApiOptions({ requestPayload }));
  },
};
