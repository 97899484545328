// import AgenliteAPIClient from '~/api';
// import SilkroadAPIClient from '~/api/silkroad';

// this function returns true if pattern has the same length as prefix
function checkPrefix(string, pattern) {
  for (let i = 0; i < string.length; i += 1) {
    if (string[i] !== pattern[i]) {
      return false;
    }
  }
  return true;
}

// this function returns an index of the string
// that has the same prefix as the key
function getPrefixStringIndex(array, key) {
  for (let i = 0; i < array.length; i += 1) {
    if (array[i].length <= key.length && checkPrefix(array[i], key)) {
      return i;
    }
  }
  return -1;
}

const initialState = {
  recentSearchResult: [],
};

const getters = {
  getRecentSearchResult(state) {
    return state.recentSearchResult;
  },
};

const mutations = {
  setRecentSearchResult(state, data) {
    state.recentSearchResult = data;
  },
  addRecentSearchResult(state, data) {
    const maximumDisplayRecentSearch = 5;
    const recentSearch = state.recentSearchResult;
    // const index = recentSearch.indexOf(data);
    const index = getPrefixStringIndex(recentSearch, data);

    if (index === -1) {
      if (recentSearch.length === maximumDisplayRecentSearch) {
        state.recentSearchResult.pop();
      }
      state.recentSearchResult.unshift(data);
    } else {
      state.recentSearchResult.splice(index, 1);
      state.recentSearchResult.unshift(data);
    }
  },
  clearRecentSearchResult(state) {
    state.recentSearchResult = [];
  },
};

const actions = {
  setRecentSearchResult({ commit }, data) {
    commit('setRecentSearchResult', data);
  },
  addRecentSearchResult({ commit }, data) {
    commit('addRecentSearchResult', data);
  },
  clearRecentSearchResult({ commit }) {
    commit('clearRecentSearchResult');
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
