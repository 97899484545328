import AgenliteAPIClient from '~/api';

const initialState = {
  operators: null,
  pdamInfo: null,
  transaction: null,
};

const moduleGetters = {
  getPdamInfo(state) {
    return state.pdamInfo;
  },
  getPdamOperators(state) {
    return state.operators;
  },
  getPdamTransaction: state => state.transaction,
};

const actions = {
  async createPdamTransaction({ dispatch, commit }, payload) {
    try {
      dispatch('showAppLoading');
      const response = await AgenliteAPIClient.postPdamTransaction(payload);
      dispatch('hideAppLoading');
      commit('setPdamTransaction', response.data);
      return response.data;
    } catch (error) {
      const message = 'Gagal melakukan transaksi';
      dispatch('catchError', { error, message, hideAppLoading: true });
      throw error;
    }
  },
  payPdam({ dispatch, getters, commit }, params) {
    dispatch('showAppLoading');
    return new Promise((resolve, reject) => {
      AgenliteAPIClient.payPdam(getters.getUserId, params)
        .then(response => {
          dispatch('hideFlashAlert');
          const mergedResponse = { ...response, transactionResponse: getters.getPdamTransaction };
          commit('setPdamTransaction', null);
          resolve(mergedResponse);
        })
        .catch(error => {
          const message =
            'Pembayaran PDAM gagal. Cek <a href="/transaksi">daftar transaksi</a> untuk melanjutkan pembayaran sebelum mengulang transaksi.';
          dispatch('catchError', { error, message, finishTransaction: true });
          reject(error);
        });
    });
  },
  fetchPdamOperators({ dispatch }) {
    AgenliteAPIClient.getPdamOperators()
      .then(response => {
        dispatch('hideAppLoading');
        dispatch('hideFlashAlert');
        dispatch('setPdamOperators', response.data);
      })
      .catch(error => {
        const message = 'Gagal mendapatkan data operator PDAM.';
        dispatch('catchError', { error, message, hideAppLoading: true });
      });
  },
  fetchPdamInfo({ dispatch }, { nomorPelanggan, operatorId }) {
    dispatch('showAppLoading');
    return new Promise((resolve, reject) => {
      AgenliteAPIClient.postPdamInquiry({
        customer_number: nomorPelanggan,
        operator_id: operatorId,
      })
        .then(response => {
          dispatch('hideAppLoading');
          dispatch('hideFlashAlert');
          dispatch('setPdamInfo', response.data);
          resolve();
        })
        .catch(error => {
          const message = 'Gagal mendapatkan data PDAM.';
          if (
            error &&
            error.errors &&
            (error.errors[0].code === 18117 || error.errors[0].message.match(/tidak terdaftar/i))
          ) {
            error.message = 'Maaf, nomornya salah. Coba cek lagi ya.';
            dispatch('hideAppLoading');
            return reject(error);
          }
          dispatch('catchError', { error, message, hideAppLoading: true });
          return reject(error);
        });
    });
  },
  setPdamOperators({ commit }, operators) {
    commit('setPdamOperators', operators);
  },
  clearPdamOperators({ commit }) {
    commit('clearPdamOperators');
  },
  clearPdamInfo({ commit }) {
    commit('clearPdamInfo');
  },
  setPdamInfo({ commit }, pdamInfo) {
    commit('setPdamInfo', pdamInfo);
  },
};

const mutations = {
  clearPdamOperators(state) {
    state.operators = JSON.parse(JSON.stringify(initialState.operators));
  },
  setPdamOperators(state, operators) {
    state.operators = [...operators];
  },
  clearPdamInfo(state) {
    state.pdamInfo = JSON.parse(JSON.stringify(initialState.pdamInfo));
  },
  setPdamInfo(state, pdamInfo) {
    state.pdamInfo = { ...pdamInfo };
  },
  setPdamTransaction(state, transaction) {
    state.transaction = transaction;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  actions,
  mutations,
};
