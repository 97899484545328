const PREFIX = 'agenlite_';

export default {
  trackTopupVisit({ route, referrer, store }) {
    const danaProfile = store.state?.dana?.profile ?? null;
    const payload = {
      evn: `${PREFIX}topup_visit`,
      current_screen: route.name,
      referrer_screen: referrer.name,
      referrer_type: referrer.type,
      binding_dana_flag: !!danaProfile,
    };
    this.$blTracker.actions.trackActions(payload);
  },
  trackTopupclick({ clickSource, clickSourceStatus, optionSelected }) {
    const danaProfile = this.$store?.state?.dana?.profile ?? null;
    const payload = {
      evn: `${PREFIX}topup_click`,
      current_screen: this.$route.name,
      binding_dana_flag: !!danaProfile,
      click_source: clickSource,
      click_source_status: clickSourceStatus,
      option_selected: optionSelected,
      referrer_type: 'page',
    };
    this.$blTracker.actions.trackActions(payload);
  },
};
