
import { Client } from '~/types/api/api-module';
import ApiOptions from '../api-options';

const URL = `${process.env.API_V4_HOST}/total-complaints/mitra`;

export interface ComplaintCount {
  active: number;
  unread: number;
}

export default Client.wrap({
  Private: {
    fetchMitraComplaintCount() {
      return this.get<ComplaintCount>(URL, 'public', new ApiOptions());
    },
  }
});
