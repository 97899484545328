import AgenliteAPIClient from '~/api';

const initialState = {
  totalCoupons: 0,
  coupons: null,
};

const moduleGetters = {
  getTotalCoupons: state => state.totalCoupons,
  getRedeemedCoupons: state => state.coupons,
};

const actions = {
  async getClaimedCoupons({ dispatch, commit }, { type = '', offset = 0, limit = 100 }) {
    dispatch('showAppLoading');
    try {
      const { data: response = [] } = await AgenliteAPIClient.getClaimedCoupons({ offset, limit });
      if (type) {
        const filteredResponse = response.filter(r => r.rules.product_types[0] === type);
        commit('setCouponsData', filteredResponse);
      } else {
        commit('setCouponsData', response);
      }
      return response;
    } catch (e) {
      const message = 'Proses Pengecekan Status Klaim Coupon Gagal';
      dispatch('catchError', { e, message });
    } finally {
      dispatch('hideAppLoading');
    }
  },
  async claimAllCoupons({ dispatch }) {
    dispatch('showAppLoading');
    try {
      const { data: response } = await AgenliteAPIClient.claimAllCoupons();
      return response;
    } catch (e) {
      const message = 'Proses Klaim Coupon Gagal';
      dispatch('catchError', { e, message });
    } finally {
      dispatch('hideAppLoading');
    }
  },
  async claimGetCoupons({ dispatch }, { type = '' }) {
    await dispatch('claimAllCoupons');
    await dispatch('getClaimedCoupons', { type });
  },
};

const mutations = {
  setCouponsData(state, payload) {
    state.totalCoupons = payload.length;
    state.coupons = payload;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  mutations,
  actions,
};
