import credentialStorageInstance from '~/api/credential-storage-instance';
import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const VOUCHER_URL = `${process.env.API_V4_HOST}/vouchers`;

const generateHeaders = () => ({
  'Bukalapak-Identity': credentialStorageInstance.identity,
});

export default Client.wrap({
  validateVoucher(requestPayload) {
    return this.post(
      `${VOUCHER_URL}/validations`,
      'user',
      new ApiOptions({ requestHeaders: generateHeaders(), requestPayload })
    );
  },
});
