import AgenliteAPIClient from '~/api/index';

const initialState = {
  transaction: {},
};

const getters = {
  getEdcCashOutTransaction(state) {
    return state.transaction;
  },
};

const actions = {
  async retrieveEdcCashOutTransaction({ dispatch, commit }, requestPayload: { id: number }) {
    try {
      const { data } = await AgenliteAPIClient.getEdcCashOutTransaction(requestPayload.id);
      commit('setEdcCashOutTransaction', data);
    } catch (error) {
      const message = 'Gagal mendapatkan data transaksi';
      dispatch('catchError', { error, message });
      throw error;
    }
  },
};

const mutations = {
  setEdcCashOutTransaction(state, transaction) {
    state.transaction = transaction;
  },
};

export default {
  state: () => ({ ...initialState }),
  mutations,
  getters,
  actions,
};
