import localforage from 'localforage';

const AgenliteLocalForage = localforage.createInstance({
  driver: [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE], // Fallback to localStorage if indexedDB isnt supported
  name: 'agenlite_storage',
  version: 1.0,
  storeName: 'agenlite_storage',
  description: 'Agenlite Storage',
  size: 15728640,
});

export default AgenliteLocalForage;
