const MAX_BUYER_NOTE_LENGTH = 255;
const WAREHOUSE_MAX_HOUR = 1;
const WAREHOUSE_MAINTENANCE = false;

const getProductImage = (product, type = 'thumbnail') => {
  let imageUrl;
  try {
    if (product && product.images) {
      switch (type) {
        case 'thumbnail':
          imageUrl = product.images.small_urls;
          break;
        case 'modal':
          imageUrl = product.images.original_urls;
          break;
        default:
          imageUrl = product.images.large_urls;
          break;
      }
      if (imageUrl && imageUrl.length) {
        return imageUrl[0];
      }
    }
  } catch (e) {
    return '/images/grosir/broken-image@2x-min.png';
  }
  return '/images/grosir/broken-image@2x-min.png';
};

const getProductPct = (product, round = false) => {
  try {
    const sellingPricePct = product.selling_price_percentage;
    const pct = sellingPricePct > 0 ? sellingPricePct : 0;
    return round ? Math.round(pct) : pct;
  } catch (e) {
    return 0;
  }
};

export {
  MAX_BUYER_NOTE_LENGTH,
  WAREHOUSE_MAX_HOUR,
  WAREHOUSE_MAINTENANCE,
  getProductImage,
  getProductPct,
};
