import Vue from 'vue';
import VueGtm from 'vue-gtm';
import { routerInstance } from '~/router';

const env = process.env.NODE_ENV;
const isProduction = env === 'production';

if (/^GTM-[0-9A-Z]+$/.test(process.env.GOOGLE_TAG_MANAGER_API_KEY)) {
  Vue.use(VueGtm, {
    defer: true,
    id: process.env.GOOGLE_TAG_MANAGER_API_KEY,
    enabled: !!isProduction,
    debug: !isProduction,
    vueRouter: routerInstance,
    trackOnNextTick: true,
  });
} else {
  console.warn('Disable GTM due invalid gtm key');
}

export default () => { };
