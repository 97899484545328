const DefaultAdaptor = {
  back: () => window.history?.back?.(),
  deviceBack: () => window.history?.back?.(),
  close: () => window.close?.(),
  topBack: () => window.history?.back?.(),
};

const BLNativeBridge = {
  // Available functions:
  // See https://bukalapak.atlassian.net/wiki/spaces/MOBS/pages/802291983/Javascript+Bridge
  bridgeFunctions: [
    'back',
    'close',
    'setResult',
    'setTopBar',
    'setDeviceBar',
    'topBack',
    'deviceBack',
    'setContentBehavior',
    'shareText',
  ],
  init() {
    const warnDecorator = wrapped => (...args) => {
      console.warn('[Warning] Use BLNativeBridge for more powerful bridging function. Just add more functions there if needed.');
      const result = wrapped.apply(this, args);
      return result;
    };
    if (window.android) {
      Object.keys(window.android).forEach(key => {
        window.android[key] = warnDecorator(window.android[key]);
      });
    }
    if (window.webkit?.messageHandlers) {
      Object.keys(window.webkit.messageHandlers).forEach(key => {
        window.webkit.messageHandlers[key].postMessage = warnDecorator(window.webkit.messageHandlers[key].postMessage);
      });
    }
    this.bridgeFunctions.forEach(func => {
      this[func] = (...args) => this.exec(func, ...args);
    });
  },
  exec(func, ...args) {
    if (window.android?.[func]) {
      window.android[func](...args);
    } else if (window.webkit?.messageHandlers?.[func]) {
      const data = args.length > 0 ? args : [''];
      window.webkit.messageHandlers[func].postMessage?.(...data);
    } else {
      DefaultAdaptor[func]?.(...args);
    }
  },
  isAvailable(func) {
    if (func) {
      return !!window.android?.[func] || !!window.webkit?.messageHandlers?.[func];
    }
    return !!window.android || !!window.webkit?.messageHandlers;
  },
};
BLNativeBridge.init();

const BLNativeBridgeInstance = {
  ...BLNativeBridge,
  install(vue) {
    vue.prototype.$nativeBridge = BLNativeBridge;
    delete this.install;
  },
};

export default BLNativeBridgeInstance;
