import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const BASE_URL = `${process.env.API_V4_HOST}/coupon-deals`;

export default Client.wrap({
  Private: {
    fetchDealsPopularMerchants() {
      return this.get(`${BASE_URL}/merchants/populars`, 'user', new ApiOptions());
    },
    fetchDealsCategories(requestPayload) {
      return this.get(`${BASE_URL}/categories`, 'user', new ApiOptions({ requestPayload }));
    },
    fetchDealsCoupons(requestPayload) {
      return this.get(`${BASE_URL}/deals`, 'user', new ApiOptions({ requestPayload }));
    },
    fetchDealsDetail(id) {
      return this.get(`${BASE_URL}/deals/${id}`, 'user');
    },
    fetchDealsMerchants() {
      return this.get(`${BASE_URL}/merchants`, 'user', new ApiOptions());
    },
    fetchDealsBestSeller() {
      return this.get(`${BASE_URL}/deals/best-sellers`, 'user', new ApiOptions());
    },
    postDealsCreateTransaction(requestPayload) {
      const requestHeaders = { Accept: 'application/json' };
      return this.post(`${BASE_URL}/transactions`, 'user', new ApiOptions({ requestHeaders, requestPayload }));
    },
    patchDealsCancelTransaction(id) {
      const requestHeaders = { Accept: 'application/json' };
      return this.patch(`${BASE_URL}/transactions/${id}/cancellations`, 'user', new ApiOptions({ requestHeaders }));
    },
    getDealsTransaction(id) {
      return this.get(`${BASE_URL}/transactions/${id}`, 'user');
    },
  },
});
