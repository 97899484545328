/**
 * @module secure-client/request-manager
 */

import axios, { AxiosInstance, CancelTokenSource, Method } from 'axios';
import axiosRetry from 'axios-retry';
import { v4 } from 'uuid';
import { redirect, refineURL, fullPath } from '~/plugins/LocationPlugin/index';

import credentialStorageInstance from '~/api/credential-storage-instance';
import AccessTokenManager from '~/api/v4/access-token-manager';
import RequestOptions from '~/types/api/request-options';
import TokenStorage from '~/types/api/token-storage';
import { RequestMethod, Scope } from '~/types/api/request-method';
import { APIResponse } from '~/types/api/api-response';
import { generateAccessTokenObject, registerLocalCallback } from '~/utils/auth-utils';

const DEFAULT_REQUEST_ATTEMPT_LIMIT = 1;
const DEFAULT_REQUEST_TIME_LIMIT = 8500; // in ms
const DEFAULT_REQUEST_FAILURE_DELAY = 325; // in ms
const DEFAULT_REQUEST_FAILURE_RETRIES = 0;

declare global {
  export interface BLAndroid {
    refreshToken?(funcName: string): void;
    printReceipt?(): void;
    openPinLandingScreen?(jsonString: string): void;
  }
  interface Window {
    blandroid?: BLAndroid;
  }
}

interface WebviewHandlerOpts {
  url: string;
  method: Method;
  requestId: string;
  options: RequestOptions;
}

/**
 * Manages a request, enwrapped by auth token and request limiter/throttler
 */
class RequestManager implements RequestMethod {
  static requestPool = new Map<string, Map<string, CancelTokenSource>>();
  static limitedRequestPool = new Map<string, { attempt: number; requestedAt: number }>();
  axiosClient: AxiosInstance;
  cancelTokenSource: CancelTokenSource;
  accessTokenManager: AccessTokenManager;
  baseURL = '';
  requestLaneKey: string;
  requestKey: string;

  constructor(accessTokenManager: AccessTokenManager, baseURL: string = '', requestLaneKey: string = 'requestLane') {
    this.accessTokenManager = accessTokenManager;
    this.baseURL = baseURL;
    this.requestLaneKey = requestLaneKey;
    this.cancelTokenSource = axios.CancelToken.source();
    this.axiosClient = axios.create();
    axiosRetry(this.axiosClient, {
      retries: DEFAULT_REQUEST_FAILURE_RETRIES,
      retryDelay: () => DEFAULT_REQUEST_FAILURE_DELAY,
    });
  }

  get<T>(url: string, scope: Scope, options?: RequestOptions): Promise<APIResponse<T>> {
    return this.request<T>(url, 'get', scope, { ...options, params: options?.data } as RequestOptions);
  }

  post<T>(url: string, scope: Scope, options?: RequestOptions): Promise<APIResponse<T>> {
    return this.request<T>(url, 'post', scope, options);
  }

  patch<T>(url: string, scope: Scope, options?: RequestOptions): Promise<APIResponse<T>> {
    return this.request<T>(url, 'patch', scope, options);
  }

  put<T>(url: string, scope: Scope, options?: RequestOptions): Promise<APIResponse<T>> {
    return this.request<T>(url, 'put', scope, options);
  }

  delete<T>(url: string, scope: Scope, options?: RequestOptions): Promise<APIResponse<T>> {
    return this.request<T>(url, 'delete', scope, options);
  }

  /**
   * Fetches access token, generates request params, and then sends the request
   */
  async request<T>(url: string, method: Method, scope: Scope = 'public', requestOptions: RequestOptions = {}) {
    this.requestKey = `${this.requestLaneKey}-${url.replace(/\/\w+\d+\w+/, '')}-${method}`;
    if (requestOptions?.requestLimit?.additionalKey) {
      this.requestKey += `-${requestOptions.requestLimit.additionalKey}`;
    }
    if (this.needToLimitRequest(requestOptions?.requestLimit)) throw new Error('REQUEST LIMITED');
    const requestId = v4();
    const options = { ...RequestManager.defaultRequestOptions(), ...requestOptions };
    this.registerCancelToken(requestId, this.cancelTokenSource);
    try {
      const tokenObj = await this.requestWithToken(scope);
      const requestParam = this.generateRequestParam(url, method, requestId, tokenObj, this.cancelTokenSource, options);
      const response = await this.axiosClient.request<APIResponse<T>>(requestParam);
      this.removeCancelToken(requestId);
      this.removeLimitedRequest(requestOptions.requestLimit);
      return response.data;
    } catch (rejection) {
      this.removeLimitedRequest(requestOptions.requestLimit);
      if (!this.handleRequestRejection({ rejection, requestId, options })) throw new Error('Unhandled Exception');
      const response = await this.requestWithTokenWebview<T>({ url, method, requestId, options });
      return response.data;
    }
  }

  /**
   * Limit request attempt between request time and time limit.
   *
   * Default: Limit 1 request per 5 seconds
   */
  needToLimitRequest(requestLimit: RequestOptions['requestLimit']): boolean {
    if (!requestLimit?.attempt && !requestLimit?.time) return false;
    const attemptLimit = requestLimit.attempt || DEFAULT_REQUEST_ATTEMPT_LIMIT;
    const timeLimit = requestLimit.time || DEFAULT_REQUEST_TIME_LIMIT;
    const { limitedRequestPool } = RequestManager;
    const limitedRequest = limitedRequestPool.get(this.requestKey);
    if (!limitedRequest) {
      this.registerLimitedRequest(requestLimit);
      return false;
    }
    const { attempt, requestedAt } = limitedRequest;
    const isLimitedByAttempt = attempt > attemptLimit - 1;
    const isLimitedByTime = new Date(requestedAt).getTime() + timeLimit > new Date().getTime();
    if (!isLimitedByTime) {
      this.registerLimitedRequest(requestLimit);
      return false;
    }
    if (isLimitedByAttempt) return true;
    limitedRequestPool.set(this.requestKey, { attempt: attempt + 1, requestedAt });
    this.removeLimitedRequest(requestLimit);
    return false;
  }

  registerLimitedRequest(requestLimit: RequestOptions['requestLimit']) {
    const timeLimit = requestLimit?.time || DEFAULT_REQUEST_TIME_LIMIT;
    const { limitedRequestPool } = RequestManager;
    limitedRequestPool.set(this.requestKey, { attempt: 1, requestedAt: Date.now() });
    setTimeout(() => {
      this.removeLimitedRequest(requestLimit);
    }, timeLimit + 1);
  }

  removeLimitedRequest(requestLimit: RequestOptions['requestLimit']) {
    if (!requestLimit) return;
    const { limitedRequestPool } = RequestManager;
    const limitedRequest = limitedRequestPool.get(this.requestKey);
    if (!limitedRequest) return;
    const { attempt, requestedAt } = limitedRequest;
    const timeLimit = requestLimit.time || DEFAULT_REQUEST_TIME_LIMIT;
    const isExpired = new Date(requestedAt).getTime() + timeLimit < new Date().getTime();
    if (!isExpired) return;
    if (attempt <= 1) {
      limitedRequestPool.delete(this.requestKey);
    } else {
      limitedRequestPool.set(this.requestKey, { attempt: attempt - 1, requestedAt });
    }
  }

  async requestWithToken(scope: Scope): Promise<TokenStorage> {
    try {
      const tokenResponse: TokenStorage = await this.accessTokenManager.fetchToken(scope);
      return tokenResponse;
    } catch (error) {
      if (process.env.NODE_ENV === 'production') console.warn('[FAILED TO RETRIEVE TOKEN]', error);
      // check for capable refresh with webview and throw appropriate exception
      // eslint-disable-next-line no-throw-literal
      if (window.blandroid?.refreshToken) throw { response: { status: 401, data: { errors: [{ code: 10001 }] } } };
      if (error?.errors?.[0]?.code === 10102) {
        try {
          const secondTokenResponse: TokenStorage = await this.accessTokenManager.fetchFreshToken();
          return secondTokenResponse;
        } catch (err) {
          const freshToken: TokenStorage = await this.accessTokenManager.fetchFreshToken();
          return freshToken;
        }
      }
      throw error;
    }
  }

  async requestWithTokenWebview<T>(payload: WebviewHandlerOpts) {
    // Prepare the callback and payload
    const { url, method, requestId, options } = payload;
    const tokenPromise = new Promise<string>((resolve, reject) => {
      const timeout = setTimeout(
        () => reject(new Error('No token returned from apps after 3 seconds. Aborting refresh mechanism.')),
        3000
      );
      const uniqueCaller = registerLocalCallback((token: string) => {
        resolve(token);
        clearTimeout(timeout);
      });

      window.blandroid?.refreshToken?.(uniqueCaller);
    });

    // Prevent proceed to API call on bad token/fail to parse
    try {
      const accessJwt = await tokenPromise;
      const accessObject = generateAccessTokenObject(accessJwt);
      this.accessTokenManager.storage.token = accessObject;
    } catch (error) {
      // Handle current broken android refreshToken. #typomaster
      AccessTokenManager.eventBus.$emit('webview:refresh:error', { error, options });
      throw new Error('Invalid JWT');
    }

    try {
      // Rebuild the requestParam
      const requestParam = this.generateRequestParam(
        url,
        method,
        requestId,
        this.accessTokenManager.storage.token,
        this.cancelTokenSource,
        options
      );
      const response = await this.axiosClient.request<APIResponse<T>>(requestParam);
      this.removeCancelToken(requestId);
      return response;
    } catch (rejection) {
      this.removeCancelToken(requestId);
      AccessTokenManager.eventBus.$emit('webview:refresh:error', { error: rejection, options });
      if (options.responseErrorJson && rejection.response) {
        throw rejection.response;
      }
      throw rejection;
    }
  }

  handleRequestRejection({ rejection, requestId, options }): boolean {
    // Check response token 401 (unauthorized). Now includes buka20 error types
    const isInvalidOauthToken =
      rejection?.response?.status === 401 &&
      [10001, 'UNAUTHORIZED'].includes(rejection?.response?.data?.errors?.[0]?.code);
    const isLoggedIn = credentialStorageInstance.login || this.accessTokenManager.login;

    if (isLoggedIn && isInvalidOauthToken) {
      if (window.blandroid?.refreshToken) {
        return true;
      }
      window.sessionStorage.setItem('comeback', refineURL(fullPath(), {}));
      console.error(rejection, options);
      redirect('/logout', {
        to: 'login',
      });
      return false;
    }
    this.removeCancelToken(requestId);
    if (axios.isCancel(rejection)) {
      // request cancelled, do nothing lalala…
      return false;
    }
    // throw errors, they should be handled by the interface
    if (options.responseErrorJson && rejection.response) {
      throw rejection.response.data;
    }
    throw rejection;
  }

  /**
   * Aborts currently active requests in the request pool with the same key
   */
  abortRequest() {
    const { requestPool } = RequestManager;
    const requestLane = requestPool.get(this.requestLaneKey);

    if (requestLane) {
      requestLane.forEach(cancelToken => {
        cancelToken.cancel('Request aborted');
      });

      requestLane.clear();
    }

    requestPool.delete(this.requestLaneKey);
  }

  generateRequestParam(
    url: string,
    method: Method,
    requestId: string,
    tokenObj: TokenStorage,
    cancelTokenSource: CancelTokenSource,
    options: RequestOptions
  ): RequestOptions {
    const requestParam: RequestOptions = {
      ...options,
      baseURL: this.baseURL,
      method,
      cancelToken: cancelTokenSource.token,
      url,
    };

    if (options.enableRequestId) {
      Object.assign(requestParam.headers, { 'X-Request-Id': requestId });
    }

    if (options.enableAuthHeader) {
      requestParam.headers = {
        ...requestParam.headers,
        Authorization: `${tokenObj.token_type} ${tokenObj.access_token}`,
      };
    } else {
      requestParam.params = {
        ...requestParam.params,
        access_token: tokenObj.access_token,
      };
    }

    return requestParam;
  }

  registerCancelToken(requestId: string, cancelTokenSource: CancelTokenSource) {
    const { requestPool } = RequestManager;
    let requestLane = requestPool.get(this.requestLaneKey);

    if (!requestLane) {
      requestLane = new Map<string, CancelTokenSource>();
      requestPool.set(this.requestLaneKey, requestLane);
    }

    requestLane.set(requestId, cancelTokenSource);
  }

  removeCancelToken(requestId: string) {
    const { requestPool } = RequestManager;
    const requestLane = requestPool.get(this.requestLaneKey);

    if (requestLane) {
      requestLane.delete(requestId);

      if (requestLane.size === 0) {
        requestPool.delete(this.requestLaneKey);
      }
    }
  }

  static defaultRequestOptions(): RequestOptions {
    return {
      data: {},
      headers: {
        Accept: 'application/vnd.bukalapak.v4+json',
      },
      params: {},
      enableRequestId: false,
      responseErrorJson: false,
      enableAuthHeader: false,
      timeout: 0,
      retryCount: DEFAULT_REQUEST_FAILURE_RETRIES,
    };
  }
}

export default RequestManager;
