import { Context } from '@nuxt/types';

declare global {
  interface Window {
    dataLayer?:any;
  }
}

const measurementId = process.env.BUKUMITRA_MEASUREMENT_ID;

export const gtagTrackPageview = ({ app, route }: Partial<Context>) => {
  const isConfigSet = window?.dataLayer?.filter(p => p[1] === measurementId)?.length;
  
  if (!isConfigSet) app?.$gtag?.('config', measurementId);
  
  app?.$gtag?.('event', 'page_view', {
    page_title: route?.name || '',
    page_location: window.location.href,
    page_path: route?.path || '',
    send_page_view: true,
  });
};
