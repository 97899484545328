import Vue from 'vue';
import AgenliteAPIClient from '~/api';

const initialState = {
  bestSeller: [],
  popularMerchants: null,
  dealsDetail: null,
  categories: [],
  merchants: [],
  coupons: [],
  search: {
    coupons: '',
    merchants: '',
  },
  totalCountCoupons: 0,
  totalQuantitySelectedSku: 0,
  selectedSku: {},
  fetchCouponsTimes: 0,
  previousPage: '',
  dealsTransaction: null,
  dealsVOCTransaction: null,
  onboardStatus: undefined,
  dealsCashback: null,
  dealsConfig: {},
};

const moduleGetters = {
  getDealsCategories: state => state.categories,
  // Line below is to make sure return unique items.
  getDealsCoupons: state => [...new Map(state.coupons.map(item => [item.id, item])).values()],
  getDealsMerchants: state => state.merchants,
  getDealsPopularMerchants: state => state.popularMerchants,
  getDealsSearchMerchants: state => state.search.merchants,
  getDealsSearchCoupons: state => state.search.coupons,
  getDealsBestSeller: state => state.bestSeller,
  getDealsTotalCount: state => state.totalCountCoupons,
  getDealsDetail: state => state.dealsDetail,
  getDealsTotalQuantitySelectedSku: state => state.totalQuantitySelectedSku,
  getDealsSelectedSku: state => state.selectedSku,
  getDealsPreviousPage: state => state.previousPage,
  getDealsFetchCouponsTimes: state => state.fetchCouponsTimes,
  getDealsTransaction: state => state.dealsTransaction,
  getDealsOnboardStatus: state => state.onboardStatus,
  getDealsVOCTransaction: state => state.dealsVOCTransaction,
  getDealsCashback: state => state.dealsCashback,
  getDealsConfig: state => state.dealsConfig,
};

const defaultErrorMessage = 'Terjadi gangguan. Silakan coba lagi nanti.';

const errorHandler = error => {
  throw error?.response?.data?.errors?.[0]?.message || error.errors?.[0]?.message || defaultErrorMessage;
};

const actions = {
  async payDealsTransaction({ getters, commit }, requestPayload) {
    const {
      dealId,
      skus,
      recipient,
      method: paymentMethod,
      voucherCode,
      finalAmount,
      transactionId,
      type: transactionType,
    } = requestPayload;
    const VOCPayload = { dealId, skus, recipient };
    const VOCOptionsPayload = { voucherCode, finalAmount, transactionId };
    const userId = getters.getUserId;
    try {
      const response = await AgenliteAPIClient.createVOCTransaction({
        userId,
        transactionType,
        paymentMethod,
        VOCOptionsPayload,
        VOCPayload,
      });

      commit('setDealsVOCTransaction', response?.data);
    } catch (error) {
      errorHandler(error);
    }
  },
  async cancelDealsTransaction({ state }) {
    try {
      await AgenliteAPIClient.patchDealsCancelTransaction(state.dealsTransaction.id);
    } catch (error) {
      errorHandler(error);
    }
  },
  async fetchDealsPopularMerchants({ commit }) {
    try {
      const response = await AgenliteAPIClient.fetchDealsPopularMerchants();
      commit('setDealsPopularMerchants', response.data);
    } catch (error) {
      errorHandler(error);
    }
  },
  async createDealsTransaction({ commit }, payload) {
    try {
      const response = await AgenliteAPIClient.postDealsCreateTransaction(payload);
      commit('setDealsTransaction', response.data);
    } catch (error) {
      errorHandler(error);
    }
  },
  async fetchDealsCategories({ commit }) {
    try {
      const response = await AgenliteAPIClient.fetchDealsCategories({ has_active_deals: true });
      const additonalCategory = {
        id: null,
        name: 'Terlaris',
      };
      const categories = [additonalCategory, ...response.data];

      commit('setDealsCategories', categories);
    } catch (error) {
      errorHandler(error);
    }
  },
  async fetchDealsMerchants({ commit }, payload) {
    try {
      const response = await AgenliteAPIClient.fetchDealsMerchants(payload);
      commit('setDealsMerchants', response.data);
    } catch (error) {
      errorHandler(error);
    }
  },
  async fetchDealsCoupons({ commit, state }, payload) {
    try {
      const response = await AgenliteAPIClient.fetchDealsCoupons(payload);
      const data = state.coupons.concat(response.data);

      commit('incDealsFetchCouponsTimes');
      commit('setDealsCoupons', data);
      commit('setDealsTotalCountCoupons', response.meta.total_count);
    } catch (error) {
      errorHandler(error);
    }
  },
  async fetchDealsBestSeller({ commit }) {
    try {
      const response = await AgenliteAPIClient.fetchDealsBestSeller();
      commit('setDealsBestSeller', response.data);
    } catch (error) {
      errorHandler(error);
    }
  },
  async fetchDealsDetail({ commit }, id) {
    try {
      const response = await AgenliteAPIClient.fetchDealsDetail(id);
      commit('setDealsDetail', response.data);
      return response;
    } catch (error) {
      errorHandler(error);
    }
  },
  async fetchDealsCashback({ commit }) {
    try {
      const response = await AgenliteAPIClient.fetchVirtualProductCashbackSettings();
      commit('setDealsCashback', response.data);
    } catch (error) {
      errorHandler(error);
    }
  },
  async fetchDealsNeoConfig({ commit }) {
    try {
      const response = await AgenliteAPIClient.fetchNeoConfigs(['mitra-deals-config']);
      commit('setDealsConfig', response.data[0].data);
    } catch (error) {
      errorHandler(error);
    }
  },
  resetDealsCoupons({ commit }) {
    commit('resetDealsCoupons');
  },
  dealsCalculateQuantity({ commit }, selectedSku) {
    commit('dealsCalculateQuantity', selectedSku);
  },
  resetDealsFetchCouponsTimes({ commit }) {
    commit('resetDealsFetchCouponsTimes');
  },
  resetDealsTotalQuantityAndSelectedSku({ commit }) {
    commit('resetDealsTotalQuantityAndSelectedSku');
  },
  fetchDealsOnboardStatus({ commit }) {
    const onboardStatus = localStorage.getItem('DEALS_ONBOARD_STATUS_V2');
    commit('setDealsOnboardStatus', onboardStatus);
  },
  startDealsOnboarding({ commit }) {
    localStorage.setItem('DEALS_ONBOARD_STATUS_V2', true);
    commit('setDealsOnboardStatus', true);
  },
};

const mutations = {
  setDealsTransaction(state, dealsTransaction) {
    state.dealsTransaction = dealsTransaction;
  },
  setDealsSearchMerchants(state, merchants) {
    state.search.merchants = merchants;
  },
  setDealsSearchCoupons(state, coupons) {
    state.search.coupons = coupons;
  },
  setDealsPopularMerchants(state, popularMerchants) {
    state.popularMerchants = popularMerchants;
  },
  setDealsCoupons(state, coupons) {
    state.coupons = coupons;
  },
  setDealsMerchants(state, merchants) {
    state.merchants = merchants;
  },
  setDealsCategories(state, categories) {
    state.categories = categories;
  },
  setDealsBestSeller(state, deals) {
    state.bestSeller = deals;
  },
  setDealsDetail(state, dealsDetail) {
    state.dealsDetail = dealsDetail;
  },
  setDealsTotalCountCoupons(state, count) {
    state.totalCountCoupons = count;
  },
  incDealsFetchCouponsTimes(state) {
    state.fetchCouponsTimes += 1;
  },
  resetDealsFetchCouponsTimes(state) {
    state.fetchCouponsTimes = 0;
  },
  setDealsPreviousPage(state, previousPage) {
    state.previousPage = previousPage;
  },
  resetDealsCoupons(state) {
    state.coupons = [];
  },
  setDealsVOCTransaction(state, dealsVOCTransaction) {
    state.dealsVOCTransaction = dealsVOCTransaction;
  },
  setDealsConfig(state, value) {
    state.dealsConfig = value;
  },
  dealsCalculateQuantity(state, { id, quantity }) {
    Vue.set(state.selectedSku, id, quantity);
    if (Object.keys(state.selectedSku).length) {
      state.totalQuantitySelectedSku = Object.values(state.selectedSku).reduce((a, b) => Number(a) + Number(b), 0);
    } else {
      state.totalQuantitySelectedSku = state.selectedSku[Object.keys(state.selectedSku)];
    }
  },
  resetDealsTotalQuantityAndSelectedSku(state) {
    state.selectedSku = {};
    state.totalQuantitySelectedSku = 0;
  },
  setDealsOnboardStatus(state, onboardStatus) {
    state.onboardStatus = onboardStatus;
  },
  setDealsCashback(state, cashback) {
    const dealsCashback = cashback.find(item => item.code === 'coupon_deals');
    state.dealsCashback = dealsCashback;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  mutations,
  actions,
};
