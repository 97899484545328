import AgenliteAPIClient from '~/api';

const initialState = {
  customerData: null,
};

const moduleGetters = {
  getTopupDanaCustomerData(state) {
    return state.customerData;
  },
};

const actions = {
  async consultTopupDanaCustomer({ dispatch, commit }, { usernameOrPhone }) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.consultTopupDanaCustomer(usernameOrPhone);
      if (response && response.meta && response.meta.http_status !== 200) throw response;
      commit('setTopupDanaCustomerData', response.data);
    } catch (error) {
      const message = 'Gagal mendapatkan data penerima topup.';
      dispatch('catchError', { error, message, hideAppLoading: true, noFlashAlert: true });
      throw error;
    } finally {
      dispatch('hideAppLoading');
    }
    return response;
  },
  async payTopupDanaCustomerTransaction({ dispatch, getters }, data) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.payTopupBukadanaTransaction(getters.getUserId, { ...data, type: 'customer' });
      if (response && response.meta && response.meta.http_status !== 201) throw response;
    } catch (error) {
      const message = 'Gagal melakukan topup DANA pelanggan.';
      dispatch('catchError', { error, message, hideAppLoading: true });
      throw error;
    } finally {
      dispatch('hideAppLoading');
    }
    return response;
  },
  clearTopupDanaCustomerData({ commit }) {
    commit('setTopupDanaCustomerData', null);
  },
};

const mutations = {
  setTopupDanaCustomerData(state, customerData) {
    state.customerData = customerData;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  actions,
  mutations,
};
