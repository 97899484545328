import { GetterTree, ActionTree, MutationTree } from 'vuex';
import AgenliteAPIClient from '~/api';

const BUSINESS_INFO_STEPS = ['business_activity_category', 'business_address', 'business_name', 'business_size_category'];

export type AccountState = {
  userSaas: {};
  businessInfoSteps: Array<string>;
  businessInfoForm: {};
};

const initialState: AccountState = {
  userSaas: {},
  businessInfoSteps: BUSINESS_INFO_STEPS,
  businessInfoForm: {},
};

const mutations: MutationTree<AccountState> = {
  setUserSaas(state, userSaas) {
    state.userSaas = { ...userSaas };
    state.businessInfoForm = { ...userSaas };
  },
  setBusinessInfoForm(state, payload) {
    state.businessInfoForm = { ...state.businessInfoForm, ...payload };
  },
};

const getters: GetterTree<AccountState, {}> = {
  getUserSaas: state => state.userSaas,
};

const actions: ActionTree<AccountState, {}> = {
  async fetchUserSaas({ commit }) {
    const { data = {} } = await AgenliteAPIClient.userSaas();
    commit('setUserSaas', data);

  },
  async updateUserSaas({ state, commit }, payload) {
    const { data = {} } = await AgenliteAPIClient.updateUserSaas(payload);
    commit('setUserSaas', { ...state.userSaas, ...data });
  },
  async createUserSaas({ commit }, payload: Record<string, any>) {
    const { data = {} } = await AgenliteAPIClient.createUserSaas(payload);
    commit('setUserSaas', data);
  },
};

export default {
  state: () => ({ ...initialState }),
  mutations,
  getters,
  actions,
};
