import Client from '~/types/api/api-module';
import ApiOptions from '../../api-options';

const URL = `${process.env.API_V4_HOST}/_exclusive/whatsapp`;
const TOGGLE_URL = `${process.env.API_HOST}/_exclusive/info/toggle-features`;

export default Client.wrap({
  Public: {
    async checkWhatsappAvailability(phoneNumber) {
      try {
        const promises = await Promise.all([
          this.v4.exclusiveWhatsapp.retrieveToggles(),
          this.v4.exclusiveWhatsapp.getWhatsAppInfo(phoneNumber),
        ]);
        const toggleStatus: boolean = promises[0]?.data?.status ?? false;
        const whatsappStatus: boolean = promises[1]?.data?.['whatsapp_available'] ?? false;
        return toggleStatus && whatsappStatus;
      } catch (error) {
        return false;
      }
    },
  },
  Private: {
    retrieveToggles() {
      const requestPayload = {
        feature: 'toggle/otp_whatsapp',
      };
      return this.get(TOGGLE_URL, 'public', new ApiOptions({ requestPayload }));
    },
    getWhatsAppInfo(phoneNumber: string) {
      return this.get(`${URL}/${phoneNumber}`, 'public', new ApiOptions());
    },
  },
});
