import AgenliteAPIClient from '~/api';

const initialState = {
  totalCommission: null,
  rewardHistories: [],
  meta: null,
  performance: {
    total_mitra: 0,
    total_mitra_non_referrer: 0,
    total_mitra_referrer: 0,
    total_mitra_referrer_reward: 0,
    total_mitra_transaction_reward: 0,
    total_non_transacting_mitra: 0,
    total_transacting_mitra: 0,
  },
  posterUrl: null,
  claimed: false,
  comissionDetails: {
    mitra_vp_trx: {
      reward_amount: 0,
      reward_value: 0,
      total_amount: 0,
    },
    mitra_gt_trx: {
      reward_amount: 0,
      reward_value: 0,
      total_amount: 0,
    },
    mitra_new_user_trx: {
      reward_amount: 0,
      reward_value: 0,
      total_amount: 0,
    },
    mitra_bonus_credit_trx: {
      reward_amount: 0,
      reward_value: 0,
      total_amount: 0,
    },
  },
  refereeDetails: {
    mitra_referral_trx: {
      reward_amount: 0,
      reward_value: 0,
      total_amount: 0,
    },
  },
  messageRemindTransaction: '[MITRA BUKALAPAK]<br>Belum cobain transaksi di Mitra Bukalapak?<br>Nih yang paling mudah, murah, dan hampir semua butuh: <b>transaksi pulsa</b>.<br>Cobain deh.<br>Kalau masih bingung, bisa liat mbl.id/bantuan',
  messageRemindRegister: '[MITRA BUKALAPAK]<br>Sayang banget, udah download Mitra Bukalapak tapi gak pernah gabung program Ajak Kerabat.<br>Soalnya kamu bisa untung jutaan, cukup dengan modal rebahan!<br>Yuk, buruan gabung sekarang.<br>Klik mbl.id/bantuan'
};

const moduleGetters = {
  totalCommission: state => state.totalCommission,
  rewardList: state => state.rewardHistories,
  rewardHistoryMeta: state => state.meta,
  mitraReferralPerformance: state => state.performance,
  getPosterUrl: state => state.posterUrl,
  rewardClaimed: state => state.claimed,
  messageRemindTransaction: state => state.messageRemindTransaction,
  messageRemindRegister: state => state.messageRemindRegister,
  comissionDetails: state => state.comissionDetails,
  refereeDetails: state => state.refereeDetails
};

const mutations = {
  setRemindMessages(state, { messageRemindTransaction, messageRemindRegister }) {
    state.messageRemindTransaction = messageRemindTransaction;
    state.messageRemindRegister = messageRemindRegister;
  },
  setRewardHistories(state, data) {
    state.totalCommission = data.totalReward;
    state.rewardHistories = data.rewardDetails;
    state.meta = data.meta;
  },
  setMitraPerformance(state, val) {
    state.performance = val;
  },
  setPosterUrl(state, url) {
    state.posterUrl = url;
  },
  setRewardClaimed(state, val) {
    state.claimed = val;
  },
  setComissionDetails(state, val) {
    state.comissionDetails = val;
  },
  setRefereeDetails(state, val) {
    state.refereeDetails = val;
  }
};

const actions = {
  async getMitraReferralData({ dispatch, commit }) {
    dispatch('showAppLoading');
    try {
      const aggregate = {
        mitraConfigs: {
          method: 'POST',
          path: `/_exclusive/neo/configs`,
          body: { id: ['o2o-act/referral-messages'] }
        },
        performances: {
          method: 'GET',
          path: `/_exclusive/referrals/mitra/performances`
        },
      };
      const { data } = await AgenliteAPIClient.postAggregate(aggregate);
      commit('setRemindMessages', {
        messageRemindTransaction: data.mitraConfigs[0].data.transactionReferral.message,
        messageRemindRegister: data.mitraConfigs[0].data.joinReferral.message
      });
      commit('setMitraPerformance', data.performances);
    } catch (error) {
      const message = 'Gagal mendapatkan data perkembangan referral mitra.';
      dispatch('catchError', { error, message });
      throw error;
    } finally {
      dispatch('hideAppLoading');
    }
  },
  async getMitraReferralComission({ dispatch, commit }, { startDate, endDate }) {
    dispatch('showAppLoading');
    const dateParams = startDate ? { start_date: startDate, end_date: endDate } : {};
    const paramsReferral = { ...dateParams, reward_type: 'referral' };
    const paramsTransaction = { ...dateParams, reward_type: 'transaction' };
    try {
      const aggregate = {
        referral: {
          method: 'GET',
          path: `/_exclusive/referrals/mitra/commissions/details?${new URLSearchParams(paramsReferral).toString()}`,
        },
        transaction: {
          method: 'GET',
          path: `/_exclusive/referrals/mitra/commissions/details?${new URLSearchParams(paramsTransaction).toString()}`
        },
        performances: {
          method: 'GET',
          path: `/_exclusive/referrals/mitra/performances?${new URLSearchParams(dateParams).toString()}`
        },
      };
      const { data } = await AgenliteAPIClient.postAggregate(aggregate);
      const defaultEmptyPayload = {
        reward_value: 0,
        total_amount: 0,
        reward_amount: 0,
      };
      commit('setComissionDetails', {
        mitra_vp_trx: (data.transaction.mitra_vp_trx && data.transaction.mitra_vp_trx[0]) || defaultEmptyPayload,
        mitra_gt_trx: (data.transaction.mitra_gt_trx && data.transaction.mitra_gt_trx[0]) || defaultEmptyPayload,
        mitra_new_user_trx: (data.transaction.mitra_new_user_trx && data.transaction.mitra_new_user_trx[0]) || defaultEmptyPayload,
        mitra_bonus_credit_trx: (data.transaction.mitra_bonus_credit_trx && data.transaction.mitra_bonus_credit_trx[0]) || defaultEmptyPayload,
      });
      commit('setRefereeDetails', {
        mitra_referral_trx: (data.referral.mitra_referral_trx && data.referral.mitra_referral_trx[0]) || defaultEmptyPayload,
      });
      commit('setMitraPerformance', data.performances);
    } catch (error) {
      const message = error.referralMessage || 'Gagal mendapatkan data riwayat referral komisi.';
      dispatch('catchError', { error, message });
      throw error;
    } finally {
      dispatch('hideAppLoading');
    }
  },
  async getMitraReferralsRewards({ dispatch }) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.getMitraReferralsRewards();
    } catch (error) {
      dispatch('catchError', { error, noFlashAlert: error?.meta?.http_status === 404 });
      throw error;
    } finally {
      dispatch('hideAppLoading');
    }
    return response;
  },
  async retrieveMitraReferralPerformance({ dispatch, commit }) {
    dispatch('showAppLoading');
    try {
      const { data } = await AgenliteAPIClient.retrieveMitraReferralPerformance();
      commit('setMitraPerformance', data);
    } catch (error) {
      const message = 'Gagal mendapatkan data perkembangan referral mitra.';
      dispatch('catchError', { error, message });
      throw error;
    } finally {
      dispatch('hideAppLoading');
    }
  },
  async fetchRewardHistories({ dispatch, commit, state }, { initialFetch, startDate, endDate, rewardType }) {
    dispatch('showAppLoading');
    let params = {};
    if (startDate) params = { start_date: startDate, end_date: endDate, reward_type: rewardType };
    else params = { reward_type: rewardType };

    try {
      const { data, meta } = await AgenliteAPIClient.retrieveRewardHistories({
        ...params,
        offset: initialFetch ? 0 : state.rewardHistories.length,
        limit: 20,
      });

      commit('setRewardHistories', {
        totalReward: data.total_reward,
        rewardDetails: initialFetch ? data.reward_details : state.rewardHistories.concat(data.reward_details),
        meta,
      });
    } catch (error) {
      const message = 'Gagal mendapatkan data riwayat referral komisi.';
      dispatch('catchError', { error, message });
      throw error;
    } finally {
      dispatch('hideAppLoading');
    }
  },
  async createReferralPoster({ dispatch, commit }) {
    dispatch('showAppLoading');
    try {
      const { data } = await AgenliteAPIClient.createReferralPoster();
      commit('setPosterUrl', data?.image_url);
    } catch (error) {
      const message = 'Gagal mendapatkan poster referral mitra.';
      dispatch('catchError', { error, message });
      throw error;
    } finally {
      dispatch('hideAppLoading');
    }
  },
  async fetchReferralPoster({ dispatch, commit }) {
    dispatch('showAppLoading');
    try {
      const { data } = await AgenliteAPIClient.fetchReferralPoster();
      commit('setPosterUrl', data?.image_url);
    } catch (error) {
      dispatch('createReferralPoster');
    } finally {
      dispatch('hideAppLoading');
    }
  },
  async fetchClaimBenefit({ dispatch, commit }, benefitSlug = 'free-referral') {
    dispatch('showAppLoading');
    try {
      const { data } = await AgenliteAPIClient.fetchClaimBenefit(benefitSlug);
      commit('setRewardClaimed', data?.claimed);
    } catch (error) {
      if (error?.meta.http_status === 422) return;
      const message = 'Gagal mendapatkan data claim free referral mitra.';
      dispatch('catchError', { error, message });
    }
    dispatch('hideAppLoading');
  },
  async patchClaimBenefit({ dispatch, commit }, benefitSlug = 'free-referral') {
    dispatch('showAppLoading');
    try {
      const { data } = await AgenliteAPIClient.patchClaimBenefit(benefitSlug);
      commit('setRewardClaimed', data?.claimed ?? false);
    } catch (error) {
      const message = 'Gagal mendapatkan credit free referral.';
      dispatch('catchError', { error, message });
      throw error;
    } finally {
      dispatch('hideAppLoading');
    }
  }
};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  actions,
  mutations,
};
