const initialState = {
  trackerInfo: {
    productInfo: null,
    paymentInfo: null,
    customerInfo: null,
  },
};

const moduleGetters = {
  getTrackerInfoVP(state) {
    return state.trackerInfo;
  },
};

const actions = {
  setTrackerInfoVP({ commit }, info) {
    commit('setTrackerInfoVP', info);
  },
  resetTrackerInfoVP({ commit }) {
    commit('resetTrackerInfoVP');
  },
};

const mutations = {
  setTrackerInfoVP(state, info) {
    state.trackerInfo = {
      ...state.trackerInfo, ...info
    };
  },
  resetTrackerInfoVP(state) {
    state.trackerInfo = JSON.parse(JSON.stringify(initialState.trackerInfo));
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  actions,
  mutations,
};
