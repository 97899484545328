const urlHelper = {
  methods: {
    visitURL(url, treatAsExternalUrl = false) {
      const cleanUrl = !treatAsExternalUrl ? url.replace(window.location.origin, '') : url;
      const isExternalUrl = cleanUrl.match(/^http[s]?:\/\//g);
      if (isExternalUrl || treatAsExternalUrl) {
        const winTarget = treatAsExternalUrl ? '_self' : '_blank';
        const win = window.open(cleanUrl, winTarget);
        return win.focus();
      }
      return this.$router.push(cleanUrl);
    },
    getUTMVariable(string) {
      const query = window.location.search.substring(1);
      const vars = query.split('&');
      for (let i = 0; i < vars.length; i += 1) {
        const pair = vars[i].split('=');
        if (pair[0] === string) {
          return pair[1];
        }
      }
      return false;
    },
    generateUTMQueries() {
      const query = {
        registration_source: this.getUTMVariable('registration_source') || 'direct',
      };
      const campaign = this.getUTMVariable('utm_campaign');
      if (campaign) {
        query.utm_campaign = campaign;
      }
      const source = this.getUTMVariable('utm_source');
      if (source) {
        query.utm_source = source;
      }
      const medium = this.getUTMVariable('utm_medium');
      if (medium) {
        query.utm_medium = medium;
      }
      if (this.from) {
        query.from = this.from;
      }
      return query;
    },
  },
};

export default urlHelper;
