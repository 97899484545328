import Client from '~/types/api/api-module';
import ApiOptions from '../../api-options';

const BASE_URL = `${process.env.API_V4_HOST}/_exclusive/mitra-edc-check-balance`;

export default Client.wrap({
  getEdcCheckBalanceTransaction(id: number) {
    return this.get(`${BASE_URL}/transactions/${id}`, 'user', new ApiOptions());
  },
  removeEdcCheckBalanceTransaction(id: number) {
    return this.patch(`${BASE_URL}/remove-balance/${id}`, 'user', new ApiOptions());
  },
});
