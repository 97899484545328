import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const RETENTION_URL = `${process.env.API_V4_HOST}/agent-retention`;
const REDEMPTION_URL = `${RETENTION_URL}/point-redemptions`;
const REWARD_URL = `${RETENTION_URL}/points`;

export default Client.wrap({
  retrieveUserPoint() {
    return this.get(`${REWARD_URL}/my-point`, 'user', new ApiOptions());
  },
  retrieveUserPointConversions() {
    return this.get(`${REWARD_URL}/my-point/conversions`, 'user', new ApiOptions());
  },
  retrieveUserTierProgress() {
    return this.get(`${REWARD_URL}/my-point/tier-progresses`, 'user', new ApiOptions());
  },
  retrieveUserTierHistory() {
    return this.get(`${REWARD_URL}/my-point/tier-histories`, 'user', new ApiOptions());
  },
  retrieveTierBenefits(requestPayload = { limit: 20 }) {
    return this.get(`${REWARD_URL}/configs/benefits`, 'user', new ApiOptions({ requestPayload }));
  },
  retrievePointConfigs() {
    return this.get(`${REWARD_URL}/configs`, 'user', new ApiOptions());
  },
  retrieveUserMutations(requestPayload) {
    return this.get(`${REWARD_URL}/my-point/mutations`, 'user', new ApiOptions({ requestPayload }));
  },
  retrieveRewards(requestPayload = { limit: 20 }) {
    return this.get(`${RETENTION_URL}/point-rewards`, 'user', new ApiOptions({ requestPayload }));
  },
  retrieveSingleReward(id) {
    return this.get(`${RETENTION_URL}/point-rewards/${id}`, 'user', new ApiOptions());
  },
  retrieveRedeemRewards(requestPayload) {
    return this.get(`${REDEMPTION_URL}/my-redemptions`, 'user', new ApiOptions({ requestPayload }));
  },
  estimatePoint(requestPayload) {
    return this.post(`${REWARD_URL}/transactions/estimate`, 'user', new ApiOptions({ requestPayload }));
  },
  redeemReward(requestPayload) {
    return this.post(REDEMPTION_URL, 'user', new ApiOptions({ requestPayload }));
  },
  remittedPoint(id) {
    return this.get(`${REWARD_URL}/transactions/${id}`, 'user', new ApiOptions());
  },
  patchOptinLoyalty() {
    return this.patch(`${REWARD_URL}/registrations`, 'user', new ApiOptions());
  },
  retrieveMyMissions(requestPayload) {
    return this.get(`${RETENTION_URL}/missions/my-missions`, 'user', new ApiOptions({ requestPayload }));
  },
});
