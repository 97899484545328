import ApiOptions from '~/api/v4/api-options';
import Client from '~/types/api/api-module';

const COUPONS_URL = `${process.env.API_V4_HOST}/promos/coupons/claims`;

export default Client.wrap({
  getClaimedCoupons(payload) {
    const defaultPayload = {
      sort: 'new',
      with: ['amount', 'percentage', 'product_types', 'platforms'],
    };
    return this.get(
      COUPONS_URL,
      'user',
      new ApiOptions({
        requestPayload: { ...defaultPayload, ...payload },
      })
    );
  },
  claimAllCoupons() {
    return this.post(COUPONS_URL, 'user', new ApiOptions());
  },
});
