import helpers from './helpers';
const PREFIX = 'agenlite_';

// TOP 3 VP TRACKER, post sunset & revival
// legacy tracker for top 3 VP are still there
// yes, there will be double track in some cases. FYI, in apps too
export default {
  productInfoPayloadBuilder(productInfo) {
    const { productCategory, productName, productIdPartner } = productInfo;
    const payload = {
      product_category: productCategory,
      product_name: productName,
      product_id_partner: productIdPartner,
    };
    return payload;
  },
  customerInfoPayloadBuilder(customerInfo) {
    const { customerNumber, customerNumberId, userPhoneNumber } = customerInfo;
    const payload = {
      user_phone_number: userPhoneNumber || '',
    };
    if (customerNumberId) {
      payload.customer_number_id = customerNumberId;
    }
    if (customerNumber !== undefined && customerNumber !== null) {
      payload.customer_number = customerNumber;
    }
    return payload;
  },
  paymentInfoPayloadBuilder(paymentInfo) {
    const { price, originalPrice, totalPurchase, voucherCode, voucherStatus, voucherType, voucherSource } = paymentInfo;
    const payload = {
      price: `${price}`,
      voucher_code: voucherCode || '',
      voucher_status: voucherStatus,
    };
    if (originalPrice) payload.original_price = `${originalPrice}`;
    if (totalPurchase) payload.total_purchase = totalPurchase;
    if (voucherType) payload.voucher_type = voucherType;
    if (voucherSource) payload.voucher_source = voucherSource;
    return payload;
  },
  trackVPClick({ clickSource, targetScreen, sessionTime }) {
    const payload = {
      evn: `${PREFIX}vp_click`,
      session_time: sessionTime,
      click_source: clickSource,
      target_screen: targetScreen,
      journey_id: this.getTrackerJourneyId,
    };
    this.$blTracker.actions.trackActions(payload);
  },
  trackVPScreenClick({ clickSource, clickSourceStatus, params, currentScreen, targetScreen }) {
    const payload = {
      evn: `${PREFIX}vp_product_screen_click`,
      current_screen: currentScreen,
      click_source: clickSource,
      click_source_status: clickSourceStatus,
      journey_id: this.getTrackerJourneyId,
      target_screen: targetScreen,
    };
    payload.params = helpers.generateParams(params);
    this.$blTracker.actions.trackActions(payload);
  },
  trackVPChooseProductClick({ customerInfo, productInfo, paymentInfo }) {
    const payload = {
      evn: `${PREFIX}vp_screen_choose_product_click`,
      journey_id: this.getTrackerJourneyId,
      ...this.customerInfoPayloadBuilder(customerInfo),
      ...this.productInfoPayloadBuilder(productInfo),
      ...this.paymentInfoPayloadBuilder(paymentInfo),
    };
    this.$blTracker.actions.trackActions(payload);
  },
  trackVPVoucherClick({
    customerInfo,
    productInfo,
    paymentInfo,
    clickSource,
    clickSourceStatus = undefined,
    currentScreen,
  }) {
    const payload = {
      evn: `${PREFIX}vp_voucher_click`,
      click_source: clickSource,
      current_screen: currentScreen ?? this.$route.name,
      journey_id: this.getTrackerJourneyId,
      ...this.customerInfoPayloadBuilder(customerInfo),
      ...this.productInfoPayloadBuilder(productInfo),
      ...this.paymentInfoPayloadBuilder(paymentInfo),
    };
    if (clickSourceStatus) payload.click_source_status = clickSourceStatus;
    this.$blTracker.actions.trackActions(payload);
  },
  trackVPPayProductClick({
    customerInfo,
    paymentInfo,
    productInfo,
    invoiceId,
    agentType,
    sessionTime,
    sessionId,
    kontakPelangganName,
    organic,
    productName,
    paymentMethod,
    clickSourceStatus = undefined,
    screenName,
  }) {
    const payload = {
      evn: `${PREFIX}click_bayar_product_vp`,
      ...this.customerInfoPayloadBuilder(customerInfo),
      ...this.paymentInfoPayloadBuilder(paymentInfo),
      ...this.productInfoPayloadBuilder(productInfo),
      is_rooted_device: false,
      screen_name: screenName || this.$route.name,
      click_source: 'buy',
      agent_type: agentType,
      is_kontak_auto_generator_clicked: !!kontakPelangganName,
      kontak_pelanggan_name: kontakPelangganName,
      session_time: sessionTime || 0,
      session_id: sessionId || '',
      invoice_number: invoiceId || '',
      payment_method: paymentMethod,
      content_type: 'product',
      currency: 'IDR',
      product_type: 'vp',
      product_quantity: 1,
      product_name: productName,
      journey_id: this.getTrackerJourneyId,
      organic,
    };
    if (clickSourceStatus) payload.click_source_status = clickSourceStatus;
    this.$blTracker.actions.trackActions(payload);
  },
  trackVPChoosePaymentMethodClick({ productInfo, customerInfo, paymentInfo, clickSource, balance }) {
    const { wallet, dana } = balance;
    const payload = {
      evn: `${PREFIX}vp_payment_method_click`,
      journey_id: this.getTrackerJourneyId,
      ...this.customerInfoPayloadBuilder(customerInfo),
      ...this.productInfoPayloadBuilder(productInfo),
      ...this.paymentInfoPayloadBuilder(paymentInfo),
      dana_balance: dana,
      wallet_balance: wallet,
      click_source: clickSource,
    };
    this.$blTracker.actions.trackActions(payload);
  },
  trackVPDetailTrxClick({
    currentScreen,
    clickSource,
    invoiceId,
    transactionStatus,
    productName,
    productIdPartner,
    productCategory,
    customerNumber,
    customerNumberId,
    userPhoneNumber,
    voucherCode,
  }) {
    const payload = {
      evn: `${PREFIX}vp_detail_transaction_click`,
      journey_id: this.getTrackerJourneyId,
      source_journey_id: this.getTrackerJourneyId,
      click_source: clickSource,
      current_screen: currentScreen,
      invoice_id: invoiceId,
      transaction_status: transactionStatus,
      product_name: productName,
      product_id_partner: productIdPartner,
      product_category: productCategory,
      customer_number: customerNumber,
      user_phone_number: userPhoneNumber,
      referrer_screen: this.routeReferrer?.name || '',
      referrer_url: this.routeReferrer?.url || '',
    };

    // Optional fields
    if (customerNumberId) {
      payload.customer_number_id = customerNumberId;
    }
    if (voucherCode) {
      payload.voucher_code = voucherCode;
    }

    this.$blTracker.actions.trackActions(payload);
  },
  trackVPScreenVisit({ currentScreen }) {
    const payload = {
      env: `${PREFIX}screen_visit`,
      journey_id: this.getTrackerJourneyId,
      referrer_screen: this.routeReferrer?.name || '',
      referrer_url: this.routeReferrer?.url || '',
      current_screen: currentScreen,
    };
    this.$bltracker.actions.trackActions(payload);
  },
};
