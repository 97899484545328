import {
  PublicCreateCustomerEWalletTransactionRequest,
  PublicCreateCustomerPrepaidPhoneTransactionRequest,
  PublicCreateCustomerEWalletInquiryRequest,
  PublicCreateCustomerSubscriptionInstantRefundRequest,
} from '@bukalapak/openapi-schema/vptrader/transaction/v1/public/transaction-domain';
import ApiOptions from '~/api/v4/api-options';
import Client from '~/types/api/api-module';

const BASE_URL = `${process.env.API_VPNONTRADER_HOST}/v1/customer/transactions`;
const BASE_V2_URL = `${process.env.API_VPNONTRADER_HOST}/v2/customer/transactions`;

export default Client.wrap({
  getPrepaidPhoneTopupTransaction(id: number) {
    return this.get(`${BASE_V2_URL}/prepaid-phone/${id}`, 'transaction.*.*', {
      enableAuthHeader: true,
      ...new ApiOptions(),
    });
  },
  createPrepaidPhoneTransaction(requestPayload: PublicCreateCustomerPrepaidPhoneTransactionRequest) {
    return this.post(`${BASE_URL}/prepaid-phones`, 'transaction.*.*', {
      enableAuthHeader: true,
      ...new ApiOptions({ requestPayload }),
    });
  },
  getEWalletTransaction(id: number) {
    return this.get(`${BASE_V2_URL}/e-wallet/${id}`, 'transaction.*.*', {
      enableAuthHeader: true,
      ...new ApiOptions(),
    });
  },
  createEWalletTransaction(requestPayload: PublicCreateCustomerEWalletTransactionRequest) {
    return this.post(`${BASE_URL}/e-wallets`, 'transaction.*.*', {
      enableAuthHeader: true,
      ...new ApiOptions({ requestPayload }),
    });
  },
  createEWalletInquiry(requestPayload: PublicCreateCustomerEWalletInquiryRequest) {
    return this.post(`${BASE_URL}/e-wallets/inquiry`, 'transaction.*.*', {
      enableAuthHeader: true,
      ...new ApiOptions({ requestPayload }),
    });
  },
  createCustomerSubscriptionInstantRefund(requestPayload: PublicCreateCustomerSubscriptionInstantRefundRequest) {
    return this.post(`${BASE_URL}/subscriptions/refund`, 'transaction.*.*', {
      enableAuthHeader: true,
      ...new ApiOptions({ requestPayload }),
    });
  },
  getCustomerSubscriptionInstantRefundEligibility(requestPayload) {
    return this.get(`${BASE_URL}/subscriptions/refund-eligibility`, 'transaction.*.*', {
      enableAuthHeader: true,
      ...new ApiOptions({ requestPayload }),
    });
  },
});
