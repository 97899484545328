import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const INFO_URL = `${process.env.API_HOST}/info`;

export default Client.wrap({
  retrieveInfoAddresses() {
    return this.get(`${INFO_URL}/addresses`, 'public', new ApiOptions());
  },
  // retrieveSupportedBanks
  fetchBankList() {
    return this.get(`${INFO_URL}/banks`, 'public', new ApiOptions());
  },
});
