import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';
import RequestBuilder from '../../request_builder';

const AGENT_BANKS_URL = `${process.env.API_V4_HOST}/agents/banks`;
const BANK_TRANSFER_URL = `${process.env.API_V4_HOST}/agents/bank-transfers`;

export default Client.wrap({
  // API interface
  Public: {
    addBankAccount(data, userId) {
      const requestHeaders = RequestBuilder.getOtpHeader(this.getOtp(userId).key);
      return this.v4.agentBanks.addBankAccountEndpoint(data, requestHeaders);
    },
    requestSendMoney(data, userId) {
      const requestHeaders = RequestBuilder.getOtpHeader(this.getOtp(userId).key);
      return this.v4.agentBanks.requestSendMoneyEndpoint(data, requestHeaders);
    },
    async fetchSingleBankAccount(id) {
      const response = await this.v4.agentBanks.fetchBankAccounts();
      return response.data.find(item => item.id === id);
    },
  },
  Private: {
    fetchBankAccounts() {
      return this.get(AGENT_BANKS_URL, 'user', new ApiOptions());
    },
    addBankAccountEndpoint(requestPayload, requestHeaders) {
      return this.post(AGENT_BANKS_URL, 'user', new ApiOptions({ requestHeaders, requestPayload }));
    },
    requestSendMoneyEndpoint(requestPayload, requestHeaders) {
      return this.post(BANK_TRANSFER_URL, 'user', new ApiOptions({ requestHeaders, requestPayload }));
    },
    removeBankAccount(entryId) {
      return this.delete(`${AGENT_BANKS_URL}/${entryId}`, 'user', new ApiOptions());
    },
  },
});
