import { Plugin } from '@nuxt/types';
import externalVisitTrackerJs from '@bukalapak/external-visit-tracker-js';
import { datadogLogs } from '@datadog/browser-logs';
import Cookies from 'js-cookie';
import { generateBrowserID, getPlatform } from '~/utils/platform';

const clientToken = process.env.DATADOG_LOG_ID || '';
const env = /preproduction|local/i.test(window.location.host) ? 'preproduction' : 'production';
const sampleRate = 50;
const service = 'external-visit-tracker-js';

const ExternalVisitTrackerJs: Plugin = ({ app, store, route }) => {
  const userId = store.getters.getUserId36;
  const axios = app.$axios;
  const clientId = generateBrowserID();
  const platform = getPlatform();
  const { identity } = app.$credentials;
  const forwardErrorsToLogs = false; // Set to false to stop forwarding console.error logs, uncaught exceptions and network errors to Datadog.
  const cookieDomain = process.env.WEB_DOMAIN;
  const action = route.name === 'authorize_page' ? 'login' : 'visit';
  const cookieLoginName = 'track_login';
  
  datadogLogs.init({
    clientToken,
    env,
    sampleRate,
    service,
    forwardErrorsToLogs
  });

  datadogLogs.addLoggerGlobalContext('host', window.location.origin);

  externalVisitTrackerJs.run({
    action,
    cookieDomain,
    axios,
    userId,
    clientId,
    platform,
    identity,
    logger: {
      error: (message, messageContext) => datadogLogs && datadogLogs.logger.error(message, messageContext),
    },
    afterSend: () => {
      if (Cookies.get(cookieLoginName))
        { Cookies.remove(cookieLoginName, { path: '/', domain: cookieDomain }); }
    },
  });
};

export default ExternalVisitTrackerJs;
