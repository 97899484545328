import ApiOptions from '~/api/v4/api-options';
import Client from '~/types/api/api-module';

const CUSTOMER_PACKAGE_COLLECTIONS_URL = `${process.env.API_VPNONTRADER_HOST}/v1/customer-package-collections`;

export default Client.wrap({
  getCustomerPackageCollections(requestPayload) {
    return this.get(CUSTOMER_PACKAGE_COLLECTIONS_URL, 'product.*.*', {
      enableAuthHeader: true,
      ...new ApiOptions({ requestPayload }),
    });
  },
});
