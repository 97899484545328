const initialState = {
  routeReferrer: {},
  curentRoute: {},
};

const getters = {
  getRouteReferrer: state => state.routeReferrer,
  getCurrentRoute: state => state.curentRoute,
};

const mutations = {
  saveCurrentRoute: (state, { route, referrer }) => {
    state.routeReferrer = referrer;
    state.curentRoute = route;
  },
};

export default {
  state: () => ({
    ...initialState,
  }),
  getters,
  mutations,
  actions: {},
};
