import mixins from 'vue-typed-mixins';
import { promLatency } from '~/server/helpers/metrics-helper';
import { timeDiffSeconds } from '~/server/helpers/date-helper';

export const PreloaderMixin = mixins().extend({
  data() {
    return {
      preloadMarker: 0,
      lastDatetime: 0,
      isRecorderActive: true,
      recordDelayThreshold: 3500, // throttle the record so it does not do frequently
      delayRecordTolerance: 60, // in seconds, skipping data that have value higher than this
      isOnline: true,
    };
  },
  computed: {
    pageStyle() {
      return { filter: this['isOnline'] ? '' : 'grayscale(1)' }; // somehow typescript vue fails to detect isOnline as property, so we access it via named array
    },
  },
  methods: {
    trackMetric() {
      // Skip if selected route includes number, id related
      // when tracked, isRecorderActive must be currently true
      if (/\d/.test(this.$route.path)) return;
      if (this.$route.path.split('/').length > 4) return;
      if (!this.isRecorderActive || this.lastDatetime === 0) return;
      // Skipping extreme data metrics
      const timediff = timeDiffSeconds(this.lastDatetime, new Date().getTime());
      if (timediff > this.delayRecordTolerance) {
        this.clearRecorder();
        return;
      }
      // Begin record
      this.isRecorderActive = false; // Thresholding
      promLatency(this.lastDatetime, this.$route.path, 'ok', 'sentinel_route_latency');
      this.lastDatetime = 0;
      // Turn it back on again after threshold done in separate thread
      setTimeout(this.clearRecorder, this.recordDelayThreshold);
    },
    clearRecorder() {
      this.isRecorderActive = true;
      this.lastDatetime = 0;
    },
    checkOnline() {
      this.isOnline = navigator.onLine;
    },
    initOnlineStatus() {
      if (this.$refs.agenliteApp !== null) {
        window.addEventListener('load', this.checkOnline);
        window.addEventListener('online', this.checkOnline);
        window.addEventListener('offline', this.checkOnline);
      }
    },
    removePreloader() {
      const preloadDOM = document.getElementById('pr-b');
      preloadDOM?.parentNode?.removeChild(preloadDOM);
      const preloadScript = document.getElementById('pr-s');
      preloadScript?.parentNode?.removeChild(preloadScript);
    },
  },
  watch: {
    preloadMarker(val) {
      if (val === 0) return;
      this.removePreloader();
    },
  },
  beforeDestroy() {
    window.removeEventListener('online', this.checkOnline);
    window.removeEventListener('offline', this.checkOnline);
  },
  async mounted() {
    this.initOnlineStatus();
    await this.$nextTick();
    this.preloadMarker = 1;
  },
});

export default PreloaderMixin;
