import { Route } from 'vue-router';

export const getScreenName = (route: Route) => {
  const meta = Array.isArray(route.meta) ? route.meta[route.meta.length - 1] : route.meta;
  let screenName = meta?.name || route?.name;

  if (screenName === 'due_debt_calendar') {
    const { tab = 'today' } = route.query;
    const dueDebtCalendarScreenName = {
      past: 'past_due',
      today: 'today',
      future: 'coming_up',
    };
    // @ts-ignore
    screenName = `debt_notes_due_date_calendar/${dueDebtCalendarScreenName[tab]}`;
  }

  return screenName;
};
