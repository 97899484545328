import ApiOptions from '../api-options';

const GENERAL_TRADE_URL = `${process.env.API_V4_HOST}/general-trade`;
const ADDRESS_URL = `${GENERAL_TRADE_URL}/addresses`;

export default {
  createAddress(requestPayload) {
    return this.post(ADDRESS_URL, 'user', new ApiOptions({ requestPayload }));
  },
  updateAddress(requestPayload, paramId) {
    return this.patch(`${ADDRESS_URL}/${paramId}`, 'user', new ApiOptions({ requestPayload }));
  },
};
