import ApiOptions from '~/api/v4/api-options';
import Client from '~/types/api/api-module';

const CUSTOMER_PRODUCTS_URL = `${process.env.API_VPNONTRADER_HOST}/v1/customer-products`;

export default Client.wrap({
  getCustomerProducts(requestPayload) {
    return this.get(CUSTOMER_PRODUCTS_URL, 'product.*.*', {
      enableAuthHeader: true,
      ...new ApiOptions({ requestPayload }),
    });
  },
});
