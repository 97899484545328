
export const getErrorMessage = (error = null, message = '') => {
  let errorMessage = '';
  if (error) {
    if (error.message === 'Failed to fetch') {
      errorMessage = 'Pastikan koneksi internet kamu tidak terganggu.';
    } else if (error.message === 'REQUEST LIMITED') {
      errorMessage = 'Mohon tunggu beberapa saat lagi.';
    } else if (error instanceof Error) {
      console.error(error, message);
    } else if (error.message) {
      errorMessage = error.message;
    } else if (error.errors) {
      errorMessage = (error.errors[0].message) ? error.errors[0].message : '';
    } else {
      console.error(error, message);
    }
  }
  return `${message} ${errorMessage}`;
};

const checkLoginError = (error, commit) => {
  if (error && error.meta && error.meta.http_status === 401 &&
    error.errors[0] && !error.errors[0].code) {
    commit('setLogout');
  }
};

const initialState = {
  needLogout: false,
};

const getters = {
  isAppNeedLogout: state => state.needLogout,
};

const actions = {
  isValidResponse(_, response) {
    return (
      (response.status && response.status === 'OK') ||
      (response.meta && response.meta.http_status === 200)
    );
  },
  catchError({ commit, dispatch }, { error, message, finishTransaction, hideAppLoading, noFlashAlert }) {
    checkLoginError(error, commit);
    if (noFlashAlert) {
      console.error(error);
    } else {
      dispatch('showFlashAlert', {
        message: getErrorMessage(error, message),
        type: 'warning',
      });
    }
    if (finishTransaction) dispatch('finishTransaction', { status: false });
    if (hideAppLoading) dispatch('hideAppLoading');
  },
};

const mutations = {
  setLogout(state) {
    state.needLogout = true;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
