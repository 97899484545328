import { GetterTree, MutationTree } from 'vuex';

export type MultipleDebtNotesState = {
  debtNoteCounter: number;
  myDebtNoteCounter: number;
  highlightedCustomers: [];
};
const initialState: MultipleDebtNotesState = {
  debtNoteCounter: 0,
  myDebtNoteCounter: 0,
  highlightedCustomers: []
};

const getters: GetterTree<MultipleDebtNotesState, {}> = {
  getTotalCounter: state => state.debtNoteCounter + state.myDebtNoteCounter,
  getCounter: state => (type: string = 'debtnote') => {
    return type === 'debtnote' ? state.debtNoteCounter : state.myDebtNoteCounter;
  },
  isHighlightedCustomer: state => id => {
    return state.highlightedCustomers.includes(id as never);
  },
};

const mutations: MutationTree<MultipleDebtNotesState> = {
  addHighlightedCustomer: (state, id) => {
    const customerIndex = state.highlightedCustomers.indexOf(id as never);
    if (customerIndex === -1) {
      state.highlightedCustomers.push(id as never);
    }
  },
  addCounter: (state, type = 'debtnote') => {
    if (type === 'debtnote') {
      state.debtNoteCounter += 1;
    } else {
      state.myDebtNoteCounter += 1;
    }
  },
  removeHighlightedCustomer: (state, id) => {
    const customerIndex = state.highlightedCustomers.indexOf(id as never);
    if (customerIndex > -1) {
      state.highlightedCustomers.splice(customerIndex, 1);
    }
  },
  resetCounter: state => {
    state.debtNoteCounter = 0;
    state.myDebtNoteCounter = 0;
  },
  resetHighlightedCustomers: state => {
    state.highlightedCustomers = [];
  }
};

export default {
  state: () => ({
    ...initialState,
  }),
  getters,
  mutations,
};
