import AgenliteAPIClient from '~/api/index';

/** this store modules is for BayarBukalapak */

const initialState = {
  fee: null,
};

const getters = {
  getBillerFee(state) {
    return state.fee;
  },
};
const mutations = {
  setBillerFee(state, payload) {
    state.fee = payload;
  },
};

const actions = {
  async getMitraBillerInquiry({ dispatch }, payload) {
    try {
      dispatch('hideFlashAlert');
      const response = await AgenliteAPIClient.getMitraBillerInquiry(payload);
      return response.data;
    } catch (err) {
      let message = 'Gagal mendapatkan data tagihan.';
      if (err.errors && err.errors[0]) {
        if (err.errors[0].code === 20402) {
          message = 'Nomor tagihan tidak ditemukan atau sudah dibayar.';
        } else if (err.errors[0].code === 20601) {
          message = 'Saat ini BukaDompet sedang tidak dapat digunakan.';
        } else if (err.errors[0].code === 20602) {
          message = 'Tagihan kadaluarsa.';
        }
      }

      dispatch('catchError', { err, message, hideAppLoading: true });
      throw err;
    }
  },
  async payMitraBill({ dispatch }, payload) {
    try {
      dispatch('hideFlashAlert');
      const response = await AgenliteAPIClient.payMitraBill({
        ...payload,
        date_time: new Date().toISOString(),
      });
      return response;
    } catch (err) {
      dispatch('catchError', { err, message: 'Gagal melakukan pembayaran, coba lagi nanti.', hideAppLoading: true });
      throw err;
    }
  },
  async retrieveBillerFee({ dispatch, state, commit }) {
    try {
      const response = await AgenliteAPIClient.getPaymentFees();
      if (response.meta.http_status === 200) {
        const paymentMethod = response.data.find(pm => pm.payment_type === 'mitra_bukalapak');
        const fee = paymentMethod.fees.find(f => f.name === 'cashier_fee');
        commit('setBillerFee', parseInt(fee.amount, 10));
      } else {
        dispatch('catchError', { message: 'Gagal mendapatkan biaya admin', hideAppLoading: true });
      }
    } catch (err) {
      if (state.fee === null) { dispatch('catchError', { err, message: 'Gagal mendapatkan biaya admin', hideAppLoading: true }); } else console.warn('Failed to get cashier fee, using old value');
      throw err;
    }
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
