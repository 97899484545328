import r from '~/utils/render-utils';

const PageDigitalVoucherTransactionDetail = () =>
  import(
    /* webpackChunkName: 'digital-voucher-transaction-detail-page' */ '~/pages/NewVirtualProducts/DigitalVoucher/TransactionDetail'
  );
const PagePrepaidPhoneTransactionDetail = () =>
  import(
    /* webpackChunkName: 'prepaid-phone-topup-transaction-detail-page' */ '~/pages/NewVirtualProducts/PrepaidPhoneDetail'
  );
const PageTransactionDetail = () =>
  import(/* webpackChunkName: 'vp-transaction-detail-page' */ '~/pages/NewVirtualProducts/TransactionDetail');
const PagePrepaidPhoneTransaction = () =>
  import(
    /* webpackChunkName: 'prepaid-phone-topup-transaction-page' */ '~/pages/NewVirtualProducts/PrepaidPhoneTransaction'
  );
const PageDigitalSendTransactionDetail = () =>
  import(/* webpackChunkName: 'digital-send-transaction-detail-page' */ '~/pages/NewVirtualProducts/DigitalSendDetail');
const PageEdcCheckBalanceTransactionDetail = () =>
  import(
    /* webpackChunkName: 'edc-check-balance-transaction-detail-page' */ '~/pages/NewVirtualProducts/EdcCheckBalanceDetail'
  );
const PageQrisCashOutTransactionDetail = () =>
  import(
    /* webpackChunkName: 'qris-cash-out-transaction-detail-page' */ '~/pages/NewVirtualProducts/QrisCashOutDetail'
  );
const PageEdcCashOutTransactionDetail = () =>
  import(
    /* webpackChunkName: 'edc-cash-out-transaction-detail-page' */ '~/pages/NewVirtualProducts/EdcCashOutDetail'
  );
const PageDigitalVoucher = () =>
  import(/* webpackChunkName: 'digital-voucher-index-page' */ '~/pages/NewVirtualProducts/DigitalVoucher/Index');
const PageDigitalVoucherTerms = () =>
  import(/* webpackChunkName: 'digital-voucher-terms-page' */ '~/pages/NewVirtualProducts/DigitalVoucher/Terms');
const PageDigitalVoucherVendorSelection = () =>
  import(
    /* webpackChunkName: 'digital-voucher-vendor-selection-page' */ '~/pages/NewVirtualProducts/DigitalVoucher/VendorSelection'
  );
const PageDigitalVoucherPriceList = () => import(/* webpackChunkName: 'dummy' */ '~/pages/NewVirtualProducts/dummy');
const PageDigitalVoucherContactList = () => import(/* webpackChunkName: 'dummy' */ '~/pages/NewVirtualProducts/dummy');
const PageDigitalVoucherTransact = () =>
  import(
    /* webpackChunkName: 'digital-voucher-transaction-page' */ '~/pages/NewVirtualProducts/DigitalVoucher/Transaction'
  );
const PageDigitalVoucherSaveCustomer = () => import(/* webpackChunkName: 'dummy' */ '~/pages/NewVirtualProducts/dummy');
const PageDigitalVoucherTopupSaldo = () => import(/* webpackChunkName: 'dummy' */ '~/pages/NewVirtualProducts/dummy');

const routes = [
  {
    path: '/virtual-products/digital-voucher/detail',
    component: r(PageDigitalVoucherTransactionDetail),
    name: 'digital_voucher_trx_detail',
  },
  {
    path: '/virtual-products/prepaid-phone-topup/detail',
    component: r(PagePrepaidPhoneTransactionDetail),
    name: 'prepaid_phone_topup_trx_detail',
  },
  { path: '/virtual-products/detail', component: r(PageTransactionDetail), name: 'vp_trx_detail' },
  {
    path: '/virtual-products/digital-send/detail',
    component: r(PageDigitalSendTransactionDetail),
    name: 'digital-sendtrx_detail',
  },
  {
    path: '/virtual-products/edc-check-balance/detail',
    component: r(PageEdcCheckBalanceTransactionDetail),
    name: 'edc-check-balancetrx_detail',
  },
  {
    path: '/virtual-products/edc-cash-out/detail',
    component: r(PageEdcCashOutTransactionDetail),
    name: 'edc-cash-out-trx_detail',
  },
  {
    path: '/virtual-products/qris-cash-out/detail',
    component: r(PageQrisCashOutTransactionDetail),
    name: 'qris-cash-outtrx_detail',
  },
  {
    path: '/buka-20/prepaid-phone/transact',
    component: r(PagePrepaidPhoneTransaction),
    name: 'prepaid_phone_transact',
  },
  {
    path: '/buka-20/topup-digital',
    name: 'topup_digital',
    component: r(PageDigitalVoucher),
    children: [
      { path: 'terms', component: r(PageDigitalVoucherTerms), name: 'topup_digital_terms' },
      { path: 'vendor', component: r(PageDigitalVoucherVendorSelection), name: 'topup_digital_vendor_selection' },
      { path: 'price-list', component: r(PageDigitalVoucherPriceList), name: 'topup_digital_price_list' },
      { path: 'contact-list', component: r(PageDigitalVoucherContactList), name: 'topup_digital_contact_list' },
      {
        path: 'transact/:code',
        component: r(PageDigitalVoucherTransact),
        name: 'topup_digital_transact',
      },
      { path: 'save-customer', component: r(PageDigitalVoucherSaveCustomer), name: 'topup_digital_save_customer' },
      { path: 'topup-saldo', component: r(PageDigitalVoucherTopupSaldo), name: 'topup_digital_topup_saldo' },
    ],
  },
];

const vpTrxDetailPath = routes.find(r => r.name === 'vp_trx_detail')?.path;

export { vpTrxDetailPath };
export default routes;
