





import Vue from 'vue';
import Cookies from 'js-cookie';
import BlLoadingDialogMv from '@bukalapak/bazaar-mweb/dist/components/BlLoadingDialogMv';
import AgenliteLocalForage from '~/plugins/localforage';

export default Vue.extend({
  name: 'Logout',
  components: {
    BlLoadingDialogMv,
  },
  props: {
    baseUrl: {
      type: String,
      default: `${process.env.WEB_HOST}`,
    },
    oauthHost: {
      type: String,
      default: `${process.env.OAUTH_HOST}`,
    },
    devMode: {
      type: Boolean,
      default: process.env.NODE_ENV !== 'production',
    },
  },
  data() {
    return {
      showLoader: true,
    };
  },
  async mounted() {
    await Promise.all([
      this.$store.dispatch('logout'),
      this.$store.dispatch('clearIdentity'),
      this.$store.dispatch('resetState'),
      AgenliteLocalForage.clear(),
    ]);

    if (this.devMode) {
      // INFO: DEVELOPMENT MODE kicks login state via popup because comeback
      // does not work on localhost (referer must be preprod/prod)
      Cookies.remove('first_time_register');
      await this.createLogoutPopup();
    }
    const redirTarget = `${this.baseUrl}/${this.$route.query.to ?? ''}`;
    // Dev: redir to baseUrl + to | Prod: redir to accounts.bukalapak.com/logout
    const redirPath = this.devMode ? redirTarget : `${this.oauthHost}/logout`;
    // Dev: utm_ params | Prod: utm_ params + comeback
    const query = { ...this.$route.query };
    delete query.to;
    const params = { ...query, ...(!this.devMode && { comeback: redirTarget }) };

    this.$location.redirect(redirPath, params);
  },
  methods: {
    createLogoutPopup() {
      const pitfallUrl = `${this.baseUrl}/_dev/pitfall`;
      const features = Object.entries({
        height: 100,
        width: 100,
        menubar: 0,
        status: 0,
        top: 25,
        left: 0,
      })
        .map(([prop, val]) => `${prop}=${val}`)
        .join(',');
      const popup = window.open(
        `${this.oauthHost}/logout?comeback=${encodeURIComponent(pitfallUrl)}`,
        '_blank',
        features
      );
      return new Promise<void>(res => {
        setInterval(() => {
          if (popup?.closed) res();
        }, 500);
        setTimeout(() => {
          popup?.close();
          res();
        }, 5000);
      });
    },
  },
});
