import AgenliteAPIClient from '~/api';

const initialState = {
  kurirAgent: {},
  bill: {},
  limit: {},
  commission: {},
  commissionList: [],
  kurirTransactionDetail: {},
  kurirTransactionList: [],
};

const getters = {
  getKurirAgent(state) {
    return state.kurirAgent;
  },
  getKurirBill(state) {
    return state.bill;
  },
  getKurirLimit(state) {
    return state.limit;
  },
  getKurirCommission(state) {
    return state.commission;
  },
  getKurirCommissionList(state) {
    return state.commissionList;
  },
  getKurirTransactionDetail(state) {
    return state.kurirTransactionDetail;
  },
  getKurirTransactionList(state) {
    return state.kurirTransactionList;
  },
};

const actions = {
  async retrieveKurirAgent({ dispatch, commit }, willCommit = true) {
    let response = null;
    try {
      response = await AgenliteAPIClient.retrieveKurirAgent();
      if (willCommit) commit('retrieveKurirAgent', response.data);
      if (response.meta.http_status !== 200) throw response;
    } catch (error) {
      const message = 'Gagal mendapatkan data kurir.';
      dispatch('catchError', { error, message, noFlashAlert: true });
    } finally {
      dispatch('hideFlashAlert');
    }
    return response;
  },
  async fetchKurirBill({ dispatch, commit }, willCommit = true) {
    let response = null;
    try {
      response = await AgenliteAPIClient.fetchKurirBill();
      if (willCommit) commit('setKurirBill', response.data.amount);
      if (response.meta.http_status !== 200) throw response;
    } catch (error) {
      const message = 'Gagal mendapatkan data bill kurir.';
      dispatch('catchError', { error, message, noFlashAlert: true });
    } finally {
      dispatch('hideFlashAlert');
    }
    return response;
  },
  async fetchKurirLimit({ dispatch, commit }, willCommit = true) {
    let response = null;
    try {
      response = await AgenliteAPIClient.fetchKurirLimit();
      if (willCommit) commit('setKurirLimit', response.data);
      if (response.meta.http_status !== 200) throw response;
    } catch (error) {
      const message = 'Gagal mendapatkan data limit kurir.';
      dispatch('catchError', { error, message, noFlashAlert: true });
    } finally {
      dispatch('hideFlashAlert');
    }
    return response;
  },
  async fetchKurirCommission({ dispatch, commit }) {
    let response = null;
    try {
      response = await AgenliteAPIClient.fetchKurirCommission();
      commit('setKurirCommission', response.data.amount);
      commit('setKurirCommissionList', response.data.commission_list);
      if (response.meta.http_status !== 200) throw response;
    } catch (error) {
      const message = 'Gagal mendapatkan data komisi kurir.';
      dispatch('catchError', { error, message, noFlashAlert: true });
    } finally {
      dispatch('hideFlashAlert');
    }
    return response;
  },
  async fetchKurirTransactionDetail({ dispatch, commit }, data) {
    const transactionType = data.type === 'topups' ? 'kurir_topup_mitra' : 'kurir_deposit_saldo';
    let response = null;
    let payload = null;
    try {
      response = await AgenliteAPIClient.fetchKurirTransactionDetail(data);
      payload = response.data;
      Object.assign(payload, { type: transactionType });
      commit('setKurirTransactionDetail', payload);
      dispatch('hideFlashAlert');
    } catch (error) {
      const message = 'Gagal mendapatkan data detail transaksi.';
      dispatch('catchError', { error, message, noFlashAlert: true });
    }
    return response;
  },
  async fetchKurirTopupTransactionList({ dispatch, commit }) {
    let response = null;
    try {
      response = await AgenliteAPIClient.fetchKurirTopupTransactionList();
      commit('setKurirTransactionList', response.data);
      if (response.meta.http_status !== 200) throw response;
    } catch (error) {
      const message = 'Gagal mendapatkan data list topup kurir.';
      dispatch('catchError', { error, message, noFlashAlert: true });
    } finally {
      dispatch('hideFlashAlert');
    }
    return response;
  },
  async fetchKurirDepositTransactionList({ dispatch, commit }) {
    let response = null;
    try {
      response = await AgenliteAPIClient.fetchKurirDepositTransactionList();
      commit('setKurirTransactionList', response.data);
      if (response.meta.http_status !== 200) throw response;
    } catch (error) {
      const message = 'Gagal mendapatkan data list deposit kurir.';
      dispatch('catchError', { error, message, noFlashAlert: true });
    } finally {
      dispatch('hideFlashAlert');
    }
    return response;
  },
  async createTopupMitraTransaction({ dispatch }, data) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.createTopupMitraTransaction(data);
      if (response.meta.http_status !== 201) throw response;
    } catch (error) {
      const message = 'Gagal melakukan pengiriman saldo.';
      if (error.errors && error.errors[0].code !== 67696) { dispatch('catchError', { error, message, hideAppLoading: true }); }
      throw error;
    } finally {
      dispatch('hideAppLoading');
      dispatch('hideFlashAlert');
    }
    return response;
  },
  async payTopupMitraTransaction({ dispatch }, data) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.payTopupMitraTransaction(data);
      if (response.meta.http_status !== 201) throw response;
    } catch (error) {
      const message = 'Gagal melakukan pengiriman saldo.';
      dispatch('catchError', { error, message, hideAppLoading: true });
      throw error;
    } finally {
      dispatch('hideAppLoading');
      dispatch('hideFlashAlert');
    }
    return response;
  },
};

const mutations = {
  retrieveKurirAgent(state, kurirAgent) {
    state.kurirAgent = kurirAgent;
  },
  setKurirBill(state, bill) {
    state.bill = bill;
  },
  setKurirLimit(state, limit) {
    state.limit = limit;
  },
  setKurirCommission(state, payload) {
    state.commission = payload;
  },
  setKurirCommissionList(state, list) {
    state.commissionList = list;
  },
  setKurirTransactionDetail(state, payload) {
    state.kurirTransactionDetail = payload;
  },
  setKurirTransactionList(state, transactionList) {
    state.kurirTransactionList = transactionList;
  },
  yieldAggregate(state, bundle) {
    const module = bundle.topupKurir;
    if (!module) return;

    Object.keys(module).forEach(i => {
      state[i] = module[i];
    });
  },
  resetState(state) {
    state.kurirAgent = JSON.parse(JSON.stringify(initialState.kurirAgent));
    state.bill = JSON.parse(JSON.stringify(initialState.bill));
    state.limit = JSON.parse(JSON.stringify(initialState.limit));
    state.commission = JSON.parse(JSON.stringify(initialState.commission));
    state.commissionList = JSON.parse(JSON.stringify(initialState.commissionList));
    state.kurirTransactionDetail = JSON.parse(JSON.stringify(initialState.kurirTransactionDetail));
    state.kurirTransactionList = JSON.parse(JSON.stringify(initialState.kurirTransactionList));
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
