import r from '~/utils/render-utils';

const LoyaltyTieringIndex = () => import(/* webpackChunkName: 'loyalty-tiering-page' */ '~/pages/LoyaltyTiering/index');
const LoyaltyTieringTierList = () => import(/* webpackChunkName: 'loyalty-tiering-page' */ '~/pages/LoyaltyTiering/tier-list');
const LoyaltyTieringProgress = () => import(/* webpackChunkName: 'loyalty-tiering-page' */ '~/pages/LoyaltyTiering/progress');

const LoyaltyTieringHistory = () => import(/* webpackChunkName: 'loyalty-tiering-page-2' */ '~/pages/LoyaltyTiering/history');
const LoyaltyTieringInfo = () => import(/* webpackChunkName: 'loyalty-tiering-page-2' */ '~/pages/LoyaltyTiering/info');

const vpins = [
  {
    path: '/loyalty-tiering',
    component: r(LoyaltyTieringIndex),
    name: 'loyalty_tiering_landing_page',
  },
  {
    path: '/loyalty-tiering/history',
    component: r(LoyaltyTieringHistory),
    name: 'loyalty_tiering_history',
  },
  {
    path: '/loyalty-tiering/tier-list',
    component: r(LoyaltyTieringTierList),
    name: 'loyalty_tiering_tier_list',
  },
  {
    path: '/loyalty-tiering/info',
    component: r(LoyaltyTieringInfo),
    name: 'loyalty_tiering_info',
  },
  {
    path: '/loyalty-tiering/progress',
    component: r(LoyaltyTieringProgress),
    name: 'loyalty_tiering_progress',
  },
];

export default vpins;
