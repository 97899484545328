import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const BASE_URL = `${process.env.API_V4_HOST}/digital-vouchers`;

export default Client.wrap({
  Private: {
    getDigitalVoucherTransaction(id: number) {
      return this.get(`${BASE_URL}/transactions/${id}`, 'user', new ApiOptions());
    }
  },
});