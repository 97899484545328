import Client from '~/types/api/api-module';
import ApiOptions from '../../api-options';

const TOPUP_KURIR_URL = `${process.env.API_V4_HOST}/_exclusive/payouts/topup-kurir`;

export default Client.wrap({
  retrieveKurirAgent() {
    return this.get(`${TOPUP_KURIR_URL}/me`, 'user', new ApiOptions());
  },
  fetchKurirBill() {
    return this.get(`${TOPUP_KURIR_URL}/bills`, 'user', new ApiOptions());
  },
  fetchKurirLimit() {
    return this.get(`${TOPUP_KURIR_URL}/limits`, 'user', new ApiOptions());
  },
  fetchKurirCommission() {
    return this.get(`${TOPUP_KURIR_URL}/commissions`, 'user', new ApiOptions());
  },
  fetchKurirTransactionDetail(requestPayload) {
    const { type, id } = requestPayload;
    return this.get(`${TOPUP_KURIR_URL}/${type}/${id}`, 'user', new ApiOptions());
  },
  fetchKurirTopupTransactionList() {
    return this.get(`${TOPUP_KURIR_URL}/topups`, 'user', new ApiOptions());
  },
  fetchKurirDepositTransactionList() {
    return this.get(`${TOPUP_KURIR_URL}/deposits`, 'user', new ApiOptions());
  },
  createTopupMitraTransaction(requestPayload) {
    return this.post(`${TOPUP_KURIR_URL}/topups`, 'user', new ApiOptions({ requestPayload }));
  },
  payTopupMitraTransaction(requestPayload) {
    return this.post(`${TOPUP_KURIR_URL}/deposits`, 'user', new ApiOptions({ requestPayload }));
  },
});
