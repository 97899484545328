import API from '~/api';

export default {
  getMitraStatus(store) {
    const { getCurrentAgent } = store.getters;
    if (!API.credentials.login) {
      return 'non-login';
    }
    if (!getCurrentAgent?.id) {
      return 'non-mitra';
    }
    if (getCurrentAgent?.status === 'deactivated') {
      return 'mitra-inactive';
    }
    return 'mitra';
  },
  generateVisitParams(store, moreParams) {
    const isFirstPage = window.history.length <= 2;
    const params = {
      is_first_journey: isFirstPage,
      user_type: this.getMitraStatus(store),
      ...moreParams,
    };
    return Object.keys(params)
      .map(k => (Number.isInteger(params[k]) || !!params[k] ? `${k}=${params[k]}` : null))
      .filter(p => !!p)
      .join('&');
  },
  generateParams(params = {}) {
    return Object.keys(params)
      .map(k => (Number.isInteger(params[k]) || !!params[k] ? `${k}=${params[k]}` : null))
      .filter(p => !!p)
      .join('&');
  },
};
