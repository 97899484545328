import { jwtDecode, JwtPayload } from 'jwt-decode';
import random from 'lodash/random';
import { v4 } from 'uuid/';
import { promLatency } from '~/server/helpers/metrics-helper';
import type { Scope } from '~/types/api/request-method';
import type { TokenStorage } from '~/types/api/token-storage';

const callbackPropKey = '__SENTINEL_CALLBACK__';

export function generateAccessTokenObject(jwtToken: string): TokenStorage {
  const desc = jwtDecode<JwtPayload & { scope: Scope; client_id: string }>(jwtToken);

  const nowTimestamp = Date.now();
  const nextTimeStamp = new Date();
  nextTimeStamp.setDate(nextTimeStamp.getDate() + 1);
  // Formula from auth domain, but note that this is just a fallback.
  // the real expire value already set in jwt
  nextTimeStamp.setHours(8 + random(0, 14, false), 0, 0);

  return {
    access_token: jwtToken,
    refresh_token: undefined,
    token_type: 'Bearer',
    created_at: desc.iat ?? nowTimestamp,
    expires_in: (desc.exp ?? nextTimeStamp.getTime()) - (desc.iat ?? nowTimestamp),
    scope: desc.scope || 'public user store product.*.* transaction.*.* subsidy.*.* payment.*.*',
    userId: Number(desc.sub ?? -1),
  };
}
/**
 *
 * @returns {string} An eval-compatible string function callable from window (e.g. "window['UNIQUE_KEY']['UNIQUE_ID']" will be eval as `window['UNIQUE_KEY']['UNIQUE_ID'](args)`)
 */
export function registerLocalCallback(func: CallableFunction, { persist = false } = { persist: false }): string {
  if (process.server) {
    console.warn('Local Callback currently only works on client-side.');
    return '';
  }
  const startTime = Date.now();
  const uniqueKey = v4();
  window[callbackPropKey] ||= {};
  window[callbackPropKey][uniqueKey] = (token: string) => {
    func(token);
    promLatency(startTime, window.location.pathname, 'ok', 'sentinel_refresh_token_webview');
    if (!persist) delete window[callbackPropKey][uniqueKey];
  };
  return `window['${callbackPropKey}']['${uniqueKey}']`;
}
