import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const TOPUP_ONDEMAND_URL = `${process.env.API_V4_HOST}/agent-topup-on-demands`;

export default Client.wrap({
  retrieveTopupsOndemandEligibility() {
    return this.get(`${TOPUP_ONDEMAND_URL}/eligibilities`, 'user', new ApiOptions());
  },
  requestTopupOndemand(requestPayload) {
    return this.post(`${TOPUP_ONDEMAND_URL}/requests`, 'user', new ApiOptions({ requestPayload }));
  },
  retreiveTopupOnDemandLastTransaction() {
    return this.get(`${TOPUP_ONDEMAND_URL}/requests/last`, 'user', new ApiOptions());
  },
  cancelTopupOnDemandLastTransaction(id, requestPayload) {
    return this.patch(`${TOPUP_ONDEMAND_URL}/requests/${id}`, 'user', new ApiOptions({ requestPayload }));
  },
});
