import { promiseAlmost } from '~/utils/promise-utils';
import API from '~/api';

const blGlobal = {
  lastKnownGoodSharedConfig: null,
  async fetchCurrentUser() {
    let user = {
      identity: null,
    };
    const { login } = await API.credentials.fetchIdentity();
    if (!login) return user;
    const response = await API.retrieveCurrentUser();
    if (response.meta?.['http_status'] === 200) {
      user = {
        ...response.data,
        id: response.data.id.toString(),
        identity: null,
      };
    }
    return user;
  },
  async fetchVirtualProductStatusInfoType() {
    const { login } = await API.credentials.fetchIdentity();
    if (!login) return null;
    const response = await API.fetchVirtualProductStatusInfoType();
    if (response.meta?.['http_status'] !== 200) throw response;
    return response.data.reduce((vpInfo, vp) => {
      vpInfo[vp.type] = vp.id;
      return vpInfo;
    }, {});
  },
  async fetchSharedConfig() {
    try {
      const [user, vpStatusInfoType] = await promiseAlmost([
        this.fetchCurrentUser(),
        this.fetchVirtualProductStatusInfoType(),
      ]);
      const responseData = {
        user,
        vpStatusInfoType,
      };
      // Save data to localStorage so it can by bypassed on further error
      if (!process.server) {
        localStorage.setItem('shared_config_cache', JSON.stringify(responseData));
      }
      return responseData;
    } catch (error) {
      // Tried to restore from cache
      console.warn('Warning: User failed updating SharedConfig. Loading from cache.', error);
      if (!process.server) {
        this.lastKnownGoodSharedConfig = JSON.parse(localStorage.getItem('shared_config_cache'));
      }
      return this.lastKnownGoodSharedConfig;
    }
  },
  async fetch(forceRenew = false) {
    if (!this.lastKnownGoodSharedConfig || forceRenew) this.lastKnownGoodSharedConfig = await this.fetchSharedConfig();
    return this.lastKnownGoodSharedConfig;
  },
};

export default blGlobal;
