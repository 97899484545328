import { stringify } from 'query-string';
import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const CUSTOMER_URL = `${process.env.API_V4_HOST}/offline-users`;

export default Client.wrap({
  getAgentCustomersList(requestPayload = {}) {
    return this.get(`${CUSTOMER_URL}/o2o-offline-customers`, 'public', new ApiOptions({ requestPayload }));
  },
  getAgentCustomer(userId) {
    return this.get(`${CUSTOMER_URL}/o2o-offline-customers/${userId}`, 'public');
  },
  getIgnoreCustomerNumber(payload = {}) {
    return this.get(`${CUSTOMER_URL}/o2o-ignored-numbers?${stringify(payload)}`, 'user', new ApiOptions());
  },
  postIgnoreCustomerNumber(requestPayload) {
    return this.post(`${CUSTOMER_URL}/o2o-ignored-numbers/`, 'user', new ApiOptions({ requestPayload }));
  },
  // add new customer
  addCustomers(payload) {
    const { numbers = [] } = payload;
    let requestPayload = payload;
    if (numbers.length) {
      numbers[0].operator_id = numbers[0].partnerId;
      requestPayload = { ...payload, numbers };
    }
    return this.post(`${CUSTOMER_URL}/o2o-offline-customers`, 'user', new ApiOptions({ requestPayload }));
  },
  // edit customer { name }
  editCustomers(userId, requestPayload) {
    return this.patch(`${CUSTOMER_URL}/o2o-offline-customers/${userId}`, 'user', new ApiOptions({ requestPayload }));
  },
  // delete customer
  deleteCustomers(userId) {
    return this.delete(`${CUSTOMER_URL}/o2o-offline-customers/${userId}`, 'user', new ApiOptions());
  },
  // add new customer number
  addCustomerNumbers(payload) {
    const { offlineCustomerId, type, name, number, partnerId } = payload;
    const requestPayload = { offline_customer_id: offlineCustomerId, type, name, number, operator_id: partnerId };
    return this.post(`${CUSTOMER_URL}/o2o-offline-customer-numbers`, 'user', new ApiOptions({ requestPayload }));
  },
  // edit customer number { name, number, type }
  editCustomerNumbers(userId, requestPayload) {
    return this.patch(
      `${CUSTOMER_URL}/o2o-offline-customer-numbers/${userId}`,
      'user',
      new ApiOptions({ requestPayload })
    );
  },
  // delete customer numbers
  deleteCustomerNumbers(userId) {
    return this.delete(`${CUSTOMER_URL}/o2o-offline-customer-numbers/${userId}`, 'user', new ApiOptions());
  },
  getCustomerNumbers(requestPayload) {
    return this.get(`${CUSTOMER_URL}/o2o-offline-customer-numbers`, 'user', new ApiOptions({ requestPayload }));
  },
  setOfflineCustomerDebtDueDate(userId: number, requestPayload: { debt_due_date: string }) {
    return this.put(`${CUSTOMER_URL}/o2o-offline-customers/${userId}/debt-due-date`, 'user', new ApiOptions({ requestPayload }));
  },
  deleteOfflineCustomerDebtDueDate(userId: number) {
    return this.delete(`${CUSTOMER_URL}/o2o-offline-customers/${userId}/debt-due-date`, 'user', new ApiOptions());
  },
});
