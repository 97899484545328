import credentialStorageInstance from '~/api/credential-storage-instance';
import wrapSecureClientInterface from '~/api/v4';
import accessTokenManagerInstance from '~/api/v4/access-token-manager-instance';
import AggregationAPI from '~/api/v4/modules/aggregation';
import SplitterAPI from './api';

/**
 * Setup and Guide: read the README.md
 */
const ACCESS_TOKEN_KEY = 'bl_token';
const ACCESS_TOKEN_KEY_DEV = 'bl_token_dev';

const Splitter = {
  api: wrapSecureClientInterface(accessTokenManagerInstance, SplitterAPI, 'xhr_v4_splitter'),
  aggregationApi: wrapSecureClientInterface(accessTokenManagerInstance, AggregationAPI, 'xhr_v4_aggregator'),
  API_HOST: `${process.env.API_V4_HOST}/_exclusive`,
  token: null,
  participant: null,

  tokenStorageLabel() {
    if (process.env.NODE_ENV === 'development') {
      return ACCESS_TOKEN_KEY_DEV;
    }
    return ACCESS_TOKEN_KEY;
  },

  init() {
    const token = localStorage.getItem(this.tokenStorageLabel());
    if (token === null) return;
    const tokenStorage = JSON.parse(token);
    this.participant = tokenStorage?.userId?.toString() ?? credentialStorageInstance.identity;
  },

  /**
   * https://github.com/bukalapak/splitter/wiki/Choosing-Alternative
   *
   * @param {String} experiment
   * @param {String} default
   * @param {Boolean} override
   */
  async choose(experiment, defaultAlternative, override = null) {
    this.init();
    if (!experiment) return null;
    const requestPayload = { participant: this.participant };
    if (override) {
      requestPayload.override = override;
    }
    let response = null;
    try {
      response = await this.api.choose(experiment, requestPayload);
      if (response.meta.http_status !== 200) throw response;
    } catch (error) {
      console.warn('ERROR_CHOOSING_EXPERIMENT', error);
      return defaultAlternative;
    }
    return response.data.alternative;
  },

  /**
   * https://github.com/bukalapak/splitter/wiki/Scoring-Metrics
   *
   * @param {String} experiment
   * @param {String} metric
   * @param {Number} value
   */
  async score(experiment, metric, value = 1) {
    this.init();
    if (!experiment || !metric) return null;
    const requestPayload = { metric, value, participant: this.participant };
    let response = null;
    try {
      response = await this.api.score(experiment, requestPayload);
      if (response.meta.http_status !== 202) throw response;
    } catch (error) {
      throw new Error('ERROR_SCORING_EXPERIMENT', error);
    }
    return response;
  },

  /**
   * https://github.com/bukalapak/splitter/wiki/Scoring-Metrics
   *
   * @param {String} experiment
   * @param {Array} metrics Ex: [{ metric: 'x', value: 1 }, { metric: 'y', value: 123 }]
   */
  async bulkScore(experiment, metrics) {
    this.init();
    if (!experiment || !metrics || metrics.length <= 0) return null;
    let response = null;
    try {
      const splitterMetrics = {};
      metrics.forEach((metric, idx) => {
        const requestPayload = {
          metric: metric.metric,
          value: metric.value || 1,
          participant: this.participant,
        };
        const config = this.api.scoreConfig(experiment, requestPayload);
        splitterMetrics[`splitterMetric${idx}`] = config;
      });
      response = await this.aggregationApi.postAggregate(splitterMetrics);
      if (response.meta.http_status !== 200) throw response;
    } catch (error) {
      throw new Error('ERROR_SCORING_EXPERIMENT', error);
    }
    return response;
  },
};
const SplitterInstance = {
  ...Splitter,
  install(vue) {
    vue.prototype.$splitter = Splitter;
    // delete this.install; // this renders plugin untestable
  },
};

export default SplitterInstance;
