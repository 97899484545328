import r from '~/utils/render-utils';

const WinBigEventIndex = () => import(/* webpackChunkName: 'win-big-event'  */ '~/pages/WinBigEvent/index.vue');
const WinBigEventClosedWeek = () => import(/* webpackChunkName: 'win-big-event-closed-week'  */ '~/pages/WinBigEvent/ClosedWeek.vue');
const WinBigEventTnc = () => import(/* webpackChunkName: 'win-big-event-tnc'  */ '~/pages/WinBigEvent/TnC.vue');
const WinBigEventOnboarding = () => import(/* webpackChunkName: 'win-big-event-onboarding'  */ '~/pages/WinBigEvent/Onboarding.vue');


const winBigEvent = [
  {
    path: '/win-big-event',
    component: r(WinBigEventIndex),
    name: 'win-big-event',
  },
  {
    path: '/win-big-event/closed-week',
    component: r(WinBigEventClosedWeek),
    name: 'win-big-event-closed-week',
  },
  {
    path: '/win-big-event/tnc',
    component: r(WinBigEventTnc),
    name: 'win-big-event-tnc',
  },
  {
    path: '/win-big-event/onboarding',
    component: r(WinBigEventOnboarding),
    name: 'win-big-event-onboarding',
    beforeEnter: (_to, from, next) => {
      if (from.name === 'win-big-event' || from.name === 'win-big-event-closed-week') {
        next('/home');
      } else {
        next();
      }
    }
  },
];

export default winBigEvent;
