import detailsTitleMap from '~/pages/Lite/Transaksi/partials/List/data/title.json';
import { money } from '~/server/helpers/number-helper';
import { humanizeTimestamp } from '~/server/helpers/date-helper';

function buka20Transaction(transaction) {
  const { detail } = transaction;
  const title = detail.transaction_name || detailsTitleMap[detail.transaction_type] || 'Pembayaran tagihan Bukalapak';
  const nominal = detail.transaction_action === 'credit' ? `+${money(detail.amount)}` : `-${money(detail.amount)}`;
  const invoiceNumber = detail.invoice_group_number;
  const time = humanizeTimestamp(transaction.created_at, '%hh%:%mi% %tz%');
  return {
    title,
    nominal,
    invoiceNumber,
    time
  };
}

function invoices(transaction) {
  const { amount, transactions } = transaction.detail;
  const title = detailsTitleMap[transactions[0]?.type] || 'Pembayaran tagihan Bukalapak';
  const nominal = `-${money(amount.total)}`;
  const invoiceNumber = transaction.detail.payment_id;
  const time = humanizeTimestamp(transaction.created_at, '%hh%:%mi% %tz%');
  return {
    title,
    nominal,
    invoiceNumber,
    time
  };
}

function grocery(transaction) {
  const { detail } = transaction;
  const title = `${detailsTitleMap[transaction.trx_type]}: ${detail.summary} jenis produk`;
  const nominal = detail.code ? 'Bayar Tunai (COD)' : `-${money(detail.amount)}`;
  const invoiceNumber = detail.invoice_id;
  const time = humanizeTimestamp(transaction.created_at, '%hh%:%mi% %tz%');
  return {
    title,
    nominal,
    invoiceNumber,
    time
  };
}

function walletMutation(transaction) {
  const { detail } = transaction;
  let title = detail.description;

  switch (detail.action) {
    case 'refund': {
      title = 'Pengembalian Saldo';
      break;
    }
    case 'topup': {
      title = 'Penambahan Saldo (Admin)';
      break;
    }
  }

  const nominal = detail.type === 'credit' ? `+${money(detail.amount)}` : `-${detail.amount}`;
  let invoiceNumber = '';
  if (detail.action === 'refund') {
    invoiceNumber = extractInvoiceNumber(detail.description) || detail.action_reference_id;
  }
  const time = humanizeTimestamp(transaction.created_at, '%hh%:%mi% %tz%');
  return {
    title,
    nominal,
    invoiceNumber,
    time
  };
}

function danaMutation(transaction) {
  const { detail } = transaction;
  const title = danaMutationNameAction(detail.action, detail.description);
  const nominal = detail.type === 'credit' ? `+${money(detail.amount)}` : `-${money(detail.amount)}`;
  const invoiceNumber = detail.action_reference_no;
  const time = humanizeTimestamp(transaction.created_at, '%hh%:%mi% %tz%');
  return {
    title,
    nominal,
    invoiceNumber,
    time
  };
}

function qris(transaction) {
  const { detail } = transaction;
  const title = 'Uang Masuk dari Transaksi Kode QR';
  const nominal = `+${money(detail.amount)}`;
  const invoiceNumber = detail.payment_id;
  const time = humanizeTimestamp(transaction.created_at, '%hh%:%mi% %tz%');
  return {
    title,
    nominal,
    invoiceNumber,
    time
  };
}

function danaMutationNameAction(action, description) {
  switch (action) {
    case 'refund':
    case 'refund_payable': {
      return 'Pengembalian saldo';
    }
    case 'topup_bukadana':
    case 'agent_topup_bukadana':
    case 'agent_transfer_bukadana': {
      return 'Penambahan saldo Mitra';
    }
    default:
      return description;
  }
}

export function transactionMapper(transaction) {
  switch (transaction.trx_type) {
    case 'buka20-transactions': {
      return buka20Transaction(transaction);
    }
    case 'invoices': {
      return invoices(transaction);
    }
    case 'grocery': {
      return grocery(transaction);
    }
    case 'wallet-mutations': {
      return walletMutation(transaction);
    }
    case 'dana-mutations': {
      return danaMutation(transaction);
    }
    case 'qris': {
      return qris(transaction);
    }
    default: {
      throw new Error(`transaction type: ${transaction.trx_type} not registered`);
    }
  }
}

function extractInvoiceNumber(description) {
  const regex = /\b(?:TRF-|INV-GR-|BL)\S*/;

  const match = description.match(regex);

  return match ? match[0] : '';
}

export default { transactionMapper };
