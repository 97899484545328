import AgenliteAPIClient from '~/api';
import { ALLOWLIST_FEATURE } from '~/utils/subscription';

const CACHE_PREFIX = 'top_spender';

interface Customer {
  rank: number;
  name: string;
  phone: string;
  transactionCount: number;
  myCustomer?: boolean;
}

interface CustomerBonus {
  positionLabel: string;
  minPosition: number;
  maxPosition?: number;
  benefit: string;
  benefitType: string;
  class?: string;
  img?: string;
}

type InitialState = {
  season: {
    startDate: Date | null;
    endDate: Date | null;
  };
  commissionBonuses: CustomerBonus[];
  commissionMyCustomers: Customer[];
  commissionMyCustomersMeta: {
    limit: number;
    offset: number;
    total: number;
  };
  commissionAllCustomers: Customer[];
  commissionAllCustomersMeta: {
    limit: number;
    offset: number;
    total: number;
    continueFetch: boolean;
  };

  latestSeasonResult: {};
  allowlist: {
    enabled: boolean | null;
    expiredAt: string | null;
  };
  couponDealId: string | null;

  loading: {
    season: boolean;
    commissionBonuses: boolean;
    commissionCustomers: boolean;
    couponDeal: boolean;
  };
};

const initialState: InitialState = {
  season: {
    startDate: null,
    endDate: null,
  },
  commissionBonuses: [],
  commissionMyCustomers: [],
  commissionMyCustomersMeta: {
    limit: 0,
    offset: 0,
    total: 0,
  },

  commissionAllCustomers: [],
  commissionAllCustomersMeta: {
    limit: 0,
    offset: 0,
    total: 0,
    continueFetch: true,
  },

  latestSeasonResult: {},
  allowlist: {
    enabled: null,
    expiredAt: null,
  },
  couponDealId: null,

  loading: {
    season: false,
    commissionBonuses: false,
    commissionCustomers: false,
    couponDeal: false,
  },
};

const getters = {
  getTopSpenderSeason(state: InitialState) {
    return state.season;
  },
  getTopSpenderAllCustomersRanking(state: InitialState) {
    if (!state.commissionBonuses.length) return [];
    return state.commissionAllCustomers.map(customer => privateUtils.mapCustomerWithBonus(state, customer));
  },
  getTopSpenderMyCustomersRanking(state: InitialState) {
    if (!state.commissionBonuses.length) return [];
    return state.commissionMyCustomers.map(customer => privateUtils.mapCustomerWithBonus(state, customer, true));
  },
  getMyCustomerCountOutsideRanking(state: InitialState) {
    return state.commissionMyCustomersMeta.total - state.commissionMyCustomers.length;
  },
  getTopSpenderTotalCommission(_state, getters: any) {
    return getters.getTopSpenderMyCustomersRanking.reduce((acc, curr) => acc + (+curr.bonus?.benefit || 0), 0);
  },
  getLatestSeasonResult(state) {
    return state.latestSeasonResult;
  },
  getTopSpenderAllowlist(state) {
    return state.allowlist;
  },
  getTopSpenderCouponDeal(state) {
    return state.couponDealId;
  },
  getTopSpenderLoading(state: InitialState) {
    return state.loading;
  },
};

const actions = {
  async retrieveCommissionBonuses({ commit, dispatch }) {
    // Retrieve commission bonuses from Neo Configs
    // -------------------------------------------------------------------
    // Neo Configs example:
    // {
    //   "bonus": [
    //     ...
    //     {
    //       "positionLabel": "4-10",
    //       "minPosition": 4,
    //       "maxPosition": 10,
    //       "benefit": "300000",
    //       "benefitType": "saldo"
    //       "class": "custom-class",
    //       "img": "/images/custom-image-path.webp",
    //     },
    //     ...
    //   ]
    // }
    // -------------------------------------------------------------------
    const config = await dispatch('fetchNeoConfigs', ['o2o-vpe/top-spender-commission']);
    commit('setCommissionBonuses', config[0]?.data?.bonus);
  },
  async retrieveCurrentTopSpenderSeason({ commit, dispatch }) {
    let response: { data: any } | null = null;

    commit('setLoadingState', { name: 'season', value: true });
    try {
      response = await AgenliteAPIClient.retrieveCurrentTopSpenderSeason();
      const season = {
        startDate: new Date(response?.data.start_date),
        endDate: new Date(response?.data.end_date),
      };
      commit('setTopSpenderSeason', season);
    } catch (error) {
      const message = 'Gagal Mendapatkan Musim.';
      dispatch('catchError', { error, message, noFlashAlert: false });
    } finally {
      commit('setLoadingState', { name: 'season', value: false });
    }
    return { response };
  },
  async retrieveTopSpenderMyCustomersRankings({ commit, dispatch }, userId) {
    const isCached = privateUtils.retrieveCachedCustomerRankings(
      commit,
      userId,
      'myCustomers',
      'setTopSpenderMyCustomersRankings'
    );
    if (isCached) return;

    // My Customer Rankings are all retrieved at once
    // because it will be used in total commission calculation.
    // Meta also used (especially total) to calculate my customer count outside the ranking.
    commit('setLoadingState', { name: 'commissionCustomers', value: true });
    let response: { data: any; meta: any } | null = null;
    try {
      response = await AgenliteAPIClient.retrieveTopSpenderCustomersRankings({
        mine: true,
        limit: 1000,
      });
      const customers = response?.data;

      const meta = {
        limit: response?.meta?.limit,
        offset: response?.meta?.offset,
        total: response?.meta?.total,
      };

      // Cache the retrieved data to local storage
      privateUtils.setCacheCustomerRankings(userId, 'myCustomers', customers, meta);

      commit('setTopSpenderMyCustomersRankings', { customers, meta });
    } catch (error) {
      const message = 'Gagal Mendapatkan Peringkat.';
      dispatch('catchError', { error, message, noFlashAlert: false });
    } finally {
      commit('setLoadingState', { name: 'commissionCustomers', value: false });
    }
    return { response };
  },
  async retrieveTopSpenderAllCustomersRankings({ commit, dispatch, state }, userId) {
    if (state.loading.commissionCustomers) return;

    const isCached = privateUtils.retrieveCachedCustomerRankings(
      commit,
      userId,
      'allCustomers',
      'setTopSpenderAllCustomersRankings'
    );
    if (isCached) return;

    // All Customer Rankings are retrieved in batches using scroll pagination.
    // Offset that used is the last rank of the previous batch.
    // ContinueFetch flag is used to check if there are still more data to be fetched.
    if (!state.commissionAllCustomersMeta.continueFetch) return;

    commit('setLoadingState', { name: 'commissionCustomers', value: true });
    let response: { data: any; meta: any } | null = null;
    try {
      response = await AgenliteAPIClient.retrieveTopSpenderCustomersRankings({
        limit: 10,
        offset: state.commissionAllCustomers[state.commissionAllCustomers.length - 1]?.rank || 0,
      });
      const customers = response?.data;

      // Create a Set of existing ranks for faster lookup
      const existingRanks = new Set(state.commissionAllCustomers.map(customer => customer.rank));

      // Combine existing customers with new unique customers
      const newCustomers = [
        ...state.commissionAllCustomers.map(item => ({
          rank: item.rank,
          name: item.name,
          customer_number: item.phone,
          transaction_count: item.transactionCount,
          mine: !!item.myCustomer,
        })),
        ...customers.filter(item => !existingRanks.has(item.rank)),
      ];

      const meta: Partial<InitialState['commissionAllCustomersMeta']> = {
        limit: response?.meta?.limit,
        offset: response?.meta?.offset,
        total: response?.meta?.total,
      };

      // Once new customer data length is same as previous batch,
      // it means there are no more data to be fetched.
      // So set continueFetch to false and cache the data.
      if (newCustomers.length === state.commissionAllCustomers.length) {
        meta.continueFetch = false;
        privateUtils.setCacheCustomerRankings(userId, 'allCustomers', newCustomers, meta);
      }

      commit('setTopSpenderAllCustomersRankings', { userId, customers: newCustomers, meta });
    } catch (error) {
      const message = 'Gagal Mendapatkan Peringkat.';
      dispatch('catchError', { error, message, noFlashAlert: false });
    } finally {
      commit('setLoadingState', { name: 'commissionCustomers', value: false });
    }
    return { response };
  },
  async retrieveLatestSeasonResult({ commit, dispatch }) {
    dispatch('showAppLoading');
    let response: { data: any } | null = null;
    try {
      response = await AgenliteAPIClient.retrieveLatestSeasonResult();
      commit('setLatestSeasonResult', response?.data);
    } catch (error) {
      const message = 'Gagal Mendapatkan data.';
      dispatch('catchError', { error, message, noFlashAlert: false });
    } finally {
      dispatch('hideAppLoading');
    }
    return { response };
  },
  async retrieveTopSpenderAllowlist({ commit, dispatch, state }) {
    if (state.allowlist.enabled !== null) return;

    const response = await dispatch('retrieveAllowlistMeFeature', { feature: ALLOWLIST_FEATURE.JAGOAN_VIRTUAL });
    const isAllowed = response?.data?.value === 'allowed';
    if (isAllowed) {
      commit('setTopSpenderAllowlist', { enabled: isAllowed, expiredAt: response?.data?.updated_at });
    }
  },
  async retrieveTopSpenderCouponDeal({ commit, dispatch }) {
    try {
      commit('setLoadingState', { name: 'couponDeal', value: true });
      const response = await dispatch('fetchNeoConfigs', ['subscription-checkout-list']);
      const checkoutList = response?.[0]?.data;

      const couponDealId =
        Object.entries(checkoutList || {}).find(([_, feature]) => feature === ALLOWLIST_FEATURE.JAGOAN_VIRTUAL)?.[0] ||
        null;
      if (couponDealId) commit('setCouponDealId', couponDealId);
    } finally {
      commit('setLoadingState', { name: 'couponDeal', value: false });
    }
  },
};

const privateUtils = {
  mapCustomerWithBonus: (state: InitialState, customer: any, isMyCustomer = false) => {
    const bonus = state.commissionBonuses.find(bonus =>
      bonus.maxPosition
        ? customer.rank >= bonus.minPosition && customer.rank <= bonus.maxPosition
        : customer.rank === bonus.minPosition
    );
    return {
      ...customer,
      bonus,
      ...(isMyCustomer ? { myCustomer: true } : {}),
    };
  },
  retrieveCachedCustomerRankings(commit: Function, userId: string, cacheKey: string, mutationName: string): boolean {
    // Customer rankings data are cached to reduce same API calls.
    // Before making an API call, it first checks if data is cached.
    // If cached data is found, it commits the data to the Vuex store and cancels the API call.
    const cachedData = privateUtils.getCacheCustomerRankings(userId, cacheKey);
    if (cachedData?.customers) {
      commit(mutationName, cachedData);
      return true;
    }
    return false;
  },
  getCacheCustomerRankings: (userId: string, key: string) => {
    const cacheName = `${CACHE_PREFIX}:${userId}`;
    const cacheMetaKey = `${key}Meta`;

    // Customer rankings data are refreshed every day and remain the same for the same day.
    // So the cached data needs to be checked if it's still the same day with the current date.
    // -------------------------------------------------------------------
    // Cached data example:
    // {
    //   myCustomers: [...],
    //   myCustomersMeta: { ... },
    //   allCustomers: [...],
    //   allCustomersMeta: { ... },
    //   timestamp: '...',
    // }
    // -------------------------------------------------------------------

    const cachedData = localStorage.getItem(cacheName);
    if (cachedData) {
      const data = JSON.parse(cachedData);
      const timestamp = new Date(data.timestamp);
      const currentTimestamp = new Date();

      const isSameDay =
        timestamp.getDate() === currentTimestamp.getDate() &&
        timestamp.getMonth() === currentTimestamp.getMonth() &&
        timestamp.getFullYear() === currentTimestamp.getFullYear();

      if (data.timestamp && isSameDay) {
        return { customers: data[key], meta: data[cacheMetaKey] };
      }

      // If not same day, clear all cache
      localStorage.removeItem(cacheName);
    }
    return null;
  },

  setCacheCustomerRankings: (userId: string, key: string, data: any, meta: any) => {
    const cacheName = `${CACHE_PREFIX}:${userId}`;
    const cacheMetaKey = `${key}Meta`;

    const cachedData = localStorage.getItem(cacheName);
    const existingData = cachedData ? JSON.parse(cachedData) : {};

    localStorage.setItem(
      cacheName,
      JSON.stringify({
        ...existingData,
        [key]: data,
        [cacheMetaKey]: meta,
        timestamp: existingData.timestamp || Date.now(), // Keep existing timestamp or set new one
      })
    );
  },
};

const mutations = {
  setCommissionBonuses(state, bonuses) {
    state.commissionBonuses = bonuses;
  },
  setTopSpenderSeason(state, season) {
    state.season = season;
  },
  setTopSpenderMyCustomersRankings(state, payload) {
    const { customers, meta } = payload;

    state.commissionMyCustomers = customers.map(item => ({
      rank: item.rank,
      name: item.name,
      phone: item.customer_number,
      transactionCount: item.transaction_count,
    }));

    state.commissionMyCustomersMeta = { ...state.commissionMyCustomersMeta, ...meta };
  },
  setTopSpenderAllCustomersRankings(state, payload) {
    const { customers, meta } = payload;

    state.commissionAllCustomers = customers.map(item => ({
      rank: item.rank,
      name: item.name,
      phone: item.customer_number,
      transactionCount: item.transaction_count,
      myCustomer: item.mine,
    }));

    state.commissionAllCustomersMeta = { ...state.commissionAllCustomersMeta, ...meta };
  },
  setLatestSeasonResult(state, latestSeasonResult) {
    state.latestSeasonResult = latestSeasonResult;
  },
  setTopSpenderAllowlist(state, payload) {
    state.allowlist.enabled = payload.enabled;
    state.allowlist.expiredAt = payload.expiredAt;
  },
  setCouponDealId(state, couponDealId) {
    state.couponDealId = couponDealId;
  },
  setLoadingState(state, payload) {
    const { name, value } = payload;
    state.loading[name] = value;
  },
};

export default {
  state: () => ({ ...initialState }),
  mutations,
  getters,
  actions,
};
