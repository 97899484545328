import AgenliteAPIClient from '~/api';

const initialState = {
};

const moduleGetters = {
};

const actions = {
  async retrieveCurrentUser({ dispatch }) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.retrieveCurrentUser();
      dispatch('hideFlashAlert');
    } catch (error) {
      const message = 'Gagal mendapatkan data pengguna.';
      dispatch('catchError', { error, message });
    } finally {
      dispatch('hideAppLoading');
    }
    return response?.data || null;
  },
  async updateUserPhoneNumber({ dispatch, getters }, { requestPayload, options }) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.updateUserPhoneNumber(
        getters.getUserId,
        requestPayload,
        options,
      );
      dispatch('hideFlashAlert');
    } catch (error) {
      if (error?.meta?.['http_status'] === 401) throw error;
      const message = 'Gagal mendaftarkan nomor handphone.';
      dispatch('catchError', { error, message, hideAppLoading: true });
      throw error;
    } finally {
      dispatch('hideAppLoading');
    }
    return response;
  },
  async phoneConfirmation({ dispatch, getters }, { options = {} } = {}) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.phoneConfirmation(getters.getUserId, options);
      dispatch('hideFlashAlert');
    } catch (error) {
      if (error?.meta?.['http_status'] === 401) throw error;
      const message = 'Tidak dapat melakukan konfirmasi nomor handphone.';
      dispatch('catchError', { error, message, hideAppLoading: true });
      throw error;
    } finally {
      dispatch('hideAppLoading');
    }
    return response;
  },
};

const mutations = {
};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  actions,
  mutations,
};
