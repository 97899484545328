const initialState = {
  shouldShow: false,
  title: '',
  illustration: '',
  message: '',
};

const getters = {
  getInstallSheet: state => ({
    shouldShow: state.shouldShow,
    title: state.title,
    illustration: state.illustration,
    message: state.message,
  }),
};

const mutations = {
  setshowSheetState(state, { shouldShow, title = '', illustration = '', message = '' }) {
    state.shouldShow = shouldShow;
    state.title = title;
    state.illustration = illustration;
    state.message = message;
  },
};

const actions = {
  showInstallSheet({ commit }, sheet = {}) {
    commit('setshowSheetState', { shouldShow: true, ...sheet });
  },
  hideInstallSheet({ commit }) {
    commit('setshowSheetState', { shouldShow: false });
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
