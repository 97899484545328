import Client from '~/types/api/api-module';
import RequestBuilder from '~/api/request_builder';
import AgenliteAPI from '~/api/index';
import ApiOptions from '../api-options';

const MITRA_BRANCH = `${process.env.API_V4_HOST}/mitra/branch`;

export interface StaffRequestParams {
  status: string;
  offset: number;
  limit: number;
}

export interface StaffActiveParams {
  offset: number;
  limit: number;
}

export interface UpdateStaffBodyParams {
  id: number;
  status: string;
}

export interface RequestAddStaffParams {
  staff_user_id: number;
  branch_name: string;
}

export default Client.wrap({
  retrieveCurrentMitraBranch() {
    return this.get(`${MITRA_BRANCH}/me`, 'user', new ApiOptions());
  },
  retrieveListOfStaffs(params: StaffActiveParams) {
    return this.get(`${MITRA_BRANCH}/staffs`, 'user', new ApiOptions({ requestPayload: params }));
  },
  retrieveListOfRequests(params: StaffRequestParams) {
    return this.get(`${MITRA_BRANCH}/requests`, 'user', new ApiOptions({ requestPayload: params }));
  },
  retrieveRequestStaffByID(params: number) {
    return this.get(`${MITRA_BRANCH}/requests/${params}`, 'user', new ApiOptions());
  },
  retrieveStaffByID(params: number) {
    return this.get(`${MITRA_BRANCH}/staffs/${params}`, 'user', new ApiOptions());
  },
  deleteStaff(params: number) {
    return this.delete(`${MITRA_BRANCH}/staffs/${params}`, 'user', new ApiOptions());
  },
  updateStaff(staffID: number, branchName: string) {
    return this.patch(`${MITRA_BRANCH}/staffs/${staffID}`, 'user', new ApiOptions({
      requestPayload: { branch_name: branchName }
    }));
  },
  updateStaffInvitationStatus(params: UpdateStaffBodyParams) {
    return this.patch(`${MITRA_BRANCH}/requests/${params.id}`, 'user', new ApiOptions({
      requestPayload: { status: params.status }
    }));
  },
  validatePhoneNumber(phoneNumber: string) {
    return this.post(`${MITRA_BRANCH}/check-phone-number`, 'user', new ApiOptions({
      requestPayload: { phone_number: phoneNumber }
    }));
  },
  requestAddStaff(params: RequestAddStaffParams) {
    return this.post(`${MITRA_BRANCH}/requests`, 'user', new ApiOptions({
      requestPayload: params
    }));
  },
  async sendStaffBalance(payload) {
      const { staffId, amount, OTPOptions, userId } = payload;
      const requestPayload = { staff_id: staffId, amount };
      const requestHeaders = { ...RequestBuilder.getOtpHeader(AgenliteAPI.getOtp(userId).key, OTPOptions) };
      const response = await this.post(`${MITRA_BRANCH}/transfers`, 'user', new ApiOptions({
        requestPayload,
        requestHeaders,
      }));
      return response;
  },
  retrieveTransactionDetailStaff(transactionID: number) {
    return this.get(`${MITRA_BRANCH}/transfers/${transactionID}`, 'user', new ApiOptions());
  },
  retrieveListOfStaffTransactions(staffID: number) {
    return this.get(`${MITRA_BRANCH}/staffs/${staffID}/transactions`, 'user', new ApiOptions());
  }
});
