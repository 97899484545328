import Client from '~/types/api/api-module';
import { APIResponse } from '~/types/api/api-response';
import ApiOptions from '../../api-options';
import RequestBuilder from '../../../request_builder';

type DanaKycType = 'non_kyc' | 'kyc';

export interface DanaProfile {
  balance: number;
  mask_dana_id: string;
  mini_dana_url: string;
  transaction_url: string;
  topup_url: string;
  ott: string;
  kyc_status: DanaKycType;
  public_dana_id: boolean;
}

const DANA_URL = `${process.env.API_V4_HOST}/_exclusive/e-wallets/dana`;
const DANA_PAYMENT_URL = `${process.env.API_V4_HOST}/_exclusive/payments`;

export default Client.wrap({
  Public: {
    async retrieveDanaRegistrationUrl(userId, requestPayload, options): Promise<APIResponse> {
      try {
        const response = await this.v4.exclusiveDana.getDanaRegistrationUrl<unknown>(
          RequestBuilder.getOtpHeader(this.getOtp(userId).key, options),
          requestPayload
        );
        if (response?.meta?.['otp_key']) {
          this.setOtpKey(userId, response.meta.otp_key);
        }
        return response;
      } catch (error) {
        if (error?.meta?.['otp_key']) {
          this.setOtpKey(userId, error.meta.otp_key);
        }
        throw error;
      }
    },
    async makePaymentWithDana(invoiceId: number, requestHeaders: Record<string, any>): Promise<APIResponse> {
      try {
        const response = await this.v4.exclusiveDana.postDanaInvoice(invoiceId, requestHeaders);
        if (response.data?.state !== 'completed') throw response;
        return response;
      } catch (error) {
        let message = 'Terjadi kesalahan. Silakan coba lagi nanti.';
        const responseMap = {
          500: 'Gagal melakukan pembayaran dengan DANA. Silakan coba lagi nanti.',
          // To handle response.data.state !== 'completed'
          200: 'Gagal melakukan pembayaran dengan DANA. Silakan coba lagi nanti.',
        };
        message = responseMap[((error || {}).meta || {}).http_status] || message;
        throw new Error(message);
      }
    },
  },
  Private: {
    getDanaRegistrationUrl(requestHeaders: Record<string, string>, requestPayload: Record<string, any>) {
      return this.get(`${DANA_URL}/registration-url`, 'user', new ApiOptions({ requestHeaders, requestPayload }));
    },
    retrieveDanaProfile() {
      return this.get<DanaProfile>(`${DANA_URL}/profile`, 'user', new ApiOptions());
    },
    consultDanaTopup(requestPayload: Record<string, any>) {
      return this.get(`${DANA_URL}/consult-topup`, 'user', new ApiOptions({ requestPayload }));
    },
    retrieveDanaMutations(requestPayload: Record<string, any>) {
      return this.get(`${DANA_URL}/mutations`, 'user', new ApiOptions({ requestPayload }));
    },
    postDanaInvoice(invoiceId: number, requestHeaders: Record<string, any>) {
      return this.post<{ state: string }>( // TODO: Create response type for dana invoice
        `${DANA_PAYMENT_URL}/${invoiceId}/dana`,
        'user',
        new ApiOptions({ requestHeaders, requestLimit: { time: 60000, additionalKey: `${invoiceId}` } })
      );
    },
    retrieveDanaPaymentDetail(invoiceId: number, requestHeaders: Record<string, string>) {
      return this.get(`${DANA_PAYMENT_URL}/${invoiceId}/dana`, 'user', new ApiOptions({ requestHeaders }));
    },
  },
});
