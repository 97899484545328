import Client from '~/types/api/api-module';
import { Catalog, Banner, BusinessCard } from '~/types/api/promotion-media';
import ApiOptions from '../api-options';

const PROMOTION_MEDIA_URL = `${process.env.API_V4_HOST}/promotion-media`;
const PROMOTION_MEDIA_UPLOAD_URL = `${PROMOTION_MEDIA_URL}/upload`;

export const PromotionMedia = Client.wrap({
  retrievePromotionMediaCatalogs(requestPayload: Object) {
    return this.get<Catalog>(`${PROMOTION_MEDIA_URL}/catalogs`, 'user', new ApiOptions({ requestPayload }));
  },
  createPromotionMediaCatalog(requestPayload: Catalog) {
    return this.post<Catalog>(`${PROMOTION_MEDIA_URL}/catalogs`, 'user', new ApiOptions({ requestPayload }));
  },
  updatePromotionMediaCatalog(id: number, requestPayload: Catalog) {
    return this.put<Catalog>(`${PROMOTION_MEDIA_URL}/catalogs/${id}`, 'user', new ApiOptions({ requestPayload }));
  },
  uploadFileToBucket(requestPayload: FormData) {
    return this.post<Catalog>(PROMOTION_MEDIA_UPLOAD_URL, 'user', new ApiOptions({ requestPayload }));
  },
  retrievePromotionMediaBanners(requestPayload: Object) {
    return this.get<Banner>(`${PROMOTION_MEDIA_URL}/banners`, 'user', new ApiOptions({ requestPayload }));
  },
  createPromotionMediaBanner(requestPayload: Banner) {
    return this.post<Banner>(`${PROMOTION_MEDIA_URL}/banners`, 'user', new ApiOptions({ requestPayload }));
  },
  updatePromotionMediaBanners(id: number, requestPayload: Object) {
    return this.put<Banner>(`${PROMOTION_MEDIA_URL}/banners/${id}`, 'user', new ApiOptions({ requestPayload }));
  },
  createPromotionMediaBusinessCard(requestPayload: BusinessCard) {
    return this.post<BusinessCard>(`${PROMOTION_MEDIA_URL}/business-cards`, 'user', new ApiOptions({ requestPayload }));
  },
  retrievePromotionMediaBusinessCard(requestPayload: Object) {
    return this.get<BusinessCard>(`${PROMOTION_MEDIA_URL}/business-cards`, 'user', new ApiOptions({ requestPayload }));
  },
  updatePromotionMediaBusinessCard(id: number, requestPayload: Object) {
    return this.put<BusinessCard>(`${PROMOTION_MEDIA_URL}/business-cards/${id}`, 'user', new ApiOptions({ requestPayload }));
  },
  retrievePromotionMediaVpCatalogs(requestPayload: Object) {
    return this.get<Catalog>(`${PROMOTION_MEDIA_URL}/vp-catalogs`, 'user', new ApiOptions({ requestPayload }));
  },
  createPromotionMediaVpCatalog(requestPayload: Catalog) {
    return this.post<Catalog>(`${PROMOTION_MEDIA_URL}/vp-catalogs`, 'user', new ApiOptions({ requestPayload }));
  },
  updatePromotionMediaVpCatalog(id: number, requestPayload: Catalog) {
    return this.patch<Catalog>(`${PROMOTION_MEDIA_URL}/vp-catalogs/${id}`, 'user', new ApiOptions({ requestPayload }));
  },
});
