
import ApiOptions from '../api-options';

const GENERAL_TRADE_URL = `${process.env.API_V4_HOST}/general-trade`;
const CART_URL = `${GENERAL_TRADE_URL}/cart-items`;

export default {
  retrieveCart({
    withFGS = false, showNonCigarrettesAmount = true, cartType = ''
  } = {}) {
    const requestPayload = {
      with_fgs: withFGS || false,
      cart_type: cartType || '',
      show_non_cigarrettes_amount: showNonCigarrettesAmount || true,
    };
  
    return this.get(CART_URL, 'user', new ApiOptions({requestPayload}));
  },
  addCart(requestPayload, type = '', from = '') {
    let cartUrl = CART_URL;
    if (type !== '') {
      cartUrl = `${cartUrl}?type=${type}&from=${from}`;
    }
    return this.post(cartUrl, 'user', new ApiOptions({
      requestPayload,
    }));
  },
  updateCart(requestPayload, paramId, showNonCigarrettesAmount) {
    return this.patch(`${CART_URL}/${paramId}?show_non_cigarrettes_amount=${showNonCigarrettesAmount}`, 'user', new ApiOptions({
      requestPayload,
    }));
  },
  deleteCart(paramId) {
    return this.delete(`${CART_URL}/${paramId}`, 'user', new ApiOptions());
  },
  validateCart() {
    return this.get(`${CART_URL}/validate`, 'user', new ApiOptions());
  },
  retrieveFreeGoodSampling() {
    return this.get(`${CART_URL}/free-good-sampling`, 'user', new ApiOptions());
  },
};
