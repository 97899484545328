import SilkroadAPIClient from '~/api/silkroad';

const initialState = {
  freeGoodSampling: [],
  bonusMinimumPrice: 0,
};

const thisGetters = {
  getFreeGoodSampling(state) {
    return state.freeGoodSampling;
  },
  getFreeGoodSamplingSize(state) {
    return state.freeGoodSampling.length;
  },
  getBonusMinimumPrice(state) {
    return state.bonusMinimumPrice;
  },
};

const actions = {
  retrieveFreeGoodSampling({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
      SilkroadAPIClient.retrieveFreeGoodSampling()
        .then(response => {
          if (response.data.bonus_list) {
            commit('setFreeGoodSampling', response.data.bonus_list);
            commit('setBonusMinimumPrice', response.data.amount);
          } else {
            commit('setFreeGoodSampling', []);
            commit('setBonusMinimumPrice', 0);
          }
          resolve(response);
        })
        .catch(error => {
          const message = 'Gagal mendapatkan data bonus.';
          dispatch('catchError', { error, message, hideAppLoading: true });
          reject(error);
        });
    });
  },
};

const mutations = {
  setFreeGoodSampling(state, freeGoodSampling) {
    state.freeGoodSampling = freeGoodSampling;
  },
  setBonusMinimumPrice(state, bonusMinimumPrice) {
    state.bonusMinimumPrice = bonusMinimumPrice;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: thisGetters,
  actions,
  mutations,
};
