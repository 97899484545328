import AgenliteAPIClient from '~/api';

// State
const initialState = {
  bukaBantuanAnnouncements: null,
};

// Getters
const moduleGetters = {
  /**
   * @param {object} state
   * @returns {object[]}
   */
  getBukaBantuanAnnouncements(state) {
    return state.bukaBantuanAnnouncements;
  },
};

// Mutations
const mutations = {
  setBukaBantuanAnnouncements(state, payload) {
    state.bukaBantuanAnnouncements = payload;
  },
};

// Actions
const actions = {
  async fetchBukaBantuanAnnouncements({ commit, dispatch }) {
    try {
      const { data } = await AgenliteAPIClient.fetchBukaBantuanAnnouncements();
      commit('setBukaBantuanAnnouncements', data);
      return data;
    } catch (error) {
      dispatch('catchError', { error });
      throw error;
    }
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  mutations,
  actions,
};
