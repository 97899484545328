import r from '~/utils/render-utils';

const PageGrosir = () => import(/* webpackChunkName: 'grosir' */ '~/pages/Grosir/Home');
const PageCart = () => import(/* webpackChunkName: 'grosir' */ '~/pages/Grosir/Cart');
const PageSpecialPromo = () => import(/* webpackChunkName: 'grosir' */ '~/pages/SpecialPromo');
const PagePromoSuggestion = () => import(/* webpackChunkName: 'grosir' */ '~/pages/Grosir/PromoSuggestion');
const PageGrosirCheckout = () => import(/* webpackChunkName: 'grosir' */ '~/pages/GrosirCheckoutPage');
const PageProductRecommendation = () => import(/* webpackChunkName: 'grosir' */ '~/pages/Grosir/ProductRecommendation');

const grosir = [
  {
    path: '/grosir/',
    component: r(PageGrosir),
    name: 'home_wholesale_new',
    children: [
      {
        path: '/grosir/:category',
        name: 'home_wholesale_category',
      },
      {
        path: '/grosir/:category/:subcategory',
        name: 'home_wholesale_subcategory',
      },
    ],
  },
  {
    path: '/checkout',
    component: r(PageGrosirCheckout),
    children: [
      {
        path: '',
        component: r(PageCart),
        name: 'grosir_checkout',
      },
      {
        path: 'promo-suggestion',
        component: r(PagePromoSuggestion),
        name: 'grosir_checkout_promo_suggestion',
      },
    ],
  },
  {
    path: '/special-promo/:name',
    component: r(PageSpecialPromo),
    name: 'special_promo',
  },
  {
    path: '/product-reco',
    component: r(PageProductRecommendation),
    name: 'product_recommendation',
  },
];

export default grosir;
