//
<template lang="pug">
#wrap
  AppHeader
    strong(slot="title") Mitra Bukalapak
  .space
  #img
    img(src="/images/error/error.svg")
  #txt
    h1 Terjadi Kesalahan
    p Tidak perlu khawatir, tim kami akan segera memperbaiki ini.
    p.u-fg--ash-dark
      span Error message: {{ errorMessage }}
      span (code: {{ errorCode }})
  .space
  #act
    button.c-btn.c-btn--red(:disabled="disabledButton" @click="goToHome") Kembali ke Halaman Utama
</template>

<script>
import AppHeader from '~/components/AppHeader';
import { promCounterEx } from '~/server/helpers/metrics-helper';

export default {
  name: 'AgenliteError',
  components: {
    AppHeader,
  },
  props: {
    error: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      disabledButton: false,
    };
  },
  head() {
    return {
      title: 'Mitra Bukalapak',
    };
  },
  computed: {
    errorMessage() {
      if (this.error.errors) {
        return this.error.errors.map(item => item.message).join('; ');
      }
      return this.error.message || this.error;
    },
    errorCode() {
      if (this.error.errors) {
        return this.error.errors.map(item => item.code).join('; ');
      }
      return this.error.code || 10000;
    },
  },
  mounted() {
    promCounterEx('sentinel_excception_error', {
      code: this.errorCode,
      message: this.errorMessage,
      path: this.$route.path,
    });
  },
  methods: {
    goToHome() {
      this.disabledButton = true;
      this.$location.redirect('/');
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

#wrap {
  justify-content: space-between;
  height: 100vh;
}

#img {
  padding: 0 1.5rem;
  align-items: center;

  & img {
    max-width: 300px;
  }
}

#txt {
  margin: 36px 0 0 0;
  padding: 0 1.5rem;
  text-align: center;
}

h1 {
  color: #353535;
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0 0 1rem 0;
}

p {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5rem;
}

#act {
  padding: 1rem 1.5rem;
}

#act button {
  font-size: 1em;
  color: #fff;
  background-color: #d5124a;
  padding: 1em;
  border: none;
  border-radius: 6px;
}

@media (max-width: 1024px) {
  html {
    font-size: 36px;
  }
}

@media (min-width: 1024px) {
  html {
    font-size: 18px;
  }

  #img svg {
    max-width: 640px;
  }
}
</style>
