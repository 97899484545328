import { isAfter } from 'date-fns';
import unionBy from 'lodash/unionBy';
import AgenliteAPIClient from '~/api/index';

const initialState = {
  bookkeepingTransactions: [],
  bookkeepingMeta: {
    total_income: null,
    total_expense: null,
    total: null,
  },
  bookkeepingProfits: [],
  bookkeepingCategories: {
    income: [],
    expense: [],
  },
  bookkeepingEntryForm: {},
  isOffline: false,
  haveNoEntry: false,
  isBookkeepingCoachmarkActive: false,
  isBookkeepingCoachmarkUnpaidTrxActive: false,
  bookkeepingReportThisPeriod: [],
  bookkeepingReportPrevPeriod: [],
  bookkeepingReportCategories: [],
  bookkeepingEntryTransactions: [],
};

const getters = {
  getBookkeepingTransactions: state => state.bookkeepingTransactions,
  getBookkeepingEntry: state => state.bookkeepingEntryForm,
  getBookkeepingMeta: state => state.bookkeepingMeta,
  getBookkeepingProfits: state => state.bookkeepingProfits,
  getBookkeepingCategories: state => type => state.bookkeepingCategories[type] || [],
  profitSummaries: state => state.bookkeepingMeta.total_income - state.bookkeepingMeta.total_expense,
  isOffline: state => state.isOffline,
  isNewBookkeepingUser: state => state.haveNoEntry,
  isBookkeepingCoachmarkActive: state => state.isBookkeepingCoachmarkActive,
  isBookkeepingCoachmarkUnpaidTrxActive: state => state.isBookkeepingCoachmarkUnpaidTrxActive,
  agentSellingProduct: state => state.bookkeepingEntryForm?.selling_product || {},
  getBookkeepingReportThisPeriod: state => state.bookkeepingReportThisPeriod,
  getBookkeepingReportPrevPeriod: state => state.bookkeepingReportPrevPeriod,
  getBookkeepingReportCategories: state => state.bookkeepingReportCategories,
  getBookkeepingEntryTransactions: state => state.bookkeepingEntryTransactions,
  getBookkeepingEntryGeneralTradeProducts: state => {
    const allReceivedProducts = [];
    state.bookkeepingEntryTransactions.forEach(t => {
      const receivedProducts = unionBy(t.refunded_partner_cart_item || [], t.cart_items, p => p.product?.id);
      allReceivedProducts.push(...receivedProducts.filter(receivedProduct => receivedProduct.received_quantity || receivedProduct.quantity));
    });
    return allReceivedProducts;
  },
};

const actions = {
  async updateBookkeepingAgentSellingProduct({ commit }, requestPayload) {
    const { data } = await AgenliteAPIClient.updateAgentSellingProduct(requestPayload);
    commit('setBookkeepingAgentSellingProduct', data);
  },
  async fetchBookkeepingEntry({ commit }, requestPayload) {
    const { data } = await AgenliteAPIClient.fetchBookkeepingEntry(requestPayload);
    commit('setBookkeepingEntryForm', data);
  },
  async fetchBookkeepingSellingPrice({ commit }, requestPayload) {
    /* istanbul ignore else */
    if (!requestPayload.selling_product_id) return;
    const { data: sellingProduct } = await AgenliteAPIClient.getAgentSellingProduct(requestPayload.selling_product_id);
    commit('setBookkeepingAgentSellingProduct', sellingProduct);
  },
  async fetchBookkeepingTransactions({ commit, state, rootGetters }, { startDate, endDate, initialFetch }) {
    let params = {};
    if (startDate) params = { start_date: startDate, end_date: endDate };
    if (rootGetters?.isSaasApp) params.automatic = false;

    const { data: bookkeepingEntries, meta } = await AgenliteAPIClient.fetchBookkeepingEntries({
      ...params,
      offset: initialFetch ? 0 : state.bookkeepingTransactions.length,
      limit: 20,
    });
    const entryStartDate = bookkeepingEntries[bookkeepingEntries.length - 1]?.date;
    const latestProfitDate = state.bookkeepingProfits[state.bookkeepingProfits.length - 1]?.date;

    const entryEndDate = initialFetch ?
      bookkeepingEntries[0]?.date :
      bookkeepingEntries.find(entry => isAfter(new Date(latestProfitDate), new Date(entry.date)))?.date;

    if (entryEndDate && entryStartDate) {
      const { data: bookkeepingProfits } = await AgenliteAPIClient.fetchBookkeepingProfits({
        ...params,
        start_date: entryStartDate,
        end_date: entryEndDate,
      });
      const profits = initialFetch ? bookkeepingProfits : state.bookkeepingProfits.concat(bookkeepingProfits);
      commit('setBookkeepingProfits', profits);
    }

    const transactions = initialFetch ? bookkeepingEntries : state.bookkeepingTransactions.concat(bookkeepingEntries);
    commit('setBookkeepingTransactions', transactions);
    commit('setBookkeepingMeta', meta);
  },
  async fetchBookkeepingCategories({ commit }, entryType) {
    const { data: categories } = await AgenliteAPIClient.fetchBookkeepingCategories({
      entry_type: entryType
    });
    commit('setBookkeepingCategories', { entryType, categories });
  },
  async fetchBookkeepingTransactionDetails({ state, commit }) {
    const bookkeepingEntry = state.bookkeepingEntryForm;
    const { data } = await AgenliteAPIClient.getInvoices({ payment_ids: [bookkeepingEntry.payment_id] });
    commit('setBookkeepingEntryTransactions', data?.[0]?.transactions);
  },
  async populateBookkeepingEntryTransactions({ getters, commit }) {
    if (getters.getBookkeepingEntryTransactions?.length === 0) return;

    const aggregate = {};
    getters.getBookkeepingEntryTransactions.forEach(t => {
      aggregate[t.id] = {
        method: 'GET',
        path: `/general-trade/payment-transactions/${t.id}`,
        scope: 'user',
      };
    });
    try {
      const { data } = await AgenliteAPIClient.postAggregate(aggregate);
      commit('setBookkeepingEntryTransactions', Object.values(data || {}));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  },
  resetBookkeepingTransactions({ commit }) {
    commit('setBookkeepingTransactions', []);
    commit('setBookkeepingMeta', {
      total_income: null,
      total_expense: null,
      total: null,
    });
    commit('setBookkeepingProfits', []);
  },
  async createBookkeepingEntry({ commit, state, dispatch }, requestPayload) {
    const { data } = await AgenliteAPIClient.createBookkeepingEntry(requestPayload);
    commit('setBookkeepingEntryForm', data);
    await dispatch('fetchBookkeepingSellingPrice', data);
    commit('setBookkeepingTransactions', [
      data,
      ...state.bookkeepingTransactions
    ]);
  },
  async updateBookkeepingEntry(_, requestPayload) {
    await AgenliteAPIClient.updateBookkeepingEntry(requestPayload);
  },
  async updateBookkeepingEntryDebtStatus(_, id) {
    await AgenliteAPIClient.updateBookkeepingEntryDebtStatus({ id });
  },
  async deleteBookkeepingEntry({ commit, state }, requestPayload) {
    await AgenliteAPIClient.deleteBookkeepingEntry(requestPayload);
    commit('setBookkeepingTransactions', state.bookkeepingTransactions.filter(entry => entry.id !== requestPayload.id));
  },
  async fetchBookkeepingReport({ commit }, requestPayload) {
    const { startDate, endDate, prevPeriodStartDate, prevPeriodEndDate, periodicity, automatic } = requestPayload;
    const thisPeriodPayload = {
      start_date: startDate,
      end_date: endDate,
      periodicity,
      ...(automatic === false ? { automatic } : {}),
    };
    const prevPeriodPayload = {
      start_date: prevPeriodStartDate,
      end_date: prevPeriodEndDate,
      periodicity,
      ...(automatic === false ? { automatic } : {}),
    };
    const [prevPeriodReport, thisPeriodReport] = await Promise.all([
      AgenliteAPIClient.fetchBookkeepingReport(prevPeriodPayload),
      AgenliteAPIClient.fetchBookkeepingReport(thisPeriodPayload),
    ]);

    commit('setBookkeepingReport', {
      thisPeriodReport: thisPeriodReport?.data,
      prevPeriodReport: prevPeriodReport?.data,
    });
  },
  async fetchBookkeepingReportCategories({ commit }, requestPayload) {
    const { startDate, endDate, periodicity, automatic, categoryId } = requestPayload;
    const categoriesPayload = {
      start_date: startDate,
      end_date: endDate,
      periodicity,
      sort: '-total_profit,-total_transaction,category_name',
      categoryId,
      ...(automatic === false ? { automatic } : {}),
    };
    const { data: categoriesReport } = await AgenliteAPIClient.fetchBookkeepingSummariesCategories(categoriesPayload);

    commit('setBookkeepingReportCategories', categoriesReport);
  },
};

const mutations = {
  setIsBookkeepingCoachmarkActive(state, isBookkeepingCoachmarkActive) {
    state.isBookkeepingCoachmarkActive = isBookkeepingCoachmarkActive;
  },
  setIsBookkeepingCoachmarkUnpaidTrxActive(state, isBookkeepingCoachmarkUnpaidTrxActive) {
    state.isBookkeepingCoachmarkUnpaidTrxActive = isBookkeepingCoachmarkUnpaidTrxActive;
  },
  setBookkeepingAgentSellingProduct(state, agentSellingProduct) {
    state.bookkeepingEntryForm = {
      ...state.bookkeepingEntryForm,
      selling_product: agentSellingProduct
    };
  },
  setBookkeepingEntryForm(state, entry) {
    state.bookkeepingEntryForm = entry;
  },
  setBookkeepingTransactions(state, transactions) {
    state.bookkeepingTransactions = transactions;
  },
  setIsNewBookkeepingUser(state, isNewUser) {
    state.haveNoEntry = isNewUser;
  },
  setBookkeepingMeta(state, meta) {
    state.bookkeepingMeta = meta;
  },
  setBookkeepingProfits(state, profits) {
    state.bookkeepingProfits = profits;
  },
  setBookkeepingCategories(state, { entryType, categories }) {
    state.bookkeepingCategories[entryType] = categories;
  },
  setIsOffline(state) {
    state.isOffline = !navigator?.onLine;
  },
  setBookkeepingReport(state, { thisPeriodReport, prevPeriodReport }) {
    state.bookkeepingReportThisPeriod = thisPeriodReport || state.bookkeepingReportThisPeriod;
    state.bookkeepingReportPrevPeriod = prevPeriodReport || state.bookkeepingReportPrevPeriod;
  },
  setBookkeepingReportCategories(state, categoriesReport) {
    state.bookkeepingReportCategories = categoriesReport || state.bookkeepingReportCategories;
  },
  setBookkeepingEntryTransactions(state, transactions = []) {
    state.bookkeepingEntryTransactions = transactions;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
