import AgenliteAPIClient from '~/api';

const initialState = {
  currentWallet: {},
};

const getters = {
  getCurrentWallet(state) {
    return state.currentWallet;
  },
};

const actions = {
  async retrieveCurrentWallet({ commit, dispatch }) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.retrieveCurrentWallet();
      commit('setCurrentWallet', response.data);
    } catch (error) {
      const message = 'Gagal mendapatkan data wallet';
      dispatch('catchError', { error, message, noFlashAlert: false });
    } finally {
      dispatch('hideAppLoading');
    }
    return { response };
  },
};

const mutations = {
  setCurrentWallet(state, currentWallet) {
    state.currentWallet = currentWallet;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
