import { Middleware } from '@nuxt/types';
import promotionMedia from '~/store/modules/promotion-media';

const PromotonMediaMiddleware: Middleware = function ({ store }) {
  if (!store.hasModule('promotionMedia')) {
    store.registerModule('promotionMedia', promotionMedia);
    store.commit('setIsLoadingPromotionMedia', true);
  }
};

export default PromotonMediaMiddleware;
