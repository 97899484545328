import AgenliteAPIClient from '~/api';

const initialState = {
  electricityInfo: {
    id: null,
    subscriberName: null,
    power: null,
    segment: null,
    meterNumber: null,
  },
  transaction: [],
};

const actions = {
  async fetchPrepaidElectricityInfo({ dispatch }, customerNumber) {
    dispatch('showAppLoading');
    try {
      const response = await AgenliteAPIClient.postElectricityPrepaidInquiry(customerNumber);
      dispatch('hideFlashAlert');
      dispatch('setElectricityInfo', response.data);
      return response.data;
    } catch (error) {
      const message = 'Gagal mendapatkan data token listrik.';
      if (error && error.message && error.message.match(/tidak terdaftar/i)) {
        error.message = 'Maaf, nomornya salah. Coba cek lagi ya.';
        throw error;
      }
      dispatch('catchError', { error, message, hideAppLoading: true });
      throw error;
    } finally {
      dispatch('hideAppLoading');
    }
  },
  async createElectricityPrepaidTransaction({ dispatch }, payload) {
    dispatch('showAppLoading');
    try {
      const response = await AgenliteAPIClient.postElectricityPrepaidTransaction(payload);
      dispatch('setPrepaidElectricityTransaction', response.data);
      return response.data;
    } catch (error) {
      let displayMessage = 'Gagal melakukan transaksi token listrik';
      if (error?.errors) {
        const { message } = error.errors[0];
        displayMessage = message;
      }
      dispatch('catchError', { error, displayMessage });
      throw error;
    } finally {
      dispatch('hideAppLoading');
    }
  },
  // non-VOC, depreceated
  async buyPrepaidElectricity({ getters, dispatch }, params) {
    dispatch('showAppLoading');
    try {
      const response = await AgenliteAPIClient.payPrepaidElectricity(getters.getUserId, params.phone, params);
      dispatch('hideAppLoading');
      dispatch('setPrepaidElectricityTransaction', null);
      return response;
    } catch (error) {
      const message = 'Pembelian token listrik gagal.';
      if (error && error.message === 'Password tidak valid') {
        error.message = `Aktifkan otentikasi 2 langkah <a href="${window.location.origin.replace(
          /agen\.|mitra\./,
          'm.',
        )}/account_settings?from=agenlite">di sini</a>.`;
      }
      dispatch('catchError', { error, message, hideAppLoading: true, finishTransaction: true });
      throw error;
    }
  },
  // VOC
  async createTransactionPrepaidElectricityVOC({ dispatch, getters }, requestPayload) {
    const { id: productId, customerNumber, method: paymentMethod, voucherCode, finalAmount, transactionId, type: transactionType } = requestPayload;
    const VOCPayload = {
      customerNumber,
      productId,
    };
    const VOCOptionsPayload = {
      voucherCode,
      finalAmount,
      transactionId,
    };
    const userId = getters.getUserId;
    try {
      const response = await AgenliteAPIClient.createVOCTransaction({
        userId,
        transactionType,
        paymentMethod,
        VOCOptionsPayload,
        VOCPayload,
      });
      return response?.data;
    } catch (error) {
      let displayMessage = 'Pembelian token listrik gagal';
      if (error?.errors) {
        const { message } = error.errors[0];
        displayMessage = message;
      }
      dispatch('catchError', { error, displayMessage, finishTransaction: true });
      throw error;
    }
  },
  clearElectricityInfo({ commit }) {
    commit('clearElectricityInfo');
  },
  setElectricityInfo({ commit }, electricityInfo) {
    commit('setElectricityInfo', electricityInfo);
  },
  setPrepaidElectricityTransaction({ commit }, transaction) {
    commit('setPrepaidElectricityTransaction', transaction);
  },
  startElectricityTokenPolling({ dispatch }, transactionId) {
    let pollingInterval = null;
    let intervalCounter = 0;
    pollingInterval = setInterval(() => {
      AgenliteAPIClient.getElectricityPrepaidTransaction(transactionId)
        .then(response => {
          const { data, meta } = response;
          const isTokenLoaded = !!data.token && data.token !== data.meter_number;
          if (meta.http_status === 200 && data.state === 'succeeded' && isTokenLoaded) {
            dispatch('hideFlashAlert');
            dispatch('updateTransaction', {
              transactionType: 'electricity-success',
              product: data.token,
            });
            clearInterval(pollingInterval);
          } else if (meta.http_status === 200 && data.state === 'refunded') {
            dispatch('updateTransaction', {
              transactionType: 'failed',
              product: '',
            });
            clearInterval(pollingInterval);
          } else if (
            intervalCounter > 10 &&
            meta.http_status === 200 &&
            ['requested', 'paid', 'remitted', 'processed', 'pending'].includes(data.state) &&
            !isTokenLoaded
          ) {
            dispatch('updateTransaction', {
              transactionType: 'electricity-process',
            });
            clearInterval(pollingInterval);
          } else if (intervalCounter > 10) {
            dispatch('updateTransaction', {
              transactionType: 'failed',
              product: '',
            });
            clearInterval(pollingInterval);
          }
        })
        .catch(() => {
          if (intervalCounter > 10) {
            dispatch('updateTransaction', {
              transactionType: 'failed',
            });
            clearInterval(pollingInterval);
          }
        });
      intervalCounter += 1;
    }, 3000);
  },
};

const mutations = {
  clearElectricityInfo(state) {
    state.electricityInfo = JSON.parse(JSON.stringify(initialState.electricityInfo));
  },
  setElectricityInfo(state, electricityInfo) {
    state.electricityInfo.id = electricityInfo.customer_number;
    state.electricityInfo.subscriberName = electricityInfo.customer_name;
    state.electricityInfo.meterNumber = electricityInfo.meter_number;
    state.electricityInfo.power = electricityInfo.power;
    state.electricityInfo.segment = electricityInfo.segmentation;
  },
  setPrepaidElectricityTransaction(state, transaction) {
    if (transaction !== null) state.transaction.push(transaction);
    else state.transaction = [];
  },
};

const getters = {
  getElectricityInfo(state) {
    if (state.electricityInfo.id !== null) {
      return state.electricityInfo;
    }
    return null;
  },
  getPrepaidElectricityTransaction(state) {
    return state.transaction;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
