import r from '~/utils/render-utils';

const BukasendApp = () =>
  import(/* webpackChunkName: 'logistics-bukasend' */ '~/pages/Logistics/bukasend/index');
const JutawanApp = () =>
  import(/* webpackChunkName: 'logistics-jutawan' */ '~/pages/Logistics/jutawan/index');

const logistics = [{
  path: '/bukasend*',
  name: 'logistics_bukasend',
  component: r(BukasendApp),
}, {
  path: '/jutawan*',
  name: 'logistics_jutawan',
  component: r(JutawanApp),
}];

export default logistics;
