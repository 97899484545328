import AgenliteAPIClient from '~/api/index';

const initialState = {
  profile: null,
  mutations: [],
  hasReachedLimit: false,
};

const moduleGetters = {
  getDanaProfile: state => state.profile,
  getDanaBalance: state => (state.profile && state.profile.balance ? state.profile.balance : 0),
  getOTT: state => (state.profile ? state.profile.ott : ''),
  getDisplayedDanaMutations(state) {
    return state.mutations.filter(mutation => mutation.type === 'credit');
  },
  getLimitConfirmation(state) {
    return state.hasReachedLimit;
  },
};

const actions = {
  async retrieveDanaProfile({ dispatch, commit }, willCommit = true) {
    let response = null;
    try {
      response = await AgenliteAPIClient.retrieveDanaProfile();
      if (willCommit) commit('setDanaProfile', response.data);
    } catch (error) {
      response = { data: null }; // ensures response have data of null, but response is not null
      if (error && error.meta && error.meta.http_status !== 404) {
        const message = 'Gagal mendapatkan profile DANA.';
        dispatch('catchError', { error, message, hideAppLoading: true, noFlashAlert: true });
      }
    }
    return response.data;
  },
  async consultDanaTopup({ dispatch }) {
    dispatch('hideFlashAlert');
    let response = { data: null };
    try {
      response = await AgenliteAPIClient.consultDanaTopup();
    } catch (error) {
      if (error && error.meta && error.meta.http_status !== 404) {
        const message = 'Gagal melakukan pengecekan limit topup.';
        dispatch('catchError', { error, message, hideAppLoading: true });
        throw error;
      }
    }
    return {
      // Convert cent to rupiah
      min_amount: response.data.min_amount / 100,
      max_amount: response.data.max_amount / 100,
    };
  },
  async topUpDanaBalance({ commit, dispatch, getters }, { amount }) {
    dispatch('hideFlashAlert');
    dispatch('showAppLoading');
    try {
      await AgenliteAPIClient.payTopupBukadanaTransaction(getters.getUserId, { amount, type: 'self' });
    } catch (error) {
      if (error && error.errors) {
        if (error.errors[0].code === 27325) {
          commit('setLimitConfirmation', true);
        }
      }
      dispatch('catchError', {
        message: !error ? 'Gagal melakukan penambahan saldo DANA.' : '',
        error,
        hideAppLoading: true,
        finishTransaction: true,
      });
      throw error;
    } finally {
      dispatch('hideAppLoading');
    }
  },
  async retrieveDanaRegistrationUrl({ dispatch, getters }, { options }) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.retrieveDanaRegistrationUrl(
        getters.getUserId,
        { category: 'pwa_sentinel' },
        options,
      );
      dispatch('hideFlashAlert');
    } catch (error) {
      if (error?.meta?.['http_status'] === 401) throw error;
      const message = 'Gagal mendapatkan url registrasi DANA.';
      dispatch('catchError', { error, message, hideAppLoading: true });
      throw error;
    } finally {
      dispatch('hideAppLoading');
    }
    return response;
  },
  async bindDanaToken({ dispatch, getters }, authParam) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.bindDanaToken(getters.getUserId, authParam);
      if (response.meta.http_status !== 201) throw response;
    } finally {
      dispatch('hideAppLoading');
    }
    return response;
  },
  async retrieveDanaMutations({ dispatch, commit }) {
    let response = null;
    try {
      const requestPayload = { offset: 0, limit: 20 };
      response = await AgenliteAPIClient.retrieveDanaMutations(requestPayload);
      commit('setDanaMutations', response.data);
    } catch (error) {
      const message = 'Gagal mendapatkan mutasi DANA.';
      dispatch('catchError', { error, message, hideAppLoading: true, noFlashAlert: true });
      throw error;
    }
    return response;
  },
};

const mutations = {
  setDanaProfile(state, profileData) {
    state.profile = profileData;
  },
  setDanaMutations(state, mutationsData) {
    state.mutations = mutationsData;
  },
  setLimitConfirmation(state, value) {
    state.hasReachedLimit = value;
  },
  yieldAggregate(state, bundle) {
    const module = bundle.dana;
    if (!module) return;

    Object.keys(module).forEach(i => {
      state[i] = module[i];
    });
  },
  resetState(state) {
    state.profile = JSON.parse(JSON.stringify(initialState.profile));
    state.hasReachedLimit = JSON.parse(JSON.stringify(initialState.hasReachedLimit));
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  actions,
  mutations,
};
