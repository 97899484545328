import { Store } from 'vuex';
import { Scope } from '~/types/api/request-method';
import TokenStorage from '~/types/api/token-storage';

const DEFAULT_TOKEN_LIFESPAN = 3600;

const clearedData: TokenStorage = {
  access_token: '',
  created_at: -1,
  expires_in: -1,
  scope: 'public',
  token_type: 'Bearer',
};

export interface AccessTokenStorage {
  label: string;
  token: TokenStorage;
  isValid(): boolean;
  isExpired(): boolean;
  isScopeMatch(scope: Scope): boolean;
  clear(): void;
  setStore(store: Store<any>): void;
}

export class ServerTokenStorage implements AccessTokenStorage {
  label: string;
  store?: Store<any>;

  constructor(label: string, store?: Store<any>) {
    this.label = label;
    this.store = store;
  }

  setStore(store: Store<any>): void {
    this.store = store;
  }

  isValid(): boolean {
    return this.token.created_at > 0 && this.token.access_token !== '';
  }

  isExpired(): boolean {
    const tokenLifespan = this.token.expires_in || DEFAULT_TOKEN_LIFESPAN;
    const now = Date.now();
    const expiredAt = (this.token.created_at + tokenLifespan) * 1000;

    return expiredAt < now;
  }

  isScopeMatch(scope: Scope): boolean {
    return this.token.scope.includes(scope);
  }

  get token() {
    return (this.store?.state.authentication.token ?? { ...clearedData }) as TokenStorage;
  }

  set token(tokenObj: TokenStorage) {
    this.store?.commit('setToken', tokenObj);
  }

  clear() {
    this.token = clearedData;
  }
}

export class ClientTokenStorage extends ServerTokenStorage {
  private _storage: Storage;
  constructor(label: string, store?: Store<any>) {
    super(label, store);
    this._storage = window.localStorage;
  }

  get token() {
    const superToken = super.token;
    const localToken: TokenStorage = JSON.parse(this._storage.getItem(this.label) || '{}');
    // TODO: Manage more graceful merge resolver
    const result: TokenStorage = {
      ...superToken,
      ...localToken,
    };
    return result;
  }

  set token(tokenObj: TokenStorage) {
    super.token = tokenObj;
    this._storage.setItem(this.label, JSON.stringify(tokenObj));
  }

  setStore(store: Store<any>) {
    super.store = store;
  }

  clear() {
    super.clear();
    this._storage.setItem(this.label, JSON.stringify(clearedData));
  }
}
