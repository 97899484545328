import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const INFO_URL = `${process.env.API_HOST}/mitra`;

export default Client.wrap({
  retrieveCurrentWallet() {
    return this.get(`${INFO_URL}/me/wallet`, 'public', new ApiOptions());
  },
});
