import credentialStorageInstance from '~/api/credential-storage-instance';
import AccessTokenManager from '~/api/v4/access-token-manager';
import { ClientTokenStorage, ServerTokenStorage } from '~/api/v4/storage-type';

const authEndpoint = '/oauth/token';
const apiEndpoint = process.env.API_HOST;
const appId = process.env.AGENLITE_API_CLIENT_ID;
const redirectUri = process.env.OAUTH_REDIRECT;
const ACCESS_TOKEN_KEY = 'bl_token';
const ACCESS_TOKEN_KEY_DEV = 'bl_token_dev';
const tokenLabel = process.env.NODE_ENV === 'production' ? ACCESS_TOKEN_KEY : ACCESS_TOKEN_KEY_DEV;

const storage = process.server ? new ServerTokenStorage(tokenLabel) : new ClientTokenStorage(tokenLabel);

const accessTokenManagerInstance = new AccessTokenManager({
  storage,
  authEndpoint,
  appId,
  apiEndpoint,
  credentials: credentialStorageInstance,
  redirectUri,
});

export default accessTokenManagerInstance;
