import r from '~/utils/render-utils';
import PageOAuthLogin from '~/pages/System/OAuthLogin';
import PageAuthorize from '~/pages/System/Authorize';

const PageSalesHome = () => import(/* webpackChunkName: 'sales' */ '~/pages/Sales/Home');
const PageSalesSetorSaldo = () => import(/* webpackChunkName: 'sales' */ '~/pages/Sales/SetorSaldo');

const sales = [
  { path: '/sales/login', component: PageOAuthLogin, name: 'sales_login' },
  { path: '/sales/authorize', component: PageAuthorize, name: 'sales_authorize' },
  { path: '/sales', component: r(PageSalesHome), name: 'sales_home' },
  { path: '/sales/setor-saldo', component: r(PageSalesSetorSaldo), name: 'sales_setor_saldo' },
];

export default sales;
