import UAParser from 'ua-parser-js';
import { generateBrowserID } from './platform';

/**
   * Creates a dataset for being sent to tracker
   *
   * @param {Object} options sets of data to be sent
   */
class TrackerDataset {
  browser?: string;
  browser_version?: string;
  os?: string;
  identity ?: string;

  constructor(anyParam: Partial<TrackerDataset> | Record<string, any>) {
    Object.keys(anyParam).map(item => {
      this[item] = anyParam[item];
      return item;
    });
    this.insertDeviceMeta();
  }

  insertDeviceMeta(): void {
    const browser = new UAParser();
    this.browser = browser.getBrowser().name;
    this.browser_version = browser.getBrowser().version;
    this.os = `${browser.getOS().name} ${browser.getOS().version}`;
    this.identity = generateBrowserID();
  }
}

export default TrackerDataset;
