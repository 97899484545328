import location from '~/plugins/LocationPlugin';
import API from '~/api';

export default function ({ store, redirect, route }) {
  if (!API.credentials.login) {
    location.redirect(`https://www.${process.env.WEB_DOMAIN}/login`);
    return;
  }
  if (!store.getters.getCurrentAgent?.id) {
    return;
  }
  if (store.getters.getCurrentAgent?.status === 'deactivated') {
    const splitUrl = route.fullPath.split('?');
    const query = splitUrl && splitUrl.length > 1 ? `?${splitUrl[1]}` : '';
    redirect(`/deactivated${query}`);
    return;
  }
  redirect('/home');
}
