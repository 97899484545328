const initialState = {
  phoneCredit: true,
  dataPlan: true,
  electricityPrepaid: true,
  electricityPostpaid: true,
  pdam: true,
  bpjs: true,
  multifinance: true,
  sendMoney: true,
};

const moduleGetters = {
  getOnboardingVPStatus(state) {
    return state;
  },
};

const actions = {
  closeOnboardingVP({ commit }, type) {
    commit('closeOnboardingVP', type);
  },
};

const mutations = {
  closeOnboardingVP(state, type) {
    state[`${type}`] = false;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  actions,
  mutations,
};
