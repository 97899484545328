import API from '~/api';

export default function ({ store, redirect }) {
  if (!store.app.$pageReferrer) return;
  const currentPageName = store.app.$pageReferrer.name;
  const topupKurirMenu = [
    'topupkurir_home',
    'topupkurir_topup_mitra',
    'topupkurir_setor_saldo',
    'topupkurir_transaction',
    'topupkurir_transaction_detail',
    'topupkurir_komisi',
  ];
  const sharedMenu = [
    'topupkurir_login',
    'topupkurir_authorize',
    'logout',
    'profile_page',
    'reset_password',
    'guidance',
    'guidance_category',
    'guidance_content',
    'guidance_transaction',
    'guidance_trend_others',
    'syarat_mitra',
    'guide_keuntungan',
    'guide_verifikasi',
    'guide_tambah_saldo',
    'guide_beli_pulsa',
    'guide_beli_paket_data',
    'guide_token_listrik',
    'guide_bayar_listrik',
    'guide_bayar_pdam',
    'guide_bayar_bpjs',
    'guide_bayar_angsuran_kredit',
    'guide_grosir',
    'guide_kirim_uang',
    'guide_instal_app',
    'guide_grosir_delivery',
    'bukabantuan_agen',
    'bukabantuan_inbox',
  ];

  function isDriver() {
    const kurir = store.state.topupKurir;
    return kurir && kurir.kurirAgent && kurir.kurirAgent.driver;
  }
  if (API.credentials.login) {
    const driverAccessibleMenu = [...topupKurirMenu, ...sharedMenu];
    if (!isDriver() && topupKurirMenu.includes(currentPageName)) {
      redirect('/');
    } else if (isDriver() && !driverAccessibleMenu.includes(currentPageName)) {
      redirect('/kurir');
    }
  }
}
