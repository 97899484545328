import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';
import RequestBuilder from '../../request_builder';

const PAYOUT_URL = `${process.env.API_V4_HOST}/payouts`;

export default Client.wrap({
  Public: {
    payTopupBukadanaTransaction(userId, requestPayload) {
      const requestHeaders = {
        ...RequestBuilder.getOtpHeader(this.getOtp(userId).key),
      };
      return this.v4.payout.topupDana(userId, requestHeaders, requestPayload);
    },
    retrievePayoutTransactions() {
      return this.v4.payout.retrieveTransactions();
    },
  },
  Private: {
    retrieveTransactions() {
      return this.get(`${PAYOUT_URL}/transactions?states[]=remitted&&states[]=paid`, 'user', new ApiOptions());
    },
    retrieveTransaction(id) {
      return this.get(`${PAYOUT_URL}/transactions/${id}`, 'user', new ApiOptions());
    },
    createPayoutTransaction(requestPayload) {
      return this.post(`${PAYOUT_URL}/transactions`, 'user', new ApiOptions({ requestPayload }));
    },
    topupDana(_userId, requestHeaders, requestPayload) {
      return this.post(`${PAYOUT_URL}/dana/topup`, 'user', new ApiOptions({ requestHeaders, requestPayload }));
    },
  },
});
