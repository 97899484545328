<template lang="pug">
#agenlite-app.u-position-relative.v-cloak--hidden(v-cloak :style="pageStyle")
  client-only
    LoadingBar(v-if="isAppLoading")

  //- Error dialog for webview refresh
  bl-overlay-av(:is-showing="showErrorDialog" title="Terjadi kesalahan" opacity="0.85")
    bl-card-mv.content.p-20.mh-16
      bl-text-av.mb-16(variation="subheading-20") Halaman gagal dimuat
      hr.mb-16
      #error-image(class="u-align-center")
        img(src="/images/error/img_medium_mitra_sorry.png")
      hr.mv-16
      bl-text-av#error-text.mb-20(variation="body-16") Maaf, kami belum berhasil memuat halaman ini. Cek ulang secara berkala, ya!
      bl-button-av(@click="dismissModal" style="letter-spacing: 0.02rem;") Kembali ke Halaman Utama


  .c-transitioner(
    v-show="isTransitioning && useTransitionPlaceholder"
    :style="`background-image: url('${$pwaIcon(64)}')`"
  )
  transition(
    :name="transitionName"
    mode="out-in"
    @before-leave="startTransition"
    @before-enter="endTransition"
    @after-enter="trackMetric"
  )
    nuxt(:key="currentPageKey" :data-route-name="currentPageKey")
</template>

<script>
import { mapGetters } from 'vuex';
import BlOverlayAv from '@bukalapak/bazaar-mweb/dist/components/BlOverlayAv';
import BlCardMv from '@bukalapak/bazaar-mweb/dist/components/BlCardMv';
import BlButtonAv from '@bukalapak/bazaar-mweb/dist/components/BlButtonAv';
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';
import LoadingBar from '~/components/LoadingBar.vue';
import PreloaderMixin from '~/mixins/preloader';
import AccessTokenManager from '~/api/v4/access-token-manager';

export default {
  name: 'DefaultLayout',
  components: {
    LoadingBar,
    BlOverlayAv,
    BlCardMv,
    BlTextAv,
    BlButtonAv,
  },
  mixins: [PreloaderMixin],
  data() {
    return {
      agenliteApp: null,
      transitionName: '',
      opacityTransitionTime: 40, // in miliseconds, calculated from $transition opacity duration halved by 2
      isTransitioning: false,
      transitionTimerIndex: 0,
      useTransitionPlaceholder: true, // set it to false to disable loading placeholder
      showErrorDialog: false,
    };
  },
  head() {
    return {
      title: 'Mitra Bukalapak',
    };
  },
  computed: {
    ...mapGetters(['isAppLoading']),
    currentPageKey() {
      return this.$route.name.split('_', 1)[0];
    },
  },
  watch: {
    $route(to, from) {
      const toDepth = to.path.split('/').length;
      const fromDepth = from.path.split('/').length;
      this.transitionName = toDepth < fromDepth || to.path === '/home' ? 'prev-transition' : 'next-transition';

      if (from.hash || to.hash) return;
      this.$blTracker.actions.trackRoutes({
        evn: 'agenlite_pages',
        referrer: from.name,
        screen_target: to.name,
      });
      if (this.isRecorderActive) {
        this.lastDatetime = new Date().getTime();
        // console.warn('start rec:', this.lastDatetime);
      }
    },
  },
  mounted() {
    AccessTokenManager.eventBus.$on('webview:refresh:error', this.handleRefreshError);
  },
  beforeDestroy() {
    AccessTokenManager.eventBus.$off('webview:refresh:error', this.handleRefreshError);
  },
  methods: {
    startTransition() {
      if (this.isTransitioning) return;
      this.endTransition(); //
      this.transitionTimerIndex = setTimeout(() => {
        this.isTransitioning = true;
      }, this.opacityTransitionTime);
    },
    endTransition() {
      clearTimeout(this.transitionTimerIndex);
      this.isTransitioning = false;
      this.transitionTimerIndex = -1;
    },
    isSentinelWrapped() {
      return /(AndroidApp|blandroid|blios|BLMitraAndroid)/i.test(window.navigator.userAgent);
    },
    dismissModal() {
      // trigger android close
      this.$nativeBridge.close();
    },
    handleRefreshError() {
      // TODO: make it mixin so it can be used for other layouts
      // won't trigger if it's not webview
      if (!this.isSentinelWrapped || !this.$nativeBridge.isAvailable()) {
        console.warn('Refresh handler executed outside webview, Logging out...');
        this.$router.replace('/logout?to=login');
        return;
      }
      this.showErrorDialog = true;
    },
  },
};
</script>

<style lang="scss">
$size: 64px;

#agenlite-app {
  max-width: 540px;
  margin: auto;
}

.c-transitioner {
  width: $size;
  height: $size;
  position: fixed;
  left: calc(50vw - #{$size}/ 2);
  top: calc(50vh - #{$size}/ 2);
  z-index: 1;
}

.content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

#error-image {
  padding: 0 1.5rem;
  align-items: center;

  & img {
    max-width: 225px;
  }
}
</style>
