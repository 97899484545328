const PREFIX = 'agenlite_vp_top_up_on_demand_';

export default {
  topUpOnDemandCommonParamsBuilder() {
    return {
      journey_id: this.getTrackerJourneyId || '',
      referrer_screen: this.routeReferrer.name,
      referrer_url: this.routeReferrer.url,
      current_screen: this.$route.name,
    };
  },
  trackTopUpOnDemandVisit({ isWhitelist, sheetType }) {
    const payload = {
      evn: `${PREFIX}visit`,
      ...this.topUpOnDemandCommonParamsBuilder(),
      is_whitelist: isWhitelist?.toString() || 'false',
      sheet_type: sheetType
    };

    this.$blTracker.actions.trackActions(payload);
  },
  trackTopUpOnDemandClick({ clickSource, clickSourceStatus, topUpValue, isDefaultAddress, sheetType }) {
    const payload = {
      evn: `${PREFIX}click`,
      ...this.topUpOnDemandCommonParamsBuilder(),
      click_source: clickSource,
      click_source_status: clickSourceStatus,
      is_default_address: isDefaultAddress?.toString(),
      top_up_value: topUpValue?.toString(),
      sheet_type: sheetType
    };

    this.$blTracker.actions.trackActions(payload);
  },
};
