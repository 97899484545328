import Client from '~/types/api/api-module';
import {
  FlashSaleList,
  FlashSaleProducts,
  GBCommission,
  CommissionHistoryParams,
  HistoryMonthlyResponse,
  HistoryTransactionResponse,
  FlashSaleProductsParams,
  RecommendationProductsResponse,
  MFBResponse,
  PosterResponse,
  ProductPosterParams,
  ProductsPosterParams,
  CatalogProductParams,
  CatalogProductResponse,
  ReplaceProductParams,
} from '~/types/api/group-buying';
import ApiOptions from '../api-options';

const BASE = process.env.API_V4_HOST;

const GB_PRODUCTS_RECOMMENDATION = `${BASE}/general-trade/recommendations/group-buying-products`;
const GB_PRODUCTS_MFB = `${BASE}/general-trade/recommendations/mfb-products`;
const GB_COMMISSION = `${BASE}/general-trade/group-buying-commissions`;
const MY_GB_COMMISSION = `${GB_COMMISSION}/me`;
const GB_COMMISSION_TRANSACTION_HISTORY = `${GB_COMMISSION}/mutations`;
const GB_COMMISSION_MONTHLY_HISTORY = `${GB_COMMISSION}/histories`;

const GB_FLASH_SALE_LIST = `${BASE}/general-trade/flash-sales`;
const GB_FLASH_SALE_PRODUCT_LIST = `${GB_FLASH_SALE_LIST}/:id/products`;

const GB_BASE_POSTER = `${BASE}/general-trade/posters/flash-sales`;
const GB_PRODUCT_POSTER = `${GB_BASE_POSTER}/products`;
const GB_FLASH_SALE_CATALOG_POSTER = `${GB_BASE_POSTER}/catalogs`;

const GB_CATALOG_PRODUCTS = `${BASE}/general-trade/products`;
const GB_SAVED_CATALOG_PRODUCTS = `${BASE}/general-trade/group-buying-catalogs`;
const GB_CATALOG_POSTER = `${BASE}/general-trade/posters/group-buying-catalogs`;

export default Client.wrap({
  Private: {
    retrieveGBCommission() {
      return this.get<GBCommission>(MY_GB_COMMISSION, 'user', new ApiOptions());
    },
    retrieveCommissionHistory({ historyType, pagination: { limit, offset } = { limit: 10, offset: 0 } }: CommissionHistoryParams) {
      const commissionHistoryURL = historyType === 'all' ? GB_COMMISSION_TRANSACTION_HISTORY : GB_COMMISSION_MONTHLY_HISTORY;
      return this.get<HistoryTransactionResponse[] | HistoryMonthlyResponse[]>(`${commissionHistoryURL}?limit=${limit}&offset=${offset}`, 'user', new ApiOptions());
    },
    retrieveFlashSaleList(status) {
      const requestPayload = { status };
      return this.get<FlashSaleList>(`${GB_FLASH_SALE_LIST}`, 'user', new ApiOptions({ requestPayload }));
    },
    retrieveFlashSaleProducts({ id = 0, pagination: { limit, offset } = { limit: 10, offset: 0 } }: FlashSaleProductsParams) {
      const url = GB_FLASH_SALE_PRODUCT_LIST.replace(':id', id.toString());
      const requestPayload = {
        limit,
        offset,
        status: 'active',
      };
      return this.get<FlashSaleProducts>(`${url}`, 'user', new ApiOptions({ requestPayload }));
    },
    retrieveProductsRecommendation({ limit }) {
      return this.get<RecommendationProductsResponse>(`${GB_PRODUCTS_RECOMMENDATION}?limit=${limit}`, 'user', new ApiOptions());
    },
    retrieveMFB({ limit }) {
      return this.get<MFBResponse>(GB_PRODUCTS_MFB, 'user', new ApiOptions({ requestPayload: { limit } }));
    },
    generateProductPoster(requestPayload: ProductPosterParams) {
      return this.post<PosterResponse>(GB_PRODUCT_POSTER, 'user', new ApiOptions({ requestPayload }));
    },
    generateProductsPoster(requestPayload: ProductsPosterParams) {
      return this.post<PosterResponse>(GB_FLASH_SALE_CATALOG_POSTER, 'user', new ApiOptions({ requestPayload }));
    },
    retrieveCatalogProducts(requestPayload: CatalogProductParams) {
      requestPayload.api_version = 2;
      return this.get<CatalogProductResponse>(GB_CATALOG_PRODUCTS, 'user', new ApiOptions({ requestPayload }));
    },
    retrieveSavedCatalogProducts() {
      return this.get<CatalogProductResponse>(GB_SAVED_CATALOG_PRODUCTS, 'user', new ApiOptions());
    },
    replaceCatalogProducts(requestPayload: ReplaceProductParams) {
      return this.put<CatalogProductResponse>(GB_SAVED_CATALOG_PRODUCTS, 'user', new ApiOptions({ requestPayload }));
    },
    generateCatalogPoster(requestPayload: ProductPosterParams) {
      return this.post<PosterResponse>(GB_CATALOG_POSTER, 'user', new ApiOptions({ requestPayload }));
    },
  }
});
