import AgenliteAPIClient from '~/api/index';

const initialState = {
  transaction: {},
};

const getters = {
  getEdcCheckBalanceTransaction(state) {
    return state.transaction;
  },
};

const actions = {
  async retrieveEdcCheckBalanceTransaction({ dispatch, commit }, requestPayload) {
    try {
      const response = await AgenliteAPIClient.getEdcCheckBalanceTransaction(requestPayload.id);
      commit('setEdcCheckBalanceTransaction', response.data);
    } catch (error) {
      const message = 'Gagal mendapatkan data transaksi';
      dispatch('catchError', { error, message });
      throw error;
    }
  },
  async removeEdcCheckBalanceTransaction(_, requestPayload) {
    try {
      await AgenliteAPIClient.removeEdcCheckBalanceTransaction(requestPayload.id);
    } catch {
      // no action
    }
  },
};

const mutations = {
  setEdcCheckBalanceTransaction(state, transaction) {
    state.transaction = transaction;
  },
};

export default {
  state: () => ({ ...initialState }),
  mutations,
  getters,
  actions,
};
