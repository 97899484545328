import { parse, ParsedQuery } from 'query-string';
import Vue from 'vue';
const config = {
  baseUrl: new URL('https://mitra.bukalapak.com/'),
};

/**
 * Transform ParsedQuery into URLSearchParams compatible object
 * @param query - A ParsedQuery compatible with `query-string` npm
 * @returns A `Record<string, string>` flat object
 */
export function transposeQuery(query: ParsedQuery): Record<string, string> {
  return Object.entries(query).reduce((acc, [prop, val]) => {
    const dummy = {};
    dummy[prop] = Array.isArray(val) ? `[${val.join(',')}]` : val ?? '';
    return Object.assign(dummy, acc);
  }, {});
}

/**
 * @param query A flag string record object
 * @returns {Record<string, string>} sanitized query string
 */
function sanitizeURLQuery(query: Record<string, string>): Record<string, string> {
  const trimmedQuery = { ...query };
  Object.entries(trimmedQuery).forEach(([prop, value]) => {
    trimmedQuery[prop] = typeof value === 'string' ? value.replace(/^(\/|%2F|\\|%5C)*/gim, '') : value;
  });
  return trimmedQuery;
}

/**
 * Cleanse URL to have no initiated slash param, which possible to do redirect exploit
 * @param {string} path - Destination absolute path or relative, without query string
 * @param {Record<string, string>} param - Query string that will be included in path
 * @param {URL} base - base URL used for relative url navigation (if path is relative)
 *
 * @returns {string} Refined URL
 */
export function refineURL(path: string, param: Record<string, string>, base: URL = config.baseUrl): string {
  let fullPath: URL;
  let customProtocol = '';
  try {
    // test absolute URL
    fullPath = new URL(path);
    if (fullPath.protocol !== 'https:') {
      customProtocol = fullPath.protocol;
      fullPath = new URL(path.replace(customProtocol, 'https:'));
    }
  } catch (err) {
    // embed baseUrl to relative url
    // error will be occured if the path is not even valid URL
    fullPath = new URL(`${base.toString()}${path}`);
  }
  const combinedParam = transposeQuery({ ...parse(fullPath.search, { decode: true }), ...param });
  const sanitizedQuery = sanitizeURLQuery(combinedParam);
  const paramsQuery = new URLSearchParams(sanitizedQuery).toString();
  const cleansedPath = `${customProtocol || fullPath.protocol}//${fullPath.host}${fullPath.pathname.replace(
    '//',
    '/'
  )}`;
  const resultUrl = `${cleansedPath}${paramsQuery?.length > 0 ? `?${paramsQuery}` : ''}`;
  return resultUrl;
}
/**
 * @deprecated Just use window.location.href in client-side, or req.url on server-side
 *
 * Returns the full current url of the location (including queries and hashes)
 */
export function url() {
  return window.location.href;
}

/**
 * Returns the current full path of the location (including queries and hashes)
 */
export function fullPath() {
  const combinedParam = transposeQuery({ ...parse(window.location.search, { decode: true }) });
  const sanitizedQuery = sanitizeURLQuery(combinedParam);
  const paramsQuery = new URLSearchParams(sanitizedQuery)?.toString();
  return `${window.location.pathname}${paramsQuery?.length > 0 ? `?${paramsQuery}` : ''}${window.location.hash}`;
}

/**
 * Request document to selected location without adding new history
 * @param {string} path - Destination absolute path or relative, without query string
 * @param {Record<string, string>} param - Query string that will be included in path
 * @param {URL} base - base URL used for relative url navigation (if path is relative)
 */
export function redirect(path: string, param: Record<string, string> = {}, base: URL = config.baseUrl) {
  const cleanedUrl = refineURL(path, param, base);
  window.location.replace(cleanedUrl);
  return cleanedUrl;
}

/**
 * Request document to selected location
 * @param {string} path - Destination absolute path or relative, without query string
 * @param {Record<string, string>} param - Query string that will be included in path
 * @param {URL} base - base URL used for relative url navigation (if path is relative)
 */
export function go(path: string, param: Record<string, string> = {}, base: URL = config.baseUrl) {
  const cleanedUrl = refineURL(path, param, base);
  window.location.assign(cleanedUrl);
  return cleanedUrl;
}
/**
 * Returns the base URL used by LocationPlugin. This value is initialized at plugins/location.js
 * e.g: new URL(https://mitra.bukalapak.com/)
 */
export function baseUrl() {
  return config.baseUrl;
}

/**
 * @deprecated For server side, just trim the req.originalUrl, and for client just use window.location.search
 *
 * Returns the query string of current location.
 * e.g: ?query=string&param=true
 */
export function query() {
  return window.location.search;
}

/**
 * @deprecated For server side, just use req.hostname, and for client, just use window.location.hostname
 *
 *
 * Returns the hostname of current location.
 * e.g. www.example.com
 */
export function hostName() {
  return window.location.hostname;
}

/**
 * @deprecated For server side, just use req.get('host') or similar, and for client-side, just use window.location.origin
 *
 * Returns the origin of current location (including ports and trailings)
 * e.g. http://localhost:3522/
 */
export function origin() {
  return window.location.origin;
}

/**
 * @deprecated For nuxt, use the $nuxt.reload() for effective reloading, or just use window.location.reload directly.
 *
 * Reloads current window into same location
 */
export function reload() {
  window.location.reload();
}

const locationFunc = {
  baseUrl,
  url,
  redirect,
  go,
  query,
  hostName,
  origin,
  reload,
  refineURL,
  fullPath,
};

const LocationPlugin = {
  ...locationFunc,
  install(vue: typeof Vue, { baseUrl: base }) {
    config.baseUrl = new URL(base);
    vue.prototype.$location = locationFunc;
  },
};

export default LocationPlugin;
