import { sha256 } from 'js-sha256';
import { getCurrentTime } from '~/server/helpers/date-helper';

const PREFIX = 'lakupandai_digital_send_';

export default {
  // flag : first_open / on_countdown
  trackDigitalSendDetailStateChange({ transactionId, transactionState, flag = 'first_open', consumedTime = '0' }) {
    const payload = {
      evn: `${PREFIX}status_change_event`,
      action: 'state_changed',
      journey_id: this.getTrackerJourneyId,
      transaction_id: `${transactionId}`,
      transaction_state: transactionState,
      flag,
      consumed_time: `${consumedTime}`,
    };
    this.$blTracker.actions.trackActions(payload);
  },
  trackDigitalSendDetailCompetition(actionName, competitionName, { screenName, transactionId, username, campaignName, subchannel }) {
    const { source, referrer, from } = this.$route.query;
    const payload = {
      evn: `${PREFIX}event`,
      action: `${screenName}_${actionName}_${competitionName}`,
      screen_name: screenName,
      transaction_id: `${transactionId}`,
      journey_id: this.getTrackerJourneyId,
      referrer: this.$route?.name || '',
      source: source || from || referrer || document.referrer || 'direct-access',
      bl_username: username,
      kyc_status: '',
      campaign_name: campaignName,
      subchannel,
    };
    this.$blTracker.actions.trackActions(payload);
  },
  trackDigitalSendDetailSimpanKontak(data) {
    const payload = {
      evn: `agenlite_click_tambah_kontak_pelanggan`,
      screen_name: data.screenName,
      session_id: data.sessionId,
      session_time: Math.round((getCurrentTime() - data.sessionTimeStart) / 1000),
      organic: data.organic,
      agent_type: data.agentType,
      user_phone_number: data.userPhoneNumber ? sha256(data.userPhoneNumber) : '',
      invoice_number: data.invoiceNumber,
      product_id_partner: data.productIdPartner,
      product_category: data.productCategory,
      product_name: data.productName,
      product_quantity: 1,
      source_journey_id: this.getTrackerJourneyId,
    };
    this.$blTracker.actions.trackActions(payload);
  },
  trackDigitalSendDetailNpsSurvey(actionName, { screenName, transactionId, username, campaignName, subchannel, otherInfo }) {
    const { source, referrer, from } = this.$route.query;
    const payload = {
      evn: `${PREFIX}event`,
      action: `${screenName}_nps_survey_${actionName}`,
      screen_name: screenName,
      transaction_id: `${transactionId}`,
      journey_id: this.getTrackerJourneyId,
      referrer: this.$route?.name || '',
      source: source || from || referrer || document.referrer || 'direct-access',
      bl_username: username,
      kyc_status: '',
      campaign_name: campaignName,
      subchannel,
      other_info: otherInfo,
    };
    this.$blTracker.actions.trackActions(payload);
  },
};
