import AgenliteAPIClient from '~/api';

const initialState = {};

const moduleGetters = {};

const actions = {
  async checkWhatsappAvailability({ dispatch }, phoneNumber) {
    let status = null;
    try {
      status = await AgenliteAPIClient.checkWhatsappAvailability(phoneNumber);
    } catch (error) {
      const message = 'Gagal mengecek nomor whatsapp.';
      dispatch('catchError', { error, message, noFlashAlert: true });
    }
    return status;
  },
};

const mutations = {};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  actions,
  mutations,
};
