import accessTokenManagerInstance from '~/api/v4/access-token-manager-instance';
import wrapSecureClientInterface from './v4';

import SilkroadMeAPI from './v4/modules/silkroad-me-api';
import SilkroadUserAPI from './v4/modules/silkroad-user-api';
import SilkroadSuperWarungAPI from './v4/modules/silkroad-super-warung-api';
import SilkroadProductCategoryAPI from './v4/modules/silkroad-category-api';
import SilkroadProductAPI from './v4/modules/silkroad-product-api';
import SilkroadCartAPI from './v4/modules/silkroad-cart-api';
import SilkroadTransactionAPI from './v4/modules/silkroad-transaction-api';
import SilkroadAddressAPI from './v4/modules/silkroad-address-api';
import SilkroadInfoAPI from './v4/modules/silkroad-info-api';
import SilkroadBannerAPI from './v4/modules/silkroad-banner-api';
import SilkroadUserLabelAPI from './v4/modules/silkroad-user-label-api';
import AgenliteAPIClient from './index';

const SilkroadAPIClient = {
  v4: {
    accessTokenManager: accessTokenManagerInstance,
  },

  init() {
    this.v4.silkroadMe = wrapSecureClientInterface(accessTokenManagerInstance, SilkroadMeAPI, 'xhr_v4_silkroad_me');
    this.v4.silkroadSuperWarung = wrapSecureClientInterface(
      accessTokenManagerInstance, 
      SilkroadSuperWarungAPI, 
      'xhr_v4_silkroad_super_warung'
    );
    this.v4.silkroadUser = wrapSecureClientInterface(
      accessTokenManagerInstance,
      SilkroadUserAPI,
      'xhr_v4_silkroad_user'
    );
    this.v4.silkroadProductCategory = wrapSecureClientInterface(
      accessTokenManagerInstance,
      SilkroadProductCategoryAPI,
      'xhr_v4_silkroad_product_category'
    );
    this.v4.silkroadProduct = wrapSecureClientInterface(
      accessTokenManagerInstance,
      SilkroadProductAPI,
      'xhr_v4_silkroad_product'
    );
    this.v4.silkroadCart = wrapSecureClientInterface(accessTokenManagerInstance, SilkroadCartAPI, 'xhr_v4_silkroad_me');
    this.v4.silkroadTransaction = wrapSecureClientInterface(
      accessTokenManagerInstance,
      SilkroadTransactionAPI,
      'xhr_v4_silkroad_transaction'
    );
    this.v4.silkroadAddress = wrapSecureClientInterface(
      accessTokenManagerInstance,
      SilkroadAddressAPI,
      'xhr_v4_silkroad_address'
    );
    this.v4.silkroadInfo = wrapSecureClientInterface(
      accessTokenManagerInstance,
      SilkroadInfoAPI,
      'xhr_v4_silkroad_info'
    );
    this.v4.silkroadBanner = wrapSecureClientInterface(
      accessTokenManagerInstance,
      SilkroadBannerAPI,
      'xhr_v4_silkroad_banner'
    );
    this.v4.silkroadUserLabel = wrapSecureClientInterface(
      accessTokenManagerInstance,
      SilkroadUserLabelAPI,
      'xhr_v4_silkroad_user_label'
    );
  },

  me() {
    return this.v4.silkroadMe.getMe();
  },
  getSuperWarungMe() {
    return this.v4.silkroadSuperWarung.getSuperWarungMe();
  },
  getSuperWarungKYC() {
    return this.v4.silkroadSuperWarung.getSuperWarungKYC();
  },
  patchSuperWarungInboundReview() {
    return this.v4.silkroadSuperWarung.patchSuperWarungInboundReview();
  },
  registerSilkroadUser(options) {
    return this.v4.silkroadUser.registerUser(options);
  },
  retrieveProducts(options) {
    return this.v4.silkroadProduct.retrieveProducts(options);
  },
  retrieveProduct(id) {
    return this.v4.silkroadProduct.retrieveProduct(id);
  },
  setReminderStock(id) {
    return this.v4.silkroadProduct.setReminderStock(id);
  },
  retrieveRecommendation(type, options) {
    return this.v4.silkroadProduct.retrieveRecommendation(type, options);
  },
  getWholesaleProductCategories(parentId) {
    return this.v4.silkroadProductCategory.retrieveProductCategories(parentId);
  },
  retrieveCart(options) {
    return this.v4.silkroadCart.retrieveCart(options);
  },
  addItemCart(options, type, from) {
    return this.v4.silkroadCart.addCart(options, type, from);
  },
  updateItemCart(options, ...args) {
    return this.v4.silkroadCart.updateCart(options, args[0], args[3]);
  },
  deleteItemCart(options) {
    return this.v4.silkroadCart.deleteCart(options);
  },
  validateItemCart() {
    return this.v4.silkroadCart.validateCart();
  },
  retrieveFreeGoodSampling() {
    return this.v4.silkroadCart.retrieveFreeGoodSampling();
  },
  retrieveTransactions() {
    return this.v4.silkroadTransaction.retrieveTransactions();
  },
  retrieveTransaction(paramId) {
    return this.v4.silkroadTransaction.retrieveTransaction(paramId);
  },
  createTransaction(options) {
    const requestPayload = { ...options };
    requestPayload.payment_type = requestPayload.method;
    delete requestPayload.method;
    if (requestPayload.payment_type === 'mitra_cod') {
      requestPayload.cod = true;
    }
    return this.v4.silkroadTransaction.createTransaction(requestPayload);
  },
  receiveTransaction(paramId) {
    return this.v4.silkroadTransaction.receiveTransaction(paramId);
  },
  retrieveInvoiceDelivery(paramId) {
    return this.v4.silkroadTransaction.retrieveInvoiceDelivery(paramId);
  },
  async buyProduct(userId, options) {
    const trxResponse = await this.createTransaction(options);
    if (trxResponse.meta.http_status !== 201) throw trxResponse;
    const voucherCode = options.voucher_code;
    const invoiceResponse = await AgenliteAPIClient.registerInvoice(
      trxResponse.data.id,
      'general-trade',
      options.method,
      voucherCode
    );
    if (invoiceResponse.meta.http_status !== 201) throw invoiceResponse;
    const paymentResponse = await AgenliteAPIClient.makePayment(
      userId,
      invoiceResponse.data.payment_id,
      options.method
    );
    const response = {
      paymentResponse,
      invoiceResponse,
      trxResponse,
    };
    if (paymentResponse.meta.http_status !== 200) throw response;
    return response;
  },
  addAddress(options) {
    return this.v4.silkroadAddress.createAddress(options);
  },
  updateAddress(options, userId) {
    return this.v4.silkroadAddress.updateAddress(options, userId);
  },
  getProvinces() {
    return this.v4.silkroadInfo.getProvince();
  },
  getCities(options) {
    return this.v4.silkroadInfo.getCity(options);
  },
  getMultipleCities(options) {
    return this.v4.silkroadInfo.getMultipleCity(options);
  },
  getAreas(options) {
    return this.v4.silkroadInfo.getArea(options);
  },
  getDistricts(options) {
    return this.v4.silkroadInfo.getDistrict(options);
  },
  getPostCodes(options) {
    return this.v4.silkroadInfo.getPostCode(options);
  },
  getSpecialPromo(id) {
    return this.v4.silkroadBanner.getSpecialPromo(id);
  },
  retrieveBannerProducts(id) {
    return this.v4.silkroadProduct.retrieveBannerProducts(id);
  },
  addUserLabels(options) {
    return this.v4.silkroadUserLabel.createUserLabels(options);
  },
};

SilkroadAPIClient.init();

export default SilkroadAPIClient;
