import { Client } from '~/types/api/api-module';
import ApiOptions from '../api-options';

const WALLET_URL = `${process.env.API_V4_HOST}/wallet`;

export default Client.wrap({
  getWalletMutations(requestPayload: any) {
    return this.get(`${WALLET_URL}/mutations`, 'user', new ApiOptions({ requestPayload }));
  },
});
