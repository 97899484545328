import blTracker from '@bukalapak/tracker-js';
import throttle from 'lodash/throttle';
import AgenliteAPIClient from '~/api';
import SilkroadAPIClient from '~/api/silkroad';
import { generateBrowserID, getTrackerPlatform } from '~/utils/platform';
import Dataset from '~/utils/tracker-dataset';
import { promiseAlmost } from '~/utils/promise-utils';
import { Braze } from '~/plugins/braze';
import AgenliteLocalForage from '~/plugins/localforage';
import { AppboyBridgeInterface } from '~/plugins/Braze/AppboyBridge';

type Appboy = typeof import('@braze/web-sdk').default;

interface TrackerClient {
  internalTracker: typeof blTracker;
  braze: Appboy | AppboyBridgeInterface | {};
}

const SYNC_DATA_THROTLE = 30 * 1000; // 30seconds
const SYNC_DATA_CACHE_KEY = 'user-tracker-attribute';
const SYNC_DATA_CACHE_EXPIRY = 30 * 24 * 60 * 60 * 1000; // 30days
const env = process.env.NODE_ENV;
const isDev = env !== 'production';
const trackerConfig = {
  braze: {
    apiKey: process.env.BRAZE_API_KEY_MITRA_WEB || '',
    sdkEndpoint: process.env.BRAZE_SDK_ENDPOINT_MITRA_WEB || '',
    active: true,
    enableLogging: isDev,
  },
  internalTracker: {
    id: env,
    host: isDev ? `http://www.${process.env.WEB_DOMAIN}:9909` : process.env.TRACKER_HOST,
    active: true,
  },
};

const tracker: Partial<TrackerClient> = {};

const init = async store => {
  const user = {
    id: AgenliteAPIClient.getCurrentUserId36(),
  };
  if (trackerConfig.internalTracker.active) {
    blTracker.config({
      baseUrl: trackerConfig.internalTracker.host,
      dev: isDev || Boolean(process.env.DEV_DEBUG_TRACKER),
      platform: getTrackerPlatform(window.navigator.userAgent, store),
    });
    tracker.internalTracker = blTracker;
  }
  if (trackerConfig.braze.active) {
    tracker.braze = await Braze.init(user, trackerConfig.braze);
  }
};

const fetchUserAttributes = async () => {
  const [
    currentUser,
    currentAgent,
    mixedDana,
    danaProfile,
    userPoint,
    userTier,
    userGeneralTrade,
  ] = await promiseAlmost([
    AgenliteAPIClient.retrieveCurrentUser(),
    AgenliteAPIClient.retrieveCurrentAgent(),
    AgenliteAPIClient.getMixedDanaActivationType(),
    AgenliteAPIClient.retrieveDanaProfile(),
    AgenliteAPIClient.retrieveUserPoint(),
    AgenliteAPIClient.retrieveUserTierProgress(),
    SilkroadAPIClient.me(),
  ]);
  return {
    phone: currentUser?.data?.phone || null,
    email: currentUser?.data?.email || null,
    username: currentUser?.data?.username || null,
    full_name: currentUser?.data?.name || null,
    mitra_id: currentAgent?.data?.id ?? null,
    is_organic: currentAgent?.data?.organic ?? null,
    joined_at: currentAgent?.data?.['joined_at'] ? new Date(currentAgent.data.joined_at) : null,
    mitra_type: currentAgent?.data?.['agent_type'] || null,
    mitra_persona: currentAgent?.data?.['display_agent_type'] || null,
    referrer: currentAgent?.data?.referrer?.username || null,
    province: userGeneralTrade?.data?.address?.province || null,
    city: userGeneralTrade?.data?.address?.city || null,
    area: userGeneralTrade?.data?.address?.area || null,
    district: userGeneralTrade?.data?.address?.district || null,
    store_name: userGeneralTrade?.data?.address?.name || null,
    is_eligible_grocery: userGeneralTrade?.data?.['coverage_availability'] ?? null,
    warehouse_name: userGeneralTrade?.data?.warehouses?.[0]?.name || null,
    server_host: process.env.WEB_DOMAIN,
    dana_balance: danaProfile?.data?.balance ?? null,
    wallet_balance: currentAgent?.data?.dompet?.saldo ?? null,
    bukadompet_balance: currentAgent?.data?.dompet?.['saldo_dompet'] ?? null,
    credits_balance: currentAgent?.data?.dompet?.['saldo_credits'] ?? null,
    mixed_dana_agreement: mixedDana?.data?.['mixed_dana_agreement'] || null,
    point_balance: userPoint?.data?.['accumulated_point'] ?? null,
    point_expired_at: userPoint?.data?.['point_expiry_date'] ? new Date(userPoint.data.point_expiry_date) : null,
    mitra_tiering: userTier?.data?.['current_tier'] || null,
  };
};

const actions = {
  syncTrackerAttributes: throttle(
    async () => {
      if (!trackerConfig.braze.active) return;
      const userId = AgenliteAPIClient.getCurrentUserId36();
      if (!userId || userId === '0') return;
      const userAttributeCache = (await AgenliteLocalForage.getItem<{ value: number; expire: number }>(
        SYNC_DATA_CACHE_KEY
      )) || { value: null, expire: null };
      const isAttributeExpired = Date.now() > (userAttributeCache?.expire ?? 0);
      const userAttribute = await fetchUserAttributes();
      if (Braze.instance) {
        Braze.instance.changeUser?.(userId);
        const brazeUser = Braze.instance.getUser?.();
        Object.keys(userAttribute).forEach(key => {
          const isAttributeEqual =
            JSON.stringify(userAttributeCache.value?.[key]) === JSON.stringify(userAttribute[key]);
          if (!isAttributeExpired && isAttributeEqual) return null;
          if (key === 'phone') return brazeUser?.setPhoneNumber(userAttribute.phone);
          if (key === 'email') return brazeUser?.setEmail(userAttribute.email);
          if (key === 'city') return brazeUser?.setHomeCity(userAttribute.city);
          if (key === 'mitra_id' && userAttribute.mitra_id) {
            return brazeUser?.addAlias(userAttribute.mitra_id.toString(), 'mitra_id');
          }
          return brazeUser?.setCustomUserAttribute(key, userAttribute[key]);
        });
        Braze.instance.logCustomEvent?.('prime-for-push');
      }
      AgenliteLocalForage.setItem(SYNC_DATA_CACHE_KEY, {
        value: userAttribute,
        expire: isAttributeExpired ? Date.now() + SYNC_DATA_CACHE_EXPIRY : userAttributeCache.expire,
      });
    },
    SYNC_DATA_THROTLE,
    { trailing: false }
  ),
  clearAttributeCache() {
    AgenliteLocalForage.removeItem(SYNC_DATA_CACHE_KEY);
  },
  trackRoutes(data = {}) {
    const payload = {
      ...data,
      identity: generateBrowserID(),
      ui: AgenliteAPIClient.getCurrentUserId36(),
      env,
    };

    const url = blTracker.pixel(new Dataset(payload), 'agenlite');
    return fetch(url);
  },
  trackActions(data = {}) {
    const payload = {
      ...data,
      identity: generateBrowserID(),
      ui: AgenliteAPIClient.getCurrentUserId36(),
    };

    blTracker.event(payload);
  },
};

export default {
  init,
  actions,
  tracker,
};
