import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';
import RequestBuilder from '../../request_builder';

const BASE_URL = `${process.env.API_V4_HOST}/trains`;

export interface TrainPayload {
  id: number;
  type: string;
  invoice_id: string;
  contact: {
    phone: string;
  };
}

interface TrainPayResponse {
  invoiceResponse: Record<string, any>;
  paymentResponse: Record<string, any>;
}

export default Client.wrap({
  Public: {
    async payTrainTransaction(userId: number, transaction: TrainPayload, voucherCode: string, method: string): Promise<TrainPayResponse> {
      const { contact } = transaction;
      const invoiceResponse = await this.registerInvoice(transaction.id, transaction.type, method, voucherCode);
      if (invoiceResponse.meta.http_status !== 201) throw invoiceResponse;

      this.createAgentTransactionDetail(invoiceResponse.data.payment_id, transaction.invoice_id, contact.phone);

      const paymentResponse = await this.makePayment(userId, invoiceResponse.data.payment_id, method);
      if (paymentResponse.meta.http_status !== 200) throw paymentResponse;
      return {
        invoiceResponse,
        paymentResponse,
      };
    },
  },
  Private: {
    getTrainStations() {
      return this.get(`${BASE_URL}/stations`, 'public', new ApiOptions());
    },
    getTrainSchedules(requestPayload: Record<string, any>) {
      return this.get(`${BASE_URL}/schedules`, 'public', new ApiOptions({ requestPayload }));
    },
    getTrainPopularDestinations() {
      return this.get(`${BASE_URL}/popular`, 'public', new ApiOptions());
    },
    getTrainSeatMap(requestPayload: Record<string, any>) {
      return this.get(`${BASE_URL}/seat-maps`, 'public', new ApiOptions({ requestPayload }));
    },
    getTrainTransaction(id: number) {
      return this.get(`${BASE_URL}/transactions/${id}`, 'user', new ApiOptions());
    },
    patchTrainChangeSeat(payload: Record<string, any>) {
      const requestPayload = RequestBuilder.getTrainSeatChangePayload(payload);
      return this.patch(`${BASE_URL}/transactions/${payload.trx.id}/seats`, 'user', new ApiOptions({ requestPayload }));
    },
    patchTrainCancelBooking(trxId: number) {
      const requestPayload = { state: 'cancelled' };
      return this.patch(`${BASE_URL}/transactions/${trxId}/status`, 'user', new ApiOptions({ requestPayload }));
    },
    postTrainRegisterTransaction(payload: Record<string, any>) {
      const requestPayload = RequestBuilder.getTrainTransactionPayload(payload);
      return this.post(`${BASE_URL}/transactions`, 'user', new ApiOptions({ requestPayload }));
    },
  },
});
