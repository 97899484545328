import AgenliteAPIClient from '~/api';

const initialState = {
  activeEvent: {},
  activeEventDetail: [],
};

const getters = {
  getActiveEvent(state) {
    return state.activeEvent;
  },
  getActiveEventDetail(state) {
    return state.activeEventDetail;
  },
};

const actions = {
  async retrieveActiveEvent({ commit, dispatch }) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.retrieveActiveEvent();
      commit('setActiveEvent', response.data);
    } catch (error) {
      const message = 'Gagal Mendapatkan Event.';
      dispatch('catchError', { error, message, noFlashAlert: false });
    } finally {
      dispatch('hideAppLoading');
    }
    return { response };
  },
  async retrieveActiveEventDetail({ commit, dispatch }, id) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.retrieveActiveEventDetail(id);
      commit('setActiveEventDetail', response.data);
    } catch (error) {
      const message = 'Gagal Mendapatkan Event.';
      dispatch('catchError', { error, message, noFlashAlert: false });
    } finally {
      dispatch('hideAppLoading');
    }
    return { response };
  },
  async joinActiveEvent({ dispatch }) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.joinActiveEvent();
    } catch (error) {
      const message = 'User gagal join event';
      dispatch('catchError', { error, message, noFlashAlert: false });
    } finally {
      dispatch('hideAppLoading');
    }
    return { response };
  },
};

const mutations = {
  setActiveEvent(state, activeEvent) {
    state.activeEvent = activeEvent;
  },
  setActiveEventDetail(state, activeEventDetail) {
    state.activeEventDetail = activeEventDetail;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
