import {
  PublicCreateCustomerSubscriptionInstantRefundResponse,
  PublicGetCustomerSubscriptionInstantRefundEligibilityResponse,
} from '@bukalapak/openapi-schema/vptrader/transaction/v1/public/transaction-domain';
import VPNonTraderAPIClient from '~/api/vpnontrader';
import { ALLOWLIST_FEATURE } from '~/utils/subscription';

const state = () => ({
  eligibility: {
    eligible: false,
    expired_at: '',
    reason: '',
  },
  allowlist: null,

  loading: {
    eligibility: true,
    claim: false,
  },
});

const actions = {
  async createCustomerSubscriptionInstantRefund({ commit, dispatch }, payload) {
    let response: PublicCreateCustomerSubscriptionInstantRefundResponse = {};
    commit('setSubscriptionInstantRefundLoading', { name: 'claim', value: true });

    try {
      response = await VPNonTraderAPIClient.createCustomerSubscriptionInstantRefund(payload);
      return response;
    } catch (error) {
      dispatch('catchError', { error, noFlashAlert: true });
      throw error;
    } finally {
      commit('setSubscriptionInstantRefundLoading', { name: 'claim', value: false });
    }
  },

  async getCustomerSubscriptionInstantRefundEligibility({ commit, dispatch }, { payload, options }) {
    let response: PublicGetCustomerSubscriptionInstantRefundEligibilityResponse = {};

    if (!options?.ignoreLoading) {
      commit('setSubscriptionInstantRefundLoading', { name: 'eligibility', value: true });
    }

    try {
      response = await VPNonTraderAPIClient.getCustomerSubscriptionInstantRefundEligibility(payload);
      const eligibility = response?.subscription_instant_refund_eligibility_customer;
      commit('setSubscriptionInstantRefundEligibility', eligibility);

      if (eligibility?.reason === 'transaction is not set as subscription') {
        await dispatch('getInstantRefundAllowlist');
      }
    } catch (error) {
      dispatch('catchError', { error, noFlashAlert: true });
    } finally {
      commit('setSubscriptionInstantRefundLoading', { name: 'eligibility', value: false });
    }
    return response;
  },

  async getInstantRefundAllowlist({ commit, dispatch, state }) {
    if (state.allowlist !== null) {
      return;
    }

    const instantRefundFeature = [ALLOWLIST_FEATURE.REFUND_STANDALONE, ALLOWLIST_FEATURE.JAGOAN_VIRTUAL];

    for (const feature of instantRefundFeature) {
      const response = await dispatch('retrieveAllowlistMeFeature', { feature });
      const isAllowed = response?.data?.value === 'allowed';
      if (isAllowed) {
        commit('setInstantRefundAllowlist', isAllowed);
        return;
      }
    }

    if (state.allowlist === null) {
      commit('setInstantRefundAllowlist', false);
    }
  },
};

const mutations = {
  setSubscriptionInstantRefundEligibility(state, response) {
    state.eligibility = response;
  },
  setInstantRefundAllowlist(state, response) {
    state.allowlist = response;
  },
  setSubscriptionInstantRefundLoading(state, payload) {
    state.loading[payload.name] = payload.value;
  },
};

const getters = {
  getSubscriptionInstantRefundEligibility(state) {
    return state.eligibility;
  },
  getInstantRefundAllowlist(state) {
    return state.allowlist;
  },
  getSubscriptionInstantRefundLoading(state) {
    return state.loading;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
