<template lang="pug">
  transition(name='loading-fader')
    .c-spinner-container.loading-fader(:class="{ 'c-spinner--absolute' : isAbsolute }")
      .c-loading-spinner
</template>

<script>
export default {
  name: 'LoadingSpinner',
  props: {
    isAbsolute: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-fader {
  transition: opacity 0.24s cubic-bezier(0.365, 0.585, 0.19, 0.99);
  &-leave-active {
    opacity: 0;
  }
  &-enter-active {
    opacity: 0.5;
  }
}
// absolute to its relative container
.c-spinner {
  &--absolute {
    position: absolute;

    .c-loading-spinner::before {
      margin-top: -18.5px;
      margin-left: -30px;
    }
  }
}
</style>
