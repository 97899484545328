<template lang="pug">
.c-fixed-footer
  .c-fixed-footer__space(:style="`height: ${footerHeight};`")
  .c-fixed-footer__content(:style="`height: ${footerHeight};`" :class="contentClass")
    div(v-if="hasInfoSlot" :class="`${infoSlotClass}`" :style="`min-height: ${infoSlotMinHeight}`")
      slot(name="info")
        .u-hidden
          .u-txt--small.u-txt--bold.u-float-left TOTAL HARGA
          .u-txt--xlarge.u-float-right Rp10.000
          .u-clearfix
    slot(name="button")
    div(ref="customSlot")
      slot(name="custom")
</template>

<script>
export default {
  name: 'FixedFooter',
  props: {
    shadow: {
      type: Boolean,
      default: false,
    },
    hasBorder: {
      type: Boolean,
      default: false,
    },
    hasCustomBorder: {
      type: Boolean,
      default: false,
    },
    shouldRecalculate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      footerHeight: '',
    };
  },
  computed: {
    hasInfoSlot() {
      return !!this.$slots.info;
    },
    hasCustomSlot() {
      return !!this.$slots.custom;
    },
    infoSlotMinHeight() {
      return this.hasInfoSlot ? '38px' : '1px';
    },
    infoSlotClass() {
      return this.hasInfoSlot ? 'u-border-top--1--sand-dark u-pad-top--3 u-mrgn-bottom--3' : '';
    },
    contentClass() {
      let contentClass = '';
      contentClass += this.shadow ? ' u-shadow--ash-light' : '';
      contentClass += this.hasBorder ? ' has-border' : '';
      contentClass += this.hasCustomBorder ? ' has-custom-border' : '';
      contentClass += this.hasCustomSlot ? ' u-pad--0' : '';
      return contentClass;
    },
  },
  watch: {
    shouldRecalculate() {
      this.calculateHeight();
    },
  },
  mounted() {
    this.calculateHeight();
  },
  methods: {
    calculateHeight() {
      this.$nextTick(() => {
        if (this.hasCustomSlot) {
          this.footerHeight = this.$refs.customSlot ? `${this.$refs.customSlot.offsetHeight}px` : '0';
        } else if (this.hasInfoSlot) {
          this.footerHeight = '124px';
        } else {
          this.footerHeight = '76px';
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.c-fixed-footer {
  &__space {
    position: relative;
    width: 100%;
  }
  &__content {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: white;
    padding: 16px;
    margin: auto;
    z-index: 150;
    max-width: 540px;
    margin: auto;
  }
}
.u-shadow--ash-light {
  box-shadow: 0 0 16px #ddd;
}
.has-border {
  box-sizing: border-box;
  border-top: 1px solid #eee;
}
.has-custom-border {
  box-sizing: border-box;
  border-top: 1px solid #eee;
  border-radius: 16px 16px 0px 0px;
}
</style>
