
import { Client } from '~/types/api/api-module';
import ApiOptions from '../api-options';

const URL = `${process.env.API_V4_HOST}/mitra`;

export interface MitraType {
  mitra_type: string;
  display_name: string;
  description: string;
}

export interface MitraPromoVoucherHighlight {
  key: string;
  value: { info: string; code:string }[];
}

export interface MitraPromo {
  title: string;
  slug: string;
  categories: string[];
  url: string;
  brief: string;
  highlights: MitraPromoVoucherHighlight[];
}


export default Client.wrap({
  Private: {
    fetchMitraTypes() {
      return this.get<MitraType[]>(`${URL}/info/mitra-types`, 'public', new ApiOptions());
    },
    fetchMitraPromos(category: string) {
      return this.get<MitraPromo[]>(`${URL}/promos?category=${category}`, 'user', new ApiOptions());
    },
  }
});
