import AgenliteAPIClient from '~/api';
import SilkroadAPIClient from '~/api/silkroad';

const initialState = {
  newRegistration: false,
};

const moduleGetters = {
  getNewRegistrationState(state) {
    return state.newRegistration;
  },
};

const mutations = {
  setNewRegistrationState(state, status) {
    state.newRegistration = status;
  },
  resetState(state) {
    state.newRegistration = JSON.parse(JSON.stringify(initialState.newRegistration));
  },
};

const actions = {
  setNewRegistration({ commit }) {
    commit('setNewRegistrationState', true);
  },
  resetNewRegistration({ commit }) {
    commit('setNewRegistrationState', false);
  },
  async requestOTP({ dispatch }, options) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.requestOTP(options);
    } catch (error) {
      const message = 'Gagal meminta token.';
      dispatch('catchError', { error, message });
    } finally {
      dispatch('hideAppLoading');
    }
    return response;
  },
  async registerMitra({ dispatch, getters }, { requestPayload, options = {} }) {
    dispatch('showAppLoading');
    dispatch('hideFlashAlert');
    let response = null;
    try {
      response = await AgenliteAPIClient.registerMitra(requestPayload, options, getters.getUserId);
      if (((response || {}).meta || {}).http_status !== 201) throw response;

      window.blandroid?.refreshToken?.(accessToken => {
        const accessObject = {
          access_token: accessToken,
          refresh_token: '',
          token_type: 'Bearer',
          created_at: Date.now(),
          expires_in: 3600,
          userId: response.data.user_id,
          login: true,
          authorize: true,
          scope: 'public user store',
        };
        AgenliteAPIClient.syncAccessToken({ v4: accessObject });
      });
      dispatch('hideAppLoading');
    } catch (error) {
      dispatch('hideAppLoading');
      if (options.triggerOnly && ((error || {}).meta || {}).http_status === 422) {
        return error;
      }
      if (((error || {}).meta || {}).http_status !== 401) {
        dispatch('catchError', {
          error,
          message: 'Gagal melakukan registrasi.',
          hideAppLoading: true,
        });
      }
      throw error;
    }
    return response;
  },
  retrieveUser({ dispatch }, username) {
    return new Promise((resolve, reject) => {
      AgenliteAPIClient.fetchUserAvailability(username)
        .then(response => {
          if (!response || !response.meta || response.meta.http_status !== 200) throw response;
          dispatch('hideFlashAlert');
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  retrieveAgent(_store, username) {
    return AgenliteAPIClient.retrieveAgent(username);
  },
  async checkRegistrationAvailability({ dispatch }, param) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.fetchUserRegistrationAvailability(param);
    } catch (error) {
      if (error && error.meta && error.meta.http_status === 400) {
        return error;
      }
      const message = 'Gagal mengirim data.';
      dispatch('catchError', { error, message });
      throw error;
    } finally {
      dispatch('hideAppLoading');
    }
    return response;
  },
  registerSilkroad({ dispatch, commit }, addressId) {
    return new Promise((resolve, reject) => {
      SilkroadAPIClient.registerSilkroadUser(addressId)
        .then(response => {
          response.data.whitelist = false;
          if (response.data.warehouses.length > 0) response.data.whitelist = true;
          commit('setCurrentUser', response.data);
          commit('setWholesaleModal', true);
          resolve(response);
        })
        .catch(error => {
          const message = 'Gagal melakukan registrasi grosir.';
          dispatch('catchError', { error, message, hideAppLoading: true });
          reject(error);
        });
    });
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  actions,
  mutations,
};
