import API from '~/api';
import { promiseAlmost } from '~/utils/promise-utils';

const initialState = {
  isMixPaymentActivated: false,
  danaTNCText: {},
  isTNCAccepted: null,
};

const initGetters = {
  isMixPaymentActive(state) {
    return state.isMixPaymentActivated;
  },
  isTNCAccepted(state) {
    return state.isTNCAccepted;
  },
  getDanaTNCText(state) {
    return state.danaTNCText;
  },
};

const mutations = {
  setMixPaymentActivationState(state, activationStateResponse) {
    const { data } = activationStateResponse;
    state.isMixPaymentActivated = data.mixed_dana_availability;
    state.isTNCAccepted = data.mixed_dana_agreement;
  },
  setDanaTNCText(state, fetchTNCResponse) {
    const { data } = fetchTNCResponse;
    state.danaTNCText = data;
  },
  setTNCAcceptanceState(state, agreementResponse) {
    const { message } = agreementResponse;
    state.isTNCAccepted = message === 'ok';
  },
};

const wrapActionCommonAPILoader = async ({
  apiMethod = '',
  params = null,
  dispatch = () => {},
  commit = () => {},
  mutation = '',
  flashErrMessage = '',
}) => {
  dispatch('showAppLoading');
  const successCodeHTTP = [200, 201];
  let response = null;
  try {
    if (params) response = await API[apiMethod](...params);
    else response = await API[apiMethod]();

    const responseSuccess = successCodeHTTP.includes(response?.meta?.['http_status']);
    if (!response || !response.meta || !responseSuccess) throw response;
    commit(mutation, response);
  } catch (error) {
    dispatch('catchError', { error, flashErrMessage, hideAppLoading: true });
    throw error;
  } finally {
    dispatch('hideAppLoading');
    dispatch('hideFlashAlert');
  }
  return response;
};

const actions = {
  async fetchMixPaymentActivationState({ dispatch, commit, getters }) {
    const { isTNCAccepted, isMixPaymentActive } = getters;

    if (isTNCAccepted && isMixPaymentActive) return true;

    const fetchAction = await wrapActionCommonAPILoader({
      apiMethod: 'getMixedDanaActivationType',
      dispatch,
      commit,
      mutation: 'setMixPaymentActivationState',
      flashErrMessage: 'Gagal mendapatkan data aktivasi dana.',
    });

    return fetchAction;
  },
  async fetchDanaTNCText({ dispatch, commit }) {
    const fetchAction = await wrapActionCommonAPILoader({
      apiMethod: 'getMitraTNC',
      params: ['mixed-dana'],
      dispatch,
      commit,
      mutation: 'setDanaTNCText',
      flashErrMessage: 'Gagal mendapatkan data aktivasi dana.',
    });

    return fetchAction;
  },
  async updateMitraTNCAgreement({ dispatch, commit }, activationState) {
    const fetchAction = await wrapActionCommonAPILoader({
      apiMethod: 'postMitraTNCAgreement',
      params: ['mixed-dana', { state: activationState }],
      dispatch,
      commit,
      mutation: 'setTNCAcceptanceState',
      flashErrMessage: 'Gagal mendapatkan data syarat ketentuan dana.',
    });

    return fetchAction;
  },
  retriveSaldoMitraDana({ dispatch }) {
    return promiseAlmost([
      dispatch('retrieveCurrentAgent', { forceCommit: true }),
      dispatch('retrieveDanaProfile'),
      dispatch('fetchMixPaymentActivationState'),
    ]);
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: initGetters,
  actions,
  mutations,
};
