<template lang="pug">
.c-input-otp
  bl-text-av.c-input-otp__title(variation="subheading-18" weight="semi-bold") Kode verifikasi telah dikirim
  bl-text-av.c-input-otp__text(variation="body-14")
    | {{ inputOtpText }}
    |
    strong {{ phoneNumber }}

  bl-text-av.c-input-otp__label(variation="body-14") Masukkan kode verifikasinya di bawah
  .c-input-otp__form(@paste.prevent="onPaste")
    bl-code-input-mv(v-model="otpCode")
    bl-text-av.c-input-otp__warning-label(v-if="shouldShowWarning" variation="caption-12") {{ warningLabel }}

  bl-text-av.c-input-otp__text(v-if="otpTimer > 0" variation="body-14")
    | Belum terima kode?
    |
    strong Tunggu {{ otpTimer }} detik lagi
  bl-text-av.c-input-otp__text(v-else-if="otpTimer <= 0 && !getTfaOtpState.isLoading" variation="body-14")
    | Kode verifikasi gak masuk?
    |
    strong.c-input-otp__text__button-link(@click="retryRequestOtp") Kirim ulang

  .c-input-otp-footer
    bl-button-av(
      variation="primary"
      fullwidth
      @click="submitOtpCode"
      :disabled="!isFilled || getTfaOtpState.isLoading"
    ) Verifikasi
</template>

<script>
import { mapGetters } from 'vuex';
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';
import BlButtonAv from '@bukalapak/bazaar-mweb/dist/components/BlButtonAv';
import { BlCodeInputMv } from '@bukalapak/bazaar-guild-mweb';

export default {
  name: 'InputOTP',
  components: {
    BlTextAv,
    BlCodeInputMv,
    BlButtonAv,
  },
  props: {
    phoneNumber: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    otpCode: '',
    isInputEmpty: false,
    isInputIncomplete: false,
    shouldShowInvalidOtp: false,
  }),
  computed: {
    ...mapGetters(['isFlashAlertDisplayed', 'getTfaOtpState']),
    isFilled() {
      return this.otpCode !== '';
    },
    otpTimer() {
      return this.getTfaOtpState.otpTimer;
    },
    invalidOtp() {
      return this.getTfaOtpState.isInvalidOtp;
    },
    inputOtpText() {
      return this.getTfaOtpState.method === 'whatsapp'
        ? 'Cek kodenya di pesan WhatsApp yang dikirim ke no.'
        : 'Cek kodenya dalam SMS yang masuk di nomor';
    },
    warningLabel() {
      if (this.isInputEmpty) {
        return 'Kamu belum memasukkan kodenya';
      }
      if (this.isInputIncomplete) {
        return 'Kode yang kamu masukkan belum lengkap';
      }
      if (this.shouldShowInvalidOtp) {
        return 'Kode yang kamu masukkan salah, cek kembali ya';
      }
      return '';
    },
    shouldShowWarning() {
      return this.isInputEmpty || this.isInputIncomplete || this.shouldShowInvalidOtp;
    },
  },
  watch: {
    invalidOtp(newVal) {
      if (newVal) {
        this.clearOtpInput();
        this.shouldShowInvalidOtp = !this.isFlashAlertDisplayed;
      }
    },
  },
  mounted() {
    this.tryReceiveOtpSms();
  },
  methods: {
    async tryReceiveOtpSms() {
      try {
        // Docs: https://web.dev/web-otp/
        // SMS Example:
        // Masukkan kode: 12345.
        // @mitra.bukalapak.com #12345
        if (!('OTPCredential' in window)) throw new Error('sms_feature_unavailable');
        const content = await navigator.credentials.get({
          otp: {
            transport: ['sms'],
          },
        });
        if (!content) throw new Error('otp_credential_no_content');
        this.otpCode = content.code;
        this.giveBack();
        this.$emit('autofillSuccess', this.otpCode);
      } catch (err) {
        console.warn('[ERROR OTP AUTOFILL]', err?.message || err);
        this.$emit('autofillFailed', err);
      }
    },
    giveBack() {
      this.$store.dispatch('hideFlashAlert');
      if (this.otpCode.length === 5) {
        this.submitOtpCode();
      }
    },
    retryRequestOtp() {
      if (this.getTfaOtpState.isLoading) return;
      this.shouldShowInvalidOtp = false;
      this.clearOtpInput();
      this.$emit('retryRequestOtp');
    },
    onPaste(event) {
      this.otpCode = (event.clipboardData || window.clipboardData).getData('text');
    },
    clearOtpInput() {
      this.otpCode = '';
    },
    submitOtpCode() {
      this.$emit('submitOtp', this.otpCode);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

.c-input-otp {
  padding: $space-base $space-small;
  text-align: center;

  &__title {
    margin-bottom: $space-xx-small;
  }

  &__text {
    margin-bottom: $space-base;
    &__button-link {
      color: $navy-30;
    }
  }

  &__label {
    margin-bottom: $space-xx-small;
  }

  &__form {
    margin-bottom: $space-small;
  }

  &__warning-label {
    color: $red-40;
    margin-top: $space-xx-small;
  }

  &-footer {
    position: absolute;
    bottom: $space-base;
    right: $space-small;
    left: $space-small;
  }
}
</style>

<style lang="scss">
@media only screen and (max-width: 350px) {
  .c-input-otp {
    .bl-code-input__item {
      width: 17%;
    }
  }
}
</style>
