import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const TRANSACTION_URL = `${process.env.API_V4_HOST}/transactions`;

export default Client.wrap({
  getTransaction(id) {
    return this.get(`${TRANSACTION_URL}/${id}`, 'user', new ApiOptions());
  },
  setTransaction(requestPayload) {
    return this.post(TRANSACTION_URL, 'user', new ApiOptions({ requestPayload }));
  },
  setTransactionStatus(id, requestPayload) {
    return this.put(`${TRANSACTION_URL}/${id}/status`, 'user', new ApiOptions({ requestPayload }));
  },
});
