import {
  PublicGetCustomerPrepaidPhoneTransactionResponse,
  PublicCreateCustomerPrepaidPhoneTransactionResponse,
} from '@bukalapak/openapi-schema/vptrader/transaction/v1/public/transaction-domain';
import {
  PublicGetCustomerTransactionResponse,
  SharedTransactionMetadata,
} from '@bukalapak/openapi-schema/vptrader/transaction/v2/public/transaction-domain';
import AgenliteAPIClient from '~/api';
import VPNonTraderAPIClient from '~/api/vpnontrader';
import { getErrorMessage } from '~/store/modules/error-handler';

function getMetadataValue(meta: SharedTransactionMetadata[] | undefined, name: string): string {
  return (
    meta?.find(m => {
      return m.name === name;
    })?.value || ''
  );
}

function convertToV1Response(
  res: PublicGetCustomerTransactionResponse
): PublicGetCustomerPrepaidPhoneTransactionResponse {
  const meta = res.customer_transaction?.metadata_list;

  return {
    prepaid_phone_transaction: {
      id: res.customer_transaction?.id,
      order_id: res.customer_transaction?.order_id,
      invoice_group_number: res.customer_transaction?.invoice_group_number,
      state: res.customer_transaction?.state,
      payment_state: res.customer_transaction?.payment_state,
      phone_number: getMetadataValue(meta, 'phone_number'),
      serial_number: getMetadataValue(meta, 'serial_number'),
      currency: res.customer_transaction?.currency,
      total_price: res.customer_transaction?.total_price,
      product_name: res.customer_transaction?.product_name,
      paid_at: res.customer_transaction?.paid_at,
      created_at: res.customer_transaction?.created_at,
      updated_at: res.customer_transaction?.updated_at,
      base_price: res.customer_transaction?.base_price,
      active_period: getMetadataValue(meta, 'active_period'),
      segmentation_description: '', // unused
      promo_label_text: getMetadataValue(meta, 'promo_label_text'),
      promo_label_start_time: '', // unused
      promo_label_end_time: '', // unused
      product_id: res.customer_transaction?.product_id,
      partner_id: res.customer_transaction?.partner_id,
      discount: res.customer_transaction?.discount,
      segmentation_adjustment: res.customer_transaction?.segmentation_adjustment,
      product_price: getMetadataValue(meta, 'product_price'),
      admin_fee: getMetadataValue(meta, 'admin_fee'),
      product_time_to_succeed: getMetadataValue(meta, 'product_time_to_succeed'),
      product_description: getMetadataValue(meta, 'product_description'),
      service_fee: '', // unused
      platform_fee: '', // unused
      refund_note: getMetadataValue(meta, 'refund_note'),
      vendor: {
        code: res.customer_transaction?.vendor?.code,
        logo_url: res.customer_transaction?.vendor?.logo_url,
        name: res.customer_transaction?.vendor?.name,
      },
    },
  };
}

const initialState = {
  transaction: {
    vendor: {},
  },
  announcement: {},
  prepaidPhoneTransferOnboardingOpened: false,
  loading: true,
};

const getters = {
  getPrepaidPhoneTransaction(state) {
    return state.transaction;
  },
  getPrepaidPhoneTransactionLoading(state) {
    return state.loading;
  },
  getPrepaidPhoneAnnouncement(state) {
    return state.announcement;
  },
  getPrepaidPhoneTransferOnboardingOpened(state) {
    return state.prepaidPhoneTransferOnboardingOpened;
  },
};

const actions = {
  async retrievePhonePrepaidTransaction({ dispatch, commit }, payload) {
    let response: PublicGetCustomerTransactionResponse = {};

    const { options, ...requestPayload } = payload;

    try {
      if (!options?.ignoreLoading) {
        commit('setPhonePrepaidTopupTransactionLoading', true);
      }

      response = await VPNonTraderAPIClient.getPrepaidPhoneTopupTransaction(requestPayload.id);
      const trx = convertToV1Response(response);

      const isBasePriceSegmentationAdjusted =
        getMetadataValue(response.customer_transaction?.metadata_list, 'is_base_price_segmentation_adjusted') ===
        'true';
      commit('setPhonePrepaidTopupTransaction', {
        ...trx.prepaid_phone_transaction,
        is_base_price_segmentation_adjusted: isBasePriceSegmentationAdjusted,
      });

      return trx.prepaid_phone_transaction;
    } catch (error) {
      const message = 'Gagal mendapatkan data transaksi';
      dispatch('catchError', { error, message });
      throw error;
    } finally {
      commit('setPhonePrepaidTopupTransactionLoading', false);
    }
  },
  async fetchPrepaidPhoneAnnouncement({ dispatch, commit }) {
    let response: any;
    try {
      response = await AgenliteAPIClient.fetchVirtualProductStatusInfoType();
      const announcement = response.data.find(
        announcement => announcement.type === 'phone_credit' && announcement.status
      );

      commit('setPrepaidPhoneAnnouncement', announcement);
    } catch (error) {
      const message = 'Gagal mendapatkan data pengumuman';
      dispatch('catchError', { error, message });
    }
  },
  async submitPrepaidPhoneTransaction({ dispatch }, payload) {
    let response: PublicCreateCustomerPrepaidPhoneTransactionResponse = {};
    try {
      response = await VPNonTraderAPIClient.createPrepaidPhoneTransaction(payload);
    } catch (error) {
      const message = getErrorMessage(error);
      if (message?.trim() === 'duplicate order id') {
        dispatch('catchError', {
          message: 'Pembelian pulsa/paket data dengan nomor dan nominal yang sama maksimal 1x per menit',
        });
        return;
      }

      dispatch('catchError', { error });
    }
    return response;
  },
  syncPrepaidPhoneTransferOnboardingOpened({ commit }) {
    const onboardStatus = localStorage.getItem('prepaid_phone_transfer_onboard_opened');
    commit('setPrepaidPhoneTransferOnboardingOpened', onboardStatus === 'true');
  },
  changePrepaidPhoneTransferOnboardingOpened({ commit }) {
    const onboardStatus = localStorage.getItem('prepaid_phone_transfer_onboard_opened');
    if (onboardStatus !== 'true') {
      localStorage.setItem('prepaid_phone_transfer_onboard_opened', 'true');
      commit('setPrepaidPhoneTransferOnboardingOpened', true);
    }
  },
};

const mutations = {
  setPhonePrepaidTopupTransaction(state, transaction) {
    state.transaction = {
      ...transaction,
      base_price: parseInt(transaction.base_price, 10),
      total_price: parseInt(transaction.total_price, 10),
    };
  },
  setPhonePrepaidTopupTransactionLoading(state, isLoading) {
    state.loading = isLoading;
  },
  setPrepaidPhoneAnnouncement(state, announcement) {
    state.announcement = announcement;
  },
  setPrepaidPhoneTransferOnboardingOpened(state, prepaidPhoneTransferOnboardingOpened) {
    state.prepaidPhoneTransferOnboardingOpened = prepaidPhoneTransferOnboardingOpened;
  },
};

export default {
  state: () => ({ ...initialState }),
  mutations,
  getters,
  actions,
};
