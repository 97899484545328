<template lang="pug">
//- Bikin placeholder imut2
</template>

<script>
import { mapGetters } from 'vuex';
import tracker from '~/mixins/tracker';

export default {
  mixins: [tracker],
  data: () => ({}),
  computed: {
    ...mapGetters(['sharedConfig', 'getKurirAgent', 'getCurrentAgent']),
    isSales() {
      return this.$route.name === 'sales_authorize';
    },
    redirectUri() {
      // Redirect URI based on login page
      switch (this.$route.name) {
        case 'sales_authorize':
          // Login via /sales/login
          return process.env.OAUTH_REDIRECT_SALES;
        default:
          // Normal login
          return process.env.OAUTH_REDIRECT;
      }
    },
  },
  async mounted() {
    const { code } = this.$route.query;
    // Fetch login via code param
    // console.warn(code);
    try {
      this.trackLogin('login_success_authorization_page');

      if (!code || code.length === 0) throw new Error('No Auth Code Found');
      await this.$store.dispatch('authenticateCode', { authCode: code, redirectUri: this.redirectUri });
      await this.$store.dispatch('refetchSharedConfig');
      if (this.isKurir || this.isSales) {
        try {
          await this.$store.dispatch('retrieveCurrentAgent');
          if (this.getCurrentAgent?.coordinator) this.$router.replace('/sales');
          else this.$router.replace({ name: 'agenlite_apps_lite_homepage' });
        } catch (error) {
          if (error.message === 'NOT_AN_AGENT') this.$location.redirect('/aplikasi-mitra');
          else if (error.message === 'DEACTIVATED_AGENT') this.$router.replace('/deactivated');
        }
      } else {
        this.trackerVendor.braze?.logCustomEvent?.('user_login', {
          phone: this.sharedConfig?.user?.phone,
          email: this.sharedConfig?.user?.email,
        });
        const comeback = window.sessionStorage.getItem('comeback');
        if (comeback) {
          window.sessionStorage.removeItem('comeback');
          this.$router.replace(comeback);
          return;
        }
        this.$router.replace({ name: 'agenlite_apps_lite_homepage' });
      }
    } catch (error) {
      console.warn(error, `and login state is ${this.$credentials.login}`);
      const target = { name: this.$credentials.login ? 'agenlite_apps_lite_homepage' : 'logout' };
      this.$router.replace(target);
    }
  },
};
</script>
