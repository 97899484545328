const transformArray = {
  methods: {
    splitArraytoChunk(arr, chunkSize) {
      const chunked = [];
      for (let i = 0, len = arr.length; i < len; i += chunkSize) {
        chunked.push(arr.slice(i, i + chunkSize));
      }
      return chunked;
    },
  }
};

export default transformArray;
