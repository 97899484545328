const initialState = {
  selectedMethod: 1,
  paymentMethods: [
    {
      label: 'Saldo DANA',
      key: 'dana',
      onlyShowIn: [
        'vp_pulsa',
        'vp_tokenlistrik',
        'vp_tix_train_checkout',
        'vp_ins_motorcycle_insurance_checkout',
        'vp_tix_kupon_detail_checkout',
        'referral_checkout',
        'subscription-checkout'
      ],
    },
    {
      label: 'Saldo Mitra',
      key: 'wallet',
    },
    {
      label: 'Bayar Tunai (COD)',
      key: 'mitra_cod',
      onlyShowIn: ['grosir_checkout', 'detail_transaction_product'],
    },
  ],
  totalPrice: 0,
  totalPay: 0,
};

const moduleGetters = {
  getSelectedPaymentMethod: state => state.paymentMethods[state.selectedMethod],
  getAvailablePaymentMethods: (state, getters) => {
    const { _route: route } = window.$nuxt || {};

    let availablePaymentMethod = state.paymentMethods.filter(
      method => !method.onlyShowIn || method.onlyShowIn.includes((route || {}).name)
    );

    if (!getters.getCurrentUser || !getters.getCurrentUser.cod_available) { availablePaymentMethod = availablePaymentMethod.filter(method => method.key !== 'mitra_cod'); }
    if (getters.isAgentLoanable || !getters.toggleConfig.danaFeature) { availablePaymentMethod = availablePaymentMethod.filter(method => method.key !== 'dana'); }
    return availablePaymentMethod;
  },
  getPaymentTotalPrice: state => state.totalPrice,
  getPaymentTotalPay: state => state.totalPay,
};

const actions = {
  setSelectedPaymentMethod({ dispatch, commit }, { paymentMethod }) {
    dispatch('setCOD', paymentMethod === 'mitra_cod');
    commit('setSelectedPaymentMethod', paymentMethod);
  },
  resetPaymentMethod({ dispatch, commit }) {
    dispatch('setCOD', false);
    commit('resetPaymentMethod');
  },
  setPaymentTotalPrice({ commit, state }, totalPrice) {
    if (totalPrice === state.totalPrice) return;
    commit('setPaymentTotalPrice', totalPrice);
  },
  setPaymentTotalPay({ commit, state }, totalPay) {
    if (totalPay === state.totalPay) return;
    commit('setPaymentTotalPay', totalPay);
  },
};

const mutations = {
  setSelectedPaymentMethod(state, paymentMethod) {
    state.selectedMethod = state.paymentMethods.findIndex(method => method.key === paymentMethod);
  },
  resetPaymentMethod(state) {
    state.selectedMethod = JSON.parse(JSON.stringify(initialState.selectedMethod));
  },
  setPaymentTotalPrice(state, totalPrice) {
    state.totalPrice = totalPrice;
  },
  setPaymentTotalPay(state, totalPay) {
    state.totalPay = totalPay;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  actions,
  mutations,
};
