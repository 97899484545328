import AgenliteAPIClient from '~/api';
import { delimiter, stripMoney } from '~/server/helpers/number-helper';

function groupBy(arr, key) {
  return arr.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

const initialState = {
  accumulatedPoint: '0',
  floatingExpiryPoint: '0',
  selectedReward: null,
  selectedMutation: null,
  currentTransactionPoint: {
    point: '0',
    transactionId: 0,
    invoiceCode: '',
    status: 'none', // remitted, processing, none
  },
  pointStatus: {
    displayPoint: false,
    blacklisted: false,
    lastMutationCheck: '',
    pointExpiryDate: '',
    maxDailyPoint: null,
    floatingExpiryDate: '',
    optInDate: null,
    newUser: false,
  },
  exchangeSheetState: {
    showFullPrizeDesc: false,
    showExchangeConfirm: false,
    showExchangeSuccess: false,
    showExchangeFailed: false,
    showRewardCardFailed: false,
    showRewardTiering: false,
    failedType: ''
  },
  configs: {},
  conversions: [],
  tierProgress: {},
  tierHistory: [],
  tierBenefits: [],
  tierList: [
    { level: 1, slug: 'bronze', name: 'Bronze' },
    { level: 2, slug: 'silver', name: 'Silver' },
    { level: 3, slug: 'gold', name: 'Gold' },
    { level: 4, slug: 'platinum', name: 'Platinum' },
  ],
  myMissions: [],
  expiryConfig: {},
};

const getters = {
  getAccumulatedPoint(state) {
    return state.accumulatedPoint;
  },
  getFloatingExpiryPoint(state) {
    return state.floatingExpiryPoint;
  },
  getNormalPoint(state) {
    return delimiter(Number(stripMoney(state.accumulatedPoint)) - Number(stripMoney(state.floatingExpiryPoint)));
  },
  getSelectedReward(state) {
    return state.selectedReward;
  },
  getSelectedLoyaltyMutation(state) {
    return state.selectedMutation;
  },
  getCurrentTransactionPoint(state) {
    return state.currentTransactionPoint;
  },
  getPointStatus(state) {
    return state.pointStatus;
  },
  getExhangeSheetState(state) {
    return state.exchangeSheetState;
  },
  getPointConfigs(state) {
    return state.configs;
  },
  getUserPointConversions(state) {
    return state.conversions;
  },
  getUserTierProgress(state) {
    return state.tierProgress;
  },
  getUserTierHistory(state) {
    return state.tierHistory;
  },
  getTierBenefits(state) {
    return state.tierBenefits;
  },
  getTierList(state) {
    return state.tierList;
  },
  getMyMissions(state) {
    return state.myMissions;
  },
  getExpiryConfig(state) {
    return state.expiryConfig;
  },
};

const getMutationText = mutation => {
  const categoryMap = {
    'Agent::PointAdd': 'Penambahan Poin (Admin)',
    'Agent::PointSub': 'Masa Berlaku Habis',
    'bpjs-kesehatan': 'Pembayaran BPJS',
    'data-plan-prepaid': 'Pembelian Paket Data',
    data_plan: 'Pembelian Paket Data',
    'electricity-prepaid': 'Pembelian Token Listrik',
    electricity_postpaid: 'Pembayaran Tagihan Listrik',
    'general-trade': 'Belanja Grosir',
    'mandiri-emoney': 'Pembayaran Uang Elektronik',
    mission_reward: `Ekstra Poin dari ${mutation.name}`,
    multifinance: 'Pembayaran Angsuran Kredit',
    pdam: 'Pembayaran PDAM',
    'phone-credit-prepaid': 'Pembelian Pulsa',
    phone_credit: 'Pembelian Pulsa',
    redeem: `Penukaran Poin ${mutation.name}`,
    'telkom-postpaid': 'Pembayaran Telkom/IndiHome',
    topdown: 'Masa Berlaku Habis',
    topup: 'Penambahan Poin (Admin)',
    train: 'Pembayaran Tiket Kereta',
    conversion: 'Pakai Poin jadi potongan'
  };
  return categoryMap[mutation.category] || categoryMap[mutation.type] || 'Penyesuaian Poin';
};

const actions = {
  async retrieveUserPoint({ commit, state }, { willCommit, force } = { willCommit: true, force: false }) {
    let responseValue = {
      displayPoint: false,
      failedFetch: true,
      point: 0,
    };
    try {
      if ((state.pointStatus && state.pointStatus.displayPoint) && !force) {
        return {
          blacklisted: state.pointStatus.blacklisted,
          displayPoint: true,
          lastMutationCheck: state.lastMutationCheck,
          point: !state.blacklisted ? state.accumulatedPoint : '0',
          optInDate: state.pointStatus.optInDate,
          newUser: state.pointStatus.newUser,
        };
      } // Force Exit if it's displayed already and not forced.
      const response = await AgenliteAPIClient.retrieveUserPoint();
      const {
        blacklisted,
        accumulated_point: point,
        floating,
        last_mutation_check_at: lastMutationCheck,
        point_expiry_date: pointExpiryDate,
        opt_in_date: optInDate,
        new_user: newUser,
      } = response.data;
      responseValue = {
        blacklisted,
        displayPoint: true,
        lastMutationCheck,
        pointExpiryDate,
        point: !blacklisted ? point : '0',
        floatingExpiryPoint: floating.expiry_point,
        floatingExpiryDate: floating.expired_at,
        optInDate,
        newUser,
      };
      if (willCommit) commit('setPointState', responseValue);
    } catch (error) {
      if (state.accumulatedPoint !== '0') commit('setAccumulatedPoint', '0');
    }
    return responseValue;
  },
  async retrieveUserPointConfigs({ commit, dispatch }) {
    let configsResp = null;
    let conversionsResp = null;
    try {
      [configsResp, conversionsResp] = await Promise.all([
        AgenliteAPIClient.retrievePointConfigs(),
        AgenliteAPIClient.retrieveUserPointConversions(),
      ]);
      commit('setUserPointConfigs', { configs: configsResp.data, conversions: conversionsResp.data });
    } catch (error) {
      const message = 'Gagal mendapatkan data konfigurasi.';
      dispatch('catchError', { error, message, noFlashAlert: true });
    }
    return { conversionsResp, configsResp };
  },
  async retrieveUserTierProgress({ commit, dispatch }) {
    let response = null;
    try {
      response = await AgenliteAPIClient.retrieveUserTierProgress();
      commit('setUserTierProgress', response.data);
    } catch (error) {
      const message = 'Gagal mendapatkan data peringkat.';
      dispatch('catchError', { error, message, noFlashAlert: true });
    }
    return { response };
  },
  async retrieveUserTierHistory({ commit, dispatch }) {
    let response = null;
    try {
      response = await AgenliteAPIClient.retrieveUserTierHistory();
      commit('setUserTierHistory', response.data);
    } catch (error) {
      const message = 'Gagal mendapatkan data riwayat peringkat.';
      dispatch('catchError', { error, message, noFlashAlert: true });
    }
    return { response };
  },
  async retrieveTierBenefits({ commit, dispatch }) {
    let response = null;
    try {
      response = await AgenliteAPIClient.retrieveTierBenefits();
      commit('setTierBenefits', groupBy(response.data, 'tier'));
    } catch (error) {
      const message = 'Gagal mendapatkan data hadiah peringkat.';
      dispatch('catchError', { error, message, noFlashAlert: true });
    }
    return { response };
  },
  async retrieveUserMutations({ dispatch }, requestPayload) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.retrieveUserMutations(requestPayload);
      response.data = response.data.map(mutation => ({
        ...mutation,
        display_text: getMutationText(mutation)
      }));
      dispatch('hideAppLoading');
    } catch (error) {
      const message = 'Gagal mendapatkan data mutasi.';
      dispatch('catchError', { error, message, hideAppLoading: true });
    }
    return response;
  },
  async estimatePoint({ commit, state }, data) {
    let response = null;
    const { status, transactionId } = state.currentTransactionPoint;
    if (status !== 'processing' || transactionId !== data.remote_transaction_id) {
      response = await AgenliteAPIClient.estimatePoint(data);
      const { point } = response.data;
      commit('setCurrentTransactionPoint', {
        point: delimiter(point),
        transactionId: data.remote_transaction_id,
        status: 'processing',
      });
    }
    return response;
  },
  redeemReward(_context, data) {
    return AgenliteAPIClient.redeemReward(data);
  },
  async retrieveRewards({ dispatch }, requestPayload) {
    let response;
    try {
      response = await AgenliteAPIClient.retrieveRewards(requestPayload);
    } catch (error) {
      const message = 'Gagal mendapatkan data reward.';
      dispatch('catchError', { error, message, hideAppLoading: true });
    }
    return response;
  },
  async retrieveSingleReward({ dispatch }, id) {
    let response = null;
    try {
      dispatch('showAppLoading');
      response = await AgenliteAPIClient.retrieveSingleReward(id);
    } finally {
      dispatch('hideAppLoading');
    }
    return response?.data;
  },
  retrieveRedeemRewards(_context, { limit, offset }) {
    return AgenliteAPIClient.retrieveRedeemRewards({ limit, offset });
  },
  clearDisplayedTransactionPoint({ commit }) {
    commit('clearDisplayedTransactionPoint');
  },
  async remittedPoint({ commit, state, dispatch }, data) {
    let response = null;
    const { status, transactionId } = state.currentTransactionPoint;
    if (status !== 'remitted' || transactionId !== data.invoice_id) {
      try {
        response = await AgenliteAPIClient.remittedPoint(data.invoice_id);
        const { point } = response.data;
        commit('setCurrentTransactionPoint', {
          point: delimiter(point),
          transactionId: data.invoice_id,
          status: 'remitted',
        });
      } catch (error) {
        dispatch('estimatePoint', data);
        throw error;
      }
    }
    return response;
  },
  async getTransactionPointByInvoiceCode({ commit, state, dispatch }, data) {
    let response = null;
    const { status, invoiceCode } = state.currentTransactionPoint;
    if (status !== 'remitted' || invoiceCode !== data.invoice_code) {
      try {
        response = await AgenliteAPIClient.remittedPoint(data.invoice_code);
        const { point } = response.data;
        commit('setCurrentTransactionPoint', {
          point: delimiter(point),
          transactionId: data.invoice_id,
          status: 'remitted',
        });
      } catch (error) {
        dispatch('estimatePoint', data);
        throw error;
      }
    }
    return response;
  },
  setSelectedReward({ commit }, data) {
    let howTo = [];
    let termsCondition = [];
    try {
      howTo = JSON.parse(data.how_to);
      termsCondition = JSON.parse(data.terms_condition);
    } catch (error) {
      console.error(error);
    }
    const parsedData = {
      ...data,
      howTo,
      termsCondition
    };
    commit('setSelectedReward', parsedData);
  },
  setSelectedLoyaltyMutation({ commit }, data) {
    commit('setSelectedLoyaltyMutation', data);
  },
  setExchangeSheetState({ commit }, data) {
    commit('setSheetState', data);
  },
  async patchOptinLoyalty({ dispatch }) {
    let response;
    dispatch('showAppLoading');
    try {
      response = await AgenliteAPIClient.patchOptinLoyalty();
    } catch (error) {
      const message = 'Gagal mendaftar program loyalitas. Silakan ulangi beberapa saat lagi.';
      dispatch('catchError', { error, message });
      throw error;
    } finally {
      dispatch('hideAppLoading');
    }
    return response;
  },
  async retrieveMyMissions({ dispatch, commit }, params) {
    try {
      const response = await AgenliteAPIClient.retrieveMyMissions(params);
      commit('setMyMissions', response?.data);
    } catch (error) {
      const message = 'Gagal mendapatkan data misi mitra.';
      dispatch('catchError', { error, message, hideAppLoading: true });
    }
  },
  async retrieveExpiryConfig({ dispatch, commit }) {
    try {
      const configKey = 'o2o-rtn/announcement-expiring-point';
      const response = await AgenliteAPIClient.fetchNeoConfigs([configKey]);
      const [config] = response?.data;
      commit('setExpiryConfig', config.data);
    } catch (error) {
      const message = 'Gagal mendapatkan data config.';
      dispatch('catchError', { error, message, hideAppLoading: true });
    }
  }
};

const mutations = {
  setPointState(state, {
    point,
    floatingExpiryPoint,
    floatingExpiryDate,
    blacklisted,
    displayPoint,
    lastMutationCheck,
    pointExpiryDate,
    maxDailyPoint,
    optInDate,
    newUser,
  }) {
    state.accumulatedPoint = delimiter(point);
    state.floatingExpiryPoint = floatingExpiryPoint ? delimiter(floatingExpiryPoint) : '0';
    state.pointStatus = {
      blacklisted,
      displayPoint,
      lastMutationCheck,
      pointExpiryDate,
      maxDailyPoint,
      floatingExpiryDate,
      optInDate,
      newUser,
    };
  },
  setUserTierProgress(state, tierProgress) {
    state.tierProgress = tierProgress;
  },
  setUserTierHistory(state, tierHistory) {
    state.tierHistory = tierHistory;
  },
  setTierBenefits(state, tierBenefits) {
    state.tierBenefits = tierBenefits;
  },
  setUserPointConfigs(state, { configs, conversions }) {
    state.configs = configs;
    state.conversions = conversions;
  },
  setSelectedReward(state, data) {
    state.selectedReward = data;
  },
  setSelectedLoyaltyMutation(state, data) {
    state.selectedMutation = data;
  },
  setCurrentTransactionPoint(state, data) {
    state.currentTransactionPoint = data;
  },
  clearDisplayedTransactionPoint(state) {
    state.currentTransactionPoint = {
      ...initialState.currentTransactionPoint,
    };
  },
  yieldAggregate(state, bundle) {
    const module = bundle.rewards;
    if (!module) return;

    Object.keys(module).forEach(i => {
      state[i] = module[i];
    });
  },
  resetState(state) {
    state.accumulatedPoint = initialState.accumulatedPoint;
    state.floatingExpiryPoint = initialState.floatingExpiryPoint;
    state.selectedReward = JSON.parse(JSON.stringify(initialState.selectedReward));
    state.selectedMutation = JSON.parse(JSON.stringify(initialState.selectedMutation));
    state.currentTransactionPoint = JSON.parse(JSON.stringify(initialState.currentTransactionPoint));
    state.pointStatus = JSON.parse(JSON.stringify(initialState.pointStatus));
    state.exchangeSheetState = JSON.parse(JSON.stringify(initialState.exchangeSheetState));
  },
  setSheetState(state, sheetData) {
    state.exchangeSheetState[sheetData.name] = sheetData.flag;
    state.exchangeSheetState.failedType = sheetData.failedType || '';
  },
  setMyMissions(state, list) {
    state.myMissions = list;
  },
  setExpiryConfig(state, data) {
    state.expiryConfig = data;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
