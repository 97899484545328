<template lang="pug">
div
  FlashAlert.u-pos--fixed
  LoadingSpinner(v-if="isAppLoading || isSubmitting")
  .page
    .o-container.u-pad-h--4
    .c-icon.c-icon--medium.c-icon--close.u-pad-h--3.u-pad-v--4(@click="historyBack")
    .u-pad-h--4
      h2.u-txt--xlarge.u-align-center.u-mrgn-bottom--7 Konfirmasi No. Handphone

      template(v-if="page === 'phone'")
        p.u-txt--caption.u-align-center Kode otentikasi akan dikirimkan ke nomor di bawah
        PhoneNumberField.u-mrgn-top--10.u-pad-top--5(:value="getUserPhone", :disabled="true")
        FixedFooter
          button.c-btn.c-btn--large.c-btn--block.u-txt--medium-bold(slot="button", :disabled="!isValidPhone || isAppLoading", @click.prevent="confirmPhoneNumber") Konfirmasi

      template(v-else)
        OtpField(:otp-timer="otp.otpTimer", :otp-message="otp.otpMessage", :invalid-otp="otp.invalidOtp", @change="receiveOTP", @rerequest="requestOTP")

</template>

<script>
import { mapGetters } from 'vuex';
import AppHeader from '~/components/AppHeader';
import FixedFooter from '~/components/FixedFooter';
import FlashAlert from '~/components/FlashAlert';
import LoadingSpinner from '~/components/shared/LoadingSpinner';
import OtpField from '~/components/form/OtpField';
import PhoneNumberField from '~/components/form/PhoneNumberField';
import stringFilter from '~/mixins/string-filter';

export default {
  name: 'PhoneNumberConfirmation',
  components: {
    AppHeader,
    FlashAlert,
    PhoneNumberField,
    OtpField,
    FixedFooter,
    LoadingSpinner,
  },
  mixins: [stringFilter],
  middleware: 'homeauth',
  props: {
    redirectUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      page: 'phone',
      phoneNumberAlert: '',
      isValidPhone: true,
      otp: {
        otpMessage: '',
        otpCode: '',
        invalidOtp: false,
        otpTimer: 0,
        otpInterval: null,
      },
      isSubmitting: false,
    };
  },
  computed: {
    ...mapGetters([
      'isAppLoading',
      'getUserPhone',
      'getUserId',
    ]),
  },
  methods: {
    async confirmPhoneNumber() {
      this.isSubmitting = false;
      try {
        await this.requestOTP();
      } finally {
        this.isSubmitting = false;
      }
    },
    async requestOTP() {
      this.isSubmitting = true;
      try {
        await this.$store.dispatch('phoneConfirmation');
        this.showOTP();
      } finally {
        this.isSubmitting = false;
      };
    },
    showOTP() {
      this.otp.otpMessage = `Masukkan kode otentifikasi yang kami kirimkan ke ${
        this.$options.filters.formatPhone(this.getUserPhone)
      }`;
      this.page = 'otp';
      this.otp.otpTimer = 60;
      clearInterval(this.otp.otpInterval);
      this.otp.otpInterval = setInterval(() => {
        this.otp.otpTimer -= 1;
        if (this.otp.otpTimer <= 0) {
          clearInterval(this.otp.otpInterval);
        }
      }, 1000);
    },
    receiveOTP({ otpCode }) {
      this.$store.dispatch('hideFlashAlert');
      this.otp.invalidOtp = false;
      this.otp.otpCode = otpCode;
      if (this.otp.otpCode.length === 5) this.sendOTP();
    },
    sendOTP() {
      this.isSubmitting = true;
      this.$store
        .dispatch('phoneConfirmation', { options: { otpCode: this.otp.otpCode } })
        .then(() => {
          this.$store.dispatch('showFlashAlert', {
            message: 'Berhasil melakukan konfirmasi.',
            type: 'success',
          });
          this.isSubmitting = false;
          if (this.redirectUrl) return this.$router.replace(this.redirectUrl);
          return this.$router.push('/');
        })
        .catch(() => {
          this.otp.invalidOtp = true;
          this.isSubmitting = false;
        });
    },
    historyBack() {
      if (window.history.length > 1) this.$router.go(-1);
      else this.$router.push('/');
    },
  },
};
</script>

<style lang="scss" scoped>
.u-txt--medium-bold {
  font-weight: 600;
}

.u-pos--fixed {
  position: fixed;
}
</style>
