import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const TOPUP_URL = `${process.env.API_V4_HOST}/topups`;

export default Client.wrap({
  requestTopups(amount: number, paymentMethod, channel) {
    const requestPayload = {
      amount,
      payment_type: paymentMethod,
      channel,
    };
    return this.post(`${TOPUP_URL}`, 'user', new ApiOptions({ requestPayload }));
  },
  requestTopupVA(bank: string, { amount }: { amount: number }) {
    const requestPayload = {
      ...(amount && { amount }),
    };
    return this.post(`${TOPUP_URL}/virtual_account/${bank}`, 'user', new ApiOptions({ requestPayload }));
  },
  retrieveTopup(id: number) {
    return this.get(`${TOPUP_URL}/${id}`, 'user', new ApiOptions());
  },
});
