export const PAYMENT_METHOD_TYPE_UNSPECIFIED = 'BALANCE_INVOICE_PAYMENT_METHOD_TYPE_UNSPECIFIED';
export const PAYMENT_METHOD_TYPE_MUTANT = 'BALANCE_INVOICE_PAYMENT_METHOD_TYPE_MUTANT';
export const PAYMENT_METHOD_TYPE_WALLET = 'BALANCE_INVOICE_PAYMENT_METHOD_TYPE_WALLET';

export const PAYMENT_METHOD_NAME = {
  [PAYMENT_METHOD_TYPE_MUTANT]: 'Saldo DANA',
  [PAYMENT_METHOD_TYPE_WALLET]: 'Saldo Mitra',
};

export const BALANCE_STATE_ACTIVE = 'active';
export const BALANCE_STATE_INACTIVE = 'inactive';
export const BALANCE_STATE_FROZEN = 'frozen';

export const DANA_CODE_INACTIVE = 20201;
export const DANA_CODE_FROZEN = 20207;

export const BUKA20_TRANSACTION_TYPES = 'buka20-transactions';
export const BUKA20_ALLOWED_BUKABANTUAN_TRANSACTION_TYPES = ['e-money', 'e-wallet', 'prepaid-phone-topup', 'topup'];

export const BILLING_PAYMENT_TYPES_MAPPING = {
  [PAYMENT_METHOD_TYPE_MUTANT]: 'BILLING_PAYMENT_METHOD_MUTANT',
  [PAYMENT_METHOD_TYPE_WALLET]: 'BILLING_PAYMENT_METHOD_WALLET',
};

export const BILLING_PRODUCT_TYPES = {
  'prepaid-phone': 'prepaid-phone',
};

// value follow the apps config 'mitra-payment-method-config'
// ref: https://gitlab.cloud.bukalapak.io/apps/mitra_bukalapak_android/-/merge_requests/236
export const NEO_CONFIG_PAYMENT_CODE_MAPPING = {
  wallet: PAYMENT_METHOD_TYPE_WALLET,
  dana: PAYMENT_METHOD_TYPE_MUTANT,
};
