import AgenliteAPIClient from '~/api/index';
import PaymentDomainAPIClient from '~/api/payment-domain';

const initialState = {
  providerList: [],
  createTopupCheckoutDraft: null,
  validateTopupCheckoutDraft: null,
  finalizeTopupCheckoutDraft: null,
};

const getters = {
  getCreateTopupCheckoutDraft: state => state.createTopupCheckoutDraft,
  getValidateTopupCheckoutDraft: state => state.validateTopupCheckoutDraft,
  getFinalizeTopupCheckoutDraft: state => state.finalizeTopupCheckoutDraft,
  getProviderList: state => state.providerList,
  getProviderFee: state => name => {
    const res = state.providerList.find(method => method.payment_type === name);
    return res ? res.fees.reduce((total, current) => total + current.amount, 0) : 0;
  },
  getProviderLimit: state => (name, attribute) => {
    const res = state.providerList.find(method => method.payment_type === name);
    return res ? res[attribute] : 0;
  },
};

const actions = {
  async retrievePaymentLimitAndFee({ dispatch, commit }) {
    try {
      const response = await AgenliteAPIClient.retrievePaymentLimitAndFee();
      commit('setProviderList', response.data);
    } catch (error) {
      const message = 'Terjadi kesalahan. Silahan coba lagi nanti.';
      dispatch('catchError', { error, message, hideAppLoading: true });
      throw error;
    }
  },
  async createTopupCheckoutDraft({ dispatch, commit }, requestPayload) {
    try {
      const response = await PaymentDomainAPIClient.createTopupCheckoutDraft(requestPayload);
      commit('setCreateTopupCheckoutDraft', response);
    } catch (error) {
      const message = 'Gagal membuat topup checkout draft dengan message  ';
      dispatch('catchError', { error, message, noFlashAlert: false });
      throw error;
    }
  },
  async validateTopupCheckoutDraft({ dispatch, commit }, requestPayload) {
    try {
      const response = await PaymentDomainAPIClient.validateTopupCheckoutDraft(requestPayload);
      commit('setValidateTopupCheckoutDraft', response);
    } catch (error) {
      const message = 'Gagal validasi topup checkout draft dengan message ';
      dispatch('catchError', { error, message, noFlashAlert: false });
      throw error;
    }
  },
  async finalizeTopupCheckoutDraft({ dispatch, commit }, requestPayload) {
    try {
      const response = await PaymentDomainAPIClient.finalizeTopupCheckoutDraft(requestPayload);
      commit('setFinalizeTopupCheckoutDraft', response);
    } catch (error) {
      const message = 'Gagal finalisasi topup checkout draft dengan message ';
      dispatch('catchError', { error, message, noFlashAlert: false });
      throw error;
    }
  },
};

const mutations = {
  setProviderList(state, providerList) {
    state.providerList = providerList;
  },
  setCreateTopupCheckoutDraft(state, createTopupCheckoutDraft) {
    state.createTopupCheckoutDraft = createTopupCheckoutDraft;
  },
  setValidateTopupCheckoutDraft(state, validateTopupCheckoutDraft) {
    state.validateTopupCheckoutDraft = validateTopupCheckoutDraft;
  },
  setFinalizeTopupCheckoutDraft(state, finalizeTopupCheckoutDraft) {
    state.finalizeTopupCheckoutDraft = finalizeTopupCheckoutDraft;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
