import { Plugin } from '@nuxt/types';
import { onCLS, onFCP, onINP, onFID, onLCP, onTTFB, Metric } from 'web-vitals';
import consola from 'consola';

type WebVitalsSegment = 'TTFB' | 'FCP' | 'LCP' | 'CLS' | 'FID' | 'INP';
function createSample(metric: { segment: WebVitalsSegment; value: number; path: string }) {
  return {
    name: 'webvitals_score',
    kind: 'hl',
    labels: {
      domain: window.location.hostname,
      path: metric.path,
      segment: metric.segment,
    },
    value: metric.value.toString(),
  };
}

// eslint-disable-next-line @typescript-eslint/keyword-spacing
export default <Plugin>function (context) {
  // batching the data send
  const BATCH_INTERVAL_CHECK = 6000;
  const log = consola.withTag('web-vitals');
  // eslint-disable-next-line no-undef
  let timer: NodeJS.Timeout;
  const samples = new Map<string, ReturnType<typeof createSample>>();
  const sendMetrics = (metric: Metric) => {
    samples.set(
      metric.name,
      createSample({
        segment: metric.name,
        value: metric.value,
        path: context.route.matched[context.route.matched.length - 1].path,
      })
    );

    log.info('Queued web-vitals metric', metric.name);
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (samples.size === 0) return;
      const blob = new Blob([JSON.stringify({ samples: Array.from(samples.values()) })], {
        type: 'application/json',
      });
      log.withTag('send').info('Queue detected: sending web-vitals collection');
      navigator.sendBeacon('/perf', blob);
      samples.clear();
    }, BATCH_INTERVAL_CHECK);
  };

  // web-vitals hooks
  onTTFB(sendMetrics);
  onFCP(sendMetrics);
  onLCP(sendMetrics);
  onCLS(sendMetrics);
  onFID(sendMetrics);
  onINP(sendMetrics);
};
