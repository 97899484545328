import AgenliteAPIClient from '~/api';

const actions = {
  async retrieveAllowlistMeFeature({ dispatch }, { feature, noFlashAlert = false }) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.retrieveAllowlistMeFeature(feature);
      return response;
    } catch (error) {
      const message = 'Gagal Mendapatkan Subscription.';
      dispatch('catchError', { error, message, noFlashAlert });
      if (error?.errors?.[0]?.message !== 'Record not exist') {
        throw error;
      }
    } finally {
      dispatch('hideAppLoading');
    }
  },
  async getAllowlistStatus({ dispatch }, { feature, cacheName }) {
    dispatch('showAppLoading');
    let response = null;
    try {
      const featureExpiration = localStorage[cacheName];
      const isAllowlistValid = featureExpiration && new Date() <= new Date(featureExpiration);

      if (isAllowlistValid) {
        return true;
      }

      localStorage.removeItem(cacheName);

      response = await AgenliteAPIClient.retrieveAllowlistMeFeature(feature);

      if (response?.data?.value === 'allowed') {
        // in the allowlist, updated_at is used as expiration date
        localStorage[cacheName] = response?.data?.updated_at;
        return true;
      }
      return false;
    } catch (error) {
      if (error?.errors?.[0]?.message !== 'Record not exist') {
        throw error;
      }
    } finally {
      dispatch('hideAppLoading');
    }
  },
};

const mutations = {
  setActiveEvent(state, activeEvent) {
    state.activeEvent = activeEvent;
  },
  setActiveEventDetail(state, activeEventDetail) {
    state.activeEventDetail = activeEventDetail;
  },
};

export default {
  actions,
  mutations,
};
