import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const PROMO_CODE_URL = `${process.env.API_HOST}/promos/vouchers/codes`;

export default Client.wrap({
  retrievePromos(requestPayload) {
    return this.get(PROMO_CODE_URL, 'user', new ApiOptions({ requestPayload }));
  },
});
