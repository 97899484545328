import AgenliteAPIClient from '~/api';
import { humanizeTimestamp } from '~/server/helpers/date-helper';
import { BUKA20_TRANSACTION_TYPES, BUKA20_ALLOWED_BUKABANTUAN_TRANSACTION_TYPES } from '~/utils/buka20-constants';

function formatTrxList(prev, next) {
  if (
    next.trx_type === BUKA20_TRANSACTION_TYPES &&
    !BUKA20_ALLOWED_BUKABANTUAN_TRANSACTION_TYPES.includes(next.detail.transaction_type)
  ) {
    return prev;
  }

  const currentDate = new Date(next.created_at);
  if (prev.length === 0 || new Date(prev[prev.length - 1]?.['created_at']).getDate() !== currentDate.getDate()) {
    prev.push({
      divider: true,
      text: humanizeTimestamp(currentDate),
      uid: `date-${currentDate.getTime()}`,
    });
  }

  prev.push({ ...next, uid: next.detail?.id || next.detail?.transaction_id });
  return prev;
}

const CONFIG_KEY = 'mitra-guidance-config';

const initialState = {
  mitraId: null,
  categories: [],
};

const getters = {
  getHelpCategories(state) {
    return state.categories;
  },
};

const actions = {
  async fetchHelpCategories({ commit, dispatch, state }) {
    if (state.categories.length > 0) return;
    let response = null;
    try {
      let categoryId = state.mitraId;
      if (!categoryId) {
        const configResponse = await AgenliteAPIClient.fetchNeoConfigs([CONFIG_KEY]);
        categoryId = configResponse?.data?.[0]?.data?.['mitra-help-category-id'];
        if (!categoryId) throw configResponse;
      }
      response = await AgenliteAPIClient.fetchHelpCategories(categoryId);
      commit('setCategories', { mitraId: categoryId, categories: response?.data ?? [] });
    } catch (error) {
      const message = 'Gagal mendapatkan data kategori.';
      dispatch('catchError', { error, message, noFlashAlert: true });
      throw error;
    }
  },
  async fetchHelpContents({ dispatch }, { categoryId }) {
    let response = null;
    try {
      response = await AgenliteAPIClient.fetchHelpContents(categoryId);
      return response?.data ?? [];
    } catch (error) {
      const message = 'Gagal mendapatkan data konten.';
      dispatch('catchError', { error, message, noFlashAlert: true });
      throw error;
    }
  },
  async fetchHelpContent({ dispatch }, { id }) {
    let response = null;
    try {
      response = await AgenliteAPIClient.fetchHelpContent(id);
      return response?.data;
    } catch (error) {
      const message = 'Gagal mendapatkan data konten.';
      dispatch('catchError', { error, message, noFlashAlert: true });
      throw error;
    }
  },
  async fetchHelpTransactions({ dispatch }) {
    let response = null;
    try {
      const params = [
        {
          trx_type: 'invoices',
        },
        {
          trx_type: 'grocery',
        },
        {
          trx_type: 'bukaemas',
        },
        {
          trx_type: 'buka20-transactions',
          v: 2,
        },
        {
          trx_type: 'topup'
        }
      ];
      response = await Promise.all(params.map(param => AgenliteAPIClient.getMitraTransactions(param)));
      return response
        .reduce((acc, resp) => acc.concat(resp.data), [])
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        .reduce(formatTrxList, []);
    } catch (error) {
      const message = 'Gagal mendapatkan data transaksi.';
      dispatch('catchError', { error, message, noFlashAlert: true });
      throw error;
    }
  },
};

const mutations = {
  setCategories(state, { mitraId, categories }) {
    state.mitraId = mitraId;
    state.categories = categories;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
