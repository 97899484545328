import API from '~/api';

export default function ({ store, redirect }) {
  if (!store.app.$pageReferrer) return;
  const currentPageName = store.app.$pageReferrer.name;
  const salesMenu = ['sales_home', 'sales_setor_saldo'];
  const sharedMenu = [
    'sales_login',
    'sales_authorize',
    'logout',
    'profile_page',
    'reset_password',
    'guidance',
    'guidance_category',
    'guidance_content',
    'guidance_transaction',
    'guidance_trend_others',
    'syarat_mitra',
    'guide_keuntungan',
    'guide_verifikasi',
    'guide_tambah_saldo',
    'guide_beli_pulsa',
    'guide_beli_paket_data',
    'guide_token_listrik',
    'guide_bayar_listrik',
    'guide_bayar_pdam',
    'guide_bayar_bpjs',
    'guide_bayar_angsuran_kredit',
    'guide_grosir',
    'guide_kirim_uang',
    'guide_instal_app',
    'guide_grosir_delivery',
    'bukabantuan_agen',
    'bukabantuan_inbox',
    'top_up_dompet',
    'transaction_empty',
    'detail_transaction_vp',
    'lite_transactions',
    'lite_transactions_details',
    'vp_kirimsaldo',
    'vp_trx_detail',
    'digital_voucher_trx_detail',
    'prepaid_phone_topup_trx_detail',
    'prepaid_phone_transact',
    'topup_digital',
    'topup_digital_terms',
    'topup_digital_vendor_selection',
    'topup_digital_price_list',
    'topup_digital_contact_list',
    'topup_digital_transact',
    'topup_digital_save_customer',
    'topup_digital_topup_saldo',
    'kontak_pelanggan',
    'kontak_pelanggan_create',

  ];
  const isSales = (store.state.agent.currentAgent || {}).coordinator || false;
  if (API.credentials.login) {
    const driverAccessibleMenu = [...salesMenu, ...sharedMenu];
    if (!isSales && salesMenu.includes(currentPageName)) {
      redirect('/');
    }
    if (isSales && !driverAccessibleMenu.includes(currentPageName)) {
      redirect('/sales');
    }
  } else if (salesMenu.includes(currentPageName)) {
    redirect('/sales/login');
  }
}
