import { Route } from 'vue-router';
export const VP_CATALOG_TRACKER_TYPE = {
  multiple: 'MultiOperator',
  telkomsel: 'Telkomsel',
  xl: 'XL',
  indosat: 'Indosat',
  smartfren: 'Smartfren',
  tri: 'Tri',
  axis: 'Axis',
};

export const getVpCatalogScreenName = (route: Route) => {
  const catalogType = route.params.catalog_type;

  let screenName = '';
  if (route?.name?.includes('_landing')) {
    screenName = 'DataPlan_CatalogList_Screen';
  } else if (route?.name?.includes('_edit')) {
    screenName = `DataPlan_${VP_CATALOG_TRACKER_TYPE[catalogType]}_CatalogEdit_Screen`;
  } else if (route?.name?.includes('_new')) {
    screenName = `DataPlan_${VP_CATALOG_TRACKER_TYPE[catalogType]}_CatalogNew_Screen`;
  }

  return screenName;
};
