import helpers from './helpers';

export default {
  trackRegister(journeyId, source, url = '/register', action = 'submit') {
    const { name, path } = this.$route;
    this.$blTracker.actions.trackActions({
      evn: 'agenlite_register',
      source,
      action,
      current_page: url,
      referrer: name || 'register',
      referrer_url: path,
      journey_id: journeyId,
    });
  },
  trackAdjustRegister({ agentId }) {
    this.$blTracker.actions.trackActions({
      evn: 'adjust_mitra_raw_register',
      activity_kind: 'register_success',
      is_organic: 1,
      deeplink: '',
      adgroup_name: '',
      network_name: '',
      campaign_name: 'mitra_pwa',
      creative_name: '',
      impression_time: 0,
      impression_based: '',
      ui: '0',
      adid: '',
      city: '',
      idfa: '',
      ads_id: '',
      region: '',
      gps_adid: '',
      android_id: '',
      installed_at: 0,
      agent_id: `${agentId}`,
      click_time: Date.now(),
      created_at: Date.now().toString(),
      modified_at: Date.now().toString(),
    });
  },
  trackLogin(source, url = '/', action = 'submit') {
    const { name, path } = this.$route;
    this.$blTracker.actions.trackActions({
      evn: 'agenlite_login',
      source,
      action,
      current_page: url,
      referrer: name || 'authorize_page',
      referrer_url: path,
      journey_id: this.getTrackerJourneyId,
    });
  },
  trackLoginRegisterVisit(schemaType, { journeyId, route, referrer, store }) {
    const payload = {
      evn: 'agenlite_login_register_visit',
      current_screen: route.name,
      schema_type: schemaType, // login; register_mitra_only; register_new_user;
      referrer_screen: referrer.name,
      referrer_url: referrer.url,
      journey_id: journeyId,
    };
    payload.params = helpers.generateVisitParams(store);
    this.$blTracker.actions.trackActions(payload);
  },
  trackLoginRegisterClick(schemaType, action, value = undefined) {
    const payload = {
      evn: 'agenlite_login_register_clicked',
      current_screen: this.$route.name,
      schema_type: schemaType, // login; register_mitra_only; register_new_user;
      click_source: action, // button_submit, button_back, etc...
      journey_id: this.getTrackerJourneyId,
    };
    if (value) payload.click_source_status = value; // success, wrong password, etc...
    this.$blTracker.actions.trackActions(payload);
    const userFlagMap = {
      registration: 'new',
      activation: 'existing',
    };
    if (Object.keys(userFlagMap).includes(action) && value === 'success') {
      this.trackerVendor.braze?.logCustomEvent?.('user_registered', {
        user_flag: userFlagMap[action],
      });
    }
  },
};
