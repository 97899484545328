<template lang="pug">
.loader
</template>

<script>
export default {
  name: 'LoadingBar',
};
</script>

<style lang="scss" scoped>
.loader {
  height: 4px;
  width: 100%;
  position: fixed;
  overflow: hidden;
  background-color: #fff;
  z-index: 101;

  &:before {
    display: block;
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    height: 2px;
    background-image: repeating-linear-gradient(90deg, #d83949, #d83949 75px, #fff 1px, #fff 78px);
    animation: loading 0.5s linear infinite;
  }
}
@keyframes loading {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 78px 0;
  }
}
</style>
