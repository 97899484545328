import { stringify } from 'query-string';
import Client from '~/types/api/api-module';
import { PhoneTypePayload } from '~/types/api/phone-type-payload';
import ApiOptions from '../api-options';

const PHONE_CREDITS_URL = `${process.env.API_V4_HOST}/phone-credits/prepaid-transactions`;
const PHONE_CREDITS_INQUIRY_URL = `${process.env.API_V4_HOST}/phone-credits/prepaid-products`;

export default Client.wrap({
  Private: {
    // Get phone credit prepaid products
    fetchPhoneCreditProducts(query: Record<string, string | number | undefined> = {}) {
      return this.get(`${PHONE_CREDITS_INQUIRY_URL}?${stringify(query)}`, 'user', new ApiOptions({}));
    },
    /** @deprecated **/
    postPhoneCreditTransaction(payload: PhoneTypePayload) {
      const { packageId, phoneNumber } = payload;
      const requestPayload = {
        package_id: packageId,
        phone_number: phoneNumber,
      };
      return this.post(PHONE_CREDITS_URL, 'user', new ApiOptions({ requestPayload }));
    },
    getPhoneCreditTransaction(id: number) {
      return this.get(`${PHONE_CREDITS_URL}/${id}`, 'user', new ApiOptions());
    },
  },
});
