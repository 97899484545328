<template lang="pug">
transition(name="slide-right")
  .c-full-modal(v-if="getTfaOtpState.isOtpModalOpened")
    .c-full-modal-header
      .c-full-modal-header__action
        button(name="close" @click="closeModal")
          bl-icon-av(name="ico_back_android")
      bl-text-av.c-full-modal-title(variation="body-16" weight="semi-bold") Verifikasi akun
    FlashAlert

    .c-full-modal-content
      template(v-if="getTfaOtpState.otpTimer > 0 || getTfaOtpState.isOtpTimeout || getTfaOtpState.isInvalidOtp")
        InputOTP(
          :phoneNumber="phoneNumberInput"
          @autofillSuccess="$emit('autofillSuccess', $event)"
          @autofillFailed="$emit('autofillFailed', $event)"
          @retryRequestOtp="onRetryRequestOtp"
          @submitOtp="onSubmitOtp"
        )
      template(v-else)
        PreOTP(:phoneNumber="phoneNumberInput" @request="requestOTP")
</template>

<script>
import { mapGetters } from 'vuex';

import { BlIconAv } from '@bukalapak/bazaar-visual';
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';
import InputOTP from '~/components/otp/InputOtp';
import FlashAlert from '~/components/FlashAlert';
import PreOTP from '~/components/otp/PreOtp';

import urlHelper from '~/mixins/url-helper';

export default {
  name: 'OtpFullModal',
  components: {
    BlIconAv,
    BlTextAv,
    FlashAlert,
    InputOTP,
    PreOTP,
  },
  mixins: [urlHelper],
  props: {
    scope: {
      type: String, // 'public' | 'user' | 'old-user'
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(['getTfaOtpState', 'getUserPhone']),
    phoneNumberInput() {
      return this.getTfaOtpState.phone || this.getUserPhone || '';
    },
    isPublicScope() {
      return this.scope === 'public';
    },
    isUserScope() {
      return this.scope === 'user';
    },
  },
  mounted() {
    this.$store.dispatch('clearOtpFlow');
  },
  beforeDestroy() {
    document.body.style.overflow = 'auto';
  },
  methods: {
    closeModal() {
      this.$store.dispatch('clearOtpFlow');
      this.$store.dispatch('hideAppLoading');
      this.$store.dispatch('hideFlashAlert');
      this.$emit('close');
    },
    async requestOTP(method) {
      if (!this.isPublicScope && !this.isUserScope) {
        await this.$store.dispatch('activateTFA', { method, otpCode: null });
      } else this.$emit('requestOtp', method);
    },
    onRetryRequestOtp() {
      if (this.isPublicScope) this.$store.dispatch('resendAuthDevicePublic');
      else if (this.isUserScope) this.$store.dispatch('resendAuthDeviceUser');
      else this.$store.dispatch('rerequestOtpCode');
    },
    async onSubmitOtp(otpCode) {
      this.$emit('beforeSubmitOtp');
      let response = null;
      try {
        if (this.isPublicScope) response = await this.$store.dispatch('callOtpGuardedPublicApi', { otpCode });
        else if (this.isUserScope) response = await this.$store.dispatch('callOtpGuardedUserApi', { otpCode });
        else response = await this.$store.dispatch('activateTFA', { otpCode });
        this.$emit('successSubmitOtp');
      } catch (error) {
        this.$emit('errorSubmitOtp');
      } finally {
        this.finishOtp(response);
      }
    },
    finishOtp(response) {
      this.$emit('afterSubmitOtp', response);
      if (this.isPublicScope && ((response || {}).meta || {}).http_status !== 422) return;
      this.$emit('finishOtp');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

.c-full-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $system-white;
  z-index: $z-index-modal;
  height: 100%;
  display: flex;
  flex-direction: column;

  &-header {
    border-bottom: 1px solid $border-medium;
    display: flex;
    flex-direction: row;
    align-items: center;
    &__action {
      margin-left: 4px;
      button {
        display: table-cell;
        -webkit-tap-highlight-color: transparent;
        vertical-align: middle;
        color: #333;
        display: inline-block;
        height: 48px;
        width: 48px;
        background: none;
        border: 0;
        padding: 0;
        outline: 0;
        -webkit-appearance: none;
        text-align: center;
      }
    }
  }

  &-title {
    line-height: 56px;
    margin-bottom: 0;
  }
}
</style>
