import Client from '~/types/api/api-module';
import ApiOptions from '../../api-options';

const BASE_URL = `${process.env.API_V4_HOST}/_exclusive/mitra-wallet`;

export default Client.wrap({
  // https://blueprint.bukalapak.io/exclusive/t/mitra-wallet~mitra~wallet~get~exclusive~mitra-wallet~whitelist
  getWallet20WhitelistStatus(productType: string) {
    const query = `product_type=${productType}`;
    return this.get(`${BASE_URL}/whitelist?${query}`, 'user', new ApiOptions());
  },
});
