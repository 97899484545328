import r from '~/utils/render-utils';

// const demoPath = '';
const DemoIndex = () => import(/* webpackChunkName: 'demo' */ `~/_doc/demo/IndexDemo`);
const DemoProgressBar = () => import(/* webpackChunkName: 'demo' */ `~/_doc/demo/ProgressBarDemo`);
const DemoScrolledOff = () => import(/* webpackChunkName: 'demo' */ `~/_doc/demo/ScrolledOffDemo`);
const OtpModalDemo = () => import(/* webpackChunkName: 'demo' */ `~/_doc/demo/OtpModalDemo`);

const demos = [
  {
    path: '/_demo/',
    component: r(DemoIndex),
    name: 'index_demo',
    children: [
      {
        path: 'progress-bar',
        component: r(DemoProgressBar),
        name: 'progress_bar_demo',
      },
      {
        path: 'scroll-off',
        component: r(DemoScrolledOff),
        name: 'scrolled_off_demo',
      },
      {
        path: 'otp',
        component: r(OtpModalDemo),
        name: 'otp_demo',
      },
    ],
  },
];

export default demos;
