import Client from '~/types/api/api-module';
import ApiOptions from '../../api-options';

const INFO_PAYMENT_URL = `${process.env.API_V4_HOST}/_exclusive/info/payments`;

export default Client.wrap({
  retrievePaymentLimitAndFee() {
    return this.get(`${INFO_PAYMENT_URL}`, 'user', new ApiOptions());
  },
});
