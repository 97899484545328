import r from '~/utils/render-utils';

const MitraBranchIndex = () => import(/* webpackChunkName: 'mitra-branch'  */ '~/pages/MitraBranch/index.vue');
const MitraBranchOnboarding = () => import(/* webpackChunkName: 'mitra-branch'  */ '~/pages/MitraBranch/onboarding.vue');
const MitraBranchStaff = () => import(/* webpackChunkName: 'mitra-branch'  */ '~/pages/MitraBranch/staff.vue');
const MitraBranchOwner = () => import(/* webpackChunkName: 'mitra-branch' */ '~/pages/MitraBranch/owner.vue');
const MitraBranchOwnerActive = () => import(/* webpackChunkName: 'mitra-branch' */ '~/pages/MitraBranch/Owner/active.vue');
const MitraBranchOwnerPending = () => import(/* webpackChunkName: 'mitra-branch' */ '~/pages/MitraBranch/Owner/pending.vue');
const MitraBranchFaq = () => import(/* webpackChunkName: 'mitra-branch' */ '~/pages/MitraBranch/faq.vue');
const MitraBranchOwnerFaq = () => import(/* webpackChunkName: 'mitra-branch' */ '~/pages/MitraBranch/Faq/owner.vue');
const MitraBranchStaffFaq = () => import(/* webpackChunkName: 'mitra-branch' */ '~/pages/MitraBranch/Faq/staff.vue');
const MitraBranchStaffSendBalance = () => import(/* webpackChunkName: 'mitra-branch' */ '~/pages/MitraBranch/Staff/send-balance.vue');
const MitraBranchStaffSendBalanceDetail = () => import(/* webpackChunkName: 'mitra-branch' */ '~/pages/MitraBranch/Staff/send-balance-detail.vue');
const MitraBranchStaffAdd = () => import(/* webpackChunkName: 'mitra-branch' */ '~/pages/MitraBranch/Staff/add.vue');
const MitraBranchStaffAddConfirmation = () => import(/* webpackChunkName: 'mitra-branch' */ '~/pages/MitraBranch/Staff/add-confirmation.vue');
const MitraBranchStaffTransactions = () => import(/* webpackChunkName: 'mitra-branch' */ '~/pages/MitraBranch/Staff/transactions.vue');
const MitraBranchStaffDetail = () => import(/* webpackChunkName: 'mitra-branch' */ '~/pages/MitraBranch/Staff/detail.vue');
const MitraBranchStaffUpdateBranch = () => import(/* webpackChunkName: 'mitra-branch' */ '~/pages/MitraBranch/Staff/update-branch.vue');
const MitraBranchStaffRequest = () => import(/* webpackChunkName: 'mitra-branch' */ '~/pages/MitraBranch/Staff/request.vue');
const MitraBranchStaffRequestConfirmation = () => import(/* webpackChunkName: 'mitra-branch' */ '~/pages/MitraBranch/Staff/request-confirmation.vue');

const subscription = [
  {
    path: '/mitra-branch',
    component: r(MitraBranchIndex),
    name: 'mitra-branch-index',
    children: [
      {
        path: '/mitra-branch/staff',
        component: r(MitraBranchStaff),
        name: 'mitra-branch-staff',
      },
      {
        path: '/mitra-branch/owner',
        component: r(MitraBranchOwner),
        name: 'mitra-branch-owner',
        children: [
          {
            path: '/mitra-branch/owner/active',
            component: r(MitraBranchOwnerActive),
            name: 'mitra-branch-active',
          },
          {
            path: '/mitra-branch/owner/pending',
            component: r(MitraBranchOwnerPending),
            name: 'mitra-branch-pending',
          },
        ],
      },
    ],
  },
  {
    path: '/mitra-branch/onboarding',
    component: r(MitraBranchOnboarding),
    name: 'mitra-branch-onboarding',
  },
  {
    path: '/mitra-branch/faq',
    component: r(MitraBranchFaq),
    name: 'mitra-branch-faq',
    children: [
      {
        path: '/mitra-branch/faq/owner',
        component: r(MitraBranchOwnerFaq),
        name: 'mitra-branch-faq-owner',
      },
      {
        path: '/mitra-branch/faq/staff',
        component: r(MitraBranchStaffFaq),
        name: 'mitra-branch-faq-staff',
      },
    ],
  },
  {
    path: '/mitra-branch/staff/add',
    component: r(MitraBranchStaffAdd),
    name: 'mitra-branch-staff-add',
  },
  {
    path: '/mitra-branch/staff/add-confirmation',
    component: r(MitraBranchStaffAddConfirmation),
    name: 'mitra-branch-staff-add-confirmation',
  },
  {
    path: '/mitra-branch/staff/send-balance',
    component: r(MitraBranchStaffSendBalance),
    name: 'mitra-branch-staff-send-balance',
  },
  {
    path: '/mitra-branch/staff/send-balance/:id',
    component: r(MitraBranchStaffSendBalanceDetail),
    name: 'mitra-branch-staff-send-balance-detail',
  },
  {
    path: '/mitra-branch/staff/transactions',
    component: r(MitraBranchStaffTransactions),
    name: 'mitra-branch-staff-transactions',
  },
  {
    path: '/mitra-branch/staff/detail/:id',
    component: r(MitraBranchStaffDetail),
    name: 'mitra-branch-staff-detail',
  },
  {
    path: '/mitra-branch/staff/update-branch/:id',
    component: r(MitraBranchStaffUpdateBranch),
    name: 'mitra-branch-staff-update-branch',
  },
  {
    path: '/mitra-branch/staff/request/:id',
    component: r(MitraBranchStaffRequest),
    name: 'mitra-branch-staff-request',
  },
  {
    path: '/mitra-branch/staff/request-confirmation',
    component: r(MitraBranchStaffRequestConfirmation),
    name: 'mitra-branch-staff-request-confirmation',
  },
];

export default subscription;
