
import { RouteConfig } from '@nuxt/types/config/router';
import r from '~/utils/render-utils';

const DipromosiinVindes = () => import(/* webpackChunkName: 'campaign-dipromosiin-vindes' */ '~/pages/DipromosiinVindes.vue');

export const CampaignRoutes: RouteConfig[] = [
  {
    path: '/dipromosiin-vindes',
    component: r(DipromosiinVindes),
    name: 'dipromosiin_vindes',
  }
];

export default CampaignRoutes;
