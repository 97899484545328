import credentialStorageInstance from '~/api/credential-storage-instance';
import Client from '~/types/api/api-module';
import ApiOptions from '../../api-options';
import RequestBuilder from '../../../request_builder';

const USER_URL = `${process.env.API_V4_HOST}/_exclusive/users`;

export default Client.wrap({
  // API interface
  Public: {
    async updateResetUserPassword(requestPayload, { method = 'sms', otpCode = null } = {}) {
      const otpKey = this.getOtp(requestPayload.token)?.key;
      const browserFingerprint = window.localStorage.getItem('browser_fp');
      const requestHeaders = {
        'Bukalapak-OTP-Device-ID': credentialStorageInstance.identity,
        'Bukalapak-OTP-Method': method || 'sms',
      };
      if (otpCode) {
        requestHeaders['Bukalapak-OTP'] = otpCode;
      } else if (otpKey) {
        requestHeaders['Bukalapak-OTP-Key'] = otpKey;
      }
      if (browserFingerprint) {
        requestHeaders['Bukalapak-Device-Fingerprint'] = browserFingerprint;
      }
      try {
        const response = await this.v4.exclusiveUser.updateResetUserPasswordEndpoint(requestPayload, requestHeaders);
        if (response.meta.http_status !== 200) throw response;
        if (response.meta.otp_key) {
          this.setOtpKey(requestPayload.token, response.meta.otp_key);
        }
        return response;
      } catch (error) {
        if (error.meta.otp_key) this.setOtpKey(requestPayload.token, error.meta.otp_key);
        throw error;
      }
    },
    async updateUserPhoneNumber(userId, requestPayload, options) {
      try {
        const response = await this.v4.exclusiveUser.updatePhoneNumber(
          userId,
          requestPayload,
          RequestBuilder.getOtpHeader(this.getOtp(userId).key, options)
        );
        if (response.meta.otp_key) this.setOtpKey(userId, response.meta.otp_key);
        return response;
      } catch (error) {
        if (error?.meta?.['otp_key']) this.setOtpKey(userId, error.meta.otp_key);
        throw error;
      }
    },
  },
  Private: {
    fetchUserAvailability(usernameOrPhone: string) {
      const userMatch = new Map<'phone' | 'username', string>();
      const regexPhone = /^(0|\+?62)?([2-9]\d{5,12})$/;
      if (regexPhone.test(usernameOrPhone)) {
        userMatch.set('phone', usernameOrPhone);
      } else if (usernameOrPhone.length > 3) {
        userMatch.set('username', usernameOrPhone);
      }

      const requestPayload = Object.fromEntries(userMatch);
      return this.get(`${USER_URL}/availability`, 'public', new ApiOptions({ requestPayload }));
    },
    updateResetUserPasswordEndpoint(requestPayload, requestHeaders) {
      return this.patch(`${USER_URL}/passwords`, 'public', new ApiOptions({ requestHeaders, requestPayload }));
    },
    fetchUserRegistrationAvailability(requestPayload) {
      return this.get(`${USER_URL}/registration-availability`, 'public', new ApiOptions({ requestPayload }));
    },
    updatePhoneNumber(userId, requestPayload, requestHeaders) {
      return this.put(
        `${USER_URL}/${userId}/phone-number`,
        'public',
        new ApiOptions({ requestHeaders, requestPayload })
      );
    },
  },
});
