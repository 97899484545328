<template lang="pug">
div
</template>

<script>
import AgenliteLocalForage from '~/plugins/localforage';

export default {
  name: 'OAuthLogin',
  middleware: 'login',
  data() {
    return {
      baseUrl: process.env.WEB_HOST,
      oauthHost: process.env.OAUTH_HOST,
      clientId: process.env.AGENLITE_API_CLIENT_ID,
    };
  },
  mounted() {
    // Cleanup
    AgenliteLocalForage.removeItem('kycData');
    this.$blTracker.actions.clearAttributeCache();
    this.$store.dispatch('resetState');
    // Redirect to OAUTH URL
    const uriMatcherList = [
      { route: 'sales_login', path: '/sales' },
    ];
    const selectedRedirect = uriMatcherList.find(uri => this.$route.name === uri.route)?.path ?? '';
    const redirectUri = `${this.baseUrl}${selectedRedirect}/authorize`;
    const oauthAuthorizeParams = new URLSearchParams({
      client_id: this.clientId,
      scope: 'public user store',
      response_type: 'code',
      redirect_uri: redirectUri,
    }).toString();
    const loginParams = new URLSearchParams({
      comeback: `${this.oauthHost}/oauth/authorize?${oauthAuthorizeParams}`,
      ...(this.$route?.query?.username ? { username: this.$route?.query?.username } : {}),
    }).toString();
    this.$location.redirect(`${this.oauthHost}/login?${loginParams}`);
  },
};
</script>

<style lang="scss" scoped>
</style>
