import Client from '~/types/api/api-module';
import timelimit from '~/constants/vp/timelimit';
import ApiOptions from '../api-options';
import RequestBuilder from '../../request_builder';

const BPJS_URL = `${process.env.API_V4_HOST}/bpjs-kesehatan`;

export default Client.wrap({
  Public: {
    async payBpjsTransaction(userId, { transaction, customerPhone, method, voucherCode }) {
      const invoiceResponse = await this.registerInvoice(transaction.id, transaction.type, method, voucherCode);
      if (invoiceResponse.meta.http_status !== 201) throw invoiceResponse;
      if (customerPhone !== '') {
        this.createAgentTransactionDetail(invoiceResponse.data.payment_id, transaction.customer_number, customerPhone);
      }
      const paymentResponse = await this.makePayment(userId, invoiceResponse.data.payment_id, method);
      if (paymentResponse.meta.http_status !== 200) throw paymentResponse;
      return {
        paymentResponse,
        invoiceResponse,
      };
    },
  },
  Private: {
    postBpjsInquiry(customerNumber, year, month) {
      const requestPayload = RequestBuilder.getBpjsPayload(customerNumber, year, month);
      return this.post(`${BPJS_URL}/inquiries`, 'public', new ApiOptions({ requestPayload }));
    },
    getBpjsTransaction(remoteId) {
      return this.get(`${BPJS_URL}/transactions/${remoteId}`, 'user', new ApiOptions());
    },
    postBpjsTransaction(customerNumber, year, month) {
      const requestPayload = RequestBuilder.getBpjsPayload(customerNumber, year, month);
      const requestLimit = { time: timelimit.deposit, additionalKey: requestPayload.customer_number };
      return this.post(`${BPJS_URL}/transactions`, 'user', new ApiOptions({ requestPayload, requestLimit }));
    },
  },
});
