const trustedDeviceMixin = {
  methods: {
    async authentify(func, options = {}) {
      await this.$store.dispatch('authenticateDevice', {
        callbackFunc: func,
        ...options,
      });
    },
    async userAuthentify(options) {
      await this.$store.dispatch('authDeviceUser', {
        ...options,
      });
    },
    async publicAuthentify(options) {
      await this.$store.dispatch('authDevicePublic', {
        ...options,
      });
    },
  },
};

export default trustedDeviceMixin;
