import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const PRODUCT_URL = `${process.env.API_HOST}/products`;

export default Client.wrap({
  retrieveProducts(requestPayload) {
    return this.get(PRODUCT_URL, 'public', new ApiOptions({ requestPayload }));
  },
});
