import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import AgenliteAPIClient from '~/api/index';

const catalogProductForm = {
  name: '',
  price: '',
  image_url: '',
};

const promotionMediaCatalog = {
  color: 0,
  business_name: '',
  owner_name: undefined,
  phone: '',
  business_description: '',
  products: [],
};

const promotionMediaBanner = {
  business_name: '',
  phone: '',
  products: [],
};

const promotionMediaBusinessCard = {
  color: 0,
  design: 0,
  business_name: '',
  owner_name: undefined,
  phone: '',
  business_address: '',
  business_description: '',
  social_media_type: '',
  social_media_account: '',
};

const initialState = {
  posters: [],
  postersMeta: null,
  isLoadingPromotionMedia: false,
  isLoadingPromotionMediaPoster: false,
  promotionMediaCatalog: { ...promotionMediaCatalog, catalog_type: 'normal' },
  promotionMediaCatalogWithoutImage: { ...promotionMediaCatalog, catalog_type: 'without_image' },
  catalogProductForm,
  promotionMediaBanner,
  promotionMediaBusinessCard,
  promotionMediaBannerConfig: undefined,
};

const getters = {
  getPosters: state => state.posters,
  isLoadingPromotionMedia: state => state.isLoadingPromotionMedia,
  isLoadingPromotionMediaPoster: state => state.isLoadingPromotionMediaPoster,
  isAllPromotionMediaInactive: (_state, _getters, _rootState, rootGetters) =>
    !rootGetters.toggleConfig?.promotionMediaPoster &&
    !rootGetters.toggleConfig?.promotionMediaCatalog &&
    !rootGetters.toggleConfig?.promotionMediaBusinessCard &&
    !rootGetters.toggleConfig?.promotionMediaBanner,
  getPromotionMediaCatalog: state => state.promotionMediaCatalog,
  getPromotionMediaCatalogProducts: state => state.promotionMediaCatalog?.products || [],
  getPromotionMediaCatalogWithoutImage: state => state.promotionMediaCatalogWithoutImage,
  getPromotionMediaCatalogWithoutImageProducts: state => state.promotionMediaCatalogWithoutImage?.products || [],
  getCatalogProductForm: state => state.catalogProductForm,
  getPromotionMediaBanner: state => state.promotionMediaBanner,
  getPromotionMediaBusinessCard: state => state.promotionMediaBusinessCard,
  getPromotionMediaBannerConfig: (state, _getters, _rootState, rootGetters) => {
    const { promotionMediaBannerConfig } = state;
    const hasBukumitraPrefix = rootGetters.isSaasApp ? 'Bukumitra' : '';
    if (!promotionMediaBannerConfig) return promotionMediaBannerConfig;
    return {
      minProductCount: promotionMediaBannerConfig[`minProductCount${hasBukumitraPrefix}`],
      maxProductCount: promotionMediaBannerConfig[`maxProductCount${hasBukumitraPrefix}`],
      categories: promotionMediaBannerConfig[`categories${hasBukumitraPrefix}`],
    };
  },
  getPromotionMediaBannerProductById: (_state, getters) => id => {
    /* istanbul ignore next */
    if (getters.getPromotionMediaBannerConfig?.categories) {
      for (const key of Object.keys(getters.getPromotionMediaBannerConfig.categories)) {
        const product = getters.getPromotionMediaBannerConfig.categories?.[key]?.products.find(p => p.id === id);
        /* istanbul ignore else */
        if (product) return product;
      }
    }
    return undefined;
  },
};

const actions = {
  async retrieveAllPromotionMediaPosters({ commit, state, rootGetters }, requestPayload = {}) {
    if (!rootGetters.toggleConfig?.promotionMediaPoster) return;
    try {
      if (!state.postersMeta) {
        commit('setIsLoadingPromotionMedia', true);
      }
      commit('setIsLoadingPromotionMediaPoster', true);
      const { data, meta } = await AgenliteAPIClient.retrieveAllPromotionMediaPosters({
        ...requestPayload,
        platform: rootGetters.isSaasApp ? 'saas_app' : 'mitra_app',
        limit: 50,
      });

      const isLatestCategory = meta.offset === 0 && isEmpty(requestPayload);

      if (isLatestCategory) {
        commit('removeNewestPromotionMediaPoster');
      }

      data.forEach(p => {
        if (isLatestCategory) {
          commit('addNewestPromotionMediaPoster', p.id);
        }
        p.image_url = p.image_url.replace('%2Foriginal%2F', '%2Fsmall%2F');
      });
      commit('setPosters', data);
      commit('setPostersMeta', meta);
    } catch (_err) {
      commit('setPosters', []);
    } finally {
      commit('setIsLoadingPromotionMedia', false);
      commit('setIsLoadingPromotionMediaPoster', false);
    }
  },
  async retrieveMorePromotionMediaPosters({ commit, state, rootGetters }, requestPayload = {}) {
    /* istanbul ignore else */
    if (rootGetters.toggleConfig?.promotionMediaPoster && state.posters.length > 0 && state.posters.length < state.postersMeta?.total) {
      const { data } = await AgenliteAPIClient.retrieveAllPromotionMediaPosters({
        ...requestPayload,
        platform: rootGetters.isSaasApp ? 'saas_app' : 'mitra_app',
        offset: state.posters.length,
        limit: 50,
      });
      commit('setPosters', [
        ...state.posters,
        ...data
      ]);
    }
  },
  async createPromotionMediaCatalog({ dispatch, commit, rootGetters }, type) {
    const catalogMutation = `setPromotionMediaCatalog${type === 'catalog' ? '' : 'WithoutImage'}`;
    const catalogPayload = await dispatch('getCatalogPayload', type);
    const payload = {
      ...catalogPayload,
      platform: rootGetters.isSaasApp ? 'saas_app' : 'mitra_app',
    };

    const { data } = await AgenliteAPIClient.createPromotionMediaCatalog(payload);
    commit(catalogMutation, data);
  },
  async updatePromotionMediaCatalog({ dispatch, commit, rootGetters }, type) {
    const catalogMutation = `setPromotionMediaCatalog${type === 'catalog' ? '' : 'WithoutImage'}`;
    const catalogPayload = await dispatch('getCatalogPayload', type);
    const payload = {
      ...catalogPayload,
      platform: rootGetters.isSaasApp ? 'saas_app' : 'mitra_app',
    };

    const { data } = await AgenliteAPIClient.updatePromotionMediaCatalog(payload.id, payload);
    commit(catalogMutation, data);
  },
  async uploadFileToBucket({ state, commit }, requestPayload) {
    const { data } = await AgenliteAPIClient.uploadFileToBucket(requestPayload);
    commit('setPromotionMediaCatalogProduct', { ...state.catalogProductForm, ...data });
  },
  async retrievePromotionMediaCatalog({ commit, rootGetters }) {
    if (!rootGetters.toggleConfig?.promotionMediaCatalog) return;
    try {
      const { data } = await AgenliteAPIClient.retrievePromotionMediaCatalogs();
      const catalog = data.find(c => c.catalog_type === 'normal');
      const catalogWithoutImage = data.find(c => c.catalog_type === 'without_image');
      commit('setPromotionMediaCatalog', catalog);
      commit('setPromotionMediaCatalogWithoutImage', catalogWithoutImage);
    } catch (_err) {
      commit('setPromotionMediaCatalog');
      commit('setPromotionMediaCatalogWithoutImage');
    }
  },
  async retrieveBannerConfig({ commit }) {
    const NEO_BANNER_CONFIG_KEY = 'o2o-saas/promotion_media_banner_config';

    const { data } = await AgenliteAPIClient.fetchNeoConfigs([NEO_BANNER_CONFIG_KEY]);
    commit('setPromotionMediaBannerConfig', data[0].data);
  },
  async createPromotionMediaBanner({ getters, dispatch, rootGetters }) {
    const payload = {
      ...getters.getPromotionMediaBanner,
      platform: rootGetters.isSaasApp ? 'saas_app' : 'mitra_app',
    };

    if (!payload.phone) delete payload.phone;
    const { data } = await AgenliteAPIClient.createPromotionMediaBanner(payload);
    dispatch('setVerifiedPromotionMediaBanner', data);
  },
  async updatePromotionMediaBanner({ getters, dispatch, rootGetters }) {
    const payload = {
      ...getters.getPromotionMediaBanner,
      platform: rootGetters.isSaasApp ? 'saas_app' : 'mitra_app'
    };

    if (!payload.phone) delete payload.phone;
    const { data } = await AgenliteAPIClient.updatePromotionMediaBanners(payload.id, payload);
    dispatch('setVerifiedPromotionMediaBanner', data);
  },
  async retrievePromotionMediaBanner({ commit, dispatch, rootGetters }) {
    if (!rootGetters.toggleConfig?.promotionMediaBanner) return;
    try {
      commit('setPromotionMediaBanner');
      const { data } = await AgenliteAPIClient.retrievePromotionMediaBanners();
      dispatch('setVerifiedPromotionMediaBanner', data[0]);
    } catch (_err) {
      commit('setPromotionMediaBanner');
    }
  },
  async createPromotionMediaBusinessCard({ getters, commit, rootGetters }) {
    const payload = {
      ...getters.getPromotionMediaBusinessCard,
      platform: rootGetters.isSaasApp ? 'saas_app' : 'mitra_app',
    };

    if (!payload.phone) delete payload.phone;
    if (!payload.social_media_type) {
      delete payload.social_media_type;
      delete payload.social_media_account;
    }
    const { data } = await AgenliteAPIClient.createPromotionMediaBusinessCard(payload);
    commit('setPromotionMediaBusinessCard', data);
  },
  async retrievePromotionMediaBusinessCard({ commit, rootGetters }) {
    if (!rootGetters.toggleConfig?.promotionMediaBusinessCard) return;
    try {
      commit('setPromotionMediaBusinessCard');
      const { data } = await AgenliteAPIClient.retrievePromotionMediaBusinessCard();
      commit('setPromotionMediaBusinessCard', data[0]);
    } catch (_err) {
      commit('setPromotionMediaBusinessCard');
    }
  },
  async updatePromotionMediaBusinessCard({ getters, commit, rootGetters }) {
    const payload = {
      ...getters.getPromotionMediaBusinessCard,
      platform: rootGetters.isSaasApp ? 'saas_app' : 'mitra_app',
    };

    if (!payload.phone) delete payload.phone;
    if (!payload.social_media_type) {
      delete payload.social_media_type;
      delete payload.social_media_account;
    }
    const { data } = await AgenliteAPIClient.updatePromotionMediaBusinessCard(payload.id, payload);
    commit('setPromotionMediaBusinessCard', data);
  },
  setVerifiedPromotionMediaBanner({ state, commit, getters }, banner) {
    let verifiedBanner = banner;
    if (state.promotionMediaBannerConfig) {
      const { maxProductCount } = getters.getPromotionMediaBannerConfig || {};
      const maxProductLength = maxProductCount || banner.products.length;
      const listedProductIds = Object.keys(getters.getPromotionMediaBannerConfig.categories).map(key => {
        return getters.getPromotionMediaBannerConfig.categories[key].products.map(p => p.id);
      }).flat();
      const verifiedProducts = banner.products.filter(p => listedProductIds.includes(p)).slice(0, maxProductLength);
      verifiedBanner = { ...banner, products: verifiedProducts };
    }
    commit('setPromotionMediaBanner', verifiedBanner);
  },
  getCatalogPayload({ state }, type) {
    const catalog = state[`promotionMediaCatalog${type !== 'catalog' ? 'WithoutImage' : ''}`];

    return {
      ...pick(catalog, [
        'id',
        'color',
        'business_name',
        'owner_name',
        'phone',
        'business_description',
        'catalog_type'
      ]),
      products: catalog?.products.map(product => pick(product, [
        'id',
        'name',
        'price',
        'image_url',
      ])),
    };
  },
};

const mutations = {
  setPosters(state, posters) {
    state.posters = posters;
  },
  setPostersMeta(state, meta) {
    state.postersMeta = meta;
  },
  setIsLoadingPromotionMedia(state, isLoading) {
    state.isLoadingPromotionMedia = isLoading;
  },
  setIsLoadingPromotionMediaPoster(state, isLoading) {
    state.isLoadingPromotionMediaPoster = isLoading;
  },
  setPromotionMediaCatalog(state, form = {
    ...promotionMediaCatalog,
    catalog_type: 'normal',
    products: []
  }) {
    state.promotionMediaCatalog = form;
  },
  setPromotionMediaCatalogWithoutImage(state, form = {
    ...promotionMediaCatalog,
    catalog_type: 'without_image',
    products: []
  }) {
    state.promotionMediaCatalogWithoutImage = form;
  },
  setPromotionMediaCatalogProduct(state, product = catalogProductForm) {
    state.catalogProductForm = product;
  },
  appendPromotionMediaCatalogProduct(state) {
    const { promotionMediaCatalog, catalogProductForm } = state;
    promotionMediaCatalog.products.push(catalogProductForm);
  },
  appendPromotionMediaCatalogWithoutImageProduct(state) {
    const { promotionMediaCatalogWithoutImage, catalogProductForm } = state;
    promotionMediaCatalogWithoutImage.products.push(catalogProductForm);
  },
  updatePromotionMediaCatalogProduct(state, idx) {
    const { promotionMediaCatalog, catalogProductForm } = state;
    promotionMediaCatalog.products[idx] = catalogProductForm;
  },
  updatePromotionMediaCatalogWithoutImageProduct(state, idx) {
    const { promotionMediaCatalogWithoutImage, catalogProductForm } = state;
    promotionMediaCatalogWithoutImage.products[idx] = catalogProductForm;
  },
  removePromotionMediaCatalogProduct(state, idx) {
    state.promotionMediaCatalog.products.splice(idx, 1);
  },
  removePromotionMediaCatalogWithoutImageProduct(state, idx) {
    state.promotionMediaCatalogWithoutImage.products.splice(idx, 1);
  },
  setPromotionMediaCatalogProducts(state, products = []) {
    state.promotionMediaCatalog.products = products;
  },
  setPromotionMediaBanner(state, banner = promotionMediaBanner) {
    state.promotionMediaBanner = banner;
  },
  setPromotionMediaBannerConfig(state, config) {
    state.promotionMediaBannerConfig = config;
  },
  setPromotionMediaBusinessCard(state, card = promotionMediaBusinessCard) {
    state.promotionMediaBusinessCard = card;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
