import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_workbox_c90338bc from 'nuxt_plugin_workbox_c90338bc' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_4912e8b5 from 'nuxt_plugin_metaplugin_4912e8b5' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_iconplugin_4dba8e29 from 'nuxt_plugin_iconplugin_4dba8e29' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_axios_49784ff4 from 'nuxt_plugin_axios_49784ff4' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_router_4183b469 from 'nuxt_plugin_router_4183b469' // Source: ./router.js (mode: 'all')
import nuxt_plugin_validate_c33f42e8 from 'nuxt_plugin_validate_c33f42e8' // Source: ../plugins/validate (mode: 'all')
import nuxt_plugin_vuexpersist_13f465a2 from 'nuxt_plugin_vuexpersist_13f465a2' // Source: ../plugins/vuex-persist (mode: 'client')
import nuxt_plugin_remodal_27e30824 from 'nuxt_plugin_remodal_27e30824' // Source: ../plugins/remodal (mode: 'client')
import nuxt_plugin_timing_641c7b2e from 'nuxt_plugin_timing_641c7b2e' // Source: ../plugins/analytics/timing (mode: 'client')
import nuxt_plugin_vuegtm_33b85a18 from 'nuxt_plugin_vuegtm_33b85a18' // Source: ../plugins/vue-gtm.js (mode: 'client')
import nuxt_plugin_nuxtclientinit_3893cbb9 from 'nuxt_plugin_nuxtclientinit_3893cbb9' // Source: ../plugins/nuxt-client-init.js (mode: 'client')
import nuxt_plugin_a2hs_6a7d5132 from 'nuxt_plugin_a2hs_6a7d5132' // Source: ../plugins/a2hs (mode: 'client')
import nuxt_plugin_serviceworker_91e072e8 from 'nuxt_plugin_serviceworker_91e072e8' // Source: ../plugins/service-worker (mode: 'client')
import nuxt_plugin_location_3bf17e28 from 'nuxt_plugin_location_3bf17e28' // Source: ../plugins/location.js (mode: 'client')
import nuxt_plugin_nativebridge_7d062f0e from 'nuxt_plugin_nativebridge_7d062f0e' // Source: ../plugins/native-bridge.js (mode: 'client')
import nuxt_plugin_credentialstorage_b35b9e0a from 'nuxt_plugin_credentialstorage_b35b9e0a' // Source: ../plugins/credential-storage (mode: 'all')
import nuxt_plugin_accesstokenmanager_04314fa6 from 'nuxt_plugin_accesstokenmanager_04314fa6' // Source: ../plugins/access-token-manager (mode: 'client')
import nuxt_plugin_splitter_ff7c4786 from 'nuxt_plugin_splitter_ff7c4786' // Source: ../plugins/splitter (mode: 'client')
import nuxt_plugin_tracker_b19fe9fc from 'nuxt_plugin_tracker_b19fe9fc' // Source: ../plugins/tracker (mode: 'client')
import nuxt_plugin_authinit_77c14942 from 'nuxt_plugin_authinit_77c14942' // Source: ../plugins/auth-init (mode: 'client')
import nuxt_plugin_externalvisittracker_f1063dac from 'nuxt_plugin_externalvisittracker_f1063dac' // Source: ../plugins/external-visit-tracker (mode: 'client')
import nuxt_plugin_csrmiddlewarerunner_93a111ec from 'nuxt_plugin_csrmiddlewarerunner_93a111ec' // Source: ../plugins/csr-middleware-runner (mode: 'all')
import nuxt_plugin_vuetouchevents_04448bc4 from 'nuxt_plugin_vuetouchevents_04448bc4' // Source: ../plugins/vue-touch-events (mode: 'client')
import nuxt_plugin_rumwebvitals_6c941699 from 'nuxt_plugin_rumwebvitals_6c941699' // Source: ../plugins/rum-web-vitals (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"next-transition","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Mitra Bukalapak: Tingkatkan Keuntungan Warung \u002F Usahamu","meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"property":"twitter:image","content":"\u002Fimages\u002Fog_image.jpg"},{"property":"twitter:title","content":"Mitra Bukalapak: Tingkatkan Keuntungan Warung \u002F Usahamu"},{"hid":"twitter:description","property":"twitter:description","content":"Jadilah Mitra Bukalapak dan tingkatkan pendapatan warung \u002F usahamu.\n    Penuhi kebutuhan usaha dengan beli produk grosir online dan maksimalkan keuntungan dengan jualan beragam produk berkualitas hanya di Bukalapak\n    ✓ Komisi Besar\n    ✓ Mudah dan Tanpa Modal\n    ✓ Harga Bersaing"},{"name":"msapplication-TileColor","content":"#d71149"},{"name":"apple-mobile-web-app-title","content":"Bukalapak"},{"name":"google","content":"notranslate"},{"name":"msapplication-config","content":"browserconfig.xml"},{"name":"msapplication-tooltip","content":"Mitra Bukalapak"},{"property":"http-equip","http-equiv":"origin-trial","content":"AlPS3WCxApPihQraHVMVydjKCvogcHV+6fPwu1LP0QxyAm\u002FswHqKyAceSdT23pf2Eqa1spfKS6ZTESNYNdQcxwAAAABreyJvcmlnaW4iOiJodHRwczovL21pdHJhLmJ1a2FsYXBhay5jb206NDQzIiwiZmVhdHVyZSI6IlNtc1JlY2VpdmVyIiwiZXhwaXJ5IjoxNTc2NjQyMjA1LCJpc1N1YmRvbWFpbiI6dHJ1ZX0="}],"link":[{"rel":"preconnect","href":"https:\u002F\u002Fs0.bukalapak.com"},{"rel":"preconnect","href":"https:\u002F\u002Fassets.bukalapak.com"},{"rel":"dns-prefetch","href":"https:\u002F\u002Fconnect.facebook.net"},{"rel":"dns-prefetch","href":"https:\u002F\u002Fwww.googletagmanager.com"}],"__dangerouslyDisableSanitizersByTagID":{"google_analytics":["innerHTML"]},"style":[],"script":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.client && typeof nuxt_plugin_workbox_c90338bc === 'function') {
    await nuxt_plugin_workbox_c90338bc(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_4912e8b5 === 'function') {
    await nuxt_plugin_metaplugin_4912e8b5(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_4dba8e29 === 'function') {
    await nuxt_plugin_iconplugin_4dba8e29(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_49784ff4 === 'function') {
    await nuxt_plugin_axios_49784ff4(app.context, inject)
  }

  if (typeof nuxt_plugin_router_4183b469 === 'function') {
    await nuxt_plugin_router_4183b469(app.context, inject)
  }

  if (typeof nuxt_plugin_validate_c33f42e8 === 'function') {
    await nuxt_plugin_validate_c33f42e8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuexpersist_13f465a2 === 'function') {
    await nuxt_plugin_vuexpersist_13f465a2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_remodal_27e30824 === 'function') {
    await nuxt_plugin_remodal_27e30824(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_timing_641c7b2e === 'function') {
    await nuxt_plugin_timing_641c7b2e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuegtm_33b85a18 === 'function') {
    await nuxt_plugin_vuegtm_33b85a18(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nuxtclientinit_3893cbb9 === 'function') {
    await nuxt_plugin_nuxtclientinit_3893cbb9(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_a2hs_6a7d5132 === 'function') {
    await nuxt_plugin_a2hs_6a7d5132(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_serviceworker_91e072e8 === 'function') {
    await nuxt_plugin_serviceworker_91e072e8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_location_3bf17e28 === 'function') {
    await nuxt_plugin_location_3bf17e28(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nativebridge_7d062f0e === 'function') {
    await nuxt_plugin_nativebridge_7d062f0e(app.context, inject)
  }

  if (typeof nuxt_plugin_credentialstorage_b35b9e0a === 'function') {
    await nuxt_plugin_credentialstorage_b35b9e0a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_accesstokenmanager_04314fa6 === 'function') {
    await nuxt_plugin_accesstokenmanager_04314fa6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_splitter_ff7c4786 === 'function') {
    await nuxt_plugin_splitter_ff7c4786(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_tracker_b19fe9fc === 'function') {
    await nuxt_plugin_tracker_b19fe9fc(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_authinit_77c14942 === 'function') {
    await nuxt_plugin_authinit_77c14942(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_externalvisittracker_f1063dac === 'function') {
    await nuxt_plugin_externalvisittracker_f1063dac(app.context, inject)
  }

  if (typeof nuxt_plugin_csrmiddlewarerunner_93a111ec === 'function') {
    await nuxt_plugin_csrmiddlewarerunner_93a111ec(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuetouchevents_04448bc4 === 'function') {
    await nuxt_plugin_vuetouchevents_04448bc4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_rumwebvitals_6c941699 === 'function') {
    await nuxt_plugin_rumwebvitals_6c941699(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    const { route } = router.resolve(app.context.route.fullPath)
    // Ignore 404s rather than blindly replacing URL
    if (!route.matched.length && process.client) {
      return resolve()
    }
    router.replace(route, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
