<template lang="pug">
div
  .c-field(:class="{ 'c-field--error': !isValidOperator || errors.has(name) }")
    .c-field__label-row.u-mrgn-bottom--2(v-if="useLabel")
      bl-text-av(:for="name" :class="{ error: errors.has(name) }") {{ label }}
    .u-position-relative
      .o-flag
        .o-flag__body
          form(@submit.prevent="$emit('submit')")
            input(
              class="text-field"
              :class="textInputClass"
              autocomplete="off"
              ref="phoneNumberInput"
              type="tel"
              v-model="phoneNumberInput"
              v-validate="{ required: true, valid_phone: true }"
              :data-vv-as="label"
              :data-vv-name="name"
              :data-vv-scope="scope"
              :disabled="disabled"
              :id="inputId"
              :name="name"
              :placeholder="placeholder"
              :readonly="readonly"
              @blur="$emit('blur')"
              @click="$emit('onClick')"
              @keyup.enter.prevent="$emit('enter')"
            )
          span.input-logo(v-if="isPhoneNumberChecking" :class="{ 'preloader-with-agent-customer': withAgentCustomers }")
            BLBPicture(src="/images/preloader_small.gif" :imgStyle="{ width: '20px' }")
          img.operator-logo(v-else-if="operatorLogoURL" :src="operatorLogoURL")
          span(v-else :class="withAgentCustomers ? 'vp-logo-with-agent-customers' : 'vp-logo'")
            span.c-logo-external-operator.c-logo-external-operator--small(
              :class="`c-logo-external-operator--op-${operatorLogo.toLowerCase()}`"
            )
        .o-flag__head.u-pad--0.u-pad-left--2(v-if="withAgentCustomers")
          router-link.c-dualtone-icon.c-dualtone-icon--kontak-pelanggan.c-dualtone-icon--medium.phone-number-link(
            replace
            :to="{ name: 'agent_customers_select', params: { sourceType: `${sourceType}`, from: 'entryPoint' } }"
            @click.native="trackVPScreenClick({ clickSource: 'contact_icon' })"
          )
    .c-field__error(v-if="!isValidOperator && value.length > 0 && !similarCustomerPhoneExist") No. handphone tidak valid
    .c-field__error(v-if="errors.has(name)") {{ errors.first(name) }}
    .c-field__hint(v-if="hint.length > 0 && !errors.has(name)") {{ hint }}
</template>

<script>
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';
import BLBPicture from '~/components/shared/base/BLBPicture';
import track from '~/mixins/tracker';
import numberMixin from '~/mixins/number-filter';

const regexPhone = /^(?:0|\+?62)?([2-9]\d{7,12})$/;
const regexPhoneWithSeparator = /^(?:0|\+?62)?([2-9]\d{2})(-)(\d{4})(-)(\d{1,5})$/;

export default {
  name: 'PhoneNumberField',
  components: {
    BlTextAv,
    BLBPicture,
  },
  mixins: [track, numberMixin],
  props: {
    name: {
      type: String,
      default: 'phone',
    },
    value: {
      type: String,
      default: '',
    },
    operatorLogo: {
      type: String,
      default: '',
    },
    operatorLogoURL: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    withAgentCustomers: {
      type: Boolean,
      default: false,
    },
    useLabel: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: 'No. Handphone',
    },
    focusOnInit: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: '',
    },
    sourceType: {
      type: String,
      default: '',
    },
    inputId: {
      type: String,
      default: 'phone_number',
    },
    required: {
      type: Boolean,
      default: false,
    },
    scope: {
      type: String,
      default: '',
    },
    validateOperator: {
      type: Boolean,
      default: false,
    },
    validationDelay: {
      type: Number,
      default: 1000,
    },
    isPhoneNumberChecking: {
      type: Boolean,
      default: false,
    },
    serif: {
      type: Boolean,
      default: true,
    },
    withSeparator: {
      type: Boolean,
      default: false,
    },
    similarCustomerPhoneExist: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      phoneNumberInput: '',
      ctrlActive: false,
      delayTimeout: null,
    };
  },
  computed: {
    isValidOperator() {
      const isValidOperator = (this.validateOperator && !!this.operatorLogo.length) || !this.validateOperator;
      return isValidOperator && this.value.length >= 9 && this.$validator.errors.items.length <= 0;
    },
    textInputClass() {
      return {
        error: !this.isValidOperator && this.value.length > 0 && !this.similarCustomerPhoneExist,
        'text-field-serif': this.serif,
      };
    },
  },
  watch: {
    phoneNumberInput(newPhoneNumberInput, oldPhoneNumberInput) {
      this.$emit('onStartValidate');

      // prevent NaN values
      const containsNaN = this.detectNaN(newPhoneNumberInput);
      if (containsNaN) this.phoneNumberInput = newPhoneNumberInput.replace(/[^0-9 \-+,.]/g, '');

      const sanitized = newPhoneNumberInput.replace(/\+62|^62/, '0').replace(/[^0-9]/g, '');

      // add separator
      if (this.withSeparator) {
        const numberWithSeparator = this.separatePhoneNumber(sanitized);
        this.phoneNumberInput = numberWithSeparator;
      }

      if (sanitized.length > 13) {
        this.phoneNumberInput = oldPhoneNumberInput;
        this.$emit('onFinishValidate');
      } else {
        this.errors.remove(this.name);
        clearTimeout(this.delayTimeout);
        this.delayTimeout = setTimeout(() => {
          this.$emit('input', sanitized);
          if (!this.required && sanitized === '') {
            this.$emit('onValidate', false);
            this.errors.remove(this.name);
          } else {
            this.$emit('onValidate', this.$validator.errors.items.length <= 0);
          }
          this.$emit('onFinishValidate');
        }, this.validationDelay);
      }
    },
    value(val) {
      if (val && this.phoneNumberInput !== val) this.phoneNumberInput = val;
    },
  },
  created() {
    this.$validator.extend('valid_phone', {
      getMessage: 'Maaf, nomornya salah. Coba cek lagi ya.',
      validate: value => regexPhone.test(value) || regexPhoneWithSeparator.test(value),
    });
  },
  async mounted() {
    await this.$nextTick();
    if (this.value) {
      this.phoneNumberInput = this.value;
    }
    if (this.focusOnInit) this.$refs.phoneNumberInput.focus();
  },
  methods: {
    detectNaN(newPhoneNumberInput) {
      const allowedPattern = /^[0-9 \-+,.]*$/;
      return !allowedPattern.test(newPhoneNumberInput);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

.vp-logo {
  position: absolute;
  background-repeat: no-repeat;
  right: 10px;
  top: 11px;
}
.vp-logo-with-agent-customers {
  position: absolute;
  background-repeat: no-repeat;
  right: 45px;
  top: 11px;
}
.phone-number-link {
  right: 0px;
  top: 10px;
}
.o-flag {
  &__body:only-child {
    display: table-row;
  }
}
.preloader-with-agent-customer {
  right: 40px;
}
.text-field {
  border: $border-size-thin solid $gray-30;
  border-radius: $border-radius-base;
  padding: 12px;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $gray-80;

  &.text-field-serif {
    font-family: Roboto;
  }

  &:focus {
    outline: $navy-30 auto 5px;
    outline-color: $navy-30;
    outline-style: auto;
    outline-width: 5px;
    outline-offset: -2px;
  }

  &.error {
    outline: $red-30 auto 5px;
    outline-color: $red-30;
    outline-style: auto;
    outline-width: 5px;
    outline-offset: -2px;
  }
}

.operator-logo {
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translateY(-50%);
  width: 16px;
  height: 22px;
  object-fit: contain;
}
</style>
