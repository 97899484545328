const PREFIX = 'agenlite_';

export default {
  // Old Tracker, still used in components/LoyaltyPoint/DetailReward
  trackLoyalty(action, referrer, screenTarget = '', { journeyId, username }) {
    this.$blTracker.actions.trackActions({
      evn: `${PREFIX}loyalty`,
      referrer,
      username,
      screen_target: screenTarget,
      action,
      journey_id: journeyId,
    });
  },
  /**
   *
   * status : status transaksinya (success, etc)
   * atau status avaibility dia buat tukar poin
   * (unavailable_redeem,
   * available_redeem,
   * available_redeem_discount,
   * not_enough_point)
   */
  trackPointVisit({ screen = {}, status = 'default', useAvailabilityStatus = false, referrer, payload } = '') {
    const { $route } = this;
    const mutationId = this.$store?.state.selectedMutation?.id ?? 0;
    const selectedReward = this.$store?.state.selectedReward ?? 0;
    // eslint-disable-next-line camelcase
    const promoPoint = this.$store?.state.selectedReward?.promo_point ?? 0;
    const pointRewardId = this.$store?.state.selectedReward?.id ?? this.$route.params?.id ?? 0;

    const rewardPoint = selectedReward.point_score - selectedReward.promo_point;
    const isPointSufficient = Number(this.$store?.state.accumulatedPoint ?? 0) - rewardPoint > 0;
    const availabilityStatus = isPointSufficient ? 'available' : 'unavailable';

    const trackerPayload = {
      evn: `${PREFIX}point_visit`,
      journey_id: this.$store?.getters.getTrackerJourneyId,
      current_screen: screen?.current || $route.name,
      referrer_screen: screen?.from || this.routeReferrer?.name || '',
      referrer_url: referrer?.url || this.routeReferrer?.url || '',
      mutation_id: Number(mutationId || 0),
      reward_id: Number(pointRewardId || 0),
      reward_promo_id: Number(promoPoint || 0),
      status: useAvailabilityStatus ? availabilityStatus : status,
      ...payload,
    };
    this.$blTracker.actions.trackActions(trackerPayload);
  },
  trackPointClick(
    {
      screen = '',
      clickSource,
      clickStatus,
      status = 'default',
      useAvailabilityStatus = false,
      orderDisplay,
      referrer,
      payload,
    } = ''
  ) {
    const { $route } = this;
    const mutationId = this.$store?.state.selectedMutation?.id ?? 0;
    const selectedReward = this.$store?.state.selectedReward ?? 0;
    // eslint-disable-next-line camelcase
    const promoPoint = this.$store?.state.selectedReward?.promo_point ?? 0;
    const pointRewardId = this.$store?.state.selectedReward?.id ?? this.$route.params?.id ?? 0;

    const rewardPoint = selectedReward.point_score - selectedReward.promo_point;
    const isPointSufficient = Number(this.$store?.state.accumulatedPoint ?? 0) - rewardPoint > 0;
    const availabilityStatus = isPointSufficient ? 'available' : 'unavailable';
    const trackerPayload = {
      evn: `${PREFIX}point_click`,
      journey_id: this.$store?.getters.getTrackerJourneyId,
      current_screen: screen?.current || $route.name,
      referrer_screen: screen?.from || this.routeReferrer?.name || '',
      referrer_url: referrer?.url || this.routeReferrer?.url || '',
      mutation_id: Number(mutationId || 0),
      reward_id: Number(pointRewardId || 0),
      reward_promo_id: Number(promoPoint || 0),
      status: useAvailabilityStatus ? availabilityStatus : status,
      click_source: clickSource,
      click_source_status: clickStatus || 'default',
      order_display: String(orderDisplay || '0'),
      ...payload,
    };
    this.$blTracker.actions.trackActions(trackerPayload);
  },
  trackPointRewardImpression({ screen = '', orderDisplay, referrer = '', payload } = '') {
    const { $route } = this;
    const trackerPayload = {
      evn: `${PREFIX}point_reward_impression`,
      journey_id: this.$store?.getters.getTrackerJourneyId,
      current_screen: screen?.current || $route.name,
      referrer_screen: screen?.from || this.routeReferrer?.name || '',
      referrer_url: referrer?.url || this.routeReferrer?.url || '',
      order_display: String(orderDisplay || '0'),
      ...payload,
    };
    this.$blTracker.actions.trackActions(trackerPayload);
  },
};
