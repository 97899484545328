const PREFIX_PROFIT_REPORTS = 'agenlite_vp_pembukuan_mitra';

export default {
  summariesParamsBuilder(summaries) {
    return {
      pembukuan_period: summaries?.period || '',
      total_profit: Number(summaries?.totalProfit) || 0,
      omzet: Number(summaries?.totalIncome) || 0,
      total_transaction: Number(summaries?.transactionCount) || 0,
      increase_amount: Number(summaries?.diffProfit) || 0,
      phone_credit_profit: Number(summaries?.productsProfit[0].totalProfit) || 0,
      data_plan_profit: Number(summaries?.productsProfit[2].totalProfit) || 0,
      electricity_prepaid_profit: Number(summaries?.productsProfit[1].totalProfit) || 0,
      journey_id: this.getTrackerJourneyId,
    };
  },
  trackProfitReportsVisit({ route, store, referrer }) {
    const summaries = store.state?.profitReports?.summariesInfo ?? null;
    const payload = {
      evn: `${PREFIX_PROFIT_REPORTS}_visit`,
      current_screen: route.name,
      referrer_screen: referrer.name,
      referrer_url: referrer.url,
      ...this.summariesParamsBuilder(summaries),
      journey_id: store.state?.trackerJourney?.id,
    };
    this.$blTracker.actions.trackActions(payload);
  },
  trackProfitReportsClick({ clickSource, clickSourceStatus }) {
    const summaries = this.$store?.state?.profitReports?.summariesInfo ?? null;
    const payload = {
      evn: `${PREFIX_PROFIT_REPORTS}_click`,
      current_screen: this.$route.name,
      referrer_screen: this.$route.name,
      referrer_url: this.$route.path,
      click_source: clickSource,
      ...this.summariesParamsBuilder(summaries),
    };
    if (clickSourceStatus) payload.click_source_status = clickSourceStatus;
    this.$blTracker.actions.trackActions(payload);
  },
};
