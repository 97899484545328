import { PublicCreateCustomerEWalletTransactionResponse } from '@bukalapak/openapi-schema/vptrader/transaction/v1/public/transaction-domain';
import AgenliteAPIClient from '~/api';
import VPNonTraderAPIClient from '~/api/vpnontrader';
import {
  PAYMENT_METHOD_TYPE_MUTANT,
  PAYMENT_METHOD_TYPE_WALLET,
  PAYMENT_METHOD_NAME,
  BALANCE_STATE_ACTIVE,
  BALANCE_STATE_INACTIVE,
  BALANCE_STATE_FROZEN,
  DANA_CODE_INACTIVE,
  DANA_CODE_FROZEN,
} from '~/utils/buka20-constants';

type InitialState = {
  currentUser: {};
  currentBalance: [];

  announcement: {};
  banner: {};
  vendorAnnouncement: {};
};

const initialState: InitialState = {
  currentUser: {},
  currentBalance: [],

  announcement: {},
  banner: {},
  vendorAnnouncement: {},
};

const getters = {
  getCurrentBalance(state) {
    return state.currentBalance;
  },
  getTopupDigitalAnnouncement(state) {
    return state.announcement;
  },
  getTopupDigitalBanner(state) {
    return state.banner;
  },
  getVendorAnnouncementList(state) {
    return state.vendorAnnouncement;
  },
};

const actions = {
  async fetchCurrentBukaUser({ dispatch, commit }) {
    let response: any;
    try {
      const response = await AgenliteAPIClient.retrieveCurrentUser();
      const { data: currentUser } = response;

      commit('setCurrentBukaUser', currentUser);
    } catch (error) {
      dispatch('catchError', { error });
    }
    return response;
  },
  async fetchMitraBalance({ dispatch, commit, state }) {
    let response: any;
    try {
      const response = await AgenliteAPIClient.retrieveCurrentAgent();
      const { data: mitraBalance } = response;
      const balanceName = PAYMENT_METHOD_NAME[PAYMENT_METHOD_TYPE_WALLET];

      const currentBalance = [
        ...state.currentBalance.filter(balance => balance.name !== balanceName),
        {
          name: balanceName,
          altName: 'Saldo Mitra',
          type: PAYMENT_METHOD_TYPE_WALLET,
          state: BALANCE_STATE_ACTIVE,
          balance: mitraBalance.dompet.saldo,
        },
      ];
      commit('retrieveCurrentAgent', response.data);
      commit('setCurrentBalance', currentBalance);
    } catch (error) {
      dispatch('catchError', { error });
    }
    return response;
  },
  async fetchDanaBalance({ dispatch, commit, state }) {
    let response: any;
    const balanceName = PAYMENT_METHOD_NAME[PAYMENT_METHOD_TYPE_MUTANT];
    try {
      const response = await AgenliteAPIClient.retrieveDanaProfile();
      const { data: danaBalance } = response;

      const currentBalance = [
        ...state.currentBalance.filter(balance => balance.name !== balanceName),
        {
          name: balanceName,
          altName: 'DANA',
          type: PAYMENT_METHOD_TYPE_MUTANT,
          state: BALANCE_STATE_ACTIVE,
          balance: danaBalance?.balance,
        },
      ];

      commit('setCurrentBalance', currentBalance);
    } catch (error) {
      const errorCode = error?.errors?.[0]?.code;
      if (errorCode === DANA_CODE_INACTIVE) {
        commit('setCurrentBalance', [
          ...state.currentBalance.filter(balance => balance.name !== balanceName),
          {
            name: balanceName,
            altName: 'DANA',
            type: PAYMENT_METHOD_TYPE_MUTANT,
            state: BALANCE_STATE_INACTIVE,
            balance: 0,
          },
        ]);
      } else if (errorCode === DANA_CODE_FROZEN) {
        commit('setCurrentBalance', [
          ...state.currentBalance.filter(balance => balance.name !== balanceName),
          {
            name: balanceName,
            altName: 'DANA',
            type: PAYMENT_METHOD_TYPE_MUTANT,
            state: BALANCE_STATE_FROZEN,
            balance: 0,
          },
        ]);

        dispatch('catchError', { error });
      }

      console.error(error);
    }
    return response;
  },
  async fetchTopupDigitalAnnouncement({ dispatch, commit }) {
    let response: any;
    try {
      response = await AgenliteAPIClient.fetchVirtualProductStatusInfoType();
      const announcement = response.data.find(
        announcement => announcement.type === 'digital_voucher' && announcement.status
      );
      if (announcement) commit('setTopupDigitalAnnouncement', announcement);

      const vendorAnnouncement = {};
      response.data.forEach(announcement => {
        if (!announcement.status) return;
        const vendor = (announcement.type || '').match(/digital_voucher_(.*)/)?.[1];
        if (vendor) {
          vendorAnnouncement[vendor] = {
            text: announcement.info,
            url: announcement.link,
          };
        }
      });

      if (vendorAnnouncement) commit('setVendorAnnouncement', vendorAnnouncement);

      const banner = response.data.find(
        banner => banner.type === 'digital_voucher_banner' && banner.status
      );
      if (banner) commit('setTopupDigitalBanner', banner);
    } catch (error) {
      dispatch('catchError', { error });
    }
    return response;
  },
  async submitEWalletTransaction({ dispatch }, payload) {
    let response: PublicCreateCustomerEWalletTransactionResponse = {};
    try {
      response = await VPNonTraderAPIClient.createEWalletTransaction(payload);
    } catch (error) {
      const customError = [
        {
          message: 'Customer tidak dapat ditemukan',
        },
      ];

      dispatch('catchError', {
        error:
          error?.errors?.[0]?.code === 'TRANSACTION_CUSTOMER-E-WALLET_INQUIRY-INVALID'
            ? { errors: customError }
            : error,
      });
    }
    return response;
  },
  async createReferredTransaction({ dispatch }, payload) {
    let response: any = {};
    try {
      response = await AgenliteAPIClient.createReferredTransaction(payload.salesCode, payload.invoiceGroupNumber);
    } catch (error) {
      dispatch('catchError', { error, noFlashAlert: true });
    }
    return response;
  },
};

const mutations = {
  setCurrentBalance(state, currentBalance) {
    state.currentBalance = currentBalance;
  },
  setTopupDigitalAnnouncement(state, announcement) {
    state.announcement = announcement;
  },
  setTopupDigitalBanner(state, banner) {
    state.banner = banner;
  },
  setVendorAnnouncement(state, vendorAnnouncement) {
    state.vendorAnnouncement = vendorAnnouncement;
  },
  resetState(state) {
    state.currentUser = JSON.parse(JSON.stringify(initialState.currentUser));
    state.currentBalance = JSON.parse(JSON.stringify(initialState.currentBalance));
    state.announcement = JSON.parse(JSON.stringify(initialState.announcement));
    state.banner = JSON.parse(JSON.stringify(initialState.banner));
    state.vendorAnnouncement = JSON.parse(JSON.stringify(initialState.vendorAnnouncement));
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
