import ApiOptions from '../api-options';

const GENERAL_TRADE_URL = `${process.env.API_V4_HOST}/general-trade`;
const ME_URL = `${GENERAL_TRADE_URL}/me`;

export default {
  getMe() {
    return this.get(`${ME_URL}?api_version=2`, 'user', new ApiOptions());
  },
};
