const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['bookkeeping'] = require('../middleware/bookkeeping.js')
middleware['bookkeeping'] = middleware['bookkeeping'].default || middleware['bookkeeping']

middleware['homeauth'] = require('../middleware/homeauth.js')
middleware['homeauth'] = middleware['homeauth'].default || middleware['homeauth']

middleware['identity'] = require('../middleware/identity.ts')
middleware['identity'] = middleware['identity'].default || middleware['identity']

middleware['login'] = require('../middleware/login.js')
middleware['login'] = middleware['login'].default || middleware['login']

middleware['mitra-activation'] = require('../middleware/mitra-activation.js')
middleware['mitra-activation'] = middleware['mitra-activation'].default || middleware['mitra-activation']

middleware['mitra-branch'] = require('../middleware/mitra-branch.js')
middleware['mitra-branch'] = middleware['mitra-branch'].default || middleware['mitra-branch']

middleware['mitra-status-check'] = require('../middleware/mitra-status-check.js')
middleware['mitra-status-check'] = middleware['mitra-status-check'].default || middleware['mitra-status-check']

middleware['postpaid-toggle'] = require('../middleware/postpaid-toggle.js')
middleware['postpaid-toggle'] = middleware['postpaid-toggle'].default || middleware['postpaid-toggle']

middleware['promotion-media'] = require('../middleware/promotion-media.ts')
middleware['promotion-media'] = middleware['promotion-media'].default || middleware['promotion-media']

middleware['referrer'] = require('../middleware/referrer.js')
middleware['referrer'] = middleware['referrer'].default || middleware['referrer']

middleware['sales'] = require('../middleware/sales.js')
middleware['sales'] = middleware['sales'].default || middleware['sales']

middleware['super-warung'] = require('../middleware/super-warung.js')
middleware['super-warung'] = middleware['super-warung'].default || middleware['super-warung']

middleware['topup-kurir'] = require('../middleware/topup-kurir.js')
middleware['topup-kurir'] = middleware['topup-kurir'].default || middleware['topup-kurir']

middleware['tracking'] = require('../middleware/tracking.js')
middleware['tracking'] = middleware['tracking'].default || middleware['tracking']

middleware['user-agent'] = require('../middleware/user-agent.ts')
middleware['user-agent'] = middleware['user-agent'].default || middleware['user-agent']

export default middleware
