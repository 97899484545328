import r from '~/utils/render-utils';

const LoyaltyPointIndex = () => import(/* webpackChunkName: 'loyalty-point-page' */ '~/pages/LoyaltyPoint/index');
const LoyaltyPointInfo = () => import(/* webpackChunkName: 'loyalty-point-page' */ '~/pages/LoyaltyPoint/info');
const LoyaltyRewardPage = () => import(/* webpackChunkName: 'loyalty-point-page' */ '~/pages/LoyaltyPoint/LoyaltyReward');
const LoyaltyMutationDetailPage = () => import(/* webpackChunkName: 'loyalty-point-page' */ '~/pages/LoyaltyPoint/LoyaltyMutationDetail');
const LoyaltyRegister = () => import(/* webpackChunkName: 'loyalty-point-register' */ '~/pages/LoyaltyPoint/LoyaltyRegister');
const LoyaltyHistory = () => import(/* webpackChunkName: 'loyalty-point-history' */ '~/pages/LoyaltyPoint/LoyaltyHistory');

const loyalty = [
  {
    path: '/loyalty-point',
    name: 'loyalty_point_landing_webview_redirector',
    redirect: '/reward-point',
  },
  {
    path: '/loyalty-point/info',
    component: r(LoyaltyPointInfo),
    name: 'loyalty_point_info',
  },
  {
    path: '/reward-point',
    component: r(LoyaltyPointIndex),
    name: 'loyalty_point_landing_page',
  },
  {
    path: '/reward-point/rewards/:id',
    component: r(LoyaltyRewardPage),
    name: 'tukar_point',
  },
  {
    path: '/reward-point/mutations/:id',
    component: r(LoyaltyMutationDetailPage),
    name: 'detail_point_mutation',
  },
  {
    path: '/reward-point/redemptions/:id',
    component: r(LoyaltyMutationDetailPage),
    name: 'detail_point_redemption',
  },
  {
    path: '/loyalty-point/register',
    component: r(LoyaltyRegister),
    name: 'loyalty_point_register',
  },
  {
    path: '/reward-point/history',
    component: r(LoyaltyHistory),
    name: 'loyalty_point_history',
  },
];

export default loyalty;
