import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const BASE_URL = `${process.env.API_V4_HOST}/saas/product-items`;


export default Client.wrap({
  retrieveProductItems(requestPayload) {
    return this.get(`${BASE_URL}`, 'user', new ApiOptions({ requestPayload }));
  },
  retrieveProductItem(id: number) {
    return this.get(`${BASE_URL}/${id}`, 'user');
  },
  createProductItem(requestPayload) {
    return this.post(`${BASE_URL}`, 'user', new ApiOptions({ requestPayload }));
  },
  updateProductItem(id, requestPayload) {
    return this.patch(`${BASE_URL}/${id}`, 'user', new ApiOptions({ requestPayload }));
  },
  deleteProductItem(id) {
    return this.delete(`${BASE_URL}/${id}`, 'user');
  },
});
