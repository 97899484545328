import AgenliteAPIClient from '~/api';
const initialState = {
  selectedProduct: {},
  selectedProducts: [],
  activeFlashSale: {},
  commissionData: {
    period: new Date().toString(),
    commission_amount: 0,
    transaction_amount: 0,
    target: 0,
    potential_bonus: 0,
    current_bonus: 0,
    previous_bonus: 0,
    applies_multiply: false,
  },
};

const mutations = {
  setSelectedProducts(state, payload) {
    state.selectedProducts = payload;
  },
  setSelectedProduct(state, payload) {
    state.selectedProduct = payload;
  },
  setActiveFlashSale(state, payload) {
    state.activeFlashSale = payload;
  },
  setCommissionData(state, payload) {
    state.commissionData = { ...state.commissionData, ...payload };
  }
};

const actions = {
  retrieveUserCommissions(_) {
    return AgenliteAPIClient.retrieveGBCommission();
  },
  retrieveCommissionHistory(_, data) {
    return AgenliteAPIClient.retrieveCommissionHistory(data);
  },
  async retrieveFlashSaleList({ dispatch }, status = 'active') {
    try {
      const response = await AgenliteAPIClient.retrieveFlashSaleList(status);
      return response;
    }
    catch (e) {
      const error = 'Gagal mendapatkan flash sale';
      dispatch('catchError', {
        error,
        message: error,
        hideAppLoading: true
      }, { root: true });
      return { data: [] };
    }
  },
  retrieveFlashSaleProducts(_, data) {
    return AgenliteAPIClient.retrieveFlashSaleProducts(data);
  },
  retrieveProductsRecommendation(_, param) {
    return AgenliteAPIClient.retrieveProductsRecommendation(param);
  },
  async retrieveMFB(_, param) {
    try {
      const response = await AgenliteAPIClient.retrieveMFB(param);
      return response;
    }
    catch (e) {
      dispatch('catchError', {
        error,
        message,
        hideAppLoading: true,
      });
      return { data: [] };
    }
  },
  retrieveCatalogProducts(_, params) {
    return AgenliteAPIClient.retrieveCatalogProducts(params);
  },
  generateProductPoster(_, data) {
    return AgenliteAPIClient.generateProductPoster(data);
  },
  generateProductsPoster(_, data) {
    return AgenliteAPIClient.generateProductsPoster(data);
  },
  setSelectedProduct({ commit }, data) {
    commit('setSelectedProduct', data);
  },
  setSelectedProducts({ commit }, data) {
    commit('setSelectedProducts', data);
  },
  retrieveSavedCatalogProducts() {
    return AgenliteAPIClient.retrieveSavedCatalogProducts();
  },
  generateCatalogPoster(_, data) {
    return AgenliteAPIClient.generateCatalogPoster(data);
  },
  replaceCatalogProducts(_, data) {
    return AgenliteAPIClient.replaceCatalogProducts(data);
  }
};

export default {
  state: () => initialState,
  actions,
  mutations,
  namespaced: true,
};
