import Cookies from 'js-cookie';
import API from '~/api';
import { promLatency, promCounter } from '~/server/helpers/metrics-helper';

const initialState = {
  authenticated: false,
  legacyAPISupport: true,
  guestLogin: false,
  token: {
    access_token: '',
    refresh_token: '',
    token_type: 'Bearer',
    created_at: -1,
    expires_in: -1,
    userId: -1,
    scope: 'public',
  },
};

const getters = {
  isSaasApp: (state, _, rootState) => {
    const isBlMitraApp = rootState.global.userAgent.toLowerCase().includes('blmitraandroid');
    const serviceOriginCalamus = state?.token?.service_origin === 'calamus';
    const referrerBukuMitra = (process.server ? rootState.global.referrer : document.referrer).includes('bukumitra');
    return (serviceOriginCalamus || referrerBukuMitra) && !isBlMitraApp;
  },
  bukumitraVersion: state => {
    return state?.token?.bukumitra_version;
  },
};

const actions = {
  authenticate({ commit, dispatch }, { username, password, alternative = false }) {
    const startTime = new Date().getTime();
    dispatch('showAppLoading');
    return new Promise((resolve, reject) => {
      API.authenticate(username, password, alternative)
        .then(response => {
          commit('setLoggedIn', true);
          // Give it a notice that this user logged in via old way
          Cookies.set('oldlogin', true);
          dispatch('hideAppLoading');
          dispatch('refetchSharedConfig');

          promLatency(startTime, '/login/success', 'ok');
          promCounter('ok', 'sentinel_login');

          return resolve(response);
        })
        .catch(error => {
          promCounter('fail', 'sentinel_login');

          if (error && (error.message === 'UNAUTHORIZED_V2' || error.message === 'UNAUTHORIZED_V4')) {
            promCounter(error.message === 'UNAUTHORIZED_V2' ? 'v2' : 'v4', 'sentinel_login_failed_unauthorized');

            dispatch('catchError', {
              error: { message: '' },
              message: 'Username atau password salah',
              hideAppLoading: true,
            });

            // Oops, we're facing error! better cleanup any stalled token (curse you v2!)
            API.destroyToken();

            return reject(error);
          }
          if (error && typeof error.message !== 'undefined') {
            let formattedMessage = error.message;
            if (error.message.includes('timeout')) {
              // timeout
              formattedMessage = 'Timeout';
            } else if (error.message.includes('Network Error') || error.message.includes('Failed to fetch')) {
              // Network Error || Failed to fetch
              formattedMessage = 'Network Issue';
            } else if (error.message.includes('btoa')) {
              // Failed to execute 'btoa' on 'Window': The string to be encoded contains characters outside of the Latin1 range.
              formattedMessage = 'Failed to Execute BTOA';
            } else if (error.message.includes('Unexpected token')) {
              // Unexpected token R in JSON at position 0 || Unexpected token F in JSON at position 0
              formattedMessage = 'Unexpected Token';
            } else if (error.message.includes(`Cannot read property 'authorize' of null`)) {
              // Cannot read property 'authorize' of null
              formattedMessage = 'Authorize of Null';
            }

            promCounter(formattedMessage, 'sentinel_login_failed');
          }

          dispatch('catchError', {
            error,
            message: 'Gagal melakukan login',
            hideAppLoading: true,
          });
          return reject(error);
        });
    });
  },
  async authenticateCode({ commit, dispatch }, { authCode, redirectUri }) {
    const startTime = new Date().getTime();
    try {
      const authResponse = await API.authenticateViaCode(authCode, redirectUri);

      // V4 Check
      if (!authResponse.v4 || !authResponse.v4.access_token) throw new Error('Missing Credentials Error');

      // Action
      commit('setLoggedIn', true);
      dispatch('hideAppLoading');
      await dispatch('refetchSharedConfig');

      // Tracker
      promLatency(startTime, '/login-oauth/success', 'ok');
      promCounter('ok', 'sentinel_oauth_login');

      return authResponse;
    } catch (codeErr) {
      promCounter('fail', 'sentinel_oauth_login');
      promCounter(codeErr.message || codeErr, 'sentinel_login_failed');
      throw codeErr;
    }
  },
  destroyV4Token() {
    API.destroyV4Token();
  },
  logout() {
    // Destroy Cookies if any
    const cookieLabel = ['TEMP-Token-V4', 'TEMP-RefreshToken-V4', 'TEMP-UserId', 'oldlogin'];
    cookieLabel.map(item => {
      Cookies.remove(item, {
        // domain: `${process.env.WEB_SUBDOMAIN}.${process.env.WEB_DOMAIN}`,
        path: '/',
      });
      return item;
    });
    API.destroyV4Token();
  },
  async syncAccessToken({ dispatch, commit }, accessObject) {
    dispatch('showAppLoading');
    try {
      if (accessObject && accessObject.referrer !== 'sentinel-webview') throw new Error('UNKNOWN REFERRER');
      API.syncAccessToken(accessObject);
      commit('setLoggedIn', true);
      dispatch('hideAppLoading');
      await dispatch('refetchSharedConfig');
    } catch (error) {
      dispatch('hideAppLoading');
      dispatch('showFlashAlert', {
        message: 'Gagal melakukan sinkronisasi data. Silakan login kembali.',
        type: 'warning',
      });
    }
  },
};

const mutations = {
  setLoggedIn(state, bool) {
    state.authenticated = bool;
  },
  setToken(state, token) {
    state.token = token;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
