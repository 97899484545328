import { Client } from '~/types/api/api-module';
import ApiOptions from '../api-options';

export default Client.wrap({
  userSaas() {
    return this.get('/saas-users/me', 'public');
  },
  updateUserSaas(requestPayload) {
    return this.patch('/saas-users/me', 'public', new ApiOptions({ requestPayload }));
  },
  createUserSaas(requestPayload) {
    return this.post('/saas-users', 'public', new ApiOptions({ requestPayload }));
  },
});
