import location from '~/plugins/LocationPlugin';

export default function ({ app, redirect }) {
  // If the user is not authenticated
  if (!app.$credentials.login) {
    if (process.env.NODE_ENV !== 'production') {
      redirect('/login');
    } else {
      location.go('/');
    }
  }
}
