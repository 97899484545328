import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const USER_URL = `${process.env.API_HOST}/users`;

export default Client.wrap({
  updateTFASetting(requestPayload, requestHeaders) {
    return this.put(`${USER_URL}/tfa-status`, 'user', new ApiOptions({ requestHeaders, requestPayload }));
  },
  confirmUserPhone(requestHeaders) {
    return this.put(`${USER_URL}/phone-confirmation`, 'user', new ApiOptions({ requestHeaders }));
  },
  resetUserPasswordByEmail(email) {
    const requestPayload = { email };
    return this.post(`${USER_URL}/passwords`, 'public', new ApiOptions({ requestPayload }));
  },
  retrieveCurrentUser() {
    return this.get('/user-profiles', 'user', new ApiOptions());
  },
});
