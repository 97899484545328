const initialState = {
  planogram: null,
  topRowCount: 0,
  stickyBannerProduct: null,
  stickyBannerOffset: 0,
  isStickyBannerCalculated: false,
};

const getters = {
  getPlanogramTopRowCount(state) {
    return state.topRowCount;
  },
  getPlanogramStickyBannerProduct(state) {
    return state.stickyBannerProduct;
  },
  getPlanogramStickyBannerOffset(state) {
    return state.stickyBannerOffset;
  },
  isPlanogramStickyBannerCalculated(state) {
    return state.isStickyBannerCalculated;
  },
};

const actions = {
  setPlanogramStickyBannerProduct({ commit }, product) {
    commit('setPlanogramStickyBannerProduct', product);
  },
  setPlanogramStickyBannerOffset({ commit }, offset) {
    commit('setPlanogramStickyBannerOffset', offset);
    commit('setIsPlanogramStickyBannerCalculated', true);
  },
  setIsPlanogramStickyBannerCalculated({ commit }, isCalculated) {
    commit('setIsPlanogramStickyBannerCalculated', isCalculated);
  },
  resetPlanogramStickyBanner({ commit }) {
    commit('setPlanogramStickyBannerProduct', null);
    commit('setPlanogramStickyBannerOffset', 0);
    commit('setIsPlanogramStickyBannerCalculated', false);
  },
};

const mutations = {
  setPlanogramStickyBannerProduct(state, product) {
    state.stickyBannerProduct = product;
  },
  setPlanogramStickyBannerOffset(state, offset) {
    state.stickyBannerOffset = offset;
  },
  setIsPlanogramStickyBannerCalculated(state, isCalculated) {
    state.isStickyBannerCalculated = isCalculated;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
