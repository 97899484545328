import { Middleware } from '@nuxt/types';
import credentialStorageInstance from '~/api/credential-storage-instance';

export const IdentityMiddleware: Middleware = async ({ store, req }) => {
  if (process.server) {
    await credentialStorageInstance.getServerIdentity(req);
  } else {
    credentialStorageInstance.fetchIdentity();
    await store.dispatch('initSharedConfig', { originalUrl: req?.originalUrl, force: false });
  }
};

export default IdentityMiddleware;
