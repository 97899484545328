import r from '~/utils/render-utils';

// VP Tix
const VPMotorcycleCheckout = () => import(/* webpackChunkName: 'vp-ins' */ '~/pages/VpIns/Motorcycle/Checkout');

const vpins = [
  {
    path: '/asuransi-motor/checkout/:id',
    component: r(VPMotorcycleCheckout),
    name: 'vp_ins_motorcycle_insurance_checkout',
  },
];

export default vpins;
