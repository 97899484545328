
import { Client } from '~/types/api/api-module';
import ApiOptions from '../api-options';

const URL = `${process.env.API_V4_HOST}/helps`;

export interface HelpCategory {
  id: number;
  slug: string;
  parent_id: number;
  name: string;
  description: string;
}

export interface HelpContent {
  id: number;
  title: string;
  description: string;
}

export default Client.wrap({
  Private: {
    fetchHelpCategories(parentId: number) {
      return this.get<HelpCategory[]>(`${URL}/categories?active=true&parent_id=${parentId}`, 'public', new ApiOptions());
    },
    fetchHelpContents(categoryId: number) {
      return this.get<HelpContent[]>(`${URL}/contents?category_id=${categoryId}&limit=100`, 'public', new ApiOptions());
    },
    fetchHelpContent(id: number) {
      return this.get<HelpCategory>(`${URL}/contents/${id}`, 'public', new ApiOptions());
    },
  }
});
