/* eslint
  prefer-rest-params: 0
  no-useless-escape: 0
 */

/**
 * @module helpers/string-helper
 */

/**
 * remove whitespace in a string
 *
 * @example
 * customTrim('  lorem ipsum   dolor sit amit  ')
 * // -> "lorem ipsum dolor sit amit"
 *
 * @param str {String} dirty string
 * @return {String} clean string without padding
 */
export function customTrim(str: string): string {
  if (typeof arguments[0] === 'undefined') {
    throw new TypeError('string is required');
  }

  if (typeof str !== 'string') {
    throw new TypeError('parameter must be a string');
  }

  return str.replace(/\s+/g, ' ').trim();
}

/**
 * transform a string to title case
 *
 * @example
 * toTitleCase('lorem ipsum dolor')
 * // -> Lorem Ipsum Dolor
 *
 * @param str {String} sentances that will be transform to title case
 */
export function toTitleCase(str: string) {
  if (typeof str === 'undefined') {
    throw new TypeError('param is required');
  }

  if (typeof str !== 'string') {
    throw new TypeError('param must be string');
  }

  return customTrim(str)
    .replace(/[-_]/g, ' ')
    .split(' ')
    .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join(' ');
}

/**
 * check if string is valid email
 *
 * @example
 * isEmailValid('test@domain.com')
 * // => true
 *
 * @param email {String} email or random string
 * @return boolean
 */
export function isEmailValid(email: string) {
  if (typeof arguments[0] === 'undefined') {
    throw new TypeError('email is required');
  }

  if (typeof email !== 'string') {
    throw new TypeError('email must be a string');
  }

  const re = /^([a-zA-Z0-9_\-\.\+]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/g;
  return re.test(email);
}

/**
 * padstart a string with a padder and count
 *
 * @example
 * padStart('12', 3, 0)
 * // => '012'
 *
 * @param {String} string
 * @param {String|Number} [padder=' ']
 * @param {Number} [count=1]
 * @returns {String}
 */
export function padStart(string: string, count: number = 1, padder: string | number = ' '): string {
  if (typeof string !== 'string') throw new Error('Paddedstring is required and must be string');
  if (!['string', 'number'].includes(typeof padder)) throw new Error('Padder must be string or number');
  if (typeof count !== 'number') throw new Error('Count must be a number');

  const repeatedPadder = padder.toString().repeat(count);
  const longString = repeatedPadder + string;
  return string.length > count ? string : longString.slice(-count);
}

/**
 * sanitize a string from a <script> tag injection
 *
 * @example
 * sanitizeXss('this is an injected <script type="javascript">alert("hahaha");</script> scripts')
 * // => 'this is an injected  scripts'
 *
 * @param {String} string
 * @returns {String}
 */
export function sanitizeXss(string: string): string {
  return string?.replace?.(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '') ?? string;
}

/**
 * Transform string into snake_case
 *
 * @example
 * toSnakeCase('That snake is eating banana')
 * // => 'that_snake_is_eating_banana'
 *
 * @param {String} str normal string
 * @returns {String} transformed string
 */
export function toSnakeCase(str: string): string {
  if (typeof str === 'undefined') {
    throw new TypeError('param is required');
  }

  if (typeof str !== 'string') {
    throw new TypeError('param must be string');
  }

  let transformedStr = str
    .replace(/[^\w\s]/gi, ' ')
    .split(/\s+|_+|(?=[A-Z])/)
    .join('_')
    .toLowerCase();

  if (transformedStr[0] === '_') transformedStr = transformedStr.slice(1, transformedStr.length);
  return transformedStr;
}
