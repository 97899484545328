import { AsyncComponent } from 'vue';

function r<T>(page: () => Promise<{ default: T }>): AsyncComponent<T> {
  return async () => {
    const result = await page();
    return result.default || result;
  };
}

export default r;
