export default {
  trackVoucher(
    referrer,
    screenTarget = '',
    {
      journeyId,
      username,
      voucherCategory,
      voucherCode,
      isValid,
      isCashback,
      discount,
      cashback,
      message,
      createdAt,
      withoutSubmit,
      voucherState,
    }
  ) {
    let voucherType = '';
    if (isValid) voucherType = isCashback ? 'cashback' : 'discount';
    this.$blTracker.actions.trackActions({
      evn: 'agenlite_voucher',
      username,
      referrer,
      screen_target: screenTarget,
      voucher_category: voucherCategory,
      voucher_code: voucherCode,
      voucher_valid: isValid,
      voucher_type: voucherType,
      voucher_amount: isCashback ? cashback : discount,
      voucher_state: voucherState,
      created_at: createdAt,
      without_submit: withoutSubmit,
      error_message: isValid ? '' : message,
      journey_id: journeyId,
    });
  },
  trackVoucherSuggestionEntryClick(referrer, screenTarget = '', { username, journeyId, createdAt }) {
    this.$blTracker.actions.trackActions({
      evn: 'agenlite_voucher_suggestion_entry_click',
      username,
      journey_id: journeyId,
      screen_target: screenTarget,
      created_at: createdAt,
      referrer,
    });
  },
};
