/**
 * @module helpers/number-helper
 */

import createNumberMask from 'text-mask-addons/dist/createNumberMask'; // https://github.com/text-mask/text-mask/tree/master/addons/#readme

type UnitType = 'short' | 'long';

/**
 * Return random integer in between min and max
 *
 * @example
 * getRandomInt(0, 5)
 * // => 0, 1, 2, 3, 4 or 5
 *
 * @param  {Number} min minimum possible value
 * @param  {Number} max maximum possible value
 *
 * @return {Number}
 */
export function getRandomInt(min: number, max: number): number {
  if (max < min) {
    throw new Error('Max must be greater or equal than min');
  }

  // eslint-disable-next-line no-mixed-operators
  return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * Return the positive of a number
 *
 * @example
 * toPositiveInt(-4)
 * // => 4
 *
 * @param  {Number} val
 * @return {Number}
 */
export function toPositiveInt(val: number): number {
  if (typeof val !== 'number') {
    throw new TypeError('toPositiveInt must receive number as input');
  }
  return Math.abs(val);
}

/**
 * Delimit number (id-ID)
 *
 * @example
 * delimiter(1000000)
 * // => '1.000.000'
 *
 * @param  {Number} val Number to delimit
 * @return {String} Delimited number
 */
export function delimiter(val: number): string {
  return new Intl.NumberFormat('id-ID', { minimumSignificantDigits: 1, style: 'decimal' }).format(val);
}

/**
 * Convert number to money (id-ID)
 *
 * @example
 * money(1000000)
 * // => 'Rp1.000.000'
 *
 * @param  {Number} val Number to convert
 * @return {String} Converted number in money format
 */
export function money(val: number): string {
  return new Intl.NumberFormat('id-ID', { minimumSignificantDigits: 1, style: 'currency', currency: 'IDR' })
    .format(val)
    .replace(/\s/g, '');
}

/**
 * Strip 'Rp' and '.' from money format
 *
 * @example
 * stripMoney('Rp50.000')
 * // => '50000'
 *
 * @param  {String} val String to convert
 * @return {String} Stripped amount
 */
export function stripMoney(val: string): string {
  let nominal = val;
  nominal = nominal.replace('Rp', '').replace(/\./g, '');
  return nominal;
}

/**
 * Transform number to simple currency format
 * can't satisfy these cases below
 * 15.750 ---> 15,7rb
 * 13.456.000 ---> 13,4jt
 *
 * @example
 * simpleMoney(500000)
 * // => 'Rp500rb'
 *
 * @param  {Number} Amount required e.g. 10000
 * @param  {String} Symbol optional e.g. 'Rp'
 * @param  {String} UnitType optional e.g. 'short' | 'long'
 * @return {String} Transformed number
 */
export function simpleMoney(amount: number, symbol = 'Rp', unitType: UnitType = 'short'): string {
  let absAmount = amount;
  if (amount < 0) absAmount = Math.abs(Number(amount));

  let parsedNumber = absAmount || 0;
  const units = unitType === 'short' ? ['', 'rb', 'jt', 'M', 'T'] : ['', 'ribu', 'juta', 'miliar', 'triliun'];

  let unitIndex = 0;
  while (parsedNumber >= 1000) {
    parsedNumber /= 1000;
    unitIndex += 1;
  }

  parsedNumber = Number.parseFloat((Math.floor(parsedNumber * 10) / 10).toString());
  if (parsedNumber === Math.floor(parsedNumber)) parsedNumber = Math.floor(parsedNumber);

  const simpleFormat = `${symbol}${parsedNumber.toString().replace('.', ',')}${units[unitIndex]}`;
  return `${amount < 0 ? '-' : ''}${simpleFormat}`;
}

/**
 * Return Currency Mask for 'Vue Text Mask' Component
 *
 * @example
 * https://github.com/text-mask/text-mask/blob/master/addons/src/createNumberMask.js
 * Used in this component -> https://github.com/text-mask/text-mask/tree/master/vue#readme
 * Documentation          -> https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md
 * Live demo              -> https://text-mask.github.io/text-mask/
 *
 * @param {string} prefix what to display before the amount
 * @param {Object} options options for createNumberMask function
 * @return {Array} Currency format in array form
 */
export function currencyMask(prefix: string = '', options: object = {}): Array<string> {
  return createNumberMask({
    prefix,
    thousandsSeparatorSymbol: '.',
    decimalLimit: 0,
    ...options,
  });
}

/**
 * Return Integer Mask for 'Vue Text Mask' Component
 *
 * @example
 * https://github.com/text-mask/text-mask/blob/master/addons/src/createNumberMask.js
 * Used in this component -> https://github.com/text-mask/text-mask/tree/master/vue#readme
 * Documentation          -> https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md
 * Live demo              -> https://text-mask.github.io/text-mask/
 *
 * @param  {Boolean} AllowNegative whether can input negative value or not
 * @param  {Number|null} IntegerLimit define number of digit that can be inputted (excl. '-' sign)
 * @return {Array} Integer format in array form
 */
export function integerMask(allowNegative = false, integerLimit = null, allowLeadingZeroes = false): Array<string> {
  return createNumberMask({
    prefix: '',
    thousandsSeparatorSymbol: '',
    decimalLimit: 0,
    allowNegative,
    integerLimit,
    allowLeadingZeroes,
  });
}

/**
 * Add phone number separator
 *
 * @example
 * separatePhoneNumber('081234567890')
 * // => '0812-3456-7890'
 *
 * @param  {String} val String to convert
 * @return {String} Separated phone number
 */
export function separatePhoneNumber(val: string): string {
  const separatedPhoneNumber = val.replace(/(\d{4})(\d{1,4})(\d*)/, (_, firstPart, secondPart, rest) => {
    if (rest.length) {
      return firstPart + '-' + secondPart + '-' + rest;
    } else if (secondPart.length) {
      return firstPart + '-' + secondPart + rest;
    }

    return val;
  });
  return separatedPhoneNumber;
}

export default {
  getRandomInt,
  toPositiveInt,
  delimiter,
  money,
  currencyMask,
  integerMask,
  separatePhoneNumber,
};
