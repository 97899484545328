import Vue from 'vue';
import { Plugin } from '@nuxt/types';
import VuexPersistence from 'vuex-persist';

// How to add persisted module:
// - Insert module key to this list
// - Implement resetState function in that module mutations, example:
//   resetState(state) {
//     state.currentAgent = JSON.parse(JSON.stringify(initialState.currentAgent));
//   }

export const persistedModules = [
  'agent',
  'agentCustomers',
  'banks',
  'banner',
  'bookkeepingOnboarding',
  'cart',
  'dana',
  'freeGoodSampling',
  'global',
  'infoPayment',
  'onboardingVP',
  'persistedOnboarding',
  'productList',
  'profitReports',
  'searchResult',
  'support',
  'topupKurir',
  'topups',
  'trackerJourney',
  'userAddress',
];

const PersistencePlugin: Plugin = ({ store }) => {
  const instance = new VuexPersistence({
    strictMode: process.env.NODE_ENV === 'development',
    key: 'agenlite_store',
    storage: window.localStorage,
    modules: persistedModules,
  });
  store.registerModule('persistence', {
    namespaced: false,
    mutations: {
      RESTORE_MUTATION(_state, localState) {
        for (const propName of Object.keys(localState)) {
          Vue.set(this.state, propName, localState[propName]);
        }
      },
    },
  });
  instance.plugin(store);
};

export default PersistencePlugin;
