<template lang="pug">
.page
  AppHeader
    strong(slot='title') {{ action | capitalize }} Password
  LoadingSpinner(v-if="isAppLoading")
  FlashAlert.u-mrgn-top--1
  OtpFullModal(
    scope="public"
    @requestOtp="triggerOtp"
    @finishOtp="goToResetPass"
  )
  template(v-if="page === '/'")
    .o-container.u-pad-top--5
      .c-field
        .c-field__label-row.u-mrgn-bottom--2
          label.u-fg--ash-dark(for="email_or_phone") No. Handphone / E-Mail
        input#email_or_phone.c-input-text.c-input.c-input--large.u-mrgn-bottom--3(v-model="identityValue", name="email_or_phone", @keydown.enter.prevent="checkPhoneEmail($event)", :disabled="from === 'change'")
      p.u-mrgn-bottom--5
        span.u-fg--ash-dark {{ action | capitalize }} password menggunakan no. handphone hanya untuk akun yang tidak memiliki e-mail.
      a.c-btn.c-btn--block.c-btn--red.c-btn--large(:disabled="!isValid || isAppLoading", @click.prevent="checkPhoneEmail($event)") Kirim
  template(v-if="page === 'email'")
    .o-container.u-pad-top--5.u-align-center
      h2.u-mrgn-bottom--4 Permintaan Telah Terkirim
      template(v-if="identityType === 'e-mail'")
        .u-txt--caption.u-pad-bottom--6 Permintaan {{ action }} password telah dikirimkan ke {{ identityType }}
          strong  {{ identityValue }}
        .u-txt--caption.u-pad-bottom--6 Cek email sekarang dan ikuti petunjuknya.
  template(v-if="page === 'reset'")
    .o-container.u-pad-top--5
      PasswordField(
        name="new_password",
        label="Password Baru",
        :value="newPassword",
        @input="newPassword = $event",
        @enter="submitResetPassword($event)",
        @error="isNewPasswordError = $event"
      )
      PasswordField(
        name="confirm_password",
        label="Konfirmasi Password Baru",
        alias="Konfirmasi Password",
        :value="confirmPassword",
        @input="confirmPassword = $event",
        @enter="submitResetPassword($event)",
        @error="isConfirmPasswordError = $event"
      )
      a.c-btn.c-btn--block.c-btn--red.c-btn--large.u-mrgn-top--5(:disabled="shouldDisableChangePasswordButton", @click.prevent="submitResetPassword($event)") Ubah Password
  .forget-back.u-position-fixed.u-align-center.u-width-1(v-if="page !== 'reset'")
    span.u-txt--caption Kembali ke&nbsp;
    router-link(v-if="from === 'change'" to="/").u-txt--caption.u-fg--red-brand Halaman Utama
    router-link(v-else to="/login").u-txt--caption.u-fg--red-brand Halaman Login
</template>

<script>
import { mapGetters } from 'vuex';
import AppHeader from '~/components/AppHeader';
import FlashAlert from '~/components/FlashAlert';
import LoadingSpinner from '~/components/shared/LoadingSpinner';
import PasswordField from '~/components/form/PasswordField';
import OtpFullModal from '~/components/otp/OtpFullModal';
import stringFilter from '~/mixins/string-filter';
import tracker from '~/mixins/tracker';
import trustedDeviceMixin from '~/mixins/trusted-device';

const regexPhone = /^(0|\+?62)8\d{8,11}$/;
const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
  name: 'LupaPassword',
  components: {
    AppHeader,
    FlashAlert,
    LoadingSpinner,
    PasswordField,
    OtpFullModal,
  },
  filters: {
    capitalize(string) {
      if (!string) return '';
      const lowerCase = string.toLowerCase();
      return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
    },
  },
  mixins: [stringFilter, tracker, trustedDeviceMixin],
  props: {
    from: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      identityValue: '',
      identityType: '',
      page: '/',
      newPassword: '',
      confirmPassword: '',
      oldPassword: '',
      isNewPasswordError: false,
      isConfirmPasswordError: false,
      disableSubmit: false,
    };
  },
  computed: {
    ...mapGetters(['isAppLoading', 'sharedConfig']),
    isFilled() {
      return this.newPassword !== '' && this.confirmPassword !== '';
    },
    isValid() {
      return regexPhone.test(this.identityValue) || regexEmail.test(this.identityValue);
    },
    action() {
      return this.from === 'change' ? 'ubah' : 'reset';
    },
    shouldDisableChangePasswordButton() {
      return (
        this.disableSubmit ||
        this.isAppLoading ||
        !this.isFilled ||
        this.isNewPasswordError ||
        this.isConfirmPasswordError
      );
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.from === 'change' && this.sharedConfig) {
        this.identityValue = this.sharedConfig.user.email
          ? this.sharedConfig.user.email
          : this.$options.filters.formatPhone(this.sharedConfig.user.phone);
      }
    });
  },
  methods: {
    async checkPhoneEmail(e) {
      if (!this.identityValue) return;
      this.trackClick(e, 'button_send_reset_password');
      let type = '';
      if (regexPhone.test(this.identityValue)) {
        type = 'phone';
        this.identityType = 'nomor';
      } else if (regexEmail.test(this.identityValue)) {
        type = 'email';
        this.identityType = 'e-mail';
      }
      try {
        await this.$store.dispatch('checkResetPasswordAvailability', {
          type,
          param: this.identityValue,
        });
        if (this.identityType === 'e-mail') {
          await this.$store.dispatch('resetUserPasswordByEmail', this.identityValue);
          this.goToEmailSent();
        } else {
          this.$store.dispatch('showOtpModal', { phone: this.identityValue });
        }
      } catch (err) {
        console.warn(err);
      }
    },
    goToForm() {
      this.identityType = '';
      this.identityValue = '';
      this.page = '/';
    },
    goToEmailSent() {
      this.page = 'email';
    },
    goToResetPass() {
      this.page = 'reset';
    },
    async triggerOtp(method) {
      const requestPayload = {
        token: this.identityValue,
      };
      await this.publicAuthentify({
        guardedApiAction: 'updateResetUserPassword',
        guardedApiPayload: requestPayload,
        callbackFunc: this.goToResetPass,
        method,
        triggerOnly: true,
      });
    },
    async submitResetPassword(e) {
      this.trackClick(e, 'button_submit_reset_password');
      const requestPayload = {
        token: this.identityValue,
        password: this.newPassword,
        password_confirmation: this.confirmPassword,
      };
      await this.publicAuthentify({
        guardedApiAction: 'updateResetUserPassword',
        guardedApiPayload: requestPayload,
        callbackFunc: response => {
          if (response.meta.http_status !== 200) return;
          this.disableSubmit = true;
          this.$store.dispatch('showFlashAlert', {
            message: `Berhasil melakukan ${this.action} password.`,
            type: 'success',
          });
          this.trackEvent('reset_password', 'success_via_pwa');
          const goToPage = this.from === 'change' ? '/logout' : '/login';
          window.setTimeout(() => {
            this.$router.replace(goToPage);
          }, 2000);
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.forget-back {
  bottom: 40px;
}
</style>
