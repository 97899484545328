import {
  DebtMutationEntity,
  DebtMutationPayload,
  PaymentRequestEntity,
  PaymentRequestPayload
} from '~/types/api/agent-customers-debt-mutations';
import { Client } from '~/types/api/api-module';
import ApiOptions from '../api-options';

const AGENT_CUSTOMER_DEBT_MUTATIONS_URL = `${process.env.API_V4_HOST}/offline-users/o2o-offline-customers`;
const AGENT_CUSTOMER_PAYMENT_REQUEST_URL = `${process.env.API_V4_HOST}/saas/payment-requests`;

export const AgentCustomerDebtMutations = Client.wrap({
  getOfflineCustomerDebtMutationList(userId: number, requestPayload: DebtMutationPayload) {
    return this.get<DebtMutationEntity[]>(`${AGENT_CUSTOMER_DEBT_MUTATIONS_URL}/${userId}/debt-mutations`, 'user', new ApiOptions({ requestPayload }));
  },
  getOfflineCustomerDebtMutation(userId: number, id: number) {
    return this.get<DebtMutationEntity>(`${AGENT_CUSTOMER_DEBT_MUTATIONS_URL}/${userId}/debt-mutations/${id}`, 'user', new ApiOptions());
  },
  deleteOfflineCustomerDebtMutation(userId: number, id: number) {
    return this.delete(`${AGENT_CUSTOMER_DEBT_MUTATIONS_URL}/${userId}/debt-mutations/${id}`, 'user', new ApiOptions());
  },
  updateOfflineCustomerDebtMutation(userId: number, id: number, requestPayload: DebtMutationPayload) {
    return this.patch<DebtMutationEntity>(`${AGENT_CUSTOMER_DEBT_MUTATIONS_URL}/${userId}/debt-mutations/${id}`, 'user', new ApiOptions({ requestPayload }));
  },
  createOfflineCustomerDebtMutation(userId: number, requestPayload: DebtMutationPayload) {
    return this.post<DebtMutationEntity>(`${AGENT_CUSTOMER_DEBT_MUTATIONS_URL}/${userId}/debt-mutations`, 'user', new ApiOptions({ requestPayload }));
  },
  createPaymentRequest(requestPayload: PaymentRequestPayload) {
    return this.post<PaymentRequestEntity>(`${AGENT_CUSTOMER_PAYMENT_REQUEST_URL}`, 'user', new ApiOptions({ requestPayload }));
  },
  retrievePaymentRequest(id: number) {
    return this.get<PaymentRequestEntity>(`${AGENT_CUSTOMER_PAYMENT_REQUEST_URL}/${id}`, 'user', new ApiOptions());
  }
});

export default AgentCustomerDebtMutations;
