import AgenliteAPIClient from '~/api';

const initialState = {};
const moduleGetters = {};

const regexPhone = /^(62)8\d{8,11}$/;

const actions = {
  async retrieveTopupsOndemandEligibility({ dispatch }) {
    let response = null;
    try {
      dispatch('showAppLoading');
      response = await AgenliteAPIClient.retrieveTopupsOndemandEligibility();
    } catch (error) {
      const message = 'Pengecekan Status Eligibility Gagal';
      dispatch('catchError', { error, message });
    } finally {
      dispatch('hideAppLoading');
    }
    return response?.data;
  },
  async requestTopupOndemand({ dispatch }, form) {
    let response = null;
    try {
      dispatch('showAppLoading');
      const { phone, address, longitude, latitude, nominal } = form;
      const formatedPhone = regexPhone.test(phone) ? `0${phone.substr(2)}` : phone;
      const requestPayload = {
        phone_number: formatedPhone,
        nominal: Number(nominal),
        address,
        longitude,
        latitude,
      };
      response = await AgenliteAPIClient.requestTopupOndemand(requestPayload);
    } catch (error) {
      dispatch('catchError', { error });
    } finally {
      dispatch('hideAppLoading');
    }
    return response;
  },
  async retreiveTopupOnDemandLastTransaction({ dispatch }) {
    let response = null;
    try {
      dispatch('showAppLoading');
      response = await AgenliteAPIClient.retreiveTopupOnDemandLastTransaction();
    } catch (error) {
      const message = 'Pengambilan Data Transaksi Terakhir Gagal';
      dispatch('catchError', { error, message });
    } finally {
      dispatch('hideAppLoading');
    }

    return response?.data;
  },
};

const mutations = {};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  mutations,
  actions,
};
