import Vuex from 'vuex';

import account from './modules/account';
import allowlist from './modules/allowlist';
import agent from './modules/agent';
import agentCustomers from './modules/agent-customers';
import app from './modules/app';
import appHeaderLayout from './modules/common/app-header-layout';
import authentication from './modules/authentication';
import balanceTransfer from './modules/balance-transfer';
import banks from './modules/banks';
import banner from './modules/banner';
import bpjs from './modules/bpjs';
import campaign from './modules/new-virtual-product/campaign';
import coupons from './modules/coupons';
import ctaInstallApp from './modules/lite/cta-install';
import dana from './modules/dana';
import dataPlan from './modules/data-plan';
import deals from './modules/vp-deals';
import digitalVoucher from './modules/new-virtual-product/digital-voucher';
import digitalSend from './modules/new-virtual-product/digital-send';
import edcCheckBalance from './modules/new-virtual-product/edc-check-balance';
import edcCashOut from './modules/new-virtual-product/edc-cash-out';
import qrisCashOut from './modules/new-virtual-product/qris-cash-out';
import prepaidPhoneTopup from './modules/new-virtual-product/prepaid-phone-topup';
import errorHandler from './modules/error-handler';
import flashalert from './modules/flashalert';
import global from './modules/global';
import groupBuying from './modules/group-buying';
import help from './modules/help';
import info from './modules/info';
import infoPayment from './modules/info-payment';
import leaderboard from './modules/leaderboard';
import winBigEvent from './modules/win-big-event';
import loading from './modules/loading';
import mitra from './modules/mitra';
import mitraBranch from './modules/mitra-branch';
import mitraBiller from './modules/mitra-biller';
import mixPayment from './modules/payment/mix-payment';
import motorcycleInsurance from './modules/motorcycle-insurance';
import multifinance from './modules/multifinance';
import multipleDebtNotes from './modules/bookkeeping/multiple-debt-notes';
import neo from './modules/neo';
import onboardingVP from './modules/onboardingVP';
import passwordReset from './modules/password-reset';
import payment from './modules/payment';
import pdam from './modules/pdam';
import persistedOnboarding from './modules/persisted-onboarding';
import phoneCredit from './modules/phone-credit';
import planogram from './modules/planogram';
import postpaidElectricity from './modules/postpaid-electricity';
import prepaidElectricity from './modules/prepaid-electricity';
import productPayment from './modules/product-payment';
import productItems from './modules/bookkeeping/product-items';
import profitReports from './modules/profit-reports';
import promos from './modules/promos';
import referral from './modules/referral';
import register from './modules/register';
import reward from './modules/reward';
import serbuSeru from './modules/serbu-seru';
import specialPromo from './modules/special-promo';
import superWarung from './modules/super-warung';
import tfa from './modules/tfa';
import topupDanaCustomer from './modules/topup-dana-customer';
import topupDigital from './modules/topup-digital';
import topupKurir from './modules/topup-kurir';
import topupOndemand from './modules/topup-ondemand';
import topups from './modules/topups';
import trackerInfoVP from './modules/tracker-info-vp';
import trackerJourney from './modules/tracker-journey';
import train from './modules/train';
import transactionProgress from './modules/transaction-progress';
import unifiedTransactions from './modules/unified-transaction-list';
import user from './modules/user';
import userAddress from './modules/user-address';
import virtualProduct from './modules/new-virtual-product/virtual-product';
import payment20 from './modules/payment/payment20';
import vpPackages from './modules/vp-packages';
import whatsapp from './modules/whatsapp';
import { cart, productList, searchResult, freeGoodSampling } from './modules/wholesale';
import bukabantuan from './modules/bukabantuan';
import vpCatalog from './modules/promotion-media/vp-catalog';
import subscriptionInstantRefund from './modules/new-virtual-product/subscription-instant-refund';
import subscriptionTopSpender from './modules/new-virtual-product/subscription-top-spender';
import subscription from './modules/subscription';
import mitraWallet from './modules/mitra-wallet';
import mitraKyc from './modules/mitra-kyc';

export const modules = {
  // BL
  ...app,
  allowlist,
  account,
  agent,
  agentCustomers,
  appHeaderLayout,
  authentication,
  balanceTransfer,
  banks,
  banner,
  bpjs,
  cart,
  campaign,
  coupons,
  ctaInstallApp,
  dana,
  dataPlan,
  deals,
  digitalVoucher,
  digitalSend,
  edcCheckBalance,
  edcCashOut,
  errorHandler,
  flashalert,
  freeGoodSampling,
  global,
  groupBuying,
  help,
  info,
  infoPayment,
  leaderboard,
  winBigEvent,
  loading,
  mitra,
  mitraBranch,
  mitraBiller,
  mixPayment,
  motorcycleInsurance,
  multifinance,
  multipleDebtNotes,
  neo,
  onboardingVP,
  passwordReset,
  payment,
  pdam,
  persistedOnboarding,
  phoneCredit,
  planogram,
  postpaidElectricity,
  prepaidElectricity,
  prepaidPhoneTopup,
  productList,
  productPayment,
  profitReports,
  promos,
  productItems,
  qrisCashOut,
  referral,
  register,
  reward,
  searchResult,
  serbuSeru,
  specialPromo,
  superWarung,
  tfa,
  topupDanaCustomer,
  topupDigital,
  topupKurir,
  topupOndemand,
  topups,
  trackerInfoVP,
  trackerJourney,
  train,
  transactionProgress,
  unifiedTransactions,
  user,
  userAddress,
  virtualProduct,
  payment20,
  vpCatalog,
  vpPackages,
  whatsapp,
  bukabantuan,
  subscriptionInstantRefund,
  subscriptionTopSpender,
  subscription,
  mitraWallet,
  mitraKyc,
};

const createStore = () =>
  new Vuex.Store({
    strict: process.env.NODE_ENV === 'development',
    modules,
    actions: {
      nuxtServerInit({ commit }, { req }) {
        commit('setReferrer', req.headers.referrer ?? '');
      },
      async nuxtClientInit({ dispatch }) {
        await dispatch('assignIdentity');
        await dispatch('initTrackerJourney');
        dispatch('clearReportTimer');
      },
      resetState({ commit }) {
        commit('resetState');
      },
      aggregate({ commit }, bundlePack) {
        commit('yieldAggregate', bundlePack);
      },
    },
  });

export default createStore;
