import AgenliteAPIClient from '~/api';

const initialState = {
  billers: null,
  multifinanceInfo: {
    customerNumber: null,
    customerName: null,
    installmentPeriod: null,
    amount: null,
    penaltyFee: null,
    adminCharge: null,
    dueDate: null,
    itemName: null,
    licenseNumber: null,
    referenceNumber: null,
    partnerName: null,
    billerId: null,
    billerName: null,
    billerImageUrl: null,
  },
  transaction: null,
};

const moduleGetters = {
  getMultifinanceInfo(state) {
    if (state.multifinanceInfo.customerNumber !== null) {
      return state.multifinanceInfo;
    }
    return null;
  },
  getMultifinanceBillers(state) {
    return state.billers;
  },
  getMultifinanceTransaction: state => state.transaction,
};

const actions = {
  async createMultifinanceTransaction({ dispatch, commit }, { nomorPelanggan, billerId, phoneNumberInput }) {
    dispatch('showAppLoading');
    try {
      const response = await AgenliteAPIClient.createMultifinanceTransaction(
        nomorPelanggan,
        billerId,
        phoneNumberInput,
      );
      commit('setMultifinanceTransaction', response.data);
      return response.data;
    } catch (error) {
      const message = 'Gagal melakukan transaksi angsuran kredit';
      dispatch('catchError', { error, message });
      throw error;
    } finally {
      dispatch('hideAppLoading');
    }
  },
  fetchMultifinanceBillers({ dispatch }) {
    AgenliteAPIClient.fetchMultifinanceBillers()
      .then(response => {
        dispatch('hideAppLoading');
        dispatch('hideFlashAlert');
        dispatch('setMultifinanceBillers', response.data);
      })
      .catch(error => {
        const message = 'Gagal mendapatkan data biller Multifinance.';
        dispatch('catchError', { error, message, hideAppLoading: true });
      });
  },
  fetchMultifinanceInfo({ dispatch }, { nomorPelanggan, billerId }) {
    dispatch('showAppLoading');
    return new Promise((resolve, reject) => {
      AgenliteAPIClient.inquireMultifinanceInfo(nomorPelanggan, billerId)
        .then(response => {
          dispatch('hideAppLoading');
          dispatch('hideFlashAlert');
          dispatch('setMultifinanceInfo', response.data);
          resolve();
        })
        .catch(error => {
          const message = 'Gagal mendapatkan data Multifinance.';
          if (error && error.errors && error.errors[0].code === 18117) {
            error.message = 'Maaf, nomornya salah. Coba cek lagi ya.';
            dispatch('hideAppLoading');
            return reject(error);
          }
          dispatch('catchError', { error, message, hideAppLoading: true });
          return reject(error);
        });
    });
  },
  setMultifinanceBillers({ commit }, billers) {
    commit('setMultifinanceBillers', billers);
  },
  clearMultifinanceBillers({ commit }) {
    commit('clearMultifinanceBillers');
  },
  clearMultifinanceInfo({ commit }) {
    commit('clearMultifinanceInfo');
  },
  setMultifinanceInfo({ commit }, multifinanceInfo) {
    commit('setMultifinanceInfo', multifinanceInfo);
  },
  payMultifinance({ dispatch, getters, commit }, { transaction, method, voucherCode }) {
    dispatch('showAppLoading');
    return new Promise((resolve, reject) => {
      AgenliteAPIClient.payMultifinance(getters.getUserId, transaction, method, voucherCode)
        .then(response => {
          dispatch('hideFlashAlert');
          commit('setMultifinanceTransaction', null);
          resolve(response);
        })
        .catch(error => {
          const message =
            'Pembayaran Cicilan gagal. Cek <a href="/transaksi">daftar transaksi</a> untuk melanjutkan pembayaran sebelum mengulang transaksi.';
          dispatch('catchError', { error, message, finishTransaction: true });
          reject(error);
        });
    });
  },
};

const mutations = {
  clearMultifinanceBillers(state) {
    state.billers = JSON.parse(JSON.stringify(initialState.billers));
  },
  setMultifinanceBillers(state, billers) {
    state.billers = [...billers];
  },
  clearMultifinanceInfo(state) {
    state.multifinanceInfo = JSON.parse(JSON.stringify(initialState.multifinanceInfo));
  },
  setMultifinanceInfo(state, multifinanceInfo) {
    state.multifinanceInfo = {
      customerNumber: multifinanceInfo.customer_number,
      customerName: multifinanceInfo.customer_name,
      installmentPeriod: multifinanceInfo.installment_period,
      amount: multifinanceInfo.amount,
      penaltyFee: multifinanceInfo.penalty_fee,
      adminCharge: multifinanceInfo.admin_charge,
      dueDate: multifinanceInfo.due_date,
      itemName: multifinanceInfo.item_name,
      licenseNumber: multifinanceInfo.license_number,
      referenceNumber: multifinanceInfo.reference_number,
      partnerName: multifinanceInfo.partner.name,
      billerId: multifinanceInfo.biller.id,
      billerName: multifinanceInfo.biller.name,
      billerImageUrl: multifinanceInfo.biller.image_url,
    };
  },
  setMultifinanceTransaction(state, transaction) {
    state.transaction = transaction;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  actions,
  mutations,
};
