import uuid from 'uuid/v4';
import Cookies from 'js-cookie';

const initialState = {
  id: '',
};

const moduleGetters = {
  getTrackerJourneyId(state) {
    return state.id;
  },
};

const actions = {
  initTrackerJourney({ commit }) {
    let journeyId = Cookies.get('journey_id');
    if (!journeyId) {
      journeyId = uuid();
      Cookies.set('journey_id', journeyId, {
        expires: 1 / 48, // 1day/48hours = 30minutes
      });
    }
    const mitrajourneyId = Cookies.get('mitra_journey_id');
    if (!mitrajourneyId) {
      Cookies.set('mitra_journey_id', journeyId, {
        expires: 1 / 48, // 1day/48hours = 30minutes
        domain: '.bukalapak.com',
      });
    }
    commit('setTrackerJourneyId', journeyId);
  },
};

const mutations = {
  setTrackerJourneyId(state, id) {
    state.id = id;
  },
  resetState(state) {
    state.id = JSON.parse(JSON.stringify(initialState.id));
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  actions,
  mutations,
};
