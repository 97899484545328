import { Plugin } from '@nuxt/types';
import axios from 'axios';
import API from '~/api';

const CredentialStoragePlugin: Plugin = (context, inject) => {
  inject('credentials', API.credentials);
  API.credentials.axios = context.app.$axios ?? axios.create();
};

export default CredentialStoragePlugin;
