import { APIResponse } from '~/types/api/api-response';
import ApiOptions from '~/api/v4/api-options';
import RequestBuilder from '~/api/request_builder';
import { Client } from '~/types/api/api-module';

const VOC_URL = `${process.env.API_V4_HOST}/_exclusive/mitra-payment`;

export const ExclusiveMitraPayment = Client.wrap({
  Public: {
    async createVOCTransaction(payload) {
      const { userId, transactionType: type, paymentMethod, VOCOptionsPayload, VOCPayload, OTPOptions } = payload;
      let response: APIResponse<any>;
      try {
        const requestPayload = {
          ...RequestBuilder.VOCPayloadBuilder(type, paymentMethod, VOCOptionsPayload, VOCPayload),
        };
        const requestHeaders = { ...RequestBuilder.getOtpHeader(this.getOtp(userId)?.key, OTPOptions) };
        response = await this.v4.exclusiveMitraPayment.postVOCTransaction(requestPayload, requestHeaders);
      } catch (error) {
        this.setOtpKey(payload.userId, error?.meta?.otp_key ?? '');
        throw error;
      }
      this.setOtpKey(payload.userId, response?.meta?.otp_key ?? '');
      return response;
    },
  },
  Private: {
    /** @deprecated */ getVOCListTransactions() {
      return this.get(`${VOC_URL}/transactions`, 'user', new ApiOptions());
    },
    postVOCTransaction(requestPayload, requestHeaders) {
      return this.post(`${VOC_URL}/transactions`, 'user', new ApiOptions({ requestPayload, requestHeaders }));
    },
  },
});
