import r from '~/utils/render-utils';

const Index = () => import(/* webpackChunkName: 'group-buying-home' */ '~/pages/GroupBuying/Index');
const History = () => import(/* webpackChunkName: 'group-buying-history' */ '~/pages/GroupBuying/History');
const Landing = () => import(/* webpackChunkName: 'group-buying-landing' */ '~/pages/GroupBuying/Landing');
const FlashSaleDetail = () => import(/* webpackChunkName: 'group-buying-share' */ '~/pages/GroupBuying/FlashSaleDetail');
const EditSingleProduct = () => import(/* webpackChunkName: 'group-buying-share' */ '~/pages/GroupBuying/EditSingleProduct');
const EditMultipleProducts = () => import(/* webpackChunkName: 'group-buying-share' */ '~/pages/GroupBuying/EditMultipleProducts');
const Catalog = () => import(/* webpackChunkName: 'group-buying-catalog' */ '~/pages/GroupBuying/Catalog/Index');
const CatalogReview = () => import(/* webpackChunkName: 'group-buying-catalog-review' */ '~/pages/GroupBuying/Catalog/Review');
const EditCatalogProducts = () => import(/* webpackChunkName: 'group-buying-catalog-review' */ '~/pages/GroupBuying/EditCatalogProducts');


const groupBuying = [
  {
    path: '/group-buying/',
    component: r(Index),
    name: 'group_buying_main',
  },
  {
    path: '/group-buying/history',
    component: r(History),
    name: 'group_buying_history',
  },
  {
    path: '/group-buying/flash-sale',
    component: r(FlashSaleDetail),
    name: 'group_buying_flash_deal',
  },
  {
    path: '/group-buying/landing',
    component: r(Landing),
    name: 'group_buying_landing',
  },
  {
    path: '/group-buying/edit-single-product',
    component: r(EditSingleProduct),
    name: 'group_buying_share'
  },
  {
    path: '/group-buying/edit-multiple-products',
    component: r(EditMultipleProducts),
    name: 'group_buying_edit_single_products'
  },
  {
    path: '/group-buying/catalog',
    component: r(Catalog),
    name: 'group_buying_catalog'
  },
  {
    path: '/group-buying/catalog/review',
    component: r(CatalogReview),
    name: 'group_buying_catalog_review'
  },
  {
    path: '/group-buying/catalog/review/edit',
    component: r(EditCatalogProducts),
    name: 'group_buying_edit_catalog'
  }
];

export default groupBuying;
