/**
 * @module promise/explanation
 *
 */

export type ExplanationState = 'pending' | 'resolved' | 'rejected';

/**
 * @export
 * @class Explanation
 * @classdesc Generate a state/condition of specified promise so it can be checked synchronously
 * @template T type of the promise
 */
export class Explanation<T> {
  private _state: ExplanationState = 'pending';
  private _promise: Promise<T>;
  private _isFinished: boolean = false;
  readonly createdAt: Date;
  private _finishedAt?: Date = undefined;

  constructor(promiseObject: Promise<T>) {
    this.createdAt = new Date();
    this._promise = promiseObject;
    this._runPromise();
  }

  private async _runPromise() {
    try {
      await this.getPromise();
    } catch (err) {
      console.warn('Explanation error at initialization,', err);
    }
  }

  get state() {
    return this._state;
  }

  get isFinished() {
    return this._isFinished;
  }

  get finishedAt() {
    return this._finishedAt;
  }

  async getPromise() {
    try {
      const result = await this._promise;
      this._state = 'resolved';
      return result;
    } catch (_) {
      this._state = 'rejected';
      throw new Error('Explanation failed');
    } finally {
      this._isFinished = true;
      this._finishedAt ??= new Date();
    }
  }
}

export default Explanation;
