import AgenliteAPIClient from '~/api';
import SilkroadAPIClient from '~/api/silkroad';

const initialState = {
  needToConfirmAddress: true,
  needToConfirmStoreImage: true,
};

const getters = {
  needToConfirmAddress(state) {
    return state.needToConfirmAddress;
  },
  needToConfirmStoreImage(state) {
    return state.needToConfirmStoreImage;
  },
};

const actions = {
  submitAddress({ dispatch }, { addressData }) {
    dispatch('showAppLoading');
    return new Promise((resolve, reject) => {
      SilkroadAPIClient.addAddress(addressData)
        .then(response => {
          dispatch('hideAppLoading');
          resolve(response);
        })
        .catch(error => {
          const message = 'Gagal menyimpan alamat.';
          dispatch('catchError', { error, message, hideAppLoading: true });
          reject(error);
        });
    });
  },
  updateAddress({ dispatch }, { addressData, addressId }) {
    dispatch('showAppLoading');
    return new Promise((resolve, reject) => {
      SilkroadAPIClient.updateAddress(addressData, addressId)
        .then(response => {
          dispatch('hideAppLoading');
          resolve(response);
        })
        .catch(error => {
          const message = 'Gagal menyimpan alamat.';
          dispatch('catchError', { error, message, hideAppLoading: true });
          reject(error);
        });
    });
  },
  submitStoreImage({ dispatch }, { storeImage }) {
    dispatch('showAppLoading');
    return new Promise((resolve, reject) => {
      AgenliteAPIClient.uploadAgentImages({ type: 'store', image: storeImage })
        .then(response => {
          dispatch('hideAppLoading');
          resolve(response.data);
        })
        .catch(error => {
          const message = 'Gagal menyimpan foto.';
          dispatch('catchError', { error, message, hideAppLoading: true });
          reject(error);
        });
    });
  },
  confirmUserAddressAndStoreImage({ dispatch, commit }, { storeImage }) {
    return new Promise(resolve => {
      commit('confirmUserAddress');
      if (storeImage) {
        dispatch('submitStoreImage', { storeImage }).then(() => {
          dispatch('showFlashAlert', {
            message: 'Berhasil melakukan konfirmasi alamat.',
            type: 'success',
          });
          commit('confirmStoreImage');
          resolve();
        });
      } else {
        dispatch('showFlashAlert', {
          message: 'Berhasil melakukan konfirmasi alamat.',
          type: 'success',
        });
        commit('confirmStoreImage');
        resolve();
      }
    });
  },
  getUserCurrentGeolocation({ dispatch }, { highAccuracy = true } = {}) {
    return new Promise(resolve => {
      const geoOptions = {
        enableHighAccuracy: highAccuracy,
        maximumAge: 120000,
        timeout: 120000,
      };

      if (navigator && navigator.geolocation) {
        const watchId = navigator.geolocation.watchPosition(
          position => {
            if (!highAccuracy || position.coords.accuracy <= 50) {
              navigator.geolocation.clearWatch(watchId);
              dispatch('hideFlashAlert');
              resolve(position.coords);
            }
          },
          error => {
            let errorMessage = '';
            if (error) {
              switch (error.code) {
                case 1: // PERMISSION_DENIED
                  errorMessage =
                    'Aktifkan layanan lokasi untuk melanjutkan. Buka Menu > Setelan/Pengaturan > Pengaturan Situs > Lokasi.';
                  break;
                case 2: // POSITION_UNAVAILABLE
                  errorMessage = 'Aktifkan GPS untuk melanjutkan.';
                  break;
                case 3: // TIMEOUT
                default:
                  errorMessage = '';
              }
            }
            dispatch('showFlashAlert', {
              message: errorMessage,
              type: 'info',
            });
          },
          geoOptions,
        );
      } else {
        dispatch('showFlashAlert', {
          message: 'Update aplikasi Chrome melalui Play Store untuk melanjutkan.',
          type: 'info',
        });
      }
    });
  },
};

const mutations = {
  confirmUserAddress(state) {
    state.needToConfirmAddress = false;
  },
  confirmStoreImage(state) {
    state.needToConfirmStoreImage = false;
  },
  resetState(state) {
    state.needToConfirmAddress = JSON.parse(JSON.stringify(initialState.needToConfirmAddress));
    state.needToConfirmStoreImage = JSON.parse(
      JSON.stringify(initialState.needToConfirmStoreImage),
    );
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
