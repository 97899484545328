import ApiOptions from '../api-options';

const GT_SUPERWARUNG_URL = `${process.env.API_V4_HOST}/general-trade/super-warung`;

export default {
  getSuperWarungMe() {
    return this.get(`${GT_SUPERWARUNG_URL}/me`, 'user', new ApiOptions());
  },
  getSuperWarungKYC() {
    return this.get(`${GT_SUPERWARUNG_URL}/submissions/me`, 'user', new ApiOptions());
  },
  patchSuperWarungInboundReview() {
    return this.patch(`${GT_SUPERWARUNG_URL}/inbound-reviews/requests`, 'user', new ApiOptions());
  }
};
