import AgenliteAPIClient from '~/api';


const initialState = {
  kycBasic: {},
};

const getters = {
  getKYCBasic(state) {
    return state.kycBasic;
  },
  isKYCVerified(state) {
    return state.kycBasic?.state === 'verified';
  },
};


const mutations = {
  setKYCBasic(state, kycBasic) {
    state.kycBasic = kycBasic;
  },
};

const actions = {
  async fetchMitraKYC({ commit, dispatch }) {
    let response = null;
    try {
      response = await AgenliteAPIClient.getMitraKYC();
      commit('setKYCBasic', response.data);
    } catch (error) {
      const message = 'Gagal mendapatkan data KYC basic.';
      dispatch('catchError', { error, message, noFlashAlert: true }, { root: true });
      throw error;
    }
    return response?.data;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
