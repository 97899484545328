import credentialStorageInstance from '~/api/credential-storage-instance';
import Client from '~/types/api/api-module';
import { AgentBannerParams, AgentBannerResponse } from '~/types/api/agents';
import ApiOptions from '../api-options';

const AGENT_URL = `${process.env.API_V4_HOST}/agents`;
const WHOLESALE_URL = `${AGENT_URL}/wholesale`;
const AGENT_INFO_URL = `${process.env.API_V4_HOST}/info/agents`;

const FLINTSTONE_API_HOST = `${process.env.API_V4_HOST}/offline-users`;
const PELANGGAN_API_HOST = `${FLINTSTONE_API_HOST}/o2o-agent-customers`;
const OTP_PELANGGAN_API_HOST = `${FLINTSTONE_API_HOST}/otp`;

export default Client.wrap({
  Public: {
    async retrieveCurrentAgent() {
      const requestHeaders = {
        'Bukalapak-Identity': credentialStorageInstance.identity,
      };
      const response = await this.v4.agent.fetchCurrentAgent(requestHeaders);
      if (response && response.meta && response.meta.otp_key) {
        this.setOtpKey(response.data.user_id, response.meta.otp_key);
      }
      return response;
    },
    async changeAgentStatus(agentId, requestPayload) {
      const response = await this.v4.agent.patchAgentStatus(agentId, requestPayload);
      return response;
    },
    uploadAgentImages(images) {
      return this.v4.agent.uploadImages(images);
    },
    createAgentTransactionDetail(paymentId, customerNumber, customerPhone) {
      if (!customerNumber || !customerPhone) return null;
      const requestPayload = {
        payment_id: paymentId,
        customer_phone_number: customerPhone,
        customer_number: customerNumber,
      };
      return this.v4.agent.createTransactionDetail(requestPayload);
    },
    getWholesaleProducts(options) {
      return this.v4.agent.retrieveWholesaleProducts(options);
    },
    addAgentCustomer(pelanggan) {
      return this.v4.agent.addPelanggan(pelanggan);
    },
    editAgentCustomer(pelanggan) {
      return this.v4.agent.editPelanggan(pelanggan);
    },
    updateAgentData(id, payload) {
      return this.v4.agent.updateAgent(id, payload);
    },
    async registerMitra(requestPayload, { method = 'sms', otpCode = null } = {}, userId = null) {
      const userKey = userId || requestPayload.phone;
      const otpKey = this.getOtp(userKey).key;
      const browserFingerprint = window.localStorage.getItem('browser_fp');
      const requestHeaders = {
        'Bukalapak-Identity': credentialStorageInstance.identity,
        'Bukalapak-OTP-Method': method || 'sms',
      };
      if (otpCode) {
        requestHeaders['Bukalapak-Phone-Token'] = otpCode;
      } else if (otpKey) {
        requestHeaders['Bukalapak-OTP-Key'] = otpKey;
      }
      if (browserFingerprint) {
        requestHeaders['Bukalapak-Device-Fingerprint'] = browserFingerprint;
      }

      try {
        const response = await this.v4.agent.createAgent(requestPayload, requestHeaders);
        if (response.meta.http_status !== 201) throw response;
        if (response.meta.otp_key) this.setOtpKey(userKey, response.meta.otp_key);
        return response;
      } catch (error) {
        if (error.meta.otp_key) {
          this.setOtpKey(userKey, error.meta.otp_key);
        }
        throw error;
      }
    },
  },
  Private: {
    async createAgent(requestPayload, requestHeaders) {
      const response = await this.post(`${AGENT_URL}`, 'public', new ApiOptions({ requestPayload, requestHeaders }));
      return response;
    },
    updateAgent(id, requestPayload) {
      return this.patch(`${AGENT_URL}/${id}`, 'user', new ApiOptions({ requestPayload }));
    },
    retrieveAgent(username) {
      return this.get(`${AGENT_URL}/username/${username}`, 'public', new ApiOptions());
    },
    fetchCurrentAgent(requestHeaders) {
      return this.get(`${AGENT_URL}/me`, 'user', new ApiOptions({ requestHeaders }));
    },
    uploadImages(images) {
      const requestPayload = {
        type: images.type,
        image: images.image,
      };
      return this.post(`${AGENT_URL}/me/images`, 'public', new ApiOptions({ requestPayload }));
    },
    retrieveCoordinatorInvoice() {
      return this.get(`${process.env.API_V4_HOST}/coordinators/me`, 'public', new ApiOptions());
    },
    createReferredTransaction(salesCode, paymentId) {
      const requestPayload = {
        payment_id: paymentId,
        referral_username: salesCode,
      };
      return this.post(
        `${process.env.API_V4_HOST}/coordinators/referred-transactions`,
        'public',
        new ApiOptions({ requestPayload })
      );
    },
    createTransactionDetail(requestPayload) {
      return this.post(`${AGENT_URL}/transactions/details`, 'public', new ApiOptions({ requestPayload }));
    },
    patchAgentStatus(agentId, requestPayload) {
      return this.patch(`${AGENT_URL}/${agentId}/status`, 'public', new ApiOptions({ requestPayload }));
    },
    getWholesaleTransactions() {
      return this.get(`${WHOLESALE_URL}/transactions`, 'user', new ApiOptions());
    },
    getWholesaleProductCategories() {
      return this.get(`${WHOLESALE_URL}/product_categories`, 'public', new ApiOptions());
    },
    retrieveWholesaleProducts(requestPayload) {
      return this.get(`${WHOLESALE_URL}/products`, 'public', new ApiOptions({ requestPayload }));
    },
    getWholesaleSellers() {
      return this.get(`${WHOLESALE_URL}/sellers`, 'public', new ApiOptions());
    },
    getPelangganWallet(pelanggan) {
      return this.get(`${PELANGGAN_API_HOST}/${pelanggan.id}/wallet`, 'user', new ApiOptions());
    },
    getListPelanggan(_container, params) {
      return this.get(`${PELANGGAN_API_HOST}?${params || ''}`, 'user', new ApiOptions());
    },
    deletePelanggan(pelanggan) {
      return this.delete(`${PELANGGAN_API_HOST}/${pelanggan.id}`, 'user', new ApiOptions());
    },
    editPelanggan(pelanggan) {
      return this.put(`${PELANGGAN_API_HOST}/${pelanggan.id}`, 'user', new ApiOptions({ requestPayload: pelanggan }));
    },
    addPelanggan(pelanggan) {
      return this.post(PELANGGAN_API_HOST, 'user', new ApiOptions({ requestPayload: pelanggan }));
    },
    requestRegisterOtp(phone) {
      const requestPayload = { phone };
      return this.post(`${OTP_PELANGGAN_API_HOST}/register-request`, 'user', new ApiOptions({ requestPayload }));
    },
    customerRequestOtp(id) {
      return this.post(`${OTP_PELANGGAN_API_HOST}/offline-user-request/${id}`, 'user', new ApiOptions());
    },
    retrieveVpStatusInfo(type) {
      return this.get(`${AGENT_INFO_URL}/vp-status-info/${type}`, 'public', new ApiOptions());
    },
    retrieveAgenliteBanners() {
      return this.get(`${AGENT_URL}/agenlite-banners`, 'public', new ApiOptions());
    },
    acceptTNC(params = '') {
      return this.get(`${AGENT_URL}/loan-authorize${params}`, 'user', new ApiOptions());
    },
    consultTopupDanaCustomer(target) {
      const requestPayload = { target };
      return this.get(`${AGENT_URL}/dana-consult`, 'user', new ApiOptions({ requestPayload }));
    },
    fetchAgentBanners(requestPayload: AgentBannerParams) {
      return this.get<AgentBannerResponse[]>(
        `${AGENT_URL}/agenlite-banners`,
        'public',
        new ApiOptions({ requestPayload })
      );
    },
  },
});
