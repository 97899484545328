import AgenliteAPIClient from '~/api';

const initialState = {
  recipientInfo: null,
};

const moduleGetters = {
  getRecipientInfo(state) {
    return state.recipientInfo;
  },
};

const actions = {
  async fetchRecipientInfo({ dispatch, commit }, { usernameOrPhone }) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.fetchUserAvailability(usernameOrPhone);
      if (!response || !response.meta || response.meta.http_status !== 200) throw response;
      commit('setRecipientInfo', response.data);
    } catch (error) {
      const message = 'Gagal mendapatkan data penerima saldo.';
      dispatch('catchError', { error, message, hideAppLoading: true });
      throw error;
    } finally {
      dispatch('hideAppLoading');
      dispatch('hideFlashAlert');
    }
    return response;
  },
  async createPayoutTransaction({ dispatch }, requestPayload) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.createPayoutTransaction(requestPayload);
    } catch (error) {
      const message = 'Gagal melakukan pengiriman saldo.';
      dispatch('catchError', { error, message, hideAppLoading: true });
      throw error;
    } finally {
      dispatch('hideAppLoading');
      dispatch('hideFlashAlert');
    }
    return response;
  },
  clearRecipientInfo({ commit }) {
    commit('setRecipientInfo', null);
  },
};

const mutations = {
  setRecipientInfo(state, recipientInfo) {
    state.recipientInfo = recipientInfo;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  actions,
  mutations,
};
