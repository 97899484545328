import AgenliteAPIClient from '~/api';

const initialState = {
  promos: {
    data: [],
    meta: {
      total: 0,
    },
  },
};

const getters = {
  getTotalPromo: ({ promos }) => promos.meta.total,
  getPromos: ({ promos }) => promos.data,
  getMeta: ({ promos }) => promos.meta,
};

const actions = {
  async getPromos({ dispatch, commit }, { offset = 0, limit = 9, category, type, totalAmount = null }) {
    try {
      const promos = await AgenliteAPIClient.retrievePromos({
        offset,
        limit,
        category,
        type,
        ...(totalAmount ? { total_amount: totalAmount } : {}),
      });
      commit('setPromos', promos);
    } catch (error) {
      dispatch('catchError', { message: error, hideAppLoading: true });
    }
  },
};

const mutations = {
  setPromos(state, promos) {
    state.promos = promos;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
