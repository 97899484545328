import timelimit from '~/constants/vp/timelimit';
import Client from '~/types/api/api-module';
import ApiOptions from '../../api-options';

const BILLER_URL = `${process.env.API_V4_HOST}/_exclusive/payments/biller`;
const FEES_URL = `${process.env.API_V4_HOST}/_exclusive/info/payments`;

export default Client.wrap({
  Private: {
    getMitraBillerInquiry(requestPayload) {
      return this.post(`${BILLER_URL}/inquiry?format=json`, 'user', new ApiOptions({ requestPayload }));
    },
    payMitraBill(requestPayload) {
      const requestLimit = { time: timelimit.deposit, additionalKey: requestPayload.bill_number };
      return this.post(`${BILLER_URL}/payment?format=json`, 'user', new ApiOptions({ requestPayload, requestLimit }));
    },
    getPaymentFees() {
      return this.get(FEES_URL, 'user', new ApiOptions());
    },
  },
});
