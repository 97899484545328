import { generateBrowserID, getTrackerPlatform } from '~/utils/platform';
import { getScreenName } from '~/utils/bookkeeping-screen-name';
const PREFIX_BOOKKEEPING = 'agenlite_saas';

export default {
  bookkeepingCommonParamsBuilder() {
    return {
      journey_id: this.getTrackerJourneyId,
      referrer_screen: this.routeReferrer.name,
      referrer_url: this.routeReferrer.url,
      current_screen: getScreenName(this.$route),
    };
  },
  trackBookkeepingEntryClick({
    fund = 0,
    amount = 0,
    trxDate,
    clickSource,
    clickSourceStatus,
    entryId,
    checkboxHargaJual,
    notes = '',
    category = '',
    isPaid = false
  }) {
    const payload = {
      evn: `${PREFIX_BOOKKEEPING}_add_bookkeeping_click`,
      ...this.bookkeepingCommonParamsBuilder(),
      is_paid: isPaid,
      click_source: clickSource,
      click_source_status: clickSourceStatus || 'success',
      trx_date: trxDate,
      entry_id: entryId,
      checkbox_harga_jual: checkboxHargaJual,
      amount,
      fund,
      notes,
      category,
    };

    this.$blTracker.actions.trackActions(payload);
  },
  trackDebtNotesEntryClick({ clickSource, clickSourceStatus, debtAmount, entryAmount, offlineCustomerId }) {
    const payload = {
      evn: `${PREFIX_BOOKKEEPING}_entry_debt_notes_click`,
      ...this.bookkeepingCommonParamsBuilder(),
      click_source: clickSource,
      click_source_status: clickSourceStatus || 'success',
      debt_amount: debtAmount || 0,
      entry_amount: entryAmount || 0,
      flintstone_customer_id: offlineCustomerId || 0,
    };
    this.$blTracker.actions.trackActions(payload);
  },
  trackVpChangePriceClick({ clickSource, clickSourceStatus, sellingPrice, sellingPriceType, entry, sessionTime }) {
    const { selling_product: sellingProduct = {}, expense, category, payment_id: paymentId } = entry || {};
    const { current_screen: currentScreen, journey_id: journeyId } = this.bookkeepingCommonParamsBuilder();
    const payload = {
      evn: 'agenlite_vp_change_price_click',
      journey_id: journeyId,
      current_screen: currentScreen,
      click_source: clickSource,
      click_source_status: clickSourceStatus || 'success',
      selling_price: sellingPrice,
      selling_price_type: sellingPriceType || 'organic-filled',
      product_name: sellingProduct.name,
      total_purchase: expense,
      product_category: category,
      invoice_id: paymentId,
      session_time: sessionTime,
    };

    this.$blTracker.actions.trackActions(payload);
  },
  trackSharePaymentRequest({ source, method, appVersion }) {
    const platformBackup = this.$blTracker.tracker.internalTracker.$config.platform;
    const platform = `${getTrackerPlatform(undefined, this.$store)}${appVersion ? `_${appVersion}` : ''}`;
    if (appVersion) {
      this.$blTracker.tracker.internalTracker.config({
        platform,
      });
    }
    const payload = {
      evn: 'debt_notes_history',
      source,
      session_id: generateBrowserID(),
      ...(method && { method }),
    };
    this.$blTracker.actions.trackActions(payload);
    if (appVersion) {
      this.$blTracker.tracker.internalTracker.config({
        platform: platformBackup,
      });
    }
  },
  trackBookkeepingReportImpression({ direction, sectionName, sectionPos, itemPos }) {
    const payload = {
      evn: 'agenlite_bookkeeping_report_impression',
      ...this.bookkeepingCommonParamsBuilder(),
      scroll_type: direction,
      section_name: sectionName,
      section_position: sectionPos,
      item_position: itemPos || 0,
    };
    delete payload.current_screen;

    this.$blTracker.actions.trackActions(payload);
  }
};
