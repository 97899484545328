<template lang="pug">
  div
    LoadingSpinner(v-if="isAppLoading")
</template>

<script>
import { mapGetters } from 'vuex';
import LoadingSpinner from '~/components/shared/LoadingSpinner';

export default {
  name: 'AutoLogin',
  components: {
    LoadingSpinner,
  },
  props: {
    accessObject: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['isAppLoading']),
  },
  mounted() {
    this.$nextTick(async () => {
      if (!this.accessObject) {
        this.$router.replace('/login');
        return;
      }
      try {
        const parsedAccessObject = JSON.parse(this.accessObject);
        await this.$store.dispatch('syncAccessToken', parsedAccessObject);
        this.$router.replace(parsedAccessObject.targetUrl);
      } catch (error) {
        setTimeout(() => {
          this.$router.replace('/login');
        }, 2000);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
</style>
