import Client from '~/types/api/api-module';
import {
  ReferralMitraResponse,
  RewardHistories,
} from '~/types/api/referral';
import ApiOptions from '../../api-options';

const MITRA_REFERRAL_URL = `${process.env.API_V4_HOST}/_exclusive/referrals/mitra`;
const REFERRAL_POSTERS_URL = `${process.env.API_V4_HOST}/promotion-media/mitra-referrals/posters`;
const RETENTION_URL = `${process.env.API_V4_HOST}/agent-retention`;

export const MitraReferrals = Client.wrap({
  getMitraReferralsRewards() {
    return this.get<ReferralMitraResponse>(`${MITRA_REFERRAL_URL}/rewards`, 'user', new ApiOptions());
  },
  retrieveMitraReferralPerformance() {
    return this.get<ReferralMitraResponse>(`${MITRA_REFERRAL_URL}/performances`, 'user', new ApiOptions());
  },
  retrieveRewardHistories(params: RewardHistories) {
    return this.get(`${MITRA_REFERRAL_URL}/rewards/histories`, 'user', new ApiOptions({ requestPayload: params }));
  },
  createReferralPoster() {
    return this.post(`${REFERRAL_POSTERS_URL}`, 'user', new ApiOptions());
  },
  fetchReferralPoster() {
    return this.get(`${REFERRAL_POSTERS_URL}`, 'user', new ApiOptions());
  },
  fetchClaimBenefit(benefitSlug) {
    return this.get(`${RETENTION_URL}/claimed-benefits/${benefitSlug}`, 'user', new ApiOptions());
  },
  patchClaimBenefit(benefitSlug) {
    return this.patch(`${RETENTION_URL}/claimed-benefits/${benefitSlug}`, 'user', new ApiOptions());
  }
});
