import ApiOptions from '~/api/v4/api-options';

const EXPERIMENT_URL = `${process.env.API_V4_HOST}/_exclusive/experiments`;

export default {
  choose(experiment, requestPayload) {
    return this.post(`${EXPERIMENT_URL}/${experiment}/participants`, 'public', new ApiOptions({ requestPayload }));
  },
  score(experiment, requestPayload) {
    return this.post(`${EXPERIMENT_URL}/${experiment}/participants/${requestPayload.participant}/metrics`, 'public', new ApiOptions({ requestPayload }));
  },
  scoreConfig(experiment, requestPayload) {
    return {
      method: 'POST',
      path: `/_exclusive/experiments/${experiment}/participants/${requestPayload.participant}/metrics`,
      body: requestPayload,
    };
  },
};
