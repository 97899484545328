import { Plugin } from '@nuxt/types';
import tracker from '~/plugins/TrackerPlugin';

const TrackerPlugin: Plugin = async ({ store }, inject) => {
  await tracker.init(store);
  // eslint-disable-next-line no-console
  console.log('Bukalapak tracker initialized');
  inject('blTracker', tracker);
};

export default TrackerPlugin;
