<template lang="pug">
section
  .o-container--bleed
    section.landing-page-hero
      .landing-page-hero__content.ph-24
        bl-text-av.mb-24.u-fg--white(weight="regular" variation="heading-24" tag="h1") Siapa pun bisa jualan dengan untung jutaan!
        bl-text-av.mb-32.u-fg--white(weight="regular" variation="body-16" tag="p") Buat jadi Mitra Bukalapak, kamu gak perlu warung, kios atau toko. Dari jualan aneka produk virtual, hingga belanja stok warung, siapa pun bisa untung! Yuk, gabung sekarang.
  .pv-24.cta-login
    nuxt-link(to="/login")
      bl-button-av#qa-btn-login(variation="primary" fullwidth) Login ke Bukalapak
    bl-text-av.mt-8.u-txt--center(variation="body-14" tag="p")
      span Belum punya akun?&nbsp;
      nuxt-link(to="/register")
        bl-link-av#qa-link-register(variation="primary") Daftar di sini
</template>

<script>
import BlButtonAv from '@bukalapak/bazaar-mweb/dist/components/BlButtonAv';
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';
import BlLinkAv from '@bukalapak/bazaar-mweb/dist/components/BlLinkAv';
import BLBPicture from '~/components/shared/base/BLBPicture';

export default {
  name: 'LiteLanding',
  components: {
    BLBPicture,
    BlButtonAv,
    BlTextAv,
    BlLinkAv,
  },
  middleware: 'login',
  head() {
    return {
      title: 'Mitra Bukalapak: Semua bisa untung jutaan!',
      meta: [{ hid: 'preloader', name: 'preloader', content: 'disabled' }],
    };
  },
};
</script>

<style lang="scss" scoped>
@use '~@bukalapak/bazaar-token/dist/tokens';

section {
  background: #3838a8;
}
.landing-page-hero {
  background-image: url('~assets/images/landing/semuabisauntung_landing_mobile.png');
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center top;
  z-index: -1;

  &__content {
    padding-top: 121%;
    padding-bottom: 120px;
  }
}

.cta-login {
  position: fixed;
  bottom: 0;
  background-color: tokens.$color-background-base;
  width: 100%;
  padding: 0 24px;
  max-width: 540px;
  margin: auto;
}
</style>
