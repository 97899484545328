import AgenliteAPIClient from '~/api';

const initialState = {
  dataPlanProducts: null, // [] === unknown operator
  phoneNumberPrefix: '',
  operatorName: '',
  transaction: null,
};

const moduleGetters = {
  getDataPlanProducts(state) {
    return state.dataPlanProducts;
  },
  getDataPlanPhoneNumberPrefix(state) {
    return state.phoneNumberPrefix;
  },
  getDataPlanOperatorName(state) {
    return state.operatorName;
  },
  getDataPlanTransaction(state) {
    return state.transaction;
  },
};

function mapData(responseData) {
  return responseData.map(item => ({
    ...item,
    id: item.partner_package.package.id,
    price: item.partner_package.package.price,
    status: item.partner_package.package.status,
    operator_name: item.operator.name,
  }));
}

const actions = {
  async createTransactionDataPlanVOC({ dispatch, getters }, requestPayload) {
    const { id, phone, method: paymentMethod, voucherCode, finalAmount, transactionId, type: transactionType } = requestPayload;
    const VOCPayload = { id, phone };
    const VOCOptionsPayload = { voucherCode, finalAmount, transactionId };
    const userId = getters.getUserId;
    try {
      const response = await AgenliteAPIClient.createVOCTransaction({
        userId,
        transactionType,
        paymentMethod,
        VOCOptionsPayload,
        VOCPayload,
      });
      return response?.data;
    } catch (error) {
      let displayMessage = 'Pembelian paket data gagal';
      if (error?.errors) {
        const { message } = error.errors[0];
        displayMessage = message;
      }
      dispatch('catchError', { error, displayMessage, finishTransaction: true });
      throw error;
    }
  },
  // v4 create transaction
  async createDataPlanTransaction({ dispatch, commit }, payload) {
    dispatch('showAppLoading');
    try {
      const response = await AgenliteAPIClient.postDataPlanTransaction(payload);
      commit('setDataPlanTransaction', response.data);
    } catch (error) {
      let displayMessage = 'Gagal melakukan transaksi paket data';
      if (error?.errors) {
        const { message } = error.errors[0];
        displayMessage = message;
      }
      dispatch('catchError', { error, displayMessage });
      throw error;
    } finally {
      dispatch('hideAppLoading');
    }
  },
  // v4 fetch dataPlan products
  async fetchDataPlanProducts({ dispatch, commit }, prefix = '') {
    dispatch('showAppLoading');
    try {
      const response = await AgenliteAPIClient.fetchDataPlanProducts({ category: 'package', prefix });
      const productData = mapData(response.data);
      commit('setDataPlanProducts', productData);
      commit('setDataPlanCurrentPhonePrefix', prefix);
      if (productData?.length && productData[0].operator) commit('setDataPlanOperatorName', productData[0].operator.name);
    } catch (error) {
      let displayMessage = 'Server sedang sibuk';
      if (error?.errors) {
        const { message } = error.errors[0];
        displayMessage = message;
      }
      dispatch('catchError', { error, displayMessage });
      throw error;
    } finally {
      dispatch('hideAppLoading');
    }
  },
  clearDataPlanProducts({ commit }) {
    commit('clearDataPlanProducts');
  },
  clearDataPlanTransaction({ commit }) {
    commit('clearDataPlanTransaction');
  },
  setDataPlanOperatorName({ commit }) {
    commit('setDataPlanOperatorName');
  },
  setDataPlanTransaction({ commit }) {
    commit('setDataPlanTransaction');
  },
};

const mutations = {
  setDataPlanProducts(state, dataPlanProducts) {
    state.dataPlanProducts = JSON.parse(JSON.stringify(dataPlanProducts));
  },
  setDataPlanCurrentPhonePrefix(state, prefix) {
    state.phoneNumberPrefix = prefix;
  },
  clearDataPlanProducts(state) {
    state.dataPlanProducts = null;
    state.phoneNumberPrefix = '';
    state.operatorName = '';
  },
  clearDataPlanTransaction(state) {
    state.transaction = null;
  },
  setDataPlanOperatorName(state, operator) {
    state.operatorName = operator;
  },
  setDataPlanTransaction(state, transaction) {
    state.transaction = transaction;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  actions,
  mutations,
};
