import AgenliteAPIClient from '~/api';

const initialState = {
  banners: [],
};

const getters = {
  getAllBanners(state) {
    return state.banners;
  },
  getAllResizedBanners(state) {
    return state.banners.map(banner => ({ ...banner, image_url: banner.image_url.replace('%2Foriginal%2F', '%2Flarge%2F') }));
  },
};

const actions = {
  async retrieveAgenliteBanners({ commit, dispatch }, willCommit = true) {
    // dispatch('showAppLoading');
    try {
      const response = await AgenliteAPIClient.retrieveAgenliteBanners();
      if (willCommit) commit('setBanners', response.data);
      return response.data;
    } catch (error) {
      const message = 'Gagal mendapatkan banner.';
      dispatch('catchError', { error, message, hideAppLoading: true, noFlashAlert: true });
      return [];
    }
    // dispatch('hideAppLoading');
  },
};

const mutations = {
  setBanners(state, banners) {
    state.banners = banners;
  },
  resetState(state) {
    state.banners = JSON.parse(JSON.stringify(initialState.banners));
  },
  yieldAggregate(state, bundle) {
    const module = bundle.banner;
    if (!module) return;

    Object.keys(module).forEach(i => {
      state[i] = module[i];
    }); // console.log(i,a[i])) )
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
