import { AgenliteAPI } from '~/api/index';
import RequestManager from '~/api/v4/request-manager';

type DefaultFunctions<W> = { [key: string]: (this: W, ...args: any[]) => any };
export interface APIInterface<
  W extends RequestManager,
  Modules = DefaultFunctions<W>
> {
  Private?: Modules;
  Public?: DefaultFunctions<AgenliteAPI>;
}

type CombinedAPIInterface<R extends RequestManager, ModuleFuncs> = APIInterface<R, ModuleFuncs> & R;

type TypedAPIInterface<W extends RequestManager, ModuleFuncs = DefaultFunctions<W>> =
  object &
  APIInterface<W, ModuleFuncs> &
  ThisType<CombinedAPIInterface<W, ModuleFuncs>>;

type SingleAPIInterface<W extends RequestManager, SingleFuncs = DefaultFunctions<W>> =
  object &
  SingleFuncs &
  ThisType<W & SingleFuncs>;

interface APIModule<W extends RequestManager = RequestManager> {
  wrap<Module>(options: TypedAPIInterface<W, Module>): APIInterface<W, Module>;
  wrap<Module>(options: SingleAPIInterface<W, Module>): Module;
}

export type APIModules = TypedAPIInterface<RequestManager> | SingleAPIInterface<RequestManager>;

export const Client = {
  wrap: obj => obj,
  // wrapSingle: obj => obj,
} as APIModule;


export default Client;
