import AgenliteAPIClient from '~/api';

const initialState = {
  currentTransaction: null,
  motorcycleTransactionLoading: true,
};

const actions = {
  async payMotorcycleInsurance({ dispatch, getters }, requestPayload) {
    const {
      productId,
      policyHolderPhone,
      method: paymentMethod,
      voucherCode,
      finalAmount,
      transactionId,
      type: transactionType,
    } = requestPayload;
    const VOCPayload = { productId, policyHolderPhone };
    const VOCOptionsPayload = { voucherCode, finalAmount, transactionId };
    const userId = getters.getUserId;
    try {
      const response = await AgenliteAPIClient.createVOCTransaction({
        userId,
        transactionType,
        paymentMethod,
        VOCOptionsPayload,
        VOCPayload,
      });
      return response?.data;
    } catch (error) {
      let displayMessage = 'Pembelian Asuransi Motor gagal';
      if (error?.errors) {
        const { message } = error.errors[0];
        displayMessage = message;
      }
      dispatch('catchError', { error, displayMessage, finishTransaction: true });
      throw error;
    }
  },
  async initMotorcycleInsuranceCheckout({ commit, dispatch }, id) {
    try {
      const response = await AgenliteAPIClient.getMotorcycleInsuranceTransaction(id);
      if (!response.data) throw response;
      commit('setMotorcycleInsuranceCurrentTransaction', response.data);
    } catch (err) {
      dispatch('showFlashAlert', {
        message: 'Gagal mendapatkan informasi transaksi asuransi motor.',
        type: 'warning',
      });
    }
  },
};

const mutations = {
  setMotorcycleInsuranceCurrentTransaction(state, payload) {
    state.currentTransaction = payload;
    state.motorcycleTransactionLoading = false;
  },
  setMotorcycleInsuranceTransactionLoading(state, payload) {
    state.motorcycleTransactionLoading = payload;
  },
};

const getters = {
  getMotorcycleInsuranceCurrentTransaction(state) {
    return state.currentTransaction;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
