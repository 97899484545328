import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const RETENTION_URL = `${process.env.API_V4_HOST}/agent-retention/win-big-events`;

export default Client.wrap({
  retrieveActiveEvent() {
    return this.get(`${RETENTION_URL}/my-event`, 'user', new ApiOptions());
  },
  retrieveActiveEventDetail(id) {
    return this.get(`${RETENTION_URL}/${id}/weeks`, 'user', new ApiOptions());
  },
  joinActiveEvent() {
    return this.post(`${RETENTION_URL}/join`, 'user', new ApiOptions());
  }
});
