import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const BASE_URL = `${process.env.API_V4_HOST}/_exclusive/motorcycle-insurances`;

export default Client.wrap({
  Public: {
    async payMotorcycleInsuranceTransaction(userId, transaction, voucherCode, method) {
      const invoiceResponse = await this.registerInvoice(transaction.id, transaction.type, method, voucherCode);
      if (invoiceResponse.meta.http_status !== 201) throw invoiceResponse;

      const paymentResponse = await this.makePayment(userId, invoiceResponse.data.payment_id, method);
      if (paymentResponse.meta.http_status !== 200) throw paymentResponse;
      return {
        invoiceResponse,
        paymentResponse,
      };
    },
  },
  Private: {
    getMotorcycleInsuranceTransaction(id) {
      return this.get(`${BASE_URL}/transactions/${id}`, 'user', new ApiOptions());
    },
  },
});
