import Vue from 'vue';
import { MutationTree, ActionTree, GetterTree } from 'vuex';
import uniqBy from 'lodash/uniqBy';
import AgenliteAPIClient from '~/api/index';

export type ProductItemsState = {
  recentlyAddedProductItem: { id?: number };
  selectedProductItem: { id?: number };
  productItemsOther: { data: Array<{ id: number; qty?: number }>; meta: { total?: number } };
  productItemsFavorite: { data: Array<{ id: number; qty?: number }>; meta: { total?: number } };
  mapSelectedProductItems: { [id: string]: number };
  cacheProductItems: { [id: string]: any };
  hasCreatedProductItem: {};
};

const initialState: ProductItemsState = {
  recentlyAddedProductItem: {},
  selectedProductItem: {},
  productItemsOther: { data: [], meta: {} },
  productItemsFavorite: { data: [], meta: {} },
  mapSelectedProductItems: {},
  cacheProductItems: {},
  hasCreatedProductItem: false,
};

const getters: GetterTree<ProductItemsState, {}> = {
  selectedProductItem: state => state.selectedProductItem,
  mapSelectedProductItems: state => state.mapSelectedProductItems,
  recentlyAddedProductItem: state => ({
    ...state.recentlyAddedProductItem,
    qty: state.recentlyAddedProductItem.id ? state.mapSelectedProductItems[state.recentlyAddedProductItem.id] : 0,
  }),
  allProductItems: (_state, getters) =>
    uniqBy([getters.recentlyAddedProductItem, ...getters.productItems.favorite.data, ...getters.productItems.other.data], 'id'),
  selectedProductItems: (state, _getters) =>
    Object.keys(state.mapSelectedProductItems).map(id => ({
      ...state.cacheProductItems[id],
      qty: state.mapSelectedProductItems[id],
    })),
  productItems: state => {
    const generateProductItems = productItems => ({
      ...productItems,
      isCompleted: productItems.data.length === productItems.meta?.total,
      filteredData: productItems.data.filter(product => product?.id !== state.recentlyAddedProductItem?.id),
    });
    return {
      favorite: generateProductItems(state.productItemsFavorite),
      other: generateProductItems(state.productItemsOther),
    };
  },
  hasCreatedProductItem: state => state.hasCreatedProductItem,
  totalExpenseSelectedProductItems: (_state, getters) =>
    getters.selectedProductItems.reduce((total, product) => total + product.expense * product.qty, 0) || 0,
  totalIncomeSelectedProductItems: (_state, getters) =>
    getters.selectedProductItems.reduce((total, product) => total + product.income * product.qty, 0) || 0,
};

const mutations: MutationTree<ProductItemsState> = {
  setRecentlyAddedProductItem(state, item) {
    state.mapSelectedProductItems[item.id] = 1;
    state.recentlyAddedProductItem = item;
  },
  clearRecentlyAddedProductItem(state, _) {
    state.recentlyAddedProductItem = {};
  },
  setProductItem(state, product) {
    const productItemKey = product?.favorite ? 'productItemsFavorite' : 'productItemsOther';
    const foundIndex = state[productItemKey].data.findIndex(productItem => productItem.id === product.id);
    const newProduct = {
      ...product,
      qty: state.mapSelectedProductItems[product.id!] || product.qty || 0,
    };
    if (foundIndex >= 0) {
      state[productItemKey].data.splice(foundIndex, 1, newProduct);
    } else if (state.recentlyAddedProductItem.id === product.id) {
      state.recentlyAddedProductItem = newProduct;
    } else {
      state[productItemKey].data.push(newProduct);
    }

    Vue.set(state.cacheProductItems, newProduct.id, newProduct);
  },
  removeProductItem(state, id) {
    ;['productItemsFavorite', 'productItemsOther'].forEach(productItemKey => {
      const foundIndex = state[productItemKey].data.findIndex(productItem => productItem.id === id);
      if (foundIndex >= 0) {
        state[productItemKey].data.splice(foundIndex, 1);
      }
    });
  },
  setProductItems(
    state,
    { data = [], meta = { total: 0, offset: 0, limit: 20 }, append = false, favorite = false } = {
      data: [],
      meta: { total: 0, offset: 0, limit: 20 },
      append: false,
      favorite: false,
    }
  ) {
    const productItems = favorite ? 'productItemsFavorite' : 'productItemsOther';
    const newProductItems = append ? [...state[productItems].data, ...data] : data;

    state[productItems].data = newProductItems.map(product => {
      Vue.set(state.cacheProductItems, product.id, product);
      return {
        ...product,
        qty: state.mapSelectedProductItems[product.id!] || product.qty || 0,
      };
    });
    state[productItems].meta = meta;
  },
  setSelectedProductItem(state, item) {
    state.selectedProductItem = item;
  },
  selectProductItem(state, product) {
    if (product.qty) {
      Vue.set(state.mapSelectedProductItems, product.id, product.qty);
    } else {
      delete state.mapSelectedProductItems[product.id];
    }
  },
  clearSelectedProductItem(state, _) {
    state.selectedProductItem = {};
  },
  clearSelectedProductItems(state, _) {
    state.mapSelectedProductItems = {};
    state.cacheProductItems = {};
  },
  setHasCreatedProductItem: (state, val) => {
    state.hasCreatedProductItem = val;
  },
};

const actions: ActionTree<ProductItemsState, {}> = {
  selectProductItem({ commit }, product) {
    commit('selectProductItem', product);
    commit('setProductItem', product);
  },
  async fetchProductItems({ commit, getters }, { name = '', append = false, favorite } = { name: '', append: false }) {
    const requestPayload = {
      favorite,
      name,
      limit: 20,
      offset: append ? getters.productItems({ favorite }).data.length : 0,
    };
    const { data = [], meta = { total: 0, offset: 0, limit: 20 } } = await AgenliteAPIClient.retrieveProductItems(requestPayload);
    commit('setProductItems', { append, data, meta, favorite });
  },
  async fetchProductItem({ commit }, id: number) {
    const { data = {} } = await AgenliteAPIClient.retrieveProductItem(id);
    commit('setSelectedProductItem', data);
  },
  async createProductItem({ commit }, payload) {
    const { data = {} } = await AgenliteAPIClient.createProductItem(payload);
    commit('setRecentlyAddedProductItem', data);
    commit('setProductItem', data);
  },
  async updateProductItem({ commit }, { id, payload }) {
    const { data = {} } = await AgenliteAPIClient.updateProductItem(id, payload);
    commit('setProductItem', data);
  },
  async deleteProductItem({ commit }, id: number) {
    await AgenliteAPIClient.deleteProductItem(id);
    commit('clearSelectedProductItem');
    commit('removeProductItem', id);
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  mutations,
  actions,
};
