import UAParser from 'ua-parser-js';
import Cookies from 'js-cookie';
import md5 from 'md5';

function addExpires() {
  const d = new Date();
  const year = d.getFullYear();
  const month = d.getMonth();
  const day = d.getDate();
  return new Date(year + 20, month, day); // 20 years
}

export function generateBrowserID() {
  const currentBrowserID = Cookies.get('browser_id');
  if (currentBrowserID) return currentBrowserID;
  const newBrowserID = md5(`${Math.random()}-${Date.now()}`);
  const expires = addExpires();
  Cookies.set('browser_id', newBrowserID, {
    expires,
    domain: window.location.hostname.replace(/^.+?\./, '.'),
    path: '/',
  });
  return newBrowserID;
}

const platforms = [
  { value: 'sentinel_wrapper', regex: /AndroidApp/i },
  { value: 'mitralite_bl_android', regex: /(blandroid)/i },
  { value: 'mitralite_bl_ios', regex: /(blios)/i },
  { value: 'mitralite_bl_mitra_android', regex: /BLMitraAndroid/i },
  { value: 'sentinel_pwa', regex: /.*/i },
];

export function getTrackerPlatform(userAgent = window.navigator.userAgent, store) {
  if (store?.getters?.isSaasApp) return 'mitralite_bukumitra';

  return platforms.find(p => p.regex.test(userAgent)).value;
}

export function isBLMitraAndroid(userAgent = window.navigator.userAgent) {
  return /BLMitraAndroid/i.test(userAgent);
}

export function isBLiOS(userAgent = window.navigator.userAgent) {
  return /blios/i.test(userAgent);
}

export function isBLWebView(userAgent = window.navigator.userAgent) {
  return /blandroid|blios|BLMitraAndroid/i.test(userAgent);
}

export function isiOSDevice(userAgent = undefined) {
  if (isBLiOS()) return true;
  const ua = new UAParser(userAgent);
  return ua.getOS().name === 'iOS';
}

export function isMobileView(userAgent = undefined) {
  if (isBLWebView()) return true;
  const ua = new UAParser(userAgent);
  return ua.getDevice().type === 'mobile';
}

export function isBot(userAgent = window.navigator.userAgent) {
  return /^.*(\+https:\/\/developers.google.com\/\+\/web\/snippet\/|ad\smonitoring|adsbot|apex|applebot|archive.org_bot|baiduspider|bingbot|chromeheadless|cloudflare|cloudinary|crawler|curl|discordbot|duckduckbot|embedly|exabot|facebookexternalhit|facebot|flipboard|google|googlebot|gsa-crawler|gurujibot|guzzlehttp|heritrix|ia_archiver|insights|linkedinbot|ltx71|mediapartners|msnbot|odklbot|phantom\.js|phantomjs|pingdom|pinterest|python|rtlnieuws|skypeuripreview|slackbot|slurp|spbot|telegrambot|test\scertificate|testing|tiabot|tumblr |twitterbot|vkshare|web\sscraper|wget|yandexbot|whatsapp|orangebot|smtbot|qwantify|mj12bot|ahrefsbot|seznambot|panscient.com|duckduckgo-favicons-bot|uptimerobot|semrushbot|postman|dotbot|zoominfobot|ifttt|sogou|ru_bot|researchscan|nimbostratus-bot|slack-imgproxy|node-superagent|go-http-client|jersey|dataprovider.com|github-camo|dispatch|checkmarknetwork|screaming frog|whatweb|daum|netcraftsurveyagent|mojeekbot|surdotlybot|springbot).*$/.test(userAgent.toLowerCase());
}

export function getPlatform(userAgent = window.navigator.userAgent) {
  if (!userAgent) return '';
  const testMobile = isMobileView(userAgent);
  const testBot = isBot(userAgent);
  const testDesktop = !testMobile && !testBot;
  if (testDesktop) return 'desktop_web';
  if (testMobile) return 'mobile_web';
  if (testBot) return 'bot';
}

export function getMitraAppVersion() {
  const version = window.navigator?.userAgent?.match(/\w+(?=\s+BLMitraAndroid)/g)?.[0];
  const truncatedVersion = version?.slice(0, 5) ?? 0;
  return Number(truncatedVersion);
}
