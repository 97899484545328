export default {
  trackGrosir(eventType, grosirData, referrerData) {
    const data = {
      // screen_name: this.$route.name,
      value: grosirData.value,
      cart_id: `${grosirData.cartId}`,
      cart_item_ids: grosirData.cartItemIds,
      qty: grosirData.qty,
      voucher_code: grosirData.voucherCode,
    };

    const refData = {
      journey_id: referrerData.journeyId,
      referrer: referrerData.referrerUrl,
    };

    // Extra data is for grosir checkout confirmation
    const extraData = {
      transaction_id: `${grosirData.transactionId}`,
      status: grosirData.status,
    };

    // TODO: track super detail of device spec on Tracker

    const trackingData = {
      evn: `agenlite_grosir_${eventType}`,
      ...data,
      ...(eventType === 'buy' ? refData : null),
      ...(eventType === 'buy' ? extraData : null),
    };
    this.$blTracker.actions.trackActions(trackingData);
  },
  getWholesaleTrackerProducts() {
    return this.getCart.map(cart => ({
      id: cart.product && cart.product.id,
      name: cart.name,
      brand: cart.product && cart.product.brand && cart.product.brand.name,
      category: cart.product && cart.product.category.name,
      price: cart.product.bonus ? 0 : cart.price,
      quantity: cart.quantity,
    }));
  },
  trackWholesaleClick(eventName) {
    this.$gtm.trackEvent({
      event: 'wholesale_click',
      ...this.getTrackerReferrerData,
      event_name: eventName,
    });
  },
  trackWholesaleAddToCart(product = {}) {
    this.$blTracker.actions.trackActions({
      product_item_id: `${product.id}`,
      qty: product.quantity,
      evn: 'agenlite_wholesale_cart',
      ...this.getTrackerReferrerData,
    });
  },
  /**
   *
   * @param {Number} step step defined in GA
   * 1 view_checkout_page
   * 2 opt- shipping_address
   * 3 opt- payment_method (saldo_mitra, mitra_cod)
   * 4 opt- voucher (voucher_name)
   * @param {Object} options: step's option
   */
  trackWholesaleCheckout(step, { option = null } = {}) {
    const checkoutOption = {
      step,
    };
    if (option) checkoutOption.option = option;
    if (step === 1) {
      this.$blTracker.actions.trackActions({
        cart_item_ids: this.getCart.map(c => c.id).join(','),
        product_item_ids: this.getCart.map(c => c.product.id).join(','),
        evn: 'agenlite_wholesale_checkout',
        ...this.getTrackerReferrerData,
      });
    }
  },
  trackSearchProduct(keyword, journeyId) {
    this.$blTracker.actions.trackActions({
      evn: 'agenlite_wholesale_search',
      keyword,
      ...this.getTrackerReferrerData,
      journey_id: journeyId,
    });
  },
  trackViewProduct(productIds, categoryId = '', productStocks = '') {
    this.$blTracker.actions.trackActions({
      evn: 'agenlite_wholesale_product_view',
      product_item_ids: productIds,
      product_item_stocks: productStocks,
      category_id: `${categoryId}`,
      ...this.getTrackerReferrerData,
    });
  },
  trackWholesalePurchase(trxId, cartIds) {
    this.$blTracker.actions.trackActions({
      evn: 'agenlite_wholesale_purchase',
      transaction_id: trxId,
      cart_item_ids: `${cartIds}`,
      ...this.getTrackerReferrerData,
    });
  },
};
