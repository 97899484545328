import { AxiosStatic } from 'axios';
import { IncomingMessage } from 'connect';
import Explanation from '~/utils/promise-explanation';

interface ICredentialIdentity {
  identity: string;
  login: boolean;
}
class CredentialStorage {
  private _identity: string;
  private _login: boolean;
  private _axiosInstance: AxiosStatic;
  private _idExp?: Explanation<ICredentialIdentity>;
  private _webviewLogin: boolean = false;
  private _debounceSize = 1000 * 60;
  readonly credentialsUrl = '/credentials';

  constructor(axios?: AxiosStatic, credentials: ICredentialIdentity = { identity: '', login: false }) {
    if (axios) this._axiosInstance = axios;
    this._identity = credentials.identity;
    this._login = credentials.login;
  }

  /**
   * Current identity stored on this storage. Identity can only be filled by fetchIdentity()
   */
  get identity() {
    return this._identity;
  }

  /**
   * Retrieve identity details from request. Mainly used for server-side (which req is available)
   */
  async getServerIdentity(req: IncomingMessage): Promise<ICredentialIdentity> {
    this._idExp = new Explanation<ICredentialIdentity>(
      Promise.resolve({
        identity: req?.['cookies'].identity,
        login:
          !!req?.['cookies']?.['first_time_register'] || // Dev Mode
          (!!req.headers?.['authorization'] && !!req.headers?.['x-bl-userid']) || // Webview Login
          !!req?.['cookies']?.['user_credentials'], // Normal Login
      })
    );
    const response = await this._idExp.getPromise();
    this._login = response.login;
    this._identity = response.identity;
    return response;
  }

  /**
   * Current login state stored on this storage. Login state can only be filled by fetchIdentity()
   */
  get login() {
    return this._login;
  }

  /**
   * Fetch identity details from /credentials endpoint. Mainly used for client-side (which req is unavailable)
   */
  async fetchIdentity(debounceTime: number = this.debounceSize): Promise<ICredentialIdentity> {
    const currentIdentity: ICredentialIdentity = { identity: this.identity, login: this.login };
    if (!this.axios) throw new ReferenceError('Axios adapter is not initialized');
    if (this._webviewLogin) return currentIdentity; // Skip fetching identity under webview

    const lastFetched = new Date().getTime() - (this._idExp?.finishedAt?.getTime() ?? 0);
    if (this._idExp && (!this._idExp.isFinished || lastFetched <= debounceTime)) return this._idExp.getPromise();
    this._idExp = new Explanation<ICredentialIdentity>(
      (async () => {
        const result = await this.axios.get<ICredentialIdentity>(this.credentialsUrl, {
          withCredentials: true,
        });
        return result.data;
      })()
    );

    const response = await this._idExp.getPromise();
    this._identity = response.identity;
    this._login = response.login;
    return response;
  }

  /**
   * When enabled (true), fetchIdentity() won't fetch /credentials and always use stored identity
   */
  get webviewLogin() {
    return this._webviewLogin;
  }

  /**
   * Tells system that authentication has been made via webview and inject login state & identity
   */
  enableWebviewLogin(identity: string) {
    this._webviewLogin = true;
    this._login = true;
    this._identity = identity;
  }

  /**
   * Axios adapter that will be used to execute credentials. This property is mandaatory
   */
  get axios() {
    return this._axiosInstance;
  }

  set axios(instance) {
    this._axiosInstance = instance;
  }

  /**
   * Sets debounce size (in miliseconds) between identity fetch request
   */
  get debounceSize() {
    return this._debounceSize;
  }

  set debounceSize(ms) {
    this._debounceSize = ms;
  }
}

export default CredentialStorage;
