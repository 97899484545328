<template lang="pug">
div
  .o-layout--fixed-header(
    :class="{ 'o-layout--sticky-header': $route.path === '/home', 'align-title-left': titleAlign === 'left', 'align-title-right': titleAlign === 'right' }"
    :style="`padding-top: ${$route.path === '/home' ? '0' : headerHeight};`"
    v-show="visibleOnWrapper || !isSentinelWrapped"
  )
    .c-header.c-header--white(ref="header" :style="headerStyle" :class="headerClass")
      .c-header__action
        slot(name="action")
          a.c-header__action__btn(name="back" @click="historyBack(false)" :class="actionClass")
            i.c-icon.c-icon--arrow-backward(v-if="actionIcon === 'back'" :style="backwardIcon")
            i.c-icon.c-icon--close(v-if="actionIcon === 'close'")
      slot(name="search")
        .c-header__title.c-header__title--text
          slot(name="title")
            strong Mitra Bukalapak
          slot(name="subtitle")
      .c-header__action(ref="header-second-action" :class="secondActionClass" :style="customActionHeaderStyle")
        slot(name="action2")
        slot(name="action3")
      .c-header__padding
        slot(name="padding")
  BlDialogMv(
    :active.sync="showDialog"
    :title="confirmDialogTitle"
    :confirm-text="confirmText"
    :dismiss-text="dismissText"
    @confirmClick="$emit('dialog-confirm'); historyBack(true)"
    @dismissClick="$emit('dialog-dismiss'); showDialog = false"
  )
    div(slot="description")
      | {{ confirmDialogMessage }}
      slot(name="customDialogContent")
</template>

<script>
import { mapGetters } from 'vuex';
import BlDialogMv from '@bukalapak/bazaar-mweb/dist/components/BlDialogMv';

export default {
  name: 'AppHeader',
  components: {
    BlDialogMv,
  },
  props: {
    isSecondActionNotifMenu: {
      type: Boolean,
      default: false,
    },
    openPadding: {
      type: Boolean,
      default: false,
    },
    hasShadow: {
      type: Boolean,
      default: false,
    },
    hasTabs: {
      type: Boolean,
      default: false,
    },
    useGradientStyle: {
      type: Boolean,
      default: false,
    },
    actionClass: {
      type: String,
      default: '',
    },
    visibleOnWrapper: {
      type: Boolean,
      default: true,
    },
    useNativeBack: {
      type: Boolean,
      default: true,
    },
    titleAlign: {
      type: String,
      default: 'center',
    },
    needConfirmDialog: {
      type: Boolean,
      default: false,
    },
    confirmDialogTitle: {
      type: String,
      default: 'Keluar dari halaman ini?',
    },
    confirmDialogMessage: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: 'Gak jadi',
    },
    dismissText: {
      type: String,
      default: 'Ya, lanjut keluar',
    },
    actionIcon: {
      type: String,
      default: 'back',
    },
    customHeaderStyle: {
      type: Object,
      default: () => ({
        'border-bottom': '1px solid #dddddd',
      }),
    },
    backwardIcon: {
      type: Object,
      default: () => ({}),
    },
    customActionHeaderStyle: {
      type: Object,
      default: () => ({}),
    }
  },
  data: () => ({
    headerHeight: '56px',
    showDialog: false,
  }),
  computed: {
    ...mapGetters(['isAgentKurir']),
    headerStyle() {
      return { height: this.headerHeight, ...this.customHeaderStyle };
    },
    secondActionClass() {
      return this.isSecondActionNotifMenu ? 'c-header__action--right-with-sibling' : 'c-header__action--right';
    },
    hasPaddingSlot() {
      return !!this.$slots.padding;
    },
    headerClass() {
      return {
        'c-header--fixed': this.$route.path !== '/home',
        'c-header--has-shadow': this.hasShadow,
        'u-bg-gradient--red-brand u-fg--white': this.useGradientStyle,
      };
    },
    isSentinelWrapped() {
      return /(AndroidApp|blandroid|blios|BLMitraAndroid)/i.test(window.navigator.userAgent);
    },
    isFromMarketplaceApp() {
      return /(blandroid|blios)/i.test(window.navigator.userAgent);
    },
    hasBackListener() {
      return this.$listeners && this.$listeners.back;
    },
  },
  watch: {
    openPadding() {
      this.calculateHeight();
    },
    showDialog(val) {
      if (this.needConfirmDialog && !val) {
        this.setPreventNavigate();
      }
    },
    needConfirmDialog(val) {
      if (val) this.attachConfirmDialog();
      else this.detachConfirmDialog();
    },
    $route(to, from) {
      if (from.name === to.name && to.hash) {
        window.onpopstate = () => {
          if (window.sessionStorage.getItem('prevent-navigate') === 'true') {
            this.attachConfirmDialog();
          }
        };
      }
    },
  },
  mounted() {
    this.attachConfirmDialog();
    this.calculateHeight();
  },
  beforeDestroy() {
    this.detachConfirmDialog();
  },
  methods: {
    hasSubtitle() {
      return !!this.$slots.subtitle;
    },
    historyBack(force = false) {
      this.$emit('history-back');
      if (force) {
        this.$nativeBridge.topBack();
      }
      // Custom Functions
      if (this.isFromMarketplaceApp) {
        if (window.history.length <= 2 || this.$route.path === '/home') {
          this.$nativeBridge.close();
        } else {
          this.$router.go(-1);
        }
        return;
      }
      // Default back
      if (this.hasBackListener) {
        this.$emit('back');
      } else if (window.history.length <= 1) {
        if (this.$route.path === '/home') {
          this.$nativeBridge.topBack();
          this.$nativeBridge.close();
        } else if (this.$nativeBridge.isAvailable()) {
          this.$nativeBridge.close();
        } else {
          this.$router.replace('/home');
        }
      } else if (this.isSentinelWrapped && this.useNativeBack && this.$nativeBridge.isAvailable()) {
        this.$nativeBridge.topBack();
      } else {
        this.$router.go(-1);
      }
    },
    calculateHeight() {
      this.$nextTick(() => {
        let height = 56;
        if (this.openPadding && this.hasPaddingSlot) {
          height = 92;
        } else if (this.hasTabs) {
          height = 102;
        }

        if (this.hasSubtitle()) {
          this.headerHeight = `${height + this.$slots.subtitle.offsetHeight}px`;
        } else {
          this.headerHeight = `${height}px`;
        }
      });
    },
    showConfirmDialog() {
      this.showDialog = true;
      this.$emit('dialog-show');
    },
    setPreventNavigate() {
      window.sessionStorage.setItem('prevent-navigate', 'true');
      window.history.pushState({ 'prevent-navigate': true }, '');
    },
    async attachConfirmDialog() {
      if (!this.needConfirmDialog) return;
      if (window.sessionStorage.getItem('prevent-navigate') !== 'true') {
        this.setPreventNavigate();
      }
      await this.$nextTick();
      window.onpopstate = () => {
        document.body.style.overflow = 'auto';
        this.showConfirmDialog();
      };
    },
    detachConfirmDialog() {
      window.sessionStorage.removeItem('prevent-navigate');
      window.onpopstate = () => {};
    },
  },
};
</script>

<style lang="scss" scoped>
.c-header {
  &--fixed {
    z-index: 201;
  }

  &--has-shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }

  &__action {
    &__btn {
      position: relative;
    }
  }
}
.o-layout-header--with-padding {
  padding-top: 92px;

  .c-header {
    height: 92px;
  }
}
.u-bg-gradient--red-brand {
  background-image: linear-gradient(244deg, #fd8081, #e73e6d);
}
.align-title-left > .c-header {
  .c-header__title {
    text-align: left;
    margin-left: 56px;
  }
  & > .c-header__action--right {
    top: 18px !important;
    right: 15px !important;
  }
}
.align-title-right > .c-header {
  .c-header__title {
    text-align: right;
    margin-right: 16px;
  }
  & > .c-header__action--right {
    top: 18px !important;
    right: 15px !important;
  }
}
</style>
