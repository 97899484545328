import Client from '~/types/api/api-module';
import { SaasUserEntity, SaasUserPayload } from '~/types/api/saas-user';
import ApiOptions from '../api-options';

const SAAS_USER_URL = `${process.env.API_V4_HOST}/saas-users`;

export default Client.wrap({
  fetchSaasUser() {
    return this.get<SaasUserEntity>(`${SAAS_USER_URL}/me`, 'user', new ApiOptions());
  },
  updateSaasUser(requestPayload: SaasUserPayload) {
    return this.patch<SaasUserEntity>(`${SAAS_USER_URL}/me`, 'user', new ApiOptions({ requestPayload }));
  },
  registerSaasUser(requestPayload: SaasUserPayload) {
    return this.post<SaasUserEntity>(SAAS_USER_URL, 'user', new ApiOptions({ requestPayload }));
  }
});
