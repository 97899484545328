import AgenliteAPIClient from '~/api';

const initialState = {
  phoneCreditProducts: null, // [] === unknown operator
  phoneNumberPrefix: '',
  operatorName: '',
  transaction: null,
};

const moduleGetters = {
  getPhoneCreditProducts(state) {
    return state.phoneCreditProducts;
  },
  getPhoneNumberPrefix(state) {
    return state.phoneNumberPrefix;
  },
  getOperatorName(state) {
    return state.operatorName;
  },
  getPhoneCreditTransaction(state) {
    return state.transaction;
  },
};

function mapData(responseData) {
  return responseData.map(item => ({
    ...item,
    id: item.partner_package.package.id,
    price: item.partner_package.package.price,
    status: item.partner_package.package.status,
    operator_name: item.operator.name,
  }));
}

const actions = {
  // VOC Pay
  async createTransactionPhoneCreditVOC({ dispatch, getters }, requestPayload) {
    const { id, phone, method: paymentMethod, voucherCode, finalAmount, transactionId, type: transactionType } = requestPayload;
    const VOCPayload = { id, phone };
    const VOCOptionsPayload = { voucherCode, finalAmount, transactionId };
    const userId = getters.getUserId;
    try {
      const response = await AgenliteAPIClient.createVOCTransaction({
        userId,
        transactionType,
        paymentMethod,
        VOCOptionsPayload,
        VOCPayload,
      });
      return response?.data;
    } catch (error) {
      let displayMessage = 'Pembelian pulsa gagal';
      if (error?.errors) {
        const { message } = error.errors[0];
        displayMessage = message;
      }
      dispatch('catchError', { error, displayMessage, finishTransaction: true });
      throw error;
    }
  },
  // v4 create transaction
  async createPhoneCreditTransaction({ dispatch, commit }, payload) {
    dispatch('showAppLoading');
    try {
      const response = await AgenliteAPIClient.postPhoneCreditTransaction(payload);
      commit('setPhoneCreditTransaction', response.data);
    } catch (error) {
      let displayMessage = 'Gagal melakukan transaksi pulsa';
      if (error?.errors) {
        const { message } = error.errors[0];
        displayMessage = message;
      }
      dispatch('catchError', { error, displayMessage });
      throw error;
    } finally {
      dispatch('hideAppLoading');
    }
  },
  // v4 fetch phone credit products
  async fetchPhoneCreditProducts({ dispatch, commit }, prefix = '') {
    dispatch('showAppLoading');
    try {
      const response = await AgenliteAPIClient.fetchPhoneCreditProducts({ category: 'pulsa', prefix });
      const productData = mapData(response.data);
      commit('setPhoneCreditProducts', productData);
      commit('setCurrentPhonePrefix', prefix);
      if (productData?.length && productData[0].operator) commit('setOperatorName', productData[0].operator.name);
    } catch (error) {
      let displayMessage = 'Server sedang sibuk';
      if (error?.errors) {
        const { message } = error.errors[0];
        displayMessage = message;
      }
      dispatch('catchError', { error, displayMessage });
      throw error;
    } finally {
      dispatch('hideAppLoading');
    }
  },
  clearPhoneCreditProducts({ commit }) {
    commit('clearPhoneCreditProducts');
  },
  clearPhoneCreditTransaction({ commit }) {
    commit('clearPhoneCreditTransaction');
  },
  setOperatorName({ commit }) {
    commit('setOperatorName');
  },
  setPhoneCreditTransaction({ commit }) {
    commit('setPhoneCreditTransaction');
  },
};

const mutations = {
  setPhoneCreditProducts(state, phoneCreditProducts) {
    state.phoneCreditProducts = JSON.parse(JSON.stringify(phoneCreditProducts));
  },
  setCurrentPhonePrefix(state, prefix) {
    state.phoneNumberPrefix = prefix;
  },
  clearPhoneCreditProducts(state) {
    state.phoneCreditProducts = null;
    state.phoneNumberPrefix = '';
    state.operatorName = '';
  },
  clearPhoneCreditTransaction(state) {
    state.transaction = null;
  },
  setOperatorName(state, operator) {
    state.operatorName = operator;
  },
  setPhoneCreditTransaction(state, transaction) {
    state.transaction = transaction;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  actions,
  mutations,
};
