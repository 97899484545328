import r from '~/utils/render-utils';

// vp related
const PagePostpaidElectricity = () => import(/* webpackChunkName: 'vp-secondary' */ '~/pages/vp/PostpaidElectricity');
const PageBpjs = () => import(/* webpackChunkName: 'vp-secondary' */ '~/pages/vp/Bpjs');
const PagePdam = () => import(/* webpackChunkName: 'vp-secondary' */ '~/pages/vp/Pdam');
const PageMultifinance = () => import(/* webpackChunkName: 'vp-secondary' */ '~/pages/vp/Multifinance');
const PageFeatureInactive = () => import(/* webpackChunkName: 'vp-secondary' */ '~/pages/vp/FeatureInactive');
const KirimUangParentRoute = () => import(/* webpackChunkName: 'vp-secondary' */ '~/pages/ParentRoute');
const PageKirimUangIndex = () => import(/* webpackChunkName: 'vp-secondary' */ '~/pages/KirimUang/Index');
const PageKirimUangSukses = () => import(/* webpackChunkName: 'vp-secondary' */ '~/pages/KirimUang/Success');
const PageBayarBukalapak = () => import(/* webpackChunkName: 'vp-secondary' */ '~/pages/vp/BayarBukalapak');
const PageBpjstkReferral = () => import(/* webpackChunkName: 'vp-secondary' */ '~/pages/vp/BpjstkReferral');
const PageVpOnboarding = () => import(/* webpackChunkName: 'vp-secondary' */ '~/pages/vp/Onboarding');

const PageVpTutorial = () =>
  import(/* webpackChunkName: 'vp-secondary' */ '~/pages/vp/Tutorial');

const vpsecondary = [
  {
    path: '/tagihan-listrik',
    component: r(PagePostpaidElectricity),
    props: true,
    name: 'vp_tagihanlistrik',
  },
  {
    path: '/tagihan-listrik/onboarding',
    component: r(PageVpOnboarding),
    props: route => ({
      productType: 'electricity-postpaid',
      section: route.query.section,
    }),
    name: 'vp_tagihanlistrik_onboarding',
  },
  {
    path: '/air-pdam',
    component: r(PagePdam),
    props: true,
    name: 'vp_pdam',
  },
  {
    path: '/air-pdam/onboarding',
    component: r(PageVpOnboarding),
    props: route => ({
      productType: 'pdam',
      section: route.query.section,
    }),
    name: 'vp_airpdam_onboarding',
  },
  {
    path: '/tagihan-telkom/onboarding',
    component: r(PageVpOnboarding),
    props: route => ({
      productType: 'telkom-postpaid',
      section: route.query.section,
    }),
    name: 'vp_telkompostpaid_onboarding',
  },
  {
    path: '/bpjs',
    component: r(PageBpjs),
    props: true,
    name: 'vp_bpjs',
  },
  {
    path: '/multifinance',
    component: r(PageMultifinance),
    props: true,
    name: 'vp_multifinance',
  },
  {
    path: '/postpaid-inactive',
    component: r(PageFeatureInactive),
    props: true,
    name: 'postpaid_inactive',
  },
  {
    path: '/mass-bill/tutorial',
    component: r(PageVpTutorial),
    props: _ => ({
      tutorialType: 'mass-bill',
    }),
    name: 'vp_massbill_tutorial',
  },
  // Kirim Uang
  {
    path: '/kirim-uang/',
    component: r(KirimUangParentRoute),
    children: [
      {
        path: '',
        component: r(PageKirimUangIndex),
        name: 'vp_sendmoney',
      },
      {
        path: 'sukses',
        component: r(PageKirimUangSukses),
        name: 'vp_sendmoney_success',
      },
    ],
  },
  // Bayar bukalapak
  {
    path: '/bayar-bukalapak',
    component: r(PageBayarBukalapak),
    name: 'vp_bayarbukalapak',
  },
  // BPJSTK Referral
  {
    path: '/bpjstk-referral',
    component: r(PageBpjstkReferral),
    name: 'vp_bpjstkreferral',
  },
];

export default vpsecondary;
