import { promiseAlmost } from '~/utils/promise-utils';

export default async function ({ store, redirect, route }) {
  await promiseAlmost([
    store.dispatch('retrieveWholesaleProductCategories'),
    store.dispatch('retrieveSilkroadAccount'),
    store.dispatch('superWarung/fetchSuperWarungKYC'),
    store.dispatch('superWarung/fetcMitraKYC'),
  ]);
  const currentUser = store.getters['getCurrentUser'];
  const isSuperwarungCategory = store.getters['getWholesaleProductCategories'].some(category => {
    return category.slug === 'super-warung';
  });  
  const hasOnboard =  store.getters['superWarung/getOnboardingStatus'];
  const toggle = store.getters['toggleConfig'];
  const superWarungStatus = store.getters['superWarung/getSuperWarungStatus'];
  const basicKYCStatus = store.getters['superWarung/getBasicKYCStatus'];

  const from = route.query?.from ? `?from=${route.query.from}` : ''; 

  if (!currentUser || !currentUser.address) {
    redirect(`/super-warung/no-address${from}`);
    return;
  }

  if (!currentUser.coverage_availability) {
    redirect(`/super-warung/no-coverage${from}`);
    return;
  }
  
  if (!isSuperwarungCategory) {
    redirect(`/super-warung/no-coverage${from}`);
    return;
  }

  if (!hasOnboard.kyc) {
    redirect(`/super-warung/onboarding${from}`);
    return;
  }
  
  if (!hasOnboard.landing && superWarungStatus === 'approved' && basicKYCStatus === 'verified' && toggle.superWarungLandingPage) {
    redirect(`/super-warung/verified-onboarding${from}`);
  }
}
