import { AppboyBridgeInterface } from '~/plugins/Braze/AppboyBridge';

type Appboy = typeof import('@braze/web-sdk').default;

interface UserConfig {
  id: string;
}

interface BrazeConfig {
  apiKey: string;
  sdkEndpoint: string;
  active: boolean;
  enableLogging: boolean;
}

function subscribeToPushNotifPermission(appboy: Appboy) {
  appboy.subscribeToInAppMessage(inAppMessage => {
    let shouldDisplay = true;
    if (inAppMessage instanceof appboy.ModalMessage) {
      const msgId = inAppMessage.extras['msg-id'];
      if (msgId === 'push-primer') {
        shouldDisplay = appboy.isPushSupported() && !appboy.isPushPermissionGranted() && !appboy.isPushBlocked();
        inAppMessage.buttons[1]?.subscribeToClickedEvent(() => {
          appboy.registerAppboyPushMessages();
          appboy.logCustomEvent('subscribed-to-notification');
        });
      }
    }
    if (shouldDisplay) {
      appboy.display.showInAppMessage(inAppMessage);
    }
  });
}

export interface BrazePlugin {
  instance?: Appboy | AppboyBridgeInterface;
  init(user: UserConfig, config: BrazeConfig): Promise<Appboy | AppboyBridgeInterface | {}>;
}

export const Braze: BrazePlugin = {
  instance: undefined,
  async init(user, config) {
    const isMitraApp = /(BLMitraAndroid)/.test(window.navigator.userAgent);
    if (isMitraApp) {
      this.instance = (await import(/* webpackChunkName: 'braze-bridge' */ '~/plugins/Braze/AppboyBridge')).default;
      return this.instance;
    }
    const appboy = (await import(/* webpackChunkName: 'braze-sdk' */ '@braze/web-sdk')).default;
    try {
      if (!config.apiKey) throw new Error('API_KEY_NOT_FOUND');
      appboy.initialize(config.apiKey, {
        baseUrl: config.sdkEndpoint,
        enableLogging: config.enableLogging,
        manageServiceWorkerExternally: true,
        serviceWorkerLocation: '/sw.js',
      });
      subscribeToPushNotifPermission(appboy);
      if (user?.id && user?.id !== '0') {
        appboy.changeUser(user.id);
      }
      appboy.openSession();
      this.instance = appboy;
      return this.instance;
    } catch (error) {
      console.warn('Failed to initialize Braze.', error);
      return {};
    }
  },
};
