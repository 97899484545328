import { stringify } from 'query-string';
import ApiOptions from '~/api/v4/api-options';
import Client from '~/types/api/api-module';

const BILLING_API_URL = `${process.env.API_PAYMENT_HOST}/v1/payment/billings`;

export default Client.wrap({
  getFeeConfigs(requestPayload) {
    // can't pass requestPayload to api option as it generate query 'payment_methods[]'
    // while the api require query 'payment_methods' (without[]) ¯\_(ツ)_/¯
    return this.get(`${BILLING_API_URL}/fee-configs?${stringify(requestPayload)}`, 'payment.*.*', { enableAuthHeader: true, ...new ApiOptions() });
  },
});
