import { Plugin } from '@nuxt/types';
import referrer from '~/middleware/referrer';
import sales from '~/middleware/sales';
import topupKurir from '~/middleware/topup-kurir';
import tracking from '~/middleware/tracking';

const CSRRunner: Plugin = context => {
  /***
   * This plugin forces some unsupported middleware to be re-executed in client-side at least once on init
   * Be caution, only list middlewares that listed in config/router.js AND you wish it to be run
   * at least once in client-side
   */
  if (context.ssrContext) {
    context.store.commit('setSSR', true);
    return;
  }

  // if page is SSR, we need to trigger these middlewares
  // else, just let client-side execute config/router.js normally
  if (!context.store.state.global.ssr) return;
  referrer(context);
  tracking(context);
  topupKurir(context);
  sales(context);
};

export default CSRRunner;
