export default function ({ store, redirect, route }) {
  const toggle = store.getters['toggleConfig'];
  const toggleMap = {
    'vp_tagihanlistrik': {
      active: toggle.postpaidFeatureElectricity,
    },
    'vp_multifinance': {
      active: toggle.postpaidFeatureMultifinance,
    },
    'vp_bpjs': {
      active: toggle.postpaidFeatureBPJS,
    },
    'vp_pdam': {
      active: toggle.postpaidFeaturePDAM,
    },
  };
  
  if (!toggleMap[route.name].active) {
    redirect(`/postpaid-inactive?feature=${route.path.replace('/', '')}`);
  }
}
