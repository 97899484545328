import AgenliteAPIClient from '~/api';

const initialState = {
  topupToggle: {
    topup_availability: {
      convenience_store: {},
      bank_transfer: {
        channels: {},
      },
      virtual_account: {
        channels: {},
      },
    },
  },
};

const getters = {
  // TODO: Fix priority between && and ||
  // eslint-disable-next-line no-mixed-operators
  topupConfig: state => state.topupToggle && state.topupToggle.topup_availability || initialState.topupToggle.topup_availability,
};

const actions = {
  async requestTopups({ dispatch }, { amount, paymentMethod, channel }) {
    let response = null;
    try {
      response = await AgenliteAPIClient.requestTopups(amount, paymentMethod, channel);
      dispatch('hideFlashAlert');
    } catch (error) {
      let message = 'Pengisian saldo gagal.';
      if (error && error.meta && error.meta.http_status === '606') {
        message = 'Layanan tambah saldo sedang tidak tersedia';
        dispatch('catchError', { message, hideAppLoading: true });
        throw error;
      }
      dispatch('catchError', { error, message, hideAppLoading: true });
      throw error;
    }
    return response;
  },
  async requestTopupVA({ dispatch }, { bank, amount = 0 }) {
    let response = null;
    try {
      response = await AgenliteAPIClient.requestTopupVA(bank, { amount });
      dispatch('hideFlashAlert');
    } catch (error) {
      const message = 'Gagal mendapatkan nomor virtual account.';
      dispatch('catchError', { error, message });
      throw error;
    }
    return response;
  },
  async retrieveTopup({ dispatch }, { topupId }) {
    let response = null;
    try {
      response = await AgenliteAPIClient.retrieveTopup(topupId);
      dispatch('hideFlashAlert');
    } catch (error) {
      const message = 'Gagal mendapatkan data topup.';
      dispatch('catchError', { error, message });
      throw error;
    }
    return response;
  },
  async fetchTopupToggle({ dispatch, commit }) {
    let response = null;
    try {
      response = await AgenliteAPIClient.fetchMitraAppsConfiguration();
      commit('setTopupToggle', response.data.toggle.topup_availability);
      dispatch('hideFlashAlert');
    } catch (error) {
      const message = 'Gagal mendapatkan toggle topup.';
      dispatch('catchError', { error, message, noFlashAlert: true });
    }
    return response;
  },
};

const mutations = {
  setTopupToggle(state, topupToggle) {
    state.topupToggle.topup_availability = topupToggle;
  },
  resetState(state) {
    state.topupToggle = JSON.parse(JSON.stringify(initialState.topupToggle));
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
