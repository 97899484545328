const initialState = {
  isAppLoading: false,
};

const getters = {
  isAppLoading(state) {
    return state.isAppLoading;
  },
};

const actions = {
  showAppLoading({ commit, state }) {
    if (!state.isAppLoading) commit('setAppLoading', true);
  },
  hideAppLoading({ commit, state }) {
    if (state.isAppLoading) commit('setAppLoading', false);
  },
};

const mutations = {
  setAppLoading(state, value) {
    state.isAppLoading = value;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
