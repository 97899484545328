import API from '~/api';

export default async function ({ route, redirect }) {
  const routeMatcher = [
    { condition: '/kurir', path: 'kurir/login' },
    { condition: '/sales', path: 'sales/login' },
    { condition: '/', path: 'login' },
  ];
  const redirRule = routeMatcher.find(matcher => route.path === matcher.condition) || { path: 'login' };
  const redirPath = `/logout?to=${redirRule.path}`; // TODO: after PWA login is opened, directly go to login instead to logout

  const { login } = await API.credentials.fetchIdentity();
  if (!login) {
    console.warn('Authentication failed. Logging out user...');
    redirect(redirPath);
    return;
  }
  if (process.client) {
    try {
      await API.fetchTokenV4();
    } catch (err) {
      API.destroyV4Token();
      console.warn('Bearer token failed.', err);
      redirect(redirPath);
    }
  }
}
