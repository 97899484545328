import Client from '~/types/api/api-module';
import { PrepaidProductGroup } from '~/types/api/prepaid-product-group';
import ApiOptions from '../api-options';

const API_URL = `${process.env.API_V4_HOST}/electricities`;

export interface TransactionPayload {
  id: number;
  type: any;
  customer_number: string;
}
export interface PrepaidPayload {
  transaction: TransactionPayload;
  method: string;
  voucherCode: string;
}

export default Client.wrap({
  Public: {
    /** @deprecated */async payPrepaidElectricity(userId: number, customerPhone: string, { transaction, method, voucherCode }: PrepaidPayload): Promise<{ paymentResponse; invoiceResponse }> {
      const { customer_number: customerNumber, id, type } = transaction;
      const invoiceResponse = await this.registerInvoice(id, type, method, voucherCode);
      if (invoiceResponse && invoiceResponse.meta && invoiceResponse.meta.http_status !== 201) throw invoiceResponse;

      if (customerPhone !== '') { this.createAgentTransactionDetail(invoiceResponse.data.payment_id, customerNumber, customerPhone); }

      const paymentResponse = await this.makePayment(userId, invoiceResponse.data.payment_id, method);
      if (paymentResponse.meta.http_status !== 200) throw paymentResponse;
      return {
        paymentResponse,
        invoiceResponse,
      };
    },
  },
  Private: {
    getElectricityPrepaidProducts(params: PrepaidProductGroup) {
      return this.get(`${API_URL}/prepaid-products`, 'user', new ApiOptions({ requestPayload: params }));
    },
    getElectricityPrepaidTransaction(id: number) {
      return this.get(`${API_URL}/prepaid-transactions/${id}`, 'user');
    },
    postElectricityPrepaidInquiry(customerNumber: string) {
      const requestHeaders = { Accept: 'application/json' };
      const requestPayload = { customer_number: customerNumber };
      return this.post(`${API_URL}/prepaid-inquiries`, 'public', new ApiOptions({ requestPayload, requestHeaders }));
    },
    /** @deprecated **/ postElectricityPrepaidTransaction(requestPayload: Record<string, any>) {
      const requestHeaders = { Accept: 'application/json' };
      return this.post(`${API_URL}/prepaid-transactions`, 'user', new ApiOptions({ requestPayload, requestHeaders }));
    },
  },
});
