import AgenliteAPIClient from '~/api';

const initialState = {
  bpjsInfo: null,
  transaction: null,
};

const moduleGetters = {
  getBpjsInfo(state) {
    return state.bpjsInfo;
  },
  getBpjsTransaction: state => state.transaction,
};

const actions = {
  async createBpjsTransaction({ dispatch, commit }, { customerNumber, year, month }) {
    dispatch('showAppLoading');
    try {
      const response = await AgenliteAPIClient.postBpjsTransaction(customerNumber, year, month);
      dispatch('hideAppLoading');
      commit('setBpjsTransaction', response.data);
      return response.data;
    } catch (error) {
      const message = 'Gagal melakukan transaksi bpjs';
      dispatch('catchError', { error, message, hideAppLoading: true });
      throw error;
    }
  },
  fetchBpjsInfo({ commit, dispatch }, { customerNumber, year, month }) {
    dispatch('showAppLoading');
    dispatch('clearBpjsInfo');
    return new Promise((resolve, reject) => {
      AgenliteAPIClient.postBpjsInquiry(customerNumber, year, month)
        .then(response => {
          dispatch('hideAppLoading');
          dispatch('hideFlashAlert');
          commit('setBpjsInfo', response.data);
          resolve();
        })
        .catch(error => {
          const message = 'Gagal mendapatkan data BPJS.';
          if (error && error.errors && error.errors[0].code === 18102) {
            error.message = 'Maaf, nomornya salah. Coba cek lagi ya.';
            dispatch('hideAppLoading');
            return reject(error);
          }
          dispatch('catchError', { error, message, hideAppLoading: true });
          return reject(error);
        });
    });
  },
  payBpjs({ dispatch, getters, commit }, params) {
    dispatch('showAppLoading');
    return new Promise((resolve, reject) => {
      AgenliteAPIClient.payBpjsTransaction(getters.getUserId, params)
        .then(response => {
          dispatch('hideFlashAlert');
          const mergedResponse = { ...response, transactionResponse: getters.getBpjsTransaction };
          commit('setBpjsTransaction', null);
          resolve(mergedResponse);
        })
        .catch(error => {
          const message =
            'Pembayaran BPJS gagal. Cek <a href="/transaksi">daftar transaksi</a> untuk melanjutkan pembayaran sebelum mengulang transaksi.';
          dispatch('catchError', { error, message, finishTransaction: true });
          reject(error);
        });
    });
  },
  clearBpjsInfo({ commit }) {
    commit('setBpjsInfo', null);
  },
};

const mutations = {
  setBpjsInfo(state, bpjsInfo) {
    state.bpjsInfo = JSON.parse(JSON.stringify(bpjsInfo));
  },
  setBpjsTransaction(state, transaction) {
    state.transaction = transaction;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  actions,
  mutations,
};
