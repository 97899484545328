const initialState = {
  hasChangedTab: false,
  hasShownNewOnboarding: false,
  hasShownPaymentLinkTooltip: false,
  hasShownBookkeepingCoachmark: false,
  hasClickedPaymentLinkOnboardingCta: false,
  hasShownBookkeepingUnpaidTrxCoachmark: false,
  hasClosedJatuhTempoAnnouncement: false,
  newestPromotionMediaPosters: [],
};

const getters = {
  'bookkeepingOnboarding/hasChangedTab': state => state.hasChangedTab,
  'bookkeepingOnboarding/shouldShowNewOnboarding': state => !state.hasShownNewOnboarding,
  'bookkeepingOnboarding/shouldShowPaymentLinkTooltip': state => !state.hasShownPaymentLinkTooltip,
  'bookkeepingOnboarding/shouldShowBookkeepingCoachmark': state => !state.hasShownBookkeepingCoachmark,
  'bookkeepingOnboarding/shouldShowPaymentLinkOnboarding': state => !state.hasClickedPaymentLinkOnboardingCta,
  'bookkeepingOnboarding/shouldShowBookkeepingUnpaidTrxCoachmark': state => !state.hasShownBookkeepingUnpaidTrxCoachmark,
  'bookkeepingOnboarding/hasClosedJatuhTempoAnnouncement': state => state.hasClosedJatuhTempoAnnouncement,
  'isNewestPromotionMediaPoster': state => id => state?.newestPromotionMediaPosters?.findIndex(posterId => posterId === id) >= 0
};

const mutations = {
  'bookkeepingOnboarding/setHasChangedTab': (state, val) => {
    state.hasChangedTab = val;
  },
  'bookkeepingOnboarding/setHasShownNewOnboarding': (state, val) => {
    state.hasShownNewOnboarding = val;
  },
  'bookkeepingOnboarding/setHasShownBookkeepingCoachmark': (state, val) => {
    state.hasShownBookkeepingCoachmark = val;
  },
  'bookkeepingOnboarding/setHasShownBookkeepingUnpaidTrxCoachmark': (state, val) => {
    state.hasShownBookkeepingUnpaidTrxCoachmark = val;
  },
  'bookkeepingOnboarding/setHasShownPaymentLinkTooltip': (state, val) => {
    state.hasShownPaymentLinkTooltip = val;
  },
  'bookkeepingOnboarding/setHasClickedPaymentLinkOnboardingCta': (state, val) => {
    state.hasClickedPaymentLinkOnboardingCta = val;
  },
  'bookkeepingOnboarding/hasClosedJatuhTempoAnnouncement': (state, val) => {
    state.hasClosedJatuhTempoAnnouncement = val;
  },
  addNewestPromotionMediaPoster: (state, id) =>  {
    if (state?.newestPromotionMediaPosters.find(posterId => posterId === id)) return;
    state?.newestPromotionMediaPosters.push(id);
  },
  removeNewestPromotionMediaPoster: state =>  {
    state.newestPromotionMediaPosters = [];
  }
};

export default {
  state: () => ({ ...initialState }),
  mutations,
  getters,
  actions: {},
};
