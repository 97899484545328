import ApiOptions from '../api-options';

const GENERAL_TRADE_URL = `${process.env.API_V4_HOST}/general-trade`;
const PRODUCT_CATEGORY_URL = `${GENERAL_TRADE_URL}/categories`;

export default {
  retrieveProductCategories(parentId) {
    let url = PRODUCT_CATEGORY_URL;
    if (parentId) {
      url += `?parent_id=${parentId}`;
    }
    return this.get(url, 'user', new ApiOptions());
  },
};
