import credentialStorageInstance from '~/api/credential-storage-instance';
import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const INVOICE_URL = `${process.env.API_HOST}/invoices`;
const CHECK_VOUCHER_URL = `${INVOICE_URL}/vouchers/checks`;

const generateHeaders = () => ({
  'Bukalapak-Identity': credentialStorageInstance.identity,
});
export default Client.wrap({
  registerInvoice(transactionId, transactionType, paymentType = 'wallet', voucherCode?: string) {
    const requestPayload = {
      transactions: [
        {
          id: transactionId,
          type: transactionType,
        },
      ],
      payment_type: paymentType,
      ...(voucherCode && { voucher_code: voucherCode }),
    };
    return this.post(INVOICE_URL, 'user', new ApiOptions({ requestHeaders: generateHeaders(), requestPayload }));
  },
  updateInvoice(id, paymentType) {
    const requestPayload = {
      payment_type: paymentType,
    };
    return this.patch(`${INVOICE_URL}/${id}`, 'user', new ApiOptions({ requestPayload }));
  },
  getInvoice(id) {
    return this.get(`${INVOICE_URL}/${id}`, 'user', new ApiOptions());
  },
  getInvoices(params) {
    return this.get(INVOICE_URL, 'user', new ApiOptions({ requestPayload: params }));
  },
  checkVoucher(requestPayload) {
    return this.post(CHECK_VOUCHER_URL, 'user', new ApiOptions({ requestHeaders: generateHeaders(), requestPayload }));
  },
});
