// import AgenliteAPIClient from '~/api';
import SilkroadAPIClient from '~/api/silkroad';

const initialState = {
  currentPromo: null,
  currentProducts: [],
  meta: null,
  isAvailable: false,
};

const getters = {
  getCurrentSpecialPromo(state) {
    return state.currentPromo;
  },
  getCurrentSpecialPromoProducts(state) {
    return state.currentProducts;
  },
  getSpecialPromoMeta(state) {
    return state.meta;
  },
  isSpecialPromoAvailableForUser(state) {
    return state.isAvailable;
  },
};

const actions = {
  clearCurrentSpecialPromo({ commit }) {
    commit('clearCurrentSpecialPromo');
  },
  async retrieveSpecialPromoInfo({ dispatch, commit }, id) {
    commit('clearCurrentSpecialPromo');
    commit('setIsSpecialPromoAvailableForUser', false);

    try {
      const response = await SilkroadAPIClient.getSpecialPromo(id);

      if (response.data) {
        commit('setIsSpecialPromoAvailableForUser', true);
        commit('setCurrentSpecialPromo', response.data);
      }
    } catch (err) {
      const message = 'Gagal mendapatkan data promo.';
      dispatch('catchError', { err, message, hideAppLoading: true });
      this.$router.push('/');
    }
  },
  async retrieveSpecialPromoProducts({ dispatch, commit }, { id, limit, offset, append }) {
    try {
      const response = await SilkroadAPIClient.retrieveProducts({
        banner_id: id, limit, offset,
      });
      if (append) {
        commit('appendCurrentSpecialPromoProducts', response.data);
      } else {
        commit('setCurrentSpecialPromoProducts', response.data);
      }
      commit('setSpecialPromoMeta', response.meta);
    } catch (err) {
      const message = 'Gagal mendapatkan data promo.';
      dispatch('catchError', { err, message, hideAppLoading: true });
      this.$router.push('/');
    }
  },
};

const mutations = {
  setCurrentSpecialPromo(state, promo) {
    state.currentPromo = promo;
  },
  clearCurrentSpecialPromo(state) {
    state.currentPromo = null;
  },
  setCurrentSpecialPromoProducts(state, products) {
    state.currentProducts = products;
  },
  appendCurrentSpecialPromoProducts(state, products) {
    state.currentProducts = [...state.currentProducts, ...products];
  },
  setSpecialPromoMeta(state, meta) {
    state.meta = meta;
  },
  setIsSpecialPromoAvailableForUser(state, isAvailable) {
    state.isAvailable = isAvailable;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
