import r from '~/utils/render-utils';

const Guide = () => import(/* webpackChunkName: 'guide' */ '~/pages/Guide/index');
const GuideLanding = () => import(/* webpackChunkName: 'guide' */ '~/pages/Guide/GuideLanding');
const GuideCategory = () => import(/* webpackChunkName: 'guide' */ '~/pages/Guide/GuideCategory');
const GuideContent = () => import(/* webpackChunkName: 'guide' */ '~/pages/Guide/GuideContent');
const GuideTransaction = () => import(/* webpackChunkName: 'guide' */ '~/pages/Guide/GuideTransaction');
const GuideTrendOthers = () => import(/* webpackChunkName: 'guide' */ '~/pages/Guide/GuideTrendOthers');

const GuideBayarAngsuranKredit = () =>
  import(/* webpackChunkName: 'static-guide' */ '~/pages/Guide/GuideBayarAngsuranKredit');
const GuideBayarBPJS = () => import(/* webpackChunkName: 'static-guide' */ '~/pages/Guide/GuideBayarBPJS');
const GuideBayarListrik = () => import(/* webpackChunkName: 'static-guide' */ '~/pages/Guide/GuideBayarListrik');
const GuideBayarPDAM = () => import(/* webpackChunkName: 'static-guide' */ '~/pages/Guide/GuideBayarPDAM');
const GuideBeliPaketData = () => import(/* webpackChunkName: 'static-guide' */ '~/pages/Guide/GuideBeliPaketData');
const GuideBeliPulsa = () => import(/* webpackChunkName: 'static-guide' */ '~/pages/Guide/GuideBeliPulsa');
const GuideInstalApp = () => import(/* webpackChunkName: 'static-guide' */ '~/pages/Guide/GuideInstalApp');
const GuideKirimUang = () => import(/* webpackChunkName: 'static-guide' */ '~/pages/Guide/GuideKirimUang');
const GuideSyarat = () => import(/* webpackChunkName: 'static-guide' */ '~/pages/Guide/GuideSyarat');
const GuideTambahSaldo = () => import(/* webpackChunkName: 'static-guide' */ '~/pages/Guide/GuideTambahSaldo');
const GuideTokenListrik = () => import(/* webpackChunkName: 'static-guide' */ '~/pages/Guide/GuideTokenListrik');
const GuideVerifikasi = () => import(/* webpackChunkName: 'guide' */ '~/pages/Guide/GuideVerifikasi');

const MiniFAQLanding = () => import(/* webpackChunkName: 'mini-faq'  */ '~/pages/Guide/MiniFAQLanding');

const routes = [
  {
    path: '/panduan',
    component: r(Guide),
    children: [
      {
        path: '',
        component: r(GuideLanding),
        name: 'guidance',
      },
      {
        path: 'artikel',
        redirect: { name: 'guidance_category' },
      },
      {
        path: 'kategori',
        component: r(GuideCategory),
        name: 'guidance_category',
      },
      {
        path: 'artikel/:id',
        component: r(GuideContent),
        name: 'guidance_content',
      },
      {
        path: 'pilih-transaksi',
        component: r(GuideTransaction),
        name: 'guidance_transaction',
      },
      {
        path: 'lainnya',
        component: r(GuideTrendOthers),
        name: 'guidance_trend_others',
      },
      // Still have some traffic, unknown entry point, maybe from app
      {
        path: 'verifikasi',
        component: r(GuideVerifikasi),
        name: 'guide_verifikasi',
      },
      {
        path: 'kirim-uang',
        component: r(GuideKirimUang),
        name: 'guide_kirim_uang',
      },
      {
        path: 'instal-app',
        component: r(GuideInstalApp),
        name: 'guide_instal_app',
      },
      // Still used in grocery cart feature
      {
        path: 'syarat-mitra',
        component: r(GuideSyarat),
        name: 'syarat_mitra',
      },
      // Still used in tambah saldo feature
      {
        path: 'tambah-saldo',
        component: r(GuideTambahSaldo),
        name: 'guide_tambah_saldo',
      },
      // Still used in onboarding vp feature
      {
        path: 'beli-pulsa',
        component: r(GuideBeliPulsa),
        name: 'guide_beli_pulsa',
      },
      {
        path: 'beli-paket-data',
        component: r(GuideBeliPaketData),
        name: 'guide_beli_paket_data',
      },
      {
        path: 'token-listrik',
        component: r(GuideTokenListrik),
        name: 'guide_token_listrik',
      },
      {
        path: 'bayar-listrik',
        component: r(GuideBayarListrik),
        name: 'guide_bayar_listrik',
      },
      {
        path: 'bayar-pdam',
        component: r(GuideBayarPDAM),
        name: 'guide_bayar_pdam',
      },
      {
        path: 'bayar-bpjs',
        component: r(GuideBayarBPJS),
        name: 'guide_bayar_bpjs',
      },
      {
        path: 'bayar-angsuran-kredit',
        component: r(GuideBayarAngsuranKredit),
        name: 'guide_bayar_angsuran_kredit',
      },
      // current mini_faq_landing is only for pulsa and will move it to separate file if feature get bigger
      {
        path: 'mini-faq',
        component: r(MiniFAQLanding),
        name: 'mini_faq_landing',
        props: true,
      },
    ],
  },
];

export default routes;
