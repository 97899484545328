import AgenliteAPIClient from '~/api';

const initialState = {
  searchParams: {
    from: null,
    date: null,
    returnDate: null,
    isHavingReturnTrip: false,
    adult: 1,
    infant: 0,
  },
  transactionParams: {
    step: 'departure',
  },
  currentTransaction: null,
};

const actions = {
  async payTrain({ dispatch, getters }, { voucherCode = null, method } = {}) {
    dispatch('showAppLoading');
    try {
      const response = await AgenliteAPIClient.payTrainTransaction(
        getters.getUserId,
        getters.getTrainCurrentTransaction,
        voucherCode,
        method,
      );
      dispatch('hideAppLoading');
      return response;
    } catch (error) {
      const message =
        'Pembayaran Tiket Kereta gagal. Cek <a href="/transaksi">daftar transaksi</a> untuk melanjutkan pembayaran sebelum mengulang transaksi.';
      dispatch('catchError', { error, message, finishTransaction: true });
      throw error;
    }
  },
  async changeTrainSeat({ dispatch }, payload) {
    try {
      const response = await AgenliteAPIClient.patchTrainChangeSeat(payload);
      return response.data;
    } catch (error) {
      dispatch('catchError', {
        error,
        message: 'Tidak dapat merubah kursi pesanan tiket kereta api.',
        hideAppLoading: true,
      });
      throw error;
    }
  },
  async cancelTrainTransaction({ dispatch }, trxId) {
    try {
      const response = await AgenliteAPIClient.patchTrainCancelBooking(trxId);
      return response.data;
    } catch (error) {
      dispatch('catchError', { error, message: 'Tidak dapat membatalkan tiket kereta api.', hideAppLoading: true });
      throw error;
    }
  },
  async createTrainTransaction({ dispatch }, payload) {
    try {
      const response = await AgenliteAPIClient.postTrainRegisterTransaction(payload);
      dispatch('setTrainCurrentTransaction', response.data);
      return response.data;
    } catch (error) {
      dispatch('catchError', {
        error,
        message: 'Tidak dapat melakukan transaksi pada kereta api.',
        hideAppLoading: true,
      });
      throw error;
    }
  },
  async retrieveTrainStations({ dispatch }) {
    try {
      const response = await AgenliteAPIClient.getTrainStations();
      return response.data;
    } catch (err) {
      dispatch('catchError', { err, message: 'Tidak dapat mendapatkan data kota dan stasiun', hideAppLoading: true });
      throw err;
    }
  },
  async retrieveTrainPopularDestinations({ dispatch }) {
    try {
      const response = await AgenliteAPIClient.getTrainPopularDestinations();
      return response.data;
    } catch (err) {
      dispatch('catchError', { err, message: 'Tidak dapat mendapatkan data destinasi populer', hideAppLoading: true });
      throw err;
    }
  },
  async retrieveTrainSchedules({ dispatch }, payload) {
    dispatch('showAppLoading');
    try {
      const response = await AgenliteAPIClient.getTrainSchedules(payload);
      dispatch('hideAppLoading');
      return response.data;
    } catch (err) {
      const message = err.message ? err.message : 'Gagal menghubungkan ke server.';
      dispatch('catchError', { err, message, hideAppLoading: true });
      throw err;
    }
  },
  async retrieveTrainSeatMap({ dispatch }, requestPayload) {
    dispatch('showAppLoading');
    try {
      const response = await AgenliteAPIClient.getTrainSeatMap(requestPayload);
      dispatch('hideAppLoading');
      return response.data;
    } catch (error) {
      const message = error.message ? error.message : 'Gagal mendapatkan informasi tempat duduk';
      dispatch('catchError', { error, message, hideAppLoading: true });
      throw error;
    }
  },
  setTrainSearchParams({ commit }, payload) {
    commit('setTrainSearchParams', payload);
  },
  setTrainTransactionParams({ commit }, payload) {
    commit('setTrainTransactionParams', payload);
  },
  setTrainCurrentTransaction({ commit }, payload) {
    commit('setTrainCurrentTransaction', payload);
  },
};

const mutations = {
  setTrainCurrentTransaction(state, payload) {
    state.currentTransaction = payload;
  },
  setTrainSearchParams(state, payload) {
    state.searchParams = payload;
  },
  setTrainTransactionParams(state, payload) {
    state.transactionParams = payload;
  },
  resetState(state) {
    state.searchParams = JSON.parse(JSON.stringify(initialState.searchParams));
    state.transactionParams = JSON.parse(JSON.stringify(initialState.transactionParams));
  },
};

const getters = {
  getTrainSearchParams(state) {
    return state.searchParams;
  },
  getTrainTransactionParams(state) {
    return state.transactionParams;
  },
  getTrainCurrentTransaction(state) {
    return state.currentTransaction;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
