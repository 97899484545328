import AgenliteAPIClient from '~/api';
import SilkroadAPIClient from '~/api/silkroad';
import SharedConfig from '~/plugins/SharedConfig';

const initialState = {
  currentAgent: null,
  currentUser: null,
  coordinatorInvoice: null,
  showWholesaleModal: false,
  showOosReminderModal: false,
  currentAgentType: null,
  showNewAgentTypeModal: false,
};

const moduleGetters = {
  getCurrentAgent(state) {
    return state.currentAgent;
  },
  // Silkroad user
  getCurrentUser(state) {
    return state.currentUser;
  },
  // Bukalapak user
  getCurrentBukaUser(state) {
    return state.currentBukaUser;
  },
  getCurrentUserPhone(state) {
    return (state.currentBukaUser?.phone || '').replace('62', '0');
  },
  getUserAddressString(state) {
    if (state.currentUser && state.currentUser.address && state.currentUser.address.province) {
      let districtStr = '';
      if (state.currentUser.address.district) {
        districtStr = `, ${state.currentUser.address.district}`;
      }
      return `${state.currentUser.address.address}${districtStr}, ${state.currentUser.address.area}, ${state.currentUser.address.city}, ${state.currentUser.address.province}, ${state.currentUser.address.post_code}.`;
    }
    return '';
  },
  getAgentKycStatus(state) {
    return state.currentAgent ? state.currentAgent.kyc_status : '';
  },
  isAgentShouldDoKyc(state, getters) {
    if (!state.currentAgent) {
      return false;
    }
    const isAgentNonKyc = state.currentAgent.kyc_status === 'NonKyc';
    const kycDate = new Date(SharedConfig.kycReleaseDate).getTime();
    const joinDate = new Date(state.currentAgent.joined_at).getTime();
    const isJoinedBeforeKyc = joinDate < kycDate;
    const isOrganicAgent = state.currentAgent.organic;

    return (
      isAgentNonKyc &&
      !state.currentAgent.coordinator &&
      !(state.currentAgent.referrer && state.currentAgent.referrer.coordinator && isJoinedBeforeKyc) &&
      (!getters.toggleConfig.whitelistAll || !isOrganicAgent)
    );
  },
  isAgentNonKyc(state) {
    return state.currentAgent ? state.currentAgent.kyc_status === 'NonKyc' : false;
  },
  isAgentVerified(state) {
    return state.currentAgent ? state.currentAgent.kyc_status === 'Verified' : false;
  },
  isOrganicAgent(state) {
    return state.currentAgent && state.currentAgent.organic;
  },
  isAgentIndividualType(state) {
    return state.currentAgent && state.currentAgent.agent_type === 'perseorangan';
  },
  isAgentBusinessType(state) {
    return state.currentAgent && state.currentAgent.agent_type !== 'perseorangan';
  },
  getCurrentAgentUsername(state) {
    if (state.currentAgent !== null && typeof state.currentAgent.username !== 'undefined') {
      return state.currentAgent.username;
    }
    return '...';
  },
  getCurrentAgentName(state) {
    if (state.currentAgent !== null && typeof state.currentAgent.name !== 'undefined') {
      return state.currentAgent.name;
    }
    return '...';
  },
  getWarehouseMaxHour(state) {
    if (state.currentUser && Array.isArray(state.currentUser.warehouses) && state.currentUser.warehouses.length > 0) {
      return state.currentUser.warehouses[0].seller === 'tigaraksa' ? 21 : 15;
    }
    return 15;
  },
  getCurrentAgentSaldo(state) {
    if (state.currentAgent !== null && typeof state.currentAgent.dompet !== 'undefined') {
      return state.currentAgent.dompet.saldo;
    }
    return 0;
  },
  transferToDanaAmount(state) {
    if (!state.currentAgent || !state.currentAgent.dompet) return 0;
    const saldo = state.currentAgent.dompet.saldo_dompet;
    if (saldo < 20000) {
      return 0;
    }
    if (saldo < 200000) {
      if (saldo % 2 === 0) {
        return saldo / 2;
      }
      return Math.floor(saldo / 2);
    }
    return 100000;
  },
  isAgentCoordinator(state) {
    return state.currentAgent ? state.currentAgent.coordinator : false;
  },
  isAgentKurir(state) {
    return state.currentAgent ? state.currentAgent.driver : false;
  },
  isAgentLoanPotential(state) {
    return state.currentAgent ? state.currentAgent.show_loan_agreement : false;
  },
  isAgentLoanable(state) {
    return state.currentAgent && state.currentAgent.loan_status ? state.currentAgent.loan_status : false;
  },
  getLoan(state) {
    if (state.currentAgent === null || typeof state.currentAgent.username === 'undefined') return 0;
    return state.currentAgent.dompet.saldo <= 0
      ? (state.currentAgent.loan_limit - state.currentAgent.dompet.saldo) * -1
      : state.currentAgent.loan_limit * -1;
  },
  getLoanLimit(state) {
    if (state.currentAgent === null || typeof state.currentAgent.username === 'undefined') return 0;
    return state.currentAgent.loan_limit * -1;
  },
  getLoanState(state) {
    if (state.currentAgent === null || typeof state.currentAgent.username === 'undefined') return 0;
    return state.currentAgent.loan_notification_state;
  },
  getCoordinatorInvoice(state) {
    return state.coordinatorInvoice;
  },
  showWholesaleModal(state) {
    return state.showWholesaleModal;
  },
  showOosReminderModal(state) {
    return state.showOosReminderModal;
  },
  getCurrentAgentType(state) {
    return state?.currentAgent?.['agent_type'] ?? null;
  },
  getCurrentAgentTypeDisplay(state) {
    return state?.currentAgent?.['display_agent_type'] ?? '';
  },
  showNewAgentTypeModal(state) {
    return state.showNewAgentTypeModal;
  },
};

const actions = {
  async retrieveCurrentAgent({ commit, dispatch, getters }, { willCommit = true } = {}) {
    try {
      const currentAgent = (await AgenliteAPIClient.retrieveCurrentAgent()).data;
      if (willCommit) commit('retrieveCurrentAgent', currentAgent);
      if (currentAgent) {
        dispatch('setToggleConfig', {
          danaFeature: getters.toggleConfig?.danaFeature && !currentAgent.coordinator,
        });
      }
      if (currentAgent.status === 'deactivated') throw new Error('DEACTIVATED_AGENT');
      return currentAgent;
    } catch (error) {
      const message = 'Gagal mendapatkan data mitra.';
      if (error && error.errors && error.errors[0].code === 10201) {
        throw new Error('NOT_AN_AGENT');
      } else if (error.message) {
        throw new Error(error.message);
      }
      dispatch('catchError', {
        error,
        message,
        hideAppLoading: true,
      });
      throw new Error('FAILED_TO_FETCH');
    }
  },
  changeAgentStatus({ dispatch, state }, requestPayload) {
    return new Promise((resolve, reject) => {
      dispatch('showAppLoading');
      AgenliteAPIClient.changeAgentStatus(state.currentAgent.id, requestPayload)
        .then(response => {
          dispatch('hideFlashAlert');
          dispatch('hideAppLoading');
          resolve(response);
        })
        .catch(error => {
          const message = 'Gagal mengganti status mitra.';
          dispatch('catchError', {
            error,
            message,
            hideAppLoading: true,
          });
          return reject(error);
        });
    });
  },
  retrieveSilkroadAccount({ commit }, willCommit = true) {
    return new Promise((resolve, reject) => {
      SilkroadAPIClient.me()
        .then(response => {
          response.data.whitelist = false;
          if (response.data.warehouses.length > 0) response.data.whitelist = true;
          if (willCommit) commit('setCurrentUser', response.data);
          resolve(response.data);
        })
        .catch(error => {
          if (error && error.meta && error.meta.http_status === 403) {
            commit('setCurrentUser', {
              data: {
                whitelist: false,
              },
            });
          }
          reject(error);
        });
    });
  },
  retrieveCoordinatorInvoice({ commit, dispatch }, { willCommit = true } = {}) {
    dispatch('showAppLoading');
    return new Promise((resolve, reject) => {
      AgenliteAPIClient.retrieveCoordinatorInvoice()
        .then(response => {
          let { resp } = response;
          resp = response.data;
          if (willCommit) {
            commit('setCoordinatorInvoice', resp);
          }
          dispatch('hideAppLoading');
          resolve(resp);
        })
        .catch(error => {
          const message = 'Gagal mendapatkan data invoice koordinator mitra.';
          if (error && error.errors && error.errors[0].code === 10201) {
            dispatch('hideAppLoading');
            resolve(error);
          }
          dispatch('catchError', {
            error,
            message,
            hideAppLoading: true,
            noFlashAlert: true,
          });
          reject(error);
        });
    });
  },
  async acceptTNC({ dispatch }) {
    dispatch('showAppLoading');
    await AgenliteAPIClient.acceptTNC();
    dispatch('hideAppLoading');
  },
  setWholesaleModal({ commit }, isShow) {
    commit('setWholesaleModal', isShow);
  },
  setOosReminderModal({ commit }, isShow) {
    commit('setOosReminderModal', isShow);
  },
  setNewAgentTypeModal({ commit }, isShow) {
    commit('setNewAgentTypeModal', isShow);
  },
  async updateAgentTypeData({ dispatch, commit, state }, agentTypeValue) {
    dispatch('showAppLoading');
    let updateResponse = null;
    try {
      updateResponse = await AgenliteAPIClient.updateAgentData(state.currentAgent.id, agentTypeValue);
      dispatch('retrieveCurrentAgent', { willCommit: true });
      // TODO: set display_agent_type too
      commit('setNewAgentTypeModal', true);
    } catch (err) {
      updateResponse = err;
      const message = 'Gagal memperbarui data mitra.';
      dispatch('catchError', { err, message, hideAppLoading: true });
    } finally {
      dispatch('hideAppLoading');
      dispatch('hideFlashAlert');
    }

    return updateResponse;
  },
  getAgentBanners(_, requestPayload) {
    return AgenliteAPIClient.fetchAgentBanners(requestPayload);
  },
};

const mutations = {
  setCurrentUser(state, user) {
    state.currentUser = user;
  },
  setCurrentBukaUser(state, user) {
    state.currentBukaUser = user;
  },
  setWholesaleModal(state, isShow) {
    state.showWholesaleModal = isShow;
  },
  setOosReminderModal(state, isShow) {
    state.showOosReminderModal = isShow;
  },
  retrieveCurrentAgent(state, agent) {
    state.currentAgent = {
      ...agent,
    };
  },
  yieldAggregate(state, bundle) {
    const module = bundle.agent;
    if (!module) return;

    Object.keys(module).forEach(i => {
      state[i] = module[i];
    }); // console.log(i,a[i])) )
  },
  setCoordinatorInvoice(state, invoice) {
    state.coordinatorInvoice = invoice;
  },
  resetState(state) {
    state.currentAgent = JSON.parse(JSON.stringify(initialState.currentAgent));
    state.currentUser = JSON.parse(JSON.stringify(initialState.currentUser));
    state.coordinatorInvoice = JSON.parse(JSON.stringify(initialState.coordinatorInvoice));
  },
  setNewAgentTypeModal(state, isShow) {
    state.showNewAgentTypeModal = isShow;
  },
};

export default {
  state: () => ({
    ...initialState,
  }),
  getters: moduleGetters,
  actions,
  mutations,
};
