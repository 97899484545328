import API from '~/api';
import { createExplanation } from '~/utils/vuex-promise-explanation';

const trxApiMap = {
  'phone-credit-prepaid': API.getPhoneCreditTransaction,
  'data-plan-prepaid': API.getDataPlanTransaction,
  'electricity-prepaid': API.getElectricityPrepaidTransaction,
  electricity_postpaid: API.getElectricityPostpaidTransaction,
  'coupon-deals': API.getDealsTransaction,
  train: API.getTrainTransaction,
};

const initialState = {
  trxExplanation: Object.seal({
    promise: {},
    isFinished: true,
    createdAt: -1,
    finishedAt: -1,
    state: 'resolved',
  }),
};

const getters = {};

const actions = {
  fetchTransactionHistory({ commit, state }, { override } = { override: false }) {
    if (override || state.trxExplanation.isFinished) {
      // TODO: Discard old request

      // Force request
      const trxRequest = API.postAggregate({
        wallet: {
          method: 'GET',
          path: '/mitra-payment/transactions?v=2&list_type=wallet',
        },
        dana: {
          method: 'GET',
          path: '/mitra-payment/transactions?v=2&list_type=dana',
        },
        credits: {
          method: 'GET',
          path: '/mitra-payment/transactions?v=2&list_type=credits',
        },
      });
      createExplanation(trxRequest, status => commit('setTrxExplanation', status));
    }
    return state.trxExplanation;
  },
  fetchDetailTransaction(_, { type, payload }) {
    return trxApiMap[type]?.(payload);
  },
};

const mutations = {
  setTrxExplanation(state, status) {
    state.trxExplanation = Object.seal(status);
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
