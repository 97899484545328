import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const MITRA_LUCKY_DEALS_URL = `${process.env.API_V4_HOST}/mitra-lucky-deals`;

export default Client.wrap({
  getSerbuBanners(requestPayload) {
    return this.get(`${MITRA_LUCKY_DEALS_URL}/banners`, 'user', new ApiOptions({ requestPayload }));
  },
  getSerbuEvents(requestPayload) {
    return this.get(`${MITRA_LUCKY_DEALS_URL}/events`, 'user', new ApiOptions({ requestPayload }));
  },
  getSerbuEventProducts(requestPayload) {
    return this.get(`${MITRA_LUCKY_DEALS_URL}/event-products`, 'user', new ApiOptions({ requestPayload }));
  },
  getSerbuEventProductDetail(id) {
    return this.get(`${MITRA_LUCKY_DEALS_URL}/event-products/${id}`, 'user', new ApiOptions());
  },
  getSerbuTransactions(requestPayload) {
    return this.get(`${MITRA_LUCKY_DEALS_URL}/transactions`, 'user', new ApiOptions({ requestPayload }));
  },
  getSerbuTransactionsDetail(id) {
    return this.get(`${MITRA_LUCKY_DEALS_URL}/transactions/${id}`, 'user', new ApiOptions());
  },
});
