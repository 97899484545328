import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/modules/account.ts'), 'modules/account.ts')
  resolveStoreModules(require('../store/modules/agent-customers.js'), 'modules/agent-customers.js')
  resolveStoreModules(require('../store/modules/agent.js'), 'modules/agent.js')
  resolveStoreModules(require('../store/modules/allowlist.js'), 'modules/allowlist.js')
  resolveStoreModules(require('../store/modules/authentication.js'), 'modules/authentication.js')
  resolveStoreModules(require('../store/modules/balance-transfer.js'), 'modules/balance-transfer.js')
  resolveStoreModules(require('../store/modules/banks.js'), 'modules/banks.js')
  resolveStoreModules(require('../store/modules/banner.js'), 'modules/banner.js')
  resolveStoreModules(require('../store/modules/bpjs.js'), 'modules/bpjs.js')
  resolveStoreModules(require('../store/modules/bukabantuan.js'), 'modules/bukabantuan.js')
  resolveStoreModules(require('../store/modules/coupons.js'), 'modules/coupons.js')
  resolveStoreModules(require('../store/modules/dana.js'), 'modules/dana.js')
  resolveStoreModules(require('../store/modules/data-plan.js'), 'modules/data-plan.js')
  resolveStoreModules(require('../store/modules/error-handler.js'), 'modules/error-handler.js')
  resolveStoreModules(require('../store/modules/flashalert.js'), 'modules/flashalert.js')
  resolveStoreModules(require('../store/modules/global.js'), 'modules/global.js')
  resolveStoreModules(require('../store/modules/group-buying.js'), 'modules/group-buying.js')
  resolveStoreModules(require('../store/modules/help.js'), 'modules/help.js')
  resolveStoreModules(require('../store/modules/info-payment.js'), 'modules/info-payment.js')
  resolveStoreModules(require('../store/modules/info.js'), 'modules/info.js')
  resolveStoreModules(require('../store/modules/leaderboard.js'), 'modules/leaderboard.js')
  resolveStoreModules(require('../store/modules/loading.js'), 'modules/loading.js')
  resolveStoreModules(require('../store/modules/mitra-biller.js'), 'modules/mitra-biller.js')
  resolveStoreModules(require('../store/modules/mitra-branch.js'), 'modules/mitra-branch.js')
  resolveStoreModules(require('../store/modules/mitra-kyc.js'), 'modules/mitra-kyc.js')
  resolveStoreModules(require('../store/modules/mitra-wallet.js'), 'modules/mitra-wallet.js')
  resolveStoreModules(require('../store/modules/mitra.js'), 'modules/mitra.js')
  resolveStoreModules(require('../store/modules/motorcycle-insurance.js'), 'modules/motorcycle-insurance.js')
  resolveStoreModules(require('../store/modules/multifinance.js'), 'modules/multifinance.js')
  resolveStoreModules(require('../store/modules/neo.js'), 'modules/neo.js')
  resolveStoreModules(require('../store/modules/onboardingVP.js'), 'modules/onboardingVP.js')
  resolveStoreModules(require('../store/modules/password-reset.js'), 'modules/password-reset.js')
  resolveStoreModules(require('../store/modules/payment.js'), 'modules/payment.js')
  resolveStoreModules(require('../store/modules/pdam.js'), 'modules/pdam.js')
  resolveStoreModules(require('../store/modules/persisted-onboarding.js'), 'modules/persisted-onboarding.js')
  resolveStoreModules(require('../store/modules/phone-credit.js'), 'modules/phone-credit.js')
  resolveStoreModules(require('../store/modules/planogram.js'), 'modules/planogram.js')
  resolveStoreModules(require('../store/modules/postpaid-electricity.js'), 'modules/postpaid-electricity.js')
  resolveStoreModules(require('../store/modules/prepaid-electricity.js'), 'modules/prepaid-electricity.js')
  resolveStoreModules(require('../store/modules/product-payment.js'), 'modules/product-payment.js')
  resolveStoreModules(require('../store/modules/profit-reports.js'), 'modules/profit-reports.js')
  resolveStoreModules(require('../store/modules/promos.js'), 'modules/promos.js')
  resolveStoreModules(require('../store/modules/referral.js'), 'modules/referral.js')
  resolveStoreModules(require('../store/modules/register.js'), 'modules/register.js')
  resolveStoreModules(require('../store/modules/reward.js'), 'modules/reward.js')
  resolveStoreModules(require('../store/modules/serbu-seru.js'), 'modules/serbu-seru.js')
  resolveStoreModules(require('../store/modules/special-promo.js'), 'modules/special-promo.js')
  resolveStoreModules(require('../store/modules/subscription.ts'), 'modules/subscription.ts')
  resolveStoreModules(require('../store/modules/super-warung.js'), 'modules/super-warung.js')
  resolveStoreModules(require('../store/modules/tfa.js'), 'modules/tfa.js')
  resolveStoreModules(require('../store/modules/topup-dana-customer.js'), 'modules/topup-dana-customer.js')
  resolveStoreModules(require('../store/modules/topup-digital.ts'), 'modules/topup-digital.ts')
  resolveStoreModules(require('../store/modules/topup-kurir.js'), 'modules/topup-kurir.js')
  resolveStoreModules(require('../store/modules/topup-ondemand.js'), 'modules/topup-ondemand.js')
  resolveStoreModules(require('../store/modules/topups.js'), 'modules/topups.js')
  resolveStoreModules(require('../store/modules/tracker-info-vp.js'), 'modules/tracker-info-vp.js')
  resolveStoreModules(require('../store/modules/tracker-journey.js'), 'modules/tracker-journey.js')
  resolveStoreModules(require('../store/modules/train.js'), 'modules/train.js')
  resolveStoreModules(require('../store/modules/transaction-progress.js'), 'modules/transaction-progress.js')
  resolveStoreModules(require('../store/modules/transform-array.js'), 'modules/transform-array.js')
  resolveStoreModules(require('../store/modules/unified-transaction-list.js'), 'modules/unified-transaction-list.js')
  resolveStoreModules(require('../store/modules/user-address.js'), 'modules/user-address.js')
  resolveStoreModules(require('../store/modules/user.js'), 'modules/user.js')
  resolveStoreModules(require('../store/modules/vp-deals.js'), 'modules/vp-deals.js')
  resolveStoreModules(require('../store/modules/vp-packages.js'), 'modules/vp-packages.js')
  resolveStoreModules(require('../store/modules/whatsapp.js'), 'modules/whatsapp.js')
  resolveStoreModules(require('../store/modules/win-big-event.js'), 'modules/win-big-event.js')
  resolveStoreModules(require('../store/modules/wholesale/index.js'), 'modules/wholesale/index.js')
  resolveStoreModules(require('../store/modules/promotion-media/index.js'), 'modules/promotion-media/index.js')
  resolveStoreModules(require('../store/modules/bookkeeping/index.js'), 'modules/bookkeeping/index.js')
  resolveStoreModules(require('../store/modules/app/index.js'), 'modules/app/index.js')
  resolveStoreModules(require('../store/modules/app/route.js'), 'modules/app/route.js')
  resolveStoreModules(require('../store/modules/bookkeeping/debt-notes.js'), 'modules/bookkeeping/debt-notes.js')
  resolveStoreModules(require('../store/modules/bookkeeping/multiple-debt-notes.ts'), 'modules/bookkeeping/multiple-debt-notes.ts')
  resolveStoreModules(require('../store/modules/bookkeeping/onboarding.js'), 'modules/bookkeeping/onboarding.js')
  resolveStoreModules(require('../store/modules/bookkeeping/product-items.ts'), 'modules/bookkeeping/product-items.ts')
  resolveStoreModules(require('../store/modules/common/app-header-layout.js'), 'modules/common/app-header-layout.js')
  resolveStoreModules(require('../store/modules/lite/cta-install.js'), 'modules/lite/cta-install.js')
  resolveStoreModules(require('../store/modules/new-virtual-product/campaign.ts'), 'modules/new-virtual-product/campaign.ts')
  resolveStoreModules(require('../store/modules/new-virtual-product/digital-send.ts'), 'modules/new-virtual-product/digital-send.ts')
  resolveStoreModules(require('../store/modules/new-virtual-product/digital-voucher.ts'), 'modules/new-virtual-product/digital-voucher.ts')
  resolveStoreModules(require('../store/modules/new-virtual-product/edc-cash-out.ts'), 'modules/new-virtual-product/edc-cash-out.ts')
  resolveStoreModules(require('../store/modules/new-virtual-product/edc-check-balance.ts'), 'modules/new-virtual-product/edc-check-balance.ts')
  resolveStoreModules(require('../store/modules/new-virtual-product/prepaid-phone-topup.ts'), 'modules/new-virtual-product/prepaid-phone-topup.ts')
  resolveStoreModules(require('../store/modules/new-virtual-product/qris-cash-out.ts'), 'modules/new-virtual-product/qris-cash-out.ts')
  resolveStoreModules(require('../store/modules/new-virtual-product/subscription-instant-refund.ts'), 'modules/new-virtual-product/subscription-instant-refund.ts')
  resolveStoreModules(require('../store/modules/new-virtual-product/subscription-top-spender.ts'), 'modules/new-virtual-product/subscription-top-spender.ts')
  resolveStoreModules(require('../store/modules/new-virtual-product/virtual-product.ts'), 'modules/new-virtual-product/virtual-product.ts')
  resolveStoreModules(require('../store/modules/payment/mix-payment.js'), 'modules/payment/mix-payment.js')
  resolveStoreModules(require('../store/modules/payment/payment20.ts'), 'modules/payment/payment20.ts')
  resolveStoreModules(require('../store/modules/promotion-media/vp-catalog.js'), 'modules/promotion-media/vp-catalog.js')
  resolveStoreModules(require('../store/modules/wholesale/cart.js'), 'modules/wholesale/cart.js')
  resolveStoreModules(require('../store/modules/wholesale/free-good-sampling.js'), 'modules/wholesale/free-good-sampling.js')
  resolveStoreModules(require('../store/modules/wholesale/product-list.js'), 'modules/wholesale/product-list.js')
  resolveStoreModules(require('../store/modules/wholesale/search-result.js'), 'modules/wholesale/search-result.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
