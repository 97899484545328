<template lang="pug">
.c-pre-otp
  bl-illustration-av(imageUrl="/images/general_otp.png" imageSize="regular")
  bl-text-av.c-pre-otp__title(variation="subheading-18" weight="semi-bold") Kami mau pastikan ini kamu
  bl-text-av.c-pre-otp__text(variation="body-14")
    | Demi keamanan akun kamu, kami perlu melakukan verifikasi. Kode verifikasi akan dikirim ke no.
    |
    strong {{ phoneNumber }}.
  .c-pre-otp-button-group
    bl-button-av(
      v-if="toggle.whatsapp"
      variation="primary"
      fullwidth
      style="margin-bottom: 16px"
      @click="$emit('request', 'whatsapp')"
      :disabled="getTfaOtpState.isLoading"
    )
      div(slot="icon")
        bl-icon-av(name="ico_comment_minor" color="ui-white")
      span Terima kode lewat Whatsapp
    bl-button-av(
      v-if="toggle.sms"
      :variation="toggle.whatsapp ? 'secondary' : 'primary'"
      fullwidth
      @click="$emit('request', 'sms')"
      :disabled="getTfaOtpState.isLoading"
    )
      div(slot="icon")
        bl-icon-av(name="ico_email_minor" :color="toggle.whatsapp ? '' : 'ui-white'")
      span Terima kode lewat SMS
</template>

<script>
import { mapGetters } from 'vuex';
import { BlIllustrationAv, BlIconAv } from '@bukalapak/bazaar-visual';
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';
import BlButtonAv from '@bukalapak/bazaar-mweb/dist/components/BlButtonAv';

export default {
  name: 'PreOTP',
  components: {
    BlIllustrationAv,
    BlTextAv,
    BlButtonAv,
    BlIconAv,
  },
  props: {
    phoneNumber: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    toggle: {
      whatsapp: false,
      sms: true,
    },
  }),
  computed: {
    ...mapGetters(['getTfaOtpState']),
  },
  watch: {
    phoneNumber() {
      this.fetchWhatsappAvailability();
    },
  },
  mounted() {
    if (this.phoneNumber) this.fetchWhatsappAvailability();
  },
  methods: {
    async fetchWhatsappAvailability() {
      this.toggle.whatsapp = await this.$store.dispatch('checkWhatsappAvailability', this.phoneNumber);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

.c-pre-otp {
  padding: $space-base $space-small;
  text-align: center;

  &__title {
    margin-top: $space-base;
    margin-bottom: $space-xx-small;
  }

  &__text {
    margin-bottom: $space-base;
  }
}
</style>
