import Client from '~/types/api/api-module';
import ApiOptions from '../../api-options';

const AGENT_URL = `${process.env.API_V4_HOST}/_exclusive/agents`;
const MITRA_APPS_URL = `${process.env.API_V4_HOST}/_exclusive/mitra-apps`;

export default Client.wrap({
  fetchMitraAppsConfiguration() {
    return this.get(`${MITRA_APPS_URL}/configurations`, 'user', new ApiOptions());
  },
  fetchVirtualProductStatusInfoType() {
    return this.get(`${AGENT_URL}/virtual-product-status-infos`, 'user', new ApiOptions());
  },
  fetchVirtualProductCashbackSettings() {
    return this.get(`${AGENT_URL}/virtual-product-cashback-settings`, 'user', new ApiOptions());
  },
});
