import { integerMask, delimiter, stripMoney, separatePhoneNumber } from '~/server/helpers/number-helper';

const numberFilter = {
  filters: {
    delimiter,
  },
  methods: {
    integerMask,
    delimiter,
    toNumber(text) {
      return Number(stripMoney(text));
    },
    toPercent(text) {
      return (Number(text) * 100 - 100).toFixed();
    },
    addThousandSeparator(value) {
      if (!value) return value;
      const cleanedNumber = parseInt(value.toString().replace(/[^\d]+/g, ''));
      if (isNaN(cleanedNumber)) return '';
      return cleanedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    removeThousandSeparator(value) {
      return value ? parseInt(value.toString().replace(/\./g, '')) : value;
    },
    separatePhoneNumber,
  },
};

export default numberFilter;
