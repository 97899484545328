<template lang="pug">
.c-field.u-mrgn-bottom--5
  .c-field__label-row(v-if="withLabel")
    label.c-input-label.u-fg--ash-dark(:for="name") {{ label }}
  .c-input-grp-table
    input.c-input-text.c-input.c-input--large(:name="name", :id="name", :placeholder="placeholder", type="password", v-validate="'required|passreq|validpass'", :data-vv-as="alias", v-model="passwordInput", ref="passwordInput", @input="passwordInput = $event.target.value", @keydown.enter.prevent="onEnter")
    a.c-input__ctx.u-pad-h--4(@click="toggleInputType")
      //- 11px = quickfix pictograph icon
      i.c-icon.u-fg--ash-dark(:class="visibilityIcon", :style="visibilityIcon == 'c-icon--show-password' ? 'font-size: 11px;' : ''")
  .c-field__error(v-show="showError && errors.has(name)") {{ errors.first(name) }}
</template>

<script>
export default {
  name: 'PasswordField',
  props: {
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    withLabel: {
      type: Boolean,
      default: true,
    },
    alias: {
      type: String,
      default: 'Password',
    },
    showError: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    visibilityIcon: 'c-icon--hide-password',
  }),
  computed: {
    passwordInput: {
      get() {
        return this.value || '';
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  watch: {
    errors: {
      handler(newValue) {
        this.$emit('error', newValue.items.length > 0);
      },
      deep: true,
    },
  },
  created() {
    this.$validator.extend('passreq', {
      getMessage: 'Password harus diisi',
      validate: value => value != null || undefined,
    });
    this.$validator.extend('validpass', {
      getMessage: 'Password minimal 6 karakter yang mengandung huruf dan angka',
      validate: value => value.length >= 6 && /[A-Z]/i.test(value) && /\d/.test(value),
    });
  },
  methods: {
    toggleInputType() {
      if (this.$refs.passwordInput.type === 'password') {
        this.$refs.passwordInput.type = 'text';
        this.visibilityIcon = 'c-icon--show-password';
      } else {
        this.$refs.passwordInput.type = 'password';
        this.visibilityIcon = 'c-icon--hide-password';
      }
    },
    onEnter() {
      this.$emit('enter', this.passwordInput);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
