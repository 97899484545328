import { AggregationPayload } from 'types/api/aggregation';
import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

type AggregationResponse<T = any> = {
  [K in keyof T]: unknown;
};

const AGGREGATION_API_HOST = `${process.env.API_V4_HOST}/aggregate`;

export default Client.wrap({
  /**
   *
   * @param {Object} config
   * Example:
   * {
   *   method: 'POST',
   *   path: `/_exclusive/experiments/${experiment}/participants/${requestPayload.participant}/metrics`,
   *   body: requestPayload
   * }
   */
  postAggregate(configs: AggregationPayload) {
    const requestPayload = {
      aggregate: {
        ...configs,
      },
    };
    return this.post<AggregationResponse<AggregationPayload>>(AGGREGATION_API_HOST, 'user', new ApiOptions({ requestPayload }));
  },
});
