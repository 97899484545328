import RequestOptions from '~/types/api/request-options';

export interface ApiOptionsConstructor {
  requestPayload?: Record<string, any>;
  requestHeaders?: Record<string, any>;
  requestLimit?: RequestOptions['requestLimit'];
}

export class ApiOptions implements RequestOptions {
  headers: Record<string, any>;
  data: Record<string, any>;
  requestLimit: { attempt?: number; time: number; additionalKey: string } | undefined;
  responseErrorJson: boolean;

  constructor(options: ApiOptionsConstructor = {}) {
    const { requestPayload, requestHeaders, requestLimit } = options;
    if (requestHeaders) this.headers = requestHeaders;
    if (requestPayload) this.data = requestPayload;
    this.requestLimit = requestLimit;
    this.responseErrorJson = true;
  }
}

export default ApiOptions;
