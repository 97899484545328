<template lang="pug">
  transition(name="slide-fade")
    .c-alert.c-alert--full(v-show="status", :class="`c-alert--${flashType}`")
      span.c-alert__close.c-icon.c-icon--close(v-if="!hideClose", @click="hideFlash")
      .o-flag.o-flag--tiny
        .o-flag__head
          span.c-icon.c-icon--info
        .o-flag__body.u-pad-right--3
          span.qa-home--txt--alert(v-html="message")
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FlashAlert',
  computed: {
    ...mapGetters(['getFlashAlert']),
    flashType() {
      return this.getFlashAlert.type;
    },
    message() {
      return this.getFlashAlert.message;
    },
    status() {
      return !!(this.getFlashAlert.isDisplayed && this.message.trim());
    },
    hideClose() {
      return this.getFlashAlert.hideClose;
    },
  },
  methods: {
    hideFlash() {
      this.$store.dispatch('hideFlashAlert');
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-fade {
  &-enter-active {
    transition: all 0.3s ease;
  }
  &-leave-active {
    transition: all 0.4s ease-in;
  }
  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(-50px);
  }
}
</style>
