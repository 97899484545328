import Client from '~/types/api/api-module';
import ApiOptions from '../../api-options';

const EXCLUSIVE_URL = `${process.env.API_V4_HOST}/_exclusive`;

export default Client.wrap({
  getEdcCashOutTransaction(id: number) {
    return this.get(`${EXCLUSIVE_URL}/mitra-edc-cashout/transactions/${id}`, 'user', new ApiOptions());
  },
});
