import {
  PublicListVendorResponse,
  PublicListCustomerProductResponse,
  SharedVendor,
  SharedCustomerProduct,
  SharedVoucherku,
  PublicBulkValidateVoucherResponse,
  PublicListCustomerPackageCollectionResponse,
  SharedCustomerPackageCollection,
  V1ResponseMetadata,
} from '@bukalapak/openapi-schema/vptrader/product/v1/public/product-domain';
import uniqBy from 'lodash/uniqBy';
import AgenliteAPIClient from '~/api';
import VPNonTraderAPIClient from '~/api/vpnontrader';

type InitialState = {
  customerList: [];
  vendorList: SharedVendor[];
  productList: SharedCustomerProduct[];
  productListMeta: V1ResponseMetadata;
  voucherkuList: SharedVoucherku[];
  customerPackageCollections: SharedCustomerPackageCollection[];

  loading: {
    customerList: Boolean;
    productList: Boolean;
    voucherku: Boolean;
    vendorList: Boolean;
    customerPackageCollection: Boolean;
  };
};

const initialState: InitialState = {
  customerList: [],
  vendorList: [],
  productList: [],
  productListMeta: {},
  voucherkuList: [],
  customerPackageCollections: [],

  loading: {
    customerList: true,
    productList: true,
    voucherku: true,
    vendorList: true,
    customerPackageCollection: true,
  },
};

const getters = {
  getCustomerList(state) {
    return state.customerList;
  },
  getCustomerNumberList(state, getters) {
    const customerPhoneList: any = [];
    state.customerList.forEach(customer => {
      customer?.numbers?.forEach(({ number, id, type }) => {
        const prefix = number.substring(0, 4);
        const vendor = getters.getPrefixVendorMapping?.[prefix];
        customerPhoneList.push({
          id,
          name: customer.name,
          number,
          logo: vendor?.logo_url,
          type,
        });
      });
    });
    return customerPhoneList;
  },
  getVendorList(state) {
    return state.vendorList;
  },
  getPrefixVendorMapping(state) {
    const mapping = {};
    state.vendorList.forEach(v => {
      v.prefixes.forEach(p => {
        mapping[p] = v;
      });
    });
    return mapping;
  },
  getVendorFromPhone(_, getters) {
    return function (phoneNumber) {
      const prefix = phoneNumber.substring(0, 4);
      return getters.getPrefixVendorMapping?.[prefix];
    };
  },
  getProductList(state) {
    return state.productList;
  },
  getProductListMeta(state) {
    return state.productListMeta;
  },
  getVoucherkuList(state) {
    return state.voucherkuList;
  },
  getLoadingState(state) {
    return state.loading;
  },
  getCustomerPackageCollections(state) {
    return state.customerPackageCollections;
  },
};

const actions = {
  async fetchCustomerList({ dispatch, commit }) {
    let response: any;
    commit('setLoadingState', { name: 'customerList', status: true });
    try {
      response = await AgenliteAPIClient.getAgentCustomersList();
      const { data: customers } = response;

      commit('setCustomerList', customers);
    } catch (error) {
      dispatch('catchError', { error });
    } finally {
      commit('setLoadingState', { name: 'customerList', status: false });
    }
    return response;
  },
  async fetchVendorList({ dispatch, commit }, { productType, phoneNumber, isSetVendorList = false }) {
    let response: PublicListVendorResponse = {};
    try {
      response = await VPNonTraderAPIClient.getVendors(productType, phoneNumber);
      if (!isSetVendorList) {
        commit(
          'setVendorList',
          response.vendors?.map(vendor => ({
            ...vendor,
            status: vendor.status === 'VENDOR_STATUS_AVAILABLE',
          }))
        );
      }
    } catch (error) {
      dispatch('catchError', { error });
    } finally {
      if (!isSetVendorList) {
        commit('setLoadingState', { name: 'vendorList', status: false });
      }
    }
    return response;
  },
  async fetchProductList({ dispatch, commit }, { payload, append = false }) {
    let response: PublicListCustomerProductResponse = {};
    commit('setLoadingState', { name: 'productList', status: true });
    try {
      response = await VPNonTraderAPIClient.getCustomerProducts(payload);
      commit('setProductList', { productList: response?.customer_products, append });
      commit('setProductListMeta', response?.meta?.offset_pagination);
    } catch (error) {
      commit('setProductList', []);
      commit('setProductListMeta', {});
      dispatch('catchError', { error });
    } finally {
      commit('setLoadingState', { name: 'productList', status: false });
    }
    return response;
  },
  async fetchVoucherkuList(
    { dispatch, commit },
    { productType, productCode, requestAmount, checkValidity = true, limit = 100, offset = 0 }
  ) {
    let response: any = [];
    commit('setLoadingState', { name: 'voucherku', status: true });
    try {
      let { voucherkus } = await VPNonTraderAPIClient.getVoucherku(productType, limit, offset);

      voucherkus = uniqBy(voucherkus, v => v.voucher_code);
      if (voucherkus.length > 0) {
        let validatedVouchers: SharedVoucherku[];

        if (checkValidity) {
          const { vouchers } = await VPNonTraderAPIClient.bulkValidateVoucher(
            voucherkus?.map(voucher => voucher.voucher_code || '')?.filter(code => code),
            productCode,
            'PAYMENT_METHOD_TYPE_WALLET',
            requestAmount
          );
          validatedVouchers = vouchers as SharedVoucherku[];
        }

        response = voucherkus.map(voucher => {
          return {
            ...voucher,
            validity: validatedVouchers?.find(
              validatedVoucher => validatedVoucher.voucher_code === voucher.voucher_code
            ),
          };
        });

        commit('setVoucherkuList', response);
      } else {
        commit('setVoucherkuList', []);
      }
    } catch (error) {
      dispatch('catchError', { error });
    } finally {
      commit('setLoadingState', { name: 'voucherku', status: false });
    }

    return response;
  },
  async bulkValidateVoucher({ dispatch, commit }, { voucherCode, productCode, requestAmount }) {
    let response: PublicBulkValidateVoucherResponse = {};
    commit('setLoadingState', { name: 'voucherku', status: true });
    try {
      response = await VPNonTraderAPIClient.bulkValidateVoucher(
        [voucherCode],
        productCode,
        'PAYMENT_METHOD_TYPE_WALLET',
        requestAmount
      );
    } catch (error) {
      dispatch('catchError', { error });
    } finally {
      commit('setLoadingState', { name: 'voucherku', status: false });
    }
    return response;
  },
  async fetchCustomerPackageCollection({ dispatch, commit }, payload) {
    let response: PublicListCustomerPackageCollectionResponse = {};
    commit('setLoadingState', { name: 'customerPackageCollections', status: true });
    try {
      response = await VPNonTraderAPIClient.getCustomerPackageCollections(payload);
      commit('setCustomerPackageCollections', response?.customer_package_collections?.filter(item => item.status !== 'CUSTOMER_PACKAGE_COLLECTION_STATUS_HIDDEN'));
    } catch (error) {
      commit('setCustomerPackageCollections', []);
      dispatch('catchError', { error });
    } finally {
      commit('setLoadingState', { name: 'customerPackageCollections', status: false });
    }
    return response;
  },
};

const mutations = {
  setCustomerList(state, customerList) {
    state.customerList = customerList;
  },
  setVendorList(state, vendorList) {
    state.vendorList = vendorList;
  },
  setProductList(state, { productList = [], append = false }) {
    state.productList = append ? [...state.productList, ...productList] : productList;
  },
  setProductListMeta(state, metadata) {
    state.productListMeta = metadata;
  },
  setVoucherkuList(state, voucherkuList) {
    state.voucherkuList = voucherkuList;
  },
  setLoadingState(state, loading) {
    state.loading[loading.name] = loading.status;
  },
  resetState(state) {
    state.customerList = JSON.parse(JSON.stringify(initialState.customerList));
    state.vendorList = JSON.parse(JSON.stringify(initialState.vendorList));
    state.productList = JSON.parse(JSON.stringify(initialState.productList));
    state.productListMeta = JSON.parse(JSON.stringify(initialState.productListMeta));
    state.customerPackageCollections = JSON.parse(JSON.stringify(initialState.customerPackageCollections));
    state.voucherkuList = JSON.parse(JSON.stringify(initialState.voucherkuList));
  },
  setCustomerPackageCollections(state, customerPackageCollections) {
    state.customerPackageCollections = customerPackageCollections;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
