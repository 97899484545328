import AgenliteAPIClient from '~/api';

const initialState = {
  isDoneTransaction: false,
  agentCustomersList: [],
  isShowingOnboarding: {
    phone_credit: true,
    electricity_prepaid: true,
    data_plan: true,
    electricity_postpaid: true,
    bpjs_kesehatan: true,
    multifinance: true,
    pdam: true,
  },
};

const getters = {
  getAgentCustomersTransactionStatus(state) {
    return state.isDoneTransaction;
  },
  getAgentCustomers(state) {
    return state.agentCustomersList;
  },
  getAgentCustomerNumbers(state) {
    let numbers = [];
    if ((state.agentCustomersList || []).length > 0) {
      state.agentCustomersList.forEach(customer => {
        if (customer.numbers.length > 0) numbers = numbers.concat(customer.numbers);
      });
      return numbers;
    }
    return numbers;
  },
  getOnboardingAgentCustomerStatus(state) {
    return state.isShowingOnboarding;
  },
};

const mutations = {
  setAgentCustomersTransactionState(state, { status }) {
    state.isDoneTransaction = status;
  },
  closeOnboarding(state, type) {
    state.isShowingOnboarding[type] = false;
  },
  addAgentCustomer(state, newCustomer) {
    state.agentCustomersList.push(newCustomer);
  },
  editAgentCustomer(state, payload) {
    const index = state.agentCustomersList.findIndex(customer => customer.id === payload.id);
    state.agentCustomersList[index] = payload;
  },
  deleteAgentCustomer(state, id) {
    const index = state.agentCustomersList.findIndex(customer => customer.id === id);
    state.agentCustomersList.splice(index, 1);
  },
  addAgentCustomerNumber(state, payload) {
    const index = state.agentCustomersList.findIndex(customer => customer.id === payload.offline_customer_id);
    state.agentCustomersList[index].numbers.push(payload);
  },
  editAgentCustomerNumber(state, payload) {
    const currentState = JSON.parse(JSON.stringify(state.agentCustomersList));
    currentState.forEach((customer, i) => {
      const index = customer.numbers.findIndex(cNum => cNum.id === payload.id);
      if (index !== -1) {
        currentState[i].numbers[index] = payload.data;
        return true;
      }
      return false;
    });
    state.agentCustomersList = currentState;
  },
  deleteAgentCustomerNumber(state, id) {
    const currentState = JSON.parse(JSON.stringify(state.agentCustomersList));
    currentState.forEach(customer => {
      let isDeleted = false;
      customer.numbers.forEach((number, i) => {
        if (id === number.id) {
          customer.numbers.splice(i, 1);
          isDeleted = true;
          return true;
        }
        return false;
      });

      if (isDeleted) return true;
      return false;
    });
    state.agentCustomersList = currentState;
  },
  setAgentCustomersList(state, list) {
    state.agentCustomersList = list;
  },
  resetState(state) {
    state.isDoneTransaction = JSON.parse(JSON.stringify(initialState.isDoneTransaction));
    state.agentCustomersList = JSON.parse(JSON.stringify(initialState.agentCustomersList));
    state.isShowingOnboarding = JSON.parse(JSON.stringify(initialState.isShowingOnboarding));
  },
  yieldAggregate(state, bundle) {
    const module = bundle.customers;
    if (!module) return;

    Object.keys(module).forEach(i => {
      state[i] = module[i];
    });
  },
};

const actions = {
  changeAgentCustomersTransactionState({ commit, state }, { status }) {
    if (state.isDoneTransaction !== status) commit('setAgentCustomersTransactionState', { status });
  },
  closeOnboarding({ commit }, type) {
    commit('closeOnboarding', type);
  },
  async getAgentCustomersList({ commit, dispatch }) {
    dispatch('showAppLoading');
    // return new Promise((resolve, reject) => {
    try {
      const response = await AgenliteAPIClient.getAgentCustomersList();
      const agentCustomersList = response.data;
      commit('setAgentCustomersList', agentCustomersList);
      dispatch('hideAppLoading');
      return agentCustomersList;
    } catch (error) {
      const message = 'Gagal mendapatkan data daftar pelanggan.';
      dispatch('catchError', { error, message, hideAppLoading: true, noFlashAlert: true });
      console.warn('Fetch list failed', error);
      return error;
    }
  },
  async getIgnoreAgentCustomerNumber({ dispatch }, payload) {
    try {
      const response = await AgenliteAPIClient.getIgnoreCustomerNumber(payload);
      return response.data;
    } catch (error) {
      dispatch('catchError', { error, hideAppLoading: true });
      throw error;
    }
  },
  async ignoreAgentCustomerNumber({ dispatch }, payload) {
    try {
      const response = await AgenliteAPIClient.postIgnoreCustomerNumber(payload);
      return response.data;
    } catch (error) {
      const message = 'Gagal menyimpan data.';
      dispatch('catchError', { error, message, hideAppLoading: true });
      throw error;
    }
  },
  async addAgentCustomers({ dispatch, commit }, payload) {
    try {
      const response = await AgenliteAPIClient.addCustomers(payload);
      commit('addAgentCustomer', response.data);
      return response.data;
    } catch (error) {
      const message = 'Gagal menambah data pelanggan.';
      dispatch('catchError', { error, message, hideAppLoading: true });
      throw error;
    }
  },
  async editAgentCustomers({ dispatch, commit }, payload) {
    try {
      const { id } = payload;
      const response = await AgenliteAPIClient.editCustomers(id, payload);
      commit('editAgentCustomer', response.data);
      return response.data;
    } catch (error) {
      const message = 'Gagal menyimpan data pelanggan.';
      dispatch('catchError', { error, message, hideAppLoading: true });
      throw error;
    }
  },
  async deleteAgentCustomers({ dispatch, commit }, id) {
    try {
      dispatch('showAppLoading');
      const response = await AgenliteAPIClient.deleteCustomers(id);
      commit('deleteAgentCustomer', id);
      return response.data;
    } catch (error) {
      const message = 'Gagal menghapus data pelanggan.';
      dispatch('catchError', { error, message, hideAppLoading: true });
      throw error;
    }
  },
  async addAgentCustomerNumbers({ dispatch, commit }, payload) {
    try {
      const response = await AgenliteAPIClient.addCustomerNumbers(payload);
      commit('addAgentCustomerNumber', response.data);
      return response.data;
    } catch (error) {
      const message = 'Gagal menambah nomor pelanggan.';
      dispatch('catchError', { error, message, hideAppLoading: true });
      throw error;
    }
  },
  async editAgentCustomerNumbers({ dispatch, commit }, payload) {
    try {
      const { id, name, type, number, partnerId } = payload;
      const response = await AgenliteAPIClient.editCustomerNumbers(id, { name, type, number, operator_id: partnerId });
      commit('editAgentCustomerNumber', { id: payload.id, data: response.data });
      return response.data;
    } catch (error) {
      const message = 'Gagal menyimpan nomor pelanggan.';
      dispatch('catchError', { error, message, hideAppLoading: true });
      throw error;
    }
  },
  async deleteAgentCustomerNumbers({ dispatch, commit }, numberId) {
    try {
      const response = await AgenliteAPIClient.deleteCustomerNumbers(numberId);
      if (response.message.includes('berhasil dihapus')) commit('deleteAgentCustomerNumber', numberId);
      return response;
    } catch (error) {
      const message = 'Gagal menghapus nomor pelanggan.';
      dispatch('catchError', { error, message, hideAppLoading: true });
      throw error;
    }
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
