import axios from 'axios';
import { timeDiffSeconds } from '~/server/helpers/date-helper';

const SAMPLE_NAME = 'sentinel_duration_seconds';
const SAMPLE_KIND = 'hl';
const SAMPLE_DOMAIN = document.location.hostname;
const SAMPLE_ADDITIONAL = [0, 0.2, 50];

function getPath() {
  // currently we only serve homepage
  return '/';
}

const SAMPLE_PATH = getPath();

function getSample(name, end, start) {
  const check = Date.now() - 86400000;
  if (start < check || end < check || start > end) {
    return {};
  }

  return {
    name: SAMPLE_NAME,
    kind: SAMPLE_KIND,
    labels: {
      domain: SAMPLE_DOMAIN,
      path: SAMPLE_PATH,
      segment: name,
    },
    value: timeDiffSeconds(start, end),
    additional: SAMPLE_ADDITIONAL,
  };
}

export default function () {
  window.addEventListener('load', () => {
    if (!process.env.PROD_STATS_ENABLED) {
      return;
    }

    if (!window.performance || !window.performance.timing) {
      return;
    }

    const t = window.performance.timing;

    const samples = [
      getSample('redirect', t.redirectEnd, t.redirectStart),
      getSample('dns', t.domainLookupEnd, t.domainLookupStart),
      getSample('tcp', t.connectEnd, t.connectStart),
      getSample('network', t.responseEnd, t.responseStart),
      getSample('first_byte', t.responseStart, t.fetchStart),
      getSample('interactive', t.domInteractive, t.domLoading),
      getSample('dom_content_loaded', t.domContentLoadedEventEnd, t.fetchStart),
      getSample('dom_complete', t.domComplete, t.domLoading),
      getSample('all', t.domComplete, t.requestStart),
    ].filter(s => typeof s !== 'undefined' && typeof s.name !== 'undefined');
    axios.post('/perf', {
      samples,
    });
  });
};
