const PREFIX = 'agenlite_group_buying';

export default {
  defaultPayload() {
    return {
      journey_id: this.getTrackerJourneyId,
      referrer_screen: this.routeReferrer?.name,
      referrer_url: this.routeReferrer?.url,
      current_screen: this.$route.name,
    };
  },
  trackGroupBuyingClick({ clickSource, evn = 'click', data = {} }) {
    const payload = {
      ...this.defaultPayload(),
      evn: `${PREFIX}_${evn}`,
      click_source: clickSource,
      ...data
    };
    this.$blTracker.actions.trackActions(payload);
  },
  trackGroupBuyingImpression({ sectionName, evn = 'impression' }) {
    const payload = {
      ...this.defaultPayload(),
      evn: `${PREFIX}_${evn}`,
      section_name: sectionName,
    };
    this.$blTracker.actions.trackActions(payload);
  }

};
