// TODO: Refactor this file and remove the following line
// Temporary fix:
/* eslint max-lines: 0 */

import Cookies from 'js-cookie';
import credentialStorageInstance from '~/api/credential-storage-instance';
import accessTokenManagerInstance from '~/api/v4/access-token-manager-instance';
import { createFetch } from './fetch-helper';
import RequestBuilder from './request_builder';

import wrapSecureClientInterface from './v4';

import Interface from './api-interface';

const AgenliteAPIClient = {
  v4: {
    accessTokenManager: accessTokenManagerInstance,
  },
  credentials: credentialStorageInstance,
  init() {
    const apiV4Mapping = [
      // Single-ACL interface
      { entry: 'account', interface: Interface.Account, pool: 'account' },
      { entry: 'invoice', interface: Interface.Invoice, pool: 'invoice' },
      { entry: 'payment', interface: Interface.Payment, pool: 'payment' },
      { entry: 'user', interface: Interface.User, pool: 'user' },
      { entry: 'transaction', interface: Interface.Transaction, pool: 'transaction' },
      { entry: 'wallet', interface: Interface.Wallet, pool: 'wallet' },
      { entry: 'mitraTransactions', interface: Interface.MitraTrx, pool: 'mitra-transactions' },
      { entry: 'groupBuying', interface: Interface.GroupBuying.Private, pool: 'group_buying' },
      { entry: 'help', interface: Interface.Help.Private, pool: 'help' },
      { entry: 'info', interface: Interface.Info, pool: 'info' },
      { entry: 'product', interface: Interface.Product, pool: 'product' },
      { entry: 'productItems', interface: Interface.ProductItems, pool: 'product_items' },
      { entry: 'mitra', interface: Interface.Mitra.Private, pool: 'mitra' },
      { entry: 'mitraBranch', interface: Interface.MitraBranch, pool: 'mitraBranch' },
      { entry: 'debtSnapshot', interface: Interface.DebtSnapshot, pool: 'debt_snapshot' },
      { entry: 'exclusiveNeo', interface: Interface.Exclusive.Neo, pool: 'exclusive_neo' },
      { entry: 'exclusiveNotification', interface: Interface.Exclusive.Notification, pool: 'exclusive_notification' },
      { entry: 'reward', interface: Interface.Reward, pool: 'reward' },
      { entry: 'credits', interface: Interface.Credits, pool: 'credits' },
      { entry: 'topup', interface: Interface.Topup, pool: 'topup' },
      { entry: 'topupOndemand', interface: Interface.TopupOndemand, pool: 'topup_ondemand' },
      { entry: 'exclusiveInfo', interface: Interface.Exclusive.Info, pool: 'exclusive_info' },
      { entry: 'exclusiveReferral', interface: Interface.Exclusive.Referral, pool: 'referral' },
      { entry: 'exclusivePoster', interface: Interface.Exclusive.Poster, pool: 'poster' },
      { entry: 'mitraBiller', interface: Interface.Exclusive.MitraBiller.Private, pool: 'exclusive_mitra_biller' },
      { entry: 'agent_customers', interface: Interface.AgentCustomers, pool: 'agent_customers' },
      { entry: 'exclusiveAgent', interface: Interface.Exclusive.Agent, pool: 'exclusive_agent' },
      { entry: 'serbuSeru', interface: Interface.SerbuSeru, pool: 'serbu_seru' },
      { entry: 'exclusiveTopupKurir', interface: Interface.Exclusive.TopupKurir, pool: 'topup_kurir' },
      { entry: 'promos', interface: Interface.Promos, pool: 'promos' },
      { entry: 'profitReports', interface: Interface.ProfitReports, pool: 'profit_reports' },
      { entry: 'promotionMedia', interface: Interface.PromotionMedia, pool: 'promotion_media' },
      { entry: 'exclusiveMitraService', interface: Interface.Exclusive.MitraService, pool: 'mitra_service' },
      { entry: 'coupons', interface: Interface.Coupons, pool: 'coupons' }, // Multi-ACL interface
      { entry: 'complaints', interface: Interface.Complaints.Private, pool: 'complaints' },
      { entry: 'exclusiveMitraKYC', interface: Interface.Exclusive.MitraKYC, pool: 'mitra_kyc' },
      {
        entry: 'agent',
        interface: Interface.Agent.Private,
        publicInterface: Interface.Agent.Public,
        pool: 'agent',
      },
      {
        entry: 'exclusiveUser',
        interface: Interface.Exclusive.User.Private,
        publicInterface: Interface.Exclusive.User.Public,
        pool: 'exclusive_user',
      },
      {
        entry: 'multifinance',
        interface: Interface.Multifinance.Private,
        publicInterface: Interface.Multifinance.Public,
        pool: 'multifinance',
      },
      {
        entry: 'payout',
        interface: Interface.Payout.Private,
        publicInterface: Interface.Payout.Public,
        pool: 'payout',
      },
      {
        entry: 'agentBanks',
        interface: Interface.AgentBanks.Private,
        publicInterface: Interface.AgentBanks.Public,
        pool: 'agent_banks',
      },
      {
        entry: 'exclusiveDana',
        interface: Interface.Exclusive.Dana.Private,
        publicInterface: Interface.Exclusive.Dana.Public,
        pool: 'exclusive_dana',
      },
      {
        entry: 'train',
        interface: Interface.Train.Private,
        pool: 'train_ticket',
        publicInterface: Interface.Train.Public,
      },
      {
        entry: 'vpDeals',
        interface: Interface.VpDeals.Private,
        pool: 'vp_deals',
        publicInterface: Interface.VpDeals.Public,
      },
      {
        entry: 'motorcycleInsurance',
        interface: Interface.MotorcycleInsurance.Private,
        pool: 'motorcycle_insurance',
        publicInterface: Interface.MotorcycleInsurance.Public,
      },
      {
        entry: 'electricityPostpaid',
        interface: Interface.ElectricityPostpaid.Private,
        publicInterface: Interface.ElectricityPostpaid.Public,
        pool: 'electricity_postpaid',
      },
      {
        entry: 'electricityPrepaid',
        interface: Interface.ElectricityPrepaid.Private,
        publicInterface: Interface.ElectricityPrepaid.Public,
        pool: 'electricity_prepaid',
      },
      { entry: 'pdam', interface: Interface.Pdam.Private, publicInterface: Interface.Pdam.Public, pool: 'pdam' },
      { entry: 'bpjs', interface: Interface.Bpjs.Private, publicInterface: Interface.Bpjs.Public, pool: 'bpjs' },
      {
        entry: 'exclusiveWhatsapp',
        publicInterface: Interface.Exclusive.Whatsapp.Public,
        interface: Interface.Exclusive.Whatsapp.Private,
        pool: 'exclusive_whatsapp',
      },
      {
        entry: 'exclusiveMitraPayment',
        interface: Interface.Exclusive.MitraPayment.Private,
        publicInterface: Interface.Exclusive.MitraPayment.Public,
        pool: 'mitra_payment',
      },
      {
        entry: 'exclusiveMitraWallet',
        interface: Interface.Exclusive.MitraWallet,
        pool: 'mitra_wallet',
      },
      {
        entry: 'phoneCredits',
        interface: Interface.PhoneCredits.Private,
        publicInterface: Interface.PhoneCredits.Public,
        pool: 'phone_credits',
      },
      {
        entry: 'dataPlan',
        interface: Interface.DataPlan.Private,
        publicInterface: Interface.DataPlan.Public,
        pool: 'data_plan',
      },
      { entry: 'voucher', interface: Interface.Voucher, pool: 'voucher' },
      { entry: 'aggregation', interface: Interface.Aggregation, pool: 'aggregation' },
      { entry: 'bookkeeping', interface: Interface.Bookkeeping, pool: 'bookkeeping' },
      { entry: 'agentSellingProducts', interface: Interface.AgentSellingProducts, pool: 'agentSellingProducts' },
      {
        entry: 'agentCustomerDebtMutations',
        interface: Interface.AgentCustomerDebtMutations,
        pool: 'agentCustomerDebtMutations',
      },
      { entry: 'saasUser', interface: Interface.SaasUser, pool: 'saas_user' },
      { entry: 'digitalVoucher', interface: Interface.DigitalVoucher.Private, pool: 'digital_voucher' },
      { entry: 'digitalSend', interface: Interface.Exclusive.MitraSendMoney, pool: 'digital_send' },
      { entry: 'edcCheckBalance', interface: Interface.Exclusive.MitraEdcCheckBalance, pool: 'edc_check_balance' },
      { entry: 'mitraEdcCashOut', interface: Interface.Exclusive.MitraEdcCashOut, pool: 'edc_cashout' },
      { entry: 'qrisCashOut', interface: Interface.Exclusive.MitraQrisCashOut, pool: 'qris_cash_out' },
      { entry: 'bukabantuan', interface: Interface.Exclusive.BukaBantuan, pool: 'bukabantuan' },
      { entry: 'leaderboard', interface: Interface.Leaderboard, pool: 'leaderboard' },
      { entry: 'winBigEvent', interface: Interface.WinBigEvent, pool: 'big_win_event' },
      { entry: 'allowlist', interface: Interface.Allowlist, pool: 'allowlist' },
      { entry: 'mitraWallet', interface: Interface.MitraWallet, pool: 'mitra_wallet' },
      {
        entry: 'subscriptionTopSpender',
        interface: Interface.SubscriptionTopSpender,
        pool: 'subscription_top_spender',
      },
    ];
    apiV4Mapping.forEach(item => {
      this.v4[item.entry] = wrapSecureClientInterface(
        accessTokenManagerInstance,
        item.interface,
        `xhr_v4_${item.pool}`
      );
      // scanning overlapping public modules
      Object.entries(item.publicInterface ?? {}).forEach(([key, func]) => {
        if (this[key]) console.warn(`Warning: public function ${key} already exists. Attempt by ${item.entry}`);
        this[key] = this[key] ?? func;
      });
      // scanning overlapping private modules
      Object.entries(this.v4[item.entry]).forEach(([key, func]) => {
        const skippedKeys = ['abortRequest', 'refreshToken'];
        if (skippedKeys.includes(key)) return;
        if (this[key]) console.warn(`Warning: ${key} possibly exists. Source: ${item.entry}`);
        this[key] = this[key] ?? func;
      });
    });
  },
  async authenticate(username, password, alternative = false) {
    const authV4Response = await this.v4.accessTokenManager.authenticate(username, password, alternative);
    if (!authV4Response.scope.includes('store')) throw new Error('UNAUTHORIZED_V4');
    return {
      authV4Response,
    };
  },
  async authenticateViaCode(code, redirectUri) {
    const authV4Response = await this.v4.accessTokenManager.authenticateViaCode(code, redirectUri);
    if (authV4Response.access_token === '') throw new Error('FAILED_OAUTH');
    return {
      v4: authV4Response,
    };
  },
  syncAccessToken(accessObject) {
    this.v4.accessTokenManager.storage.token = { ...accessObject.v4, userId: accessObject.v4.userId };
  },
  // FOR V4 CRITICAL QUICKFIX
  fetchTokenV4() {
    return this.v4.accessTokenManager.fetchToken('public user store'); // proxy
  },
  isAuthenticated() {
    return this.credentials.login; // proxy
  },
  getCurrentUserIdV4() {
    const { userId } = this.v4.accessTokenManager.storage.token; // proxy
    return `${userId ?? ''}`;
  },
  getCurrentUserId36() {
    return Number(this.isAuthenticated() ? this.getCurrentUserIdV4() : '0').toString(36);
  },
  destroyV4Token() {
    this.v4.accessTokenManager.destroyToken();
  },
  deleteOtpKey(userId = null) {
    if (!userId) {
      window.localStorage.setItem('otp_storage', JSON.stringify([]));
      return;
    }
    const otpStorage = window.localStorage.getItem('otp_storage');
    let otpList = otpStorage ? JSON.parse(otpStorage) : [];
    otpList = otpList.filter(otp => otp.userId !== userId);
    window.localStorage.setItem('otp_storage', JSON.stringify(otpList));
  },
  setOtpKey(userId, otpKey) {
    if (!otpKey) return;
    const otpStorage = window.localStorage.getItem('otp_storage');
    let otpList = otpStorage ? JSON.parse(otpStorage) : [];
    otpList = [
      {
        userId,
        key: otpKey,
        lastTimeVerified: Date.now(),
      },
    ];
    window.localStorage.setItem('otp_storage', JSON.stringify(otpList));
  },
  getOtp(userId) {
    let otpStorage = window.localStorage.getItem('otp_storage');
    const otpCookies = Cookies.get('otp_key_temp');

    if (otpStorage === null || JSON.parse(otpStorage).length <= 0) {
      if (otpCookies) {
        this.setOtpKey(userId, otpCookies);
      } else {
        window.localStorage.setItem('otp_storage', JSON.stringify([]));
        return null;
      }
    }

    otpStorage = window.localStorage.getItem('otp_storage');
    const otpList = JSON.parse(otpStorage);
    let otpObj = {
      key: '',
      lastTimeVerified: 0,
    };
    if (otpList.length <= 0) return otpObj;
    const found = otpList.some(otp => {
      if (userId !== otp.userId) return false;
      otpObj = otp;
      return true;
    });
    const hasKeyWithoutUserId = !otpList[0].userId;
    if (!found && hasKeyWithoutUserId) {
      otpObj = otpList[0];
    }
    return otpObj;
  },
  async changeTFASetting(userId, requestPayload, { checkOnly = false, method = 'sms', otpCode = null }) {
    const otpKey = this.getOtp(userId).key;
    const browserFingerprint = window.localStorage.getItem('browser_fp');
    const requestHeaders = {
      'Bukalapak-OTP-Device-ID': this.credentials.identity,
    };
    if (!checkOnly) {
      requestHeaders['Bukalapak-OTP-Method'] = method;
    }
    if (otpCode) {
      requestHeaders['Bukalapak-OTP'] = otpCode;
    } else if (otpKey) {
      requestHeaders['Bukalapak-OTP-Key'] = otpKey;
    }
    if (browserFingerprint) {
      requestHeaders['Bukalapak-Device-Fingerprint'] = browserFingerprint;
    }
    const response = await this.v4.user.updateTFASetting(requestPayload, requestHeaders);
    if (response.meta.http_status !== 200) return false;
    if (response.meta.otp_key) {
      this.setOtpKey(userId, response.meta.otp_key);
      return true;
    }
    return false;
  },
  async makePayment(userId, invoiceId, paymentMethod = 'wallet', options = {}) {
    let response = null;
    try {
      if (paymentMethod === 'mitra_cod') {
        response = await this.v4.payment.makePaymentByChannel(
          invoiceId,
          RequestBuilder.getOtpHeader(this.getOtp(userId).key, options),
          'mitra',
          'cod'
        );
      } else if (paymentMethod === 'wallet') {
        response = await this.v4.payment.makePaymentByChannel(
          invoiceId,
          RequestBuilder.getOtpHeader(this.getOtp(userId).key, options),
          'wallet'
        );
      } else if (paymentMethod === 'dana') {
        response = await this.v4.exclusiveDana.makePaymentWithDana(
          invoiceId,
          RequestBuilder.getOtpHeader(this.getOtp(userId).key, options)
        );
      }
    } catch (error) {
      if (error?.meta?.['otp_key']) {
        this.setOtpKey(userId, error.meta.otp_key);
      }
      throw error;
    }
    if (response?.meta?.['otp_key']) {
      this.setOtpKey(userId, response.meta.otp_key);
    }
    return response;
  },
  async payPendingInvoice(userId, { invoiceId, method }) {
    const invoiceResponse = await this.updateInvoice(invoiceId, method);
    if (invoiceResponse.meta.http_status !== 200) throw invoiceResponse;
    const paymentResponse = await this.makePayment(userId, invoiceResponse.data.payment_id, method);
    if (paymentResponse.meta.http_status !== 200) throw paymentResponse;
    return {
      invoiceResponse,
      paymentResponse,
    };
  },
  async payTransaction(userId, { id, type, method }) {
    const invoiceResponse = await this.registerInvoice(id, type, method);
    if (invoiceResponse.meta.http_status !== 201) throw invoiceResponse;
    const paymentResponse = await this.makePayment(userId, invoiceResponse.data.payment_id, method);
    if (paymentResponse.meta.http_status !== 200) throw paymentResponse;
    return {
      invoiceResponse,
      paymentResponse,
    };
  },
  setWholesaleTransaction(data) {
    return this.v4.transaction.setTransaction(data);
  },
  getWholesaleTransaction(remoteId) {
    return this.v4.transaction.getTransaction(remoteId);
  },
  setWholesaleTransactionStatus(id, data) {
    return this.v4.transaction.setTransactionStatus(id, data);
  },
  async phoneConfirmation(userId, options) {
    let response = null;
    const requestHeaders = RequestBuilder.getOtpHeader(this.getOtp(userId).key, options);
    try {
      response = await this.v4.user.confirmUserPhone(requestHeaders);
    } catch (error) {
      if (error?.meta?.['otp_key']) {
        this.setOtpKey(userId, error.meta.otp_key);
      }
      throw error;
    }
    if (response?.meta?.['otp_key']) {
      this.setOtpKey(userId, response.meta.otp_key);
    }
    return response;
  },
  bindDanaToken(userId, authParam) {
    return createFetch('POST', '/dana/tokens', {
      requestHeaders: {
        'user-token': this.v4.accessTokenManager.storage.token.access_token,
      },
      requestPayload: {
        user_id: userId,
        auth_code: authParam.authCode,
        state: authParam.state,
      },
    });
  },
};

AgenliteAPIClient.init(); // TODO: all inside init will be static-declaration

export default AgenliteAPIClient;
