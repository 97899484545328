import {
  PublicListVendorResponse,
  CustomerProductServiceListCustomerProductQueryParams,
  VoucherServiceListVoucherkuQueryParams,
  PublicListVoucherkuResponse,
  SharedProductTypeEnum,
  PublicBulkValidateVoucherResponse,
  PublicListCustomerPackageCollectionResponse,
  CustomerPackageCollectionServiceListCustomerPackageCollectionQueryParams,
} from '@bukalapak/openapi-schema/vptrader/product/v1/public/product-domain';
import {
  PublicCreateCustomerEWalletTransactionResponse,
  PublicCreateCustomerEWalletTransactionRequest,
  PublicCreateCustomerEWalletInquiryRequest,
  PublicCreateCustomerEWalletInquiryResponse,
  PublicCreateCustomerPrepaidPhoneTransactionResponse,
  PublicCreateCustomerPrepaidPhoneTransactionRequest,
  CustomerSubscriptionServiceGetCustomerSubscriptionInstantRefundEligibilityQueryParams,
  PublicCreateCustomerSubscriptionInstantRefundRequest,
  PublicCreateCustomerSubscriptionInstantRefundResponse,
  PublicGetCustomerSubscriptionInstantRefundEligibilityResponse,
} from '@bukalapak/openapi-schema/vptrader/transaction/v1/public/transaction-domain';
import { PublicGetCustomerTransactionResponse } from '@bukalapak/openapi-schema/vptrader/transaction/v2/public/transaction-domain';
import accessTokenManagerInstance from '~/api/v4/access-token-manager-instance';
import wrapSecureClientInterface from './v4';

import VPNonTraderCustomerTransactionAPI from './v4/modules/vpnontrader/customer-transaction-api';
import VPNonTraderVendorAPI from './v4/modules/vpnontrader/vendors-api';
import VPNonTraderCustomerProductAPI from './v4/modules/vpnontrader/customer-products-api';
import VPNonTraderVoucherAPI from './v4/modules/vpnontrader/voucher-api';
import VPNonTraderCustomerPackageCollectionsAPI from './v4/modules/vpnontrader/customer-package-collections-api';

const VPNonTraderAPIClient = {
  v4: {
    accessTokenManager: accessTokenManagerInstance,
  } as any,

  init() {
    this.v4.vpnontraderVendor = wrapSecureClientInterface(
      accessTokenManagerInstance,
      VPNonTraderVendorAPI,
      'vpnontrader_vendor'
    );
    this.v4.vpnontraderCustomerTransaction = wrapSecureClientInterface(
      accessTokenManagerInstance,
      VPNonTraderCustomerTransactionAPI,
      'vpnontrader_customer_transaction'
    );
    this.v4.vpnontraderCustomerProduct = wrapSecureClientInterface(
      accessTokenManagerInstance,
      VPNonTraderCustomerProductAPI,
      'vpnontrader_customer_product'
    );
    this.v4.vpnontraderVoucher = wrapSecureClientInterface(
      accessTokenManagerInstance,
      VPNonTraderVoucherAPI,
      'vpnontrader_voucher'
    );
    this.v4.vpnontraderCustomerPackageCollection = wrapSecureClientInterface(
      accessTokenManagerInstance,
      VPNonTraderCustomerPackageCollectionsAPI,
      'vpnontrader_customer_package_collections'
    );
  },

  getPrepaidPhoneTopupTransaction(id: number): PublicGetCustomerTransactionResponse {
    return this.v4.vpnontraderCustomerTransaction.getPrepaidPhoneTopupTransaction(id);
  },

  getEWalletTransaction(id: number): PublicGetCustomerTransactionResponse {
    return this.v4.vpnontraderCustomerTransaction.getEWalletTransaction(id);
  },

  createPrepaidPhoneTransaction(payload): PublicCreateCustomerPrepaidPhoneTransactionResponse {
    const requestPayload: PublicCreateCustomerPrepaidPhoneTransactionRequest = {
      order_id: payload.orderId,
      product_code: payload.productCode,
      phone_number: payload.phoneNumber,
      payment_method_type: payload.paymentMethodType, // deprecated
      balance_payment_method_type: payload.balancePaymentMethodType,
      voucher_code: payload.voucherCode,
    };
    return this.v4.vpnontraderCustomerTransaction.createPrepaidPhoneTransaction(requestPayload);
  },

  createEWalletTransaction(payload): PublicCreateCustomerEWalletTransactionResponse {
    const requestPayload: PublicCreateCustomerEWalletTransactionRequest = {
      order_id: payload.orderId,
      product_code: payload.productCode,
      phone_number: payload.phoneNumber,
      payment_method_type: payload.paymentMethodType, // deprecated
      balance_payment_method_type: payload.balancePaymentMethodType,
      voucher_code: payload.voucherCode,
      amount: payload.amount,
    };
    return this.v4.vpnontraderCustomerTransaction.createEWalletTransaction(requestPayload);
  },

  createEWalletInquiry(payload): PublicCreateCustomerEWalletInquiryResponse {
    const requestPayload: PublicCreateCustomerEWalletInquiryRequest = {
      product_code: payload.productCode,
      phone_number: payload.phoneNumber,
      amount: payload.amount,
    };
    return this.v4.vpnontraderCustomerTransaction.createEWalletInquiry(requestPayload);
  },

  getVendors(productTypes: String, phoneNumber: String): PublicListVendorResponse {
    const requestPayload = {
      product_type: productTypes,
      phone_number: phoneNumber,
      limit: 100,
    };

    return this.v4.vpnontraderVendor.getVendors(requestPayload);
  },

  getCustomerProducts(payload: CustomerProductServiceListCustomerProductQueryParams): PublicListVendorResponse {
    return this.v4.vpnontraderCustomerProduct.getCustomerProducts(payload);
  },

  getVoucherku(productTypes: SharedProductTypeEnum, limit: number, offset: number): PublicListVoucherkuResponse {
    const requestPayload: VoucherServiceListVoucherkuQueryParams = {
      limit,
      offset,
      product_type: productTypes,
    };

    return this.v4.vpnontraderVoucher.getVoucherku(requestPayload);
  },

  bulkValidateVoucher(
    voucherCodes: string[],
    productCode: string,
    paymentMethod: string,
    requestAmount: string
  ): PublicBulkValidateVoucherResponse {
    const requestPayload = {
      voucher_codes: voucherCodes,
      product_code: productCode,
      payment_method: paymentMethod,
      ...{ request_amount: requestAmount || undefined },
    };

    return this.v4.vpnontraderVoucher.bulkValidateVoucher(requestPayload);
  },

  getCustomerPackageCollections(
    payload: CustomerPackageCollectionServiceListCustomerPackageCollectionQueryParams
  ): PublicListCustomerPackageCollectionResponse {
    return this.v4.vpnontraderCustomerPackageCollection.getCustomerPackageCollections(payload);
  },

  createCustomerSubscriptionInstantRefund(payload): PublicCreateCustomerSubscriptionInstantRefundResponse {
    const requestPayload: PublicCreateCustomerSubscriptionInstantRefundRequest = {
      transaction_id: payload.transaction_id,
    };
    return this.v4.vpnontraderCustomerTransaction.createCustomerSubscriptionInstantRefund(requestPayload);
  },

  getCustomerSubscriptionInstantRefundEligibility(
    payload: CustomerSubscriptionServiceGetCustomerSubscriptionInstantRefundEligibilityQueryParams
  ): PublicGetCustomerSubscriptionInstantRefundEligibilityResponse {
    return this.v4.vpnontraderCustomerTransaction.getCustomerSubscriptionInstantRefundEligibility(payload);
  },
};

VPNonTraderAPIClient.init();

export default VPNonTraderAPIClient;
