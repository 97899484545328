<template lang="pug">
  div.otp-modal
    .u-align-center.u-mrgn-bottom--10
      p.u-txt--large.u-mrgn-bottom--4(v-if="title") {{ title }}
      .u-txt--caption.u-mrgn-bottom--4 {{ otpMessage }}
      a.u-fg--ash-dark.u-txt--underline(v-if="allowChangePhone", @click="changePhoneNumber") Ubah No. Handphone
    .u-fg--ash-dark.u-mrgn-bottom--2 Kode Otentifikasi
    .o-layout
      .o-layout__item.u-width-1of5
        input.c-input-text.c-input--large.u-align-center(type="number", max="9", v-model="otpCode0", @click="emptySlot(0)", @input="handleInput(1)", @paste.prevent="fillOtpInput", ref="otpCode0", :class="{'c-input--error': shouldShowInvalidOtp}")
      .o-layout__item.u-width-1of5
        input.c-input-text.c-input--large.u-align-center(type="number", max="9", v-model="otpCode1", @click="emptySlot(1)", @input="handleInput(2)", @paste.prevent="fillOtpInput", @keyup.delete="focusInput(0)", ref="otpCode1", :class="{'c-input--error': shouldShowInvalidOtp}")
      .o-layout__item.u-width-1of5
        input.c-input-text.c-input--large.u-align-center(type="number", max="9", v-model="otpCode2", @click="emptySlot(2)", @input="handleInput(3)", @paste.prevent="fillOtpInput", @keyup.delete="focusInput(1)", ref="otpCode2", :class="{'c-input--error': shouldShowInvalidOtp}")
      .o-layout__item.u-width-1of5
        input.c-input-text.c-input--large.u-align-center(type="number", max="9", v-model="otpCode3", @click="emptySlot(3)", @input="handleInput(4)", @paste.prevent="fillOtpInput", @keyup.delete="focusInput(2)", ref="otpCode3", :class="{'c-input--error': shouldShowInvalidOtp}")
      .o-layout__item.u-width-1of5
        input.c-input-text.c-input--large.u-align-center(type="number", max="9", v-model="otpCode4", @click="emptySlot(4)", @input="blurAndGiveBack", @paste.prevent="fillOtpInput", @keyup.delete="focusInput(3)", ref="otpCode4", :class="{'c-input--error': shouldShowInvalidOtp}")
    .u-fg--red-brand.u-mrgn-top--2(v-if="shouldShowInvalidOtp") Kode otentifikasi tidak valid
    FixedFooter
      .u-pad--4(slot="custom")
        .u-align-center(v-if="otpTimer > 0") Klik tombol di bawah ini untuk mengirim ulang kode dalam {{otpTimer}} detik
        .u-align-center(v-if="otpTimer <= 0") Klik tombol di bawah ini untuk mengirim ulang kode
        a.c-btn.c-btn--block.c-btn--large.u-mrgn-top--7(@click="rerequestOtp", :disabled="otpTimer > 0") Kirim Ulang Kode
</template>
<script>
import { mapGetters } from 'vuex';
import FixedFooter from '~/components/FixedFooter';

export default {
  name: 'OtpModal',
  components: {
    FixedFooter,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    allowChangePhone: {
      type: Boolean,
      default: false,
    },
    otpMessage: {
      type: String,
      default: '',
    },
    invalidOtp: {
      type: Boolean,
      default: false,
    },
    otpTimer: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    otpCode0: '',
    otpCode1: '',
    otpCode2: '',
    otpCode3: '',
    otpCode4: '',
    shouldShowInvalidOtp: false,
  }),
  computed: {
    ...mapGetters(['isFlashAlertDisplayed']),
    isFilled() {
      return (
        this.otpCode0 !== '' &&
        this.otpCode1 !== '' &&
        this.otpCode2 !== '' &&
        this.otpCode3 !== '' &&
        this.otpCode4 !== ''
      );
    },
  },
  watch: {
    invalidOtp(newVal) {
      if (newVal) {
        this.clearOtpInput();
        this.shouldShowInvalidOtp = !this.isFlashAlertDisplayed;
      }
    },
  },
  mounted() {
    this.$refs.otpCode0.focus();
  },
  methods: {
    clearOtpInput() {
      this.otpCode0 = '';
      this.otpCode1 = '';
      this.otpCode2 = '';
      this.otpCode3 = '';
      this.otpCode4 = '';
      this.focusInput(0);
    },
    fillOtpInput(event) {
      const fullOtp = event.clipboardData.getData('text');
      this.otpCode0 = fullOtp.charAt(0);
      this.otpCode1 = fullOtp.charAt(1);
      this.otpCode2 = fullOtp.charAt(2);
      this.otpCode3 = fullOtp.charAt(3);
      this.otpCode4 = fullOtp.charAt(4);
      this.focusInput(4);
      this.giveBack();
    },
    emptySlot(inputIndex) {
      this[`otpCode${inputIndex}`] = '';
    },
    focusInput(inputIndex) {
      this.$refs[`otpCode${inputIndex}`].focus();
    },
    handleInput(inputIndex) {
      this.limitOneDigit();
      if (this.$refs[`otpCode${inputIndex - 1}`].value !== '') this.focusInput(inputIndex);
      this.giveBack();
    },
    blurAndGiveBack() {
      this.limitOneDigit();
      setTimeout(() => {
        if (this.isFilled) {
          this.$refs.otpCode4.blur();
        }
        this.giveBack();
      }, 5);
    },
    limitOneDigit() {
      if (this.otpCode0.length > 1) this.otpCode0 = this.otpCode0.slice(1);
      if (this.otpCode1.length > 1) this.otpCode1 = this.otpCode1.slice(1);
      if (this.otpCode2.length > 1) this.otpCode2 = this.otpCode2.slice(1);
      if (this.otpCode3.length > 1) this.otpCode3 = this.otpCode3.slice(1);
      if (this.otpCode4.length > 1) this.otpCode4 = this.otpCode4.slice(1);
    },
    giveBack() {
      this.$emit('change', {
        otpCode: `${this.otpCode0}${this.otpCode1}${this.otpCode2}${this.otpCode3}${this.otpCode4}`,
      });
    },
    changePhoneNumber() {
      this.$emit('changePhoneNumber');
    },
    rerequestOtp() {
      this.$emit('rerequest');
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
