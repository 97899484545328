import timelimit from '~/constants/vp/timelimit';
import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const ELECTRICITY_POSTPAID_URL = `${process.env.API_V4_HOST}/electricities`;

interface TransactionPayload {
  id: number;
  type: any;
  customer_number: string;
}
interface PostpaidPayload {
  transaction: TransactionPayload;
  method: string;
  voucherCode: string;
  customerPhone: string;
}

export default Client.wrap({
  Public: {
    /** @deprecated */ async payPostpaidElectricity(
      userId: number,
      { transaction, customerPhone, method, voucherCode }: PostpaidPayload
    ): Promise<{ paymentResponse; invoiceResponse }> {
      const invoiceResponse = await this.registerInvoice(transaction.id, transaction.type, method, voucherCode);
      if (invoiceResponse.meta.http_status !== 201) throw invoiceResponse;
      if (customerPhone !== '') {
        this.createAgentTransactionDetail(invoiceResponse.data.payment_id, transaction.customer_number, customerPhone);
      }
      const paymentResponse = await this.makePayment(userId, invoiceResponse.data.payment_id, method);
      if (paymentResponse.meta.http_status !== 200) throw paymentResponse;
      return {
        paymentResponse,
        invoiceResponse,
      };
    },
  },
  Private: {
    postElectricityPostpaidInquiry(customerNumber: string) {
      const requestPayload = { customer_number: customerNumber };
      return this.post(`${ELECTRICITY_POSTPAID_URL}/postpaid-inquiries`, 'public', new ApiOptions({ requestPayload }));
    },
    postElectricityPostpaidTransaction(customerNumber: string) {
      const requestPayload = { customer_number: customerNumber };
      const requestLimit = {
        time: timelimit.deposit,
        additionalKey: customerNumber,
      };
      return this.post(
        `${ELECTRICITY_POSTPAID_URL}/postpaid-transactions`,
        'user',
        new ApiOptions({ requestPayload, requestLimit })
      );
    },
    getElectricityPostpaidTransaction(id: number) {
      return this.get(`${ELECTRICITY_POSTPAID_URL}/postpaid-transactions/${id}`, 'user', new ApiOptions());
    },
  },
});
