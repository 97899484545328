import AgenliteAPIClient from '~/api';
import { money } from '~/server/helpers/number-helper';
import defineMessage from '~/utils/voucher-message';

const initialState = {
  voucherInfo: {
    isValid: false,
    isCashback: false,
    isDiscount: false,
    submitted: false,
    discount: 0,
    cashback: 0,
    message: '',
    voucherCode: null,
    focus: false,
    loading: false,
  },
};

const moduleGetters = {
  getVoucherInfo(state) {
    return state.voucherInfo;
  },
  getVoucherCode(state) {
    return state.voucherInfo.voucherCode;
  },
};

const actions = {
  async payPendingInvoice({ dispatch, getters }, options) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.payPendingInvoice(getters.getUserId, options);
      dispatch('hideFlashAlert');
    } catch (error) {
      const message = 'Pembayaran gagal.';
      dispatch('catchError', { error, message, finishTransaction: true });
      throw error;
    }
    return response;
  },
  async payTransaction({ dispatch, getters }, options) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.payTransaction(getters.getUserId, options);
      dispatch('hideFlashAlert');
    } catch (error) {
      const message = 'Pembayaran gagal.';
      dispatch('catchError', { error, message, finishTransaction: true });
      throw error;
    }
    return response;
  },
  async createInvoice(_ctx, options) {
    const { id: transactionId, type, method, voucherCode } = options;
    const invoiceResponse = await AgenliteAPIClient.registerInvoice(transactionId, type, method, voucherCode);
    return invoiceResponse;
  },
  // After all products are using Direct OTP Flow, invoiceId and method will be removed
  async makePayment({ dispatch, getters }, { requestPayload, options, invoiceId, method }) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.makePayment(
        getters.getUserId,
        requestPayload.invoiceId || invoiceId,
        requestPayload.method || method,
        options
      );
      dispatch('hideFlashAlert');
    } catch (error) {
      if (error?.meta?.['http_status'] === 401) throw error;
      const message = 'Pembayaran gagal.';
      dispatch('catchError', { error, message, finishTransaction: true });
      throw error;
    }
    return response;
  },
  async makeVOCPayment({ dispatch, getters }, { requestPayload, options }) {
    dispatch('showAppLoading');
    let response = null;
    try {
      const {
        method: paymentMethod,
        voucherCode,
        finalAmount,
        transactionId,
        type: transactionType,
        VOCPayload,
      } = requestPayload;
      const VOCOptionsPayload = { voucherCode, finalAmount, transactionId };
      const userId = getters.getUserId;
      response = await AgenliteAPIClient.createVOCTransaction({
        userId,
        transactionType,
        paymentMethod,
        VOCOptionsPayload,
        VOCPayload,
        OTPOptions: options,
      });
    } catch (error) {
      if (error?.meta?.['http_status'] === 401) throw error;
      const message = 'Pembayaran gagal.';
      dispatch('catchError', { error, message, finishTransaction: true });
      throw error;
    } finally {
      dispatch('hideAppLoading');
    }
    return response;
  },
  async checkVoucher({ dispatch, commit }, params) {
    commit('setVoucherLoading', true);
    try {
      const response = await AgenliteAPIClient.checkVoucher(params);
      const { data, meta } = response;
      const isValid = data.benefit.length > 0;
      const cashback = isValid ? data.benefit[0].cashback : 0;
      const discount = isValid ? data.benefit[0].discount : 0;
      const isCashback = cashback > 0;
      const isDiscount = discount > 0;
      const { transactions } = params;
      let message = defineMessage(meta, isCashback ? cashback : discount);
      if (isValid && isCashback) {
        const vpTransactionType = [
          'train',
          'electricity-prepaid',
          'electricity_postpaid',
          'pdam',
          'bpjs-kesehatan',
          'multifinance',
          'phone_credit',
          'data_plan',
        ];
        const { type } = transactions[0];
        if (vpTransactionType.includes(type)) {
          message = `Selamat, kamu dapat cashback ${money(
            cashback
          )}! Cek di Saldo maks. 1x24 jam setelah transaksi berhasil.`;
        }
      }
      dispatch('setVoucherInfo', {
        isValid,
        isCashback,
        isDiscount,
        cashback,
        discount,
        message,
        submitted: true,
        voucherCode: params.voucher_code,
        voucherState: meta.message_key,
        focus: false,
      });
    } catch (error) {
      dispatch('catchError', { error, message: 'Pengecekan kode promo gagal dilakukan' });
    } finally {
      commit('setVoucherLoading', false);
    }
  },
  handleValidateVoucherSuccess({ dispatch }, payload) {
    dispatch('setVoucherInfo', {
      isValid: true,
      isCashback: payload.cashback > 0,
      isDiscount: payload.discount > 0,
      cashback: payload.cashback,
      discount: payload.discount,
      message: payload.message,
      submitted: true,
      voucherCode: payload.voucher_code,
      voucherState: '',
      focus: false,
    });
  },
  handleValidateVoucherError({ dispatch }, payload) {
    dispatch('setVoucherInfo', {
      isValid: false,
      isCashback: false,
      isDiscount: false,
      cashback: 0,
      discount: 0,
      message: payload.message,
      submitted: true,
      voucherCode: payload.voucher_code,
      voucherState: '',
      focus: false,
    });
  },
  async validateVoucher({ dispatch, commit }, payload) {
    commit('setVoucherLoading', true);
    try {
      const response = await AgenliteAPIClient.validateVoucher(payload);
      const [benefit] = response.data.benefits;
      dispatch('handleValidateVoucherSuccess', benefit);
    } catch (response) {
      const [error] = response?.errors ?? [];
      if (error) {
        dispatch('handleValidateVoucherError', { ...error, voucher_code: payload.voucher_codes[0] });
      } else {
        dispatch('catchError', { error, message: 'Pengecekan kode promo gagal dilakukan' });
      }
    } finally {
      commit('setVoucherLoading', false);
    }
  },
  setVoucherCode({ commit }, voucherCode) {
    commit('setVoucherCode', voucherCode);
  },
  setVoucherCodeFocus({ commit }, status) {
    commit('setVoucherCodeFocus', status);
  },
  setVoucherInfo({ commit }, voucherInfo) {
    commit('setVoucherInfo', voucherInfo);
  },
  clearVoucherInfo({ commit }) {
    commit('clearVoucherInfo');
  },
};

const mutations = {
  setVoucherCodeFocus(state, status) {
    state.voucherInfo.focus = status;
  },
  setVoucherCode(state, voucherCode) {
    state.voucherInfo.voucherCode = voucherCode;
  },
  setVoucherInfo(state, voucherInfo) {
    state.voucherInfo = voucherInfo;
  },
  setVoucherLoading(state, loading) {
    state.voucherInfo.loading = loading;
  },
  clearVoucherInfo(state) {
    state.voucherInfo = JSON.parse(JSON.stringify(initialState.voucherInfo));
  },
};
export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  actions,
  mutations,
};
