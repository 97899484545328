import r from '~/utils/render-utils';
const PageKontakPelanggan = () =>
  import(/* webpackChunkName: 'kontak-pelanggan-page' */ '~/pages/KontakPelanggan/index');
const PageKontakPelangganCreate = () =>
  import(/* webpackChunkName: 'kontak-pelanggan-create-page' */ '~/pages/KontakPelanggan/Create');

const routes = [
  {
    path: '/buka-20/customer-phone-list',
    component: r(PageKontakPelanggan),
    name: 'kontak_pelanggan',
  },
  {
    path: '/buka-20/customer-phone-list/create',
    component: r(PageKontakPelangganCreate),
    name: 'kontak_pelanggan_create',
  },
];

export default routes;
