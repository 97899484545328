import AgenliteAPIClient from '~/api';

let timerInterval = null;
// const day = 24 * 60 * 60 * 1000;
// const OTP_DEFAULT_EXPIRY_TIME = 60 * day;

const initialState = {
  otp: {
    isOtpModalOpened: false,
    isOtpTimeout: false,
    otpTimer: 0,
    isLoading: false,
    isInvalidOtp: false,
    redirectRoute: null,
    callbackFunc: null,
    method: null,
    phone: null,
    triggerOnly: false,
    guardedApiAction: null,
    guardedApiPayload: null,
  },
};

const moduleGetters = {
  getTfaOtpState(state) {
    return state.otp;
  },
  getGuardedApiAction(state) {
    return state.otp.guardedApiAction;
  },
  getGuardedApiPayload(state) {
    return state.otp.guardedApiPayload;
  },
};

const actions = {
  // options: guardedApiAction, guardedApiPayload, callbackFunc, triggerOnly, expiryTime, method
  async authDevicePublic({ commit, dispatch }, options = null) {
    if (options) {
      commit('setOtpOptions', options);
    }
    const phone = options.guardedApiPayload?.phone || options.guardedApiPayload?.phone_number || options.guardedApiPayload?.token;
    if (phone) {
      const userOtp = AgenliteAPIClient.getOtp(phone);
      let isOtpExpired = false;
      if (options?.expiryTime) {
        const { expiryTime } = options;
        const date = new Date();
        const expiryTimeAgo = new Date(date.getTime() - expiryTime);
        isOtpExpired = userOtp && userOtp.lastTimeVerified ? userOtp.lastTimeVerified < expiryTimeAgo : false;
      }
      if ((userOtp && (!userOtp.key || userOtp.userId !== phone)) || isOtpExpired) {
        AgenliteAPIClient.deleteOtpKey();
      }
    }
    try {
      await dispatch('callOtpGuardedPublicApi');
    } catch (err) {
      return true;
    }
    return true;
  },
  callOtpGuardedPublicApi({ commit, state, dispatch, getters }, { otpCode = null } = { otpCode: null }) {
    commit('setLoading', true);
    return dispatch(getters.getGuardedApiAction, {
      requestPayload: getters.getGuardedApiPayload,
      options: { method: state.otp.method, otpCode, triggerOnly: state.otp.triggerOnly },
    })
      .then(response => {
        commit('setLoading', false);
        dispatch('stopOtpTimer');
        dispatch('hideOtpModal');
        if (state && state.otp && state.otp.callbackFunc) {
          state.otp.callbackFunc(response);
        }
        dispatch('resetOtpState');
      })
      .catch(error => {
        commit('setLoading', false);
        const otpSentErrorCodes = [10101, 10103, 10121, 10122];
        if (
          error &&
          error.errors &&
          error.meta.http_status === 401 &&
          otpSentErrorCodes.includes(error.errors[0].code)
        ) {
          if (
            error.errors[0].message === 'Token OTP tidak ada' ||
            /Token OTP berhasil dikirimkan ke/.test(error.errors[0].message)
          ) {
            commit('setLoading', true);
            dispatch('showOtpModal');
            dispatch('startOtpTimer', error.meta.wait_time);
            dispatch('hideFlashAlert');
            setTimeout(() => {
              commit('setLoading', false);
            }, 1100);
            throw error;
          } else if (error.errors[0].message === 'Token OTP tidak valid') {
            commit('setInvalidOtp', true);
          } else if (error.errors[0].message === 'Perangkat belum divalidasi') {
            AgenliteAPIClient.deleteOtpKey();
            dispatch('showFlashAlert', {
              message: 'Terjadi kesalahan, silakan coba lagi.',
              type: 'warning',
            });
          } else {
            dispatch('showFlashAlert', {
              message: error.errors[0].message,
              type: 'warning',
            });
          }
          commit('setLoading', false);
          dispatch('hideAppLoading');
          return error;
        }
        if (state && state.otp && state.otp.callbackFunc) {
          state.otp.callbackFunc(error);
        }
        dispatch('clearOtpFlow');
        return error;
      });
  },
  resendAuthDevicePublic({ dispatch }) {
    AgenliteAPIClient.deleteOtpKey();
    dispatch('stopOtpTimer');
    setTimeout(() => {
      dispatch('showOtpModal');
      dispatch('callOtpGuardedPublicApi');
    }, 500);
  },

  // options: guardedApiAction, guardedApiPayload, callbackFunc, expiryTime, method
  // guardedApiAction must receive 'options'
  async authDeviceUser({ commit, dispatch, getters }, options = null) {
    if (options) {
      commit('setOtpOptions', options);
    }
    const userOtp = AgenliteAPIClient.getOtp(getters.getUserId);
    let isOtpExpired = false;
    if (options?.expiryTime) {
      const { expiryTime } = options;
      const date = new Date();
      const expiryTimeAgo = new Date(date.getTime() - expiryTime);
      isOtpExpired = userOtp && userOtp.lastTimeVerified ? userOtp.lastTimeVerified < expiryTimeAgo : false;
    }
    if ((userOtp && !userOtp.key) || isOtpExpired) {
      AgenliteAPIClient.deleteOtpKey(getters.getUserId);
    }
    try {
      await dispatch('callOtpGuardedUserApi');
    } catch (err) {
      return true;
    }
    return true;
  },
  async callOtpGuardedUserApi({ commit, state, dispatch, getters }, { otpCode = null } = { otpCode: null }) {
    commit('setLoading', true);
    await dispatch(getters.getGuardedApiAction, {
      requestPayload: getters.getGuardedApiPayload,
      options: { method: state.otp.method, otpCode },
    })
      .then(response => {
        commit('setLoading', false);
        dispatch('stopOtpTimer');
        dispatch('hideOtpModal');
        if (state && state.otp && state.otp.callbackFunc) {
          state.otp.callbackFunc(response);
        }
        dispatch('resetOtpState');
      })
      .catch(error => {
        commit('setLoading', false);
        const otpSentErrorCodes = [10101, 10103, 10121, 10122];
        const phone = getters.getGuardedApiPayload?.phone || getters.getGuardedApiPayload?.phone_number;
        if (
          error &&
          error.errors &&
          error.meta.http_status === 401 &&
          otpSentErrorCodes.includes(error.errors[0].code)
        ) {
          if (
            error.errors[0].message === 'OTP diperlukan' ||
            error.errors[0].message === 'Header OTP tidak valid' ||
            error.errors[0].message === 'OTP Tidak Valid.' ||
            error.meta.otp_needed
          ) {
            AgenliteAPIClient.deleteOtpKey();
            dispatch('showOtpModal', { phone });
          } else if (
            error.errors[0].message === 'Token OTP tidak ada' ||
            /Token OTP berhasil dikirimkan ke/.test(error.errors[0].message) ||
            error.meta.wait_time > 0
          ) {
            commit('setLoading', true);
            dispatch('showOtpModal', { phone });
            dispatch('startOtpTimer', error.meta.wait_time);
            dispatch('hideFlashAlert');
            setTimeout(() => {
              commit('setLoading', false);
            }, 1100);
            throw error;
          } else if (error.errors[0].message === 'Token OTP tidak valid') {
            commit('setInvalidOtp', true);
          } else if (error.errors[0].message === 'Perangkat belum divalidasi') {
            AgenliteAPIClient.deleteOtpKey();
            dispatch('showFlashAlert', {
              message: 'Terjadi kesalahan, silakan coba lagi.',
              type: 'warning',
            });
          } else {
            dispatch('showFlashAlert', {
              message: error.errors[0].message,
              type: 'warning',
            });
          }
          commit('setLoading', false);
          dispatch('hideAppLoading');
          return;
        }
        if (state && state.otp && state.otp.callbackFunc) {
          state.otp.callbackFunc(error);
        }
        dispatch('clearOtpFlow');
      });
  },
  resendAuthDeviceUser({ dispatch }) {
    AgenliteAPIClient.deleteOtpKey();
    dispatch('stopOtpTimer');
    setTimeout(() => {
      dispatch('showOtpModal');
      dispatch('callOtpGuardedUserApi');
    }, 500);
  },

  // options: callbackFunc, redirectTo, expiryTime
  authenticateDevice({ commit, dispatch, getters }, options = null) {
    console.warn('[DEPRECATED] Please use authDeviceUser');
    return new Promise((resolve, reject) => {
      if (options) {
        const { redirectTo, callbackFunc } = options;
        if (redirectTo) commit('setRedirectRoute', redirectTo);
        if (callbackFunc) commit('setCallbackFunc', callbackFunc);
      }
      const userOtp = AgenliteAPIClient.getOtp(getters.getUserId);
      let isOtpExpired = false;
      if (options?.expiryTime) {
        const { expiryTime } = options;
        const date = new Date();
        const expiryTimeAgo = new Date(date.getTime() - expiryTime);
        isOtpExpired = userOtp && userOtp.lastTimeVerified ? userOtp.lastTimeVerified < expiryTimeAgo : false;
      }
      if ((userOtp && !userOtp.key) || isOtpExpired) {
        AgenliteAPIClient.deleteOtpKey(getters.getUserId);
        dispatch('showOtpModal');
        return resolve(false);
      }
      if (options.redirectTo) {
        this.$router.push(options.redirectTo);
      }
      if (options.callbackFunc) {
        // Recheck for invalid/revoked/expired OTP Key
        dispatch('showAppLoading');
        AgenliteAPIClient.changeTFASetting(getters.getUserId, { state: 'active' }, { checkOnly: true })
          .then(() => {
            options.callbackFunc();
            dispatch('resetOtpState');
            return resolve(true);
          })
          .catch(error => {
            dispatch('hideAppLoading');
            if (error && error.errors && error.meta.http_status === 401) {
              commit('setLoading', true);
              AgenliteAPIClient.deleteOtpKey(getters.getUserId);
              dispatch('hideFlashAlert');
              if (error.errors[0].message === 'Token OTP tidak ada') {
                dispatch('showOtpModal');
                dispatch('startOtpTimer', error.meta.wait_time);
              } else if (error.errors[0].message === 'Perangkat belum divalidasi') {
                AgenliteAPIClient.deleteOtpKey();
                dispatch('showOtpModal');
              } else {
                dispatch('showFlashAlert', {
                  message: error.errors[0].message,
                  type: 'warning',
                });
              }
              dispatch('hideAppLoading');
              setTimeout(() => {
                commit('setLoading', false);
              }, 1100);
              return reject(error);
            }
            return resolve(true);
          });
      }
      return resolve(true);
    });
  },

  validateAddAgentCustomers({ commit, dispatch }, { pelanggan, otpCode }) {
    commit('setLoading', true);
    dispatch('showAppLoading');
    AgenliteAPIClient.addAgentCustomer(pelanggan, otpCode)
      .then(isOtpSet => {
        commit('setLoading', false);
        dispatch('stopOtpTimer');
        if (isOtpSet) {
          if (isOtpSet && isOtpSet.data) {
            if (isOtpSet.meta.http_status === 201) {
              this.$router.replace('/');
              dispatch('showFlashAlert', {
                message: `Pelanggan <strong>${isOtpSet.data.name}</strong> berhasil ditambahkan`,
                type: 'success',
              });
            }
            return;
          }
          if (isOtpSet && isOtpSet.errors) {
            if (
              isOtpSet.errors[0].message ===
              'Name gunakan nama asli atau nama lapak kamu dan Username gunakan username lain'
            ) {
              this.$router.go(-1);
              dispatch('showFlashAlert', {
                message: 'Gunakan nama asli',
                type: 'warning',
              });
            } else if (
              isOtpSet.errors[0].message ===
              'Nama hanya boleh berupa huruf, angka, titik ( . ), underscore ( _ ), dan spasi' ||
              isOtpSet.errors[0].message ===
              'Nama hanya boleh berupa huruf, angka, titik ( . ), underscore ( _ ), dan spasi dan Username minimum 3 huruf'
            ) {
              this.$router.go(-1);
              dispatch('showFlashAlert', {
                message: 'Format nama salah. Hanya boleh berupa huruf, angka, titik, underscore dan spasi',
                type: 'warning',
              });
            } else if (isOtpSet.errors[0].code === 10000) {
              this.$router.go(-1);
              dispatch('showFlashAlert', {
                message: 'Nomor telah terkonfirmasi di akun lain atau format nama salah',
                type: 'warning',
              });
            }
            return;
          }
        }
        dispatch('clearOtpFlow');
      })
      .catch(error => {
        const message = 'Gagal melakukan validasi pelanggan.';
        commit('setLoading', false);
        if (error && error.errors) {
          if (error.errors[0].message === 'Validasi gagal') {
            dispatch('showFlashAlert', {
              message: 'Gagal melakukan validasi. Cek kode OTP',
              type: 'warning',
            });
          } else if (error.errors[0].message === 'Nomor telepon tidak valid.') {
            dispatch('showFlashAlert', {
              message: 'Nomor handphone tidak valid',
              type: 'warning',
            });
          } else if (error.errors[0].code === 10111) {
            dispatch('showFlashAlert', {
              message: 'Tidak dapat mengirim OTP. Tunggu beberapa saat lagi',
              type: 'warning',
            });
          } else if (error.errors[0].code === 10203) {
            this.$router.go(-1);
            dispatch('showFlashAlert', {
              message: 'Pelanggan telah terdaftar',
              type: 'warning',
            });
          }
          dispatch('hideAppLoading');
          return;
        }
        dispatch('clearOtpFlow');
        dispatch('catchError', { error, message, hideAppLoading: true });
      });
  },

  validateEditAgentCustomers({ commit, dispatch }, { pelanggan }) {
    commit('setLoading', true);
    dispatch('showAppLoading');
    AgenliteAPIClient.editAgentCustomer(pelanggan)
      .then(response => {
        commit('setLoading', false);
        dispatch('stopOtpTimer');
        if (response) {
          if (response && response.data) {
            if (response.meta.http_status === 201) {
              this.$router.replace('/');
              dispatch('showFlashAlert', {
                message: `Pelanggan <strong>${response.data.name}</strong> berhasil diperbarui`,
                type: 'success',
              });
            }
          }
        }
      })
      .catch(error => {
        const message = 'Gagal melakukan validasi pelanggan.';
        commit('setLoading', false);
        if (error && error.errors) {
          if (error.errors[0].message === 'Validasi gagal') {
            dispatch('showFlashAlert', {
              message: 'Gagal melakukan validasi. Cek kode OTP',
              type: 'warning',
            });
          } else if (error.errors[0].message === 'Nomor telepon tidak valid.') {
            dispatch('showFlashAlert', {
              message: 'Nomor handphone tidak valid',
              type: 'warning',
            });
          } else if (error.errors[0].code === 10111) {
            dispatch('showFlashAlert', {
              message: 'Tidak dapat mengirim OTP. Tunggu beberapa saat lagi',
              type: 'warning',
            });
          } else if (error.errors[0].code === 10203) {
            this.$router.go(-1);
            dispatch('showFlashAlert', {
              message: 'Pelanggan telah terdaftar',
              type: 'warning',
            });
          }
          dispatch('hideAppLoading');
          return;
        }
        dispatch('catchError', { error, message, hideAppLoading: true });
      });
  },

  activateTFA({ commit, dispatch, state, getters }, { method = null, otpCode } = {}) {
    console.warn('[DEPRECATED] Please use callOtpGuardedUserApi');
    commit('setLoading', true);
    if (method) commit('setOtpMethod', method);
    AgenliteAPIClient.changeTFASetting(getters.getUserId, { state: 'active' }, { method: state.otp.method, otpCode })
      .then(isOtpSet => {
        commit('setLoading', false);
        dispatch('stopOtpTimer');
        dispatch('hideOtpModal');
        if (isOtpSet) {
          dispatch('showFlashAlert', {
            message: 'Perangkat berhasil diverifikasi',
            type: 'success',
          });
        }
        if (state && state.otp && state.otp.redirectRoute) {
          this.$router.push(state.otp.redirectRoute);
        }
        if (state && state.otp && state.otp.callbackFunc) {
          state.otp.callbackFunc();
        }
        dispatch('resetOtpState');
      })
      .catch(error => {
        const message = 'Gagal melakukan verifikasi perangkat.';
        commit('setLoading', false);
        if (
          error &&
          error.errors &&
          error.meta.http_status === 401 &&
          (error.errors[0].code === 10101 || error.errors[0].code === 10103)
        ) {
          if (error.errors[0].message === 'Token OTP tidak ada') {
            dispatch('showOtpModal');
            dispatch('startOtpTimer', error.meta.wait_time);
            dispatch('hideFlashAlert');
          } else if (error.errors[0].message === 'Token OTP tidak valid') {
            commit('setInvalidOtp', true);
          } else if (error.errors[0].message === 'Perangkat belum divalidasi') {
            AgenliteAPIClient.deleteOtpKey();
            dispatch('showOtpModal');
          } else {
            dispatch('showFlashAlert', {
              message: error.errors[0].message,
              type: 'warning',
            });
            dispatch('hideAppLoading');
          }
          return;
        }
        this.$router.replace('/');
        dispatch('clearOtpFlow');
        dispatch('catchError', { error, message, hideAppLoading: true });
      });
  },

  showOtpModal({ commit }, { phone = null } = {}) {
    if (phone) commit('setOtpOptions', { phone });
    commit('toggleOtpModal', true);
  },

  hideOtpModal({ commit }) {
    commit('toggleOtpModal', false);
  },

  rerequestOtpCode({ dispatch }) {
    dispatch('stopOtpTimer');
    setTimeout(() => {
      dispatch('showOtpModal');
      dispatch('activateTFA', { otpCode: null });
    }, 500);
  },

  startOtpTimer({ commit, dispatch }, defaultTimer = 60) {
    let timer = defaultTimer > 0 ? defaultTimer : 60;
    clearInterval(timerInterval);
    setTimeout(() => {
      commit('setOtpTimeout', false);
    }, 1100);
    timerInterval = setInterval(() => {
      timer -= 1;
      commit('setOtpTimer', timer);
      if (timer <= 0) {
        commit('setLoading', false);
        dispatch('otpTimeout');
      }
    }, 1000);
  },

  stopOtpTimer({ commit }) {
    clearInterval(timerInterval);
    commit('setOtpTimer', 0);
  },

  otpTimeout({ commit, dispatch }) {
    dispatch('stopOtpTimer');
    commit('setOtpTimeout', true);
  },

  clearOtpFlow({ commit, dispatch }) {
    dispatch('stopOtpTimer');
    dispatch('hideOtpModal');
    commit('clearOtpFlow');
  },

  resetOtpState({ commit }) {
    commit('resetOtpState');
  },
};

const mutations = {
  setOtpMethod(state, method) {
    state.otp = {
      ...state.otp,
      method,
    };
  },
  setInvalidOtp(state, isInvalidOtp) {
    state.otp = {
      ...state.otp,
      isInvalidOtp,
    };
  },
  setLoading(state, isLoading) {
    state.otp = {
      ...state.otp,
      isLoading,
    };
  },
  toggleOtpModal(state, isOtpModalOpened) {
    state.otp = {
      ...state.otp,
      isOtpModalOpened,
    };
  },
  setOtpTimer(state, otpTimer) {
    state.otp = {
      ...state.otp,
      otpTimer,
    };
  },
  setOtpTimeout(state, isOtpTimeout) {
    state.otp = {
      ...state.otp,
      isOtpTimeout,
    };
  },
  clearOtpFlow(state) {
    state.otp = {
      ...initialState.otp,
      redirectRoute: state.redirectRoute,
      callbackFunc: state.callbackFunc,
      method: state.method,
      phone: state.phone,
      triggerOnly: state.triggerOnly,
      guardedApiAction: state.guardedApiAction,
      guardedApiPayload: state.guardedApiPayload,
    };
  },
  setRedirectRoute(state, route) {
    state.otp = {
      ...state.otp,
      redirectRoute: route,
    };
  },
  setCallbackFunc(state, callbackFunc) {
    state.otp = {
      ...state.otp,
      callbackFunc,
    };
  },
  setOtpOptions(state, options) {
    state.otp = {
      ...state.otp,
      ...options,
    };
  },
  resetOtpState(state) {
    state.otp = JSON.parse(JSON.stringify(initialState.otp));
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  actions,
  mutations,
};
