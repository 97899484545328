import axios, { AxiosRequestConfig } from 'axios';
import Vue from 'vue';
import { API_TIMEOUT_DURATION, logStdout } from '~/server/helpers/metrics-helper';
import CredentialStorage from '~/api/credential-storage';
import { AccessTokenStorage } from '~/api/v4/storage-type';
import { Scope } from '~/types/api/request-method';
import TokenStorage from '~/types/api/token-storage';
import { UserInfo } from '~/types/api/user-info';
import { APIResponse } from '~/types/api/api-response';

interface AccessTokenManagerConstructor {
  authEndpoint?: string;
  apiEndpoint?: string;
  appId?: string;
  credentials: CredentialStorage;
  storage: AccessTokenStorage;
  redirectUri?: string;
}

interface AuthPayload {
  client_id: string;
  grant_type: 'client_credentials' | 'password' | 'authorization_code' | 'refresh_token';
  username?: string;
  password?: string;
  scope: Scope;
  code?: string;
  redirect_uri?: string;
}

/**
 * @deprecated
 * use new logging library provided later
 */
const authLogger = (name = '[TOKEN_MANAGER]', status = 'ok', endpoint = '', error = {}) => {
  logStdout(name, { endpoint, status, error });
};

class AccessTokenManager {
  static eventBus = new Vue();
  storage: AccessTokenStorage;
  authEndpoint: string;
  appId: string;
  isFetchingToken: boolean;
  /** @deprecated */ login: boolean;
  credentials: CredentialStorage;
  private _apiEndpoint: string;
  private _redirectUri: string;
  /**
   * Creates an AccessTokenManager instance
   *
   */
  constructor(options: AccessTokenManagerConstructor) {
    this.storage = options.storage;
    this.authEndpoint = options.authEndpoint || '/oauth/token';
    this.appId = options.appId || '';
    this.credentials = options.credentials;
    this._apiEndpoint = options.apiEndpoint || '/api';
    this._redirectUri = options.redirectUri || '/authorize';
    this.isFetchingToken = false;
    const { token } = this.storage;
    // Ignore public token initialization in test env, hard to mock
    if (!token && process.env.NODE_ENV !== 'test') {
      this.requestPublicToken();
    }
  }

  get requestOptions(): AxiosRequestConfig {
    const userAgent = process.server ? '' : navigator.userAgent;
    return {
      headers: {
        'User-Agent-Original': userAgent,
        Identity: this.credentials.identity,
      },
    };
  }

  /**
   * Request new token
   */
  fetchFreshToken() {
    if (this.storage.token.refresh_token) {
      return this.requestFromRefreshToken();
    }
    const { token } = this.storage;
    if (token.scope !== 'public') {
      this.destroyToken();
      throw new Error('Authentication failure: illegal token was declared with authenticated scope.');
    }
    return this.requestPublicToken();
  }

  async requestFromRefreshToken() {
    const eventName = 'SecureClient.tokenLoaded';
    if (this.isFetchingToken) {
      return new Promise<TokenStorage>(resolve => {
        AccessTokenManager.eventBus.$on(eventName, (response: TokenStorage) => {
          resolve(response);
        });
      });
    }
    this.isFetchingToken = true;
    const refreshForm = {
      grant_type: 'refresh_token',
      refresh_token: this.storage.token.refresh_token,
    };
    const loggerName = '[TOKEN_MANAGER_REFRESH_TOKEN]';
    try {
      const { data: response } = await axios.post<TokenStorage>(
        this.storage.token.refresh_url || this.authEndpoint,
        refreshForm,
        { ...this.requestOptions, withCredentials: true }
      );
      authLogger(
        // TODO: use new logging library
        loggerName,
        'ok',
        `POST ${this.storage.token.refresh_url || this.authEndpoint}`,
        {}
      );
      const userInfo = await this.fetchUserInfo(response.access_token);
      const mergedResponse = { ...response, ...userInfo };
      this.storage.token = mergedResponse;
      AccessTokenManager.eventBus.$emit(eventName, mergedResponse);
      return mergedResponse;
    } catch (err) {
      authLogger(loggerName, 'fail', `POST ${this.storage.token.refresh_url || this.authEndpoint}`, err);
      this.storage.clear();
      // do not redirect from access token manager - let it bubble to parent - on requestmanager
      // redirect('/logout');
      // AccessTokenManager.eventBus.$emit('webview:refresh:error', { error: err, options: {} });
      throw err;
    } finally {
      this.isFetchingToken = false;
    }
  }

  async requestPublicToken() {
    const form = {
      client_id: this.appId,
      grant_type: 'client_credentials',
    };
    const { data: response } = await axios.post<TokenStorage>(this.authEndpoint, form, this.requestOptions);
    this.storage.token = response;
    return response;
  }

  /**
   * Get token from storage or request new token
   */
  fetchToken(scope: Scope = 'public') {
    if (this.storage.isExpired() || !this.storage.isScopeMatch(scope)) {
      return this.fetchFreshToken();
    }
    return Promise.resolve<TokenStorage>(this.storage.token);
  }

  /** @deprecated */
  async authenticate(username: string, password: string, alternative: boolean = false) {
    const eventName = 'SecureClient.authorized';
    const { token } = this.storage;
    if (this.login) return token;
    this.destroyToken();
    const otpStorage = JSON.parse(window.localStorage.getItem('otp_storage') || '{}');
    const otpPayload = !alternative ? { provider: 'otp', otp_key: otpStorage?.[0]?.key ?? '' } : {};
    const requestObject: AuthPayload = {
      client_id: this.appId,
      grant_type: 'password',
      username,
      password,
      scope: 'public user store',
      ...otpPayload,
    };

    const loggerName = '[TOKEN_MANAGER_AUTHENTICATE]';
    const timer = setTimeout(() => {
      throw new Error('Authenticate Timeout');
    }, API_TIMEOUT_DURATION);

    const { data: tokenData } = await axios.post<TokenStorage>(this.authEndpoint, requestObject, this.requestOptions);
    authLogger(
      // TODO: use new logging library
      loggerName,
      'ok',
      `POST ${this.authEndpoint}`,
      {}
    );
    const userInfo = await this.fetchUserInfo(tokenData.access_token);
    const mergedResponse = { ...tokenData, ...userInfo };
    this.storage.token = mergedResponse;
    AccessTokenManager.eventBus.$emit(eventName, mergedResponse);
    this.login = true;
    clearTimeout(timer);
    return mergedResponse;
  }

  /**
   * Authenticate Via Bukalapak OAuth callback code
   */
  async authenticateViaCode(authCode: string, redirectUri = this._redirectUri) {
    const eventName = 'SecureClient.authorized';
    // Clear previous stalled auth when re-authenticate
    this.destroyToken();
    const authForm: AuthPayload = {
      client_id: this.appId,
      grant_type: 'authorization_code',
      scope: 'public user store',
      code: authCode,
      redirect_uri: redirectUri,
    };

    const loggerName = '[TOKEN_MANAGER_AUTHENTICATE_CODE]';
    const timer = setTimeout(() => {
      throw new Error('OAuth Authenticate Timeout');
    }, API_TIMEOUT_DURATION);

    // Begin auth
    try {
      const { data: tokenData } = await axios.post<TokenStorage>(this.authEndpoint, authForm, this.requestOptions);
      authLogger(
        // TODO: use new logging library
        loggerName,
        'ok',
        `POST ${this.authEndpoint}`,
        {}
      );
      // User Info
      const userInfo = await this.fetchUserInfo(tokenData.access_token);
      const mergedResponse = { ...tokenData, ...userInfo };
      this.storage.token = mergedResponse;
      AccessTokenManager.eventBus.$emit(eventName, mergedResponse);
      clearTimeout(timer);
      return tokenData;
    } catch (error) {
      authLogger(loggerName, 'fail', `POST ${this.authEndpoint}`, error); // TODO: use new logging library
      clearTimeout(timer);
      throw error;
    }
  }

  async fetchUserInfo(token?: string) {
    const loggerName = '[TOKEN_MANAGER_USER_INFO]';
    // Try for userInfo
    try {
      const { data: userData } = await axios.get<APIResponse<UserInfo>>(`${this._apiEndpoint}/user-profiles`, {
        params: { access_token: token || this.storage.token.access_token },
        withCredentials: true,
      });
      authLogger(loggerName, 'ok', `GET ${this._apiEndpoint}/user-profiles`, {}); // TODO: use new logging library
      return { userId: userData.data?.id, username: userData.data?.username };
    } catch (error) {
      authLogger(loggerName, 'fail', `GET ${this._apiEndpoint}/user-profiles`, error); // TODO: use new logging library
      throw error;
    }
  }

  /**
   * @deprecated
   * use storage.clear() directly instead
   **/
  destroyToken() {
    this.login = false;
    this.storage.clear();
  }
}

export default AccessTokenManager;
