import AgenliteAPIClient from '~/api/index';

const initialState = {
  transaction: {},
};

const getters = {
  getQrisCashOutTransaction(state) {
    return state.transaction;
  },
};

const actions = {
  async retrieveQrisCashOutTransaction({ dispatch, commit }, requestPayload) {
    try {
      const response = await AgenliteAPIClient.getQrisCashOutTransaction(requestPayload.id);
      commit('setQrisCashOutTransaction', response.data);
    } catch (error) {
      const message = 'Gagal mendapatkan data transaksi';
      dispatch('catchError', { error, message });
      throw error;
    }
  },
};

const mutations = {
  setQrisCashOutTransaction(state, transaction) {
    state.transaction = transaction;
  },
};

export default {
  state: () => ({ ...initialState }),
  mutations,
  getters,
  actions,
};
