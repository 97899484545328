import r from '~/utils/render-utils';

const PagePreSubscription = () => import(/* webpackChunkName: 'referral-pre-page' */ '~/pages/Referral/PreSubscription');
const PageReferralJoin = () => import(/* webpackChunkName: 'referral-join-page' */ '~/pages/Referral/Join');
const PageReferralLanding = () => import(/* webpackChunkName: 'referral-landing-page' */ '~/pages/Referral/Landing');
const PageReferralPerformance = () => import(/* webpackChunkName: 'referral-performance-page' */ '~/pages/Referral/Performance');
const PageReferralCommission = () => import(/* webpackChunkName: 'referral-commission-page' */ '~/pages/Referral/Commission');
const PageReferralClaim = () => import(/* webpackChunkName: 'referral-claim-page' */ '~/pages/Referral/Claim');
const PageOnboarding = () => import(/* webpackChunkName: 'referral-onboarding-page' */ '~/pages/Referral/Onboarding');

const routes = [
  {
    path: '/referral',
    name: 'referral_landing_webview_redirector',
    redirect: '/referral/landing',
  },
  { path: '/referral/onboarding', component: r(PageOnboarding), name: 'referral_onboarding' },
  { path: '/referral/pre', component: r(PagePreSubscription), name: 'referral_onboarding_old' },
  { path: '/referral/join', component: r(PageReferralJoin), name: 'referral_checkout' },
  { path: '/referral/landing', component: r(PageReferralLanding), name: 'referral_landing' },
  { path: '/referral/performance', component: r(PageReferralPerformance), name: 'referral_performance' },
  { path: '/referral/komisi', component: r(PageReferralCommission), name: 'referral_commission' },
  { path: '/referral/share', component: r(PageReferralLanding), name: 'referral_share' },
  { path: '/referral/claim', component: r(PageReferralClaim), name: 'referral_claim_platinum' },
];

export default routes;
