import Client from '~/types/api/api-module';
import ApiOptions from '../../api-options';

const URL = `${process.env.API_V4_HOST}/_exclusive/neo`;

export default Client.wrap({
  fetchNeoToggles(toggleIds, platform = 'bl_app_pwa_mitra_android') {
    const requestPayload = { id: toggleIds, platform };
    return this.post(`${URL}/toggles`, 'user', new ApiOptions({ requestPayload }));
  },
  fetchNeoConfigs(configs) {
    const requestPayload = { id: configs };
    return this.post(`${URL}/configs`, 'user', new ApiOptions({ requestPayload }));
  },
});
