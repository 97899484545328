/**
 * @module helpers/date-helper
 */

export const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'];
export const listOfMonths = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
];
export const monthShorts = ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'];

const localeTZ = {
  'GMT+0000': '',
  'GMT+0700': 'WIB',
  'GMT+0800': 'WITA',
  'GMT+0900': 'WIT',
};
/**
 * Return local machine GMT
 *
 * @param  {String} javascript valid Date format
 * @return {String} Timezone. e.g. GMT+0700
 */

export function getLocalGMT(timestamp: string): string {
  const gmtReg = /GMT([-+]?\d{4})/;
  return gmtReg.exec(timestamp)?.[0] || '';
}

/**
 * Return human readable timestamp format
 *
 * @example
 * humanizeTimestamp('2017-06-23T14:15:53.886+07:00', '%day% %dd% %mmm% %yyyy% - %hh%:%mi% WIB')
 * // => Jumat 23 Jun 2017 - 14:15 WIB
 *
 * @param  {String|Date} timestamp timestamp format
 * @param  {String} format specific wanted format to humanize the timestamp (in-casesensitive)
 *
 * @return {String}
 */
export function humanizeTimestamp(timestamp: string | Date, format: string = '%day%, %dd% %month% %yyyy%'): string {
  const dateTime = new Date(timestamp);
  const timeMapping = {
    '%hh%': `0${dateTime.getHours()}`.slice(-2),
    '%mi%': `0${dateTime.getMinutes()}`.slice(-2),
    '%d%': dateTime.getDate(),
    '%dd%': `0${dateTime.getDate()}`.slice(-2),
    '%day%': days[dateTime.getDay()],
    '%mm%': `0${dateTime.getMonth() + 1}`.slice(-2),
    '%mmm%': monthShorts[dateTime.getMonth()],
    '%month%': listOfMonths[dateTime.getMonth()],
    '%yyyy%': dateTime.getFullYear(),
    '%yy%': `${dateTime.getFullYear()}`.slice(-2),
    '%tz%': localeTZ[getLocalGMT(dateTime.toString())],
  };
  return format.replace(/%(.*?)%/gi, n => timeMapping[n]);
}

function prependZero(num: number) {
  return Number(num) < 10 ? `0${Number(num).toString()}` : Number(num).toString();
}

/**
 * Time Filter
 * @param value {number|Date|string} ISO 8601 format, e.g '2017-01-31T02:30:00+07:00'
 * @retrun hour:minute e.g '02:30'
 */
export function customTime(value: number | Date | string) {
  if (value === undefined) {
    throw new Error('value cant be null');
  }

  if (typeof value === 'number') {
    throw new TypeError('type must be string or date');
  }

  if (value instanceof Date) {
    return `${prependZero(value.getHours())}:${prependZero(value.getMinutes())}`;
  }
  const date = new Date(value);
  return `${prependZero(date.getHours())}:${prependZero(date.getMinutes())}`;
}

type TimeType = 's' | 'ms' | 'days';

export function timeDiff(start: number, end: number, unit: TimeType = 'ms') {
  const diff = end - start;

  switch (unit) {
    case 's':
      return diff / 1000;
    case 'days':
      return Math.ceil(diff / (1000 * 3600 * 24));
    default:
      return diff;
  }
}

export function timeDiffSeconds(start: number, end: number) {
  return timeDiff(start, end, 's');
}

/**
 * @deprecated Just use new Date().getTime() directly.
 *
 * @description Returns the number of milliseconds between midnight of January 1, 1970 and the specified date
 */
export function getCurrentTime() {
  return new Date().getTime();
}

export function getDaysInMonth(monthIdx: number, year: number) {
  return new Date(year, monthIdx + 1, 0).getDate();
}

export function listOfLast100Years(): number[] {
  const years: Array<number> = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i >= currentYear - 100; i -= 1) {
    years.push(i);
  }
  return years;
}

export default {
  listOfMonths,
  listOfLast100Years,
  humanizeTimestamp,
  customTime,
  timeDiff,
  timeDiffSeconds,
  getLocalGMT,
  getDaysInMonth,
};
