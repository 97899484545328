import r from '~/utils/render-utils';

const Tutorial = () => import(/* webpackChunkName: 'tutorial-page' */ '~/pages/Tutorial/index');

const tutorial = [
  {
    path: '/tutorial',
    component: r(Tutorial),
    name: 'tutorial_page',
  },
];

export default tutorial;
