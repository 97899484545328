import r from '~/utils/render-utils';

const LeaderboardIndex = () => import(/* webpackChunkName: 'leaderboard-page' */ '~/pages/Leaderboard/index');
const LeaderboardGuidance = () => import(/* webpackChunkName: 'leaderboard-guidance-page' */ '~/pages/Leaderboard/LeaderboardGuidance');

const leaderboard = [
  {
    path: '/leaderboard',
    component: r(LeaderboardIndex),
    name: 'leaderboard_landing_page',
  },
  {
    path: '/leaderboard-guidance',
    component: r(LeaderboardGuidance),
    name: 'leaderboard_guidance_page',
  },
  {
    path: '/leaderboard-guidance/:level',
    component: r(LeaderboardGuidance),
    name: 'leaderboard_guidance_tab_level',
  },
];

export default leaderboard;
