import cart from './cart';
import productList from './product-list';
import searchResult from './search-result';
import freeGoodSampling from './free-good-sampling';

export {
  productList,
  cart,
  searchResult,
  freeGoodSampling,
};
