/**
 * @module plugins/BLRemodal
 */

import BLCRemodal, { remodalEventBus } from '~/components/shared/compound/BLCRemodal';

export const BLRemodal = {
  show(name) {
    remodalEventBus.$emit('toggle', name, true);
  },
  hide(name) {
    remodalEventBus.$emit('toggle', name, false);
  },
  toggle(name) {
    remodalEventBus.$emit('toggle', name, undefined);
  },
};

/**
 * BLRemodal Plugin
 *
 * @example
 * // Install the plugin
 * import BLRemodalPlugin from '~/components/shared/plugins/BLRemodal';
 * Vue.use(BLRemodalPlugin);
 *
 * // Use BLCRemodal global component inside your template
 * // Make sure to name it accordingly
 * <template>
 *    <BLCRemodal name="confirmation-modal" />
 * </template>
 *
 * // Using the plugin inside component method / lifecycle
 * this.$BLRemodal.show('confirmation-modal')
 * this.$BLRemodal.hide('confirmation-modal')
 * this.$BLRemodal.toggle('confirmation-modal')
 */
const BLRemodalPlugin = {
  install(vue) {
    // attach as instance method
    vue.prototype.$BLRemodal = BLRemodal;

    vue.component('BLCRemodal', BLCRemodal);
  },
};

export default BLRemodalPlugin;
