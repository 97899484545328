import Client from '~/types/api/api-module';
import { MitraPosterResponse } from '~/types/api/posters';
import ApiOptions from '../../api-options';

const MITRA_POSTERS_URL = `${process.env.API_V4_HOST}/_exclusive/posters`;

export const MitraPosters = Client.wrap({
  retrieveAllPromotionMediaPosters(requestPayload) {
    return this.get<Array<MitraPosterResponse>>(MITRA_POSTERS_URL, 'user', new ApiOptions({ requestPayload }));
  }
});
