import r from '~/utils/render-utils';

const SubscriptionPacketIndex = () => import(/* webpackChunkName: 'subscription'  */ '~/pages/Subscription/index.vue');
const SubscriptionBuyPacket = () => import(/* webpackChunkName: 'subscription'  */ '~/pages/Subscription/buy.vue');
const SubscriptionActivePacket = () =>
  import(/* webpackChunkName: 'subscription'  */ '~/pages/Subscription/active.vue');
const SubscriptionFaq = () => import(/* webpackChunkName: 'subscription'  */ '~/pages/Subscription/faq.vue');
const SubscriptionActiveDetail = () => import(/* webpackChunkName: 'subscription' */ '~/pages/Subscription/detail.vue');
const SubscriptionCheckout = () => import(/* webpackChunkName: 'subscription'  */ '~/pages/Subscription/checkout.vue');
const SubscriptionTopSpender = () =>
  import(/* webpackChunkName: 'subscription'  */ '~/pages/Subscription/TopSpender/index.vue');
const SubscriptionTopSpenderLanding = () =>
  import(/* webpackChunkName: 'subscription'  */ '~/pages/Subscription/TopSpender/landing.vue');
const SubscriptionTopSpenderTutorial = () =>
  import(/* webpackChunkName: 'subscription'  */ '~/pages/Subscription/TopSpender/Tutorial');
const SubscriptionTopSpenderFaq = () =>
  import(/* webpackChunkName: 'subscription'  */ '~/pages/Subscription/TopSpender/faq.vue');
const SubscriptionTopSpenderCommissionTerms = () =>
  import(/* webpackChunkName: 'subscription'  */ '~/pages/Subscription/TopSpender/commissionTerms.vue');
const SubscriptionTopSpenderCommissionDetail = () =>
  import(/* webpackChunkName: 'subscription'  */ '~/pages/Subscription/TopSpender/commissionDetail.vue');
const SubscriptionInstantRefund = () =>
  import(/* webpackChunkName: 'subscription' */ '~/pages/Subscription/InstantRefund/index.vue');
const SubscriptionInstantRefundDetail = () =>
  import(/* webpackChunkName: 'subscription' */ '~/pages/Subscription/InstantRefund/Detail.vue');
const SubscriptionInstantRefundFaq = () =>
  import(/* webpackChunkName: 'subscription'  */ '~/pages/Subscription/InstantRefund/Faq.vue');

const subscription = [
  {
    path: '/subscription',
    component: r(SubscriptionPacketIndex),
    name: 'subscription-index',
    children: [
      {
        path: '/subscription/buy',
        component: r(SubscriptionBuyPacket),
        name: 'subscription-buy',
      },
      {
        path: '/subscription/active',
        component: r(SubscriptionActivePacket),
        name: 'subscription-active',
      },
    ],
  },
  {
    path: '/subscription/faq',
    component: r(SubscriptionFaq),
    name: 'subscription-faq',
  },
  {
    path: '/subscription/top-spender/tutorial',
    component: r(SubscriptionTopSpenderTutorial),
    name: 'subscription-top-spender-tutorial',
  },
  {
    path: '/subscription/instant-refund',
    component: r(SubscriptionInstantRefund),
    name: 'subscription-instant-refund',
  },
  {
    path: '/subscription/instant-refund/detail',
    component: r(SubscriptionInstantRefundDetail),
    name: 'subscription-instant-refund-detail',
  },
  {
    path: '/subscription/instant-refund/faq',
    component: r(SubscriptionInstantRefundFaq),
    name: 'subscription-instant-refund-faq',
  },
  {
    path: '/subscription/top-spender',
    component: r(SubscriptionTopSpender),
    name: 'subscription-top-spender',
  },
  {
    path: '/subscription/top-spender/landing',
    component: r(SubscriptionTopSpenderLanding),
    name: 'subscription-top-spender-landing',
  },
  {
    path: '/subscription/top-spender/faq',
    component: r(SubscriptionTopSpenderFaq),
    name: 'subscription-top-spender-faq',
  },
  {
    path: '/subscription/top-spender/commission-terms',
    component: r(SubscriptionTopSpenderCommissionTerms),
    name: 'subscription-top-spender-commission-terms',
  },
  {
    path: '/subscription/top-spender/commission-detail',
    component: r(SubscriptionTopSpenderCommissionDetail),
    name: 'subscription-top-spender-commission-detail',
  },
  {
    path: '/subscription/:dealId',
    component: r(SubscriptionActiveDetail),
    name: 'subscription-detail',
  },
  {
    path: '/subscription/checkout/:dealId',
    component: r(SubscriptionCheckout),
    name: 'subscription-checkout',
  },
];

export default subscription;
