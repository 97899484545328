import {
  PublicGetCampaignInfoResponse,
} from '@bukalapak/openapi-schema/reusable/subsidy/v1/public/subsidy-domain';
import SubsidyDomainAPIClient from '~/api/subsidy-domain';

const initialState = {
  campaign: {},
};

const getters = {
  getCampaign(state) {
    return state.campaign;
  }
};

const actions = {
  async fetchCampaign({ dispatch, commit }, id) {
    let response: PublicGetCampaignInfoResponse = {};

    try {
      response = await SubsidyDomainAPIClient.getCampaign(id);
      commit('setCampaign', response.campaign);
    } catch (error) {
      const message = 'Gagal mendapatkan data voucher';
      dispatch('catchError', { error, message });
      throw error;
    }
  },
};

const mutations = {
  setCampaign(state, campaign) {
    state.campaign = campaign;
  },
};

export default {
  state: () => ({ ...initialState }),
  mutations,
  getters,
  actions,
};