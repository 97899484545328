import { ALLOWLIST_FEATURE } from '~/utils/subscription';

interface CheckoutList {
  [dealId: string]: string;
}

interface FeatureBenefit {
  title: string;
  subtitle: string;
  detail_link: string;
  detail_text: string;
}

interface Feature {
  feature: string;
  image_url: string;
  subtitle: string;
  benefits: FeatureBenefit[];
}

interface SubscriptionDetail extends Feature {
  skus: any[];
  price: number;
  name: string;
}

type InitialState = {
  checkoutList: CheckoutList;
  featureList: Feature[];
  subscriptionDetail: SubscriptionDetail;
  recoName: string;

  loading: {
    checkoutFeatureList: boolean;
    subscriptionDetail: boolean;
  };
};

const initialState: InitialState = {
  checkoutList: {},
  featureList: [],
  subscriptionDetail: {
    feature: '',
    image_url: '',
    subtitle: '',
    benefits: [],
    skus: [],
    price: 0,
    name: '',
  },
  recoName: '',

  loading: {
    checkoutFeatureList: true,
    subscriptionDetail: true,
  },
};

const getters = {
  getSubscriptionDetail(state: InitialState) {
    return state.subscriptionDetail;
  },
  getSubscriptionDetailRecoName(state: InitialState) {
    return state.recoName;
  },
  getSubscriptionDealIdByFeature: (state: InitialState) => (feature: string) => {
    return Object.keys(state.checkoutList).find(key => state.checkoutList[key] === feature) || '';
  },
  getSubscriptionLoading(state: InitialState) {
    return state.loading;
  },
};

const actions = {
  async retrieveCheckoutFeatureList({ commit, dispatch }) {
    let response;

    commit('setLoadingState', { name: 'checkoutFeatureList', value: true });
    try {
      response = await dispatch('fetchNeoConfigs', ['subscription-feature-list', 'subscription-checkout-list']);
      const featureList = response?.[0]?.data?.data;
      const checkoutList = response?.[1]?.data;

      commit('setCheckoutList', checkoutList);
      commit('setFeatureList', featureList);
    } catch (error) {
      const message = 'Gagal Mendapatkan data.';
      dispatch('catchError', { error, message, noFlashAlert: false });
    } finally {
      commit('setLoadingState', { name: 'checkoutFeatureList', value: false });
    }
    return { response };
  },
  async retrieveSubscriptionDetail({ commit, dispatch, state }, dealId: number) {
    let response;

    commit('setLoadingState', { name: 'subscriptionDetail', value: true });
    try {
      response = await dispatch('fetchDealsDetail', dealId);
      const dealsDetail = response?.data;

      const feature: string = state.checkoutList[dealId];
      const featureDetail: Feature = state.featureList.find(f => f.feature === feature);

      const subscriptionDetail: SubscriptionDetail = {
        ...featureDetail,
        skus: dealsDetail?.skus,
        price: dealsDetail?.price,
        name: dealsDetail?.name,
      };

      commit('setSubscriptionDetail', subscriptionDetail);
    } catch (error) {
      const message = 'Gagal Mendapatkan data.';
      dispatch('catchError', { error, message, noFlashAlert: false });
    } finally {
      commit('setLoadingState', { name: 'subscriptionDetail', value: false });
    }
    return { response };
  },
  async checkSubscriptionDetailRecommendation({ state, commit, dispatch }) {
    switch (state.subscriptionDetail?.feature) {
      case ALLOWLIST_FEATURE.REFUND_STANDALONE: {
        const response = await dispatch('retrieveAllowlistMeFeature', { feature: ALLOWLIST_FEATURE.JAGOAN_VIRTUAL });
        const isActive = response?.data?.value === 'allowed';

        if (isActive) {
          commit('setRecoName', 'jagoan_downsell');
        }
        break;
      }
      case ALLOWLIST_FEATURE.JAGOAN_VIRTUAL: {
        const response = await dispatch('retrieveAllowlistMeFeature', { feature: ALLOWLIST_FEATURE.REFUND_STANDALONE });
        const isActive = response?.data?.value === 'allowed';

        if (isActive) {
          commit('setRecoName', 'jagoan_upsell');
        }
        break;
      }
    }
  },
};

const mutations = {
  setCheckoutList(state, value: CheckoutList) {
    state.checkoutList = value;
  },
  setFeatureList(state, value: Feature[]) {
    state.featureList = value;
  },
  setSubscriptionDetail(state, value: SubscriptionDetail) {
    state.subscriptionDetail = value;
  },
  setRecoName(state, value: string) {
    state.recoName = value;
  },
  setLoadingState(state, payload: { name: 'checkoutFeatureList' | 'subscriptionDetail'; value: boolean }) {
    state.loading[payload.name] = payload.value;
  },
};

export default {
  state: () => ({ ...initialState }),
  mutations,
  getters,
  actions,
};
