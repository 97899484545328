import ApiOptions from '~/api/v4/api-options';
import Client from '~/types/api/api-module';

const CAMPAIGN_URL = `${process.env.API_SUBSIDY_DOMAIN_HOST}/v1/subsidies/campaigns`;

export default Client.wrap({
  getCampaign(id: string) {
    return this.get(`${CAMPAIGN_URL}/${id}?include_banner=true`, 'subsidy.*.*', { enableAuthHeader: true, ...new ApiOptions() });
  },
});
