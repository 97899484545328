import phonePrefixes from '~/constants/phone/prefixes.json';
import AgenliteAPIClient from '~/api';

const initialState = {
  isLoaded: false,
  vpProducts: null,
  phonePrefixes,
};

const getters = {
  getPhoneCreditPackages(state) {
    if (state.isLoaded) {
      return state.vpProducts.phoneCredits;
    }
    return null;
  },
  getPhoneOperatorPrefixes(state) {
    return state.phonePrefixes;
  },
  getDataPlanPackages(state) {
    if (state.isLoaded) {
      return state.vpProducts.dataPlans;
    }
    return null;
  },
  getPrepaidElectricityPackages(state) {
    if (state.isLoaded) {
      return state.vpProducts.electricities;
    }
    return null;
  },
};

const actions = {
  async loadVPProducts({ commit, dispatch }) {
    try {
      const [phoneCredits, dataPlans, electricityPrepaid] = await Promise.all([
        AgenliteAPIClient.fetchPhoneCreditProducts({ category: 'topup', group: 'agent' }),
        AgenliteAPIClient.fetchDataPlanProducts({ category: 'package', group: 'agent' }),
        AgenliteAPIClient.getElectricityPrepaidProducts({ group: 'agent' }),
      ]);

      const reducePhoneType = (current, next) => {
        const idx = current.findIndex(c => c.operator === next.operator.name.toLowerCase());

        if (idx !== -1) {
          current[idx].items.push({
            ...next.partner_package.package,
            nominal: next.nominal,
          });
        } else {
          current.push({
            operator: next.operator.name.toLowerCase(),
            items: [
              {
                ...next.partner_package.package,
                nominal: next.nominal,
              },
            ],
          });
        }

        return current;
      };

      commit('setVirtualProductConfigs', {
        phoneCredits: phoneCredits.data.reduce(reducePhoneType, []),
        dataPlans: dataPlans.data.reduce(reducePhoneType, []),
        electricities: electricityPrepaid.data,
      });
    } catch (e) {
      dispatch('catchError', { message: 'Terjadi kesalahan.' });
    }
  },
};

const mutations = {
  setVirtualProductConfigs(state, configs) {
    state.vpProducts = { ...configs };
    state.isLoaded = true;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
