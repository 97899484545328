import trackerRouteMapper from '~/utils/tracker-route-mapper.json';
import tracker from '~/mixins/tracker';
import { getScreenName } from '~/utils/bookkeeping-screen-name';
import { getVpCatalogScreenName } from '~/utils/vp-catalog-screen-name';

const DUE_DEBT_CALENDAR_ROUTE_NAME = 'due_debt_calendar'; // This const will be useless once bookkeeping migrated to calamus

export default function ({ app, route, store, query }) {
  if (process.server) return; // TODO: Enable trackers to be available server-side
  if (route.query.flash_message) return;
  if (app.$pageReferrer?.name === route.name && route.name !== DUE_DEBT_CALENDAR_ROUTE_NAME) return; // Handle same route with only different hash

  const referrerActions = [
    {
      expr: () => app.$pageReferrer?.name,
      value: () => ({ name: app.$pageReferrer.name, url: app.$pageReferrer.url, type: app.$pageReferrer.type }),
    },
    {
      expr: () => !document.referrer && route.query.from,
      value: () => ({ name: route.query.from, url: '', type: 'param_from' }),
    },
    {
      expr: () => document.referrer !== '' && !document.referrer.includes(window.location.origin),
      value: () => ({ name: 'referred', url: document.referrer, type: 'external_link' }),
    },
    {
      expr: () => document.referrer !== '' && document.referrer.includes(window.location.origin),
      value: () => ({ name: 'other', url: document.referrer, type: 'internal_link' }),
    },
  ];
  const referrer = referrerActions.find(item => item.expr())?.value() ?? { name: 'organic', url: '', type: 'direct' };

  app.$pageReferrer = { name: route.name, url: route.path, type: 'internal_link' };

  const trackData = { journeyId: store.getters.getTrackerJourneyId, route, referrer, store };

  if (store.$router?.history?.current?.meta?.bookkeeping) {
    trackData.params = {
      ...query,
      coachmark_available: store.getters?.isBookkeepingCoachmarkActive,
    };
  }

  const registerNewUserRoutes = ['register_phone', 'register_registered', 'register_form'];
  const topupRelatedRoutes = [
    'top_up_dompet',
    'top_up_dompet_dana',
    'top_up_dompet_select',
    'agenlite_apps_lite_saldo_activation',
    'agenlite_apps_lite_saldo_activation_TNC',
    'agenlite_apps_lite_saldo_bind_dana',
  ];
  const profitReportsRelatedRoutes = ['pembukuan_mitra_landing'];
  const topUpOnDemandRelatedRoutes = ['topup_ondemand_landing'];

  const nonTrackedRoutes = [
    'loyalty_tiering_landing_page',
    'mini_faq_landing',
    'prepaid_phone_transact',
    'vp_trx_detail', // exclude this so we can customise screen name for different virtual products
    'topup_digital_transact',
    'mitra-branch-index',
    'mitra-branch-staff',
    'mitra-branch-owner',
    'mitra-branch-active',
    'mitra-branch-pending',
    'mitra-branch-onboarding',
    'mitra-branch-faq',
    'mitra-branch-staff-add',
    'mitra-branch-staff-add-confirmation',
    'mitra-branch-staff-send-balance',
    'mitra-branch-staff-send-balance-detail',
    'mitra-branch-staff-transactions',
    'mitra-branch-staff-detail',
    'mitra-branch-staff-update-branch',
    'mitra-branch-staff-request',
    'mitra-branch-staff-request-confirmation',
    'mitra-branch-faq-owner',
    'mitra-branch-faq-staff'
  ];

  if (
    ['organic', 'referred', 'authorize_page'].includes(referrer.name) &&
    !['authorize_page', 'logout'].includes(route.name)
  ) {
    tracker.methods.trackOpenedSession.call(app, trackData);
  }
  if (nonTrackedRoutes.includes(route.name)) {
    // SKIP
  } else if (route.name === 'mitra_activation') {
    tracker.methods.trackLoginRegisterVisit.call(app, 'register_mitra_only', trackData);
  } else if (registerNewUserRoutes.includes(route.name)) {
    tracker.methods.trackLoginRegisterVisit.call(app, 'register_new_user', trackData);
  } else if (topupRelatedRoutes.includes(route.name)) {
    tracker.methods.trackTopupVisit.call(app, trackData);
  } else if (profitReportsRelatedRoutes.includes(route.name)) {
    tracker.methods.trackProfitReportsVisit.call(app, trackData);
  } else if (topUpOnDemandRelatedRoutes.includes(route.name)) {
    // intentionally ignored to avoid double tracker call
    // will send tracker when page mounted because the needs of data fetching
  } else if (route.name === DUE_DEBT_CALENDAR_ROUTE_NAME) {
    tracker.methods.trackScreenVisit.call(app, { ...trackData, currentScreen: getScreenName(route) });
  } else if (route.name.includes('promotion_media_vp_catalog')) {
    tracker.methods.trackScreenVisit.call(app, { ...trackData, currentScreen: getVpCatalogScreenName(route) });
  } else {
    const currentScreen = trackerRouteMapper[route.name] || route.name;
    tracker.methods.trackScreenVisit.call(app, { ...trackData, currentScreen });
  }
  store.commit('saveCurrentRoute', { route: { path: route.path, name: route.name }, referrer });
}
