import ApiOptions from '../api-options';

const GENERAL_TRADE_URL = `${process.env.API_V4_HOST}/general-trade`;
const INFO_URL = `${GENERAL_TRADE_URL}/info`;

export default {
  getProvince() {
    return this.get(`${INFO_URL}/provinces`, 'user', new ApiOptions());
  },
  getCity(requestPayload) {
    return this.get(`${INFO_URL}/cities`, 'user', new ApiOptions({ requestPayload }));
  },
  getMultipleCity(requestPayload) {
    return this.post(`${INFO_URL}/cities`, 'user', new ApiOptions({ requestPayload }));
  },
  getArea(requestPayload) {
    return this.get(`${INFO_URL}/area`, 'user', new ApiOptions({ requestPayload }));
  },
  getDistrict(requestPayload) {
    return this.get(`${INFO_URL}/districts`, 'user', new ApiOptions({ requestPayload }));
  },
  getPostCode(requestPayload) {
    return this.get(`${INFO_URL}/post-codes`, 'user', new ApiOptions({ requestPayload }));
  },
};
