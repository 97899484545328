import ApiOptions from '~/api/v4/api-options';
import Client from '~/types/api/api-module';

const INVOICE_DETAILS_URL = `${process.env.API_PAYMENT_HOST}/v1/payment/invoice-groups`;

export default Client.wrap({
  getInvoiceDetails(invoiceGroupNumber: string) {
    return this.get(`${INVOICE_DETAILS_URL}/${invoiceGroupNumber}`, 'payment.*.*', { enableAuthHeader: true, ...new ApiOptions() });
  },
});
