import AgenliteAPIClient from '~/api';
import SilkroadAPIClient from '~/api/silkroad';

const initialState = {
  wholesale: {
    productCategories: null,
    products: null,
    mfbProducts: [],
    sellers: [],
    lastSearchParams: null,
    searchResultMeta: null,
    searchKeyword: null,
  },
};

const getters = {
  getWholesaleProductCategories(state) {
    return state.wholesale.productCategories ? state.wholesale.productCategories : [];
  },
  getWholesaleProducts(state) {
    return state.wholesale.products ? state.wholesale.products : [];
  },
  getCategoryName(state) {
    return categoryId => {
      let categoryName = null;
      Object.keys(state.wholesale.productCategories).some(key => {
        if (state.wholesale.productCategories[key].id === categoryId) {
          categoryName = state.wholesale.productCategories[key].name;
          return true;
        }
        return false;
      });
      return categoryName;
    };
  },
  getWholesaleSellers(state) {
    return state.wholesale.sellers;
  },
  getSearchResultMeta(state) {
    return state.wholesale.searchResultMeta;
  },
  getSearchKeyword(state) {
    return state.wholesale.searchKeyword;
  },
  getMfbProducts(state) {
    return state.wholesale.mfbProducts;
  },
};

const actions = {
  getWholesaleSellers({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
      AgenliteAPIClient.getWholesaleSellers()
        .then(response => {
          const sellers = response.data.seller_id;
          commit('setWholesaleSellers', sellers);
          resolve(sellers);
        })
        .catch(error => {
          const message = 'Gagal mendapatkan data grosir.';
          dispatch('catchError', {
            error,
            message,
          });
          reject(error);
        });
    });
  },
  retrieveWholesaleProductCategories({ commit, dispatch }, parentId = null) {
    dispatch('showAppLoading');
    return new Promise((resolve, reject) => {
      SilkroadAPIClient.getWholesaleProductCategories(parentId)
        .then(response => {
          if (parentId === null) {
            commit('setWholesaleProductCategories', response.data);
          } else {
            commit('setWholesaleChildrenCategory', { id: parentId, categories: response.data });
          }
          dispatch('hideAppLoading');
          resolve(response.data);
        })
        .catch(error => {
          const message = 'Gagal mendapatkan data kategori produk.';
          dispatch('catchError', {
            error,
            message,
            hideAppLoading: true,
          });
          reject(error);
        });
    });
  },
  retrieveWholesaleProducts({ commit, dispatch, state }, options) {
    dispatch('showAppLoading');
    return new Promise((resolve, reject) => {
      SilkroadAPIClient.retrieveProducts(options)
        .then(response => {
          if (options && options.offset > 0) {
            commit('setWholesaleProducts', [...state.wholesale.products, ...response.data]);
          } else {
            commit('setWholesaleProducts', response.data);
          }
          commit('setSearchResultMeta', response.meta);
          commit('setLastSearchParams', options);
          dispatch('hideAppLoading');
          resolve(response.data);
        })
        .catch(error => {
          const message = 'Gagal mendapatkan data produk.';
          dispatch('catchError', {
            error,
            message,
            hideAppLoading: true,
          });
          reject(error);
        });
    });
  },
  async retrieveWholesaleProduct({ commit, dispatch }, id) {
    dispatch('showAppLoading');
    try {
      const response = await SilkroadAPIClient.retrieveProduct(id);
      commit('setWholesaleProducts', [response.data]);
      response.meta.total = 1;
      commit('setSearchResultMeta', response.meta);
      dispatch('hideAppLoading');
      return response.data;
    } catch (error) {
      const message = 'Gagal mendapatkan data produk.';
      dispatch('catchError', {
        error,
        message,
        hideAppLoading: true,
      });
      if (error && error.response.status === 404) {
        commit('setWholesaleProducts', []);
        const meta = {
          total: 0,
        };
        commit('setSearchResultMeta', meta);
        return null;
      }
      return error;
    }
  },
  async setReminderStock({ dispatch, commit }, id) {
    dispatch('showAppLoading');
    try {
      const response = await SilkroadAPIClient.setReminderStock(id);
      commit('setWholesaleProduct', response.data);
      dispatch('hideAppLoading');
      return response.data;
    } catch (error) {
      const message = 'Gagal mendapatkan data produk.';
      dispatch('catchError', {
        error,
        message,
        hideAppLoading: true,
      });
      return error;
    }
  },
  clearProductList({ commit }) {
    commit('clearProductList');
  },
  setSearchKeyword({ commit }, keyword) {
    commit('setSearchKeyword', keyword);
  },
  retrieveMfbProducts({ commit, dispatch }, options) {
    dispatch('showAppLoading');
    commit('setMfbProducts', []);
    return new Promise((resolve, reject) => {
      SilkroadAPIClient.retrieveRecommendation('mfb-products', options)
        .then(response => {
          commit('setMfbProducts', response.data);
          dispatch('hideAppLoading');
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

const mutations = {
  setWholesaleProductCategories(state, productCategories) {
    state.wholesale.productCategories = productCategories;
  },
  setWholesaleChildrenCategory(state, { id, categories } = {}) {
    const category = state.wholesale.productCategories.find(c => c.id === id);
    if (category && category.has_children) {
      category.children = categories;
      const index = state.wholesale.productCategories.findIndex(c => c.id === category.id);
      state.wholesale.productCategories = [
        ...state.wholesale.productCategories.filter((_c, i) => i < index),
        category,
        ...state.wholesale.productCategories.filter((_c, i) => i > index),
      ];
    }
    return state.wholesale.productCategories;
  },

  setWholesaleProducts(state, products) {
    state.wholesale.products = products;
  },
  setWholesaleProduct(state, product) {
    const index = state.wholesale.products.findIndex(prd => prd.id === product.id);
    state.wholesale.products = [
      ...state.wholesale.products.slice(0, index),
      product,
      ...state.wholesale.products.slice(index + 1),
    ];
  },
  clearProductList(state) {
    state.wholesale.products = null;
  },
  setWholesaleSellers(state, sellers) {
    state.wholesale.sellers = sellers;
  },
  setLastSearchParams(state, lastSearchParams) {
    state.wholesale.lastSearchParams = lastSearchParams;
  },
  setSearchResultMeta(state, searchResultMeta) {
    state.wholesale.searchResultMeta = searchResultMeta;
  },
  resetState(state) {
    state.wholesale = JSON.parse(JSON.stringify(initialState.wholesale));
  },
  setSearchKeyword(state, keyword) {
    state.wholesale.searchKeyword = keyword;
  },
  setMfbProducts(state, products) {
    state.wholesale.mfbProducts = products;
    return state.wholesale.mfbProducts;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
