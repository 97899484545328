import ApiOptions from '~/api/v4/api-options';
import Client from '~/types/api/api-module';

const REPORT_URL = `${process.env.API_V4_HOST}/agents/profit-reports`;

export default Client.wrap({
  getProfitReportsSummaries(period) {
    return this.get(
      `${REPORT_URL}/summaries`,
      'user',
      new ApiOptions({
        requestPayload: { period },
      })
    );
  },
  getProfitReportsDetails(payload) {
    const { startDate, endDate, productType } = payload;
    const requestPayload = {
      start_date: startDate,
      end_date: endDate,
      product_type: productType,
    };
    return this.get(
      `${REPORT_URL}/details`,
      'user',
      new ApiOptions({
        requestPayload,
      })
    );
  },
  getProfitReportsOverviews(period) {
    return this.get(
      `${REPORT_URL}/overviews`,
      'user',
      new ApiOptions({
        requestPayload: { period },
      })
    );
  },
});
