import r from '~/utils/render-utils';

// Super Warung
const SuperWarung = () => import(/* webpackChunkName: 'super-warung'  */ '~/pages/SuperWarung.vue');
const SuperWarungNoCoverage = () => import(/* webpackChunkName: 'super-warung-no-coverage'  */ '~/pages/SuperWarung/NoCoverage.vue');
const SuperWarungNoAddress = () => import(/* webpackChunkName: 'super-warung-no-address'  */ '~/pages/SuperWarung/NoAddress.vue');
const SuperWarungOnboarding = () => import(/* webpackChunkName: 'super-warung-onboarding'  */ '~/pages/SuperWarung/Onboarding.vue');
const SuperWarungVerifiedOnboarding = () => import(/* webpackChunkName: 'super-warung-onboarding'  */ '~/pages/SuperWarung/VerifiedOnboarding.vue');



const superWarung = [
  {
    path: '/super-warung/onboarding',
    component: r(SuperWarungOnboarding),
    name: 'SWOnboarding',
  },
  {
    path: '/super-warung',
    component: r(SuperWarung),
    name: 'super_warung',
  },
  {
    path: '/super-warung/verified-onboarding',
    component: r(SuperWarungVerifiedOnboarding),
    name: 'SWVerifiedOnboarding',
  },
  {
    path: '/super-warung/no-coverage',
    component: r(SuperWarungNoCoverage),
    name: 'super_warung_no_coverage',
  },
  {
    path: '/super-warung/no-address',
    component: r(SuperWarungNoAddress),
    name: 'super_warung_no_address',
  },
];

export default superWarung;
