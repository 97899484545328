import SilkroadAPIClient from '~/api/silkroad';
// Utils
import {
  getProductPct,
} from '~/utils/grosir-utils';

const dispatchError = (dispatch, message, error) => {
  dispatch('hideAppLoading');
  dispatch('catchError', {
    error,
    message,
    hideAppLoading: true,
  });
};
const dispatchRetrieveCart = dispatch => {
  dispatch('retrieveCart');
  dispatch('hideAppLoading');
};

const getProductCart = (carts, productId) => {
  const cart = carts.find(c => c.product.id === productId) || {
    id: 0,
    quantity: 0,
  };
  return {
    cartId: cart.id,
    quantity: cart.quantity,
  };
};

const initialState = {
  cart: [],
  cartMeta: [],
  expiredCart: [],
  changedCart: [],
  bonusMinimumPrice: 0,
  isOpen: false,
  buyerNote: '',
  newPurchase: false,
  cod: false,
};

const thisGetters = {
  getCart(state) {
    return state.cart;
  },
  getCartMeta(state) {
    return state.cartMeta;
  },
  getCartProduct(state) {
    return state.cart.map(cart => cart.product);
  },
  getExpiredCart(state) {
    return state.expiredCart;
  },
  getChangedCart(state) {
    return state.changedCart;
  },
  isCartOpened(state) {
    return state.isOpen;
  },
  getProductQuantityInCart(state) {
    return productId => getProductCart(state.cart, productId).quantity;
  },
  getCartSize(state) {
    return state.cart.length;
  },
  getCartTotalPrice(state) {
    if (Array.isArray(state.cart)) {
      return state.cart.reduce((total, {
        price = 0,
        quantity = 0,
        product = {},
      }) => {
        if (product.bundling && state.cod) {
          return total + quantity * product.normal_selling_price;
        }
        return total + quantity * price;
      }, 0);
    }
    return 0;
  },
  getCartGrossPrice(state) {
    if (Array.isArray(state.cart)) {
      return state.cart.reduce((total, {
        product = {},
        price = 0,
        quantity = 0,
      }) => {
        if (product.bonus) {
          return total;
        }
        let calcPrice = (getProductPct(product) > 0 ? product.normal_selling_price : price);
        if (product.price_level_status === 'price_level_active' && product.price_levels && product.price_levels.length > 0) {
          calcPrice = product.price_levels[0].price;
        }
        return total + quantity * calcPrice;
      }, 0);
    }
    return 0;
  },
  getBuyerNote(state) {
    return state.buyerNote;
  },
  getNewPurchaseState(state) {
    return state.newPurchase;
  },
  getCOD(state) {
    return state.cod;
  },
};

const actions = {
  retrieveCart({
    dispatch,
    commit,
  }) {
    dispatch('showAppLoading');
    return new Promise((resolve, reject) => {
      SilkroadAPIClient.retrieveCart()
        .then(response => {
          commit('setCart', response.data);
          commit('setCartMeta', response.meta);
          dispatch('hideAppLoading');
          resolve(response);
        })
        .catch(error => {
          dispatch('hideAppLoading');
          reject(error);
        });
    });
  },
  validateCart({
    dispatch,
    commit,
  }) {
    dispatch('showAppLoading');
    commit('setChangedCart', []);
    return new Promise((resolve, reject) => {
      SilkroadAPIClient.validateItemCart()
        .then(response => {
          if (response.data && response.data.length > 0) {
            commit('setChangedCart', response.data);
            resolve(true);
          }
          dispatch('hideAppLoading');
          resolve(false);
        })
        .catch(error => {
          const message = 'Gagal mendapatkan data keranjang belanja.';
          dispatch('catchError', {
            error,
            message,
            hideAppLoading: true,
          });
          reject(error);
        });
    });
  },
  setCurrentCart({ commit }, cart) {
    commit('setCart', cart);
  },
  setCart({
    commit,
    getters,
  }, cartAPIResponse) {
    let wholesaleCart = [];
    const wholesaleSellers = getters.getWholesaleSellers;
    cartAPIResponse.forEach(cartObject => {
      if (wholesaleSellers.includes(`${cartObject.seller.id}`)) {
        const wholesaleProduct = [];
        cartObject.items.forEach(item => {
          const structuredItem = {
            ...item,
            id: item.product.id,
            images: {
              small_urls: item.product.small_images,
            },
            description: item.product.desc,
            carriers: item.product.courier,
            item_id: item.id,
          };
          delete structuredItem.product;
          wholesaleProduct.push(structuredItem);
        });
        wholesaleCart = [...wholesaleCart, ...wholesaleProduct];
      }
    });
    commit('setCart', wholesaleCart);
  },
  toggleCartMenu({
    commit,
  }) {
    commit('toggleCartMenu');
  },
  closeCartMenu({
    commit,
  }) {
    commit('closeCartMenu');
  },
  openCartMenu({
    commit,
  }) {
    commit('openCartMenu');
  },
  addToCart({
    dispatch,
    commit,
  }, {
    id,
    type = '',
    from = '',
  }) {
    dispatch('showAppLoading');
    return new Promise((resolve, reject) => {
      const options = {
        warehouse_product_id: id,
        quantity: 1,
      };

      SilkroadAPIClient.addItemCart(options, type, from)
        .then(response => {
          commit('setCart', response.data);
          dispatch('hideAppLoading');
          resolve();
        })
        .catch(error => {
          dispatch('hideAppLoading');
          const message = 'Gagal menambahkan barang ke keranjang.';
          dispatch('catchError', {
            error,
            message,
            hideAppLoading: true,
          });
          reject(error);
        });
    });
  },
  updateToCart({
    dispatch,
    getters,
  }, {
    productId,
    value,
  }) {
    return new Promise((resolve, reject) => {
      const {
        cartId,
        quantity,
      } = getProductCart(getters.getCart, productId);
      const newQuantity = quantity + value;
      if (newQuantity < 1 && value === -1) {
        SilkroadAPIClient.deleteItemCart(cartId)
          .then(response => {
            dispatchRetrieveCart(dispatch);
            resolve(response);
          })
          .catch(error => {
            dispatchError(dispatch, 'Gagal mengurangi barang dari keranjang.', error);
            reject(error);
          });
      } else {
        dispatch('updateCartValue', {
          productId,
          quantity: newQuantity,
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      }
    });
  },
  deleteCartwithId({
    dispatch,
  }, {
    productId,
  }) {
    return new Promise((resolve, reject) => {
      SilkroadAPIClient.deleteItemCart(productId)
        .then(response => {
          dispatch('retrieveCart');
          dispatch('hideAppLoading');
          resolve(response);
        })
        .catch(error => {
          const message = 'Gagal mengurangi barang dari keranjang.';
          dispatch('catchError', {
            error,
            message,
            hideAppLoading: true,
          });
          reject(error);
        });
    });
  },
  emptyExpiredCart({
    commit,
  }) {
    commit('deleteExpiredCart');
  },
  updateCartValue({
    dispatch,
    commit,
    getters,
  }, {
    productId = 0,
    quantity = 0,
    type = '',
    from = '',
    showNonCigarrettesAmount = true,
  }) {
    return new Promise((resolve, reject) => {
      try {
        const {
          cartId,
        } = getProductCart(getters.getCart, productId);
        if (cartId > 0) {
          SilkroadAPIClient.updateItemCart({
            quantity,
          }, cartId, type, from, showNonCigarrettesAmount).then(response => {
            commit('setCart', response.data);
            commit('setCartNonCigarrettesAmount', response.meta.non_cigarrettes_amount);
            resolve(response);
          });
        } else {
          resolve();
        }
      } catch (error) {
        dispatchError(dispatch, 'Gagal mengubah data barang di keranjang.', error);
        reject(error);
      } finally {
        dispatch('hideAppLoading');
      }
    });
  },
  setBuyerNote({
    commit,
  }, note) {
    commit('setBuyerNote', note);
  },
  setNewPurchase({
    commit,
  }) {
    commit('setNewPurchaseState', true);
  },
  resetNewPurchase({
    commit,
  }) {
    commit('setNewPurchaseState', false);
  },
  setCOD({
    commit,
  }, cod = false) {
    commit('setCOD', cod);
  },
};

const mutations = {
  setCart(state, cart) {
    state.cart = cart;
  },
  setCartMeta(state, meta) {
    state.cartMeta = meta;
  },
  setCartNonCigarrettesAmount(state, amount) {
    state.cartMeta.non_cigarrettes_amount = amount;
  },
  setChangedCart(state, changedCart) {
    state.changedCart = changedCart;
  },
  deleteExpiredCart(state) {
    state.expiredCart = [];
  },
  toggleCartMenu(state) {
    state.isOpen = JSON.parse(JSON.stringify(!state.isOpen));
  },
  closeCartMenu(state) {
    state.isOpen = false;
  },
  openCartMenu(state) {
    state.isOpen = true;
  },
  resetState(state) {
    state.cart = JSON.parse(JSON.stringify(initialState.cart));
    state.isOpen = initialState.isOpen;
    state.newPurchase = initialState.newPurchase;
  },
  setBuyerNote(state, note) {
    state.buyerNote = note;
  },
  setNewPurchaseState(state, status) {
    state.newPurchase = status;
  },
  setCOD(state, cod) {
    state.cod = cod;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: thisGetters,
  actions,
  mutations,
};
