import {
  PublicGetCampaignInfoResponse,
} from '@bukalapak/openapi-schema/reusable/subsidy/v1/public/subsidy-domain';
import accessTokenManagerInstance from '~/api/v4/access-token-manager-instance';
import wrapSecureClientInterface from './v4';

import SubsidyDomainCampaignAPI from './v4/modules/subsidy-domain/campaign-api';

const SubsidyDomainAPIClient = {
  v4: {
    accessTokenManager: accessTokenManagerInstance,
  } as any,

  init() {
    this.v4.subsidyDomainCampaign = wrapSecureClientInterface(
      accessTokenManagerInstance,
      SubsidyDomainCampaignAPI,
      'subsidy_domain_campaign'
    );
  },

  getCampaign(id: string): PublicGetCampaignInfoResponse {
    return this.v4.subsidyDomainCampaign.getCampaign(id);
  },
};

SubsidyDomainAPIClient.init();

export default SubsidyDomainAPIClient;
