import AgenliteAPIClient from '~/api';

const initialState = {
  leaderboardRankings: [],
  leaderboard: {},
  userDetail: {}
};

const getters = {
  getLeaderboardRankings(state) {
    return state.leaderboardRankings;
  },
  getLeaderboard(state) {
    return state.leaderboard;
  },
  getUserDetail(state) {
    return state.userDetail;
  },
};

const actions = {
  async retrieveLeaderboardRankings({ commit, dispatch }) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.retrieveLeaderboardRankings();
      commit('setLeaderboardRankings', response.data);
    } catch (error) {
      const message = 'Gagal Mendapatkan Peringkat.';
      dispatch('catchError', { error, message, noFlashAlert: false });
    } finally {
      dispatch('hideAppLoading');
    }
    return { response };
  },
  async retrieveLeaderboard({ commit, dispatch }) {
    dispatch('showAppLoading');
    let response = null;
    let previousResponse = JSON.parse(localStorage.getItem('leaderboardData')) || {};

    try {
      response = await AgenliteAPIClient.retrieveLeaderboard();

      // Check if the data has changed since the last retrieval
      if (!previousResponse || JSON.stringify(previousResponse.data) !== JSON.stringify(response.data)) {
        // Update local storage with the latest data and store the previous data
        localStorage.setItem('leaderboardData', JSON.stringify(response.data));
        localStorage.setItem('previousLeaderboardData', JSON.stringify(previousResponse));
      }

      commit('setLeaderboard', response.data);
      previousResponse = response.data;
    } catch (error) {
      const message = 'Gagal Mendapatkan Data.';
      dispatch('catchError', { error, message, noFlashAlert: false });
    } finally {
      dispatch('hideAppLoading');
    }
    return { response };
  },
  async retrieveUserDetail({ commit, dispatch }) {
    dispatch('showAppLoading');
    let response = null;
    let errorRes = null;
    try {
      response = await AgenliteAPIClient.retrieveUserDetail();
      commit('setUserDetail', response.data);
    } catch (error) {
      const message = 'Gagal Mendapatkan data.';
      errorRes = error;
      dispatch('catchError', { error, message, noFlashAlert: false });
    } finally {
      dispatch('hideAppLoading');
    }
    return { response, errorRes };
  },
};

const mutations = {
  setLeaderboardRankings(state, leaderboardRankings) {
    state.leaderboardRankings = leaderboardRankings;
  },
  setLeaderboard(state, leaderboard) {
    state.leaderboard = leaderboard;
  },
  setUserDetail(state, userDetail) {
    state.userDetail = userDetail;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
