import timelimit from '~/constants/vp/timelimit';
import ApiOptions from '../api-options';

const GENERAL_TRADE_URL = `${process.env.API_V4_HOST}/general-trade`;
const GENERAL_TRADE_LOGSITICS_URL = `${process.env.API_V4_HOST}/_exclusive/general-trade-logistics`;
const TRANSACTION_URL = `${GENERAL_TRADE_URL}/payment-transactions`;

export default {
  retrieveTransactions() {
    return this.get(TRANSACTION_URL, 'user', new ApiOptions());
  },
  retrieveTransaction(paramId) {
    return this.get(`${TRANSACTION_URL}/${paramId}`, 'user', new ApiOptions());
  },
  createTransaction(requestPayload) {
    return this.post(TRANSACTION_URL, 'user', new ApiOptions({
      requestPayload,
      requestLimit: {
        time: timelimit.grosir,
      },
    }));
  },
  receiveTransaction(paramId) {
    return this.patch(`${TRANSACTION_URL}/${paramId}/receive`, new ApiOptions());
  },
  retrieveInvoiceDelivery(paramId) {
    return this.get(`${GENERAL_TRADE_LOGSITICS_URL}/invoices/${paramId}`, new ApiOptions());
  },
};
