import ApiOptions from '../api-options';

const GENERAL_TRADE_URL = `${process.env.API_V4_HOST}/general-trade`;
const BANNER_URL = `${GENERAL_TRADE_URL}/banners`;

export default {
  getSpecialPromo(id) {
    return this.get(`${BANNER_URL}/${id}`, 'user', new ApiOptions());
  },
};
