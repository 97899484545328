import Client from '~/types/api/api-module';
import {
  FetchBookkeepingEntryParams,
  CreateBookkeepingEntryPayload,
  UpdateBookkeepingEntryPayload,
  UpdateBookkeepingEntryDebtStatusPayload,
  DeleteBookkeepingEntryPayload,
  FetchBookkeepingReportPayload,
  FetchBookkeepingSummariesCategoriesPayload,
  BookkeepingEntryEntity,
  BookkeepingReportEntity,
  BookkeepingSummariesCategoriesEntity,
  BookkeepingCategory,
  BookkeepingProfit,
  FetchBookkeepingEntriesParams,
  FetchBookkeepingProfitsParams,
  FetchBookkeepingCategories,
  CalculateSettlementEntryPayload,
  BookkeepingEntrySettlementEntity,
} from '~/types/api/bookkeeping';
import ApiOptions from '../api-options';

const BOOKKEEPING_URL = `${process.env.API_V4_HOST}/bookkeepings`;

export default Client.wrap({
  fetchBookkeepingEntry(requestPayload: FetchBookkeepingEntryParams) {
    const { id } = requestPayload;
    return this.get<BookkeepingEntryEntity>(`${BOOKKEEPING_URL}/entries/${id}`, 'user', new ApiOptions({ requestPayload }));
  },
  fetchBookkeepingEntries(params: FetchBookkeepingEntriesParams) {
    return this.get<BookkeepingEntryEntity[]>(`${BOOKKEEPING_URL}/entries`, 'user', new ApiOptions({ requestPayload: params }));
  },
  fetchBookkeepingProfits(params: FetchBookkeepingProfitsParams) {
    return this.get<BookkeepingProfit[]>(`${BOOKKEEPING_URL}/profits`, 'user', new ApiOptions({ requestPayload: params }));
  },
  fetchBookkeepingCategories(params: FetchBookkeepingCategories) {
    return this.get<BookkeepingCategory[]>(`${BOOKKEEPING_URL}/categories`, 'user', new ApiOptions({ requestPayload: params }));
  },
  createBookkeepingEntry(requestPayload: CreateBookkeepingEntryPayload) {
    return this.post<BookkeepingEntryEntity>(`${BOOKKEEPING_URL}/entries`, 'user', new ApiOptions({ requestPayload }));
  },
  updateBookkeepingEntry(requestPayload: UpdateBookkeepingEntryPayload) {
    const { id } = requestPayload;
    return this.patch<BookkeepingEntryEntity>(`${BOOKKEEPING_URL}/entries/${id}`, 'user', new ApiOptions({ requestPayload }));
  },
  updateBookkeepingEntryDebtStatus(requestPayload: UpdateBookkeepingEntryDebtStatusPayload) {
    const { id } = requestPayload;
    return this.patch<BookkeepingEntryEntity>(`${BOOKKEEPING_URL}/entries/${id}/debt-status`, 'user');
  },
  deleteBookkeepingEntry(requestPayload: DeleteBookkeepingEntryPayload) {
    const { id } = requestPayload;
    return this.delete<BookkeepingEntryEntity>(`${BOOKKEEPING_URL}/entries/${id}`, 'user', new ApiOptions({}));
  },
  fetchBookkeepingReport(requestPayload: FetchBookkeepingReportPayload) {
    return this.get<BookkeepingReportEntity[]>(`${BOOKKEEPING_URL}/summaries/entries`, 'user', new ApiOptions({ requestPayload }));
  },
  fetchBookkeepingSummariesCategories(requestPayload: FetchBookkeepingSummariesCategoriesPayload) {
    return this.get<BookkeepingSummariesCategoriesEntity[]>(`${BOOKKEEPING_URL}/summaries/categories`, 'user', new ApiOptions({ requestPayload }));
  },
  calculateSettlement(requestPayload: CalculateSettlementEntryPayload) {
    return this.post<BookkeepingEntrySettlementEntity[]>(`${BOOKKEEPING_URL}/entries/settlement-calculations`, 'user', new ApiOptions({ requestPayload }));
  }
});
