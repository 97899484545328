const initialState = {
  isLiteMode: true,
};

const initGetters = {
  isLiteMode(state) {
    return state.isLiteMode;
  },
};

const mutations = {
};

const actions = {
};

export default {
  state: () => ({ ...initialState }),
  getters: initGetters,
  actions,
  mutations,
};
