/**
 * @module secure-client/interface-wrapper
 */

import AccessTokenManager from '~/api/v4/access-token-manager';
import { APIModules } from '~/types/api/api-module';
import { APIResponse } from '~/types/api/api-response';
import RequestManager from './request-manager';

const env = process.env.NODE_ENV;

export type WrappedModule /* extends APIModule */ = {
  // TODO: enable extending APIModule after this conflicts resolved
  [x: string]: <T = any>(...args: any[]) => Promise<APIResponse<T>>;
} & {
  abortRequest(this: RequestManager): void;
  refreshToken(this: RequestManager, useAgentId?: boolean): Promise<any>;
};
/**
 *
 * This function wraps API endpoint interfaces to be used in Secure Client context.
 *
 * @param {AccessTokenManager} accessTokenManager Token manager
 * @param {APIModules} interfaces API interfaces to be wrappeed
 * @param {String} requestLaneKey key for grouping this interface requests in the request pool
 * @param {Record<string, any>=} dummy if dummy is an Object, this will be used as result of invocating
 *                       an interface method instead.
 *
 * @example
 * // Create the request functions (the interface)
 * const cartInterface = {
 *   getCart() {
 *     return this.get('/carts');
 *   },
 *   addToCart(item) {
 *     return this.post('/carts', 'public', { data: item });
 *   },
 * };
 *
 * // Wrap them
 * const cartClient = wrapSecureClientInterface(cartInterface, 'requestKey')
 *
 * // Use them
 * cartClient.getCart().then(response => {
 *   // do something with response
 * });
 */
export function wrapSecureClientInterface(
  accessToken: AccessTokenManager,
  interfaces: APIModules = {},
  requestLaneKey: string = 'requestLane',
  dummy?: Record<string, any>
) {
  const requestManager = new RequestManager(accessToken, process.env.API_HOST, requestLaneKey);
  const wrappedInterfaces /* : WrappedModule */ = {
    // TODO: this is hack, and whack
    abortRequest: requestManager.abortRequest.bind(requestManager),
    refreshToken: requestManager.accessTokenManager.fetchFreshToken.bind(requestManager.accessTokenManager),
  };
  Object.keys(interfaces).forEach(key => {
    wrappedInterfaces[key] = (...args: any[]) => {
      if (dummy?.[key] && env !== 'production') {
        return new Promise(resolve => resolve(dummy?.[key]));
      }
      return (interfaces[key] as CallableFunction).bind(requestManager)(...args);
    };
  });

  return wrappedInterfaces as WrappedModule;
}

export default wrapSecureClientInterface;
