import bookkeeping from '~/store/modules/bookkeeping';
import bookkeepingOnboarding from '~/store/modules/bookkeeping/onboarding';
import bookkeepingMultipleDebtNotes from '~/store/modules/bookkeeping/multiple-debt-notes';
import AgenliteAPIClient from '~/api';
import { gtagTrackPageview } from '~/utils/gtag-tracker';

export default async function ({ store, app, route }) {
  if (store.getters.isSaasApp) gtagTrackPageview({ app, route });

  const modules = { bookkeeping, bookkeepingOnboarding, bookkeepingMultipleDebtNotes };
  Object.keys(modules).forEach(key => {
    const someGettersDefined = Object.keys(modules[key].getters).some(getter => store?.getters?.[getter] !== undefined);
    if (store?.state?.[key] && someGettersDefined) return;
    store.registerModule(key, modules[key], {
      preserveState: Boolean(store.state[key]),
    });
  });

  const currentRoute = store.$router?.history?.current;
  /* istanbul ignore else */
  if (!currentRoute?.meta?.bookkeeping) return;
  const bookkeepingUnpaidTrxToggle = store.getters.toggleConfig.bookkeepingUnpaidTransaction;
  const shouldShowBookkeepingCoachmarkFlag = store.getters['bookkeepingOnboarding/shouldShowBookkeepingCoachmark'];
  const shouldShowBookkeepingUnpaidTrxCoachmarkFlag = store.getters['bookkeepingOnboarding/shouldShowBookkeepingUnpaidTrxCoachmark'] && bookkeepingUnpaidTrxToggle;
  const bookkeepingCoachmarkStep =
    currentRoute?.params?.bookkeepingCoachmarkStep || currentRoute?.query?.bookkeepingCoachmarkStep;
  const inTheMiddleOfBookkeepingCoachmark = currentRoute?.query?.shouldPrefillFormData;
  if (
    (shouldShowBookkeepingCoachmarkFlag || shouldShowBookkeepingUnpaidTrxCoachmarkFlag) &&
    store?.getters?.toggleConfig?.bookkeepingCoachmark &&
    (currentRoute?.meta?.bookkeepingCoachmarkStep === 0 || bookkeepingCoachmarkStep || inTheMiddleOfBookkeepingCoachmark) &&
    !['wholesale_status', 'click-here-link', 'see-bookkeeping-button'].includes(currentRoute?.query?.src)
  ) {
    // check if user has no manual entries
    let hasNoManualEntry = false;
    try {
      const { data } = await AgenliteAPIClient.fetchBookkeepingEntries({ limit: 1, automatic: false });
      hasNoManualEntry = data.length === 0;
    } catch (err) {
      console.error(err);
    }

    if (!hasNoManualEntry) {
      store.commit('bookkeepingOnboarding/setHasShownBookkeepingCoachmark', true);
    }
    store.commit('setIsBookkeepingCoachmarkActive', Boolean(hasNoManualEntry || bookkeepingCoachmarkStep));
    store.commit('setIsBookkeepingCoachmarkUnpaidTrxActive', Boolean(!hasNoManualEntry));
    store.commit('setIsNewBookkeepingUser', hasNoManualEntry);
  } else {
    store.commit('setIsBookkeepingCoachmarkActive', false);
    store.commit('setIsBookkeepingCoachmarkUnpaidTrxActive', false);
  }

  const shouldShowOnboarding = store.getters['bookkeepingOnboarding/shouldShowNewOnboarding'];
  if (shouldShowOnboarding && store.getters.isSaasApp) {
    store.commit('bookkeepingOnboarding/setHasShownNewOnboarding', true);
  }
}
