import AgenliteAPIClient from '~/api';

const initialState = {
  banner: {
    banner_url: '',
  },
  event: {},
  eventProducts: [],
  eventProductDetail: {
    image: {
      small_url: '',
    },
    winners: [],
  },
};

const actions = {
  async getSerbuBanners({ dispatch, commit }, params) {
    dispatch('showAppLoading');
    try {
      const { data } = await AgenliteAPIClient.getSerbuBanners(params);
      commit('setDataFromArrayFirstIndex', {
        key: 'banner',
        data,
      });
      dispatch('hideAppLoading');
      return data;
    } catch (error) {
      return error;
    }
  },
  async getSerbuEvents({ dispatch, commit, state, rootState }, params) {
    dispatch('showAppLoading');
    try {
      const { data } = await AgenliteAPIClient.getSerbuEvents(params);
      commit('setDataFromArrayFirstIndex', {
        key: 'event',
        data,
      });
      const warehouseId = rootState.agent.currentUser.warehouses.length && rootState.agent.currentUser.warehouses[0].id;
      dispatch('getSerbuEventProducts', { event_id: state.event.id, warehouse_id: warehouseId });
      dispatch('hideAppLoading');
      return data;
    } catch (error) {
      return error;
    }
  },
  async getSerbuEventProducts({ dispatch, commit }, params) {
    dispatch('showAppLoading');
    try {
      const { data } = await AgenliteAPIClient.getSerbuEventProducts(params);
      commit('setData', {
        key: 'eventProducts',
        data,
      });
      dispatch('hideAppLoading');
    } catch (error) {
      dispatch('catchError', { error, hideAppLoading: true });
    };
  },
  async getSerbuEventProductDetail({ dispatch, commit }, id) {
    dispatch('showAppLoading');
    try {
      const { data } = await AgenliteAPIClient.getSerbuEventProductDetail(id);
      commit('setData', {
        key: 'eventProductDetail',
        data,
      });
      dispatch('hideAppLoading');
    } catch (error) {
      dispatch('catchError', { error, hideAppLoading: true });
    };
  },
};

const mutations = {
  setDataFromArrayFirstIndex(state, { key, data }) {
    if (data[0]) {
      state[key] = data[0];
    }
  },
  setData(state, { key, data }) {
    state[key] = data;
  },
};

export default {
  state: () => ({ ...initialState }),
  actions,
  mutations,
};
