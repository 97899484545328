import ApiOptions from '../api-options';

const GENERAL_TRADE_URL = `${process.env.API_V4_HOST}/general-trade`;
const USER_URL = `${GENERAL_TRADE_URL}/users`;

export default {
  registerUser(requestPayload) {
    return this.post(USER_URL, 'user', new ApiOptions({ requestPayload }));
  },
};
