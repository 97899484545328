import Client from '~/types/api/api-module';
import ApiOptions from '../../api-options';

const EXCLUSIVE_URL = `${process.env.API_V4_HOST}/_exclusive`;
const MITRA_SEND_MONEY_URL = `${EXCLUSIVE_URL}/mitra-send-money`;

export default Client.wrap({
  getDigitalSendTransaction(id: number) {
    return this.get(`${MITRA_SEND_MONEY_URL}/transaction/${id}`, 'user', new ApiOptions());
  },
  getCompetitionTieringMissionProgress() {
    return this.post(`${EXCLUSIVE_URL}/mitra-tiering-mission/progress`, 'user', new ApiOptions());
  },
  getCompetitionTieringMissionEvents(progressId) {
    return this.get(`${EXCLUSIVE_URL}/mitra-tiering-mission/events?progress_id=${progressId}`, 'user', new ApiOptions());
  },
  getDailyCheckinEvent() {
    return this.get(`${EXCLUSIVE_URL}/mitra-lakupandai/check-in/event`, 'user', new ApiOptions());
  },
  getDailyCheckinProgress(eventId) {
    return this.get(`${EXCLUSIVE_URL}/mitra-lakupandai/check-in/progress?event_id=${eventId}`, 'user', new ApiOptions());
  },
  getCompetitionLeaderboardEvents() {
    return this.get(`${EXCLUSIVE_URL}/mitra-leaderboard/events`, 'user', new ApiOptions());
  },
  getCompetitionLeaderboardProgress(eventId) {
    return this.get(`${EXCLUSIVE_URL}/mitra-leaderboard/events/${eventId}/leaderboard-details`, 'user', new ApiOptions());
  },
  retrievePinStatus() {
    return this.get(`${EXCLUSIVE_URL}/users/pins`, 'user', new ApiOptions());
  },
});
