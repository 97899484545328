const PREFIX = 'agenlite_vp_minifaq_';

export default {
  miniFAQCommonParamsBuilder() {
    return {
      journey_id: this.getTrackerJourneyId || '',
      referrer_screen: this.routeReferrer.name,
      referrer_url: this.routeReferrer.url,
    };
  },
  trackMiniFAQVisit({ currentScreen }) {
    const payload = {
      evn: `${PREFIX}visit`,
      ...this.miniFAQCommonParamsBuilder(),
      current_screen: currentScreen,
    };

    this.$blTracker.actions.trackActions(payload);
  },
  trackMiniFAQClick({ currentScreen, clickSourceStatus, targetScreen, clickSource }) {
    const payload = {
      evn: `${PREFIX}click`,
      ...this.miniFAQCommonParamsBuilder(),
      current_screen: currentScreen,
      click_source: clickSource,
      click_source_status: clickSourceStatus,
      target_screen: targetScreen,
    };

    this.$blTracker.actions.trackActions(payload);
  },
};
