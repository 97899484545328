import Agent from './v4/modules/agent-api';
import AgentBanks from './v4/modules/agent-banks-api';
import AgentCustomers from './v4/modules/agent-customers-api';
import Aggregation from './v4/modules/aggregation';
import Allowlist from './v4/modules/allowlist-api';
import Bookkeeping from './v4/modules/bookkeeping-api';
import Bpjs from './v4/modules/bpjs-api';
import WinBigEvent from './v4/modules/win-big-event-api';
import Complaints from './v4/modules/complaints-api';
import Coupons from './v4/modules/coupons-api';
import Credits from './v4/modules/credits-api';
import DataPlan from './v4/modules/data-plan-api';
import DigitalVoucher from './v4/modules/digital-voucher-api';
import DebtSnapshot from './v4/modules/debt-snapshots-api';
import ElectricityPostpaid from './v4/modules/electricity-postpaid-api';
import ElectricityPrepaid from './v4/modules/electricity-prepaid-api';
import ExclusiveAgent from './v4/modules/exclusive/agent-api';
import ExclusiveDana from './v4/modules/exclusive/dana-api';
import ExclusiveInfo from './v4/modules/exclusive/info-api';
import ExclusiveMitraBiller from './v4/modules/exclusive/mitra-biller-api';
import ExclusiveMitraService from './v4/modules/exclusive/mitra-service-api';
import ExclusiveNeo from './v4/modules/exclusive/neo-api';
import ExclusiveNotification from './v4/modules/exclusive/notification-api';
import ExclusiveTopupKurir from './v4/modules/exclusive/topup-kurir-api';
import ExclusiveUser from './v4/modules/exclusive/user-api';
import ExclusiveWhatsapp from './v4/modules/exclusive/whatsapp-api';
import ExclusiveMitraEdcCheckBalance from './v4/modules/exclusive/mitra-edc-check-balance';
import ExclusiveMitraKYC from './v4/modules/exclusive/mitra-kyc';
import ExclusiveMitraEdcCashOut from './v4/modules/exclusive/mitra-edc-cash-out';
import ExclusiveMitraQrisCashOut from './v4/modules/exclusive/mitra-qris-cash-out';
import ExclusiveMitraSendMoney from './v4/modules/exclusive/mitra-send-money';
import ExclusiveMitraWallet from './v4/modules/exclusive/mitra-wallet-api';
import ExclusiveBukaBantuan from './v4/modules/exclusive/bukabantuan-api';
import GroupBuying from './v4/modules/group-buying-api';
import Help from './v4/modules/help-api';
import Info from './v4/modules/info-api';
import Invoice from './v4/modules/invoice-api';
import Leaderboard from './v4/modules/leaderboard-api';
import Mitra from './v4/modules/mitra-api';
import MitraBranch from './v4/modules/mitra-branch-api';
import MotorcycleInsurance from './v4/modules/motorcycle-insurance-api';
import Multifinance from './v4/modules/multifinance-api';
import Payment from './v4/modules/payment-api';
import Payout from './v4/modules/payout-api';
import Pdam from './v4/modules/pdam-api';
import PhoneCredits from './v4/modules/phone-credits-api';
import Product from './v4/modules/product-api';
import Account from './v4/modules/account-api';
import ProductItems from './v4/modules/product-items-api';
import ProfitReports from './v4/modules/profit-reports-api';
import Promos from './v4/modules/promos-api';
import Reward from './v4/modules/reward-api';
import SaasUser from './v4/modules/saas-user-api';
import SerbuSeru from './v4/modules/serbu-seru-api';
import SubscriptionTopSpender from './v4/modules/subscription-top-spender-api';
import Topup from './v4/modules/topup-api';
import TopupOndemand from './v4/modules/topup-ondemand-api';
import Train from './v4/modules/train-api';
import Transaction from './v4/modules/transaction-api';
import User from './v4/modules/user-api';
import Voucher from './v4/modules/voucher-api';
import VpDeals from './v4/modules/vp-deals-api';
import Wallet from './v4/modules/wallet-api';
import MitraWallet from './v4/modules/mitra-wallet-api';
import { AgentCustomerDebtMutations } from './v4/modules/agent-customer-debt-mutations-api';
import { AgentSellingProducts } from './v4/modules/agent-selling-products-api';
import { ExclusiveMitraPayment } from './v4/modules/exclusive/mitra-payment';
import { MitraPosters } from './v4/modules/exclusive/poster-api';
import { MitraReferrals } from './v4/modules/exclusive/referral';
import { MitraTrx } from './v4/modules/mitra-transactions-api';
import { PromotionMedia } from './v4/modules/promotion-media-api';

export default {
  Exclusive: {
    Agent: ExclusiveAgent,
    Dana: ExclusiveDana,
    Info: ExclusiveInfo,
    MitraBiller: ExclusiveMitraBiller,
    MitraPayment: ExclusiveMitraPayment,
    MitraService: ExclusiveMitraService,
    Neo: ExclusiveNeo,
    Notification: ExclusiveNotification,
    Poster: MitraPosters,
    Referral: MitraReferrals,
    TopupKurir: ExclusiveTopupKurir,
    User: ExclusiveUser,
    Whatsapp: ExclusiveWhatsapp,
    MitraEdcCheckBalance: ExclusiveMitraEdcCheckBalance,
    MitraKYC: ExclusiveMitraKYC,
    MitraEdcCashOut: ExclusiveMitraEdcCashOut,
    MitraQrisCashOut: ExclusiveMitraQrisCashOut,
    MitraSendMoney: ExclusiveMitraSendMoney,
    MitraWallet: ExclusiveMitraWallet,
    BukaBantuan: ExclusiveBukaBantuan,
  },
  Account,
  Agent,
  AgentBanks,
  Allowlist,
  AgentCustomerDebtMutations,
  AgentCustomers,
  AgentSellingProducts,
  Aggregation,
  Bookkeeping,
  WinBigEvent,
  Bpjs,
  Complaints,
  Coupons,
  Credits,
  DataPlan,
  DebtSnapshot,
  DigitalVoucher,
  ElectricityPostpaid,
  ElectricityPrepaid,
  GroupBuying,
  Help,
  Info,
  Invoice,
  Leaderboard,
  Mitra,
  MitraBranch,
  MitraTrx,
  MotorcycleInsurance,
  Multifinance,
  Payment,
  Payout,
  Pdam,
  PhoneCredits,
  Product,
  ProductItems,
  ProfitReports,
  Promos,
  PromotionMedia,
  Reward,
  SaasUser,
  SerbuSeru,
  SubscriptionTopSpender,
  Topup,
  TopupOndemand,
  Train,
  Transaction,
  User,
  Voucher,
  VpDeals,
  Wallet,
  MitraWallet,
};
