<template>
  <picture>
    <source v-if="webp" :srcset="webpSrc" type="image/webp" />
    <source :srcset="cdnSrc" :type="imgType" />
    <img :src="cdnSrc"
         :alt="title"
         :class="imgClass"
         :style="imgStyle"
         @load="$emit('imageLoad')"
    />
  </picture>
</template>

<script>
export default {
  name: 'BLBPicture',
  props: {
    imgClass: {
      type: String,
      default: '',
    },
    imgExt: {
      type: String,
      default: 'jpg',
    },
    imgStyle: {
      type: Object,
      default: () => {},
    },
    src: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: 'image',
    },
    webp: {
      type: Boolean,
      default: true,
    },
    assetUrl: {
      type: String,
      default: `${process.env.ASSETS_URL}`,
    },
  },
  computed: {
    cdnSrc() {
      // check if it's not relative path
      if (this.src?.charAt(0) !== '/') return this.src;
      // STAGING: inspect if assets url are not relative either
      if (this.assetUrl === 'undefined' || this.assetUrl.charAt(0) === '/') return this.src;
      // return the replaced value
      return `${this.assetUrl}${this.src}`;
    },
    webpSrc() {
      return `${this.cdnSrc}.webp`;
    },
    ext() {
      return this.src?.includes('.') ? this.src.split('.').pop() : this.imgExt;
    },
    imgType() {
      return `image/${this.ext}`;
    },
  },
};
</script>
