import AgenliteAPIClient from '~/api';

const initialState = {
  postpaidElectricityInfo: null,
  transaction: null,
};

const moduleGetters = {
  getPostpaidElectricityInfo(state) {
    return state.postpaidElectricityInfo;
  },
  getPostpaidElectricityTransaction(state) {
    return state.transaction;
  },
};

const actions = {
  async createElectricityPostpaidTransaction({ dispatch }, payload) {
    dispatch('showAppLoading');
    try {
      const response = await AgenliteAPIClient.postElectricityPostpaidTransaction(payload);
      dispatch('setPostpaidElectricityTransaction', response.data);
      return response.data;
    } catch (error) {
      const message = 'Gagal melakukan transaksi tagihan listrik';
      dispatch('catchError', { error, message });
      throw error;
    } finally {
      dispatch('hideAppLoading');
    }
  },
  fetchPostpaidElectricityInfo({ dispatch }, idPln) {
    dispatch('showAppLoading');
    return new Promise((resolve, reject) => {
      AgenliteAPIClient.postElectricityPostpaidInquiry(idPln)
        .then(response => {
          dispatch('hideAppLoading');
          dispatch('hideFlashAlert');
          dispatch('setPostpaidElectricityInfo', response.data);
          resolve();
        })
        .catch(error => {
          let message = 'Gagal mendapatkan data tagihan listrik.';
          if (error && error.errors && error.errors[0].code === 18102) {
            error.message = 'Maaf, nomornya salah. Coba cek lagi ya.';
            dispatch('hideAppLoading');
            return reject(error);
          } if (error && error.errors && error.errors[0].code === 18111) {
            message = error.errors[0].message.replace(
              /.*(\d{2}:\d{2}).*(\d{2}:\d{2}) WIB/g,
              'Transaksi Tagihan Listrik tidak dapat digunakan pukul $1 - $2 WIB, silakan coba setelah itu',
            );
            dispatch('catchError', { message, hideAppLoading: true });
            return reject(error);
          }
          dispatch('catchError', { error, message, hideAppLoading: true });
          return reject(error);
        });
    });
  },
  payPostpaidElectricity({ dispatch, getters }, params) {
    dispatch('showAppLoading');
    return new Promise((resolve, reject) => {
      AgenliteAPIClient.payPostpaidElectricity(getters.getUserId, params)
        .then(response => {
          dispatch('clearPostpaidElectricityTransaction');
          dispatch('hideFlashAlert');
          resolve(response);
        })
        .catch(error => {
          const message =
            'Pembayaran tagihan listrik gagal. Cek <a href="/transaksi">daftar transaksi</a> untuk melanjutkan pembayaran sebelum mengulang transaksi.';
          dispatch('catchError', { error, message, finishTransaction: true });
          reject(error);
        });
    });
  },
  clearPostpaidElectricityTransaction({ commit }) {
    commit('clearPostpaidElectricityTransaction');
  },
  clearPostpaidElectricityInfo({ commit }) {
    commit('clearPostpaidElectricityInfo');
  },
  setPostpaidElectricityTransaction({ commit }, transaction) {
    commit('setPostpaidElectricityTransaction', transaction);
  },
  setPostpaidElectricityInfo({ commit }, postpaidElectricityInfo) {
    commit('setPostpaidElectricityInfo', postpaidElectricityInfo);
  },
};

const mutations = {
  clearPostpaidElectricityTransaction(state) {
    state.transaction = null;
  },
  clearPostpaidElectricityInfo(state) {
    state.postpaidElectricityInfo = JSON.parse(JSON.stringify(initialState.postpaidElectricityInfo));
  },
  setPostpaidElectricityTransaction(state, transaction) {
    state.transaction = transaction;
  },
  setPostpaidElectricityInfo(state, postpaidElectricityInfo) {
    state.postpaidElectricityInfo = JSON.parse(JSON.stringify(postpaidElectricityInfo));
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  actions,
  mutations,
};
