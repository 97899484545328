import ApiOptions from '../api-options';

const GENERAL_TRADE_URL = `${process.env.API_V4_HOST}/general-trade`;
const PRODUCT_URL = `${GENERAL_TRADE_URL}/products`;
const RECOMMENDATIONS_URL = `${GENERAL_TRADE_URL}/recommendations`;

export default {
  retrieveProducts(requestPayload) {
    return this.get(
      PRODUCT_URL,
      'user',
      new ApiOptions({
        requestPayload: {
          ...requestPayload,
          api_version: 2,
        },
      })
    );
  },
  retrieveProduct(id) {
    return this.get(`${PRODUCT_URL}/${id}`, 'user');
  },
  setReminderStock(id) {
    return this.post(`${process.env.API_V4_HOST}/_exclusive/general-trade/warehouse-products/${id}/reminders`, 'user');
  },
  retrieveRecommendation(type = 'mfb-products', requestPayload = {}) {
    return this.get(
      `${RECOMMENDATIONS_URL}/${type}`,
      'user',
      new ApiOptions({
        requestPayload,
      })
    );
  },
};
