import AgenliteAPIClient from '~/api';

const initialState = {
  mitraTypes: [],
  mitraPromos: [],
};

const getters = {
  getMitraTypes(state) {
    return state.mitraTypes;
  },
  getMitraPromos(state) {
    return state.mitraPromos;
  },
  getBusinessMitraTypes(state) {
    return state.mitraTypes.filter(type => type.mitra_type !== 'perseorangan');
  },
};

const actions = {
  async fetchMitraTypes({ commit, dispatch }) {
    let response = null;
    try {
      response = await AgenliteAPIClient.fetchMitraTypes();
      commit('setMitraTypes', response.data);
    } catch (error) {
      const message = 'Gagal mendapatkan data tipe mitra.';
      dispatch('catchError', { error, message });
      throw error;
    }
  },
  async fetchMitraPromos({ commit, dispatch }, requestPayload) {
    let response = null;
    try {
      response = await AgenliteAPIClient.fetchMitraPromos(requestPayload.category);
      commit('setMitraPromos', response.data);
    } catch (error) {
      const message = 'Gagal mendapatkan data promo mitra.';
      dispatch('catchError', { error, message });
    }
  },
  async fetchMitraComplaintCount({ dispatch }) {
    let response = null;
    try {
      response = await AgenliteAPIClient.fetchMitraComplaintCount();
    } catch (error) {
      const message = 'Gagal mendapatkan data komplain mitra.';
      dispatch('catchError', { error, message, noFlashAlert: true });
    }
    return response?.data;
  },
};

const mutations = {
  setMitraTypes(state, mitraTypes) {
    state.mitraTypes = mitraTypes;
  },
  setMitraPromos(state, mitraPromos) {
    state.mitraPromos = mitraPromos;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
