import { lowerCase, upperCase } from 'lodash';
import { toTitleCase } from '~/server/helpers/string-helper';

function capitalize(str) {
  return str && typeof str === 'string' ? toTitleCase(str) : '';
}

const stringFilter = {
  filters: {
    capitalize,
    sanitizeCommas(text) {
      return typeof text === 'string' ? text.replace(/,(?=[^\s0-9])/g, ', ') : null;
    },
    formatPhone(phone, defaultValue = '') {
      if (!phone) return defaultValue;
      return phone.replace(/(-|\s)/ig, '').replace(/^62/, '0') || defaultValue;
    },
    phoneDisplay(phone, defaultValue = '') {
      if (!phone) return defaultValue;
      const no = phone.replace(/^\+62/, '0') || defaultValue;
      return `${no.substr(0, 4)}-${no.substr(4, 4)}-${no.substr(8)}`;
    },
    truncate(value = '', limit) {
      if (value.length > limit) {
        return value.substring(0, limit) + '...';
      }
      return value;
    },
  },
  methods: {
    capitalize,
    // used for tracker
    lowerCaseAndTrim(text) {
      return lowerCase(text).replaceAll(' ', '');
    },
    initial(text) {
      return upperCase(text.substring(1, 0));
    },
  },
};

export default stringFilter;
