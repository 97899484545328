import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const CREDITS_URL = `${process.env.API_V4_HOST}/credits`;

export default Client.wrap({
  retrieveCredits(offset = 0, limit = 20) {
    const requestPayload = { offset, limit };
    return this.get(CREDITS_URL, 'user', new ApiOptions({ requestPayload }));
  },
});
