import AgenliteAPIClient from '~/api';

const initialState = {
  summariesInfo: null,
  detailsInfo: null,
  overviewInfo: null,
  profitReportsOnboardingSheetState: true,
};

const moduleGetters = {
  getProfitReportsSummariesInfo: state => state.summariesInfo,
  getProfitReportsDetailsInfo: state => state.detailsInfo,
  getProfitReportsOverviewInfo: state => state.overviewInfo,
  getProfitReportsOnboardingSheetState: state => state.profitReportsOnboardingSheetState,
};

const actions = {
  async getProfitReportsSummaries({ dispatch, commit }, period) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.getProfitReportsSummaries(period);
      const {
        total_profit: totalProfit,
        diff_profit: diffProfit,
        total_transaction_count: transactionCount,
        total_income: totalIncome,
        product_profits: productsProfitRaw,
      } = response.data;
      const productsProfit = productsProfitRaw.map(x => ({
        productType: x.product_type,
        transactionCount: x.total_transaction_count,
        totalProfit: x.total_profit,
      }));
      commit('setProfitReportSummariesInfo', { totalProfit, diffProfit, transactionCount, totalIncome, productsProfit, period });
    } catch (e) {
      const message = 'Gagal Mendapatkan Laporan Keuntungan';
      dispatch('catchError', { e, message });
    } finally {
      dispatch('hideAppLoading');
    }
    return response;
  },
  async getProfitReportsDetail({ dispatch }, payload) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.getProfitReportsDetails(payload);
    } catch (e) {
      const message = 'Gagal Mendapatkan Detail Laporan Keuntungan';
      dispatch('catchError', { e, message });
    } finally {
      dispatch('hideAppLoading');
    }
    return response;
  },
  async getProfitReportsOverviews({ dispatch }, period) {
    dispatch('showAppLoading');
    let response = null;
    try {
      response = await AgenliteAPIClient.getProfitReportsOverviews(period);
    } catch (e) {
      const message = 'Gagal Mendapatkan Overview Laporan Keuntungan';
      dispatch('catchError', { e, message });
    } finally {
      dispatch('hideAppLoading');
    }
    return response;
  },
  showProfitReportsOnboardingSheet({ commit }) {
    commit('setProfitReportsOnboardingSheetState', true);
  },
  hideProfitReportsOnboardingSheet({ commit }) {
    commit('setProfitReportsOnboardingSheetState', false);
  },
};

const mutations = {
  setProfitReportSummariesInfo(state, summariesInfo) {
    state.summariesInfo = summariesInfo;
  },
  setProfitReportDetailsInfo(state, detailsInfo) {
    state.detailsInfo = detailsInfo;
  },
  setProfitReportOverviewInfo(state, overviewInfo) {
    state.overviewInfo = overviewInfo;
  },
  setProfitReportsOnboardingSheetState(state, data) {
    state.profitReportsOnboardingSheetState = data;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  mutations,
  actions,
};
