import Client from '~/types/api/api-module';
import ApiOptions from '../api-options';

const TOP_SPENDER_URL = `${process.env.API_V4_HOST}/agent-retention/top-spender`;

export default Client.wrap({
  retrieveCurrentTopSpenderSeason() {
    return this.get(`${TOP_SPENDER_URL}/current-season`, 'user', new ApiOptions());
  },
  retrieveTopSpenderCustomersRankings(options) {
    const queryParams = new URLSearchParams();
    if (options?.limit !== undefined) queryParams.append('limit', options.limit);
    if (options?.offset !== undefined) queryParams.append('offset', options.offset);
    if (options?.mine !== undefined) queryParams.append('mine', options.mine);

    return this.get(`${TOP_SPENDER_URL}/rankings?${queryParams.toString()}`, 'user', new ApiOptions());
  },
  retrieveLatestSeasonResult() {
    return this.get(`${TOP_SPENDER_URL}/results/me`, 'user', new ApiOptions());
  },
});
